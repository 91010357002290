import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import { Lock } from "@material-ui/icons";
import AutoEnrolmentPensionsFormValidator from "./AutoEnrolmentPensionsFormValidator";
import {
  Alert,
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { List } from "../../../../lists/containers";
import { tooltips } from "../../../../../constants";
import styles from "./AutoEnrolmentPensionsForm.module.scss";

export default class AutoEnrolmentPensionsForm extends Component {

  static propTypes = {
    data: PropTypes.shape({
      previous_pension_provider: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      pension_company: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      pension_scheme: PropTypes.string,
      staging_date: PropTypes.number,
      default_postponement_months: PropTypes.number,
      eligible_job_holder_postponement_months: PropTypes.number,
      employer_pension_reference: PropTypes.string,
    }),
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        previousPensionProvider: props.data?.previous_pension_provider,
        pensionCompany: props.data?.pension_company,
        pensionScheme: props.data?.pension_scheme,
        stagingDate: props.data?.staging_date,
        defaultPostponementMonths: props.data?.default_postponement_months,
        eligibleJobHolderPostponementMonths: props.data?.eligible_job_holder_postponement_months,
        employerPensionReference: props.data?.employer_pension_reference,
      },
      errors: {},
    };
  }

  render() {
    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={12}>
          <Form className={styles.form}>
            <Form.Section title="Auto-Enrolment Pensions" titleClassName={styles.title} className={styles.editable}>
              <Form.Group
                className={this.props.submitting && styles.submitting}
                inline
                wide
                error={this.state.errors?.previousPensionProvider}
              >
                <Form.Label inline>
                  Previous Pension Provider
                </Form.Label>
                <Form.Value>
                  <List
                    identifier="previous_pension_company"
                    name="previousPensionProvider"
                    value={this.state.data.previousPensionProvider?.value}
                    disabled={this.state.data.isNewAgency}
                    onChange={this.handleListValueChange}
                    highlightError={this.state.errors?.previousPensionProvider}
                    placeholder="Select a previous pension provider"
                    size="medium"
                  />
                </Form.Value>
              </Form.Group>
            </Form.Section>
            <Form.Section>
              <Alert className={styles.onboardingAlert} variant="warning" showClose={false}>
                <div className={styles.onboardingAlertContent}>
                  <Lock className={styles.lockIcon}/>
                  <span className={styles.alertTitle}>Agency Only</span>{tooltips.NOT_AVAILABLE_UNTIL_CONVERTED_TOOLTIP}
                </div>
              </Alert>
              <Form.Group
                inline
                wide
              >
                <Form.Label inline>
                  Pension Company
                </Form.Label>
                <Form.ValueInline>
                  <List
                    identifier="pension_company"
                    size="medium"
                    value={this.state.data.pensionCompany?.value}
                    disabled
                    showLock
                    tooltip={tooltips.NOT_AVAILABLE_UNTIL_CONVERTED_TOOLTIP}
                  />
                </Form.ValueInline>
              </Form.Group>
              <Form.Group
                inline
                wide
              >
                <Form.Label inline note="(Optional)">
                  Pension Scheme
                </Form.Label>
                <Form.ValueInline>
                  <Form.Input
                    name="pension_scheme"
                    value={this.state.data.pensionScheme}
                    disabled
                    showLock
                    tooltip={tooltips.NOT_AVAILABLE_UNTIL_CONVERTED_TOOLTIP}
                  />
                </Form.ValueInline>
              </Form.Group>
              <Form.Group
                inline
                wide
              >
                <Form.Label inline>
                  Staging Date
                </Form.Label>
                <Form.Value>
                  <Form.Date
                    name="staging_date"
                    value={this.state.data.stagingDate}
                    disabled
                    showLock
                    tooltip={tooltips.NOT_AVAILABLE_UNTIL_CONVERTED_TOOLTIP}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group
                inline
                wide
              >
                <Form.Label inline note="(Optional)">
                  Default Postponement Months
                </Form.Label>
                <Form.Value>
                  <Form.Number
                    name="default_postponement_months"
                    value={this.state.data.defaultPostponementMonths}
                    disabled
                    showLock
                    tooltip={tooltips.NOT_AVAILABLE_UNTIL_CONVERTED_TOOLTIP}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group
                inline
                wide
              >
                <Form.Label inline>
                  Eligible Job Holder Postponement Months
                </Form.Label>
                <Form.Value>
                  <Form.Number
                    name="eligible_job_holder_postponement_months"
                    value={this.state.data.eligibleJobHolderPostponementMonths}
                    disabled
                    showLock
                    tooltip={tooltips.NOT_AVAILABLE_UNTIL_CONVERTED_TOOLTIP}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group
                inline
                wide
              >
                <Form.Label inline>
                  Employer Pension Reference
                </Form.Label>
                <Form.Value>
                  <Form.Input
                    name="employer_pension_reference"
                    value={this.state.data.employerPensionReference}
                    disabled
                    showLock
                    tooltip={tooltips.NOT_AVAILABLE_UNTIL_CONVERTED_TOOLTIP}
                  />
                </Form.Value>
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              disabled={this.props.submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleListValueChange = (e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: item,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const data = { ...this.state.data };

    const validator = new AutoEnrolmentPensionsFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit && onSubmit({
      previous_pension_provider_id: +data.previousPensionProvider?.id,
    }, this.props);
  }
}

