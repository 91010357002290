import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import CreditApplicationCommentModal from "./CreditApplicationCommentModal";

const mapStateToProps = state => ({
  submitting: state.creditLimitManagement.ui.submitting,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(CreditApplicationCommentModal);

container.displayName = "CreditApplicationCommentModal";

export default container;
