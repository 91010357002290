import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChangePasswordForm } from "../../components";
import { ConfirmationModal, TextBlock } from "../../../common/components";
import Logo from "../../../../assets/images/logo.png";
import styles from "./ChangePasswordModal.module.scss";
import ChangePasswordFormValidator from "modules/currentUser/components/ChangePasswordForm/ChangePasswordFormValidator";

export default function ChangePasswordModal(props) {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleFormChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });

  const handleSubmit = () => {
    const validator = new ChangePasswordFormValidator(formData);
    const { success, errors } = validator.validate();

    setErrors(errors);

    if (!success) {
      return;
    }

    props.changePassword(formData);
  };

  const handleCancel = () => {
    props.hideModal(ChangePasswordModal.displayName);
  };

  return (
    <ConfirmationModal
      title=""
      className={styles.container}
      visible
      onSubmit={handleSubmit}
      submitText="Save"
      busy={props.password.submitting}
      onCancel={handleCancel}
    >
      <div className={styles.header}>
        <img src={Logo} className={styles.logo} alt="Optia logo"/>
        <div className={styles.title}>
          Change your password
        </div>
        <div className={styles.subtitle}>
          Please enter your new password below.
        </div>
        <TextBlock
          className={styles.warning}
          content="Password must be at least 8 characters, with at least one uppercase, lower case, and special character."
        />
        {props.password.error && (
          <div className={styles.error}>
            {props.password.error}
          </div>
        )}
      </div>
      <ChangePasswordForm
        formClassName={styles.form}
        submitting={props.password.submitting}
        formData={formData}
        errors={errors}
        onFormChange={handleFormChange}
      />

    </ConfirmationModal>
  );
}

ChangePasswordModal.propTypes = {
  password: PropTypes.shape({
    submitting: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.string,
  }),
  hideModal: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

ChangePasswordModal.displayName = "ChangePasswordModal";
