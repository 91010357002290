import * as types from "./types";


const initialState = {
  revisions: [],
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_HISTORY_PENDING:
      return initialState;

    case types.GET_HISTORY_SUCCESS:
      return { revisions: action.payload.revisions };

    case types.GET_HISTORY_FAILURE:
      return { ...initialState, error: action.payload.error };

    default:
      return state;
  }
}
