import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { Squares as ActivityIndicator } from "react-activity";
import { ConditionalTooltip, Form } from "../../../common/components";

export class List extends Component {
  static propTypes = {
    identifier: PropTypes.string.isRequired,
    sort_order: PropTypes.shape({
      field: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
    }),
    loading: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    data: PropTypes.shape({
      type: PropTypes.string.isRequired,
      values: PropTypes.array.isRequired,
      placeholder: PropTypes.string,
    }),
    disabledItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
    tooltips: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      tip: PropTypes.string.isRequired,
      effect: PropTypes.string,
      place: PropTypes.string,
    })),
    checklistDisplayType: PropTypes.oneOf(["default", "values"]),
    readOnly: PropTypes.bool.isRequired,
    preventReload: PropTypes.bool,
    getList: PropTypes.func.isRequired,
    customRenderer: PropTypes.func,
    optionsParser: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
  }

  static defaultProps = {
    readOnly: false,
    checklistDisplayType: "default",
    sort_order: [{
      field: "weight",
      order: "asc",
    }],
  }

  async componentDidMount() {
    const { identifier, getList, sort_order, data, preventReload, onReady } = this.props;

    if (data && preventReload && onReady) {
      return onReady({
        ...this.props,
        data,
      });
    }

    await getList({ identifier, sort_order });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading && this.props.onReady) {
      this.props.onReady({ ...this.props });
    }
    return null;
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <ActivityIndicator />;
    }

    const ListComponent = this.getComponentToRender();

    return ListComponent
      ? React.cloneElement(ListComponent)
      : <span>Failed to load list</span>;
  }

  getComponentToRender = () => {
    const {
      identifier,
      data,
      value,
      readOnly,
      disabledItems,
      tooltips,
      onChange,
      customRenderer: CustomRenderer,
      optionsParser,
      checklistDisplayType,
    } = this.props;
    const { type, values, nullable, ...otherProps } = data || {};
    const listProps = _.omit(this.props, ["identifier", "data", "getList"]);

    if (CustomRenderer) {
      return <CustomRenderer {...this.props} />;
    }

    switch (type) {
      case "dropdown":
        return (
          <Form.Select
            name={identifier}
            options={optionsParser ? optionsParser(values) : values}
            clearable={nullable}
            {...otherProps}
            {...listProps}
            value={value}
          />
        );

      case "multiselect":
        return (
          <Form.MultiSelect
            name={identifier}
            values={value}
            options={optionsParser ? optionsParser(values) : values}
            {...otherProps}
            {...listProps}
          />
        );

      case "radiolist":
        if (this.props.readOnly) {
          return (
            <div {...otherProps} {..._.omit(listProps, ["value"])}>
              {values.find(v => String(v.value) === String(value))?.label}
            </div>
          );
        }
        return (
          <>
            {values.map((v, i) => (
              <Form.Radio
                key={i}
                name={identifier}
                value={v.value}
                text={v.label}
                checked={v.value === value}
                defaultChecked={v.is_default}
                {...otherProps}
                {..._.omit(listProps, ["value"])}
                onChange={e => onChange(e, v)}
              />
            ))}
          </>
        );

      case "checklist":
        return (
          checklistDisplayType === "values" ?
            !_.isEmpty(value) ?
              <>
                {value.map((v, index) => (
                  <span key={index}>{values.find(item => item.id === v)?.label}{index === value.length-1 ? "" : ", "}</span>
                ))}
              </>
              :
              <span>-</span>
            :
          <>
            {values.map((v, i) => {
              const tooltip = tooltips?.find(t => t.id === v.id);
              return (
                <ConditionalTooltip
                  key={i}
                  identifier={`${identifier}-${i}`}
                  condition={tooltip}
                  content={tooltip?.tip}
                >
                  <Form.Checkbox
                    key={i}
                    label={v.label}
                    name={v.id}
                    checked={value?.includes(v.id) || value?.includes(v.value) || this.valueExists(value, v)}
                    onClick={() => onChange(v.id)}
                    disabled={readOnly || disabledItems?.includes(v.id)}
                    {...otherProps}
                    {...listProps}
                    onChange={() => onChange(v.id, v)}
                  />
                </ConditionalTooltip>
              );})}
          </>
        );

      default:
        return null;
    }
  }

  handleChange = (item) => {
    const { onChange } = this.props;

    onChange && onChange(item.id, item);
  }

  valueExists = (value, item) => {
    return _.some(value, { id: item.id });
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: _.defaultTo(state.lists.lists[ownProps.identifier]?.loading, true),
  ...state.lists.lists[ownProps.identifier],
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(List);
