import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { ContactInformationForm } from "../../components";
import styles from "./EditContactInformationPage.module.scss";

export default class EditContactInformationPage extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    contactInformation: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
    }).isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool,
    }).isRequired,
    getContactInformation: PropTypes.func.isRequired,
    updateContactInformation: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, getContactInformation } = this.props;

    await getContactInformation({ legalEntityId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.contactInformation.loading ? (
          <ActivityIndicator />
        ) : (
          <ContactInformationForm
            data={this.props.contactInformation.data}
            submitting={this.props.ui.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { legalEntityId, onCancel } = this.props;

    await onCancel(legalEntityId);
  }

  handleSubmit = async (data) => {
    const { legalEntityId, updateContactInformation } = this.props;

    await updateContactInformation({
      legalEntityId,
      ...data,
      redirectUrl: `/credit-limit-management/legal-entities/${legalEntityId}/details`,
    });
  }

}
