import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { actions } from "../../duck";
import VerifyRegisteredCompanyPage from "./VerifyRegisteredCompanyPage";
import { showVerifyRegisteredAgencyCustomerModal } from "modules/customers/duck/actions";

const mapStateToProps = (state, ownProps) => ({
  ...state.customers,
  loading: state.customers.companiesHouseCompanyProfile.loading,
  agencyCustomerId: ownProps.match.params.agencyCustomerId,
  registeredCompanyNumber: ownProps.location.state?.registeredCompanyNumber,
  exactLegalName: ownProps.location.state?.exactLegalName,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  onCancel: () => {
    dispatch(goBack());
  },
  onSubmit: (data) => {
    dispatch(push(`/customers/${data.agencyCustomerId}`));

    dispatch(showVerifyRegisteredAgencyCustomerModal({
      agencyCustomerId: data.agencyCustomerId,
      companiesHouse: {
        registeredCompanyNumber: data.registeredCompanyNumber,
        exactLegalName: data.exactLegalName,
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyRegisteredCompanyPage);
