import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../common/components";
import { options } from "../../../../constants";
import { AgreedPaymentTermsValueLabelConverter } from "converters";
import styles from "./AddNewContractForm.module.scss";
import AddNewContractAddress from "./AddNewContractAddress";
import AddNewContractContact from "./AddNewContractContact";
import canSubmit from "./canSubmit";

const INITIAL_NEW_CONTRACT_FORM_DATA = {
  tradingAs: "",
  contractOrAssignment: "",
  siteContact: {
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    townOrCity: "",
    county: "",
    postcode: "",
  },
  hasInvoiceContact: false,
  useSiteContactForInvoice: false,
  invoiceContact: {
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    townOrCity: "",
    county: "",
    postcode: "",
  },
  isPurchaseOrderNumberRequired: false,
  agreedPaymentTermsType: "",
  agreedPaymentTermsValue: "",
};

export default function AddNewContractForm(props) {
  const [formData, setFormData] = useState(INITIAL_NEW_CONTRACT_FORM_DATA);

  const handleFormChange = event => setFormData({
    ...formData,
    [event.target.name]: event.target.value,
  });

  const handleFormRadioChange = event => setFormData({
    ...formData,
    [event.target.name]: String(event.target.value) === "true",
  });


  const handleSiteContactChange = event => setFormData({
    ...formData,
    siteContact: {
      ...formData.siteContact,
      [event.target.name]: event.target.value,
    },
  });

  const handleInvoiceContactChange = event => setFormData({
    ...formData,
    invoiceContact: {
      ...formData.invoiceContact,
      [event.target.name]: event.target.value,
    },
  });

  const handleCancel = () => {
    if (!_.isEqual(formData, INITIAL_NEW_CONTRACT_FORM_DATA)) {
      props.showUnsavedChangesModal({
        onLeaveClick: props.onCancel,
      });
    } else {
      props.onCancel();
    }
  };

  const handleSubmit = () => {
    props.onSubmit(formData);
  };

  return (
    <>
      <h2>
        Customer Contract Details
      </h2>
      <Form requiredKey>
        <Form.Section title="Contract">
          <Form.Group inline>
            <Form.Label required>
              Name of Legal Entity
            </Form.Label>
            <Form.Input
              name="legalEntityName"
              value={props.legalEntityName}
              size="large"
              readOnly
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label required>
              Company Number
            </Form.Label>
            <Form.Input
              name="registeredCompanyNumber"
              value={props.registeredCompanyNumber}
              readOnly
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label>
              Trading As (If Applicable)
            </Form.Label>
            <Form.Input
              placeholder="Enter Trading As..."
              name="tradingAs"
              value={formData.tradingAs}
              onChange={handleFormChange}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label>
              Contract / Assignment
            </Form.Label>
            <Form.Input
              placeholder="Enter Contract / Assignment..."
              name="contractOrAssignment"
              value={formData.contractOrAssignment}
              onChange={handleFormChange}
            />
          </Form.Group>
        </Form.Section>

        <Form.Section title="Site Contact">
          <AddNewContractContact
            contactType="Site"
            contact={formData.siteContact}
            onChange={handleSiteContactChange}
          />
        </Form.Section>

        <Form.Section title="Site Address">
          <AddNewContractAddress
            contact={formData.siteContact}
            onChange={handleSiteContactChange}
          />
        </Form.Section>

        <Form.Section title="Invoice Contact">
          <Form.Group inline>
            <Form.Label
              required
              fluid
            >
              Do you have Invoice Contact and Address Details?
            </Form.Label>
            <Form.RadioContainer>
              <Form.Radio
                value={true}
                name="hasInvoiceContact"
                checked={formData.hasInvoiceContact}
                text="Yes"
                onChange={handleFormRadioChange}
              />
              <Form.Radio
                value={false}
                name="hasInvoiceContact"
                checked={!formData.hasInvoiceContact}
                text="No"
                onChange={handleFormRadioChange}
              />
            </Form.RadioContainer>
          </Form.Group>

          {formData.hasInvoiceContact && (
            <Form.Group inline>
              <Form.Label required>
                Use site contact and address?
              </Form.Label>
              <Form.RadioContainer>
                <Form.Radio
                  value={true}
                  name="useSiteContactForInvoice"
                  checked={formData.useSiteContactForInvoice}
                  text="Yes"
                  onChange={handleFormRadioChange}
                />
                <Form.Radio
                  value={false}
                  name="useSiteContactForInvoice"
                  checked={!formData.useSiteContactForInvoice}
                  text="No"
                  onChange={handleFormRadioChange}
                />
              </Form.RadioContainer>
            </Form.Group>
          )}

          {formData.hasInvoiceContact &&
            !formData?.useSiteContactForInvoice && (
            <AddNewContractContact
              contactType="Invoice"
              contact={formData.invoiceContact}
              onChange={handleInvoiceContactChange}
            />
          )}
        </Form.Section>

        {formData.hasInvoiceContact &&
          !formData.useSiteContactForInvoice && (
          <Form.Section title="Invoice Address">
            <AddNewContractAddress
              contact={formData.invoiceContact}
              onChange={handleInvoiceContactChange}
            />
          </Form.Section>
        )}

        <Form.Section title="Purchase Order">
          <Form.Group inline>
            <Form.Label>
              Is a P.O. Number required?
            </Form.Label>
            <Form.RadioContainer>
              <Form.Radio
                value={true}
                name="isPurchaseOrderNumberRequired"
                checked={formData.isPurchaseOrderNumberRequired}
                text="Yes"
                onChange={handleFormRadioChange}
              />
              <Form.Radio
                value={false}
                name="isPurchaseOrderNumberRequired"
                checked={!formData.isPurchaseOrderNumberRequired}
                text="No"
                onChange={handleFormRadioChange}
              />
            </Form.RadioContainer>
          </Form.Group>
        </Form.Section>

        <Form.Section title="Agreed Payment Terms">
          <Form.Group inline>
            <Form.Label required>
              Agreed Payment Terms
            </Form.Label>
            <Form.Value>
              <Form.Select
                name="agreedPaymentTermsType"
                value={formData.agreedPaymentTermsType}
                placeholder="Select Agreed Payment Terms..."
                options={options.AGREED_PAYMENT_TERMS_OPTIONS}
                onChange={handleFormChange}
              />
            </Form.Value>
          </Form.Group>

          {formData.agreedPaymentTermsType && (
            <Form.Group inline>
              <Form.Label required>
                {AgreedPaymentTermsValueLabelConverter.to(formData.agreedPaymentTermsType)}
              </Form.Label>
              <Form.Number
                name="agreedPaymentTermsValue"
                maxLength="2"
                value={formData.agreedPaymentTermsValue}
                onChange={handleFormChange}
              />
            </Form.Group>
          )}
        </Form.Section>

        <Form.Section title="Documents">
          <div className={styles.info}>
            If you have signed Terms and Conditions Document(s), please upload these documents
            and submit them with this form or provide the information to the Back Office Team.
          </div>
          <Form.Group>
            <Form.Label>
              Terms and Conditions
            </Form.Label>
            <div className={styles.placeholder}>
              Coming soon...
            </div>
          </Form.Group>
        </Form.Section>
      </Form>

      <ButtonGroup className={styles.buttonGroup}>
        <Button
          variant="outline-danger"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!canSubmit(formData)}
        >
          Submit
        </Button>
      </ButtonGroup>
    </>
  );
}

AddNewContractForm.propTypes = {
  legalEntityName: PropTypes.string,
  registeredCompanyNumber: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showUnsavedChangesModal: PropTypes.func.isRequired,
};
