import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_BACK_OFFICE_CONTACTS = gql`
query($agency_id: ID!) {
  getAgency(id: $agency_id) {
    business_development_manager {
      id
      first_name
      last_name
    }

    credit_controller {
      id
      first_name
      last_name
    }

    credit_controller_buddy {
      id
      first_name
      last_name
    }

    payroll_account_manager {
      id
      first_name
      last_name
    }

    payroll_account_manager_buddy {
      id
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 */
export const getBackOfficeContacts = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BACK_OFFICE_CONTACTS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_BACK_OFFICE_CONTACTS,
      variables: {
        agency_id: agencyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_BACK_OFFICE_CONTACTS_SUCCESS,
      payload: {
        data: response.data.getAgency,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_BACK_OFFICE_CONTACTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
