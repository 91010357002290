import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormValueInline.module.scss";

export default function FormValueInline({
  className,
  children,
}) {
  return (
    <div className={classnames(
      styles.container,
      className
    )}>
      {children}
    </div>
  );
}

FormValueInline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
};
