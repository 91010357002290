import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { CompaniesHouseSearchResultList } from "../../../common/components";
import { Button, ButtonGroup, Form, Heading, Pager } from "../../../common/components";
import { upToNearestThousand } from "../../../../utils";
import styles from "./SearchCreditLimitManagementPage.module.scss";

export class SearchCreditLimitManagementPage extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string,
    legalEntityDetails: PropTypes.shape({
      company_number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      initial_credit_limit_requested: PropTypes.number,
      initial_credit_requested_by: PropTypes.string,
    }),
    loading: PropTypes.bool.isRequired,
    companiesHouseResults: PropTypes.array.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,
    getLegalEntityDetails: PropTypes.func.isRequired,
    getSelectedCompanyProfile: PropTypes.func.isRequired,
    addLegalEntity: PropTypes.func.isRequired,
    updateLegalEntity: PropTypes.func.isRequired,
    searchCompaniesHouse: PropTypes.func.isRequired,
  };

  state = {
    resultsLoaded: false,
    page: 1,
    company_number: "",
    exact_legal_name: "",
    initial_credit_limit: "",
    requested_by: "",
    searchedCompanyNumber: "",
    searchedCompanyName: "",
    selected_company: null,
    busy: false,
  }

  async componentDidMount() {
    const { legalEntityId, getLegalEntityDetails } = this.props;

    if (!legalEntityId) return;

    await getLegalEntityDetails(legalEntityId);

    const { legalEntityDetails } = this.props;

    this.setState({
      company_number: legalEntityDetails.company_number,
      exact_legal_name: legalEntityDetails.name,
      initial_credit_limit: legalEntityDetails.initial_credit_limit_requested || "",
      requested_by: legalEntityDetails.initial_credit_requested_by || "",
    }, () => this.handleSearchClicked());
  }

  render() {
    const {
      company_number,
      exact_legal_name,
      initial_credit_limit,
      requested_by,
    } = this.state;

    return (
      <div>
        <Heading title="Add New Legal Entity" />
        <div className={styles.flexContainer}>
          <div className={styles.form}>
            <Form>
              <Form.Section title="Legal Entity">
                <Form.Group>
                  <Form.Label>Registered Company Number</Form.Label>
                  <Form.Input
                    size="small"
                    name="company_number"
                    value={company_number}
                    onChange={this.handleInputChanged}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Exact Legal Name</Form.Label>
                  <Form.Input
                    size="medium"
                    name="exact_legal_name"
                    value={exact_legal_name}
                    onChange={this.handleInputChanged}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Estimated Credit Needed</Form.Label>
                  <Form.Number
                    size="small"
                    name="initial_credit_limit"
                    value={initial_credit_limit}
                    prefix="£"
                    formatNumber
                    onChange={this.handleInputChanged}
                    onBlur={() => this.handleInputChanged({
                      target: {
                        name: "initial_credit_limit",
                        value: upToNearestThousand(this.state.initial_credit_limit),
                      },
                    })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Requested By</Form.Label>
                  <Form.Input
                    size="medium"
                    name="requested_by"
                    value={requested_by}
                    onChange={this.handleInputChanged}
                  />
                </Form.Group>
              </Form.Section>
            </Form>
            <ButtonGroup className={styles.buttonContainer}>
              <Button
                className={styles.primaryButton}
                variant="primary"
                disabled={!company_number || !exact_legal_name}
                onClick={this.handleSearchClicked}
              >
                Search Companies House
              </Button>
              <Button
                className={styles.secondaryButton}
                variant="secondary"
                onClick={this.handleCancelClicked}
              >
                Cancel
              </Button>
              <Button
                className={styles.primaryButton}
                variant="primary"
                disabled={!company_number || !exact_legal_name}
                onClick={this.handleAddLegalEntity}
              >
                Save To Draft
              </Button>
            </ButtonGroup>
          </div>
          {this.renderResults()}
        </div>
      </div>
    );
  }

  renderResults = () => {
    const { loading, companiesHouseResults } = this.props;
    const { resultsLoaded, page } = this.state;

    return resultsLoaded && (
      <div className={styles.flexResults}>
        <h2 className={styles.sectionHeader}>Companies House</h2>
        <div className={loading && styles.loadingOverlay}>
          {this.renderCompanyNumberResults()}
          {this.renderCompanyNameResults()}
        </div>
        <div className={styles.pagination}>
          {companiesHouseResults.searchByName.total > 0 && <Pager
            pageCount={this.getPageCount()}
            selectedPage={page}
            onPageChanged={this.handlePageChanged}
          />}
        </div>
      </div>
    );
  }

  renderCompanyNumberResults = () => {
    const { companiesHouseResults } = this.props;
    const { searchedCompanyNumber } = this.state;
    const { searchByNumber } = companiesHouseResults;

    return (
      <CompaniesHouseSearchResultList
        searchText={searchedCompanyNumber}
        total={searchByNumber.total}
        data={searchByNumber.data}
        existingLegalEntities={searchByNumber.existing_legal_entities}
        onItemClick={this.handleSearchResultClicked}
      />
    );
  }

  renderCompanyNameResults = () => {
    const { companiesHouseResults } = this.props;
    const { searchedCompanyName } = this.state;
    const { searchByName } = companiesHouseResults;

    return (
      <CompaniesHouseSearchResultList
        searchText={searchedCompanyName}
        total={searchByName.total}
        data={searchByName.data}
        existingLegalEntities={searchByName.existing_legal_entities}
        onItemClick={this.handleSearchResultClicked}
      />
    );
  }

  handleInputChanged = (e) => {
    if (e.target.name === "company_number" && e.target.value.length > 9) return;

    this.setState({ [e.target.name]: e.target.value });
  }

  handleCancelClicked = () => {
    const { history } = this.props;

    history.goBack();
  }

  handleSearchClicked = () => {
    const newState = {
      resultsLoaded: true,
      page: 1,
      searchedCompanyNumber: this.state.company_number,
      searchedCompanyName: this.state.exact_legal_name,
    };

    this.setState(newState, () => this.getCompaniesHouseResults({
      company_number: this.state.company_number,
      company_name: this.state.exact_legal_name, page:
      this.state.page,
    }));
  }

  handleSearchResultClicked = async (result, existing) => {
    const { history } = this.props;

    if (existing) {
      history.push(`/credit-limit-management/legal-entities/${existing.id}`);
    } else {
      this.setState({
        selected_company: result,
      });
    }
  }

  handleAddLegalEntity = _.throttle(async () => {
    try {
      const { legalEntityId, addLegalEntity, updateLegalEntity } = this.props;
      const { busy, company_number, exact_legal_name, selected_company } = this.state;

      if (busy) return;

      this.setState({ busy: true });

      if (!legalEntityId) {
        await addLegalEntity({
          name: selected_company?.name || exact_legal_name,
          company_number: selected_company?.companyNumber || company_number,
        });
      } else {
        await updateLegalEntity(legalEntityId, {
          name: selected_company?.name || exact_legal_name,
          company_number: selected_company?.companyNumber || company_number,
        });
      }
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    this.setState({ busy: false });
  }, 1000)

  handlePageChanged = async (n) => {
    const { company_number, exact_legal_name } = this.state;

    this.setState({ page: n });

    await this.getCompaniesHouseResults({
      company_number,
      company_name: exact_legal_name,
      page: n,
    });
  }

  getPageCount = () => {
    const { companiesHouseResults } = this.props;

    let pageCount = companiesHouseResults.searchByName.total / 3;
    if (pageCount < 1) {
      pageCount = 1;
    }
    // 1000 results is the max available from CH API.
    if (pageCount > 1000 / 3) {
      pageCount = 1000 / 3;
    }
    return Math.floor(pageCount);
  }

  getCompaniesHouseResults = _.throttle(async ({ company_number, company_name, page }) => {
    const { searchCompaniesHouse } = this.props;
    const { resultsLoaded } = this.state;

    if (!resultsLoaded) return;

    await searchCompaniesHouse({
      company_number,
      company_name,
      page,
      limit: 3,
    });
  }, 1000, { trailing: true })
}

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchCreditLimitManagementPage);
