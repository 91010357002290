import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_ONBOARDING_TASKS = gql`
query($agency_id: ID!, $filter_options: OnboardingTasksFilterOptions) {
  getAgencyOnboardingTasks(agency_id: $agency_id, filter_options: $filter_options) {
    id
    onboarding_task {
      id
      name
      weight
    }
    progress {
      required_completed
      required_total
    }
    status
    due_date
  }
}
`;

export const getAgencyTasks = ({
  agencyId,
  filterOptions,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_TASKS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_ONBOARDING_TASKS,
      variables: {
        agency_id: agencyId,
        filter_options: filterOptions,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const agencyTasks = response.data.getAgencyOnboardingTasks;

    dispatch({
      type: types.GET_AGENCY_TASKS_SUCCESS,
      payload: {
        agencyTasks,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_AGENCY_TASKS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
