import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Close } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import styles from "./DistributeInput.module.scss";

export default function DistributeInput(props) {
  return (
    <div className={styles.container}>
      <Form.Group inline className={styles.group}>
        <Form.Label inline required>From</Form.Label>
        <Form.Value
          className={classnames(
            props.index > 0 && styles.flexRow
          )}
        >
          <Form.Select
            className={styles.dropdown}
            placeholder="Select account to distribute from..."
            options={props.accounts}
            fluid
            value={props.distribution.accountId}
            onChange={e => props.onChange({
              accountId: e.target.value,
              amount: props.distribution.amount,
            })}
            disabled={props.loading}
          />
          {props.index > 0 && (
            <IconButton
              className={styles.removeButton}
              icon={(<Close />)}
              iconPosition="left"
              onClick={props.onRemove}
            />
          )}
        </Form.Value>
      </Form.Group>
      <Form.Group inline className={styles.group} error={props.error}>
        <Form.Label inline required>Distribute amount</Form.Label>
        <Form.Number
          value={props.distribution.amount}
          placeholder="eg. £10,000"
          prefix="£"
          onChange={e => props.onChange({
            accountId: props.distribution.accountId,
            amount: e.target.value,
          })}
          highlightError={props.error}
          disabled={props.loading}
          formatNumber
        />
      </Form.Group>
    </div>
  );
}

DistributeInput.propTypes = {
  index: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  accounts: PropTypes.array.isRequired,
  distribution: PropTypes.shape({
    accountId: PropTypes.string,
    amount: PropTypes.number,
  }).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

DistributeInput.defaultProps = {
  loading: false,
  accounts: [],
  distributionAmount: 0,
};
