const MAPPINGS = {
  days_after_invoice_date: "Payment Terms Date",
  end_of_month: "Day of the month",
};

/**
 * Converts an agreed payment terms value into a label
 *
 * @param {string} value
 */
const from = (value) => {
  return Object.keys(MAPPINGS).find(x => MAPPINGS[x] === value);
};

/**
 * Converts an agreed payment terms label into a value
 *
 * @param {string} value
 */
const to = (value) => {
  return MAPPINGS[value] || "Unknown";
};

export default {
  from,
  to,
};
