import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import EditUserPage from "./EditUserPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.userManagement,
  userId: ownProps.match.params.id,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage);
