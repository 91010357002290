import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../duck";
import SignInPage from "./SignInPage";

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

const mapStateToProps = state => ({
  loginState: state.signIn.login,
  resetPasswordState: state.signIn.resetPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
