import * as types from "./types";


//TODO: split these into 2 seperate reducers!
const initialState = {
  submitting: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SIGN_UP_PENDING:
      return { submitting: true, error: null };

    case types.SIGN_UP_SUCCESS:
      return { submitting: false, error: null };

    case types.SIGN_UP_FAILURE:
      return { submitting: false, error: action.payload.error };

    default:
      return state;
  }
}
