import { push } from "connected-react-router";
import { ExperianCreditCheckForAgencyModal } from "../../components";
import { addExperianCreditCheck } from "./";
import { actions as modalActions } from "../../../modals/duck";

export const handleExperianCreditCheckRequiredTask = creditLimitManagementTask => async (dispatch) => {
  dispatch(push(`/credit-limit-management/legal-entities/${creditLimitManagementTask.data.legal_entity_id}/credit-management`));

  dispatch(modalActions.showModal(ExperianCreditCheckForAgencyModal.displayName, {
    // onVerifyAgencyCustomer is called when the Experian form is submitted
    onVerifyAgencyCustomer: (formData) => {
      dispatch(addExperianCreditCheck({
        legalEntityId: creditLimitManagementTask.data.legal_entity_id,
        companyFound: formData.companyFound,
        experianCreditResult: formData.newCreditLimit,
        experianDateOfResult: formData.dateOfResult,
        experianCreditReport: formData.experianCreditReport,
      }));

      dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));
    },
  }));
};
