import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import upload from "../../../../assets/images/upload.svg";
import { Icon } from "..";
import styles from "./DocumentUploadButton.module.scss";

export default class DocumentUploadButton extends PureComponent {

  static propTypes = {
    name: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    name: "upload-button",
  }

  ref = null;

  render() {
    const { name, text } = this.props;

    return (
      <div className={styles.container} onClick={this.handleClick}>
        <input
          className={styles.hidden}
          type="file"
          onChange={this.handleFileSelected}
          name={name}
          ref={_ref => this.ref = _ref}
        />
        <div className={styles.content}>
          <div className={styles.title}>
            <Icon className={styles.icon} icon={upload} />
            <span className={styles.text}>{text || "Upload"}</span>
          </div>
        </div>
      </div>
    );
  }

  handleClick = () => {
    this.ref.click();
  }

  handleFileSelected = (e) => {
    const { name, onClick } = this.props;

    if (e.target.files.length === 0) return;

    let file = e.target.files[0];

    onClick && onClick({
      target: {
        name,
        value: {
          name: file.name,
          data: file,
        },
      },
    });
  };

}
