import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CompaniesHouseRegisteredAddressSection from "./CompaniesHouseRegisteredAddressSection/CompaniesHouseRegisteredAddressSection";
import CompanyDetailsSection from "./CompanyDetailsSection/CompanyDetailsSection";
import ContactInformationSection from "./ContactInformationSection/ContactInformationSection";
import styles from "./LegalEntityDetailsView.module.scss";

export default function LegalEntityDetailsView(props) {
  return (
    <div className={classnames(props.className, styles.container)}>
      <div className={styles.row}>
        <CompanyDetailsSection
          className={styles.companyDetails}
          data={props.legalEntity}
          revisions={props.legalEntityHistory}
          onEditClick={props.onEditCompanyDetailsClick}
        />
      </div>
      <div className={styles.row}>
        <CompaniesHouseRegisteredAddressSection
          data={props.legalEntity?.companies_house_info?.registered_office_address}
          revisions={props.legalEntityHistory}
        />
        <ContactInformationSection
          data={props.legalEntity}
          onEditClick={props.onEditContactInformationClick}
        />
      </div>
    </div>
  );
}

LegalEntityDetailsView.propTypes = {
  className: PropTypes.string,
  legalEntity: {
    ...CompaniesHouseRegisteredAddressSection.propTypes.data,
    ...CompanyDetailsSection.propTypes.data,
    ...ContactInformationSection.propTypes.data,
  },
  legalEntityHistory: PropTypes.object,
  onEditCompanyDetailsClick: PropTypes.func.isRequired,
  onEditContactInformationClick: PropTypes.func.isRequired,
};
