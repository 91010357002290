import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import PermissionDenied from "./PermissionDenied";
import styles from "./PermissionDenied.module.scss";

export default function withPermissionDenied(WrappedComponent) {
  return class PermissionDeniedWrapper extends PureComponent {
    static propTypes = {
      error: PropTypes.string,
    }

    render() {
      const { error } = this.props;

      return error === "Permission Required."
        ? (
          <div>
            <div className={styles.disabled}>
              <WrappedComponent {...this.props} />
            </div>
            <PermissionDenied />
          </div>
        ) : <WrappedComponent {...this.props} />;
    }
  };
}
