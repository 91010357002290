import React from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";
import classnames from "classnames";
import styles from "./FormInputSemantic.module.scss";

export default function FormInputSemantic({
  value,
  name,
  className,
  size,
  inline,
  fluid,
  errorMessage,
  disabled,
  readOnly,
  showError,
  onChange,
  highlightError,
  ...otherProps
}) {
  const renderInput = () => {
    const defaultValue = value ? value : readOnly ? "-" : "";

    const { ...inputProps } = otherProps;

    if (readOnly && !disabled) {
      return (
        <div
          className={classnames(
            styles.text,
            readOnly && styles.readOnly,
          )}
        >
          {value || defaultValue}
        </div>
      );
    }

    return (
      <Input
        {...inputProps}
        className={classnames(
          styles.inputText,
          styles[size],
          highlightError && styles.highlightError,
        )}
        name={name}
        onChange={handleInputChange}
        value={value || defaultValue}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete="off"
      />
    );
  };

  const selectInput = (e) => {
    if (e.target.tagName !== "DIV") return;

    const element = e.target.children?.length > 0
      ? e.target.children[0]
      : e.target;

    element.focus();
  };

  const handleInputChange = (e) => {
    if (disabled || readOnly) return;

    onChange && onChange(e);
  };


  return (
    <div>
      <div
        className={classnames(
          styles.container,
          styles[size],
          fluid && styles.fluid,
          inline && styles.inline,
          className,
          disabled && styles.disabled,
          readOnly && styles.readOnly,
        )}
        onClick={selectInput}
      >
        {renderInput()}
      </div>
      {showError && (
        <div className={classnames(styles.error, styles[size])}>
          <span className={styles.errorHeader}>Error: </span>
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}

FormInputSemantic.defaultProps = {
  disabled: false,
  size: "medium",
  variant: "default",
};

FormInputSemantic.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fluid: PropTypes.bool,
  inline: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.object,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showError: PropTypes.bool,
  highlightError: PropTypes.string,
  onChange: PropTypes.func,
  onLockClick: PropTypes.func,
};
