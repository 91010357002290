import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./TaskCard.module.scss";
import { AccessTime, Done, Warning } from "@material-ui/icons";

const iconForStatus = (status) => {
  switch (status) {
    case "not_started":
      return <Warning className={styles.notStartedIcon} />;

    case "in_progress":
      return <AccessTime className={styles.inProgressIcon} />;

    case "done":
      return <Done className={styles.doneIcon} />;

    default:
      return null;
  }
};

const TaskCard = ({
  active,
  status,
  name,
  tasksTotal,
  tasksCompleted,
  disabled,
  onClick,
}) => {
  const completed = status === "done";
  return (
    <div
      className={classnames(
        styles.taskCard,
        active && styles.active,
        disabled && styles.disabled,
      )}
      onClick={onClick && onClick}
    >
      {iconForStatus(status)}
      <div className={styles.taskFlex}>
        <div className={`${styles.taskName} ${completed && styles.taskNameCompleted}`}>{name || "Example Task Name"}</div>
        {!completed && <div>{`(${tasksCompleted}/${tasksTotal} required)`}</div>}
      </div>
    </div>
  );
};

TaskCard.defaultProps = {
  active: false,
  name: "",
  status: "not_started",
  tasksCompleted: 0,
  tasksTotal: 0,
};

TaskCard.propTypes = {
  active: PropTypes.bool,
  status: PropTypes.oneOf(["not_started", "in_progress", "done"]),
  name: PropTypes.string,
  tasksCompleted: PropTypes.number,
  tasksTotal: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default TaskCard;
