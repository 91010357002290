import React from "react";
import PropTypes from "prop-types";
import CompaniesHouseSearchResultItem from "./CompaniesHouseSearchResultItem";
import styles from "./CompaniesHouseSearchResultList.module.scss";

export default function CompaniesHouseSearchResultList(props) {
  return (
    <div className={styles.container}>
      {props.data.map((value) => {
        const existing = props.existingLegalEntities.find((legalEntity) => {
          return legalEntity.company_number === value.company_number;
        });

        return (
          <CompaniesHouseSearchResultItem
            className={styles.item}
            key={value.company_number}
            name={value.name}
            companyNumber={value.company_number}
            companyStatus={value.company_status}
            incorporatedDate={value.incorporated_date}
            addressSnippet={value.address_snippet}
            exists={!!existing}
            hideExistingWarning={props.hideExistingWarning}
            onClick={() => props.onItemClick({
              name: value.name,
              companyNumber: value.company_number,
              companyStatus: value.company_status,
            }, existing)}
          />
        );
      })}
    </div>
  );
}

CompaniesHouseSearchResultList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
    company_status: PropTypes.string,
    incorporated_date: PropTypes.number,
    address_snippet: PropTypes.string,
  })).isRequired,
  existingLegalEntities: PropTypes.arrayOf(PropTypes.shape({
    company_number: PropTypes.string.isRequired,
  })),
  hideExistingWarning: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
};

CompaniesHouseSearchResultList.defaultProps = {
  searchText: "",
  total: 0,
  data: [],
  existingLegalEntities: [],
};
