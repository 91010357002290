import React from "react";
import PropTypes from "prop-types";
import { Header, Portal, Segment } from "semantic-ui-react";
import { Button } from "../../../common/components";
import styles from "./ConvertedToAgencyMessage.module.scss";

export default function ConvertedToAgencyMessage(props) {
  return (
    <Portal open defaultOpen>
      <Segment className={styles.container} padded raised>
        <Header>This is now an agency</Header>
        <div className={styles.text}>
          This prospect has been converted into an agency. Its prospect profile is no longer available, but you can find its agency profile by clicking the button below.
        </div>
        <div className={styles.actions}>
          <Button
            variant="primary"
            onClick={props.onSubmit}
          >
            Go to Agency
          </Button>
        </div>
      </Segment>
    </Portal>
  );
}

ConvertedToAgencyMessage.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
