import _ from "lodash";

export default class UserFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isEmpty(data?.firstName)) {
      errors = _.set(errors, "firstName", "First Name is required");
    }

    if (_.isEmpty(data?.lastName)) {
      errors = _.set(errors, "lastName", "Last Name is required");
    }

    if (_.isEmpty(data?.email)) {
      errors = _.set(errors, "email", "Email is required");
    }

    if (_.isEmpty(data?.departmentId)) {
      errors = _.set(errors, "departmentId", "Department is required");
    }

    if (_.isEmpty(data?.userTypeId)) {
      errors = _.set(errors, "userTypeId", "User Type is required");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
