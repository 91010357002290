import * as types from "./types";

const initialState = {
  lists: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_LIST_PENDING:
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.payload.identifier]: {
            loading: true,
            error: null,
          },
        },
      };

    case types.GET_LIST_SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.payload.identifier]: {
            loading: false,
            error: null,
            data: action.payload.list,
          },
        },
      };

    case types.GET_LIST_FAILURE:
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.payload.identifier]: {
            loading: false,
            error: action.payload.error,
          },
        },
      };

    default:
      return state;
  }
}
