import React from "react";
import PropTypes from "prop-types";
import { DocumentDownloadCard } from "..";
import styles from "./DocumentVersionDownload.module.scss";

export default function DocumentVersionDownload(props) {
  const onDelete = (version) => {
    props.onDelete(version);
  };

  const sortedVersions = props.document?.versions?.sort((a, b) => b.metadata.date_uploaded - a.metadata.date_uploaded);

  return (
    props.document &&
    <div>
      {sortedVersions.map((version, index) => {
        let fileName = props.documentType;
        fileName += version.isLatest
          ? " (Current)"
          : ` (v${sortedVersions.length - index})`;

        return (
          <div className={styles.download} key={index}>
            <DocumentDownloadCard
              fileName={fileName}
              fileSize={version.size}
              uploadDate={version.metadata.date_uploaded}
              showDeleteButton={true}
              onDelete={() => onDelete(version)}
              onDownload={() => props.onDownload({
                agencyId: props.agencyId,
                prospectId: props.prospectId,
                documentType: props.documentType,
                versionId: version.versionId,
              })}
            />
          </div>
        );
      })}
    </div>
  );
}

DocumentVersionDownload.propTypes = {
  agencyId: PropTypes.string,
  prospectId: PropTypes.string,
  documentType: PropTypes.string.isRequired,
  document: PropTypes.shape({
    name: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.shape({
      isLatest: PropTypes.bool,
      versionId: PropTypes.string,
      size: PropTypes.number,
      metadata: PropTypes.shape({
        date_uploaded: PropTypes.number,
      }),
    })),
  }).isRequired,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func.isRequired,
};

