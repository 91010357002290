import { CancelScheduledCreditActionModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.scheduledActionId
 */
export const showCancelScheduledCreditActionModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(CancelScheduledCreditActionModal.displayName, {
    scheduledActionId: String(obj.scheduledActionId),
  }));
};
