/* eslint-disable no-unused-vars */
import * as types from "../types";
import { sleep } from "../../../../utils";

export const getCustomers = ({
  prospectId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CUSTOMERS_PENDING,
    });

    await sleep(500);

    dispatch({
      type: types.GET_CUSTOMERS_SUCCESS,
      payload: {
        customers: [],
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CUSTOMERS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
