import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Popup, Table } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import { ErrorOutline } from "@material-ui/icons";
import { SortIndicator, TextLink } from "../../../common/components";
import { parseDate, toMoney } from "../../../../utils";
import { CompanyTypeFormatter } from "../../../../formatters";
import styles from "./LegalEntityList.module.scss";

export default function LegalEntityList(props) {
  if (props.loading) {
    return <ActivityIndicator />;
  }

  const handleColumnClicked = (name) => {
    props.onColumnClick(name, props.sortOrder[name] || "NONE");
  };

  return (
    <Table
      className={styles.table}
      basic
    >
      <Table.Header>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("name")}
        >
          <div>
            Legal Entity
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.name}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          {/* For the icons */}
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("credit_limit_including_vat")}
        >
          <div>
            Credit Limit
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.credit_limit_including_vat}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("undistributed_credit_limit")}
        >
          <div>
            Undistributed Credit
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.undistributed_credit_limit}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <div>
            Total Debt
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <div>
            Exposure
          </div>
        </Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {props.legalEntities.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan="6" className={styles.noResults}>
              There are no Legal Entities available to view.
            </Table.Cell>
          </Table.Row>
        ) : props.legalEntities.map((legalEntity) => {
          const hasCreditLimit = !_.isNull(legalEntity.credit_limit_including_vat);

          return (
            <Table.Row key={`legalEntity_${legalEntity.id}`}>
              <Table.Cell className={styles.detailsCell}>
                <div>
                  <TextLink
                    className={styles.legalEntityName}
                    text={legalEntity.name}
                    to={`/credit-limit-management/legal-entities/${legalEntity.id}`}
                  />
                  <div className={styles.companyDetails}>
                    {CompanyTypeFormatter.format(legalEntity.__typename)}
                    {legalEntity.company_number && (
                      <> | {legalEntity.company_number}</>
                    )}
                    {legalEntity.registered_charity_number && !legalEntity.company_number && (
                      <> | {legalEntity.registered_charity_number}</>
                    )}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>
                {["RegisteredCompany", "RegisteredCharity"].includes(legalEntity.__typename) &&
                  legalEntity.company_status !== "active" && (
                  <Popup
                    trigger={<ErrorOutline className={styles.inactiveIcon} />}
                    on="hover"
                    position="bottom left"
                    eventsEnabled
                  >
                    <Popup.Content className={styles.lastChased}>
                      This Legal Entity has been marked as <strong>inactive</strong>{
                        legalEntity.inactive_since && (
                          <> since <strong>{parseDate(legalEntity.inactive_since)}</strong></>
                        )
                      }
                    </Popup.Content>
                  </Popup>
                )}
              </Table.Cell>
              <Table.Cell>
                {hasCreditLimit ? toMoney(legalEntity.credit_limit_including_vat) : "£-"}
              </Table.Cell>
              <Table.Cell>
                {hasCreditLimit ? toMoney(legalEntity.undistributed_credit_limit) : "£-"}
              </Table.Cell>
              <Table.Cell>
                {toMoney(legalEntity.total_debt || 0)}
              </Table.Cell>
              <Table.Cell>
                {toMoney(legalEntity.exposure || 0)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

LegalEntityList.propTypes = {
  loading: PropTypes.bool.isRequired,
  legalEntities: PropTypes.arrayOf(PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    credit_limit_excluding_vat: PropTypes.number,
    credit_limit_including_vat: PropTypes.number,
    undistributed_credit_limit: PropTypes.number,
    total_debt: PropTypes.number,
    exposure: PropTypes.number,
    active_suspension: PropTypes.shape({
      date_of_effect: PropTypes.string.isRequired,
    }),
  })).isRequired,
  sortOrder: PropTypes.shape({
    name: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    credit_limit_including_vat: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    undistributed_credit_limit: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
  }).isRequired,
  onColumnClick: PropTypes.func.isRequired,
};
