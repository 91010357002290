import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PAYROLL_GENERAL = gql`
query($agency_id: ID!) {
  getAgency(id: $agency_id) {
    can_remove_worker_types
    worker_types {
      id
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 */
export const getPayrollGeneral = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PAYROLL_GENERAL_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PAYROLL_GENERAL,
      variables: {
        agency_id: agencyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_PAYROLL_GENERAL_SUCCESS,
      payload: {
        data: response.data.getAgency,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PAYROLL_GENERAL_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
