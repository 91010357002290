import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Heading } from "../../../common/components";
import { AddNewContractForm } from "../../components";

export default function AddNewContractPage(props) {
  const { getAgencyCustomer, agencyCustomerId } = props;

  useEffect(() => {
    getAgencyCustomer({
      agencyCustomerId: agencyCustomerId,
    });
  }, [getAgencyCustomer, agencyCustomerId]);

  const handleCancel = () => {
    props.onCancelAddNewCustomerContract();
  };

  const handleSubmit = (formData) => {
    const dataToSubmit = {
      ...formData,
      agencyCustomerId: props.agencyCustomer.data?.id,
    };

    props.addCustomerContract(dataToSubmit);
  };

  return (
    <div>
      <Heading title="New Customer Contract" />
      <AddNewContractForm
        legalEntityName={props.agencyCustomer.data?.legal_name}
        registeredCompanyNumber={props.agencyCustomer.data?.registered_company_number}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        showUnsavedChangesModal={props.showUnsavedChangesModal}
      />
    </div>
  );
}

AddNewContractPage.propTypes = {
  agencyCustomerId: PropTypes.string.isRequired,
  agencyCustomer: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      legal_name: PropTypes.string,
      registered_company_number: PropTypes.string,
    }),
  }),
  addCustomerContract: PropTypes.func.isRequired,
  getAgencyCustomer: PropTypes.func.isRequired,
  onCancelAddNewCustomerContract: PropTypes.func.isRequired,
  showUnsavedChangesModal: PropTypes.func.isRequired,
};
