import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import AgencyDetailsFormValidator from "./AgencyDetailsFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { List } from "../../../../lists/containers";
import { tooltips } from "../../../../../constants";
import styles from "./AgencyDetailsForm.module.scss";

export default class AgencyDetailsForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
      correspondence_name: PropTypes.string.isRequired,
      trading_as: PropTypes.string,
      website: PropTypes.string,
      linked_in: PropTypes.string,
      twitter: PropTypes.string,
      previous_provider: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      industry_sectors: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        prospect_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        known_as: props.data?.known_as,
        correspondence_name: props.data?.correspondence_name,
        trading_as: props.data?.trading_as,
        website: props.data?.website,
        linked_in: props.data?.linked_in,
        twitter: props.data?.twitter,
        previous_provider: props.data?.previous_provider,
        industry_sectors: props.data?.industry_sectors.map(industrySector => industrySector.name),
      },
      errors: {},
    };
  }

  render() {
    const { submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={14}>
          <Form className={styles.form}>
            <Form.Section title="Agency Details" titleClassName={styles.title}>
              <Form.Group inline wide error={errors?.known_as}>
                <Form.Label inline>Known As</Form.Label>
                <Form.Input
                  name="known_as"
                  value={data.known_as}
                  size="large"
                  maxLength={30}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  onChange={this.handleInputChange}
                  highlightError={errors?.known_as}
                />
              </Form.Group>

              <Form.Group inline wide error={errors?.correspondence_name}>
                <Form.Label
                  inline
                  tooltip={tooltips.WHAT_IS_CORRESPONDENCE_NAME}
                >
                  Exact Legal Name (Payroll Version)
                </Form.Label>
                <Form.Input
                  name="correspondence_name"
                  type="text"
                  value={data.correspondence_name}
                  placeholder="Enter Exact Legal Name..."
                  size="large"
                  maxLength={160}
                  onChange={this.handleInputChange}
                  highlightError={errors?.correspondence_name}
                />
              </Form.Group>

              <Form.Group inline wide>
                <Form.Label inline note="(Optional)">
                  Trading As
                </Form.Label>
                <Form.Input
                  name="trading_as"
                  value={data.trading_as}
                  size="large"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("trading_as")}
                  showLock={this.isFieldDisabled("trading_as")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("trading_as")}
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline note="(Optional)">Industry Sector</Form.Label>
                {this.isFieldDisabled("industry_sectors") ? (
                  <Form.Input
                    name="industry_sectors"
                    value={data.industry_sectors?.join(", ")}
                    size="large"
                    tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                    disabled
                    showLock
                    onLockClick={() => this.handleDisabledFieldClick("industry_sectors")}
                  />
                ) : (
                  <Form.Value>
                    <List
                      identifier="industry_sectors"
                      value={data.industry_sectors}
                      size="large"
                      onChange={this.handleSelectedValuesChange}
                    />
                  </Form.Value>
                )}
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline note="(Optional)">Previous Provider</Form.Label>
                <Form.Value>
                  <List
                    identifier="previous_provider"
                    sort_order={{ field: "value", order: "asc" }}
                    value={data.previous_provider?.value}
                    size="large"
                    onChange={this.handleItemChange}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline note="(Optional)">Agency Website</Form.Label>
                <Form.Input
                  name="website"
                  value={data.website}
                  size="large"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("website")}
                  showLock={this.isFieldDisabled("website")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("website")}
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline note="(Optional)">LinkedIn Page</Form.Label>
                <Form.Input
                  name="linked_in"
                  value={data.linked_in}
                  size="large"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("linked_in")}
                  showLock={this.isFieldDisabled("linked_in")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("linked_in")}
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label inline note="(Optional)">Twitter</Form.Label>
                <Form.Input
                  name="twitter"
                  value={data.twitter}
                  size="large"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("twitter")}
                  showLock={this.isFieldDisabled("twitter")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("twitter")}
                />
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={submitting}
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleItemChange = (e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: item,
      },
    });
  }

  handleSelectedValuesChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.target.values,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const validator = new AgencyDetailsFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    const dataExcludingDisabledFields = _.omitBy(data, (_value, key) => {
      return this.isFieldDisabled(key);
    });

    onSubmit && onSubmit(dataExcludingDisabledFields);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick && onDisabledFieldClick(disabledField);
  }

  isFieldDisabled = (fieldName) => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
