import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const REMOVE_AGENCY_DOCUMENT = gql`
mutation($input: AgencyDocumentUrlInput!) {
  removeAgencyDocument(input: $input)
}
`;

/**
 * @param  {object} obj
 * @param  {string} obj.agencyId
 * @param  {object} obj.document
 * @param  {string} obj.document.name
 * @param  {string} obj.document.versionId
 */
export const removeAgencyDocument = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.REMOVE_AGENCY_DOCUMENT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: REMOVE_AGENCY_DOCUMENT,
      variables: {
        input: {
          agency_id: obj.agencyId,
          document_type: obj.document.name,
          version_id: obj.document.versionId,
        },
      },
    });

    if (!response.data.removeAgencyDocument) {
      throw Error(`Failed to remove ${document.name}`);
    }

    dispatch({
      type: types.REMOVE_AGENCY_DOCUMENT_SUCCESS,
      payload: {
        document: obj.document,
        versionId: obj.versionId,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.REMOVE_AGENCY_DOCUMENT_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
