import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { History } from "@material-ui/icons";
import styles from "./CreditApplicationActiveLabel.module.scss";

export default function CreditApplicationActiveLabel(props) {
  return (
    <div className={classnames(props.className, styles.container)} >
      <div className={styles.iconWrapper} >
        <History className={styles.icon} />
      </div>
      <span className={styles.text}>
        Credit application in progress
      </span>
    </div>
  );
}

CreditApplicationActiveLabel.propTypes = {
  className: PropTypes.string,
};
