import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_LEGAL_ENTITY = gql`
query($id: ID!) {
  getLegalEntity(id: $id) {
    id
    name
    company_type
    created_at
    credit_limit_excluding_vat
    credit_limit_including_vat
    undistributed_credit_limit
    active_suspension {
      date_of_effect
    }
    scheduled_suspension {
      id
      type
      date_of_effect
    }

    ...on RegisteredCompany {
      company_number
      company_status
      companies_house_info {
        company_name
      }
      experian_credit_checks(pager: { page: 1, limit: 1 }) {
        id
      }
    }
  }
}
`;

export const getLegalEntity = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_LEGAL_ENTITY_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_LEGAL_ENTITY,
      variables: {
        id,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const legalEntity = response.data.getLegalEntity;

    dispatch({
      type: types.GET_LEGAL_ENTITY_SUCCESS,
      payload: {
        legalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_LEGAL_ENTITY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
