import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { bytesToGigabytes } from "../../../../utils";
import { getAgencyDocuments } from ".";

const UPLOAD_AGENCY_DOCUMENT = gql`
query($input: AgencyDocumentUploadUrlInput!) {
  getAgencyDocumentUploadUrl(input: $input) {
    bucket
    key
    url
  }
}
`;

export const uploadAgencyDocuments = ({
  agencyId,
  files,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPLOAD_AGENCY_DOCUMENTS_PENDING,
    });

    const promises = files.map(async (file) => {
      const fileSize = bytesToGigabytes(file.size);

      if (fileSize > 5) {
        throw new Error("File size was too large. Maximum file size is 5GB.");
      }

      const response = await apolloClient.query({
        query: UPLOAD_AGENCY_DOCUMENT,
        variables: {
          input: {
            agency_id: agencyId,
            document_type: file.documentType.type,
            filename: file.name,
          },
        },
      });

      const uploadUrl = response.data.getAgencyDocumentUploadUrl;

      await fetch(uploadUrl.url, {
        method: "PUT",
        body: file,
      });
    });

    await Promise.all(promises);

    dispatch(getAgencyDocuments({ agencyId }));

    dispatch({
      type: types.UPLOAD_AGENCY_DOCUMENTS_SUCCESS,
    });

    return document.name;
  }
  catch (err) {
    dispatch({
      type: types.UPLOAD_AGENCY_DOCUMENTS_FAILURE,
      payload: {
        error: err,
        fileSizeError: err.message.startsWith("File size was too large"),
      },
    });
  }
};
