import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Divider, Icon, Table } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import { ConfirmationModal, Form } from "../../../common/components";
import { List } from "../../../lists/containers";
import styles from "./VerifyRegisteredAgencyCustomerModal.module.scss";

const INITIAL_STATE = {
  mismatchReasonValue: null,
  mismatchReasonDetails: null,
  isCharityNumberCorrect: null,
  correctCharityNumber: null,
};

export default function VerifyRegisteredAgencyCustomerModal(props) {
  const { getAgencyCustomer, agencyCustomer, agencyCustomerId, companiesHouse, loading } = props;

  useEffect(() => {
    // In the initial addition of this modal this won't run because
    // the page will fetch the customer.
    if (!loading && agencyCustomer?.id !== agencyCustomerId) {
      getAgencyCustomer({
        agencyCustomerId: agencyCustomerId,
      });
    }
  }, [getAgencyCustomer, agencyCustomer, agencyCustomerId, loading]);

  const isRegisteredCompanyNumberMatch =
    agencyCustomer?.registered_company_number === companiesHouse?.registeredCompanyNumber;
  const isExactLegalNameMatch =
    agencyCustomer?.legal_name === companiesHouse?.exactLegalName;

  const getInitialMismatchReason = () => {
    if (!isRegisteredCompanyNumberMatch && isExactLegalNameMatch) {
      return "typo_on_company_number";
    }
    if (isRegisteredCompanyNumberMatch && !isExactLegalNameMatch) {
      return "user_omitted_the_word_limited_ltd";
    }
    if (!isRegisteredCompanyNumberMatch && !isExactLegalNameMatch) {
      return "other";
    }
    return null;
  };

  const [formData, setFormData] = useState({
    ...INITIAL_STATE,
    mismatchReasonValue: getInitialMismatchReason(),
  });

  const renderMatch = (isMatch) => {
    return isMatch ? (
      <Icon name="check circle outline" color="green" size="large" />
    ) : (
      <Icon name="times circle outline" color="red" size="large" />
    );
  };

  const handleMismatchReasonChange = e =>
    setFormData({
      ...formData,
      mismatchReasonValue: e.target.value,
      mismatchReasonDetails:
        e.target.value === "other" ? formData.mismatchReasonDetails : null,
    });

  const canSubmit = () => {
    if (!isRegisteredCompanyNumberMatch || !isExactLegalNameMatch) {
      if (formData.mismatchReasonValue === "other" && !formData.mismatchReasonDetails) {
        return false;
      }
    }

    if (agencyCustomer.is_registered_charity) {
      if (formData.isCharityNumberCorrect === null) {
        return false;
      }
      if (formData.isCharityNumberCorrect === false && !formData.correctCharityNumber) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    await props.verifyRegisteredAgencyCustomer({
      agencyCustomerId,
      legal_name: companiesHouse.exactLegalName,
      registered_company_number: companiesHouse.registeredCompanyNumber,
      registered_charity_number: formData.correctCharityNumber,
      mismatchReasonValue: formData.mismatchReasonValue,
      mismatchReasonDetails: formData.mismatchReasonDetails,
    });

    props.hideModal(VerifyRegisteredAgencyCustomerModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(VerifyRegisteredAgencyCustomerModal.displayName);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title={
        <>
          Verify Legal Entity for {agencyCustomer.agency?.known_as ?? agencyCustomer.prospect?.known_as}
          {!agencyCustomer.agency && agencyCustomer.prospect && (
            <span className={styles.prospectTag}> Prospect</span>
          )}
        </>
      }
      submitText="Verify"
      cancelText="Cancel"
      submitDisabled={props.loading || !canSubmit()}
      busy={props.submitting}
      visible={props.visible}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      scrolling
    >
      <div className={styles.line}>
        Please verify that the selected Legal Entity is correct.
      </div>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <div className={styles.subHeading}>Verify Companies House Details</div>
          <Table className={styles.table} basic="very">
            <Table.Header className={styles.headerRow}>
              <Table.HeaderCell />
              <Table.HeaderCell className={styles.headerCell}>
                Registered Company Number
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.headerCell}>
                Exact Legal Name
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.labelCell}>
                  Entered by user
                </Table.Cell>
                <Table.Cell>{agencyCustomer?.registered_company_number}</Table.Cell>
                <Table.Cell>{agencyCustomer?.legal_name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.labelCell}>
                  Selected from Companies House
                </Table.Cell>
                <Table.Cell>{companiesHouse?.registeredCompanyNumber}</Table.Cell>
                <Table.Cell>{companiesHouse?.exactLegalName}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.labelCell}>Match?</Table.Cell>
                <Table.Cell>
                  {renderMatch(isRegisteredCompanyNumberMatch)}
                </Table.Cell>
                <Table.Cell>{renderMatch(isExactLegalNameMatch)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider className={styles.divider} />
          {(!isRegisteredCompanyNumberMatch || !isExactLegalNameMatch) && (
            <Form className={styles.mismatchReason}>
              <Form.Group inline>
                <Form.Label className={styles.label} inline>
                  Reason for verifying a mis-matched Legal Entity
                </Form.Label>
                <List
                  identifier="verify_mismatch_reasons"
                  value={formData.mismatchReasonValue}
                  onChange={handleMismatchReasonChange}
                  disabled={props.submitting}
                />
              </Form.Group>
              {formData.mismatchReasonValue === "other" && (
                <Form.Group inline>
                  <Form.Label className={styles.moreDetailsLabel} inline>
                    More details
                  </Form.Label>
                  <Form.TextArea
                    className={styles.textArea}
                    value={formData.mismatchReasonDetails}
                    rows={6}
                    maxLength={255}
                    disabled={props.submitting}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        mismatchReasonDetails: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              )}
            </Form>
          )}

          {agencyCustomer?.is_registered_charity && (
            <>
              <div className={styles.subHeading}>Verify Charity Number</div>
              <Table className={styles.table} basic="very">
                <Table.Header className={styles.headerRow}>
                  <Table.HeaderCell />
                  <Table.HeaderCell className={styles.headerCell}>
                    Is it a Charity?
                  </Table.HeaderCell>
                  <Table.HeaderCell className={styles.headerCell}>
                    Charity Number
                  </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className={styles.labelCell}>
                      Entered by user
                    </Table.Cell>
                    <Table.Cell>
                      Yes
                    </Table.Cell>
                    <Table.Cell>{agencyCustomer?.registered_charity_number}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Divider className={styles.divider} />
              <Form>
                <Form.Group inline className={styles.group}>
                  <Form.Label inline required>
                    Is the Charity Number correct?
                  </Form.Label>
                  <Form.RadioContainer>
                    <Form.Radio
                      value={true}
                      name="isCharityNumberCorrect"
                      checked={formData.isCharityNumberCorrect === true}
                      text="Yes"
                      disabled={props.submitting}
                      onChange={() =>
                        setFormData({ ...formData, isCharityNumberCorrect: true })
                      }
                    />
                    <Form.Radio
                      value={false}
                      name="isCharityNumberCorrect"
                      checked={formData.isCharityNumberCorrect === false}
                      text="No"
                      disabled={props.submitting}
                      onChange={() =>
                        setFormData({ ...formData, isCharityNumberCorrect: false, correctCharityNumber: null })
                      }
                    />
                  </Form.RadioContainer>
                </Form.Group>

                {formData.isCharityNumberCorrect === false && (
                  <Form.Group inline className={styles.group}>
                    <Form.Label inline required>
                      Correct Charity Number
                    </Form.Label>
                    <Form.Input
                      name="correctCharityNumber"
                      value={formData.correctCharityNumber}
                      maxLength={255}
                      disabled={props.submitting}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          correctCharityNumber: e.target.value,
                        })
                      }
                      fluid
                    />
                  </Form.Group>
                )}
                <br/>
              </Form>
            </>
          )}
        </>
      )}
    </ConfirmationModal>
  );
}

VerifyRegisteredAgencyCustomerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  agencyCustomerId: PropTypes.string.isRequired,
  agencyCustomer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    agency: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }),
    prospect: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }),
    display_name: PropTypes.string.isRequired,
    legal_name: PropTypes.string.isRequired,
    registered_company_number: PropTypes.string.isRequired,
    is_registered_charity: PropTypes.string.isRequired,
    registered_charity_number: PropTypes.string,
  }),
  companiesHouse: PropTypes.shape({
    registeredCompanyNumber: PropTypes.string,
    exactLegalName: PropTypes.string,
  }),
  getAgencyCustomer: PropTypes.func.isRequired,
  verifyRegisteredAgencyCustomer: PropTypes.func.isRequired,
};

VerifyRegisteredAgencyCustomerModal.displayName = "VerifyRegisteredAgencyCustomerModal";
