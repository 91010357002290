import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { TextLink } from "../../";
import "./BreadcrumbItem.scss";

class BreadcrumbItem extends PureComponent {

    static defaultProps = {
      active: false,
    }

    static propTypes = {
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      active: PropTypes.bool,
      className: PropTypes.string,
      onClick: PropTypes.func,
    }

    render() {
      const { link, text, className, active, onClick } = this.props;
      return (
        <TextLink className={classnames("BreadcrumbItem", active && "active", className)} onClick={onClick} to={link} text={text} />
      )
      ;
    }
}

export default BreadcrumbItem;
