import gql from "graphql-tag";
import {
  handleAgencyCustomerVerificationRequiredTask,
  handleCreditApplicationResultRequiredTask,
  handleCreditApplicationRequiredTask,
  handleCreditRedistributionRequiredTask,
  handleCreditRequestCommunicationRequiredTask,
  handleCreditRequestDistributionRequiredTask,
  handleCreditReviewRequiredTask,
  handleExperianCreditCheckRequiredTask,
} from "./";
import { apolloClient } from "../../../../apolloClient";

const GET_CREDIT_LIMIT_MANAGEMENT_TASK = gql`
query($id: ID!) {
  getCreditLimitManagementTask(id: $id) {
    id
    task_type
    data
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.creditLimitManagementTaskId
 */
export const handleCreditLimitManagementTask = obj => async (dispatch) => {
  try {
    const response = await apolloClient.query({
      query: GET_CREDIT_LIMIT_MANAGEMENT_TASK,
      variables: {
        id: obj.creditLimitManagementTaskId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const task = {
      ...response.data.getCreditLimitManagementTask,
      data: JSON.parse(response.data.getCreditLimitManagementTask.data),
    };

    switch (task.task_type) {
      case "AGENCY_CUSTOMER_VERIFICATION_REQUIRED":
        dispatch(handleAgencyCustomerVerificationRequiredTask(task));
        break;

      case "CREDIT_APPLICATION_REQUIRED":
        dispatch(handleCreditApplicationRequiredTask(task));
        break;

      case "CREDIT_APPLICATION_RESULT_REQUIRED":
        dispatch(handleCreditApplicationResultRequiredTask(task));
        break;

      case "CREDIT_REDISTRIBUTION_REQUIRED":
        dispatch(handleCreditRedistributionRequiredTask(task));
        break;

      case "CREDIT_REQUEST_COMMUNICATION_REQUIRED":
        dispatch(handleCreditRequestCommunicationRequiredTask(task));
        break;

      case "CREDIT_REQUEST_DISTRIBUTION_REQUIRED":
        dispatch(handleCreditRequestDistributionRequiredTask(task));
        break;

      case "EXPERIAN_CREDIT_CHECK_REQUIRED":
        dispatch(handleExperianCreditCheckRequiredTask(task));
        break;

      case "CREDIT_LIMIT_REVIEW_REQUIRED":
        dispatch(handleCreditReviewRequiredTask(task));
        break;

      default:
        // eslint-disable-next-line no-console
        console.error("Could not handle task type", task);
        break;
    }
  }
  catch (err) {
    // eslint-disable-next-line no-console
    console.error("Failed to handle credit limit management task", err);
  }
};
