import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AgencyContractInformationFormValidator from "./AgencyContractInformationFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
  SeeHistoryTooltip,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./AgencyContractInformationForm.module.scss";

export default class AgencyContractInformationForm extends Component {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    agencyContractInformation: PropTypes.shape({
      contract: PropTypes.shape({
        start_date: PropTypes.instanceOf(Date),
        contract_length: PropTypes.number,
        contract_period: PropTypes.string,
        date_first_traded: PropTypes.instanceOf(Date),
        fee: PropTypes.number,
        funding_type: PropTypes.string,
      }),
      is_new_agency: PropTypes.bool,
      is_ledger_buyout: PropTypes.bool,
      introduced_via_broker: PropTypes.bool,
    }).isRequired,
    disabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    history: PropTypes.shape({
      contract_start_date: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {
        contract_start_date: props.agencyContractInformation?.contract.start_date,
        length_of_contract: {
          contract_length: props.agencyContractInformation?.contract.contract_length,
          // Though in onboarding this is required, an imported agency may have "null" for these
          // so we default to "months".
          contract_period: props.agencyContractInformation?.contract.contract_period || "months",
        },
        date_first_traded: props.agencyContractInformation?.contract.date_first_traded,
        contract_fee: props.agencyContractInformation?.contract.fee,
        contract_funding_type: props.agencyContractInformation?.contract.funding_type,
        is_ledger_buyout: props.agencyContractInformation?.is_new_agency
          ? false
          : props.agencyContractInformation?.is_ledger_buyout,
        introduced_via_broker: props.agencyContractInformation?.introduced_via_broker,
      },
      errors: {},
    };
  }

  render() {
    const { disabledFields, history, onDisabledFieldClick, submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <div className={styles.container}>
        <Form className={styles.form}>
          <Form.Section title="Contract Information" underlined>
            <Form.Group inline wide  error={errors?.contract_start_date}>
              <Form.Label>
                <SeeHistoryTooltip variant="compact" data={history?.contract_start_date}>
                  Contract Start Date
                </SeeHistoryTooltip>
              </Form.Label>
              <Form.Date
                name="contract_start_date"
                value={data.contract_start_date}
                size="small"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields?.includes("contract_start_date")}
                showLock={disabledFields?.includes("contract_start_date")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("contract_start_date")}
                highlightError={errors?.contract_start_date}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.length_of_contract}>
              <Form.Label>Length Of Contract</Form.Label>
              <Form.DatePeriod
                name="length_of_contract"
                period={data.length_of_contract.contract_period}
                value={data.length_of_contract.contract_length}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields?.includes("length_of_contract")}
                showLock={disabledFields?.includes("length_of_contract")}
                onChange={this.handleContractLengthChange}
                onLockClick={() => onDisabledFieldClick("length_of_contract")}
                highlightError={errors?.length_of_contract}
              />
            </Form.Group>
            <Form.Group inline wide>
              <Form.Label inline note="(Optional)">Date First Traded</Form.Label>
              <Form.Date
                name="date_first_traded"
                value={data.date_first_traded}
                size="small"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields?.includes("date_first_traded")}
                showLock={disabledFields?.includes("date_first_traded")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("date_first_traded")}
              />
            </Form.Group>
            <Form.Group inline wide>
              <Form.Label inline note="(Optional)">Fee %</Form.Label>
              <Form.Number
                name="contract_fee"
                value={data.contract_fee}
                size="small"
                decimal
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields?.includes("contract_fee")}
                showLock={disabledFields?.includes("contract_fee")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("contract_fee")}
              />
            </Form.Group>
            <Form.Group inline wide>
              <Form.Label inline note="(Optional)">Funding Type</Form.Label>
              <Form.Input
                name="contract_funding_type"
                value={data.contract_funding_type}
                size="small"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields?.includes("contract_funding_type")}
                showLock={disabledFields?.includes("contract_funding_type")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("contract_funding_type")}
              />
            </Form.Group>
            <Form.Group className={styles.radioGroup} inline wide>
              <Form.Label className={styles.radioLabel} inline>Introduced via Broker?</Form.Label>
              <div>
                <Form.Radio
                  value={true}
                  name="introduced_via_broker"
                  checked={data.introduced_via_broker}
                  text="Yes"
                  onChange={this.handleRadioChange}
                />
                <Form.Radio
                  value={false}
                  name="introduced_via_broker"
                  checked={data.introduced_via_broker === false}
                  text="No"
                  onChange={this.handleRadioChange}
                />
              </div>
            </Form.Group>
            <Form.Group className={styles.radioGroup} inline wide>
              <Form.Label className={styles.radioLabel} inline>Ledger Buy-out?</Form.Label>
              <div>
                <Form.Radio
                  value={true}
                  name="is_ledger_buyout"
                  checked={data.is_ledger_buyout}
                  text="Yes"
                  disabled={this.props.agencyContractInformation?.is_new_agency}
                  onChange={this.handleRadioChange}
                />
                <Form.Radio
                  value={false}
                  name="is_ledger_buyout"
                  checked={data.is_ledger_buyout === false}
                  text="No"
                  disabled={this.props.agencyContractInformation?.is_new_agency}
                  onChange={this.handleRadioChange}
                />
              </div>
            </Form.Group>
          </Form.Section>
        </Form>
        <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
          <>
            <Button
              variant="primary"
              className={styles.button}
              onClick={this.handleSaveClicked}
              busy={submitting}
              disabled={submitting}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              className={styles.button}
              onClick={this.handleCancelClicked}
            >
              Cancel
            </Button>
          </>
        </ButtonGroup>
      </div>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleRadioChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: String(e.target.value) === "true",
      },
    });
  }

  handleContractLengthChange = ({ value, period }) => {
    this.setState({
      data: {
        ...this.state.data,
        length_of_contract: {
          contract_length: value,
          contract_period: period,
        },
      },
    });
  }

  handleCancelClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSaveClicked = () => {
    const { data } = this.state;
    const { onSave } = this.props;

    const validator = new AgencyContractInformationFormValidator(data, this.props);
    const validationResult = (validator && validator.validate()) || { errors: [], success: true };

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) return;

    onSave && onSave({
      ...data,
    });
  }
}
