import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../common/components";
import styles from "./AgencyCustomerAlreadyExistsModal.module.scss";

export default function AgencyCustomerAlreadyExistsModal(props) {
  return (
    <ConfirmationModal
      className={classnames(props.className, styles.container)}
      dimmerClassName={styles.dimmer}
      title="Agency Customer already exists"
      submitText="Okay"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div>
        This agency customer already exists within Optia.
      </div>
      <div>
        Do you want to use the existing agency customer?
      </div>
    </ConfirmationModal>
  );
}

AgencyCustomerAlreadyExistsModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
