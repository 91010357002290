import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const VALIDATE_PAYROLL_TRANSFER = gql`
query(
  $agency_id: ID!
) {
  validatePayrollTransfer(id: $agency_id) {
    success
    results {
      message
      fields {
        group
        fields
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 *
 */
export const validatePayrollTransfer = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.VALIDATE_PAYROLL_TRANSFER_PENDING,
    });

    const response = await apolloClient.query({
      query: VALIDATE_PAYROLL_TRANSFER,
      variables: {
        agency_id: agencyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.VALIDATE_PAYROLL_TRANSFER_SUCCESS,
      payload: {
        data: {
          payrollTransferValidation: response.data.validatePayrollTransfer,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.VALIDATE_PAYROLL_TRANSFER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
