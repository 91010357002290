import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PROSPECT_DISABLED_FIELDS = gql`
query($prospect_id: ID!, $filter_options: ProspectDisabledFieldsFilterOptions) {
  getProspect(id: $prospect_id) {
    disabled_fields(filter_options: $filter_options) {
      disabled_field
      prospect_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.filterOptions
 */
export const getProspectDisabledFields = ({ prospectId, filterOptions }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECT_DISABLED_FIELDS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PROSPECT_DISABLED_FIELDS,
      variables: {
        prospect_id: prospectId,
        filter_options: filterOptions,
      },
    });

    if (!response.data.getProspect) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_PROSPECT_DISABLED_FIELDS_SUCCESS,
      payload: {
        prospectDisabledFields: response.data.getProspect.disabled_fields,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_PROSPECT_DISABLED_FIELDS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
