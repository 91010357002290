import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./AppInfo.module.scss";

export default function AppInfo(props) {
  return (
    <div className={classnames(props.className, styles.container)}>
      <span className={styles.buildNumber}>
        {props.buildNumber}
      </span>
    </div>
  );
}

AppInfo.propTypes = {
  className: PropTypes.string,
  buildNumber: PropTypes.string.isRequired,
};
