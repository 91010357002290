import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Grid } from "semantic-ui-react";
import { Form } from "../../../../../common/components";
import { toMoney } from "../../../../../../utils";
import styles from "./CreditCard.module.scss";

export default function CreditCard(props) {
  const getMoneyValue = (value) => {
    if (_.isNil(value)) {
      return "-";
    }

    return toMoney(value);
  };

  return (
    <Form.Card
      className={styles.container}
      title={(
        <>
          Credit
          <span className={styles.caption}>(Incl. VAT)</span>
        </>
      )}
    >
      {props.loading ? (
        <ActivityIndicator />
      ) : (
        <Grid columns={2} relaxed>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.value}>
              Credit Limit
            </Grid.Column>
            <Grid.Column className={styles.value} textAlign="right">
              {getMoneyValue(props.creditLimit)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.value}>
              Distributed
            </Grid.Column>
            <Grid.Column className={styles.value} textAlign="right">
              {!_.isNil(props.creditLimit)
                ? getMoneyValue(props.creditLimit - props.undistributedCredit)
                : "-"}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.emphasised}>
              Undistributed
            </Grid.Column>
            <Grid.Column className={styles.emphasised} textAlign="right">
              {getMoneyValue(props.undistributedCredit)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Form.Card>
  );
}

CreditCard.propTypes = {
  loading: PropTypes.bool,
  creditLimit: PropTypes.number,
  undistributedCredit: PropTypes.number,
};
