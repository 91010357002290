import gql from "graphql-tag";

export const GET_WORKERS_LISTVIEW = gql`
query {
  getWorkers {
    id
    first_name
    last_name
    updated_at
    status
  }
}
`;

export const ADD_WORKER = data => gql`
    mutation {
        addWorker(
            cognito_username:"${data.cognito_username}"
            status:"${data.status}"
            first_name:"${data.first_name}"
            last_name:"${data.last_name}"
            email:"${data.email}"
            agency_id:"${data.agency_id}"
        ) {
            id
            cognito_username
            type
            status
            first_name
            last_name
            email
            created_at
            updated_at
        }
    }
`;

export const DISABLE_WORKER = id => gql`
    mutation {
        disableUser(id: ${id}) {
            id
        }
    }
`;
