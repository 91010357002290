import { ProspectDocumentVersionHistoryModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.prospectId
 * @param {object} obj.documentType
 *
 * @returns
 */
export const showProspectDocumentVersionHistoryModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(ProspectDocumentVersionHistoryModal.displayName, {
    prospectId: obj.prospectId,
    documentType: obj.documentType,
  }));
};
