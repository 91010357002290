import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { AgencyHistoryList } from "../../components";
import "./HistoryTab.scss";

export class HistoryTab extends Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    agencyId: PropTypes.string.isRequired,
    agencyHistory: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      document_id: PropTypes.string.isRequired,
      document: PropTypes.string.isRequired,
      operation: PropTypes.string.isRequired,
      revision: PropTypes.string.isRequired,
      created_at: PropTypes.number.isRequired,
    })),
    getAgencyHistory: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    const { agencyId } = this.props;

    await this.props.getAgencyHistory(agencyId);
  }

  render() {
    const { loading, agencyHistory } = this.props;

    return (
      <div className="AgencyHistoryPage">
        <AgencyHistoryList
          loading={loading}
          revisions={agencyHistory}
        />
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
