import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import {
  Form,
  IconButton,
  MaskedValue,
  SeeHistoryTooltip,
} from "../../../../common/components";
import { formatSortCode } from "utils";
import styles from "./BankInformationSection.module.scss";

export default function BankInformationSection(props) {
  const formattedHistory = () => {
    return props.companyHistory.sort_code?.map(revision => ({
      ...revision,
      value: formatSortCode(revision.value),
    }));
  };

  return (
    <Form.Card
      className={styles.container}
      title="Bank Information"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <Form.Group inline>
        <Form.Label>Sort Code</Form.Label>
        <SeeHistoryTooltip variant="compact" data={formattedHistory()}>
          <MaskedValue value={props.sortCode} maskOptions={ { mask: "99-99-99" } } style={ { width: "70px" } } />
        </SeeHistoryTooltip>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Account Number</Form.Label>
        <SeeHistoryTooltip variant="compact" data={props.companyHistory?.account_number}>
          <Form.ValueInline>{props.accountNumber || "-"}</Form.ValueInline>
        </SeeHistoryTooltip>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Payee Name</Form.Label>
        <Form.ValueInline>{props.payeeName || "-"}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Bank Name</Form.Label>
        <Form.ValueInline>{props.bankName || "-"}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Branch</Form.Label>
        <Form.ValueInline>{props.branch || "-"}</Form.ValueInline>
      </Form.Group>
    </Form.Card>
  );
}

BankInformationSection.propTypes = {
  accountNumber: PropTypes.string,
  bankName: PropTypes.string,
  branch: PropTypes.string,
  companyHistory: PropTypes.shape({
    account_number: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
    sort_code: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  payeeName: PropTypes.string,
  sortCode: PropTypes.string,
  onEditClick: PropTypes.func,
};
