import React from "react";
import PropTypes from "prop-types";
import styles from "./DeletedDocumentAlert.module.scss";
import { Alert } from "../../../common/components";

export default function DeletedDocumentAlert() {
  return (
    <div className={styles.alertContainer}>
      <Alert className={styles.alert} showClose={false}>Deleted</Alert>
      <div className={styles.name}>Alicia Stanger</div>
      <div className={styles.date}>Today</div>
    </div>
  );
}

DeletedDocumentAlert.propTypes = {
  startDate: PropTypes.number,
  dueDate: PropTypes.number,
};

DeletedDocumentAlert.defaultProps = {
  startDate: (new Date()).getTime(),
};
