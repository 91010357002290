import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const SEARCH_COMPANIES_HOUSE = gql`
query(
  $namePager: Pager
  $numberPager: Pager
  $nameSearch: CompaniesHouseSearch
  $numberSearch: CompaniesHouseSearch
) {
  searchByName: searchCompaniesHouse(pager: $namePager, search: $nameSearch) {
    total
    data {
      company_number
      company_status
      name
      incorporated_date
      address_snippet
    }
    existing_agencies {
      id
      company_number
    }
    existing_prospects {
      id
      company_number
    }
  }

  searchByNumber: searchCompaniesHouse(pager: $numberPager, search: $numberSearch) {
    total
    data {
      company_number
      company_status
      name
      incorporated_date
      address_snippet
    }
    existing_agencies {
      id
      company_number
    }
    existing_prospects {
      id
      company_number
    }
  }
}
`;

const getNameResultsFromResponse = (response) => {
  return response.data.searchByName || { total: 0, data: [] };
};

const getNumberResultsFromResponse = (response, companyNumber) => {
  if (!response.data.searchByNumber) {
    return { total: 0, data: [] };
  }

  const results = response.data.searchByNumber.data
    .filter(result => result.company_number.includes(companyNumber));

  return {
    ...response.data.searchByNumber,
    total: results.length,
    data: results,
  };
};

const defaultOptions = {
  companyNumber: "",
  companyName: "",
  page: 1,
  limit: 25,
};

export const searchCompaniesHouse = (options = defaultOptions) => async (dispatch) => {
  try {
    dispatch({
      type: types.SEARCH_COMPANIES_HOUSE_PENDING,
    });

    const response = await apolloClient.query({
      query: SEARCH_COMPANIES_HOUSE,
      variables: {
        namePager: {
          page: options.page,
          limit: options.limit,
        },
        numberPager: {
          page: 0,
          limit: 1,
        },
        nameSearch: {
          name: options.companyName,
        },
        numberSearch: {
          company_number: options.companyNumber,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const nameResults = getNameResultsFromResponse(response);
    const numberResults = getNumberResultsFromResponse(response, options.companyNumber);

    dispatch({
      type: types.SEARCH_COMPANIES_HOUSE_SUCCESS,
      payload: {
        nameResults,
        numberResults,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.SEARCH_COMPANIES_HOUSE_FAILURE,
      payload: {
        error,
      },
    });
  }
};
