import _ from "lodash";

export default class CompaniesHouseSearchFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (_.isEmpty(data?.registeredCompanyNumber)) {
      errors.registeredCompanyNumber = "Registered Company Number is required";
    }

    if (_.isEmpty(data?.exactLegalName)) {
      errors.exactLegalName = "Exact Legal Name is required";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
