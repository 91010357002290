import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { AgencyHeading } from "../../components";
import AgencyBankInformation from "./AgencyBankInformation";
import AgencyBranch from "./AgencyBranch";
import AgencyContractInformation from "./AgencyContractInformation";
import AgencyDetails from "./AgencyDetails";
import AgencyPreferences from "./AgencyPreferences";
import AgencyTradingAddress from "./AgencyTradingAddress";
import AgencyVatInformation from "./AgencyVatInformation";
import AutoEnrolmentPensions from "./AutoEnrolmentPensions";
import HmrcOnline from "./HmrcOnline";
import PayrollGeneral from "./PayrollGeneral";
import WtrHoliday from "./WtrHoliday";
import styles from "./AgencyEditPage.module.scss";

export default class AgencyEditPage extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agency: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
      logo_url: PropTypes.string,
      opera_version: PropTypes.string,
      opera_company: PropTypes.string,
    }),
    getAgency: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { agencyId, agency, getAgency } = this.props;

    if (agency?.id !== agencyId) {
      await getAgency(agencyId);
    }
  }

  render() {
    return (
      <div>
        {this.renderAgencyInfo()}
        <Switch>
          <Route exact path={"/agencies/:agencyId/edit/agency-details"} component={AgencyDetails} />
          <Route exact path={"/agencies/:agencyId/edit/trading-address"} component={AgencyTradingAddress} />
          <Route exact path={"/agencies/:agencyId/edit/bank-information"} component={AgencyBankInformation} />
          <Route exact path={"/agencies/:agencyId/edit/contract-information"} component={AgencyContractInformation} />
          <Route exact path={"/agencies/:agencyId/edit/branch/:branchId"} component={AgencyBranch} />
          <Route exact path={"/agencies/:agencyId/edit/payroll/agency-preferences"} component={AgencyPreferences} />
          <Route exact path={"/agencies/:agencyId/edit/payroll/auto-enrolment-pensions"} component={AutoEnrolmentPensions} />
          <Route exact path={"/agencies/:agencyId/edit/payroll/general"} component={PayrollGeneral} />
          <Route exact path={"/agencies/:agencyId/edit/payroll/hmrc-online"} component={HmrcOnline} />
          <Route exact path={"/agencies/:agencyId/edit/payroll/wtr-holiday"} component={WtrHoliday} />
          <Route exact path={"/agencies/:agencyId/edit/payroll/vat-information"} component={AgencyVatInformation} />
        </Switch>
      </div>
    );
  }

  renderAgencyInfo = () => {
    const { agency } = this.props;

    return (
      <AgencyHeading
        className={styles.header}
        loading={!agency}
        agency={{
          shortCode: agency?.short_code,
          name: agency?.known_as,
          logoUrl: agency?.logo_url,
          operaInfo: {
            version: agency?.opera_version,
            company: agency?.opera_company,
          },
        }}
      />
    );
  }
}
