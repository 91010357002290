import { CreateCreditApplicationModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} legalEntityId the id of the legal entity to create a credit application for
 */
export const showCreateCreditApplicationModal = ({ legalEntityId }) => async (dispatch) => {
  dispatch(modalActions.showModal(CreateCreditApplicationModal.displayName, {
    legalEntityId,
  }));
};
