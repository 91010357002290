import moment from "moment";

const parseDateTime = (date, format = "DD/MM/YYYY, HH:mm:ss") => {
  if (!date) {
    return "";
  }
  return moment(date).format(format);
};

export default parseDateTime;
