import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DELETE_PROSPECT_CONTACT = gql`
mutation($contact_id: ID!) {
  deleteProspectContact(id: $contact_id) {
    id
    first_name
    last_name
    email_address
    telephone_number
    job_role
    is_primary_contact
    ni_number
    archived
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.contactId
 */
export const deleteProspectContact = ({ contactId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.DELETE_PROSPECT_CONTACT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: DELETE_PROSPECT_CONTACT,
      variables: {
        contact_id: contactId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.DELETE_PROSPECT_CONTACT_SUCCESS,
      payload: {
        prospectContact: response.data.deleteProspectContact,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.DELETE_PROSPECT_CONTACT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
