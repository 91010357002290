import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_VAT_INFORMATION = gql`
mutation(
  $agency_id: ID!
  $input: UpdateAgencyVatInformationInput!
) {
  updateAgencyVatInformation(agency_id: $agency_id, input: $input) {
    vat_number
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @param {object} obj.data
 * @param {bool} obj.data.applying_registered_vat
 * @param {string} obj.vat_number
 * @param {string} obj.data.vat_frequency
 * @param {string} obj.data.vat_period_ending_date
 * @param {string} obj.redirectUrl
 *
 */
export const updateAgencyVatInformation = ({ agencyId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_VAT_INFORMATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_AGENCY_VAT_INFORMATION,
      variables: {
        agency_id: agencyId,
        input: {
          ...data,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_VAT_INFORMATION_SUCCESS,
      payload: {
        agency: {
          vat: response.data.updateAgencyVatInformation,
        },
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_VAT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
