import React, { useState } from "react";
import styles from "./MultiFieldFilterFieldRow.module.scss";
import { KeyboardArrowDown } from "@material-ui/icons";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import classnames from "classnames";
import { Form } from "../../../components";

export default function MultiFieldFilterFieldRow(props) {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  if (props.field.possibleValues?.length === 0) {
    return null;
  }

  return (
    <Grid.Row className={styles.popupContentRow}>
      <div className={classnames(styles.popupContentRowContainer, styles.spacious)}>
        <span className={styles.fieldName}>{props.field.label}</span>
        <KeyboardArrowDown className={classnames(styles.expandFieldIcon, isRowExpanded? styles.rotatedIcon : null)} onClick={() => setIsRowExpanded(!isRowExpanded)}/>
      </div>
      {isRowExpanded && (
        <div className={styles.filterList}>
          {props.field.possibleValues.map(value => (
            <Form.Checkbox
              key={value.value}
              label={value.displayValue ?? value.value}
              name={`${props.field.label}:${value.value}`}
              checked={value.checked}
              onChange={props.onChange}
            />
          ))}
        </div>
      )}
    </Grid.Row>
  );
}

MultiFieldFilterFieldRow.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    possibleValues: PropTypes.arrayOf({
      value: PropTypes.string,
      displayValue: PropTypes.string,
      checked: PropTypes.bool,
    }),
  }),
  onChange: PropTypes.func.isRequired,
};
