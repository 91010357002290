import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_CREDIT_REQUEST = gql`
query(
  $legal_entity_id: ID!
  $credit_request_id: ID!
) {
  getLegalEntity(id: $legal_entity_id) {
    credit_request(id: $credit_request_id) {
      id
      agency {
        id
        known_as
      }
      prospect {
        id
        known_as
      }
      credit_requested
      credit_distributed
      date_communicated
      status
      is_agency_customer_verified
      created_at
      credit_request_communications {
        id
        address_type
        contact {
          first_name
          last_name
          email
        }
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {object} obj.creditRequestId
 * @returns
 */
export const getCreditRequest = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CREDIT_REQUEST_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_CREDIT_REQUEST,
      variables: {
        legal_entity_id: obj.legalEntityId,
        credit_request_id: obj.creditRequestId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CREDIT_REQUEST_SUCCESS,
      payload: {
        data: response.data.getLegalEntity.credit_request,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_REQUEST_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
