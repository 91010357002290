import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_DATA_MISMATCH_COUNT = gql`
query(
  $pager: Pager!
) {
  getDataMismatches(pager: $pager) {
    total
  }
}
`;

export const getDataMismatchCount = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_DATA_MISMATCH_COUNT_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_DATA_MISMATCH_COUNT,
      variables: {
        pager: {
          page: 1,
          limit: 0,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_DATA_MISMATCH_COUNT_SUCCESS,
      payload: {
        data: response.data.getDataMismatches.total,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_DATA_MISMATCH_COUNT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
