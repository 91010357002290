import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import EditLegalEntityPage from "./EditLegalEntityPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
  currentUser: state.currentUser.user,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditLegalEntityPage);
