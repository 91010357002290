import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_INSURER_REPORTING_ACTIVITIES = gql`
query(
  $legal_entity_id: ID!
  $filter_options: InsurerReportingActivitiesFilterOptions
) {
  getLegalEntity(id: $legal_entity_id) {
    insurer_reporting_activities(filter_options: $filter_options) {
      id
      reported_by {
        first_name
        last_name
      }
      reported_at
      report_reason {
        label
      }
    }
  }
}
`;

export const getInsurerReportingActivities = ({ legal_entity_id, filter_options }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_INSURER_REPORTING_ACTIVITIES_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_INSURER_REPORTING_ACTIVITIES,
      variables: {
        legal_entity_id,
        filter_options,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_INSURER_REPORTING_ACTIVITIES_SUCCESS,
      payload: {
        insurerReportingActivities: response.data.getLegalEntity.insurer_reporting_activities,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_INSURER_REPORTING_ACTIVITIES_FAILURE,
      payload: {
        error,
      },
    });
  }
};
