import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ViewAsTaskPage from "./ViewAsTaskPage";

const mapStateToProps = state => ({
  ...state.onboarding,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    onFetchOnboardingTask: actions.getOnboardingTask,
    onFetchOnboardingTasks: actions.getOnboardingTasks,
  }, dispatch),
  onGoToAgencyTask: ({ onboardingTaskId, agencyId, agencyOnboardingTaskId }) => {
    dispatch(push(`/onboarding/tasks/${onboardingTaskId}/${agencyId}/${agencyOnboardingTaskId}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAsTaskPage);
