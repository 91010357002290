import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import CommunicateToAgencyModal from "./CommunicateToAgencyModal";

const mapStateToProps = state => ({
  loading:
    state.creditLimitManagement.agencyContacts.loading ||
    state.creditLimitManagement.creditRequest.loading,
  contacts: state.creditLimitManagement.agencyContacts.data,
  creditRequest: state.creditLimitManagement.creditRequest.data,
  creditController: state.creditLimitManagement.agencyContacts.creditController,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(CommunicateToAgencyModal);

container.displayName = "CommunicateToAgencyModal";

export default container;
