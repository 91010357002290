import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import {
  Breadcrumb,
  Button,
  Form,
  Heading,
  Input,
} from "../../../common/components";
import { List } from "../../../lists/containers";
import styles from "./NewUserPage.module.scss";

export default class NewUserPage extends Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    departments: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    userGroups: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })).isRequired,
    }),
    error: PropTypes.string.isRequired,
    getDepartments: PropTypes.func.isRequired,
    getUserGroups: PropTypes.func.isRequired,
    addUser: PropTypes.func.isRequired,
    gotoUsers: PropTypes.func.isRequired,
  }

  state = {
    firstName: "",
    lastName: "",
    email: "",
    userType: null,
    userTypes: [],
    departmentId: null,
    userGroups: [],
  }

  async componentDidMount() {
    await this.props.getDepartments();
    await this.props.getUserGroups();
  }

  render() {
    const isLoading = _.some([
      this.props.departments,
      this.props.userGroups,
    ], x => x.loading);

    if (isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <div className={styles.container}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item link="/manage/users" text="User Management" />
          <Breadcrumb.Item link="/manage/users/new" text="New User" />
        </Breadcrumb>
        <Heading title="New User" />
        <div className={styles.content}>
          <div className={styles.subHeader}>
            New User Set Up
          </div>
          <div className={styles.subTitle}>
            {"Tell us who you’re adding and what type of access to give them. "}
            {"Once your new user is added, we’ll send them the details."}
          </div>
          <Form className={styles.form}>
            <Form.Section
              title="Employee Details"
              rightAccessory={(
                <div className={styles.allFieldsRequired}>
                  All fields are required
                </div>
              )}
            >
              <Form.Group inline>
                <Form.Label inline>First Name</Form.Label>
                <Form.Value>
                  <Input
                    name="firstName"
                    value={this.state.firstName}
                    type="text"
                    onChange={this.handleInputChanged}
                    required
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group inline>
                <Form.Label inline>Last Name</Form.Label>
                <Form.Value>
                  <Input
                    name="lastName"
                    value={this.state.lastName}
                    type="text"
                    onChange={this.handleInputChanged}
                    required
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group inline>
                <Form.Label inline>Email Address</Form.Label>
                <Form.Value>
                  <Input
                    name="email"
                    value={this.state.email}
                    type="email"
                    onChange={this.handleInputChanged}
                    required
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group inline>
                <Form.Label inline>User Type</Form.Label>
                <Form.Value>
                  <List
                    identifier="user_types"
                    name="userType"
                    value={this.state.userType}
                    search
                    fluid
                    onReady={(list) => {
                      this.setState({
                        userType: this.state.userType || list.data.values.find(x => x.is_default).value,
                        userTypes: list.data.values,
                      });
                    }}
                    onChange={this.handleInputChanged}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group inline>
                <Form.Label inline>Select Department</Form.Label>
                <Form.Value>
                  <Form.Select
                    name="departmentId"
                    value={this.state.departmentId}
                    placeholder="Select Department..."
                    options={[
                      {
                        label: "No department",
                        value: 0,
                      },
                      ...this.props.departments.data.map(value => ({
                        label: value.name,
                        value: value.id,
                      })),
                    ]}
                    search
                    fluid
                    onChange={this.handleInputChanged}
                  />
                </Form.Value>
              </Form.Group>
            </Form.Section>
            <Form.Section title="Permissions">
              <Form.Group inline>
                <Form.Label inline>Select Permission Groups</Form.Label>
                <Form.Value>
                  <Form.Select
                    name="userGroups"
                    placeholder="Select Permission Groups..."
                    value={this.state.userGroups}
                    options={this.props.userGroups.data.map(value => ({
                      label: value.name,
                      value: value.id,
                    }))}
                    search
                    multiple
                    fluid
                    onChange={this.handleInputChanged}
                  />
                </Form.Value>
              </Form.Group>
            </Form.Section>
          </Form>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            variant="primary"
            busy={this.props.saving}
            disabled={!this.canSubmit() || this.props.saving}
            onClick={this.handleSubmitClicked}
          >
            Create User
          </Button>
          <Button
            className={styles.button}
            variant="outline-danger"
            disabled={this.props.saving}
            onClick={this.handleCancelClicked}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  handleSubmitClicked = async () => {
    const userTypeId = this.state.userTypes.find(x => x.value === this.state.userType)?.id;

    await this.props.addUser({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      userTypeId,
      departmentId: this.state.departmentId > 0 ? this.state.departmentId : null,
      userGroups: this.state.userGroups,
    });
  }

  handleCancelClicked = () => {
    this.props.gotoUsers();
  }

  handleInputChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  canSubmit = () => {
    if (_.isEmpty(this.state.firstName)) return false;
    if (_.isEmpty(this.state.lastName)) return false;
    if (_.isEmpty(this.state.email)) return false;
    if (_.isEmpty(this.state.userType)) return false;
    if (_.isEmpty(this.state.departmentId)) return false;
    if (_.isEmpty(this.state.userGroups)) return false;
    return true;
  }

}
