import React, { useState } from "react";
import PropTypes from "prop-types";
import OnboardingEntityListItem from "./OnboardingEntityListItem/OnboardingEntityListItem";
import styles from "./OnboardingEntityList.module.scss";

export default function OnboardingEntityList(props) {
  const [expandedEntityId, setExpandedEntityId] = useState(null);

  return (
    <div className={styles.container}>
      {props.data.map(onboardingEntity => (
        <OnboardingEntityListItem
          className={styles.onboardingEntity}
          key={onboardingEntity.entity.id}
          onExpandToggle={id => setExpandedEntityId(id === expandedEntityId ? null : id)}
          isExpanded={onboardingEntity.entity.id === expandedEntityId}
          showCompletedTasks={props.showCompletedTasks}
          onViewClick={props.onViewTaskClick}
          {...onboardingEntity}
        />
      ))}
    </div>
  );
}

OnboardingEntityList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    entity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      entityType: PropTypes.oneOf(["agency", "prospect"]),
    }),
    tasks: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string.isRequired,
    })),
  })),
  showCompletedTasks: PropTypes.bool,
  onViewTaskClick: PropTypes.func.isRequired,
};
