import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_ONBOARDING_TASK = gql`
query(
  $onboarding_task_id: ID!
) {
  getOnboardingTask(id: $onboarding_task_id) {
    id
    name
    outstanding_agencies {
      id
      status
      agency {
        id
        name
      }
      progress {
        required_completed
        required_total
      }
      due_date
    }
  }
}
`;

const GET_ONBOARDING_TASK_AND_AGENCY = gql`
query(
  $onboarding_task_id: ID!
  $agency_id: ID!
) {
  getOnboardingTask(id: $onboarding_task_id) {
    id
    name
    completed_agencies {
      status
      agency {
        known_as
      }
    }
    outstanding_agencies {
      id
      status
      agency {
        id
        short_code
        known_as
      }
      progress {
        required_completed
        required_total
      }
      due_date
    }
  }

  getAgency(id: $agency_id) {
    id
    short_code
    known_as
    is_new_agency
    is_existing_paye_scheme
    introduced_via_broker
    worker_types {
      name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.onboardingTaskId
 * @param {number} obj.agencyId
 * @returns
 */
export const getOutstandingAgenciesWithOnboardingTask = ({
  onboardingTaskId,
  agencyId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_PENDING,
    });

    const query = agencyId
      ? GET_ONBOARDING_TASK_AND_AGENCY
      : GET_ONBOARDING_TASK;

    const response = await apolloClient.query({
      query,
      variables: {
        onboarding_task_id: onboardingTaskId,
        agency_id: agencyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_SUCCESS,
      payload: {
        onboardingTask: response.data.getOnboardingTask,
        agency: response.data.getAgency,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
