import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import ContractInformationFormValidator from "./ContractInformationFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./ContractInformationForm.module.scss";

export default class ContractInformationForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      introduced_via_broker: PropTypes.bool,
      is_ledger_buyout: PropTypes.bool,
      is_new_agency: PropTypes.bool,
      contract: PropTypes.shape({
        start_date: PropTypes.number,
        contract_length: PropTypes.number,
        contract_period: PropTypes.string,
        date_first_traded: PropTypes.number,
        fee: PropTypes.number,
        funding_type: PropTypes.string,
      }),
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        prospect_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        introduced_via_broker: props.data?.introduced_via_broker,
        is_ledger_buyout: props.data?.is_ledger_buyout,
        contract_start_date: props.data?.contract?.start_date,
        length_of_contract: {
          contract_length: props.data?.contract?.contract_length,
          contract_period: props.data?.contract?.contract_period,
        },
        date_first_traded: props.data?.contract?.date_first_traded,
        fee: props.data?.contract?.fee,
        funding_type: props.data?.contract?.funding_type,
      },
      errors: {},
    };
  }

  render() {
    const { submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={14}>
          <Form className={styles.form}>
            <Form.Section title="Contract Information" titleClassName={styles.title}>
              <Form.Group inline wide error={errors?.contract_start_date}>
                <Form.Label inline>Contract Start Date</Form.Label>
                <Form.Date
                  name="contract_start_date"
                  value={data.contract_start_date}
                  size="medium"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("contract_start_date")}
                  showLock={this.isFieldDisabled("contract_start_date")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("contract_start_date")}
                  highlightError={errors?.contract_start_date}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.length_of_contract}>
                <Form.Label inline>Length of Contract</Form.Label>
                <Form.DatePeriod
                  name="length_of_contract"
                  value={data?.length_of_contract?.contract_length}
                  period={data?.length_of_contract?.contract_period}
                  size="medium"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("length_of_contract")}
                  showLock={this.isFieldDisabled("length_of_contract")}
                  onChange={this.handleDatePeriodChange}
                  onLockClick={() => this.handleDisabledFieldClick("length_of_contract")}
                  highlightError={errors?.length_of_contract}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.date_first_traded}>
                <Form.Label inline note="(Optional)">Date First Traded</Form.Label>
                <Form.Date
                  name="date_first_traded"
                  value={data.date_first_traded}
                  size="medium"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("date_first_traded")}
                  showLock={this.isFieldDisabled("date_first_traded")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("date_first_traded")}
                  highlightError={errors?.date_first_traded}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.fee}>
                <Form.Label inline note="(Optional)">Fee %</Form.Label>
                <Form.Number
                  name="fee"
                  value={data.fee}
                  size="medium"
                  decimal
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("fee")}
                  showLock={this.isFieldDisabled("fee")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("fee")}
                  highlightError={errors?.fee}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.funding_type}>
                <Form.Label inline note="(Optional)">Funding Type</Form.Label>
                <Form.Input
                  name="funding_type"
                  value={data.funding_type}
                  size="medium"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("funding_type")}
                  showLock={this.isFieldDisabled("funding_type")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("funding_type")}
                  highlightError={errors?.funding_type}
                />
              </Form.Group>
              <Form.Group className={styles.radioGroup} inline wide error={errors?.introduced_via_broker}>
                <Form.Label className={styles.radioLabel} inline>Introduced via Broker?</Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="introduced_via_broker"
                    checked={data.introduced_via_broker}
                    text="Yes"
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="introduced_via_broker"
                    checked={!data.introduced_via_broker}
                    text="No"
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
              <Form.Group className={styles.radioGroup} inline wide error={errors?.is_ledger_buyout}>
                <Form.Label className={styles.radioLabel} inline>Ledger Buyout?</Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="is_ledger_buyout"
                    checked={data.is_ledger_buyout}
                    text="Yes"
                    disabled={this.props.data?.is_new_agency}
                    onChange={this.handleIsLedgerBuyoutChange}
                  />
                  <Form.Radio
                    value={false}
                    name="is_ledger_buyout"
                    checked={data.is_ledger_buyout === false}
                    text="No"
                    disabled={this.props.data?.is_new_agency}
                    onChange={this.handleIsLedgerBuyoutChange}
                  />
                  <Form.Radio
                    value={null}
                    name="is_ledger_buyout"
                    checked={_.isNull(data.is_ledger_buyout)}
                    text="Unknown"
                    disabled={this.props.data?.is_new_agency}
                    onChange={this.handleIsLedgerBuyoutChange}
                  />
                </div>
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={submitting}
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleDatePeriodChange = ({ value, period }) => {
    this.setState({
      data: {
        ...this.state.data,
        length_of_contract: {
          contract_length: value,
          contract_period: period,
        },
      },
    });
  }

  handleRadioChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: String(e.target.value) === "true",
      },
    });
  }

  handleIsLedgerBuyoutChange = (e) => {
    let value;

    switch (e.target.value) {
      case "true":
        value = true;
        break;

      case "false":
        value = false;
        break;

      case null:
        value = null;
        break;

      default:
        value = null;
    }

    this.setState({
      data: {
        ...this.state.data,
        is_ledger_buyout: value,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const validator = new ContractInformationFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    const dataExcludingDisabledFields = _.omitBy(data, (_value, key) => {
      return this.isFieldDisabled(key);
    });

    onSubmit && onSubmit(dataExcludingDisabledFields);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick && onDisabledFieldClick(disabledField);
  }

  isFieldDisabled = (fieldName) => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
