import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { NewProspectForm } from "../../components";
import { Breadcrumb, Heading } from "../../../common/components";
import styles from "./NewRegisteredProspectPage.module.scss";

export default class NewRegisteredProspectPage extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      block: PropTypes.func.isRequired,
    }).isRequired,
    companyNumber: PropTypes.string.isRequired,
    companiesHouseInfo: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        companyProfile: PropTypes.shape({
          company_name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          company_number: PropTypes.string.isRequired,
          accounts: PropTypes.shape({
            last_accounts: PropTypes.shape({
              made_up_to: PropTypes.string.isRequired,
            }),
          }),
          company_status: PropTypes.string.isRequired,
          company_status_detail: PropTypes.string,
          previous_company_names: PropTypes.shape({
            ceased_on: PropTypes.string.isRequired,
            effective_from: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
          registered_office_address: PropTypes.shape({
            address_line_1: PropTypes.string.isRequired,
            address_line_2: PropTypes.string.isRequired,
            locality: PropTypes.string.isRequired,
            postal_code: PropTypes.string.isRequired,
          }),
        }).isRequired,
        certificateOfIncorporationUrl: PropTypes.string,
      }),
    }),
    businessDevelopmentManagers: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array.isRequired,
    }).isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool,
    }),
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showUnsavedChangesModal: PropTypes.func.isRequired,
    getCompaniesHouseInfoForNewProspect: PropTypes.func.isRequired,
    getBusinessDevelopmentManagers: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getCompaniesHouseInfoForNewProspect({
      companyNumber: this.props.companyNumber,
    });
    this.props.getBusinessDevelopmentManagers();

    this.unblock = this.props.history.block(({ pathname }) => {
      this.props.showUnsavedChangesModal({
        onLeaveClick: () => {
          window.onbeforeunload = null;
          this.unblock();
          this.props.history.push(pathname);
        },
      });

      return false;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  render() {
    return (
      <div>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item link="/prospects" text="Prospects" />
          <Breadcrumb.Item link="/onboarding/new/prospects" text="Add New Prospect" />
        </Breadcrumb>
        <Heading title="Add New Prospect" />
        {this.props.businessDevelopmentManagers.loading ? (
          <ActivityIndicator />
        ) : (
          <NewProspectForm
            className={styles.form}
            loading={this.props.companiesHouseInfo.loading}
            submitting={this.props.ui.submitting}
            businessDevelopmentManagers={this.props.businessDevelopmentManagers.data}
            companyProfile={this.props.companiesHouseInfo.data?.companyProfile}
            certificateOfIncorporationUrl={this.props.companiesHouseInfo.data?.certificateOfIncorporationUrl}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        )}
      </div>
    );
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = (data) => {
    const { onSubmit } = this.props;

    this.unblock();

    onSubmit && onSubmit({
      companyNumber: this.props.companiesHouseInfo.data.companyProfile.company_number,
      legalName: this.props.companiesHouseInfo.data.companyProfile.company_name,
      ...data,
    });
  }

}
