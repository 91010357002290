import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Label from "../Label";
import "./DropDownItem.scss";

class DropDownItem extends PureComponent {

    static defaultProps = {
      selected: false,
    };

    static propTypes = {
      value: PropTypes.string.isRequired,
      children: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
    };

    render() {
      const selected = this.props.selected && "selected";
      return (
        <div onClick={this.props.onClick} className={classnames("DropDownItem",selected)}>
          <Label title={this.props.children}>{this.props.children}</Label>
        </div>
      );
    }
}

export default DropDownItem;
