import React from "react";
import PropTypes from "prop-types";
import { Lock } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import { formatAnswer } from "../../../../../utils";
import styles from "./HMRCOnlineSection.module.scss";

export default function HMRCOnlineSection(props) {
  return (
    <Form.Card
      className={styles.container}
      title="HMRC Online"
      rightAccessory={(
        <IconButton
          className={styles.locked}
          icon={<Lock />}
          tooltip={tooltips.NOT_AVAILABLE_TO_PROSPECTS_TOOLTIP}
        />
      )}
    >
      <Form.Group inline>
        <Form.Label inline>Existing PAYE Scheme?</Form.Label>
        <Form.ValueInline>{formatAnswer(props.existingPayeScheme, "Unknown")}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>PAYE Scheme Reference</Form.Label>
        <Form.ValueInline>{props.payeSchemeReference || "-"}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>Accounts Office Reference</Form.Label>
        <Form.ValueInline>{props.accountsOfficeReference || "-"}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>Back Office to apply for Employment Allowance?</Form.Label>
        <Form.ValueInline>{formatAnswer(props.claimingEmployersNiAllowance, "-")}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>Entitled to Small Employers Relief?</Form.Label>
        <Form.ValueInline>{formatAnswer(props.smallEmployersRelief, "-")}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>Back Office to do Employment Intermediary Reporting (EIR)?</Form.Label>
        <Form.ValueInline>{formatAnswer(props.intermediaryReporting, "-")}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>Does the Apprenticeship Levy apply?</Form.Label>
        <Form.ValueInline>-</Form.ValueInline>
      </Form.Group>
    </Form.Card>
  );
}

HMRCOnlineSection.propTypes = {
  accountsOfficeReference: PropTypes.string,
  claimingEmployersNiAllowance: PropTypes.bool,
  existingPayeScheme: PropTypes.bool,
  intermediaryReporting: PropTypes.bool,
  payeSchemeReference: PropTypes.string,
  smallEmployersRelief: PropTypes.bool,
};
