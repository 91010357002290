export default class NonRegisteredCompanyDetailsFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (!data.telephone && !data.emailAddress) {
      const message = "A Telephone Number or Email Address is required";
      errors.telephone = message;
      errors.emailAddress = message;
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
