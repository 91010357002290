import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  GetApp,
  CloseRounded,
  InsertDriveFileRounded,
} from "@material-ui/icons";
import { formatBytes, parseDate } from "../../../../utils";
import styles from "./DocumentDownloadCard.module.scss";

export default function DocumentDownloadCard(props) {
  return (
    <div
      className={classnames(
        props.className,
        styles.container,
        props.disabled && styles.disabled,
      )}
    >
      <div className={styles.fileContainer}>
        <InsertDriveFileRounded className={styles.fileIcon} />
        <div className={styles.file}>
          <div className={styles.fileName}>
            {props.fileName}
          </div>
          <div className={styles.fileDetails}>
            <div className={styles.fileSize}>
              {formatBytes(props.fileSize)}
            </div>
            <div className={styles.uploadDate}>
              Uploaded {parseDate(props.uploadDate)}
            </div>
          </div>
        </div>
      </div>
      <GetApp className={styles.downloadIcon} onClick={props.onDownload} />
      {props.showDeleteButton && (
        <CloseRounded className={styles.deleteIcon} onClick={props.onDelete} />
      )}
    </div>
  );
}

DocumentDownloadCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  uploadDate: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func.isRequired,
};
