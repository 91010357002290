export default class BackOfficeContactModalValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (!data?.payrollAccountManagerId) {
      errors.payrollAccountManagerId = "Payroll Account Manager is required";
    }

    if (!data?.creditControllerId) {
      errors.creditControllerId = "Credit Controller is required";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
