import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_BACK_OFFICE_CONTACTS = gql`
mutation(
  $agency_id: ID!
  $payroll_account_manager_id: ID!
  $payroll_account_manager_buddy_id: ID
  $credit_controller_id: ID!
  $credit_controller_buddy_id: ID
) {
  payroll_account_manager: updateAgencyPayrollAccountManager(agency_id: $agency_id, user_id: $payroll_account_manager_id) {
    id
    first_name
    last_name
  }

  payroll_account_manager_buddy: updateAgencyPayrollAccountManagerBuddy(agency_id: $agency_id, user_id: $payroll_account_manager_buddy_id) {
    id
    first_name
    last_name
  }

  credit_controller: updateAgencyCreditController(agency_id: $agency_id, user_id: $credit_controller_id) {
    id
    first_name
    last_name
  }

  credit_controller_buddy: updateAgencyCreditControllerBuddy(agency_id: $agency_id, user_id: $credit_controller_buddy_id) {
    id
    first_name
    last_name
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @param {string} obj.payrollAccountManagerId
 * @param {string} obj.payrollAccountManagerBuddyId
 * @param {string} obj.creditControllerId
 * @param {string} obj.creditControllerBuddyId
 */
export const updateBackOfficeContacts = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_BACK_OFFICE_CONTACTS_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_BACK_OFFICE_CONTACTS,
      variables: {
        agency_id: obj.agencyId,
        payroll_account_manager_id: obj.payrollAccountManagerId,
        payroll_account_manager_buddy_id: obj.payrollAccountManagerBuddyId,
        credit_controller_id: obj.creditControllerId,
        credit_controller_buddy_id: obj.creditControllerBuddyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_BACK_OFFICE_CONTACTS_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.UPDATE_BACK_OFFICE_CONTACTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
