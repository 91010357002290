import _ from "lodash";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { ConfirmationModal, Form } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./CompleteCreditReviewTaskModal.module.scss";
import CompleteCreditReviewTaskModalValidator from "./CompleteCreditReviewTaskModalValidator";

export default function CompleteCreditReviewTaskModal (props) {
  const [formData, setFormData] = useState({
    setNewReview: false,
    nextReviewDate: null,
    comments: "",
  });
  const [errors, setErrors] = useState({});

  const { getCreditReviewDates, legalEntityId } = props;

  useEffect(() => {
    getCreditReviewDates({
      legalEntityId,
    });
  }, [getCreditReviewDates, legalEntityId]);

  const canSubmit = () => {
    if (formData.setNewReview && (_.isNil(formData.nextReviewDate) || _.isNaN(formData.nextReviewDate))) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const nextReviewDates = props.creditReviewDates.map(reviewDate => reviewDate.next_review_date);

    const validator = new CompleteCreditReviewTaskModalValidator(formData, nextReviewDates, formData.setNewReview);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.completeCreditReview({
      creditReviewId: props.creditReviewId,
    });

    if (formData.setNewReview) {
      props.createCreditReviewDate({
        legalEntityId,
        dateOfNextReview: formData.nextReviewDate,
        comment: formData.comments,
      });
    }

    props.hideModal(CompleteCreditReviewTaskModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(CompleteCreditReviewTaskModal.displayName);
  };

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleRadioChange = e => setFormData({
    ...formData,
    [e.target.name]: String(e.target.value) === "true",
  });

  return (
    <ConfirmationModal
      title="Date of Next Review"
      visible={props.visible}
      className={styles.container}
      submitText="Complete Task"
      submitDisabled={!canSubmit()}
      busy={props.loading}
      onSubmit={handleSubmit}
      onCancel={handleCancel}>
      {props.loading ? (<ActivityIndicator />) : (
        <>
          <div className={styles.modalDescription}>
            You can add a new Date of Next Review before completing this task below.
          </div>
          {props.creditReviewDates?.length > 1 && (
            <>
              <div className={styles.upcomingDatesTitle}>
                Upcoming Review Dates
              </div>
              <div>
                {props.creditReviewDates.map((creditReviewDate) => {
                  return creditReviewDate.id === String(props.creditReviewId) ? null : (
                    <div className={styles.upcomingReviewDate} key={creditReviewDate.id}>
                      {parseDate(creditReviewDate.next_review_date)}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <Form requiredKey>
            <Form.Section title="Add a new Date of Next Review" className={styles.formSection}>
              <Form.Group inline wide>
                <Form.Label className={styles.label} inline required>
                  Would you like to set a new Next Review Date?
                </Form.Label>
                <div>
                  <Form.Radio
                    name="setNewReview"
                    value={true}
                    checked={formData.setNewReview}
                    text="Yes"
                    onChange={handleRadioChange}
                  />
                  <Form.Radio
                    name="setNewReview"
                    value={false}
                    checked={!formData.setNewReview}
                    text="No"
                    onChange={handleRadioChange}
                  />
                </div>
              </Form.Group>

              {formData.setNewReview && (
                <>
                  <Form.Group inline wide error={errors?.nextReviewDate}>
                    <Form.Label className={styles.label} inline required>
                      New Date of Next Review
                    </Form.Label>
                    <Form.Date
                      name="nextReviewDate"
                      value={formData.nextReviewDate}
                      size="small"
                      highlightError={errors?.nextReviewDate}
                      onChange={handleFormChange}
                      min={parseDate(Date.now(), "YYYY-MM-DD")}/>
                  </Form.Group>

                  <Form.Group inline wide>
                    <Form.Label className={styles.commentsLabel} inline>
                      Comments
                    </Form.Label>
                    <Form.TextArea
                      name="comments"
                      className={styles.textArea}
                      value={formData.comments}
                      placeholder="Enter comments..."
                      rows={6}
                      maxLength={255}
                      onChange={handleFormChange}/>
                  </Form.Group>
                </>
              )}
            </Form.Section>
          </Form>
        </>
      )}
    </ConfirmationModal>
  );
}

CompleteCreditReviewTaskModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  creditReviewDates: PropTypes.arrayOf({
    id: PropTypes.string,
    next_review_date: PropTypes.number,
  }),
  legalEntityId: PropTypes.string,
  creditReviewId: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  completeCreditReview: PropTypes.func.isRequired,
  getCreditReviewDates: PropTypes.func.isRequired,
  createCreditReviewDate: PropTypes.func.isRequired,
};

CompleteCreditReviewTaskModal.displayName = "CompleteCreditReviewTaskModal";
