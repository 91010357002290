import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as modalActions } from "../../../modals/duck";
import { actions as documentActions } from "../../../documents/duck";
import UploadDocumentsModal from "./UploadDocumentsModal";

const mapStateToProps = state => ({
  documentTypes: state.documents.documentTypes,
  submitting: state.documents.ui.submitting,
  loading: state.documents.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...modalActions,
  ...documentActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(UploadDocumentsModal);

container.displayName = "UploadDocumentsModal";

export default container;
