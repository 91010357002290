import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import AddNewSelfBillingOrganisationPage from "./AddNewSelfBillingOrganisationPage";

const mapStateToProps = state => ({
  ...state.creditLimitManagement,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  onCancel: () => {
    dispatch(push("/credit-limit-management"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewSelfBillingOrganisationPage);
