import React from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Table } from "semantic-ui-react";
import styles from "./SelfBillingOrganisationList.module.scss";

export default function SelfBillingOrganisationList(props) {
  if (props.loading) {
    return <ActivityIndicator />;
  }

  return (
    <Table
      className={styles.container}
      basic
    >
      <Table.Header>
        <Table.HeaderCell>
          Legal Entity
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          Credit Limit
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          Total Debt
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          Exposure
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {(props.data?.length ?? []) === 0 ? (
          <Table.Row>
            <Table.Cell colSpan="4" className={styles.noResults}>
              There are no Self-Billing Organisations available to view.
            </Table.Cell>
          </Table.Row>
        ) : <div /> /* TODO: Add SelfBillingOrganisationRow */}
      </Table.Body>
    </Table>
  );
}

SelfBillingOrganisationList.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};
