import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";



export const UPDATE_REGISTERED_COMPANY = gql`
mutation($id: ID!, $input: UpdateRegisteredLegalEntityInput!) {
  updateRegisteredLegalEntity(id: $id, input: $input) {
    id
    name
    company_type
    meta {
      key
      value
    }

    ...on RegisteredCompany {
      company_number
      company_status
      companies_house_info {
        accounts {
          last_accounts {
            made_up_to
          }
        }
        company_name
        company_number
        company_status
        company_status_detail
        previous_company_names {
          name
        }
        registered_office_address {
          address_line_1
          address_line_2
          locality
          region
          postal_code
          country
        }
        type
      }
      registered_charity_number
    }

    ...on RegisteredCharity {
      registered_charity_number
      registered_charity_info {
        registered_charity_name
        charity_address_line_one
        charity_city
        charity_postcode
        charity_status
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.name
 * @param {boolean} obj.isRegisteredCharity
 * @param {string} obj.registeredCharityNumber
 * @param {string} obj.redirectUrl (optional)
 * @returns
 */
export const updateRegisteredCompany = obj => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_REGISTERED_COMPANY_PENDING });

    const response = await apolloClient.query({
      query: UPDATE_REGISTERED_COMPANY,
      variables: {
        id: obj.legalEntityId,
        input: {
          name: obj.name,
          is_registered_charity: obj.isRegisteredCharity,
          registered_charity_number: obj.registeredCharityNumber,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_REGISTERED_COMPANY_SUCCESS,
      payload: {
        legalEntityDetails: response.data.updateRegisteredLegalEntity,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_REGISTERED_COMPANY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
