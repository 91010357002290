import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { MoreVert } from "@material-ui/icons";
import styles from "./ActionPopup.module.scss";

export default class ActionPopup extends Component {

  static propTypes = {
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  };

  state = {
    visible: false,
  }

  constructor(props) {
    super(props);

    this.actionRef = React.createRef();
  }

  state = { visible: false };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside.bind(this));
  }

  render() {
    const { visible } = this.state;
    const { className, children, overlayClassName } = this.props;

    return (
      <div className={classnames(className, styles.container)}>
        <MoreVert className={styles.icon} onClick={() => this.setState({ visible: !visible })} />
        <div className={classnames(overlayClassName, styles.overlay, visible && styles.visible)} ref={this.actionRef}>
          {React.Children.map(children, (element) => {
            return element && (
              <element.type {...element.props} onClick={(e) => {
                element.props.onClick && element.props.onClick(e);
                this.handleItemClick();
              }}>
                {element.props.children}
              </element.type>);
          })}
        </div>
      </div>
    );
  }

  handleItemClick = () => {
    this.setState({ visible: false });
  }

  handleClickOutside(event) {
    const { visible } = this.state;

    if (!visible) return;
    if (!this.actionRef.current) return;
    if (this.actionRef.current.contains(event.target)) return;

    this.setState({ visible: false });
  }

}
