import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormTextArea.module.scss";

export default function FormTextArea({
  className,
  disabled,
  ...otherProps
}) {
  return (
    <textarea
      {...otherProps}
      className={classnames(styles.textArea, className, disabled && styles.disabled)}
      disabled={disabled}
    />
  );
}

FormTextArea.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func,
};
