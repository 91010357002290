import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Add } from "@material-ui/icons";
import { IconButton } from "../../../../../common/components";
import styles from "./MIReportGroup.module.scss";

export default function MIReportGroup(props) {
  return (
    <div
      className={classnames(
        styles.container,
        props.disabled && styles.disabled,
      )}
    >
      <div className={styles.title}>
        {props.title}
      </div>
      {props.children}
      {props.readOnly || (
        <IconButton
          icon={(<Add />)}
          iconPosition="left"
          className={styles.addButton}
          onClick={props.onAdd}
          text={`Add Another ${props.title}`}
        />
      )}
    </div>
  );
}

MIReportGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
};

