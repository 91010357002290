import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormFlexRow.module.scss";

export default function FormFlexRow({
  className,
  align,
  children,
}) {
  return (
    <div className={classnames(styles.container, className, styles[align])}>
      {children}
    </div>
  );
}

FormFlexRow.defaultProps = {
  align: "center",
};

FormFlexRow.propTypes = {
  className: PropTypes.string,
  align: PropTypes.oneOf([
    "top",
    "center",
    "bottom",
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
