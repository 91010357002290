import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import AgencyPreferencesSection from "./AgencyPreferencesSection/AgencyPreferencesSection";
import AutoEnrolmentPensionsSection from "./AutoEnrolmentPensionsSection/AutoEnrolmentPensionsSection";
import GeneralSection from "./GeneralSection/GeneralSection";
import HMRCOnlineSection from "./HMRCOnlineSection/HMRCOnlineSection";
import VATSection from "./VATSection/VATSection";
import WtrHolidaySection from "./WtrHolidaySection/WtrHolidaySection";
import styles from "./PayrollView.module.scss";

export default function PayrollView(props) {
  const hasWtr = props.payrollInfo?.worker_types.some(x => x.name === "PAYE");
  return (
    <div className={classnames(styles.container, props.className)}>
      <div className={styles.grid}>
        <div className={styles.row}>
          <GeneralSection
            workerTypes={props.payrollInfo?.worker_types?.map(x => x.id)}
            onEditClick={props.onEditGeneralClick}
          />
          <AgencyPreferencesSection
            payrollFrequency={props.payrollInfo?.preferences?.payroll_frequency}
            defaultCustomerVatRate={props.payrollInfo?.preferences?.default_customer_vat_rate}
            defaultPayslipPreference={props.payrollInfo?.preferences?.default_payslip_preference}
          />
        </div>
        <div className={classnames(styles.row, styles.sidle)}>
          <VATSection
            applyingRegisteredVat={props.payrollInfo?.applying_registered_vat}
            vatNumber={props.payrollInfo?.vat_number}
            vatPeriodEndingDate={props.payrollInfo?.vat_period_ending_date}
            vatFrequency={props.payrollInfo?.vat_frequency}
            nextVatReturnPeriodEnd={props.payrollInfo?.next_vat_return_period_end}
            vatDocument={props.payrollInfo?.vatDocument}
            isDisabled={!_.isEmpty(props.payrollInfo?.disabledFields)}
            payrollHistory={props.payrollHistory}
            onDownload={props.onDownload}
            onEditClick={props.onEditVatClick}
          />
          {hasWtr
            ? <WtrHolidaySection
              hasHolidayPot={props.payrollInfo?.holiday_pay_info?.has_holiday_pot}
              defaultHolidayScheme={props.payrollInfo?.holiday_pay_info?.default_holiday_scheme?.label}
              holidayAccrualPeriod={props.payrollInfo?.holiday_pay_info?.holiday_accrual_period?.label}
              endOfHolidayYearPayPeriod={props.payrollInfo?.holiday_pay_info?.end_of_holiday_year_pay_period}
              endOfHolidayYearTaxYearEnd={props.payrollInfo?.holiday_pay_info?.end_of_holiday_year_tax_year_end}
              canCarryOverAccrual={props.payrollInfo?.holiday_pay_info?.can_carry_over_accrual}
              payrollHistory={props.payrollHistory}
              onEditClick={props.onEditWtrHolidayClick}
            />
            : <HMRCOnlineSection
              accountsOfficeReference={props.payrollInfo?.hmrc_info?.accounts_office_reference}
              claimingEmployersNiAllowance={props.payrollInfo?.hmrc_info?.claiming_employers_ni_allowance}
              existingPayeScheme={props.payrollInfo?.is_existing_paye_scheme}
              intermediaryReporting={props.payrollInfo?.hmrc_info?.intermediary_reporting}
              payeSchemeReference={props.payrollInfo?.hmrc_info?.paye_scheme_reference}
              smallEmployersRelief={props.payrollInfo?.hmrc_info?.small_employers_relief}
            />
          }
        </div>
        <div className={classnames(styles.row, styles.sidle)}>
          {hasWtr
            ? <>
                <HMRCOnlineSection
                  accountsOfficeReference={props.payrollInfo?.hmrc_info?.accounts_office_reference}
                  claimingEmployersNiAllowance={props.payrollInfo?.hmrc_info?.claiming_employers_ni_allowance}
                  existingPayeScheme={props.payrollInfo?.is_existing_paye_scheme}
                  intermediaryReporting={props.payrollInfo?.hmrc_info?.intermediary_reporting}
                  payeSchemeReference={props.payrollInfo?.hmrc_info?.paye_scheme_reference}
                  smallEmployersRelief={props.payrollInfo?.hmrc_info?.small_employers_relief}
                />
                <AutoEnrolmentPensionsSection
                  isNewAgency={props.payrollInfo?.is_new_agency}
                  defaultPostponementMonths={props.payrollInfo?.auto_enrolment_pension_info?.defaultPostponementMonths}
                  eligibleJobHolderPostponementMonths={props.payrollInfo?.auto_enrolment_pension_info?.eligible_job_holder_postponement_months}
                  employerPensionReference={props.payrollInfo?.auto_enrolment_pension_info?.employer_pension_reference}
                  pensionCompany={props.payrollInfo?.auto_enrolment_pension_info?.pension_company}
                  pensionDocument={props.payrollInfo?.pensionDocument}
                  pensionScheme={props.payrollInfo?.auto_enrolment_pension_info?.pension_scheme}
                  stagingDate={props.payrollInfo?.auto_enrolment_pension_info?.staging_date}
                  previousPensionProvider={props.payrollInfo?.previous_pension_provider?.label}
                  onDownload={props.onDownload}
                  onEditClick={props.onEditAutoEnrolmentPensionsClick}
                />
              </>
            : <>
                <AutoEnrolmentPensionsSection
                  isNewAgency={props.payrollInfo?.is_new_agency}
                  defaultPostponementMonths={props.payrollInfo?.auto_enrolment_pension_info?.defaultPostponementMonths}
                  eligibleJobHolderPostponementMonths={props.payrollInfo?.auto_enrolment_pension_info?.eligible_job_holder_postponement_months}
                  employerPensionReference={props.payrollInfo?.auto_enrolment_pension_info?.employer_pension_reference}
                  pensionCompany={props.payrollInfo?.auto_enrolment_pension_info?.pension_company}
                  pensionDocument={props.payrollInfo?.pensionDocument}
                  pensionScheme={props.payrollInfo?.auto_enrolment_pension_info?.pension_scheme}
                  stagingDate={props.payrollInfo?.auto_enrolment_pension_info?.staging_date}
                  previousPensionProvider={props.payrollInfo?.previous_pension_provider?.label}
                  onDownload={props.onDownload}
                  onEditClick={props.onEditAutoEnrolmentPensionsClick}
                />
                <div></div>
              </>
          }
        </div>
      </div>
    </div>
  );
}

PayrollView.propTypes = {
  className: PropTypes.string,
  payrollInfo: PropTypes.shape({
    is_new_agency: PropTypes.bool.isRequired,
    auto_enrolment_pension_info: PropTypes.shape({
      pension_company: PropTypes.string,
      pension_scheme: PropTypes.string,
      staging_date: PropTypes.number,
      default_postponement_months: PropTypes.number,
      eligible_job_holder_postponement_months: PropTypes.number,
      employer_pension_reference: PropTypes.string,
    }),
    holiday_pay_info: PropTypes.shape({
      has_holiday_pot: PropTypes.bool,
      default_holiday_scheme: PropTypes.string,
      holiday_accrual_period: PropTypes.string,
      end_of_holiday_year_pay_period: PropTypes.number,
      end_of_holiday_year_tax_year_end: PropTypes.number,
      can_carry_over_accrual: PropTypes.bool,
    }),
    hmrc_info: PropTypes.shape({
      paye_scheme_reference: PropTypes.string,
      accounts_office_reference: PropTypes.string,
      claiming_employers_ni_allowance: PropTypes.bool,
      small_employers_relief: PropTypes.bool,
      intermediary_reporting: PropTypes.bool,
    }),
    preferences: PropTypes.shape({
      payroll_frequency: PropTypes.string,
      default_payslip_preference: PropTypes.string,
      default_customer_vat_rate: PropTypes.number,
    }),
    is_existing_paye_scheme: PropTypes.bool,
    applying_registered_vat: PropTypes.bool,
    previous_pension_provider: PropTypes.shape({
      label: PropTypes.string,
    }),
    worker_types: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
    pensionDocument: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    vatDocument: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    disabledFields: PropTypes.arrayOf(PropTypes.shape({
      disabled_field: PropTypes.string.isRequired,
      prospect_onboarding_task: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    })),
  }).isRequired,
  payrollHistory: PropTypes.object,
  onDownload: PropTypes.func.isRequired,
  onEditAutoEnrolmentPensionsClick: PropTypes.func,
  onEditGeneralClick: PropTypes.func,
  onEditWtrHolidayClick: PropTypes.func,
  onEditVatClick: PropTypes.func,
};
