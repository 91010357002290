import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { HmrcOnlineForm } from "../../../components";
import styles from "./HmrcOnline.module.scss";

export default class HmrcOnline extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    hmrcInfo: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
      submitting: PropTypes.bool,
    }),
    onFetchHmrcInfo: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { agencyId, onFetchHmrcInfo } = this.props;

    await onFetchHmrcInfo({ agencyId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.hmrcInfo?.loading ? (
          <ActivityIndicator />
        ) : (
          <HmrcOnlineForm
            data={this.props.hmrcInfo?.data}
            submitting={this.props.hmrcInfo?.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onDisabledFieldClick={this.handleDisabledFieldClick}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { agencyId, onCancel } = this.props;

    await onCancel(agencyId);
  }

  handleSubmit = async (data) => {
    const { agencyId, onSubmit } = this.props;

    await onSubmit({ agencyId, data });
  }

  handleDisabledFieldClick = async (disabledField) => {
    const { agencyId, onDisabledFieldClick } = this.props;

    await onDisabledFieldClick({ agencyId, disabledField });
  }

}
