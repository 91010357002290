import _ from "lodash";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { PaginatedResponseFormatter } from "../../../../formatters";
import { SortOrderConverter, KeyCaseConverter } from "../../../../converters";

export const GET_PROSPECTS = gql`
query(
  $pager: Pager!
  $sort_order: [SortOrder]!
  $search_options: ProspectSearchOptions
) {
  getProspects(
    pager: $pager
    sort_order: $sort_order
    search_options: $search_options
  ) {
    total
    data {
      id
      known_as
      status
      legal_name
      company_number
      primary_contact {
        first_name
        last_name
        email_address
        preferred_contact_number {
          phone_number
        }
      }
    }
  }
}
`;

const defaultOptions = {
  pager: {
    page: 1,
    limit: 25,
  },
  sortOrder: {},
  searchText: "",
};

export const getProspects = (options = defaultOptions) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECTS_PENDING,
      payload: {
        searchText: options.searchText,
      },
    });

    const response = await apolloClient.query({
      query: GET_PROSPECTS,
      variables: {
        pager: options.pager,
        sort_order: SortOrderConverter(KeyCaseConverter.toSnakeCase(options.sortOrder)),
        search_options: {
          known_as: options.searchText,
          exact_legal_name: options.searchText,
          company_number: options.searchText,
          email_address: options.searchText,
          telephone_number: options.searchText,
          first_name: options.searchText,
          last_name: options.searchText,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const pagination = PaginatedResponseFormatter.format(
      response.data.getProspects,
      options.pager.page,
      options.pager.limit
    );

    dispatch({
      type: types.GET_PROSPECTS_SUCCESS,
      payload: {
        prospects: response.data.getProspects.data.map(prospect => KeyCaseConverter.toCamelCase({
          ...prospect,
          contactName: _.chain([ prospect.primary_contact?.first_name, prospect.primary_contact?.last_name])
            .compact()
            .join(" ")
            .trim()
            .value(),
          telephoneNumber: prospect.primary_contact?.preferred_contact_number?.phone_number,
          emailAddress: prospect.primary_contact?.email_address,
        })),
        ...pagination,
        searchText: options.searchText,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_PROSPECTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
