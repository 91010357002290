import gql from "graphql-tag";
import { getActiveCreditApplications } from "./";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const CANCEL_CREDIT_APPLICATION = gql`
mutation($input: CancelCreditApplicationInput!) {
  cancelCreditApplication(input:$input) {
    id
    reference_number
    credit_limit_requested
    status
    date_of_request
    date_of_result
    created_at
    created_by {
        id
        first_name
        last_name
    }
    comments {
      id
      created_by {
        first_name
        last_name
      }
      comment
      type
      created_at
    }
    legal_entity {
      id
      credit_limit_excluding_vat
      credit_limit_including_vat

      ...on RegisteredCompany {
        companies_house_info {
          accounts {
            next_due
          }
        }
      }
    }
  }
}

`;
/**
 * @param  {string} obj.credit_application_id
 * @param  {string} obj.notes (optional)
 */
export const cancelCreditApplication = ({ credit_application_id, notes = "" }) => async (dispatch) => {
  try {
    dispatch({ type: types.CANCEL_CREDIT_APPLICATION_PENDING });
    const response = await apolloClient.mutate({
      mutation: CANCEL_CREDIT_APPLICATION,
      variables: {
        input: {
          credit_application_id,
          notes,
        },
      },
    });

    if (response.errors) {
      throw Error(response.errors[0].message);
    }

    dispatch({
      type: types.CANCEL_CREDIT_APPLICATION_SUCCESS,
      payload: {
        creditApplication: response.data.cancelCreditApplication,
      },
    });

    // Update the list of active credit applications
    const legalEntityId = response.data.cancelCreditApplication.legal_entity.id;
    dispatch(getActiveCreditApplications({ legalEntityId }));

  } catch (error) {
    dispatch({
      type: types.CANCEL_CREDIT_APPLICATION_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
