import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ResetPasswordCodeFormValidator from "./ResetPasswordCodeFormValidator";
import { Button, Form } from "../../../common/components";
import styles from "./ResetPasswordCodeForm.module.scss";

class ResetPasswordCodeForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    submitting: PropTypes.bool,
    email: PropTypes.string.isRequired,
    onSendVerificationCode: PropTypes.func.isRequired,
    onSubmitVerificationCode: PropTypes.func.isRequired,
  }

  state = {
    newPassword: "",
    confirmNewPassword: "",
    verificationCode: "",
    errors: {},
  }

  render() {
    return (
      <Form className={classnames(styles.container, this.props.className)}>
        <Form.Group>
          <Form.Label htmlFor="email">
            Email Address
          </Form.Label>
          <Form.Input
            name="email"
            type="email"
            value={this.props.email}
            fluid
            disabled
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.newPassword}>
          <Form.Label htmlFor="newPassword">
            New Password
          </Form.Label>
          <Form.Input
            name="newPassword"
            type="password"
            value={this.state.newPassword}
            onChange={this.handleInputChange}
            fluid
            highlightError={this.state.errors?.newPassword}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.confirmNewPassword}>
          <Form.Label htmlFor="confirmNewPassword">
            Confirm New Password
          </Form.Label>
          <Form.Input
            name="confirmNewPassword"
            type="password"
            value={this.state.confirmNewPassword}
            onChange={this.handleInputChange}
            fluid
            highlightError={this.state.errors?.confirmNewPassword}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.verificationCode}>
          <Form.Label htmlFor="verificationCode">
            Verification Code
          </Form.Label>
          <Form.Input
            name="verificationCode"
            type="text"
            value={this.state.verificationCode}
            onChange={this.handleInputChange}
            fluid
            highlightError={this.state.errors?.verificationCode}
          />
        </Form.Group>
        <Button
          className={styles.submitButton}
          onClick={this.handleSubmitVerificationCode}
          disabled={this.props.submitting}
          busy={this.props.submitting}
        >
          Reset Password & Sign In
        </Button>
      </Form>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmitVerificationCode = () => {
    const { email, onSubmitVerificationCode } = this.props;
    const { newPassword, verificationCode } = this.state;

    const validator = new ResetPasswordCodeFormValidator(this.state);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmitVerificationCode(email, newPassword, verificationCode);
  }
}

export default ResetPasswordCodeForm;
