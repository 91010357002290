import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_USERS = gql`
query($pager: Pager, $sort_order: [SortOrder]) {
  getUsers(pager: $pager, sort_order: $sort_order) {
    id
    first_name
    last_name
    email
    department {
      name
    }
  }
}
`;

/**
 *
 * @param {object} obj (optional)
 * @param {object} obj.pager
 * @param {number} obj.pager.page
 * @param {number} obj.pager.limit
 * @param {number} obj.sortOrder
 */
export const getUsers = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_USERS_PENDING,
    });

    const pager = {
      page: obj?.pager?.page || 1,
      limit: obj?.pager?.limit || 1000,
    };

    const response = await apolloClient.query({
      query: GET_USERS,
      variables: {
        pager,
        sort_order: SortOrderConverter(obj?.sortOrder || {}),
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_USERS_SUCCESS,
      payload: {
        users: response.data.getUsers,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_USERS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
