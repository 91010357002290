import React from "react";
import PropTypes from "prop-types";
import styles from "./AgencyLogo.module.scss";

export default function AgencyLogo(props) {
  const url = props.logoUrl ?? `https://eu.ui-avatars.com/api/?name=${props.name?.split(" ").join("+")}`;

  return (
    <img
      className={styles.logo}
      src={url}
      alt="agency logo"
    />
  );
}

AgencyLogo.propTypes = {
  name: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};
