import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { OnboardingEntityList } from "../../components";
import {
  Checkbox,
  Heading,
  ItemCountSelector,
  Pager,
  SearchInput,
} from "../../../common/components";
import styles from "./ViewAsAgencyPage.module.scss";

export default class ViewAsAgencyPage extends Component {

  static propTypes = {
    onboardingAgencies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        agency: PropTypes.shape({
          name: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }),
        tasks: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          onboarding_task: PropTypes.shape({
            name: PropTypes.string.isRequired,
          }),
          definition: PropTypes.shape({
            subtasks: PropTypes.arrayOf(PropTypes.shape({
              name: PropTypes.string.isRequired,
              required: PropTypes.bool.isRequired,
              complete: PropTypes.bool.isRequired,
            })),
          }),
          status: PropTypes.string.isRequired,
          progress: PropTypes.shape({
            required_total: PropTypes.number.isRequired,
            required_completed: PropTypes.number.isRequired,
            optional_total: PropTypes.number.isRequired,
            optional_completed: PropTypes.number.isRequired,
          }),
          completed_at: PropTypes.string,
          is_applicable: PropTypes.bool.isRequired,
        })),
      })),
      pagination: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
      }),
    }),
    onFetchOnboardingAgencies: PropTypes.func.isRequired,
    onGoToAgencyTask: PropTypes.func.isRequired,
  }

  state = {
    searchText: "",
    page: 1,
    limit: 25,
    showOnboardedAgencies: false,
    showCompletedTasks: false,
  };


  async componentDidMount() {
    await this.fetchOnboardingAgencies();
  }

  render() {
    const { searchText, showOnboardedAgencies, showCompletedTasks } = this.state;
    const { onboardingAgencies } = this.props;

    return (
      <div className={styles.container}>
        <Heading
          className={styles.header}
          title="Onboarding"
        />
        <div className={onboardingAgencies.loading && styles.loading}>
          <div className={styles.filterRow}>
            <SearchInput
              name="searchText"
              value={searchText}
              placeholder={"Search"}
              onChange={this.handleChange}
              onSearch={this.handleSearch}
            />
          </div>
          <div className={styles.filters}>
            <Checkbox
              text="Show Onboarded Agencies"
              checked={showOnboardedAgencies}
              onClick={this.handleOnboardedAgenciesToggle}
            />
            <Checkbox
              text="Show Completed Tasks"
              checked={showCompletedTasks}
              onClick={this.handleCompletedTasksToggle} />
          </div>
          <div className={styles.taskList}>
            <OnboardingEntityList
              data={this.agenciesByUrgency().map(onboardingAgency => ({
                ...onboardingAgency,
                tasks: onboardingAgency.tasks.filter(t => t.is_applicable),
                entity: {
                  ...onboardingAgency.agency,
                  shortCode: onboardingAgency.agency.short_code,
                  entityType: "agency",
                },
              }))}
              showCompletedTasks={showCompletedTasks}
              onViewTaskClick={this.handleViewTaskClick}
            />
          </div>
          {onboardingAgencies.data.length > 0 && (
            <div className={styles.pagination}>
              <Pager
                pageCount={onboardingAgencies.pagination.pages}
                selectedPage={onboardingAgencies.pagination.page}
                onPageChanged={this.handlePageChanged}
              />
              <ItemCountSelector
                value={onboardingAgencies.pagination.limit}
                onChange={this.handleItemCountChanged}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleOnboardedAgenciesToggle = () => {
    const { showOnboardedAgencies } = this.state;

    this.setState({
      page: 1,
      showOnboardedAgencies: !showOnboardedAgencies,
    }, this.fetchOnboardingAgencies);
  }

  handleCompletedTasksToggle = () => {
    const { showCompletedTasks } = this.state;

    this.setState({
      showCompletedTasks: !showCompletedTasks,
    });
  }

  handleViewTaskClick = async ({ entity, task }) => {
    const { onGoToAgencyTask } = this.props;

    await onGoToAgencyTask({
      agencyId: entity.id,
      agencyOnboardingTaskId: task.id,
    });
  }

  handlePageChanged = (n) => {
    this.setState({
      page: n,
    }, this.fetchOnboardingAgencies);
  }

  handleItemCountChanged = (e) => {
    this.setState({
      page: 1,
      limit: e.value,
    }, this.fetchOnboardingAgencies);
  }

  handleSearch = async () => {
    this.setState({
      page: 1,
    }, this.fetchOnboardingAgencies);
  };

  fetchOnboardingAgencies = () => {
    const { onFetchOnboardingAgencies } = this.props;
    const { searchText, showOnboardedAgencies, page, limit } = this.state;

    onFetchOnboardingAgencies({
      pager: {
        page,
        limit,
      },
      searchText,
      completed: showOnboardedAgencies,
    });
  };

  agenciesByUrgency = () => {
    return _
      .chain(this.props.onboardingAgencies.data)
      .sortBy(agency => Math.min(...agency.tasks
        .filter(task => task.due_date && task.status !== "done")
        .map(task => task.due_date)
      ))
      .value();
  }
}
