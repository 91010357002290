import * as types from "./types";
import { apolloClient } from "../../../apolloClient";
import gql from "graphql-tag";
import { push } from "connected-react-router";

const VERIFY_USER = gql`
mutation($user_id: String!, $code: String!, $new_password: String!) {
  verifyUser(
    user_id: $user_id
    code: $code
    new_password: $new_password
  )
}
`;

export const signUpUser = data => async (dispatch) => {
  try {
    dispatch({
      type: types.SIGN_UP_PENDING,
    });

    await apolloClient.mutate({
      mutation: VERIFY_USER,
      variables: {
        user_id: data.userid,
        code: data.code,
        new_password: data.newPassword,
      },
    });

    dispatch({
      type: types.SIGN_UP_SUCCESS,
    });
    dispatch(push("/sign-in"));
  }
  catch (err) {
    dispatch({
      type: types.SIGN_UP_FAILURE,
      payload: {
        error: err,
      },
    });

    // eslint-disable-next-line no-console
    console.log(err);
  }
};
