import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import FilterDropdownItem from "./FilterDropdownItem";
import styles from "./FilterDropdown.module.scss";

export default class FilterDropdown extends Component {

  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    selectedValues: PropTypes.arrayOf(PropTypes.any),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    onFilterClear: PropTypes.func.isRequired,
  }

  static defaultProps = {
    placeholder: "Select a value",
    selectedValues: [],
    children: [],
  }

  dropdownRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
    };
  }

  render() {
    const { className, disabled, readOnly, children, onFilterClear } = this.props;
    const { dropDownVisible } = this.state;
    const textValue = this.getTextValue();
    const dropArrow = this.state.dropDownVisible ? faChevronUp : faChevronDown;
    const selectedIndexes = this.getSelectedIndexes();

    return (
      <div
        className={classnames(
          styles.container,
          className,
          readOnly && styles.readOnly
        )}
        disabled={disabled}
        ref={this.dropdownRef}
        tabIndex={0}
        onClick={this.handleDropDownClicked}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
      >
        <div
          className={styles.label}
          selectable={false}
        >
          {textValue}
        </div>
        {readOnly || (
          <>
            <FontAwesomeIcon icon={dropArrow} />
            <div className={classnames(styles.list, dropDownVisible && styles.visible)} style={this.calculateStyle()}>
              {onFilterClear && <div className={styles.clearFilter} onClick={this.handleClearFilters}>Reset Filter</div>}
              {children.map((child, index) => React.cloneElement(child, {
                checked: selectedIndexes.includes(index),
                onClick: () => {
                  this.handleItemClicked(index);
                  child.onClick && child.onClick(child.props.value);
                },
              }))}
            </div>
          </>
        )}
      </div>
    );
  }

  getTextValue = () => {
    const { placeholder, children } = this.props;
    const selectedIndexes = this.getSelectedIndexes();

    if (selectedIndexes.length === 0) {
      return placeholder;
    }

    if (selectedIndexes.length === 1) {
      return children[selectedIndexes[0]].props.children;
    }

    return `${selectedIndexes.length} selected`;
  }

  getSelectedIndexes = () => {
    const { selectedValues, children } = this.props;

    return selectedValues.map(value => children.findIndex(child => child.props.value === value));
  }

  handleMouseDown = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target) && this.state.dropDownVisible ) {
      this.setState({ dropDownVisible: false });
    }
  }

  handleDropDownClicked = () => {
    if (this.props.disabled) return;
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  handleBlur = () => {
    this.setState({
      dropDownVisible: false,
    });
  }

  handleItemClicked = (index) => {
    const { onChange, children, name } = this.props;
    const selectedIndexes = this.getSelectedIndexes();

    const newSelectedIndexes = selectedIndexes.includes(index)
      ? selectedIndexes.filter(idx => idx !== index)
      : [...selectedIndexes, index];

    onChange && onChange({
      name,
      values: newSelectedIndexes.map(i => children[i].props.value),
    });
  }

  handleClearFilters = () => {
    const { onFilterClear } = this.props;

    onFilterClear && onFilterClear();
  }

  calculateStyle = () => {
    if (this.dropdownRef && this.dropdownRef.current) {
      const getInt = str => Number(str.substring(0, str.length - 2));
      const bounds = this.dropdownRef.current.getBoundingClientRect();
      const top = bounds.height;
      const computed = window.getComputedStyle(this.dropdownRef.current);
      const borderWidths = getInt(computed.getPropertyValue("border-left-width")) + getInt(computed.getPropertyValue("border-right-width"));
      const width = bounds.width - borderWidths;
      return { top, width };
    }
    return {};
  }

}

FilterDropdown.Item = FilterDropdownItem;
