import _ from "lodash";

export default class AgencyPreferencesFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isEmpty(data?.payroll_frequency)) {
      errors = _.set(errors, "payroll_frequency", "Payroll Frequency is required");
    }

    if (_.isEmpty(data?.default_payslip_preference)) {
      errors = _.set(errors, "default_payslip_preference", "Default Payslip Preference is required");
    }

    if (_.isEmpty(data?.default_customer_vat_rate)) {
      errors = _.set(errors, "default_customer_vat_rate", "Default Customer VAT is required");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
