import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Container, Divider, Grid } from "semantic-ui-react";
import { Add, InfoOutlined } from "@material-ui/icons";
import ContactListItem from "./ContactListItem/ContactListItem";
import ContactModal from "./ContactModal/ContactModal";
import ContactValidator from "./ContactValidator/ContactValidator";
import ArchiveContactConfirmationModal from "./ArchiveContactConfirmationModal/ArchiveContactConfirmationModal";
import DeleteContactConfirmationModal from "./DeleteContactConfirmationModal/DeleteContactConfirmationModal";
import { IconButton } from "../";
import styles from "./ContactList.module.scss";

export default class ContactList extends Component {

  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      preferredName: PropTypes.string,
      jobRole: PropTypes.string,
      contactNumbers: PropTypes.arrayOf(PropTypes.shape({
        phoneNumberType: PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
        phoneNumber: PropTypes.string,
        isPreferredNumber: PropTypes.bool,
      })),
      emailAddress: PropTypes.string,
      primaryContact: PropTypes.bool,
      niNumber: PropTypes.string,
    })).isRequired,
    error: PropTypes.string,
    readOnly: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
    canArchive: PropTypes.bool,
    canDelete: PropTypes.bool,
    canMakePrimaryContact: PropTypes.bool,
    onChange: PropTypes.func,
    onContactAdd: PropTypes.func,
    onContactChange: PropTypes.func,
    onContactRemove: PropTypes.func,
    onContactArchive: PropTypes.func,
  };

  static defaultProps = {
    contacts: [],
    canAdd: true,
    canEdit: true,
    canDelete: true,
    canMakePrimaryContact: true,
  };

  state = {
    contactModal: {
      visible: false,
      selectedContact: null,
      selectedIndex: null,
      errors: null,
    },
    archiveModal: {
      visible: false,
      selectedIndex: null,
    },
    deleteModal: {
      visible: false,
      selectedIndex: null,
    },
  }

  render() {
    return (
      <>
        <Container className={classnames(styles.container, this.props.className)} fluid>
          {this.props.contacts.length > 0 && (
            <>
              <Grid columns={2} divided="vertically">
                {this.props.contacts.map((contact, index) => (
                  <ContactListItem
                    key={`contact_${index}`}
                    contact={contact}
                    readOnly={this.props.readOnly}
                    onEditClick={this.props.canEdit ? (() => this.handleEditClick(contact, index)) : null}
                    onArchiveClick={this.props.canArchive ? (() => this.handleArchiveClick(index)) : null}
                    onDeleteClick={this.props.canDelete ? (() => this.handleDeleteClick(index)) : null}
                    onMakePrimaryContactClick={this.props.canMakePrimaryContact ? (() => this.handleMakePrimaryContactClick(index)) : null}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          )}
          {!this.props.readOnly && this.props.canAdd && (
            <>
              <IconButton
                className={styles.addButton}
                icon={(<Add />)}
                iconPosition="left"
                text="Add Contact"
                onClick={this.handleAddClick}
              />
            </>
          )}
          {this.props.error && (
            <div className={styles.error}><InfoOutlined />{this.props.error}</div>)
          }
        </Container>
        {this.state.contactModal.visible && (
          <ContactModal
            contact={this.state.contactModal.selectedContact}
            visible={this.state.contactModal.visible}
            errors={this.state.contactModal.errors}
            onCancel={this.handleModalCancel}
            onSubmit={this.handleModalSubmit}
          />
        )}
        <ArchiveContactConfirmationModal
          visible={this.state.archiveModal.visible}
          onCancel={this.handleArchiveModalCancel}
          onSubmit={this.handleArchiveModalSubmit}
        />
        <DeleteContactConfirmationModal
          visible={this.state.deleteModal.visible}
          onCancel={this.handleDeleteModalCancel}
          onSubmit={this.handleDeleteModalSubmit}
        />
      </>
    );
  }

  handleEditClick = (contact, index) => {
    this.setState({
      contactModal: {
        visible: true,
        selectedContact: contact,
        selectedIndex: index,
      },
    });
  }

  handleArchiveClick = (index) => {
    this.setState({
      archiveModal: {
        visible: true,
        selectedIndex: index,
      },
    });
  }

  handleDeleteClick = (index) => {
    this.setState({
      deleteModal: {
        visible: true,
        selectedIndex: index,
      },
    });
  }

  handleMakePrimaryContactClick = (index) => {
    const { onContactChange } = this.props;

    // Move new primary contact to index 0
    // Do this before finding indices.
    let updatedContacts = _.cloneDeep(this.props.contacts);
    const newPrimaryContact = this.props.contacts[index];
    updatedContacts.splice(index, 1);
    updatedContacts.unshift(newPrimaryContact);

    const previousPrimaryContactIndex = updatedContacts.findIndex(contact => contact.primaryContact);
    updatedContacts.forEach((contact, i) => {
      contact.primaryContact = i === 0;
    });

    // We may get in a state where there is NO primary contact, so we need to make sure we don't send
    // the `onContactChange` event as it will contain an undefined parameter
    if (previousPrimaryContactIndex >= 0) {
      onContactChange && onContactChange(updatedContacts[previousPrimaryContactIndex], previousPrimaryContactIndex);
    }

    onContactChange && onContactChange(updatedContacts[0], 0);

    this.publishChange(updatedContacts);
  }

  handleAddClick = () => {
    this.setState({
      contactModal: {
        visible: true,
        selectedContact: null,
        selectedIndex: null,
      },
    });
  }

  handleModalCancel = () => {
    this.setState({
      contactModal: {
        visible: false,
        selectedContact: null,
        selectedIndex: null,
      },
    });
  }

  handleModalSubmit = (contact) => {
    const { onContactAdd, onContactChange } = this.props;

    const validator = new ContactValidator(contact, this.props);
    const validationResult = validator.validate();

    if (!validationResult.success) {
      this.setState({
        contactModal: {
          ...this.state.contactModal,
          errors: validationResult.errors,
        },
      });
      return;
    }

    const index = this.state.contactModal.selectedIndex;
    const isNewContact = _.isNull(index);
    const updatedContacts = isNewContact
      ? [...this.props.contacts, { ...contact, primaryContact: false }]
      : this.props.contacts.map((c, i) => index === i ? contact : c);

    // Make sure we set the primary contact for the initial contact
    if (updatedContacts.length === 1) {
      updatedContacts[0].primaryContact = true;
    }

    if (isNewContact) {
      onContactAdd && onContactAdd(_.last(updatedContacts), _.lastIndexOf(updatedContacts));
    }
    else {
      onContactChange && onContactChange(updatedContacts[index], index);
    }


    this.setState({
      contactModal: {
        visible: false,
        selectedContact: null,
        selectedIndex: null,
      },
    }, () => this.publishChange(updatedContacts));
  }

  handleArchiveModalCancel = () => {
    this.setState({
      archiveModal: {
        visible: false,
        selectedIndex: null,
      },
    });
  }

  handleArchiveModalSubmit = () => {
    const { onContactChange, onContactArchive } = this.props;

    const index = this.state.archiveModal.selectedIndex;
    const contactToArchive = this.props.contacts[index];
    const updatedContacts = this.props.contacts.filter((_contact, i) => index !== i);

    // Make sure we replace the primary contact if we can
    if (contactToArchive.primaryContact && updatedContacts.length > 0) {
      const nextInLine = Math.min(index, updatedContacts.length - 1);

      updatedContacts[nextInLine].primaryContact = true;

      onContactChange && onContactChange(updatedContacts[nextInLine], nextInLine);
    }

    onContactArchive && onContactArchive(contactToArchive, index);

    this.setState({
      archiveModal: {
        visible: false,
        selectedIndex: null,
      },
    }, () => this.publishChange(updatedContacts));
  }

  handleDeleteModalCancel = () => {
    this.setState({
      deleteModal: {
        visible: false,
        selectedIndex: null,
      },
    });
  }

  handleDeleteModalSubmit = () => {
    const { onContactChange, onContactRemove } = this.props;

    const index = this.state.deleteModal.selectedIndex;
    const contactToDelete = this.props.contacts[index];
    const updatedContacts = this.props.contacts.filter((_contact, i) => index !== i);

    // Make sure we replace the primary contact if we can
    if (contactToDelete.primaryContact && updatedContacts.length > 0) {
      const nextInLine = Math.min(index, updatedContacts.length - 1);

      updatedContacts[nextInLine].primaryContact = true;

      onContactChange && onContactChange(updatedContacts[nextInLine], nextInLine);
    }

    onContactRemove && onContactRemove(contactToDelete, index);

    this.setState({
      deleteModal: {
        visible: false,
        selectedIndex: null,
      },
    }, () => this.publishChange(updatedContacts));
  }

  publishChange = (contacts) => {
    const { name, onChange } = this.props;

    onChange && onChange({
      target: {
        name,
        value: contacts,
      },
    });
  }

}
