import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_COMPANY_INFO = gql`
mutation(
  $agency_id: ID!,
  $contract: UpdateAgencyContractInput,
  $agency_details: UpdateAgencyInput,
  $bank_information: UpdateAgencyBankInput,
  $industry_sectors: [String]!
) {
  updateAgency(id: $agency_id, agency_input: $agency_details) {
    id
  }

  updateAgencyContract(id: $agency_id, agency_contract_input: $contract) {
    id
  }

  updateAgencyBankInformation(agency_bank_input: $bank_information) {
    agency_id
  }

  updateAgencyIndustrySectors(id: $agency_id, industry_sectors: $industry_sectors) {
    name
  }
}
`;

export const updateCompanyInfo = (agencyId, companyInfo) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_COMPANY_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: UPDATE_COMPANY_INFO,
      variables: {
        agency_id: agencyId,
        contract: companyInfo.contract,
        agency_details: companyInfo.agencyDetails,
        bank_information: companyInfo.bankInformation,
        industry_sectors: companyInfo.industrySectors,
      },
    });

    dispatch({
      type: types.UPDATE_COMPANY_INFO_SUCCESS,
      payload: {
        agency: {
          contract: response.data.contract,
          agency_details: response.data.agencyDetails,
          bank_information: response.data.bankInformation,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_COMPANY_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
