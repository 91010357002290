import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TableCell from "./TableCell";
import TableColumn from "./TableColumn";
import TableRow from "./TableRow";
import "./Table.scss";

export default class Table extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string,
      style: PropTypes.object,
      name: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]).isRequired,
      value: PropTypes.func.isRequired,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    onRowClicked: PropTypes.func,
  }

  renderColumns = () => {
    const { columns } = this.props;

    return (columns || []).map((column, i) => (
      <TableColumn key={i} className={column.className} style={column.style}>
        {column.name}
      </TableColumn>
    ));
  }

  renderData = () => {
    const { columns, data, onRowClicked } = this.props;

    return (data || []).map((row, i) => (
      <TableRow key={i} onClick={onRowClicked && onRowClicked.bind(this, row, i)}>
        {[...Array(columns.length).keys()].map(k => (
          <TableCell key={k} className={columns[k].className} style={columns[k].style}>
            {columns[k].value(row, i)}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  render() {
    const { className, style } = this.props;

    return (
      <div
        className={classnames("Table", className)}
        style={style}
      >
        <div className="TableHeading">
          <TableRow>
            {this.renderColumns()}
          </TableRow>
        </div>
        <div className="TableBody">
          {this.renderData()}
        </div>
      </div>
    );
  }

}
