export const EXPORT_AGENCIES_PENDING = "SYSTEM/EXPORT_AGENCIES_PENDING";
export const EXPORT_AGENCIES_SUCCESS = "SYSTEM/EXPORT_AGENCIES_SUCCESS";
export const EXPORT_AGENCIES_FAILURE = "SYSTEM/EXPORT_AGENCIES_FAILURE";
export const EXPORT_PROSPECTS_PENDING = "SYSTEM/EXPORT_PROSPECTS_PENDING";
export const EXPORT_PROSPECTS_SUCCESS = "SYSTEM/EXPORT_PROSPECTS_SUCCESS";
export const EXPORT_PROSPECTS_FAILURE = "SYSTEM/EXPORT_PROSPECTS_FAILURE";
export const EXPORT_TEMPEST_PENDING = "SYSTEM/EXPORT_TEMPEST_PENDING";
export const EXPORT_TEMPEST_SUCCESS = "SYSTEM/EXPORT_TEMPEST_SUCCESS";
export const EXPORT_TEMPEST_FAILURE = "SYSTEM/EXPORT_TEMPEST_FAILURE";
export const GET_DATA_EXPORTS_PENDING = "SYSTEM/GET_DATA_EXPORTS_PENDING";
export const GET_DATA_EXPORTS_SUCCESS = "SYSTEM/GET_DATA_EXPORTS_SUCCESS";
export const GET_DATA_EXPORTS_FAILURE = "SYSTEM/GET_DATA_EXPORTS_FAILURE";
export const GET_DATA_IMPORTS_PENDING = "SYSTEM/GET_DATA_IMPORTS_PENDING";
export const GET_DATA_IMPORTS_SUCCESS = "SYSTEM/GET_DATA_IMPORTS_SUCCESS";
export const GET_DATA_IMPORTS_FAILURE = "SYSTEM/GET_DATA_IMPORTS_FAILURE";
export const IMPORT_AGENCIES_PENDING = "SYSTEM/IMPORT_AGENCIES_PENDING";
export const IMPORT_AGENCIES_SUCCESS = "SYSTEM/IMPORT_AGENCIES_SUCCESS";
export const IMPORT_AGENCIES_FAILURE = "SYSTEM/IMPORT_AGENCIES_FAILURE";
export const IMPORT_PROSPECTS_PENDING = "SYSTEM/IMPORT_PROSPECTS_PENDING";
export const IMPORT_PROSPECTS_SUCCESS = "SYSTEM/IMPORT_PROSPECTS_SUCCESS";
export const IMPORT_PROSPECTS_FAILURE = "SYSTEM/IMPORT_PROSPECTS_FAILURE";
export const IMPORT_TEMPEST_PENDING = "SYSTEM/IMPORT_TEMPEST_PENDING";
export const IMPORT_TEMPEST_SUCCESS = "SYSTEM/IMPORT_TEMPEST_SUCCESS";
export const IMPORT_TEMPEST_FAILURE = "SYSTEM/IMPORT_TEMPEST_FAILURE";
