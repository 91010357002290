import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../../common/components";
import styles from "./DistributeConfirmationModal.module.scss";
import { toMoney } from "../../../../../../utils";

export default function DistributeConfirmationModal ({
  fromAccount,
  toAccount,
  amount,
  onSubmit,
  onCancel,
}) {
  return (
    <Modal
      title="Are you sure?"
      subtitle="Please review the distribution below to ensure that it is correct:"
      visible
      className={styles.modal}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitText="Complete"
      cancelText="Go back"
      cancelVariant="secondary"
      buttonPosition="middle"
    >
      <div className={styles.grid}>
        <div className={styles.label}>From:</div>
        <div>{fromAccount?.agency ? `${fromAccount.agency}: ${fromAccount.name}` : fromAccount?.name}</div>

        <div className={styles.label}>To:</div>
        <div>{toAccount?.agency ? `${toAccount.agency}: ${toAccount.name}` : toAccount?.name}</div>

        <div className={styles.label}>Distribute Amount:</div>
        <div>{toMoney(amount)}</div>
      </div>
    </Modal>
  );
}

DistributeConfirmationModal.propTypes = {
  fromAccount: PropTypes.shape({
    agency: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  toAccount: PropTypes.shape({
    agency: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
