import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_SEARCH = gql`
query($pager: Pager!, $search_options: AgencySearchOptions!, $sort_order: [SortOrder], $allow_empty_search: Boolean) {
  searchAgencies(pager: $pager, search_options: $search_options, sort_order: $sort_order, allow_empty_search: $allow_empty_search) {
    data {
      id
      known_as
    }
    total
  }
}
`;

export const getAgencySearch = ({
  searchText,
  allowEmptySearch,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.AGENCY_SEARCH_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY_SEARCH,
      variables: {
        pager: {
          page: 1,
          limit: 25,
        },
        search_options: {
          short_code: searchText,
          known_as: searchText,
        },
        sort_order: [{
          field: "known_as",
          order: "ASC",
        }],
        allow_empty_search: allowEmptySearch,
      },
    });

    dispatch({
      type: types.AGENCY_SEARCH_SUCCESS,
      payload: {
        data: response.data.searchAgencies.data,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.AGENCY_SEARCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
