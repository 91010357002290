import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_PAYROLL_GENERAL = gql`
mutation(
  $agency_id: ID!
  $input: UpdateAgencyWorkerTypesInput!
) {
  updateAgencyWorkerTypes(agency_id: $agency_id, input: $input) {
    data {
      id
    }
    has_new_onboarding_tasks
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @param {object} obj.data
 * @param {Array.<number>} obj.data.worker_types
 * @param {string} obj.redirectUrl
 *
 */
export const updatePayrollGeneral = ({ agencyId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_PAYROLL_GENERAL_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_PAYROLL_GENERAL,
      variables: {
        agency_id: agencyId,
        input: {
          worker_types: data.worker_types,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_PAYROLL_GENERAL_SUCCESS,
      payload: {
        data: {
          worker_types: response.data.updateAgencyWorkerTypes.data,
        },
        hasNewOnboardingTasks: response.data.updateAgencyWorkerTypes.has_new_onboarding_tasks,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl, {
        hasNewOnboardingTasks: response.data.updateAgencyWorkerTypes.has_new_onboarding_tasks,
      }));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_PAYROLL_GENERAL_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
