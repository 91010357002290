import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Edit } from "@material-ui/icons";
import { actions } from "../duck";
import { Breadcrumb, Heading, IconButton, ItemList } from "../../common/components";
import "./UsersPage.scss";

class UsersPage extends PureComponent {

  static propTypes = {
    users: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.array.isRequired,
    }).isRequired,
    getUsers: PropTypes.func.isRequired,
    gotoEditUser: PropTypes.func.isRequired,
    gotoAddUser: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    await this.props.getUsers();
  }

  render() {
    return (
      <div className="UsersPage">
        <Breadcrumb className="header-breadcrumb">
          <Breadcrumb.Item link="/manage" text="User Management" />
          <Breadcrumb.Item link="/manage/users" text="Users" />
        </Breadcrumb>
        <Heading className="heading" title="Users" caption="You can view and edit your permissions for different roles that help manage your account." />
        <div className="content">
          <ItemList
            newButtonLabel="Add User"
            archiveButtonVisible={false}
            loading={this.props.users.loading}
            showCheckBoxes={false}
            columns={[
              {
                className: "name",
                name: "Name",
                value: row =>
                  <div className="user-name-column">
                    <p className="user-name">{row.first_name} {row.last_name}</p>
                    <small className="user-email">{row.email}</small>
                  </div>,
              },
              {
                className: "department",
                name: "Department",
                value: row => row.department ? row.department.name : "",
              },
              {
                className: "actions",
                name: "Action",
                value: row => <IconButton text="Edit" onClick={() => this.handleItemClicked(row)} icon={<Edit />} />,
              },
            ]}
            data={this.props.users.data}
            onNewClicked={this.handleNewClicked}
            onArchiveClicked={this.handleArchiveClicked}
            onItemClicked={this.handleItemClicked}
          />
        </div>
      </div>
    );
  }

  handleNewClicked = () => {
    this.props.gotoAddUser();
  }

  handleArchiveClicked = () => {
    // console.log("Archive Clicked");
  }

  handleItemClicked = (item) => {
    this.props.gotoEditUser(item.id);
  }

}

const mapStateToProps = state => ({
  ...state.userManagement,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
