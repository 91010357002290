import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_BANK_INFORMATION = gql`
mutation(
  $bank_information: UpdateAgencyBankInput
) {
  updateAgencyBankInformation(agency_bank_input: $bank_information) {
    agency_id
  }
}
`;

export const updateAgencyBankInformation = companyInfo => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_BANK_INFORMATION_PENDING,
    });

    const response = await apolloClient.query({
      query: UPDATE_AGENCY_BANK_INFORMATION,
      variables: {
        bank_information: companyInfo.bankInformation,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_BANK_INFORMATION_SUCCESS,
      payload: {
        agency: {
          bank_information: response.data.bankInformation,
        },
      },
    });

    dispatch(push(`/agencies/${companyInfo.bankInformation.agency_id}/agency`));
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_BANK_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
