import React from "react";
import PropTypes from "prop-types";
import {
  Form,
} from "../../../../common/components";
import { REACT_APP_FEATURE_FLAG_PROSPECT_CREDIT } from "../../../../../config";
import { AgencyProspectSearchBox } from "../../../containers";
import { upToNearestThousand } from "../../../../../utils";

export default function InitialQuestionsForm(props) {
  const handleRadioChange = (e) => {
    props.onChange && props.onChange({
      target: {
        name: e.target.name,
        value: String(e.target.value) === "true",
      },
    });
  };

  const handleAgencyChange = (agency) => {
    props.onAgencyChange && props.onAgencyChange({
      agencyId: agency.id,
      agencyName: agency?.name,
      prospect: agency.prospect,
    });
  };

  const handleEstimatedLimitBlur = () => {
    // If the user clicks off the estimated credit limit input, reset the value to 1000
    const newValue = props.data.estimatedCreditLimit === ""
      ? ""
      : upToNearestThousand(props.data.estimatedCreditLimit);

    props.onChange({
      target: {
        name: "estimatedCreditLimit",
        value: newValue,
      },
    });
  };

  return (
    <Form requiredKey>
      <Form.Section title="Prerequisite Questions">
        <Form.Group inline wide error={props.errors?.agencyId}>
          <Form.Label inline required>
            Which agency are you requesting a credit check on behalf of?
          </Form.Label>
          <Form.Value size="medium">
            <AgencyProspectSearchBox
              name="agencyId"
              onItemSelected={handleAgencyChange}
              value={props.data.agency?.agencyName}
              hideProspects={!REACT_APP_FEATURE_FLAG_PROSPECT_CREDIT}
              highlightError={props.errors?.agencyId}
            />
          </Form.Value>
        </Form.Group>
        <Form.Group inline wide>
          <Form.Label inline required>
            Is it a UK or International company?
          </Form.Label>
          <Form.Value size="medium">
            <Form.RadioContainer>
              <Form.Radio
                value="uk"
                name="companyLocation"
                checked={props.data.companyLocation === "uk"}
                text="UK"
                onChange={props.onChange}
              />
              <Form.Radio
                value="international"
                name="companyLocation"
                checked={props.data.companyLocation === "international"}
                text="International"
                onChange={props.onChange}
              />
            </Form.RadioContainer>
          </Form.Value>
        </Form.Group>
        <Form.Group inline wide>
          <Form.Label inline required>
            Is the credit check for a Limited company?
          </Form.Label>
          <Form.Value size="medium">
            <Form.RadioContainer>
              <Form.Radio
                value={true}
                name="limitedCompany"
                checked={props.data.limitedCompany}
                text="Yes"
                onChange={handleRadioChange}
              />
              <Form.Radio
                value={false}
                name="limitedCompany"
                checked={!props.data.limitedCompany}
                text="No"
                onChange={handleRadioChange}
              />
            </Form.RadioContainer>
          </Form.Value>
        </Form.Group>
        <Form.Group inline wide error={props.errors?.estimatedCreditLimit}>
          <Form.Label inline required>
            Enter estimated credit limit required
          </Form.Label>
          <Form.Number
            name="estimatedCreditLimit"
            value={props.data.estimatedCreditLimit}
            placeholder="Enter credit limit..."
            prefix="£"
            onChange={props.onChange}
            onBlur={handleEstimatedLimitBlur}
            formatNumber
            highlightError={props.errors?.estimatedCreditLimit}
          />
        </Form.Group>
      </Form.Section>
    </Form>
  );
}

InitialQuestionsForm.propTypes = {
  data: {
    companyLocation: PropTypes.string.isRequired,
    limitedCompany: PropTypes.bool.isRequired,
    estimatedCreditLimit: PropTypes.number,
    agency: PropTypes.shape({
      agencyId: PropTypes.string,
      agencyName: PropTypes.string,
      prospect: PropTypes.bool,
    }),
  },
  errors: PropTypes.shape({
    agencyId: PropTypes.string,
    estimatedCreditLimit: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onAgencyChange: PropTypes.func.isRequired,
};
