import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AgencyVatInformationFormValidator from "./AgencyVatInformationFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { options, tooltips } from "../../../../../constants";
import styles from "./AgencyVatInformationForm.module.scss";

export default class AgencyVatInformationForm extends Component {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      applying_registered_vat: PropTypes.bool,
      vat_number: PropTypes.string,
      vat_period_ending_date: PropTypes.string,
      vat_frequency: PropTypes.string,
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        agency_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }),
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {
        applying_registered_vat: props.data?.applying_registered_vat,
        vat_number: props.data?.vat_number,
        vat_period_ending_date: props.data?.vat_period_ending_date,
        vat_frequency: props.data?.vat_frequency,
      },
      errors: {},
    };
  }

  render() {
    const { submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <div className={styles.container}>
        <Form className={styles.form}>
          <Form.Section title="VAT" titleClassName={styles.title}>
            <Form.Group inline wide>
              <Form.Label inline wide>
                Agency applying / registered for VAT?
              </Form.Label>
              <Form.Checkbox
                name="applying_registered_vat"
                checked={data.applying_registered_vat}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={this.isFieldDisabled("applying_registered_vat")}
                showLock={this.isFieldDisabled("applying_registered_vat")}
                onChange={this.handleApplyingVatChange}
                onLockClick={() => this.handleDisabledFieldClick("applying_registered_vat")}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.vat_number}>
              <Form.Label inline wide>VAT Registration Number</Form.Label>
              <Form.Input
                name="vat_number"
                value={data.vat_number}
                size="medium"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={this.isFieldDisabled("vat_registration_number") || !data.applying_registered_vat}
                showLock={this.isFieldDisabled("vat_registration_number")}
                onChange={this.handleInputChange}
                onLockClick={() => this.handleDisabledFieldClick("vat_registration_number")}
                highlightError={errors?.vat_number}
                maskOptions={{
                  mask: "999 9999 99",
                  maskChar: null,
                }}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.vat_period_ending_date}>
              <Form.Label inline wide>VAT Period Ending Date</Form.Label>
              <Form.Date
                name="vat_period_ending_date"
                disabled={this.isFieldDisabled("vat_period_ending_date") || !data.applying_registered_vat}
                showLock={this.isFieldDisabled("vat_period_ending_date")}
                onLockClick={() => this.handleDisabledFieldClick("vat_period_ending_date")}
                value={data?.vat_period_ending_date}
                onChange={this.handleInputChange}
                highlightError={errors?.vat_period_ending_date}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.vat_frequency}>
              <Form.Label inline wide>VAT Frequency</Form.Label>
              <Form.Select
                name="vat_frequency"
                size="medium"
                options={options.VAT_FREQUENCY_OPTIONS}
                value={data.vat_frequency}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={this.isFieldDisabled("vat_frequency") || !data.applying_registered_vat}
                showLock={this.isFieldDisabled("vat_frequency")}
                onChange={this.handleInputChange}
                onLockClick={() => this.handleDisabledFieldClick("vat_frequency")}
                highlightError={errors?.vat_frequency}
              />
            </Form.Group>
          </Form.Section>
        </Form>
        <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
          <>
            <Button
              variant="primary"
              className={styles.button}
              onClick={this.handleSaveClicked}
              busy={submitting}
              disabled={submitting || this.isFieldDisabled("applying_registered_vat")}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              className={styles.button}
              onClick={this.handleCancelClicked}
            >
              Cancel
            </Button>
          </>
        </ButtonGroup>
      </div>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleApplyingVatChange = () => {
    const { data } = this.state;

    const clearVatInfo = data.applying_registered_vat;

    this.setState({
      data: {
        vat_number: clearVatInfo ? "" : data.vat_number,
        applying_registered_vat: !data.applying_registered_vat,
        vat_period_ending_date: clearVatInfo ? null : data.vat_period_ending_date,
        vat_frequency: clearVatInfo ? null : data.vat_frequency,
      },
    });
  }

  handleCancelClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSaveClicked = () => {
    const { data } = this.state;
    const { onSave } = this.props;

    const validator = new AgencyVatInformationFormValidator(data, this.props);
    const validationResult = (validator && validator.validate()) || { errors: [], success: true };

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) return;

    onSave && onSave({
      ...data,
    });
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick && onDisabledFieldClick(disabledField);
  }

  isFieldDisabled = (fieldName) => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
