import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, Form } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./UseDiscretionaryLimitModal.module.scss";
import UseDiscretionaryLimitModalValidator from "./UseDiscretionaryLimitModalValidator";

export default function UseDiscretionaryLimitModal (props) {
  const [formData, setFormData] = useState({
    resultDate: Date.now(),
    comments: "",
  });
  const [errors, setErrors] = useState({});

  const canComplete = () => {
    if (!formData?.resultDate) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const validator = new UseDiscretionaryLimitModalValidator(formData);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.completeCreditApplication({
      creditApplicationId: props.creditApplicationId,
      notes: formData.comments,
      useDiscretionaryLimit: true,
      status: "declined",
      amountApproved: null,
      dateOfResult: formData.resultDate,
    });

    props.hideModal(UseDiscretionaryLimitModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(UseDiscretionaryLimitModal.displayName);
  };

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <ConfirmationModal
      title="Use Discretionary Limit"
      visible={props.visible}
      className={styles.container}
      busy={props.submitting}
      submitText="Complete"
      submitDisabled={!canComplete()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Please enter the date that the broker returned with this decision.
      </div>
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline wide error={errors.resultDate}>
            <Form.Label className={styles.label} inline required>
              Date result was obtained
            </Form.Label>
            <Form.Date
              name="resultDate"
              value={formData.resultDate}
              size="small"
              disabled={props.submitting}
              highlightError={errors?.resultDate}
              onChange={handleFormChange}
              max={parseDate(Date.now(), "YYYY-MM-DD")}
            />
          </Form.Group>
          <Form.Group inline wide>
            <Form.Label className={styles.commentsLabel} inline>
              Comments
            </Form.Label>
            <Form.TextArea
              name="comments"
              className={styles.textArea}
              value={formData.comments}
              placeholder="Enter comments..."
              rows={6}
              maxLength={255}
              disabled={props.submitting}
              onChange={handleFormChange}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

UseDiscretionaryLimitModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  creditApplicationId: PropTypes.string.isRequired,
  completeCreditApplication: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

UseDiscretionaryLimitModal.displayName = "UseDiscretionaryLimitModal";
