import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./SortIndicator.module.scss";

export default function SortIndicator(props) {
  const isUpArrowVisible = ["NONE", "ASC"].includes(props.sortOrder);
  const isDownArrowVisible = ["NONE", "DESC"].includes(props.sortOrder);

  return (
    <div className={classnames(props.className, styles.arrows)}>
      <FontAwesomeIcon
        className={classnames(
          styles.icon,
          isUpArrowVisible && styles.visible
        )}
        icon={faAngleUp}
      />
      <FontAwesomeIcon
        className={classnames(
          styles.icon,
          isDownArrowVisible && styles.visible
        )}
        icon={faAngleDown}
      />
    </div>
  );
}

SortIndicator.propTypes = {
  className: PropTypes.string,
  sortOrder: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
};

SortIndicator.defaultProps = {
  sortOrder: "NONE",
};
