import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_USER_GROUPS = gql`
query {
  getUserGroups {
    id
    name
    description
    permissions {
      id
    }
  }
}
`;

export const getUserGroups = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_USER_GROUPS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_USER_GROUPS,
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_USER_GROUPS_SUCCESS,
      payload: {
        userGroups: response.data.getUserGroups,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_USER_GROUPS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
