import React from "react";
import PropTypes from "prop-types";

export default function Icon(props) {
  return (
    <img className={props.className} src={props.icon} alt={props.alt ?? ""}/>
  );
}

Icon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};
