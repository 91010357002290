import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import ViewLegalEntityPage from "./ViewLegalEntityPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
  currentUser: state.currentUser.user,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    ...modalActions,
  }, dispatch),
  goToCreditManagementTab: (legalEntityId) => {
    dispatch(push(`/credit-limit-management/legal-entities/${legalEntityId}/credit-management`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewLegalEntityPage);
