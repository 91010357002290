import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

export default function ConditionalTooltip({
  condition,
  effect,
  place,
  content,
  children,
  identifier,
}) {

  return (
    condition
      ? <div data-tip data-for={`${identifier}-tooltip`}>
        <ReactTooltip
          id={`${identifier}-tooltip`}
          effect={effect}
          place={place}
        >
          {content}
        </ReactTooltip>
        {children}
      </div>
      : children
  );
}

ConditionalTooltip.propTypes = {
  condition: PropTypes.bool,
  identifier: PropTypes.string.isRequired,
  content: PropTypes.string,
  effect: PropTypes.string,
  place: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ConditionalTooltip.defaultProps = {
  effect: "solid",
  place: "bottom",
};
