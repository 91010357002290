import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ExpandMore } from "@material-ui/icons";
import { Squares as ActivityIndicator } from "react-activity";
import { NewRegisteredCompanyConfirmationModal } from "../../";
import { Button, CollapseContainer, Form } from "../../../../common/components";
import { capitalize, parseDate } from "../../../../../utils";
import styles from "./CompanyDetailsForm.module.scss";

export default function CompanyDetailsForm(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({
    isRegisteredCharity: false,
    registeredCharityNumber: "",
    website: "",
  });

  return (
    <div className={classnames(props.className, styles.container)}>
      <div className={styles.companyDetailsContainer}>
        <div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
          <h1 className={styles.title}>
            Companies House Details
          </h1>
          {props.loading ? (
            <ActivityIndicator />
          ) : (
            <span className={styles.icon}>
              <ExpandMore
                className={classnames(
                  styles.expandIcon,
                  isExpanded ? styles.collapse : styles.expand
                )}
                fontSize="inherit"
              />
            </span>
          )}
        </div>
        <CollapseContainer collapsed={!isExpanded}>
          <Form className={styles.form}>
            <div className={styles.cols}>
              <div className={styles.col}>
                <Form.Group inline>
                  <Form.Label>Exact Legal Name</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.company_name}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Registered Company Number</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.company_number}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Company Type</Form.Label>
                  <Form.Value>
                    {capitalize(props.companyProfile?.type) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Incorporated Date</Form.Label>
                  <Form.Value>
                    {parseDate(props.companyProfile?.date_of_creation) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Last Account Period End On</Form.Label>
                  <Form.Value>
                    {parseDate(props.companyProfile?.accounts?.last_accounts?.made_up_to) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group className={styles.alignStart} inline>
                  <Form.Label className={styles.alignStartLabel}>Previous Names</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.previous_company_names?.map((previousName, index) => (
                      <Form.FlexRow key={`previous_name_${index}`}>
                        <Form.ValueInline>
                          {previousName.name}
                        </Form.ValueInline>
                      </Form.FlexRow>
                    )) || "-"}
                  </Form.Value>
                </Form.Group>
              </div>
              <div className={styles.col}>
                <Form.Group inline>
                  <Form.Label>Company Status</Form.Label>
                  <Form.Value>
                    {capitalize(props.companyProfile?.company_status) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Company Status Detail</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.company_status_detail || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group className={styles.alignStart} inline>
                  <Form.Label className={styles.alignStartLabel}>Registered Address</Form.Label>
                  <Form.Value>
                    {_.chain([
                      props.companyProfile?.registered_office_address?.address_line_1,
                      props.companyProfile?.registered_office_address?.address_line_2,
                      props.companyProfile?.registered_office_address?.locality,
                      props.companyProfile?.registered_office_address?.region,
                      props.companyProfile?.registered_office_address?.postal_code,
                      props.companyProfile?.registered_office_address?.country,
                    ]).compact().map((addressLine, index) => (
                      <Form.FlexRow key={`registered_address_${index}`}>
                        <Form.ValueInline>
                          {addressLine}
                        </Form.ValueInline>
                      </Form.FlexRow>
                    )).value()}
                  </Form.Value>
                </Form.Group>
              </div>
            </div>
          </Form>
        </CollapseContainer>
      </div>
      <Form className={classnames(styles.form, props.submitting && styles.submitting)} requiredKey>
        <Form.Section />
        <Form.Section title="Company Details">
          <Form.Group className={styles.alignStart} inline>
            <Form.Label className={styles.alignStartLabel} inline required>Is the company a charity?</Form.Label>
            <Form.RadioList
              orientation="vertical"
              value={state.isRegisteredCharity}
              options={[
                {
                  label: "Yes",
                  value: true,
                },
                {
                  label: "No",
                  value: false,
                },
              ]}
              onChange={e => setState({ ...state, isRegisteredCharity: e.target.value === "true" })}
            />
          </Form.Group>
          {state.isRegisteredCharity && (
            <Form.Group inline>
              <Form.Label inline>Registered Charity Number</Form.Label>
              <Form.Input
                type="text"
                value={state.registeredCharityNumber}
                maxLength={10}
                onChange={e => setState({ ...state, registeredCharityNumber: e.target.value })}
              />
            </Form.Group>
          )}
        </Form.Section>
        <Form.Section title="Communication">
          <Form.Group inline>
            <Form.Label>Website</Form.Label>
            <Form.Input
              type="text"
              value={state.website}
              size="large"
              onChange={e => setState({ ...state, website: e.target.value })}
            />
          </Form.Group>
        </Form.Section>
      </Form>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          className={styles.button}
          disabled={props.submitting || props.loading}
          onClick={() => setShowModal(true)}
        >
          Complete
        </Button>
        <Button
          variant="outline-danger"
          className={styles.button}
          disabled={props.submitting || props.loading}
          onClick={() => props.onCancelClick()}
        >
          Cancel
        </Button>
      </div>
      <NewRegisteredCompanyConfirmationModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onSubmit={() => {
          setShowModal(false);
          props.onCompleteClick(state);
        }}
      />
    </div>
  );
}

CompanyDetailsForm.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  companyProfile: PropTypes.shape({
    company_name: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
    company_status: PropTypes.string.isRequired,
    company_status_detail: PropTypes.string,
    type: PropTypes.string.isRequired,
    date_of_creation: PropTypes.string,
    previous_names: PropTypes.arrayOf(PropTypes.string),
    accounts: PropTypes.shape({
      last_accounts: PropTypes.shape({
        made_up_to: PropTypes.string,
      }),
    }),
    links: PropTypes.shape({
      self: PropTypes.string.isRequired,
    }),
    previous_company_names: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      effective_from: PropTypes.string,
    })),
    registered_office_address: PropTypes.shape({
      address_line_1: PropTypes.string,
      address_line_2: PropTypes.string,
      locality: PropTypes.string,
      region: PropTypes.string,
      postal_code: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
  onCancelClick: PropTypes.func.isRequired,
  onCompleteClick: PropTypes.func.isRequired,
};
