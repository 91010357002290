import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { CompleteSignUpForm } from "../../components";
import { Alert, TextBlock } from "../../../common/components";
import Logo from "../../../../assets/images/logo.png";
import PanelImage from "../../../../assets/images/login@2x.jpg";
import styles from "./SignUpPage.module.scss";

export default class SignUpPage extends Component {

  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    submitting: PropTypes.bool,
    error: PropTypes.string,
    signUpUser: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img className={styles.image} src={PanelImage} alt="" />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.content}>
            <img src={Logo} className={styles.logo} alt="logo"/>
            <div className={styles.title}>
              Complete Verification
            </div>
            <div className={styles.subtitle}>
              To complete your account setup, please enter a new password
            </div>
            <TextBlock
              className={styles.warning}
              content="Password must be at least 8 characters, with at least one uppercase, lower case, and special character."
            />
            {this.props.error && (
              <Alert className={styles.error} variant="error" showClose={false}>
                {this.props.error}
              </Alert>
            )}
            <CompleteSignUpForm
              className={styles.form}
              submitting={this.props.submitting}
              onSubmit={this.handleSubmit}
            />
          </div>
          <div className={styles.footer}></div>
        </div>
      </div>
    );
  }

  handleSubmit = (data) => {
    const query = queryString.parse(this.props.location.search);

    return this.props.signUpUser({
      userid: query.userid,
      code: query.code,
      newPassword: data.password,
    });
  }
}
