import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormCard.module.scss";

export default function FormCard({
  className,
  title,
  variant,
  rightAccessory,
  history,
  children,
}) {
  const titleComponent = React.createElement(variant, {
    className: styles.title,
  }, title);

  return (
    <div className={classnames(styles.container, className)}>
      <div className={classnames(styles.heading, rightAccessory && styles.accessory, history && styles.history )}>
        <div className={styles.titleWrapper}>
          {titleComponent || <span />}
          {history}
        </div>
        {rightAccessory}
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

FormCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
  rightAccessory: PropTypes.element,
  history: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

FormCard.defaultProps = {
  variant: "h2",
};
