import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DELETE_AGENCY_CUSTOMER_REQUEST = gql`
mutation($id: ID!) {
  deleteAgencyCustomerRequest(id: $id) {
    id
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerRequestId
 * @param {string} obj.redirectUrl (optional)
 * @returns
 */
export const deleteAgencyCustomerRequest = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.DELETE_AGENCY_CUSTOMER_REQUEST_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: DELETE_AGENCY_CUSTOMER_REQUEST,
      variables: {
        id: obj.agencyCustomerRequestId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.DELETE_AGENCY_CUSTOMER_REQUEST_SUCCESS,
      payload: {
        data: response.data.deleteAgencyCustomerRequest,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.DELETE_AGENCY_CUSTOMER_REQUEST_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
