import * as types from "../types";

/**
 *
 * @param {object} obj
 * @param {string} obj.prospectOnboardingTaskId
 * @param {number} obj.requiredSubtasksTotal
 * @param {number} obj.requiredSubtasksCompleted
 * @returns
 */
export const prospectOnboardingTaskProgressChanged = obj => (dispatch) => {
  dispatch({
    type: types.PROSPECT_ONBOARDING_TASK_PROGRESS_CHANGED,
    payload: {
      prospectOnboardingTaskId: obj.prospectOnboardingTaskId,
      requiredSubtasksTotal: obj.requiredSubtasksTotal,
      requiredSubtasksCompleted: obj.requiredSubtasksCompleted,
    },
  });
};
