import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_CERTIFICATE_OF_INCORPORATION_URL = gql`
query($company_number: String!) {
  getCompaniesCertificateOfIncorporationUrl(companyNumber: $company_number) {
    url
  }
}
`;

export const getCompanyCertificateOfIncorporationUrl = company_number => async (dispatch) => {
  try {
    dispatch({ type: types.GET_COMPANY_CERT_URL_PENDING });
    const response = await apolloClient.query({
      query: GET_CERTIFICATE_OF_INCORPORATION_URL,
      variables: {
        company_number,
      },
    });
    const url = response.data.getCompaniesCertificateOfIncorporationUrl.url;

    if (url === null) {
      throw Error("Failed to get Companies Certificate of Incorporation URL");
    }

    dispatch({
      type: types.GET_COMPANY_CERT_URL_SUCCESS,
      payload: {
        url,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_COMPANY_CERT_URL_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
