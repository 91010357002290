import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "../";
import styles from "./FilterDropdownItem.module.scss";

export default function FilterDropdownItem({
  children,
  checked,
  onClick,
}) {

  const handleClick = (e) => {
    e.stopPropagation();

    onClick && onClick();
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <Checkbox containerClassName={styles.checkbox} checked={checked} onClick={onClick} />
      <div className={styles.label}>
        {children}
      </div>
    </div>
  );
}

FilterDropdownItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  value: PropTypes.any.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

