import { UploadDocumentsModal } from "../../../documents/containers";
import { actions as modalActions } from "../../../modals/duck";
import { actions as documentActions } from "../../../documents/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 *
 * @returns
 */
export const showUploadDocumentsModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(UploadDocumentsModal.displayName, {
    agencyId: obj.agencyId,
    uploadDocuments: data => dispatch(documentActions.uploadAgencyDocuments(data)),
  }));
};
