import _ from "lodash";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import FormSelect from "../FormSelect/FormSelect";
import { apolloClient } from "../../../../../apolloClient";

const buildQuery = (queryOptions) => {
  const variableDefinitions = _.chain(queryOptions.inputs)
    .map(input => `$${input.variableName}: ${input.type}`)
    .join(", ");
  const variables = _.reduce(queryOptions.inputs, (r, input) => ({
    ...r,
    [input.variableName]: input.value,
  }), {});
  const queryParameters = _.chain(queryOptions.inputs)
    .map(input => `${input.variableName}: $${input.variableName}`)
    .join(", ");
  const fields = _.join(queryOptions.fields, "\n");

  const query = gql`
    query(${variableDefinitions}) {
      ${queryOptions.query}(${queryParameters}) {
        ${fields}
      }
    }
  `;

  return {
    query,
    variables,
  };
};

const replacePlaceholders = (str, data) => {
  const matches = [...str.matchAll(/(?:\$\.(\w+))/g)];
  let newStr = str;

  for (const match of matches) {
    newStr = newStr.replace(match[0], _.get(data, match[1]));
  }

  return newStr;
};

const transformData = (data, transform) => {
  return _.map(data, x => ({
    label: replacePlaceholders(transform.label, x),
    value: replacePlaceholders(transform.value, x),
  }));
};

export default function FormSelectQuery({ queryOptions, ...otherProps }) {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function executeQuery() {
      const response = await apolloClient.query(buildQuery(queryOptions));

      if (response.errors) {
        setError(response.errors[0].message);
        return;
      }

      const data = transformData(response.data[queryOptions.query], queryOptions.transform);

      setOptions(data);
    }

    executeQuery();
  }, []);

  if (error) {
    return error;
  }

  return (
    <FormSelect
      {...otherProps}
      options={options}
    />
  );
}

FormSelectQuery.propTypes = {
  ...FormSelect.propTypes,
  queryOptions: PropTypes.shape({
    query: PropTypes.string.isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      variableName: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })),
    fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    transform: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  }),
};
