import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table2, StatusLabel, Pager } from "../../../common/components";
import { CustomerStatusConverter } from "../../../../converters";
import { sorting } from "../../../../utils";
import styles from "./CustomersTab.module.scss";

export default class CustomersTab extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    customers: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        credit_status: PropTypes.string.isRequired,
        contracts: PropTypes.number.isRequired,
        customer_status: PropTypes.string.isRequired,
      })),
      pagination: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
      }),
    }),
    onFetchCustomers: PropTypes.func.isRequired,
  }

  state = {
    page: 1,
    limit: 25,
    sortOrder: {
      name: "NONE",
      credit_limit: "NONE",
      balance: "NONE",
    },
  };

  async componentDidMount() {
    const { page, limit, sortOrder } = this.state;
    const { prospectId, onFetchCustomers } = this.props;

    await onFetchCustomers({
      prospectId,
      pager: { page, limit },
      sortOrder,
    });
  }

  render() {
    const { sortOrder } = this.state;
    const { customers } = this.props;

    return (
      <div className={styles.container}>
        <Table2
          loading={customers.loading}
          data={customers.data}
          onRowClick={this.handleItemClicked}
        >
          <Table2.SortableColumn
            name="name"
            value={row => row.name}
            sortOrder={sortOrder["name"]}
            onClick={this.handleColumnClick}
          >
            Name
          </Table2.SortableColumn>
          <Table2.Column
            collapsed
            name="company_number"
            value={row => row.company_number}
          >
            Company Number
          </Table2.Column>
          <Table2.Column
            collapsed
            name="id"
            value={row => row.id}
          >
            Customer Ref
          </Table2.Column>
          <Table2.SortableColumn
            collapsed
            name="credit_limit"
            sortOrder={sortOrder["credit_limit"]}
            onClick={this.handleColumnClick}
            value={row => `£${row.credit_limit || 0}`}
          >
            Credit Limit
          </Table2.SortableColumn>
          <Table2.SortableColumn
            collapsed
            name="balance"
            value={row => `£${row.balance || 0}`}
            sortOrder={sortOrder["balance"]}
            onClick={this.handleColumnClick}
          >
            Balance
          </Table2.SortableColumn>
          <Table2.Column
            collapsed
            name="status"
            value={row => (
              <StatusLabel
                status={CustomerStatusConverter.to(row.is_dormant ? "dormant" : "live")}
                variant={row.is_dormant ? "dormant" : "live"}
              />
            )}
          >
            Status
          </Table2.Column>
        </Table2>
        {customers.pagination.pages > 0 && (
          <div className={styles.pagination}>
            <Pager
              pageCount={customers.pagination.pages}
              selectedPage={customers.pagination.page}
              onPageChanged={this.handlePageChanged}
            />
          </div>
        )}
      </div>
    );
  }

  handlePageChanged = (n) => {
    this.setState({
      page: n,
    }, this.searchCustomers);
  }

  handleColumnClick = async (name, order) => {
    const newSortOrder = { ...this.state.sortOrder };

    newSortOrder[name] = sorting.nextTransition(name, { [name]: order });

    this.setState({
      sortOrder: newSortOrder,
    }, this.searchCustomers);
  }

  searchCustomers = _.debounce(async () => {
    const { page, limit, sortOrder } = this.state;
    const { prospectId, onFetchCustomers } = this.props;

    await onFetchCustomers({
      prospectId,
      pager: { page, limit },
      sortOrder,
    });
  }, 200);
}
