import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import styles from "./Button.module.scss";

export default function Button(props) {
  const { className, children, disabled, variant, type, busy, onClick } = props;

  const handleClick = (e) => {
    if (busy || disabled) return;

    onClick && onClick(e);
  };

  return (
    <button
      className={classnames(
        styles.container,
        styles[variant.replace(/-(.)/, m => m.substring(1).toUpperCase())],
        busy && styles.busy,
        className,
      )}
      type={type}
      disabled={disabled}
      onClick={handleClick}
    >
      {busy
        ? <FontAwesomeIcon icon={faCircleNotch} spin />
        : children}
    </button>
  );
}

Button.defaultProps = {
  variant: "primary",
  type: "button",
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "danger",
    "warning",
    "outline-primary",
    "outline-danger",
  ]),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  busy: PropTypes.bool,
};
