import React from "react";
import PropTypes from "prop-types";
import { Form } from "../../../../common/components";

export default function AddNewContractAddress(props) {
  return (
    <>
      <Form.Group inline>
        <Form.Label required>
          Address Line 1
        </Form.Label>
        <Form.Input
          placeholder="Enter Address Line 1..."
          name="addressLine1"
          maxLength={255}
          value={props.contact?.addressLine1}
          onChange={props.onChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Label>
          Address Line 2
        </Form.Label>
        <Form.Input
          placeholder="Enter Address Line 2..."
          name="addressLine2"
          maxLength={255}
          value={props.contact?.addressLine2}
          onChange={props.onChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Label required>
          Town / City
        </Form.Label>
        <Form.Input
          placeholder="Enter Town / City..."
          name="townOrCity"
          maxLength={255}
          value={props.contact?.townOrCity}
          onChange={props.onChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Label>
          County
        </Form.Label>
        <Form.Input
          placeholder="Enter County..."
          name="county"
          maxLength={255}
          value={props.contact?.county}
          onChange={props.onChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Label required>
          Postcode
        </Form.Label>
        <Form.Input
          placeholder="Enter Postcode..."
          name="postcode"
          value={props.contact?.postcode}
          size="small"
          maxLength={8}
          onChange={props.onChange}
        />
      </Form.Group>
    </>
  );
}

AddNewContractAddress.propTypes = {
  contact: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    townOrCity: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};
