import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Divider } from "semantic-ui-react";
import { Form } from "../../../common/components";
import styles from "./ChangePasswordForm.module.scss";

export default function ChangePasswordForm(props) {
  return (
    <div className={classnames(styles.container, props.className)}>
      <Form className={classnames(styles.form, props.formClassName)}>
        <Form.Group error={props.errors?.currentPassword}>
          <Form.Label>Current Password</Form.Label>
          <Form.Input
            type="password"
            name="currentPassword"
            value={props.formData.currentPassword}
            disabled={props.submitting}
            highlightError={props.errors?.currentPassword}
            onChange={props.onFormChange}
            fluid
          />
        </Form.Group>
        <Form.Group error={props.errors?.newPassword}>
          <Form.Label>New Password</Form.Label>
          <Form.Input
            type="password"
            name="newPassword"
            value={props.formData.newPassword}
            disabled={props.submitting}
            highlightError={props.errors?.newPassword}
            onChange={props.onFormChange}
            fluid
          />
        </Form.Group>
        <Form.Group error={props.errors?.confirmPassword}>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Input
            type="password"
            name="confirmPassword"
            value={props.formData.confirmPassword}
            disabled={props.submitting}
            highlightError={props.errors?.confirmPassword}
            onChange={props.onFormChange}
            fluid
          />
        </Form.Group>
      </Form>
      <Divider />
    </div>
  );
}

ChangePasswordForm.propTypes = {
  className: PropTypes.string,
  formClassName: PropTypes.string,
  formData: PropTypes.object,
  errors: PropTypes.object,
  submitting: PropTypes.bool,
  onFormChange: PropTypes.func.isRequired,
};
