import React from "react";
import PropTypes from "prop-types";
import { AddRounded } from "@material-ui/icons";
import { Table } from "semantic-ui-react";
import { SortIndicator, TextLink } from "../../../common/components";
import styles from "./CustomerContractsTable.module.scss";
import { getUsersName } from "../../../../utils";

export default function CustomerContractsTable(props) {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          Contracts
        </div>

        <div
          className={styles.addNewContractContainer}
          onClick={props.onAddContract}
        >
          <AddRounded className={styles.addNewContractIcon}/>
          <div className={styles.addNewContractTitle}>
            Add Contract
          </div>
        </div>
      </div>
      <Table className={styles.table} basic>
        <Table.Header>
          <Table.HeaderCell className={styles.sortableHeader}>
            <div>
              Contract Name
              <SortIndicator className={styles.sortIndicator} />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.sortableHeader}>
            <div>
              Contact Name
              <SortIndicator className={styles.sortIndicator} />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.sortableHeader}>
            <div>
              Contact Telephone
              <SortIndicator className={styles.sortIndicator} />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.sortableHeader}>
            <div>
              Account Reference
              <SortIndicator className={styles.sortIndicator} />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.sortableHeader}>
            <div>
              Contract Status
              <SortIndicator className={styles.sortIndicator} />
            </div>
          </Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          {(props.contracts ?? []).length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan="5" className={styles.noResults}>
                There are no Contracts available to view.
              </Table.Cell>
            </Table.Row>
          ) : (
            props.contracts.map(contract => (
              <Table.Row key={contract.id}>
                <Table.Cell>
                  <TextLink
                    className={styles.contractName}
                    text={contract.display_name}
                    to={`/customers/${props.customerId}/contracts/${contract.id}`}
                  />
                </Table.Cell>
                <Table.Cell>{getUsersName(contract.account_contact)}</Table.Cell>
                <Table.Cell>{contract.account_contact?.telephone_number}</Table.Cell>
                <Table.Cell>{contract.customer_account_reference?.reference ?? "-"}</Table.Cell>
                <Table.Cell>{contract.status ?? "-"}</Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
}

CustomerContractsTable.propTypes = {
  customerId: PropTypes.string.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    account_contact: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      telephone_number: PropTypes.string.isRequired,
    }).isRequired,
    customer_account_reference: PropTypes.shape({
      reference: PropTypes.string.isRequired,
    }),
    status: PropTypes.string,
  })),
  onAddContract: PropTypes.func.isRequired,
};
