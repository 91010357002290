const formatSortCode = (value) => {
  if (!value) {
    return;
  }

  const input = value.replace(/\D/g,"").substring(0,6);
  return input ? input.match(/.{1,2}/g).join("-") : "";
};

export default formatSortCode;
