import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import EditPaymentDetailsModalValidator from "./EditPaymentDetailsModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import { options } from "../../../../constants";
import styles from "./EditPaymentDetailsModal.module.scss";

const ALLOWED_NUMBER_OF_DAYS = [...Array(99)].map((_, i) => ({
  label: `${i + 1}`,
  value: String(i + 1),
}));
const ALLOWED_DAYS_OF_MONTH = [...Array(31)].map((_, i) => ({
  label: moment(i + 1, "DD").format("Do"),
  value: String(i + 1),
}));

export default function EditPaymentDetailsModal(props) {
  const [formData, setFormData] = useState({
    isPurchaseOrderNumberRequired: false,
    agreedPaymentTermsType: null,
    agreedPaymentTermsValue: null,
  });
  const [errors, setErrors] = useState({});

  const { customerAccountId, payrollAgencyCustomerRequestId, getCustomerAccount } = props;

  useEffect(() => {
    getCustomerAccount({
      customerAccountId,
      payrollAgencyCustomerRequestId,
    });
  }, [ customerAccountId, payrollAgencyCustomerRequestId, getCustomerAccount]);

  useEffect(() => {
    if (!props.submitting) {
      setFormData({
        isPurchaseOrderNumberRequired: !!props.customerAccount?.is_purchase_order_number_required,
        agreedPaymentTermsType: props.customerAccount?.agreed_payment_terms_type || options.AGREED_PAYMENT_TERMS_OPTIONS[0]?.value,
        agreedPaymentTermsValue: props.customerAccount?.agreed_payment_terms_value,
      });
    }
  }, [props]);

  const handleSubmit = async () => {
    const validator = new EditPaymentDetailsModalValidator(formData);
    const { success, errors } = validator.validate();

    setErrors(errors);

    if (!success) {
      return;
    }

    await props.updatePaymentDetails({
      customerAccountId: props.customerAccountId,
      paymentDetails: formData,
    });
    props.hideModal(EditPaymentDetailsModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(EditPaymentDetailsModal.displayName);
  };

  const handleOnChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleRadioChange = e => setFormData({
    ...formData,
    [e.target.name]: String(e.target.value) === "true",
  });

  return (
    <ConfirmationModal
      className={styles.container}
      title="Edit Payment Details"
      visible={props.visible}
      submitText="Save"
      submitDisabled={props.loading}
      busy={props.submitting}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <Form requiredKey>
        <Form.Section title="Purchase Order">
          <Form.Group inline wide error={errors?.isPurchaseOrderNumberRequired}>
            <Form.Label className={styles.label} inline required>
              Is a P.O Number Required?
            </Form.Label>
            <Form.RadioContainer>
              <Form.Radio
                name="isPurchaseOrderNumberRequired"
                text="Yes"
                value={true}
                disabled={props.submitting || props.loading}
                checked={formData.isPurchaseOrderNumberRequired}
                onChange={handleRadioChange}
              />
              <Form.Radio
                name="isPurchaseOrderNumberRequired"
                text="No"
                value={false}
                disabled={props.submitting || props.loading}
                checked={!formData.isPurchaseOrderNumberRequired}
                onChange={handleRadioChange}
              />
            </Form.RadioContainer>
          </Form.Group>
        </Form.Section>
        <Form.Section title="Agreed Payment Terms">
          <Form.Group inline wide error={errors?.agreedPaymentTermsType}>
            <Form.Label className={styles.label} inline required>
              Agreed Payment Terms
            </Form.Label>
            <Form.Select
              name="agreedPaymentTermsType"
              value={formData.agreedPaymentTermsType}
              options={options.AGREED_PAYMENT_TERMS_OPTIONS}
              disabled={props.submitting || props.loading}
              highlightError={errors?.agreedPaymentTermsType}
              onChange={handleOnChange}
            />
          </Form.Group>
          {formData.agreedPaymentTermsType === "days_after_invoice_date" && (
            <Form.Group inline wide error={errors?.agreedPaymentTermsValue}>
              <Form.Label className={styles.label} inline required>
                Number of days after invoice
              </Form.Label>
              <Form.Select
                name="agreedPaymentTermsValue"
                value={formData.agreedPaymentTermsValue}
                options={ALLOWED_NUMBER_OF_DAYS}
                disabled={props.submitting || props.loading}
                highlightError={errors?.agreedPaymentTermsValue}
                onChange={handleOnChange}
              />
            </Form.Group>
          )}
          {formData.agreedPaymentTermsType === "day_of_month_following_invoice" && (
            <Form.Group inline wide error={errors?.agreedPaymentTermsValue}>
              <Form.Label className={styles.label} inline required>
                Day of month following invoice
              </Form.Label>
              <Form.Select
                name="agreedPaymentTermsValue"
                value={formData.agreedPaymentTermsValue}
                options={ALLOWED_DAYS_OF_MONTH}
                disabled={props.submitting || props.loading}
                highlightError={errors?.agreedPaymentTermsValue}
                onChange={handleOnChange}
              />
            </Form.Group>
          )}
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

EditPaymentDetailsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  customerAccountId: PropTypes.string.isRequired,
  payrollAgencyCustomerRequestId: PropTypes.string.isRequired,
  customerAccount: PropTypes.shape({
    is_purchase_order_number_required: PropTypes.bool,
    agreed_payment_terms_type: PropTypes.string,
    agreed_payment_terms_value: PropTypes.number,
  }),
  getCustomerAccount: PropTypes.func.isRequired,
  updatePaymentDetails: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

EditPaymentDetailsModal.displayName = "EditPaymentDetailsModal";
