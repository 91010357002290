import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { isValidTelephone } from "../../../../../utils";
import NonRegisteredCompanyDetailsFormValidator from "./NonRegisteredCompanyDetailsFormValidator";
import styles from "./NonRegisteredCompanyDetailsForm.module.scss";

export default function NonRegisteredCompanyDetailsForm(props) {
  const [formData, setFormData] = useState({
    name: props.data?.name,
    addressLine1: props.data?.company_address?.address_line_one,
    addressLine2: props.data?.company_address?.address_line_two,
    townOrCity: props.data?.company_address?.town_or_city,
    county: props.data?.company_address?.county,
    postcode: props.data?.company_address?.postcode,
    telephone: props.data?.telephone,
    emailAddress: props.data?.email_address,
    website: props.data?.website,
    isRegisteredCharity: props.data?.__typename === "Charity",
    registeredCharityNumber: props.data?.registered_charity_number ?? null,
  });

  const [errors, setErrors] = useState({});

  const handleFormChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });

  const handleRadioChange = e => setFormData({
    ...formData,
    [e.target.name]: String(e.target.value) === "true",
  });

  const handleTelephoneChange = (e) => {
    if (!isValidTelephone(e.target.value)) {
      return;
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const canComplete = () => {
    if (!formData.name) {
      return false;
    }
    if (!formData.addressLine1) {
      return false;
    }
    if (!formData.townOrCity) {
      return false;
    }
    if (!formData.county) {
      return false;
    }
    if (!formData.postcode) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const validator = new NonRegisteredCompanyDetailsFormValidator(formData);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }

    props.onSubmit(formData);
  };

  return (
    <Grid className={styles.grid} stackable reversed="mobile">
      <Grid.Column width={14}>
        <Form className={styles.form}>
          <Form.Section title="Company Details" titleClassName={styles.title}>
            <Form.Group inline wide>
              <Form.Label inline required>Business Name</Form.Label>
              <Form.Input
                name="name"
                type="text"
                value={formData.name}
                placeholder="Enter Business Name..."
                size="large"
                onChange={handleFormChange}
                maxLength={255}
              />
            </Form.Group>

            <Form.Group inline wide>
              <Form.Label inline required>Address Line 1</Form.Label>
              <Form.Input
                name="addressLine1"
                type="text"
                value={formData.addressLine1}
                placeholder="Address Line 1..."
                size="large"
                onChange={handleFormChange}
                maxLength={255}
              />
            </Form.Group>

            <Form.Group inline wide>
              <Form.Label inline>Address Line 2</Form.Label>
              <Form.Input
                name="addressLine2"
                type="text"
                value={formData.addressLine2}
                placeholder="Address Line 2..."
                size="large"
                onChange={handleFormChange}
                maxLength={255}
              />
            </Form.Group>


            <Form.Group inline wide>
              <Form.Label inline required>Town/City</Form.Label>
              <Form.Input
                name="townOrCity"
                type="text"
                value={formData.townOrCity}
                placeholder="Town or City..."
                size="large"
                onChange={handleFormChange}
                maxLength={255}
              />
            </Form.Group>

            <Form.Group inline wide>
              <Form.Label inline required>County</Form.Label>
              <Form.Input
                name="county"
                type="text"
                value={formData.county}
                placeholder="County..."
                size="large"
                onChange={handleFormChange}
                maxLength={255}
              />
            </Form.Group>

            <Form.Group inline wide>
              <Form.Label inline required>Postcode</Form.Label>
              <Form.Input
                name="postcode"
                type="text"
                value={formData.postcode}
                placeholder="Postcode..."
                size="small"
                onChange={handleFormChange}
                maxLength={8}
              />
            </Form.Group>

            <Form.Group inline wide error={errors.telephone}>
              <Form.Label inline>Telephone Number</Form.Label>
              <Form.Input
                name="telephone"
                type="text"
                value={formData.telephone}
                placeholder="Enter Phone Number..."
                size="small"
                onChange={handleTelephoneChange}
                highlightError={errors.telephone}
                maxLength={30}
              />
            </Form.Group>

            <Form.Group inline wide error={errors.emailAddress}>
              <Form.Label inline>Email Address</Form.Label>
              <Form.Input
                name="emailAddress"
                type="email"
                value={formData.emailAddress}
                placeholder="Enter Email Address..."
                size="large"
                onChange={handleFormChange}
                highlightError={errors.emailAddress}
                maxLength={255}
              />
            </Form.Group>

            <Form.Group inline wide>
              <Form.Label inline>Website</Form.Label>
              <Form.Input
                name="website"
                type="text"
                value={formData.website}
                placeholder="Website..."
                size="large"
                onChange={handleFormChange}
                maxLength={255}
              />
            </Form.Group>

            <Form.Group className={styles.radioGroup} inline wide>
              <Form.Label className={styles.radioLabel} inline required>
                Is the company a charity?
              </Form.Label>
              <Form.RadioContainer>
                <Form.Radio
                  value={true}
                  name="isRegisteredCharity"
                  checked={formData.isRegisteredCharity}
                  text="Yes"
                  onChange={handleRadioChange}
                />
                <Form.Radio
                  value={false}
                  name="isRegisteredCharity"
                  checked={!formData.isRegisteredCharity}
                  text="No"
                  onChange={handleRadioChange}
                />
              </Form.RadioContainer>
            </Form.Group>

            {formData.isRegisteredCharity && (
              <Form.Group inline wide>
                <Form.Label inline>Charity Number</Form.Label>
                <Form.Input
                  name="registeredCharityNumber"
                  type="text"
                  value={formData.registeredCharityNumber}
                  placeholder="Enter Charity Number..."
                  size="small"
                  onChange={handleFormChange}
                  maxLength={100}
                />
              </Form.Group>
            )}
          </Form.Section>
        </Form>
      </Grid.Column>
      <Grid.Column floated="right">
        <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
          <Button
            variant="primary"
            busy={props.submitting}
            disabled={!canComplete()}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            variant="outline-danger"
            disabled={props.submitting}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Grid.Column>
    </Grid>
  );
}

NonRegisteredCompanyDetailsForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  data: PropTypes.shape({

    registered_charity_number: PropTypes.string,
    __typename: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
