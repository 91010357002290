import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Info } from "@material-ui/icons";
import ConvertToAgencyFormValidator from "./ConvertToAgencyFormValidator";
import {
  ButtonGroup,
  Button,
  Form,
  TextBlock,
  TextLink,
} from "../../../common/components";
import { List } from "../../../lists/containers";
import { isAlphaNumeric } from "../../../../utils/string";
import styles from "./ConvertToAgencyForm.module.scss";

const ALLOWED_AGENCY_STATUSES = [
  "live_active",
  "live_not_active",
];

export default class ConvertToAgencyForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      workerTypes: PropTypes.array,
      isNewAgency: PropTypes.bool,
      isLedgerBuyout: PropTypes.bool,
      isExistingPayeScheme: PropTypes.bool,
      previousProvider: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      introducedViaBroker: PropTypes.bool,
      knownAs: PropTypes.string,
      tradingAs: PropTypes.string,
    }),
    initialState: PropTypes.object,
    shortCodeAvailability: PropTypes.shape({
      loading: PropTypes.bool,
      isAvailable: PropTypes.bool,
    }),
    showContractualAgreementWarning: PropTypes.bool,
    submitting: PropTypes.bool,
    onCheckShortCodeAvailability: PropTypes.func,
    onViewExistingAgencyShortCodes: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        ...props.data,
        shortCode: "",
        agencyStatus: {
          value: "live_active",
        },
        isLedgerBuyout: !!props.data.isLedgerBuyout,
        isExistingPayeScheme: !!props.data.isExistingPayeScheme,
        ...props.initialState,
      },
      errors: {},
    };
  }

  async componentDidMount() {
    await this.validateShortCode();
  }

  render() {
    return (
      <Form className={this.props.className}>
        {this.renderRequiredQuestionsSection()}
        {this.renderWarnings()}
        <ButtonGroup className={styles.actions}>
          <Button
            variant="outline-danger"
            disabled={this.props.submitting}
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            busy={this.props.submitting}
            disabled={this.props.shortCodeAvailability.loading}
            onClick={this.handleSubmit}
          >
            Continue
          </Button>
        </ButtonGroup>
      </Form>
    );
  }

  renderRequiredQuestionsSection = () => {
    return (
      <Form.Section title="Required Questions">
        <TextBlock
          className={styles.reviewBlock}
          variant="primary"
          content="Please review the questions below, and ensure that the answers given are still valid"
        />
        <Form.Group error={this.state.errors?.workerTypes}>
          <Form.Label>Type of workers?</Form.Label>
          <List
            identifier="worker_types"
            value={this.state.data.workerTypes}
            onChange={this.handleWorkerTypeChange}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.isNewAgency}>
          <Form.Label>Is the agency new or existing?</Form.Label>
          <List
            identifier="agency_new"
            name="isNewAgency"
            value={String(this.state.data.isNewAgency)}
            onChange={this.handleNewOrExistingChange}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.isLedgerBuyout}>
          <Form.Label>Ledger Buy-out?</Form.Label>
          {!this.props.data.isNewAgency && _.isNull(this.props.data.isLedgerBuyout) && this.renderReviewNote()}
          <Form.Radio
            value={true}
            name="isLedgerBuyout"
            checked={this.state.data.isLedgerBuyout}
            text="Yes"
            disabled={this.state.data.isNewAgency}
            onChange={this.handleRadioChange}
          />
          <Form.Radio
            value={false}
            name="isLedgerBuyout"
            checked={this.state.data.isLedgerBuyout === false}
            text="No"
            disabled={this.state.data.isNewAgency}
            onChange={this.handleRadioChange}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.previousProvider}>
          <Form.Label>Previous Provider (Optional)</Form.Label>
          <Form.Value size="medium">
            <List
              className={styles.previousProviders}
              identifier="previous_provider"
              name="previousProvider"
              value={this.state.data.previousProvider?.value}
              disabled={this.state.data.isNewAgency}
              onChange={this.handlePreviousProviderChange}
              fluid
            />
          </Form.Value>
        </Form.Group>
        <Form.Group error={this.state.errors?.isExistingPayeScheme}>
          <Form.Label>Existing PAYE Scheme?</Form.Label>
          {_.isNull(this.props.data.isExistingPayeScheme) && this.renderReviewNote()}
          <Form.Radio
            value={true}
            name="isExistingPayeScheme"
            checked={this.state.data.isExistingPayeScheme}
            text="Yes"
            onChange={this.handleRadioChange}
          />
          <Form.Radio
            value={false}
            name="isExistingPayeScheme"
            checked={this.state.data.isExistingPayeScheme === false}
            text="No"
            onChange={this.handleRadioChange}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.knownAs}>
          <Form.Label>Known As</Form.Label>
          <Form.Input
            name="knownAs"
            value={this.state.data.knownAs}
            disabled={this.props.submitting}
            onChange={this.handleInputChange}
            maxLength={50}
            highlightError={this.state.errors?.knownAs}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.tradingAs}>
          <Form.Label>Trading As (Optional)</Form.Label>
          <Form.Input
            name="tradingAs"
            value={this.state.data.tradingAs}
            maxLength={255}
            disabled={this.props.submitting}
            onChange={this.handleInputChange}
            highlightError={this.state.errors?.tradingAs}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.introducedViaBroker}>
          <Form.Label>Introduced via broker?</Form.Label>
          <Form.Radio
            value={true}
            name="introducedViaBroker"
            checked={this.state.data.introducedViaBroker}
            text="Yes"
            onChange={this.handleRadioChange}
          />
          <Form.Radio
            value={false}
            name="introducedViaBroker"
            checked={!this.state.data.introducedViaBroker}
            text="No"
            onChange={this.handleRadioChange}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.shortCode}>
          <Form.Label>Short Code</Form.Label>
          <Form.Input
            name="shortCode"
            value={this.state.data.shortCode}
            maxLength={2}
            disabled={this.props.submitting || this.props.shortCodeAvailability.loading}
            onChange={this.handleShortCodeChange}
            highlightError={this.state.errors?.shortCode}
          />
          <TextLink
            className={styles.link}
            text="View existing Short Codes"
            onClick={this.handleViewExistingShortCodesClicked}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.agencyStatus}>
          <Form.Label>Agency Status</Form.Label>
          <Form.Value size="medium">
            <List
              className={styles.agencyStatuses}
              name="agencyStatus"
              identifier="agency_status"
              value={this.state.data.agencyStatus?.value}
              onChange={this.handleAgencyStatusChange}
              onReady={this.handleAgencyStatusReady}
              fluid
              highlightError={this.state.errors?.agencyStatus}
              optionsParser={options => options.filter((option) => {
                return ALLOWED_AGENCY_STATUSES.includes(option.value);
              })}
            />
          </Form.Value>
        </Form.Group>
      </Form.Section>
    );
  }

  renderWarnings = () => {
    return this.props.showContractualAgreementWarning && (
      <TextBlock
        className={styles.contractualWarningBlock}
        variant="primary"
      >
        <div className={styles.title}>
          The Contractual Agreement onboarding task needs to be completed as soon as possible.
        </div>
        <div className={styles.content}>
          Please review this as soon as you can.
        </div>
      </TextBlock>
    );
  }

  renderReviewNote = () => {
    return (
      <Form.Note
        className={styles.formNote}
        icon={(
          <Info className={styles.icon} />
        )}
        text="This answer may have changed. Please review it."
      />
    );
  }

  handleRadioChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: String(e.target.value) === "true",
      },
    });
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleWorkerTypeChange = (workerTypeId) => {
    const workerTypes = this.state.data.workerTypes;

    this.setState({
      data: {
        ...this.state.data,
        workerTypes: workerTypes.find(x => x.id === workerTypeId)
          ? workerTypes.filter(workerType => workerType.id !== workerTypeId)
          : [...workerTypes, { id: workerTypeId }],
      },
    });
  }

  handleNewOrExistingChange = (e) => {
    const data = String(e.target.value) === "true"
      ? { ...this.state.data, isNewAgency: true, isLedgerBuyout: false }
      : { ...this.state.data, isNewAgency: false };

    this.setState({ data });
  }

  handlePreviousProviderChange = (_e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        previousProvider: item,
      },
    });
  }

  handleShortCodeChange = (e) => {
    if (!isAlphaNumeric(e.target.value)) {
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: _.toUpper(e.target.value),
      },
    }, this.validateShortCode);
  }

  handleAgencyStatusChange = (_e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        agencyStatus: item,
      },
    });
  }

  handleAgencyStatusReady = (listProps) => {
    // Only set the state if we haven't already.
    if (!this.state.data?.agencyStatus?.id) {
      this.setState({
        data: {
          ...this.state.data,
          agencyStatus: listProps.data?.values?.find(value => value.is_default),
        },
      });
    }
  }

  handleViewExistingShortCodesClicked = () => {
    const { data } = this.state;
    const { onViewExistingAgencyShortCodes } = this.props;

    onViewExistingAgencyShortCodes && onViewExistingAgencyShortCodes(data);
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = async () => {
    const { data } = this.state;
    const { onSubmit } = this.props;

    const validator = new ConvertToAgencyFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit && onSubmit(data, this.props);
  }

  validateShortCode = _.debounce(async () => {
    const { onCheckShortCodeAvailability } = this.props;
    const { shortCode } = this.state.data;

    shortCode.length === 2 && onCheckShortCodeAvailability && onCheckShortCodeAvailability({
      shortCode: this.state.data.shortCode,
    });
  }, 200, { trailing: true });

}
