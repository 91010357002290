import _ from "lodash";
import moment from "moment";

const isOverdue = (startDate, dueDate) => {
  if (_.isNil(startDate) || _.isNil(dueDate)) return false;

  const start = moment(startDate);
  const due = moment(dueDate);

  return due.diff(start, "days") <= 0;
};

export default isOverdue;
