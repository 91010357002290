import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_CONTACT = gql`
mutation($id: ID!, $input: UpdateAgencyContactInput!) {
  updateAgencyContact(id: $id, input: $input) {
    id
    first_name
    last_name
    preferred_name
    email_address
    contact_numbers {
      phone_number_type {
        id
        label
        value
      }
      phone_number
      is_preferred_number
    }
    job_role
    is_primary_contact
    ni_number
    archived
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @param {object} obj.contact
 * @param {number} obj.contact.id
 * @param {string} obj.contact.firstName
 * @param {string} obj.contact.lastName
 * @param {string} obj.contact.preferredName
 * @param {string} obj.contact.emailAddress
 * @param {string} obj.contact.jobRole
 * @param {boolean} obj.contact.primaryContact
 * @param {string} obj.contact.niNumber
 * @param {object[]} obj.contact.contactNumbers
 * @param {object} obj.contact.contactNumbers[].phoneNumberType
 * @param {number} obj.contact.contactNumbers[].phoneNumberType.id
 * @param {string} obj.contact.contactNumbers[].phoneNumber
 * @param {boolean} obj.contact.contactNumbers[].isPreferredNumber
 */
export const updateAgencyContact = ({ contact }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_CONTACT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_AGENCY_CONTACT,
      variables: {
        id: contact.id,
        input: {
          first_name: contact.firstName,
          last_name: contact.lastName,
          preferred_name: contact.preferredName,
          email_address: contact.emailAddress,
          job_role: contact.jobRole,
          is_primary_contact: contact.primaryContact,
          ni_number: contact.niNumber,
          contact_numbers: contact.contactNumbers.map(contactNumber => ({
            phone_number_type_id: contactNumber.phoneNumberType.id,
            phone_number: contactNumber.phoneNumber,
            is_preferred_number: contactNumber.isPreferredNumber,
          })),
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_CONTACT_SUCCESS,
      payload: {
        agencyContact: response.data.updateAgencyContact,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_CONTACT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
