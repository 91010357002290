import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AUTO_ENROLMENT_PENSION_INFO = gql`
query(
  $agency_id: ID!
  $disabled_field_filter_options: AgencyDisabledFieldsFilterOptions!
) {
  getAgency(id: $agency_id) {
    auto_enrolment_pension_info {
      pension_company {
        id
        value
      }
      pension_scheme_weekly
      pension_scheme_monthly
      staging_date
      payment_source_reference_weekly
      payment_source_reference_monthly
      default_postponement_months
      eligible_job_holder_postponement_months
      employer_pension_reference
    }

    is_new_agency
    previous_pension_provider {
      id
      value
    }

    preferences {
      payroll_frequency
    }

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      agency_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 */
export const getAutoEnrolmentPensionInfo = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AUTO_ENROLMENT_PENSION_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AUTO_ENROLMENT_PENSION_INFO,
      variables: {
        agency_id: agencyId,
        disabled_field_filter_options: {
          field_names: [
            "auto_enrolment_pensions.pension_company",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AUTO_ENROLMENT_PENSION_INFO_SUCCESS,
      payload: {
        data: {
          ...response.data.getAgency.auto_enrolment_pension_info,
          ...response.data.getAgency.preferences,
          is_new_agency: response.data.getAgency.is_new_agency,
          previous_pension_provider: response.data.getAgency.previous_pension_provider,
          disabled_fields: response.data.getAgency.disabled_fields,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AUTO_ENROLMENT_PENSION_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
