import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { parseDate } from "../../../../../utils";
import styles from "./FormDateTime.module.scss";

export default function FormDateTime({
  className,
  value,
  name,
  size,
  readOnly,
  disabled,
  highlightError,
  onChange,
  ...otherProps
}) {

  const parseValue = () => {
    if (!value) return "-";
    if (disabled || readOnly) return parseDate(value, "YYYY/MM/DD HH:mm");
    return moment(value).format("YYYY-MM-DDTHH:mm");
  };

  const handleInputChange = (e) => {
    if (disabled || readOnly) return;

    onChange && onChange({
      ...e,
      target: {
        name: e.target.name,
        value: moment(e.target.value).valueOf(),
      },
    });
  };

  const renderInput = () => {
    return (
      <input
        {...otherProps}
        type={disabled || readOnly ? "text" : "datetime-local"}
        className={styles.input}
        name={name}
        onChange={handleInputChange}
        value={parseValue()}
        disabled={disabled}
        readOnly={readOnly}
      />
    );
  };
  return (
    <div
      className={classnames(
        styles.container,
        styles[size],
        className,
        disabled && styles.disabled,
        readOnly && styles.readOnly,
        highlightError && styles.highlightError,
      )}
    >
      {renderInput()}
    </div>
  );
}


FormDateTime.defaultProps = {
  size: "medium",
};

FormDateTime.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  highlightError: PropTypes.string,
  onChange: PropTypes.func,
};
