import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Modal, Form } from "../../../common/components";

export default class CreditApprovedModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  initialState = {
    formSubmitting: false,
    formData: {
      completedText: "",
    },
  };

  state = this.initialState;

  render() {
    const { formSubmitting, formData } = this.state;
    const { completedText } = formData;
    const { visible } = this.props;

    return (
      <Modal
        title="Cancel Credit Application"
        subtitle="You must provide a reason for the cancellation. The cancellation will be recorded in the credit application history log for reference."
        visible={visible}
        primaryButtonDisabled={formSubmitting}
        onSubmit={this.handleCreditCancelledClicked}
        onCancel={this.handleCancelModalClicked}
        submitText="Save"
      >
        <Form>
          <Form.Section>
            <Form.Group>
              <Form.TextArea
                placeholder="Leave a comment (Providing a reason for cancellation)"
                name="completedText"
                value={completedText}
                onChange={this.handleFormChange}
              />
            </Form.Group>
          </Form.Section>
        </Form>
      </Modal>
    );
  }

  handleFormChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCreditCancelledClicked = _.throttle(async () => {
    const { formData } = this.state;
    const { onSubmit } = this.props;

    this.setState({
      formSubmitting: true,
    });

    onSubmit && await onSubmit(formData);

    this.setState(this.initialState);
  }, 1000)

  handleCancelModalClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();

    this.setState(this.initialState);
  }
}
