import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import {
  Checkbox,
  Heading,
  ItemCountSelector,
  Pager,
  SearchInput,
} from "../../../common/components";
import { OnboardingEntityList } from "../../components";
import styles from "./ViewAsProspectPage.module.scss";

export default class ViewAsProspectPage extends Component {

  static propTypes = {
    onboardingProspects: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        prospect: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        tasks: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          onboarding_task: PropTypes.shape({
            name: PropTypes.string.isRequired,
          }),
          definition: PropTypes.shape({
            subtasks: PropTypes.arrayOf(PropTypes.shape({
              name: PropTypes.string.isRequired,
              required: PropTypes.bool.isRequired,
              complete: PropTypes.bool.isRequired,
            })),
          }),
          status: PropTypes.string.isRequired,
          progress: PropTypes.shape({
            required_total: PropTypes.number.isRequired,
            required_completed: PropTypes.number.isRequired,
            optional_total: PropTypes.number.isRequired,
            optional_completed: PropTypes.number.isRequired,
          }),
          completed_at: PropTypes.string,
          is_applicable: PropTypes.bool,
        })),
      })),
      pagination: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
      }),
    }),
    onFetchOnboardingProspects: PropTypes.func.isRequired,
    onGoToTask: PropTypes.func.isRequired,
  }

  state = {
    searchText: "",
    page: 1,
    limit: 25,
    showOnboardedProspects: false,
    showCompletedTasks: false,
  };


  async componentDidMount() {
    await this.fetchOnboardingProspects();
  }

  render() {
    const { searchText, showOnboardedProspects, showCompletedTasks } = this.state;
    const { onboardingProspects } = this.props;

    return (
      <div className={styles.container}>
        <Heading
          className={styles.header}
          title="Onboarding"
        />
        <div className={onboardingProspects?.loading && styles.loading}>
          <div className={styles.filterRow}>
            <SearchInput
              name="searchText"
              value={searchText}
              placeholder={"Search"}
              onChange={this.handleChange}
              onSearch={this.handleSearch}
            />
          </div>
          <div className={styles.filters}>
            <Checkbox
              text="Show Onboarded Prospects"
              checked={showOnboardedProspects}
              onClick={this.handleOnboardedProspectsToggle}
            />
            <Checkbox
              text="Show Completed Tasks"
              checked={showCompletedTasks}
              onClick={this.handleCompletedTasksToggle} />
          </div>
          <div className={styles.taskList}>
            {onboardingProspects?.loading ? (
              <ActivityIndicator />
            ) : (
              <OnboardingEntityList
                data={(onboardingProspects?.data || []).map(onboardingProspect => ({
                  ...onboardingProspect,
                  tasks: onboardingProspect.tasks.filter(t => t.is_applicable),
                  entity: {
                    id: onboardingProspect.prospect.id,
                    name: onboardingProspect.prospect.known_as,
                    entityType: "prospect",
                  },
                }))}
                showCompletedTasks={showCompletedTasks}
                onViewTaskClick={this.handleViewTaskClick}
              />
            )}
          </div>
          {onboardingProspects?.data.length > 0 && (
            <div className={styles.pagination}>
              <Pager
                pageCount={onboardingProspects.pagination.pages}
                selectedPage={onboardingProspects.pagination.page}
                onPageChanged={this.handlePageChanged}
              />
              <ItemCountSelector
                value={onboardingProspects.pagination.limit}
                onChange={this.handleItemCountChanged}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleOnboardedProspectsToggle = () => {
    const { showOnboardedProspects } = this.state;

    this.setState({
      page: 1,
      showOnboardedProspects: !showOnboardedProspects,
    }, this.fetchOnboardingProspects);
  }

  handleCompletedTasksToggle = () => {
    const { showCompletedTasks } = this.state;

    this.setState({
      showCompletedTasks: !showCompletedTasks,
    });
  }

  handleViewTaskClick = ({ entity, task }) => {
    const { onGoToTask } = this.props;

    onGoToTask(entity.id, task.id);
  }

  handlePageChanged = (n) => {
    this.setState({
      page: n,
    }, this.fetchOnboardingProspects);
  }

  handleItemCountChanged = (e) => {
    this.setState({
      page: 1,
      limit: e.value,
    }, this.fetchOnboardingProspects);
  }

  handleSearch = () => {
    this.setState({
      page: 1,
    }, this.fetchOnboardingProspects);
  };

  fetchOnboardingProspects = () => {
    const { onFetchOnboardingProspects } = this.props;
    const { searchText, showOnboardedProspects, page, limit } = this.state;

    onFetchOnboardingProspects({
      pager: {
        page,
        limit,
      },
      searchText,
      completed: showOnboardedProspects,
    });
  }

}
