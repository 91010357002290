import moment from "moment";

const parseDate = (date, format = "DD/MM/YYYY") => {
  if (!date) {
    return "";
  }
  return moment(date).format(format);
};

export default parseDate;
