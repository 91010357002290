import _ from "lodash";
import { LOCATION_CHANGE } from "connected-react-router";
import * as types from "./types";

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        ...state,
        [action.payload.componentName]: action.payload.props,
        updatedAt: new Date(), // Needed to make the component update
      };

    case types.HIDE_MODAL:
      return _.omit(state, action.payload.componentName);

    case LOCATION_CHANGE:
      return initialState;

    default:
      return state;
  }
}
