import React from "react";
import PropTypes from "prop-types";
import { Button, Form } from "../../../common/components";
import styles from "./SelfBillingOrganisationLookUpForm.module.scss";

export default function SelfBillingOrganisationLookUpForm(props) {
  return (
    <Form
      className={styles.container}
      title="Companies House Details"
      requiredKey
    >
      <Form.Section>
        <Form.Group inline>
          <Form.Label>Registered Company Number</Form.Label>
          <Form.Input
            name="registeredCompanyNumber"
            placeholder="Enter number..."
            value={props.data.registeredCompanyNumber}
            size="small"
            onChange={props.onFormChange}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Label>Exact Legal Name</Form.Label>
          <Form.Input
            name="exactLegalName"
            placeholder="Enter exact legal name..."
            value={props.data.exactLegalName}
            onChange={props.onFormChange}
          />
        </Form.Group>
        <Button
          className={styles.searchButton}
          variant="primary"
          disabled={!props.data.registeredCompanyNumber || !props.data.exactLegalName}
          onClick={props.onSubmit}
        >
        Search Companies House
        </Button>
      </Form.Section>
    </Form>
  );
}

SelfBillingOrganisationLookUpForm.propTypes = {
  data: PropTypes.shape({
    registeredCompanyNumber: PropTypes.string,
    exactLegalName: PropTypes.string,
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
