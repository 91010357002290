import React from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Table } from "semantic-ui-react";
import DocumentRow from "./DocumentRow/DocumentRow";
import styles from "./DocumentList.module.scss";

export default function DocumentList(props) {
  if (props.loading) {
    return <ActivityIndicator />;
  }

  const renderRows = () => {
    if (props.documents?.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan="4" className={styles.noResults}>
            There are no documents available to view.
          </Table.Cell>
        </Table.Row>
      );
    }

    return props.documents?.map(document => (
      <DocumentRow
        key={`document_list_${document.id}`}
        document={document}
        prospectId={props.prospectId}
        documentTypes={props.documentTypes}
        onDownloadDocument={props.onDownloadDocument}
        onDeleteClick={props.onDeleteDocumentClick}
        onVersionHistoryClick={props.onVersionHistoryClick}/>
    ));
  };

  return (
    <Table className={styles.container} basic>
      <Table.Header>
        <Table.HeaderCell>
          Document
        </Table.HeaderCell>
        <Table.HeaderCell>
          Uploaded By
        </Table.HeaderCell>
        <Table.HeaderCell>
          Upload Date
        </Table.HeaderCell>
        <Table.HeaderCell className={styles.iconsColumn}>
          {/* This is for the download/delete icons */}
        </Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {renderRows()}
      </Table.Body>
    </Table>
  );
}

DocumentList.propTypes = {
  loading: PropTypes.bool,
  documents: PropTypes.array,
  prospectId: PropTypes.string.isRequired,
  documentTypes: PropTypes.array,
  onDownloadDocument: PropTypes.func.isRequired,
  onDeleteDocumentClick: PropTypes.func.isRequired,
  onVersionHistoryClick: PropTypes.func.isRequired,
};
