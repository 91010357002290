import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Link } from "react-router-dom";
import * as Tabs from "../../tabs";
import { Button, Heading, TabControl } from "../../../common/components";
import {
  AgencyCustomerRequestAlert,
  CreditApplicationActiveLabel,
  CreditSuspendedLabel,
  CreditSuspensionScheduledLabel,
  LegalEntityReportAlert,
} from "../../components";
import styles from "./ViewLegalEntityPage.module.scss";

export default class ViewLegalEntityPage extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    legalEntity: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        company_number: PropTypes.string,
        company_type: PropTypes.string,
        company_status: PropTypes.string,
        created_at: PropTypes.number,
        active_suspension: PropTypes.shape({
          date_of_effect: PropTypes.string.isRequired,
        }),
        scheduled_suspension: PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          date_of_effect: PropTypes.string.isRequired,
        }),
        credit_limit_excluding_vat: PropTypes.number,
        undistributed_credit_limit: PropTypes.number,
        experian_credit_checks: PropTypes.array,
      }),
    }),
    agencyCustomerRequests: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        registered_company_number: PropTypes.string,
        exact_legal_name: PropTypes.string,
        credit_limit_requested: PropTypes.number,
        created_at: PropTypes.number.isRequired,
        agency: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          extended: PropTypes.shape({
            logo_url: PropTypes.string,
          }),
        }).isRequired,
      })),
    }),
    insurerReportingActivities: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        reported_by: PropTypes.string,
        resolved_by: PropTypes.string,
        reason: PropTypes.string,
        reported_at: PropTypes.number,
        resolved_at: PropTypes.number,
      })).isRequired,
    }),
    insurerReportingActivity: PropTypes.shape({
      loading: PropTypes.bool,
    }),
    activeCreditApplications: PropTypes.shape({
      data: PropTypes.object,
    }).isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool,
    }),
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    getLegalEntity: PropTypes.func.isRequired,
    getActiveCreditApplications: PropTypes.func.isRequired,
    getAgencyCustomerRequests: PropTypes.func.isRequired,
    getInsurerReportingActivities: PropTypes.func.isRequired,
    showUnreportModal: PropTypes.func.isRequired,
    showVerifyAgencyCustomerModal: PropTypes.func.isRequired,
    showCancelScheduledCreditSuspensionModal: PropTypes.func.isRequired,
  }

  initialState = {
    unreportModal: {
      visible: false,
      data: null,
    },
  }

  state = this.initialState;

  async componentDidMount() {
    const {
      legalEntityId,
      legalEntity,
      getLegalEntity,
      getActiveCreditApplications,
      getAgencyCustomerRequests,
      getInsurerReportingActivities,
    } = this.props;

    // Make sure we don't reload the Legal Entity every time we change the tab
    if (legalEntity.data?.id === legalEntityId) {
      return;
    }

    await getLegalEntity(legalEntityId);
    await getAgencyCustomerRequests({ legalEntityId });
    await getInsurerReportingActivities({
      legal_entity_id: legalEntityId,
      filter_options: {
        resolved: false,
      },
    });
    await getActiveCreditApplications({ legalEntityId });
  }

  render() {
    const { match, legalEntityId, legalEntity } = this.props;

    const canLoadTabs = legalEntity?.data && legalEntity?.data?.id === legalEntityId;

    return (
      <div className={styles.container}>
        <Heading
          className={styles.headerContainer}
          title={this.renderHeaderTitle()}
          rightAccessory={(
            <Button
              className={styles.backButton}
              variant="outline-primary"
              onClick={this.handleBackToLegalEntities}
            >
              Back to Legal Entities
            </Button>
          )}
          hideSeparator
        />
        <div className={styles.statuses}>
          {this.renderUnverifiedAgencyCustomers()}
          {this.renderReportingActivities()}
        </div>
        {canLoadTabs && (
          <TabControl
            className={styles.tabControl}
            defaultIndex={this.getDefaultTabIndex()}
            onTabChanged={this.handleTabChanged}
          >
            <Link title="Details" to={`${match.url}/details`} />
            <Link title="Credit Management" to={`${match.url}/credit-management`} />
            <Link title="Credit Applications" to={`${match.url}/credit-applications`} />
            <Link title="Request History" to={`${match.url}/request-history`} />
            <Link title="Credit Activity" to={`${match.url}/credit-activity`} />
          </TabControl>
        )}
        <Switch>
          <Route exact path={`${match.path}/details`} component={Tabs.DetailsTab} />
          <Route path={`${match.path}/credit-management`} component={Tabs.CreditManagementTab} />
          <Route path={`${match.path}/credit-applications`} component={Tabs.CreditApplicationsTab} />
          <Route path={`${match.path}/request-history`} component={Tabs.RequestHistoryTab} />
          <Route exact path={`${match.path}/credit-activity`} component={Tabs.CreditActivityTab} />
        </Switch>
      </div>
    );
  }

  renderHeaderTitle = () => {
    const { legalEntity } = this.props;

    if (!legalEntity?.data) {
      return "";
    }

    return (
      <div className={styles.headerTitle}>
        <span className={styles.name}>{legalEntity.data.name}</span>
        {this.renderStatusBadge()}
      </div>
    );
  }

  renderStatusBadge = () => {
    const { legalEntity, showCancelScheduledCreditSuspensionModal } = this.props;

    if (legalEntity?.data?.active_suspension) {
      return (
        <CreditSuspendedLabel
          suspendedAt={legalEntity.data.active_suspension.date_of_effect}
        />
      );
    }

    if (legalEntity?.data?.scheduled_suspension) {
      return (
        <CreditSuspensionScheduledLabel
          suspendedAt={legalEntity.data.scheduled_suspension.date_of_effect}
          suspensionId={legalEntity.data.scheduled_suspension.id}
          onCancel={showCancelScheduledCreditSuspensionModal}
        />
      );
    }

    if (this.props.activeCreditApplications.data?.length > 0) {
      return (
        <CreditApplicationActiveLabel
        />
      );
    }
  }

  renderUnverifiedAgencyCustomers = () => {
    const { agencyCustomerRequests } = this.props;

    return (
      <AgencyCustomerRequestAlert
        loading={agencyCustomerRequests.loading}
        data={agencyCustomerRequests.data}
        onVerifyClick={this.handleVerifyNowClick}
      />
    );
  }

  renderReportingActivities = () => {
    const { insurerReportingActivities } = this.props;

    return (
      <LegalEntityReportAlert
        loading={insurerReportingActivities.loading}
        data={insurerReportingActivities.data}
        onUnreportClick={this.handleUnreport}
      />
    );
  }

  getDefaultTabIndex = () => {
    const { history, location, match } = this.props;

    if (location.pathname.includes("/details")) return 0;
    if (location.pathname.includes("/credit-management")) return 1;
    if (location.pathname.includes("/credit-applications")) return 2;
    if (location.pathname.includes("/request-history")) return 3;
    if (location.pathname.includes("/credit-activity")) return 4;
    history.replace(`${match.url}/credit-management`);
    return 1;
  }

  handleTabChanged = (tab) => {
    const { match, history } = this.props;

    switch (tab) {
      case "Details":
        history.push(`${match.url}/details`);
        break;

      case "Credit Management":
        history.push(`${match.url}/credit-management`);
        break;

      case "Credit Applications":
        history.push(`${match.url}/credit-applications`);
        break;

      case "Request History":
        history.push(`${match.url}/request-history`);
        break;

      case "Credit Activity":
        history.push(`${match.url}/credit-activity`);
        break;

      default:
        history.push(`${match.url}/credit-management`);
    }
  }

  handleBackToLegalEntities = () => {
    const { history } = this.props;

    history.push("/credit-limit-management/legal-entities");
  }

  handleUnreport = (insurerReportingActivity) => {
    this.props.showUnreportModal({
      insurerReportingActivityId: insurerReportingActivity.id,
      reportedAt: insurerReportingActivity.reported_at,
      reportedReason: insurerReportingActivity.report_reason.label,
    });
  }

  handleVerifyNowClick = (agencyCustomerRequest) => {
    this.props.showVerifyAgencyCustomerModal({
      legalEntityId: this.props.legalEntityId,
      agencyCustomerRequestId: agencyCustomerRequest.id,
      agencyId: agencyCustomerRequest.agency?.id || agencyCustomerRequest.prospect?.id,
      agencyName: agencyCustomerRequest.agency?.known_as || agencyCustomerRequest.prospect?.known_as,
      isProspect: !agencyCustomerRequest.agency,
      creditLimitRequested: agencyCustomerRequest.credit_limit_requested,
      enteredByUser: {
        registeredCompanyNumber: agencyCustomerRequest.registered_company_number,
        exactLegalName: agencyCustomerRequest.exact_legal_name,
      },
      selectedFromCompaniesHouse: {
        registeredCompanyNumber: this.props.legalEntity.data?.company_number,
        exactLegalName: this.props.legalEntity.data?.name,
      },
      hasBeenCreditChecked: !_.isEmpty(this.props.legalEntity.data?.experian_credit_checks),
    });
  }

}
