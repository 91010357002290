import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Form } from "../../../../../common/components";
import { ExperianCreditReportUpload } from "../../../";
import { parseDate, toMoney } from "../../../../../../utils";
import styles from "./IncreaseForm.module.scss";

export default function IncreaseForm (props) {
  return (
    <>
      <Form.Group inline className={styles.currentLimitGroup}>
        <Form.Label inline>Current credit limit (Excl. VAT)</Form.Label>
        <Form.Value className={styles.fixedFormNumber}>
          {!_.isNil(props.currentCreditLimit) ? toMoney(props.currentCreditLimit) : "£-"}
        </Form.Value>
      </Form.Group>

      <Form.Group inline className={styles.group}>
        <Form.Label inline required>Source</Form.Label>
        <Form.RadioContainer>
          <Form.Radio
            value="experian"
            name="typeOfCheck"
            checked={props.formData.typeOfCheck === "experian"}
            text="Experian Check"
            onChange={e => props.onChangeTypeOfCheck(e.target.value)}
          />
          <Form.Radio
            value="broker"
            name="typeOfCheck"
            checked={props.formData.typeOfCheck === "broker"}
            text="Broker Request"
            onChange={e => props.onChangeTypeOfCheck(e.target.value)}
          />
          <Form.Radio
            value="insurer"
            name="typeOfCheck"
            checked={props.formData.typeOfCheck === "insurer"}
            text="Insurer Application"
            onChange={e => props.onChangeTypeOfCheck(e.target.value)}
          />
        </Form.RadioContainer>
      </Form.Group>

      {props.formData.typeOfCheck === "experian" && (
        <>
          <Form.Group inline className={styles.group}>
            <Form.Label inline required>Were you able to find the company on Experian?</Form.Label>
            <Form.RadioContainer>
              <Form.Radio
                value={true}
                name="companyFound"
                checked={props.formData.companyFound}
                text="Yes"
                onChange={props.onRadioChange}
              />
              <Form.Radio
                value={false}
                name="companyFound"
                checked={!props.formData.companyFound}
                text="No"
                onChange={props.onRadioChange}
              />
            </Form.RadioContainer>
          </Form.Group>

          {props.formData.companyFound && (
            <Form.Group inline className={styles.group}>
              <Form.Label inline required>Result amount (Excl. VAT)</Form.Label>
              <Form.Number
                name="newCreditLimit"
                value={props.formData.newCreditLimit}
                placeholder="eg. £10,000"
                prefix="£"
                onChange={props.onChange}
                disabled={props.busy}
                formatNumber
              />
            </Form.Group>
          )}

          <Form.Group inline className={styles.group} error={props.errors.dateOfResult}>
            <Form.Label inline required className={styles.dateLabel}>
              Date result obtained
            </Form.Label>
            <Form.Value>
              <Form.Date
                name="dateOfResult"
                size="small"
                value={props.formData.dateOfResult}
                onChange={props.onChange}
                disabled={props.busy}
                highlightError={props.errors.dateOfResult}
                fluid
              />
            </Form.Value>
          </Form.Group>
          {props.formData.companyFound && (
            <>
              <Form.Group inline>
                <Form.Label inline required>
                  Experian credit report<br />
                  (Maximum 5GB)
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <ExperianCreditReportUpload
                  name="experianCreditReport"
                  value={props.formData.experianCreditReport}
                  onChange={props.onChange}
                  onClear={props.onClearExperianReport}
                  disabled={props.busy}
                  error={props.errors.experianCreditReport}
                />
              </Form.Group>
            </>
          )}
        </>
      )}

      {(props.formData.typeOfCheck === "insurer" || props.formData.typeOfCheck === "broker") && (
        <>
          <Form.Group inline className={styles.group} error={props.errors.newCreditLimit}>
            <Form.Label inline required>Amount applying for (Excl. VAT)</Form.Label>
            <Form.Number
              name="newCreditLimit"
              value={props.formData.newCreditLimit}
              placeholder="eg. £10,000"
              prefix="£"
              onChange={props.onChange}
              onBlur={props.onNewCreditLimitBlur}
              highlightError={props.errors.newCreditLimit}
              formatNumber
            />
          </Form.Group>

          <Form.Group inline className={styles.group} error={props.errors.dateOfRequest}>
            <Form.Label inline required className={styles.dateLabel}>
              Date of request
            </Form.Label>
            <Form.Value>
              <Form.Date
                name="dateOfRequest"
                size="small"
                value={props.formData.dateOfRequest}
                onChange={props.onChange}
                disabled={props.busy}
                highlightError={props.errors.dateOfRequest}
                max={parseDate(Date.now(), "YYYY-MM-DD")}
                fluid
              />
            </Form.Value>
          </Form.Group>

          <Form.Group inline className={styles.group}>
            <Form.Label inline className={styles.commentsLabel}>Comments</Form.Label>
            <Form.Value>
              <Form.TextArea
                placeholder="Enter comments..."
                name="comment"
                value={props.formData.comment}
                onChange={props.onChange}
                className={styles.textArea}
                rows={6}
                maxLength={255}
              />
            </Form.Value>
          </Form.Group>
        </>
      )}
    </>
  );
}

IncreaseForm.propTypes = {
  currentCreditLimit: PropTypes.number,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  onChangeTypeOfCheck: PropTypes.func.isRequired,
  onClearExperianReport: PropTypes.func.isRequired,
  onNewCreditLimitBlur: PropTypes.func.isRequired,
  sourceOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  busy: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};
