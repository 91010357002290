import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ExistingShortCodesPage from "./ExistingShortCodesPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.onboarding,
  returnUrl: ownProps.location.state.from,
  data: ownProps.location.state.data,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchExistingAgencyShortCodes: actions.getExistingAgencyShortCodes,
  }, dispatch),
  onGoBack: (path, data) => {
    dispatch(push(path, {
      data,
    }));
  },
  onUseShortCode: (path, data) => {
    dispatch(push(path, {
      data,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExistingShortCodesPage);
