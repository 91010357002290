import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { actions } from "../../duck";
import ViewAgencyCustomerPage from "./ViewAgencyCustomerPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.customers,
  agencyCustomerId: ownProps.match.params.agencyCustomerId,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
  }, dispatch),
  navigateToCustomers: () => {
    dispatch(push("/customers"));
  },
  navigateToAddContract: (agencyCustomerId) => {
    dispatch(push(`/customers/${agencyCustomerId}/contracts/new`));
  },
  navigateToVerifyRegisteredCompanyPage: (data) => {
    dispatch(push(`/customers/${data.agencyCustomerId}/verify`, {
      registeredCompanyNumber: data.registeredCompanyNumber,
      exactLegalName: data.exactLegalName,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAgencyCustomerPage);
