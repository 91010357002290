import _ from "lodash";

export default class EditPaymentDetailsModalValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isNil(data?.isPurchaseOrderNumberRequired)) {
      errors = _.set(errors, "isPurchaseOrderNumberRequired", "You must specify if a Purchase Order Number is required");
    }

    if (_.isNil(data?.agreedPaymentTermsType) || _.isEmpty(data?.agreedPaymentTermsType)) {
      errors = _.set(errors, "agreedPaymentTermsType", "Agreed Payment Terms is required");
    }

    if (_.isNil(data?.agreedPaymentTermsValue)) {
      errors = _.set(errors, "agreedPaymentTermsValue", "Agreed Payment Terms value is required");
    }
    else if (data?.agreedPaymentTermsValue <= 0) {
      errors = _.set(errors, "agreedPaymentTermsValue", "Agreed Payment Terms value must be a positive number");
    }
    else if (data?.agreedPaymentTermsType === "days_after_invoice_date" && data?.agreedPaymentTermsValue > 99) {
      errors = _.set(errors, "agreedPaymentTermsValue", "Agreed Payment Terms value must not be greater than 99 days");
    }
    else if (data?.agreedPaymentTermsType === "day_of_month_following_invoice" && data?.agreedPaymentTermsValue > 31) {
      errors = _.set(errors, "agreedPaymentTermsValue", "Agreed Payment Terms value must not be greater than the 31st");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
