import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../duck";
import CurrentTasksTab from "./CurrentTasksTab";

const mapStateToProps = state => ({
  ...state.creditLimitManagement,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentTasksTab);

