import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../duck";
import RequestHistoryTab from "./RequestHistoryTab";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
  initialFilters: ownProps.history.location.state?.filters,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestHistoryTab);

