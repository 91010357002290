import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Table } from "semantic-ui-react";
import { UnfoldLess, UnfoldMore } from "@material-ui/icons";
import AgencyCustomerRow from "./AgencyCustomerRow/AgencyCustomerRow";
import { SortIndicator } from "../../../common/components";
import { sorting } from "../../../../utils";
import styles from "./AgencyCustomerList.module.scss";

export default function AgencyCustomerList(props) {
  const [expanded, setExpanded] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    "agency.known_as": "NONE",
  });

  useEffect(() => {
    setExpanded((props.data || []).map(agencyCustomer => agencyCustomer.id));
  }, [props.data]);

  const handleColumnClick = (field) => {
    const newSortOrder = {
      ...sortOrder,
      [field]: sorting.nextTransition(field, sortOrder),
    };

    setSortOrder(newSortOrder);

    props.onColumnClick && props.onColumnClick(newSortOrder);
  };

  if (props.loading) {
    return <ActivityIndicator />;
  }

  return (
    <Table
      className={styles.container}
      basic
    >
      <Table.Header>
        <Table.HeaderCell
          className={styles.agencyNameHeader}
          onClick={() => handleColumnClick("agency.known_as")}
        >
          <div className={styles.agencyNameWrapper}>
            Agency Name
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={sortOrder["agency.known_as"]}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          Total Distributed
          <div>
            (Incl. VAT)
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          Total Debt
          <div>
            (Incl. VAT)
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right">
          Total Exposure
          <div>
            (Incl. VAT)
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.unfoldColumn}
          textAlign="right"
          verticalAlign="middle"
        >
          {expanded.length === props.data?.length ? (
            <div
              className={styles.collapseExpand}
              onClick={() => setExpanded([])}
            >
              <UnfoldLess className={styles.unfoldIcon} />
              Collapse All
            </div>
          ) : (
            <div
              className={styles.collapseExpand}
              onClick={() => setExpanded((props.data || []).map(agencyCustomer => agencyCustomer.id))}
            >
              <UnfoldMore className={styles.unfoldIcon} />
              Expand All
            </div>
          )}
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {props.data?.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan="6" className={styles.noResults}>
              There are no Agency Customers available to view.
            </Table.Cell>
          </Table.Row>
        ) : props.data?.map((agencyCustomer, index) => {
          const isExpanded = expanded.includes(agencyCustomer.id);

          return (
            <AgencyCustomerRow
              key={`agency_customer_${agencyCustomer.id}`}
              index={index}
              agencyCustomer={agencyCustomer}
              expanded={isExpanded}
              onRowClick={() => {
                if (isExpanded) {
                  setExpanded(expanded.filter(x => x !== agencyCustomer.id));
                }
                else {
                  setExpanded([...expanded, agencyCustomer.id]);
                }
              }}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
}

AgencyCustomerList.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  onColumnClick: PropTypes.func,
};
