import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Pill } from "../../../common/components";

export default function PriorityPill({
  startDate,
  dueDate,
}) {
  if (!dueDate) return null;

  const now = moment(startDate);
  const due = moment(dueDate);
  const days = due.diff(now, "days");

  if (days > 1) {
    return <Pill variant="warning">{`Due in ${days} days`}</Pill>;
  }

  if (days === 1) {
    return <Pill variant="warning">Due in 1 day</Pill>;
  }

  if (days === 0) {
    return <Pill variant="danger">Due today</Pill>;
  }

  if (days === -1) {
    return <Pill variant="danger">Overdue by 1 day</Pill>;
  }

  return <Pill variant="danger">{`Overdue by ${-days} days`}</Pill>;
}

PriorityPill.propTypes = {
  startDate: PropTypes.number,
  dueDate: PropTypes.number,
};

PriorityPill.defaultProps = {
  startDate: (new Date()).getTime(),
};
