import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropDown, Input, Label, Form } from "../../../common/components";
import "./WorkerForm.scss";

class WorkerForm extends Component {

  static defaultProps = {
    agencies: [],
  }

  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    agencies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  };


state = {
  first_name: "",
  last_name: "",
  agency_id: "",
  status: "active",
  email: "",
};

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

    handleDropdownChange = (e) => {
      this.setState({ [e.name]: e.value });
    }


    render() {
      const { first_name, last_name, agency_id, status, email } = this.state;

      return (
        <div className="WorkerForm">
          <Form onCancel={this.props.onCancel} onSubmit={() => this.props.onSubmit(this.state)} submitText="Save Worker">
            <Form.Row>
              <Form.Column>
                <Label>First Name</Label>
                <Input name="first_name" onChange={ this.handleChange } value={first_name} />
              </Form.Column>
              <Form.Column>
                <Label>Last Name</Label>
                <Input name="last_name" onChange={ this.handleChange } value={last_name} />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Label>Agency</Label>
                <DropDown name="agency_id" placeholder="Select Agency" value={agency_id} onChange={this.handleDropdownChange} data={
                  this.props.agencies.reduce((prev, next) => prev.concat({ value: next.id, text: next.name }),[])
                } />
              </Form.Column>
              <Form.Column>
                <Label>Status</Label>
                <DropDown name="status" placeholder="Status" value={status} onChange={this.handleDropdownChange} data={[
                  { value: "active", text: "Active" },
                  { value: "disabled", text: "Disabled" },
                ]} />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Label>Email</Label>
                <Input name="email" onChange={ this.handleChange } value={email} />
              </Form.Column>
            </Form.Row>
          </Form>
        </div>
      );
    }
}

export default WorkerForm;
