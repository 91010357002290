import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import PayrollGeneralFormValidator from "./PayrollGeneralFormValidator";
import { Button, ButtonGroup, Form } from "../../../../common/components";
import { List } from "../../../../lists/containers";
import styles from "./PayrollGeneralForm.module.scss";

export default class PayrollGeneralForm extends Component {

  static propTypes = {
    data: PropTypes.shape({
      workerTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
      })),
    }),
    submitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        workerTypes: props.data?.workerTypes?.map(x => x.id),
      },
    };
  }

  render() {
    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={14}>
          <Form className={styles.form}>
            <Form.Section title="General" titleClassName={styles.title}>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
              >
                <Form.Label className={styles.label} inline>
                  Type of Workers
                </Form.Label>
                <Form.ValueInline>
                  <List
                    identifier="worker_types"
                    name="workerTypes"
                    value={this.state.data.workerTypes}
                    onChange={this.handleWorkerTypeChange}
                  />
                </Form.ValueInline>
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleWorkerTypeChange = (workerTypeId) => {
    const { workerTypes } = this.state.data;

    this.setState({
      data: {
        ...this.state.data,
        workerTypes: workerTypes.includes(workerTypeId)
          ? workerTypes.filter(workerType => workerType !== workerTypeId)
          : [...workerTypes, workerTypeId],
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const validator = new PayrollGeneralFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit && onSubmit(data, this.props);
  }

}

