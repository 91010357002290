import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import DataImportPage from "./DataImportPage";

const mapStateToProps = state => ({
  ...state.system,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  onFetchDataImports: actions.getDataImports,
  onImportAgencies: actions.importAgencies,
  onImportProspects: actions.importProspects,
  onImportTempest: actions.importTempest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataImportPage);
