import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { AgencyVatInformationForm } from "../../../components";
import styles from "./AgencyVatInformation.module.scss";

export default class AgencyVatInformation extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }),
    agencyVatInformation: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object.isRequired,
    }).isRequired,
    agencyDisabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    onFetchAgencyVatInformation: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    const { agencyId, onFetchAgencyVatInformation } = this.props;

    await onFetchAgencyVatInformation({ agencyId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderAgencyVatInformationSection()}
      </div>
    );
  }

  renderAgencyVatInformationSection = () => {
    const {
      agencyVatInformation: {
        loading,
        data,
      },
      ui: {
        submitting,
      },
    } = this.props;

    if (loading) {
      return <ActivityIndicator />;
    }

    return (
      <AgencyVatInformationForm
        data={data}
        onDisabledFieldClick={this.handleDisabledFieldClick}
        onSave={this.handleSaveClicked}
        onCancel={this.handleCancelClicked}
        submitting={submitting}
      />
    );
  }

  handleDisabledFieldClick = async (disabledField) => {
    const { agencyId, onDisabledFieldClick } = this.props;

    await onDisabledFieldClick({ agencyId, disabledField });
  }

  handleCancelClicked = async () => {
    const { agencyId, onCancel } = this.props;

    await onCancel(agencyId);
  }

  handleSaveClicked = async (data) => {
    const { agencyId, onSubmit } = this.props;

    await onSubmit({ agencyId, data });
  }
}
