import _ from "lodash";
import { bytesToGigabytes } from "utils";

export default class UploadDocumentsModalValidator {
  constructor(files) {
    this.files = files;
  }

  validate() {
    const { files } = this;
    let errors = {};

    files.forEach((file, i) => {
      if (_.isNil(file.documentType)) {
        errors = _.set(errors, i, "You must select a document type.");
      }

      if (bytesToGigabytes(file.size) > 5) {
        errors = _.set(errors, i, "File size was too large. Maximum file size is 5GB.");
      }
    });

    return { errors };
  }
}
