import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const REMOVE_PROSPECT_DOCUMENT = gql`
mutation($input: ProspectDocumentUrlInput!) {
  removeProspectDocument(input: $input)
}
`;

export const removeProspectDocument = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.REMOVE_PROSPECT_DOCUMENT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: REMOVE_PROSPECT_DOCUMENT,
      variables: {
        input: {
          prospect_id: obj.prospectId,
          document_type: obj.document.name,
          version_id: obj.versionId,
        },
      },
    });

    if (!response.data.removeProspectDocument) {
      throw Error(`Failed to remove ${document.name}`);
    }

    dispatch({
      type: types.REMOVE_PROSPECT_DOCUMENT_SUCCESS,
      payload: {
        document: obj.document,
        versionId: obj.versionId,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.REMOVE_PROSPECT_DOCUMENT_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
