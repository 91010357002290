import _ from "lodash";
import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { getActiveCreditApplications } from "./getActiveCreditApplications";

const COMPLETE_CREDIT_APPLICATION = gql`
mutation($input: CompleteCreditApplicationInput!) {
  completeCreditApplication(input:$input) {
    id
    reference_number
    source
    credit_limit_requested
    date_of_request
    date_of_result
    use_discretionary_limit
    approved_credit_excluding_vat
    completed_at
    completed_by {
      first_name
      last_name
    }
    status
    closing_comment
    created_at
    created_by {
        id
        first_name
        last_name
    }

    comments {
      id
      created_by {
        first_name
        last_name
      }
      comment
      type
      created_at
    }

    legal_entity {
      id
      credit_limit_excluding_vat
      credit_limit_including_vat

      ...on RegisteredCompany {
        companies_house_info {
          accounts {
            next_due
          }
        }
      }
    }

    experian_credit_check {
      experian_credit_result
    }

    chases {
      name
      date_of_chase
      comments
      method_of_communication {
        label
      }
      chased_by
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.creditApplicationId
 * @param {number} obj.amountApproved
 * @param {string} obj.notes
 * @param {string} obj.status
 * @param {boolean} obj.useDiscretionaryLimit
 * @param {Date} obj.dateOfResult
 * @param {string} obj.redirectUrl (optional)
 * @returns
 */
export const completeCreditApplication = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.COMPLETE_CREDIT_APPLICATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: COMPLETE_CREDIT_APPLICATION,
      variables: {
        input: {
          credit_application_id: obj.creditApplicationId,
          amount_approved: !_.isNil(obj.amountApproved)
            ? parseInt(obj.amountApproved, 10)
            : null,
          notes: obj.notes,
          status: obj.status,
          use_discretionary_limit: obj.useDiscretionaryLimit,
          date_of_result: obj.dateOfResult,
        },
      },
    });

    if (response.errors) {
      throw Error(response.errors[0].message);
    }

    dispatch({
      type: types.COMPLETE_CREDIT_APPLICATION_SUCCESS,
      payload: {
        creditApplication: response.data.completeCreditApplication,
      },
    });

    // Update the list of active credit applications
    const legalEntityId = response.data.completeCreditApplication.legal_entity.id;
    dispatch(getActiveCreditApplications({ legalEntityId }));

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (error) {
    dispatch({
      type: types.COMPLETE_CREDIT_APPLICATION_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
