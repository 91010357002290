import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_AGENCY_CUSTOMERS = gql`
query(
  $legal_entity_id: ID!
  $sort_order: [SortOrder]
) {
  getLegalEntity(id: $legal_entity_id) {
    id

    agency_customers(
      pager: { page: 1, limit: 1000 }
      sort_order: $sort_order
    ) {
      id
      allocated_credit
      agency {
        id
        known_as
      }
      prospect {
        id
        known_as
      }
      agency_customer_request {
        verified_at
        verified_by {
          first_name
          last_name
        }
        verify_mismatch_reason {
          label
        }
        verify_mismatch_reason_details
      }
      customer_accounts {
        id
        display_name
        allocated_credit
      }
      credit_requests(
        filter_options: { status: "pending" }
        pager: { page: 1, limit: 1 }
      ) {
        legal_entity {
          id
        }
        status
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {object[]} obj.sortOrder
 * @returns
 */
export const getAgencyCustomers = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_CUSTOMERS_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_AGENCY_CUSTOMERS,
      variables: {
        legal_entity_id: obj.legalEntityId,
        sort_order: SortOrderConverter(obj.sortOrder || {}),
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_CUSTOMERS_SUCCESS,
      payload: {
        data: response.data.getLegalEntity.agency_customers,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_CUSTOMERS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
