import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./StatusLabel.module.scss";

export default function StatusLabel(props) {
  return (
    <div
      className={classnames(
        props.className,
        styles.container,
        styles[props.size],
        styles[_.lowerCase(props.variant)],
      )}
    >
      {props.status}
    </div>
  );
}

StatusLabel.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf([
    "medium",
    "large",
  ]),
  variant: PropTypes.oneOf([
    "trading",
    "live",
    "dormant",
    "unfinished",
    "prospect",
    "onboarding",
    "closed",
    "dead",
    "archive",
    "test",
  ]),
  status: PropTypes.string.isRequired,
};

StatusLabel.defaultProps = {
  size: "medium",
};
