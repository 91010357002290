import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_REPORTING_INFO = gql`
query($prospect_id: ID!) {
  getProspect(id: $prospect_id) {
    mi_report_grouping_required

    mi_report_groupings {
      active
      mi_report_grouping {
        id
        label
        value
      }
      values {
        id
        value
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getReportingInfo = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_REPORTING_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_REPORTING_INFO,
      variables: {
        prospect_id: prospectId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_REPORTING_INFO_SUCCESS,
      payload: {
        data: {
          mi_report_grouping_required: response.data.getProspect.mi_report_grouping_required,
          mi_report_groupings: response.data.getProspect.mi_report_groupings,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_REPORTING_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
