import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_AGENCY_CUSTOMER = gql`
  mutation($input: AddAgencyCustomerInput!) {
    addAgencyCustomer(input : $input) {
      id
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @param {boolean} obj.isProspect
 * @param {string} obj.exactLegalName
 * @param {string} obj.companyType
 * @param {boolean} obj.isRegisteredCharity
 * @param {string} obj.registeredCompanyNumber
 * @param {number} obj.creditLimitRequested
 * @param {string} obj.soleTraderName
 * @param {string} obj.internationalTradingName
 * @param {string} obj.addressLine1
 * @param {string} obj.addressLine2
 * @param {string} obj.townOrCity
 * @param {string} obj.county
 * @param {string} obj.country
 * @param {string} obj.postcode
 * @returns
 */
export const addAgencyCustomer = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_AGENCY_CUSTOMER_PENDING,
    });

    const response = await apolloClient.query({
      query: ADD_AGENCY_CUSTOMER,
      variables: {
        input: {
          [obj.isProspect ? "prospect_id" : "agency_id"]: obj.agencyId,
          legal_name: obj.exactLegalName,
          display_name: obj.exactLegalName,
          company_type: obj.companyType,
          registered_company_number: obj.registeredCompanyNumber,
          sole_trader_name: obj.soleTraderName,
          is_registered_charity: obj.isRegisteredCharity,
          registered_charity_number: obj.registeredCharityNumber,
          credit_limit_requested: parseInt(obj.creditLimitRequested, 10),
          international_trading_name: obj.internationalTradingName,
          address_line_1: obj.addressLine1,
          address_line_2: obj.addressLine2,
          town_or_city: obj.townOrCity,
          county: obj.county,
          country: obj.country,
          postcode: obj.postcode,
        },
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    dispatch(push(`/customers/${response.data.addAgencyCustomer.id}`));

    dispatch({
      type: types.ADD_AGENCY_CUSTOMER_SUCCESS,
    });
  }
  catch (err) {
    dispatch({
      type: types.ADD_AGENCY_CUSTOMER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
