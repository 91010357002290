import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Form } from "../../../../common/components";
import styles from "./FormRadioList.module.scss";

export default function FormRadioList(props) {
  const handleChange = (e) => {
    props.onChange && props.onChange({
      target: {
        name: props.name,
        value: e.target.value,
      },
    });
  };

  return (
    <div
      className={classnames(
        styles.container,
        styles[props.orientation],
        props.className
      )}
    >
      {props.options.map(option => (
        <Form.Radio
          name={props.name}
          key={String(option.value)}
          text={option.label}
          value={option.value}
          checked={String(props.value) === String(option.value)}
          onChange={handleChange}
          readOnly={props.readOnly}
          disabled={props.disabled}
        />
      ))}
    </div>
  );
}

FormRadioList.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  })).isRequired,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FormRadioList.defaultProps = {
  value: "",
  options: [],
  orientation: "horizontal",
};
