import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../common/components";
import styles from "./AgencyCustomerRequestAlreadyExistsModal.module.scss";

export default function AgencyCustomerRequestAlreadyExistsModal(props) {
  return (
    <ConfirmationModal
      className={classnames(props.className, styles.container)}
      dimmerClassName={styles.dimmer}
      title="Agency Customer Request already exists"
      submitText="Okay"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div>
        An unverified agency customer request already exists within Optia.
      </div>
      <div>
        Do you want to update the existing agency customer request?
      </div>
    </ConfirmationModal>
  );
}

AgencyCustomerRequestAlreadyExistsModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
