import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  HomeRounded,
  PeopleRounded,
  ShareRounded,
  AllInboxRounded,
  WorkRounded,
} from "@material-ui/icons";
import { NavLink, Route } from "react-router-dom";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import Logo from "../assets/images/logo.svg";
import { Toaster, UserDropDown } from "../modules/common/components";
import * as DashboardModule from "../modules/dashboard";
import "./AgencyApp.scss";

export default class AgencyApp extends PureComponent {

  static propTypes = {
    user: PropTypes.shape({
      attributes: PropTypes.shape ({
        name: PropTypes.string.isRequired,
      }),
      metadata: PropTypes.shape({
        department: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }).isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.string,
    ),
    onChangePassword: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onShowChangePasswordModal: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className="AgencyApp">
        {this.renderHeader()}
        {this.renderSidebar()}
        {this.renderContent()}
      </div>
    );
  }

  renderHeader = () => {
    const { user, errors, onLogout, onShowChangePasswordModal } = this.props;

    return (
      <div className="Header">
        <Toaster errors={errors} />
        <div>
          <div className="Splitter" />
          <UserDropDown
            className="UserDropDown"
            user={user}
            onChangePassword={onShowChangePasswordModal}
            onLogout={onLogout}
          />
        </div>
      </div>
    );
  }

  renderSidebar = () => {
    return (
      <div className="Sidebar">
        <img className="Logo" src={Logo} alt="Optia" />
        <div className="menu">
          <NavLink to="/dashboard" className="item" activeClassName="active">
            <HomeRounded className="icon" />
            <span className="link">Dashboard</span>
          </NavLink>
          <hr className="separator" />
          <NavLink to="/customers" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")} activeClassName="active">
            <PeopleRounded className="icon" />
            <span className="link">Customers</span>
          </NavLink>
          <hr className="separator" />
          <NavLink to="/workers" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")}  activeClassName="active">
            <WorkRounded className="icon" />
            <span className="link">Workers</span>
          </NavLink>
          <NavLink to="/disabled" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")} activeClassName="active">
            <ShareRounded className="icon" />
            <span className="link">Suppliers</span>
          </NavLink>
          <hr className="separator" />
          <NavLink to="/reports" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")}  activeClassName="active">
            <AllInboxRounded className="icon" />
            <span className="link">Reports</span>
          </NavLink>
        </div>
      </div>
    );
  }

  renderContent = () => {
    return (
      <div className="Content">
        <ReactTooltip place="right" />
        <Route exact path="/dashboard" component={DashboardModule.DashboardPage} />
      </div>
    );
  };
}
