import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, DocumentDownloadButton } from "modules/common/components";
import styles from "./CompaniesHouseInfo.module.scss";
import { DOCUMENT_TYPES } from "modules/documents";
import { capitalize } from "utils";
import { CompanyTypeConverter } from "../../../../converters";

export default class CompaniesHouseInfo extends Component {
  static propTypes = {
    companyProfile: PropTypes.shape({
      company_name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      company_number: PropTypes.string.isRequired,
      accounts: PropTypes.shape({
        last_accounts: PropTypes.shape({
          made_up_to: PropTypes.string.isRequired,
        }),
      }),
      company_status: PropTypes.string.isRequired,
      company_status_detail: PropTypes.string,
      previous_company_names: PropTypes.shape({
        ceased_on: PropTypes.string.isRequired,
        effective_from: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      registered_office_address: PropTypes.shape({
        address_line_1: PropTypes.string.isRequired,
        address_line_2: PropTypes.string.isRequired,
        locality: PropTypes.string.isRequired,
        postal_code: PropTypes.string.isRequired,
      }),
    }),
    companyCertUrl: PropTypes.string,
  }

  render() {
    const { companyProfile, companyCertUrl } = this.props;

    return (
      <Form className={styles.form}>
        <Form.Section title="Companies House">
          <Form.Group>
            <Form.Label>Registered Company Number</Form.Label>
            <Form.Input
              name="company_number"
              value={companyProfile.company_number}
              readOnly={true}
            />
          </Form.Group>
          {companyCertUrl && (
            <Form.Group>
              <Form.Value>
                <DocumentDownloadButton
                  text={DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION}
                  onClick={this.handleDownload}
                />
              </Form.Value>
            </Form.Group>

          )}
          <Form.Group>
            <Form.Label>Exact Legal Name</Form.Label>
            <Form.Input
              name="legal_name"
              value={companyProfile.company_name}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Accounts Period End On</Form.Label>
            <Form.Date
              name="last_accounts_period"
              value={companyProfile.accounts?.last_accounts?.made_up_to}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Input
              name="type"
              value={CompanyTypeConverter.to(companyProfile.type)}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Company Status</Form.Label>
            <Form.Input
              name="company_status"
              value={capitalize(companyProfile.company_status)}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Company Status Detail</Form.Label>
            <Form.Input
              name="company_status_detail"
              value={companyProfile.company_status_detail}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Previous Names</Form.Label>
            {companyProfile?.previous_company_names ? companyProfile?.previous_company_names.map(value => (
              <Form.Input
                key={value.name}
                name={value.name}
                value={value.name}
                readOnly={true}
              />
            )) : <Form.Input value="" readOnly={true} />}
          </Form.Group>
        </Form.Section>
        <Form.Section title="Registered Address">
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Form.Input
              name="address_line_one"
              value={companyProfile.registered_office_address.address_line_1}
              readOnly={true}
            />
          </Form.Group>
          <Form.FlexRow>
            <Form.Group>
              <Form.Label fluid>Town/City</Form.Label>
              <Form.Input
                name="town_or_city"
                value={companyProfile.registered_office_address.address_line_2 || companyProfile.registered_office_address.locality}
                readOnly={true}
                fluid
              />
            </Form.Group>
            <Form.Group>
              <Form.Label fluid>Address</Form.Label>
              <Form.Input
                name="postcode"
                value={companyProfile.registered_office_address.postal_code}
                readOnly={true}
                fluid
              />
            </Form.Group>
          </Form.FlexRow>
        </Form.Section>
      </Form>
    );
  }

  handleDownload = () => {
    const { companyCertUrl } = this.props;

    window.open(companyCertUrl, "_blank");
  }
}
