import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Heading,
  ItemCountSelector,
  Pager,
  SearchInput,
} from "modules/common/components";
import AgencyCustomersList from "modules/customers/components/AgencyCustomersList";
import { sorting } from "../../../../utils";
import styles from "./ViewAgencyCustomersPage.module.scss";

export default function ViewAgencyCustomersPage(props) {
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState({
    display_name: "ASC",
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 25,
  });

  useEffect(() => {
    fetchAgencyCustomers();
  }, [sortOrder, pagination]);

  const fetchAgencyCustomers = (filters =  []) => {
    const pager = {
      page: pagination.currentPage,
      limit: pagination.itemsPerPage,
    };

    props.getCustomers({
      searchText,
      sortOrder,
      pager,
      filters,
    });
  };

  const handleColumnClick = (name, order) => {
    const newSortOrder = { ...sortOrder };

    newSortOrder[name] = sorting.nextTransition(name, { [name]: order });

    setSortOrder(newSortOrder);
  };

  const handlePageChanged = n => setPagination({ ...pagination, currentPage: n });

  const handleItemCountChanged = e => setPagination({ currentPage: 1, itemsPerPage: e.value });

  // const handleFiltersChange = (filters) => {
  //   fetchAgencyCustomers(filters);
  // };

  const handleSearch = () =>  setPagination({ ...pagination, currentPage: 1 });

  return (
    <div>
      <Heading
        className={styles.heading}
        title="Customers"
        rightAccessory={(
          <Button
            variant="primary"
            onClick={props.onNewCustomerClick}
          >
            Add New Customer
          </Button>
        )}
      />
      <div className={styles.searchRow}>
        <SearchInput
          name="searchText"
          className={styles.searchInput}
          value={searchText}
          placeholder="Search..."
          onChange={e => setSearchText(e.target.value)}
          onSearch={handleSearch}
        />
        <div>
          {/* The story to properly implement the filter died on the backlog so commenting it out for now*/}
          {/* <AgencyCustomersFilter onFiltersChange={handleFiltersChange}/> */}
        </div>
      </div>
      <AgencyCustomersList
        loading={props.agencyCustomers.loading}
        customers={props.agencyCustomers.data}
        sortOrder={sortOrder}
        onColumnClick={handleColumnClick}
      />
      {props.agencyCustomers.data?.length > 0 && (
        <div className={styles.pagination}>
          <Pager
            pageCount={Math.ceil(props.agencyCustomers.total / pagination.itemsPerPage)}
            selectedPage={pagination.currentPage}
            onPageChanged={handlePageChanged}
          />
          <ItemCountSelector
            value={pagination.itemsPerPage}
            onChange={handleItemCountChanged}
          />
        </div>
      )}
    </div>
  );
}

ViewAgencyCustomersPage.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  agencyCustomers: PropTypes.shape({
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onNewCustomerClick: PropTypes.func.isRequired,
};
