import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import SignUpPage from "./SignUpPage";

const mapStateToProps = state => ({
  ...state.signUp,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
