import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { LegalEntityList } from "../../components";
import {
  Heading,
  ItemCountSelector,
  Pager,
  SearchInput,
  withPermissionDenied,
} from "../../../common/components";
import styles from "./LegalEntitiesPage.module.scss";
import { sorting } from "../../../../utils";


const FILTERS = {
  ALL: "All",
  SUSPENDED: "Suspended",
  CREDIT_APPLICATIONS_IN_PROGRESS: "Credit Applications In Progress",
};

class LegalEntitiesPage extends Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    legalEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
      pages: PropTypes.number.isRequired,
    }),
    getLegalEntities: PropTypes.func.isRequired,
    searchLegalEntities: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    searchText: "",
    filter: FILTERS.ALL,
    sortOrder: {
      name: "ASC",
      credit_limit_including_vat: "NONE",
      undistributed_credit_limit: "NONE",
    },
  }

  async componentWillMount() {
    await this.props.setPagination({
      ...this.props.pagination,
      page: 1,
    });
    await this.searchLegalEntities();
  }

  render() {
    const { searchText, sortOrder } = this.state;
    const { loading, legalEntities, pagination } = this.props;

    return (
      <div>
        <Heading
          title="View Credit Limits"
          className={styles.heading}
        />
        <div className={styles.searchRow}>
          <SearchInput
            name="searchText"
            className={styles.searchInput}
            value={searchText}
            placeholder="Search..."
            onChange={this.handleInputChanged}
            onSearch={this.searchLegalEntities}
          />
        </div>
        <LegalEntityList
          loading={loading}
          legalEntities={legalEntities}
          onColumnClick={this.handleColumnClick}
          sortOrder={sortOrder}
        />
        {legalEntities.length > 0 && (
          <div className={styles.pagination}>
            <Pager
              pageCount={pagination.pages}
              selectedPage={pagination.page}
              onPageChanged={this.handlePageChanged}
            />
            <ItemCountSelector value={pagination.limit} onChange={this.handleItemCountChanged} />
          </div>
        )}
      </div>
    );
  }

  handleInputChanged = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    await this.props.setPagination({
      ...this.props.pagination,
      page: 1,
    });
  }

  handleColumnClick = async (name, order) => {
    const newSortOrder = { ...this.state.sortOrder };

    newSortOrder[name] = sorting.nextTransition(name, { [name]: order });

    this.setState({
      sortOrder: newSortOrder,
    }, this.searchLegalEntities);
  }

  handleRowClicked = (legalEntity) => {
    this.props.history.push(`/credit-limit-management/legal-entities/${legalEntity.id}`);
  }

  handlePageChanged = async (n) => {
    await this.props.setPagination({
      ...this.props.pagination,
      page: n,
    });
    await this.searchLegalEntities();
  }

  handleItemCountChanged = async (e) => {
    await this.props.setPagination({
      ...this.props.pagination,
      limit: e.value,
    });
    await this.searchLegalEntities();
  }

  handleFilterChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    await this.props.setPagination({
      ...this.props.pagination,
      page: 1,
    });
    await this.searchLegalEntities();
  }

  searchLegalEntities = async () => {
    const { searchText, sortOrder } = this.state;
    const { pagination: { page, limit } } = this.props;

    await this.props.searchLegalEntities({
      pager: { page, limit },
      searchOptions: {
        name: searchText,
        company_number: searchText,
        ...this.parseFilters(),
      },
      sortOrder,
    });
  }

  parseFilters = () => {
    const { filter } = this.state;

    switch (filter) {
      case FILTERS.CREDIT_APPLICATIONS_IN_PROGRESS:
        return { has_pending_credit_applications: true };

      default:
        return {};
    }
  }
}

const mapStateToProps = state => ({
  ...state.creditLimitManagement,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
  withPermissionDenied(LegalEntitiesPage)
);
