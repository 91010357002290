import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions } from "../../../duck";
import { CancelScheduledCreditActionModal } from "../../../components";
import { Button, ButtonGroup, CommentsSection, TextLink } from "../../../../common/components";
import { capitalize, parseDate, toMoney } from "../../../../../utils";
import styles from "./ScheduledCreditAction.module.scss";

export class ScheduledCreditAction extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    scheduledCreditActionId: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    scheduledCreditAction: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
        new_credit_limit: PropTypes.number.isRequired,
        scheduled_at: PropTypes.number.isRequired,
        scheduled_by: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired,
        created_by: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
        comment: PropTypes.string,
      }),
    }),
    getScheduledCreditAction: PropTypes.func.isRequired,
    cancelScheduledAction: PropTypes.func.isRequired,
  }

  state = {
    showCancelModal: false,
  }

  async componentDidMount() {
    const { scheduledCreditActionId, getScheduledCreditAction } = this.props;

    await getScheduledCreditAction(scheduledCreditActionId);
  }

  render() {
    const { scheduledCreditAction } = this.props;

    if (scheduledCreditAction.loading) {
      return <ActivityIndicator />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.headerText}>Manage Credit - {capitalize(scheduledCreditAction.data.type)}</h2>
          <ButtonGroup className={styles.buttonContainer}>
            <Button variant="secondary" className={styles.button} onClick={this.handleCloseClicked}>Close</Button>
          </ButtonGroup>
        </div>
        {this.renderCancelModal()}
        {this.renderRequestDetails()}
        {this.renderRecentActivity()}
      </div>
    );
  }

  renderRequestDetails = () => {
    const { scheduledCreditAction } = this.props;

    return (
      <div className={styles.requestDetailsContainer}>
        <h2 className={styles.requestDetailsHeader}>Request Details</h2>
        <div className={styles.requestDetailsSection}>
          <div className={styles.requestDetailItem}>
            <p className={styles.requestDetailKey}>Submitted Date</p>
            <p className={styles.requestDetailValue}>
              {parseDate(scheduledCreditAction.data.created_at)}
            </p>
          </div>
          <div className={styles.requestDetailItem}>
            <p className={styles.requestDetailKey}>Amount Applying For</p>
            <p className={styles.requestDetailValue}>
              {toMoney(scheduledCreditAction.data.new_credit_limit)}
            </p>
          </div>
          <div className={styles.requestDetailItem}>
            <p className={styles.requestDetailKey}>Requested By</p>
            <p className={styles.requestDetailValue}>
              {scheduledCreditAction.data.scheduled_by}
            </p>
          </div>
          <div className={styles.requestDetailItem}>
            <p className={styles.requestDetailKey}>Source</p>
            <p className={styles.requestDetailValue}>
              {scheduledCreditAction.data.source}
            </p>
          </div>
          <div className={styles.requestDetailItem}>
            <p className={styles.requestDetailKey}>Status</p>
            <p className={styles.requestDetailValue}>
              {capitalize(scheduledCreditAction.data.status)}
            </p>
          </div>
          {scheduledCreditAction.data.status === "scheduled" && (
            <>
              <div className={styles.requestDetailItem}>
                <p className={styles.requestDetailKey}>Scheduled Date</p>
                <p className={styles.requestDetailValue}>
                  {parseDate(scheduledCreditAction.data.scheduled_at)}
                </p>
              </div>
              <div className={styles.requestDetailItem}>
                <p className={styles.requestDetailKey}></p>
                <p className={styles.requestDetailValue}>
                  <TextLink className={styles.cancelButton} text="Cancel" onClick={this.handleCancelClicked} />
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  renderRecentActivity = () => {
    const { scheduledCreditAction } = this.props;

    if (_.isEmpty(scheduledCreditAction.data.comment)) {
      return null;
    }

    const comments = [{
      created_by: scheduledCreditAction.data.created_by,
      created_at: scheduledCreditAction.data.created_at,
      comment: scheduledCreditAction.data.comment,
    }];

    return (
      <div className={styles.recentActivity}>
        <CommentsSection
          title="Recent Activity"
          comments={comments}
          commentsEnabled={false}
        />
      </div>
    );
  }

  renderCancelModal = () => {
    const { showCancelModal } = this.state;

    return (
      <CancelScheduledCreditActionModal
        visible={showCancelModal}
        onSubmit={this.handleApproveCancelScheduledActionClicked}
        onCancel={this.handleDeclineCancelScheduledActionClicked}
      />
    );
  }

  handleCloseClicked = () => {
    const { history, legalEntityId } = this.props;

    history.push(`/credit-limit-management/legal-entities/${legalEntityId}/credit-management`);
  }

  handleCancelClicked = () => {
    this.setState({ showCancelModal: true });
  }

  handleApproveCancelScheduledActionClicked = async () => {
    const { legalEntityId, scheduledCreditActionId, cancelScheduledAction } = this.props;

    await cancelScheduledAction({ legalEntityId, scheduledCreditActionId });

    this.setState({ showCancelModal: false });
  }

  handleDeclineCancelScheduledActionClicked = () => {
    this.setState({ showCancelModal: false });
  }

}

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
  scheduledCreditActionId: ownProps.match.params.scheduledCreditActionId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledCreditAction);
