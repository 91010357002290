import * as types from "./types";

const initialState = {
  users: {
    loading: false,
    data: [],
    error: null,
  },
  user: {
    loading: false,
    data: null,
    error: null,
  },
  departments: {
    loading: false,
    data: [],
    error: null,
  },
  userGroups: {
    loading: false,
    data: [],
    error: null,
  },
  ui: {
    submitting: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_USERS_PENDING:
      return {
        ...state,
        users: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          loading: false,
          data: action.payload.users,
          error: null,
        },
      };

    case types.GET_USERS_FAILURE:
      return {
        ...state,
        users: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_USER_PENDING:
      return {
        ...state,
        user: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          loading: false,
          data: action.payload.user,
          error: null,
        },
      };

    case types.GET_USER_FAILURE:
      return {
        ...state,
        user: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.ADD_USER_PENDING:
      return { ...state, saving: true };

    case types.ADD_USER_SUCCESS:
      return { ...state, saving: false, user: action.payload.user };

    case types.ADD_USER_FAILURE:
      return { ...state, saving: false, error: action.payload.error };

    case types.UPDATE_USER_PENDING:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitting: true,
        },
      };

    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          error: action.payload.error,
        },
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.GET_DEPARTMENTS_PENDING:
      return {
        ...state,
        departments: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: {
          loading: false,
          data: action.payload.departments,
          error: null,
        },
      };

    case types.GET_DEPARTMENTS_FAILURE:
      return {
        ...state,
        departments: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_USER_GROUPS_PENDING:
      return {
        ...state,
        userGroups: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        userGroups: {
          loading: false,
          data: action.payload.userGroups,
          error: null,
        },
      };

    case types.GET_USER_GROUPS_FAILURE:
      return {
        ...state,
        userGroups: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
