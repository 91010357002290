import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "modules/common/components";
import styles from "./DeclineCreditRequestModal.module.scss";

export default function DeclineCreditRequestModal(props) {
  const handleCancel = () => {
    props.hideModal(DeclineCreditRequestModal.displayName);
  };

  const handleSubmit = () => {
    props.declineCreditRequest(props.creditRequestId);
    props.hideModal(DeclineCreditRequestModal.displayName);
  };

  return (
    <ConfirmationModal
      title="Are you sure?"
      visible={true}
      className={styles.container}
      submitText="Decline Request"
      submitDisabled={props.submitting}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div className={styles.text}>
        Are you sure you want to decline this request? No money will be distributed to this account.
      </div>
    </ConfirmationModal>
  );
}

DeclineCreditRequestModal.propTypes = {
  submitting: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  creditRequestId: PropTypes.string.isRequired,
  declineCreditRequest: PropTypes.func.isRequired,
};

DeclineCreditRequestModal.displayName = "DeclineCreditRequestModal";
