import * as GQL_TYPES from "./gqltypes";
import * as types from "./types";
import { apolloClient } from "../../../apolloClient";

export const getList = ({ identifier, sort_order }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_LIST_PENDING,
      payload: {
        identifier,
      },
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_LIST,
      variables: {
        identifier,
        sort_order,
      },
    });

    if (!response.data.getList && response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_LIST_SUCCESS,
      payload: {
        identifier,
        list: response.data.getList,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_LIST_FAILURE,
      payload: {
        identifier,
        error: err,
      },
    });
  }
};
