import React from "react";
import PropTypes from "prop-types";
import { AddNewCustomerForm } from "../../components";
import styles from "./AddNewCustomerPage.module.scss";

export default function AddNewCustomerPage(props) {
  return (
    <>
      <div className={styles.moduleTitle}>Customers</div>
      <div className={styles.pageTitle}>Add New Customer</div>
      <AddNewCustomerForm
        onSubmit={props.addAgencyCustomer}
        onCancel={props.onCancelAddNewCustomer}
        showUnsavedChangesModal={props.showUnsavedChangesModal}
      />
    </>
  );
}

AddNewCustomerPage.propTypes = {
  addAgencyCustomer: PropTypes.func.isRequired,
  onCancelAddNewCustomer: PropTypes.func.isRequired,
  showUnsavedChangesModal: PropTypes.func.isRequired,
};
