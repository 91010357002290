import React, { Component } from "react";
import PropTypes from "prop-types";
import { Add, Close } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import styles from "./FormInputList.module.scss";

export default class FormInputList extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.array,
    ]).isRequired,
    addButtonLabel: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: props.values,
    };
  }

  render() {
    const { addButtonLabel, placeholder, values } = this.props;

    return (
      <div className={styles.container}>
        {values.map((v, i) => (
          <Form.FlexRow className={styles.compactRow} key={i}>
            <Form.Input
              className={styles.inlineInput}
              value={v}
              placeholder={placeholder}
              onChange={e => this.handleChange(e, i)}
              maxLength={255}
            />
            {values.length > 1 &&
              <IconButton
                icon={(<Close />)}
                className={styles.removeButton}
                onClick={() => this.handleRemove(i)}
              />
            }
          </Form.FlexRow>
        ))}
        <IconButton
          icon={(<Add />)}
          iconPosition="left"
          className={styles.addButton}
          onClick={this.handleAdd}
          text={addButtonLabel}
        />
      </div>
    );
  }

  handleAdd = () => {
    const { onAdd } = this.props;

    onAdd();
  };

  handleRemove = (index) => {
    const { onRemove } = this.props;
    onRemove(index);
  }

  handleChange = (e, index) => {
    const { onChange } = this.props;
    onChange({
      index,
      value: e.target.value,
    });
  };
}
