import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormNote.module.scss";

export default function FormNote(props) {
  return (
    <div className={classnames(styles.container, props.className)}>
      {props.icon && (
        <div className={styles.icon}>
          {props.icon}
        </div>
      )}
      <div className={styles.text}>
        {props.text}
      </div>
    </div>
  );
}

FormNote.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
};
