import gql from "graphql-tag";
import { apolloClient } from "../../../apolloClient";

const GET_CUSTOMER_ACCOUNT_REFERENCES = gql`
  query($id: ID!) {
    getPayrollAgencyCustomerRequest(id: $id) {
      customer_account_references {
        id
        reference
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.payrollAgencyCustomerRequestId
 * @returns
 */
export default async function getExistingAccountReferences(obj) {
  const response = await apolloClient.query({
    query: GET_CUSTOMER_ACCOUNT_REFERENCES,
    variables: {
      id: obj.payrollAgencyCustomerRequestId,
    },
  });

  if (response.errors) {
    throw new Error(response.errors[0].message);
  }

  return response.data.getPayrollAgencyCustomerRequest?.customer_account_references;
}
