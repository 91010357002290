import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import MiReportGroupingSection from "./MiReportGroupingSection/MIReportGroupingSection";
import styles from "./ReportingView.module.scss";

export default function ReportingView(props) {
  return (
    <div className={classnames(styles.container, props.className)}>
      <div className={styles.grid}>
        <div className={styles.row}>
          <MiReportGroupingSection
            data={props.reportingInfo?.data}
            onEditClick={props.onEditMiReportGroupingClick}
          />
        </div>
      </div>
    </div>
  );
}

ReportingView.propTypes = {
  className: PropTypes.string,
  reportingInfo: PropTypes.shape({
    data: PropTypes.object,
  }),
  onDownload: PropTypes.func.isRequired,
  onEditMiReportGroupingClick: PropTypes.func,
};
