import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import {
  CancelOutlined,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  CloseRounded,
  CommentRounded,
  HelpOutlineRounded,
  HistoryRounded,
  RepeatRounded,
} from "@material-ui/icons";
import styles from "./InsurerApplicationEventLog.module.scss";
import { options } from "../../../../constants";
import { getUsersName, parseDate, toMoney } from "utils";
import { EventLogEvent } from "../../components";

export default function InsurerApplicationEventLog(props) {

  const getEventIcon = () => {
    const { creditApplication } = props;
    switch (creditApplication?.status) {
      case "pending":
        return <HistoryRounded className={styles.pendingIcon} />;
      case "partially_accepted":
        return <CheckCircleOutlineRounded className={styles.partiallyAcceptedIcon} />;
      case "accepted":
        return <CheckCircleRounded className={styles.acceptedIcon} />;
      case "declined":
        return <CancelOutlined className={styles.declinedIcon} />;
      case "company_not_found":
        return <HelpOutlineRounded className={styles.companyNotFoundIcon} />;
      case "cancelled":
        return <CloseRounded className={styles.cancelledIcon} />;
      default:
        return null;
    }
  };

  const createdApplicationEvent = {
    key: "credit_application_created",
    icon: <HistoryRounded className={styles.pendingIcon} />,
    title: `Requested - ${toMoney(props.creditApplication?.credit_limit_requested)}`,
    username: getUsersName(props.creditApplication?.created_by),
    date: props.creditApplication?.created_at,
    body: props.creditApplication?.initial_comment
      ? <div>&quot;{props.creditApplication?.initial_comment}&quot;</div>
      : null,
  };

  const completedApplicationEvent = props.creditApplication?.status === "pending"
    ? null
    : {
      key: "credit_application_closed",
      icon: getEventIcon(),
      title: `${options.CREDIT_APPLICATION_STATUS_OPTIONS?.find(
        option => option.value === props.creditApplication?.status
        )?.label || props.creditApplication?.status} - ${toMoney(props.creditApplication?.approved_credit_excluding_vat)}`,
      username: getUsersName(props.creditApplication?.completed_by),
      date: props.creditApplication?.completed_at,
      body: props.creditApplication?.closing_comment
        ? <div>&quot;{props.creditApplication?.closing_comment}&quot;</div>
        : null,
    };

  const events = [
    createdApplicationEvent,
    completedApplicationEvent,
    ...(props.creditApplication?.comments ?? []).map(comment => ({
      key: `comment_${comment.id}`,
      icon: (<CommentRounded className={styles.commentIcon}/>),
      title: "Commented",
      username: getUsersName(comment.created_by),
      date: comment.created_at,
      body: (<div>&quot;{comment.comment}&quot;</div>),
    })),
    ...(props.creditApplication?.chases ?? []).map(chase => ({
      key: chase.id,
      icon: (<RepeatRounded className={styles.chaseIcon} />),
      title: "Chased",
      username: chase.chased_by,
      date: chase.date_of_chase,
      body: (<>
        <div>
          <span className={styles.fieldTitle}>Name:</span>
          <span>{chase.name}</span>
        </div>
        <div>
          <span className={styles.fieldTitle}>How they were contacted:</span>
          <span>{chase.method_of_communication.label}</span>
        </div>
        {chase.comments && (
          <div>&quot;{chase.comments}&quot;</div>
        )}
      </>),
    })),
  ].filter(event => event !== null).sort((a, b) => b?.date - a?.date);

  return (
    <Table basic className={styles.table}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className={styles.headerContainer}>
            <div className={styles.tableHeader}>
              <span className={styles.title}>Event Log</span>
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {events.length > 0 && events.map(event => (
          <EventLogEvent
            key={event.key}
            icon={event.icon}
            title={event.title}
            username={event.username}
            date={parseDate(event.date)}
            body={event.body} />
        ))}
      </Table.Body>
    </Table>
  );
}

InsurerApplicationEventLog.propTypes = {
  creditApplication: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    credit_limit_requested: PropTypes.number.isRequired,
    approved_credit_excluding_vat: PropTypes.number,
    created_by: PropTypes.object.isRequired,
    created_at: PropTypes.number.isRequired,
    completed_at: PropTypes.number,
    completed_by: PropTypes.object,
    initial_comment: PropTypes.string,
    closing_comment: PropTypes.string,
    chases: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      date_of_chase: PropTypes.number,
      method_of_communication: PropTypes.shape({
        label: PropTypes.string,
      }),
      comments: PropTypes.string,
      chased_by: PropTypes.string,
    })),
    comments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      comment: PropTypes.string.isRequired,
      created_at: PropTypes.number,
      created_by: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }),
    })),
  }),
};
