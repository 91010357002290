import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_DETAILS = gql`
query($id: ID!) {
  getAgency(id:$id) {
    id
    short_code
    known_as
    correspondence_name
    trading_as
    website
    linked_in
    twitter

    agency_status {
      id
      label
      value
    }

    previous_provider {
      id
      label
      value
    }

    industry_sectors {
      name
    }
  }
}
`;

export const getAgencyDetails = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_DETAILS_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY_DETAILS,
      variables: {
        id,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_DETAILS_SUCCESS,
      payload: {
        agency: response.data.getAgency,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
