import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_CUSTOMER = gql`
  mutation($id: ID!, $input: UpdateAgencyCustomerInput) {
    updateAgencyCustomer(id: $id, input: $input) {
      is_registered_charity
      registered_charity_number
      credit_limit_requested
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 * @param {boolean} obj.is_registered_charity
 * @param {string} obj.registered_charity_number
 * @param {number} obj.credit_limit_requested
 * @returns
 */
export const updateAgencyCustomer = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_CUSTOMER_PENDING,
    });

    const response = await apolloClient.query({
      query: UPDATE_AGENCY_CUSTOMER,
      variables: {
        id: obj.agencyCustomerId,
        input: {
          is_registered_charity: obj.is_registered_charity,
          registered_charity_number: obj.registered_charity_number,
          credit_limit_requested: parseInt(obj.credit_limit_requested, 10),
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_CUSTOMER_SUCCESS,
      payload: {
        data: response.data.updateAgencyCustomer,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_CUSTOMER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
