import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_PROSPECT_SEARCH = gql`
query($search_options: AgencyProspectSearchOptions!, $allow_empty_search: Boolean) {
  searchAllAgencies(search_options: $search_options, allow_empty_search: $allow_empty_search) {
    data {
      id
      name
      prospect
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.searchText
 * @param {bool} obj.allowEmptySearch
 * @returns
 */
export const getAgencyProspectSearch = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.AGENCY_PROSPECT_SEARCH_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY_PROSPECT_SEARCH,
      variables: {
        search_options: {
          known_as: obj.searchText,
        },
        allow_empty_search: obj.allowEmptySearch,
      },
    });

    dispatch({
      type: types.AGENCY_PROSPECT_SEARCH_SUCCESS,
      payload: {
        data: response.data.searchAllAgencies.data,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.AGENCY_PROSPECT_SEARCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
