import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ReportModalValidator from "./ReportModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import { List } from "../../../lists/containers";
import styles from "./ReportModal.module.scss";

export default function ReportModal(props) {
  const [state, setState] = useState({
    reportedAt: moment().valueOf(),
    reportReasonValue: null,
    reportedComment: "",
  });
  const [errors, setErrors] = useState({});
  const [reportReasons, setReportReasons] = useState([]);

  const handleSubmit = () => {
    const data = {
      ...state,
      reportReasonId: reportReasons.find(x => x.value === state.reportReasonValue)?.id,
    };
    const validator = new ReportModalValidator(data);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.onSubmit(data);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Report to Insurer"
      visible={props.visible}
      submitText="Report"
      submitDisabled={props.submitting}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Date and time should represent when the communication occurred.
      </div>
      <Form>
        <Form.Section>
          <Form.Group inline wide error={errors?.reportedAt}>
            <Form.Label className={styles.label} inline >
              Date of report
            </Form.Label>
            <Form.Date
              name="reportedAt"
              value={state.reportedAt}
              size="small"
              disabled={props.submitting}
              highlightError={errors?.reportedAt}
              onChange={e => setState({ ...state, reportedAt: e.target.value })}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.reportReasonValue}>
            <Form.Label className={styles.label} inline>
              Reason for report
            </Form.Label>
            <List
              identifier="report_to_insurer_reasons"
              value={state.reportReasonValue}
              disabled={props.submitting}
              highlightError={errors?.reportReasonValue}
              fluid
              onReady={list => setReportReasons(list.data?.values)}
              onChange={e => setState({
                ...state,
                reportReasonValue: e.target.value,
              })}
            />
          </Form.Group>
          <Form.Group inline wide>
            <Form.Label className={styles.commentsLabel} inline>
              Comments
            </Form.Label>
            <Form.TextArea
              className={styles.textArea}
              value={state.reportedComment}
              placeholder="Enter comments..."
              rows={6}
              maxLength={255}
              disabled={props.submitting}
              onChange={e => setState({ ...state, reportedComment: e.target.value })}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

ReportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
