import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table2 } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./BackOfficeContactHistoryList.module.scss";

export default class BackOfficeContactHistoryList extends PureComponent {

  static propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      updated_at: PropTypes.number.isRequired,
      changed_by: PropTypes.string,
    })).isRequired,
  }

  render() {
    const { loading, data } = this.props;

    return (
      <Table2
        className={styles.container}
        loading={loading}
        data={data}
      >
        <Table2.Column
          name="name"
          value={row => row.name
            ? <span className={styles.nameValue}>{row.name}</span>
            : "-"
          }
        >
          Name
        </Table2.Column>
        <Table2.Column
          name="updated_at"
          value={row => parseDate(row.updated_at)}
        >
          Date of Change
        </Table2.Column>
        <Table2.Column
          name="changed_by"
          value={row => row.changed_by}
        >
          Changed By
        </Table2.Column>
      </Table2>
    );
  }

}
