import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class TableRow extends PureComponent {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    onClick: PropTypes.func,
  }

  render() {
    const { children, onClick } = this.props;

    return (
      <div className={classnames("TableRow", onClick && "selectable")} onClick={onClick}>
        {children}
      </div>
    );
  }

}
