import _ from "lodash";

export default class HmrcOnlineFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isEmpty(data?.paye_scheme_reference)) {
      errors = _.set(errors, "paye_scheme_reference", "PAYE Scheme Reference is required");
    }

    if (_.isEmpty(data?.accounts_office_reference)) {
      errors = _.set(errors, "accounts_office_reference", "Accounts Office Reference is required");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
