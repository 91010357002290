export const GET_AGENCY_TASKS_PENDING = "ONBOARDING/GET_AGENCY_TASKS_PENDING";
export const GET_AGENCY_TASKS_SUCCESS = "ONBOARDING/GET_AGENCY_TASKS_SUCCESS";
export const GET_AGENCY_TASKS_FAILURE = "ONBOARDING/GET_AGENCY_TASKS_FAILURE";
export const GET_AGENCY_TASK_PENDING = "ONBOARDING/GET_AGENCY_TASK_PENDING";
export const GET_AGENCY_TASK_SUCCESS = "ONBOARDING/GET_AGENCY_TASK_SUCCESS";
export const GET_AGENCY_TASK_FAILURE = "ONBOARDING/GET_AGENCY_TASK_FAILURE";
export const GET_ONBOARDING_AGENCIES_PENDING = "ONBOARDING/GET_ONBOARDING_AGENCIES_PENDING";
export const GET_ONBOARDING_AGENCIES_SUCCESS = "ONBOARDING/GET_ONBOARDING_AGENCIES_SUCCESS";
export const GET_ONBOARDING_AGENCIES_FAILURE = "ONBOARDING/GET_ONBOARDING_AGENCIES_FAILURE";
export const GET_ONBOARDING_PROSPECTS_PENDING = "ONBOARDING/GET_ONBOARDING_PROSPECTS_PENDING";
export const GET_ONBOARDING_PROSPECTS_SUCCESS = "ONBOARDING/GET_ONBOARDING_PROSPECTS_SUCCESS";
export const GET_ONBOARDING_PROSPECTS_FAILURE = "ONBOARDING/GET_ONBOARDING_PROSPECTS_FAILURE";
export const GET_ONBOARDING_TASK_PENDING = "ONBOARDING/GET_ONBOARDING_TASK_PENDING";
export const GET_ONBOARDING_TASK_SUCCESS = "ONBOARDING/GET_ONBOARDING_TASK_SUCCESS";
export const GET_ONBOARDING_TASK_FAILURE = "ONBOARDING/GET_ONBOARDING_TASK_FAILURE";
export const GET_ONBOARDING_TASKS_PENDING = "ONBOARDING/GET_ONBOARDING_TASKS_PENDING";
export const GET_ONBOARDING_TASKS_SUCCESS = "ONBOARDING/GET_ONBOARDING_TASKS_SUCCESS";
export const GET_ONBOARDING_TASKS_FAILURE = "ONBOARDING/GET_ONBOARDING_TASKS_FAILURE";
export const GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_PENDING = "ONBOARDING/GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_PENDING";
export const GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_SUCCESS = "ONBOARDING/GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_SUCCESS";
export const GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_FAILURE = "ONBOARDING/GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_FAILURE";
export const GET_PROSPECT_TASK_PENDING = "ONBOARDING/GET_PROSPECT_TASK_PENDING";
export const GET_PROSPECT_TASK_SUCCESS = "ONBOARDING/GET_PROSPECT_TASK_SUCCESS";
export const GET_PROSPECT_TASK_FAILURE = "ONBOARDING/GET_PROSPECT_TASK_FAILURE";
export const GET_PROSPECT_TASKS_PENDING = "ONBOARDING/GET_PROSPECT_TASKS_PENDING";
export const GET_PROSPECT_TASKS_SUCCESS = "ONBOARDING/GET_PROSPECT_TASKS_SUCCESS";
export const GET_PROSPECT_TASKS_FAILURE = "ONBOARDING/GET_PROSPECT_TASKS_FAILURE";
export const ADD_AGENCY_TASK_TRACKING_ENTRY_PENDING = "ONBOARDING/ADD_AGENCY_TASK_TRACKING_ENTRY_PENDING";
export const ADD_AGENCY_TASK_TRACKING_ENTRY_SUCCESS = "ONBOARDING/ADD_AGENCY_TASK_TRACKING_ENTRY_SUCCESS";
export const ADD_AGENCY_TASK_TRACKING_ENTRY_FAILURE = "ONBOARDING/ADD_AGENCY_TASK_TRACKING_ENTRY_FAILURE";
export const ADD_PROSPECT_TASK_TRACKING_ENTRY_PENDING = "ONBOARDING/ADD_PROSPECT_TASK_TRACKING_ENTRY_PENDING";
export const ADD_PROSPECT_TASK_TRACKING_ENTRY_SUCCESS = "ONBOARDING/ADD_PROSPECT_TASK_TRACKING_ENTRY_SUCCESS";
export const ADD_PROSPECT_TASK_TRACKING_ENTRY_FAILURE = "ONBOARDING/ADD_PROSPECT_TASK_TRACKING_ENTRY_FAILURE";
export const ADD_AGENCY_TASK_COMMENT_PENDING = "ONBOARDING/ADD_AGENCY_TASK_COMMENT_PENDING";
export const ADD_AGENCY_TASK_COMMENT_SUCCESS = "ONBOARDING/ADD_AGENCY_TASK_COMMENT_SUCCESS";
export const ADD_AGENCY_TASK_COMMENT_FAILURE = "ONBOARDING/ADD_AGENCY_TASK_COMMENT_FAILURE";
export const ADD_PROSPECT_TASK_COMMENT_PENDING = "ONBOARDING/ADD_PROSPECT_TASK_COMMENT_PENDING";
export const ADD_PROSPECT_TASK_COMMENT_SUCCESS = "ONBOARDING/ADD_PROSPECT_TASK_COMMENT_SUCCESS";
export const ADD_PROSPECT_TASK_COMMENT_FAILURE = "ONBOARDING/ADD_PROSPECT_TASK_COMMENT_FAILURE";
export const SEARCH_COMPANIES_HOUSE_PENDING = "ONBOARDING/SEARCH_COMPANIES_HOUSE_PENDING";
export const SEARCH_COMPANIES_HOUSE_SUCCESS = "ONBOARDING/SEARCH_COMPANIES_HOUSE_SUCCESS";
export const SEARCH_COMPANIES_HOUSE_FAILURE = "ONBOARDING/SEARCH_COMPANIES_HOUSE_FAILURE";
export const AGENCY_ONBOARDING_TASK_PROGRESS_CHANGED = "ONBOARDING/AGENCY_ONBOARDING_TASK_PROGRESS_CHANGED";
export const PROSPECT_ONBOARDING_TASK_PROGRESS_CHANGED = "ONBOARDING/PROSPECT_ONBOARDING_TASK_PROGRESS_CHANGED";
export const SAVE_AGENCY_ONBOARDING_TASK_PENDING = "ONBOARDING/SAVE_AGENCY_ONBOARDING_TASK_PENDING";
export const SAVE_AGENCY_ONBOARDING_TASK_SUCCESS = "ONBOARDING/SAVE_AGENCY_ONBOARDING_TASK_SUCCESS";
export const SAVE_AGENCY_ONBOARDING_TASK_FAILURE = "ONBOARDING/SAVE_AGENCY_ONBOARDING_TASK_FAILURE";
export const SAVE_PROSPECT_ONBOARDING_TASK_PENDING = "ONBOARDING/SAVE_PROSPECT_ONBOARDING_TASK_PENDING";
export const SAVE_PROSPECT_ONBOARDING_TASK_SUCCESS = "ONBOARDING/SAVE_PROSPECT_ONBOARDING_TASK_SUCCESS";
export const SAVE_PROSPECT_ONBOARDING_TASK_FAILURE = "ONBOARDING/SAVE_PROSPECT_ONBOARDING_TASK_FAILURE";
export const COMPLETE_AGENCY_ONBOARDING_TASK_PENDING = "ONBOARDING/COMPLETE_AGENCY_ONBOARDING_TASK_PENDING";
export const COMPLETE_AGENCY_ONBOARDING_TASK_SUCCESS = "ONBOARDING/COMPLETE_AGENCY_ONBOARDING_TASK_SUCCESS";
export const COMPLETE_AGENCY_ONBOARDING_TASK_FAILURE = "ONBOARDING/COMPLETE_AGENCY_ONBOARDING_TASK_FAILURE";
export const COMPLETE_PROSPECT_ONBOARDING_TASK_PENDING = "ONBOARDING/COMPLETE_PROSPECT_ONBOARDING_TASK_PENDING";
export const COMPLETE_PROSPECT_ONBOARDING_TASK_SUCCESS = "ONBOARDING/COMPLETE_PROSPECT_ONBOARDING_TASK_SUCCESS";
export const COMPLETE_PROSPECT_ONBOARDING_TASK_FAILURE = "ONBOARDING/COMPLETE_PROSPECT_ONBOARDING_TASK_FAILURE";
export const DELETE_AGENCY_TASK_TRACKING_ENTRY_PENDING = "ONBOARDING/DELETE_AGENCY_TASK_TRACKING_ENTRY_PENDING";
export const DELETE_AGENCY_TASK_TRACKING_ENTRY_SUCCESS = "ONBOARDING/DELETE_AGENCY_TASK_TRACKING_ENTRY_SUCCESS";
export const DELETE_AGENCY_TASK_TRACKING_ENTRY_FAILURE = "ONBOARDING/DELETE_AGENCY_TASK_TRACKING_ENTRY_FAILURE";
export const DELETE_PROSPECT_TASK_TRACKING_ENTRY_PENDING = "ONBOARDING/DELETE_PROSPECT_TASK_TRACKING_ENTRY_PENDING";
export const DELETE_PROSPECT_TASK_TRACKING_ENTRY_SUCCESS = "ONBOARDING/DELETE_PROSPECT_TASK_TRACKING_ENTRY_SUCCESS";
export const DELETE_PROSPECT_TASK_TRACKING_ENTRY_FAILURE = "ONBOARDING/DELETE_PROSPECT_TASK_TRACKING_ENTRY_FAILURE";
export const CHECK_AGENCY_SHORT_CODE_PENDING = "ONBOARDING/CHECK_AGENCY_SHORT_CODE_PENDING";
export const CHECK_AGENCY_SHORT_CODE_SUCCESS = "ONBOARDING/CHECK_AGENCY_SHORT_CODE_SUCCESS";
export const CHECK_AGENCY_SHORT_CODE_FAILURE = "ONBOARDING/CHECK_AGENCY_SHORT_CODE_FAILURE";
export const GET_EXISTING_AGENCY_SHORT_CODES_PENDING = "ONBOARDING/GET_EXISTING_AGENCY_SHORT_CODES_PENDING";
export const GET_EXISTING_AGENCY_SHORT_CODES_SUCCESS = "ONBOARDING/GET_EXISTING_AGENCY_SHORT_CODES_SUCCESS";
export const GET_EXISTING_AGENCY_SHORT_CODES_FAILURE = "ONBOARDING/GET_EXISTING_AGENCY_SHORT_CODES_FAILURE";
