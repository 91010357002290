import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_BRANCH = gql`
query(
  $prospect_id: ID!
  $branch_id: ID!
  $disabled_field_filter_options: ProspectDisabledFieldsFilterOptions!
) {
  getProspect(id: $prospect_id) {
    branch(id: $branch_id) {
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
      branch {
        value
      }
    }
    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      prospect_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {number} obj.branchId
 */
export const getBranch = ({ prospectId, branchId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BRANCH_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_BRANCH,
      variables: {
        prospect_id: prospectId,
        branch_id: branchId,
        disabled_field_filter_options: {
          field_names: [
            "branch_details",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_BRANCH_SUCCESS,
      payload: {
        data: {
          ...response.data.getProspect.branch,
          branch_name: response.data.getProspect.branch.branch.value,
          disabled_fields: response.data.getProspect.disabled_fields,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_BRANCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
