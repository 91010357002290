import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_BRANCH = gql`
query(
  $agency_id: ID!
  $branch_id: ID!
) {
  getAgency(id: $agency_id) {
    branch(id: $branch_id) {
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
      branch {
        value
      }
    }
  }
}
`;

export const getBranch = (agencyId, branchId) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BRANCH_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_BRANCH,
      variables: {
        agency_id: agencyId,
        branch_id: branchId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_BRANCH_SUCCESS,
      payload: {
        data: {
          ...response.data.getAgency.branch,
          branch_name: response.data.getAgency.branch.branch.value,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_BRANCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
