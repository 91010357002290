import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as modalActions } from "../../../modals/duck";
import ChangePasswordSuccessModal from "./ChangePasswordSuccessModal";

const mapDispatchToProps = dispatch => bindActionCreators(modalActions, dispatch);

const container = connect(null, mapDispatchToProps)(ChangePasswordSuccessModal);

container.displayName = ChangePasswordSuccessModal.displayName;

export default container;
