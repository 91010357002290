import { push } from "connected-react-router";

export const gotoEditUser = userId => (dispatch) => {
  dispatch(push(`/manage/users/edit/${userId}`));
};

export const gotoAddUser = () => (dispatch) => {
  dispatch(push("/manage/users/new"));
};

export const gotoUsers = () => (dispatch) => {
  dispatch(push("/manage/users"));
};
