import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_BANK_INFORMATION = gql`
query($id: ID!) {
  getAgency(id:$id) {
    banking {
      agency_id
      sort_code
      account_number
      bank_name
      branch
      payee_name
    }
  }
}
`;

export const getAgencyBankInformation = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_BANK_INFORMATION_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY_BANK_INFORMATION,
      variables: {
        id,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_BANK_INFORMATION_SUCCESS,
      payload: {
        banking: response.data.getAgency.banking,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_BANK_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
