import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import {
  Form,
  IconButton,
} from "../../../../common/components";
import styles from "./TradingAddressSection.module.scss";

export default function TradingAddressSection(props) {
  return (
    <Form.Card
      className={styles.container}
      title="Trading Address"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <Form.ValueInline>
        {props.tradingAddress?.address_line_one && (
          <>
            <span>{props.tradingAddress.address_line_one}</span>
            <br />
          </>
        )}
        {props.tradingAddress?.address_line_two && (
          <>
            <span>{props.tradingAddress.address_line_two}</span>
            <br />
          </>
        )}
        {props.tradingAddress?.town_or_city && (
          <>
            <span>{props.tradingAddress.town_or_city}</span>
            <br />
          </>
        )}
        {props.tradingAddress?.county && (
          <>
            <span>{props.tradingAddress.county}</span>
            <br />
          </>
        )}
        {props.tradingAddress?.postcode && (
          <>
            <span>{props.tradingAddress.postcode}</span>
            <br />
          </>
        )}
      </Form.ValueInline>
    </Form.Card>
  );
}

TradingAddressSection.propTypes = {
  companyHistory: PropTypes.shape({
    registered_address: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  tradingAddress: PropTypes.shape({
    address_line_one: PropTypes.string,
    address_line_two: PropTypes.string,
    town_or_city: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
  onEditClick: PropTypes.func,
};
