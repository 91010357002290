import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./CancelScheduledCreditActionModal.module.scss";

export default function CancelScheduledCreditActionModal({
  visible,
  onSubmit,
  onCancel,
}) {
  return (
    <ConfirmationModal
      className={styles.container}
      title="Cancel Scheduled Action"
      submitText="Yes, Cancel It"
      cancelText="No, Take Me Back"
      visible={visible}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      Are you sure you want to cancel the scheduled action?
    </ConfirmationModal>
  );
}

CancelScheduledCreditActionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
