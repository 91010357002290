import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { InfoOutlined } from "@material-ui/icons";
import ContactNumberInput from "../ContactNumberInput/ContactNumberInput";
import { Alert, ConfirmationModal, Form } from "../../";
import styles from "./ContactModal.module.scss";

const defaultState = {
  firstName: "",
  lastName: "",
  preferredName: "",
  contactNumbers: [{
    phoneNumber: "",
    isPreferredNumber: true,
  }],
  emailAddress: "",
  jobRole: "",
  niNumber: "",
};

export default function ContactModal(props) {
  const [state, setState] = useState(_.assign({}, defaultState, {
    ...props.contact,
    contactNumbers: _.isEmpty(props.contact?.contactNumbers) ? defaultState.contactNumbers : props.contact?.contactNumbers,
  }));

  const handleSubmit = () => {
    const contactNumbers = state.contactNumbers?.length === 1
      ? state.contactNumbers.filter(contactNumber => !_.isEmpty(contactNumber.phoneNumber))
      : state.contactNumbers;

    props.onSubmit({
      ...state,
      contactNumbers,
    });
  };

  return (
    <ConfirmationModal
      className={styles.container}
      visible={props.visible}
      title={props.contact ? "Edit Contact" : "New Contact"}
      submitText={props.contact ? "Update Contact" : "Add Contact"}
      cancelText="Cancel"
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
    >
      <Form>
        <Form.Section>
          <Form.Group error={props.errors?.firstName}>
            <Form.Label>First Name</Form.Label>
            <Form.Input
              fluid
              value={state.firstName}
              maxLength={255}
              highlightError={props.errors?.firstName}
              onChange={e => setState({ ...state, firstName: e.target.value })}
            />
          </Form.Group>
          <Form.Group error={props.errors?.lastName}>
            <Form.Label>Last Name</Form.Label>
            <Form.Input
              fluid
              value={state.lastName}
              maxLength={255}
              highlightError={props.errors?.lastName}
              onChange={e => setState({ ...state, lastName: e.target.value })}
            />
          </Form.Group>
          <Form.Group error={props.errors?.preferredName}>
            <Form.Label fluid note="(Optional)">Preferred Name</Form.Label>
            <Form.Input
              fluid
              value={state.preferredName}
              maxLength={255}
              highlightError={props.errors?.preferredName}
              onChange={e => setState({ ...state, preferredName: e.target.value })}
            />
          </Form.Group>
          <Form.Group error={props.errors?.niNumber}>
            <Form.Label size="medium" note="(Optional)">NI Number</Form.Label>
            <Form.Input
              size="medium"
              value={state.niNumber}
              maxLength={13}
              onChange={e => setState({
                ...state,
                niNumber: _.upperCase(e.target.value),
              })}
              maskOptions={{
                mask: "aa 99 99 99 a",
                maskChar: null,
              }}
            />
          </Form.Group>
          <Form.Group error={props.errors?.telephoneNumber}>
            <Form.Label>Telephone Number</Form.Label>
            <ContactNumberInput
              contactNumbers={state.contactNumbers}
              errors={props.errors?.contactNumbers}
              onAdd={() => setState({
                ...state,
                contactNumbers: [
                  ...state.contactNumbers, { phoneNumber: "", isPreferredNumber: false },
                ],
              })}
              onRemove={(index) => {
                const newContactNumbers = state.contactNumbers.filter((c, i) => i !== index);

                if (newContactNumbers.every(c => !c.isPreferredNumber)) {
                  newContactNumbers[0].isPreferredNumber = true;
                }

                setState({
                  ...state,
                  contactNumbers: newContactNumbers,
                });
              }}
              onChange={(contactNumber, index) => setState({
                ...state,
                contactNumbers: state.contactNumbers.map((c, i) => i === index ? contactNumber : c),
              })}
              onPreferred={index => setState({
                ...state,
                contactNumbers: state.contactNumbers.map((c, i) => ({
                  ...c,
                  isPreferredNumber: i === index,
                })),
              })}
            />
          </Form.Group>
          <Form.Group error={props.errors?.emailAddress}>
            <Form.Label>Email Address</Form.Label>
            <Form.Input
              fluid
              value={state.emailAddress}
              maxLength={255}
              highlightError={props.errors?.emailAddress}
              onChange={e => setState({ ...state, emailAddress: e.target.value })}
            />
          </Form.Group>
          <Form.Group error={props.errors?.jobRole}>
            <Form.Label fluid note="(Optional)">Job Role</Form.Label>
            <Form.Input
              fluid
              value={state.jobRole}
              maxLength={255}
              highlightError={props.errors?.jobRole}
              onChange={e => setState({ ...state, jobRole: e.target.value })}
            />
          </Form.Group>
        </Form.Section>
        {props.errors?.error && (
          <Alert variant="error" showClose={false}>
            <div className={styles.error}>
              <InfoOutlined className={styles.errorIcon} />
              {props.errors?.error}
            </div>
          </Alert>
        )}
      </Form>
    </ConfirmationModal>
  );
}

ContactModal.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    telephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    jobRole: PropTypes.string,
    niNumber: PropTypes.string,
  }),
  visible: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    telephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    jobRole: PropTypes.string,
    niNumber: PropTypes.string,
    contactNumbers: PropTypes.arrayOf(PropTypes.shape({
      index: PropTypes.number.isRequired,
      phoneNumberType: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      phoneNumber: PropTypes.string,
    })),
    error: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

