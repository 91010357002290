import _ from "lodash";

export default class AgencyBankInformationFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (!this._isFieldDisabled("sort_code")) {
      if (_.isEmpty(data?.sort_code)) {
        errors = _.set(errors, "sort_code", "Sort Code is required");
      }

      if (!_.isEmpty(data?.sort_code) && data?.sort_code.length < 8) {
        errors = _.set(errors, "sort_code", "Sort Code must be at least 6 numbers");
      }

      if (_.isEmpty(data?.account_number)) {
        errors = _.set(errors, "account_number", "Account Number is required");
      }
      else if (data.account_number.length < 8) {
        errors = _.set(errors, "account_number", "8 digits are required, use leading 0s for Bank Account no's with fewer than 8 digits, e.g. 00123456");
      }

      if (_.isEmpty(data?.payee_name)) {
        errors = _.set(errors, "payee_name", "Payee Name is required");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  _isFieldDisabled = (fieldName) => {
    return this.props?.disabledFields?.includes(fieldName);
  }
}
