import _ from "lodash";
import * as types from "./types";
import config from "../../../config";

const { DEFAULT_ITEMS_PER_PAGE } = config;

const initialState = {
  loading: true,
  busy: false,
  error: null,
  legalEntities: [],
  agencies: [],
  globalDefaults: {
    loading: false,
    data: {},
    error: null,
  },
  pagination: {
    page: 1,
    limit: DEFAULT_ITEMS_PER_PAGE,
    pages: 1,
    total: 0,
  },
  activeCreditApplications: {
    loading: false,
    data: null,
    error: null,
  },
  legalEntity: {
    loading: true,
    data: null,
    error: null,
  },
  legalEntityDetails: {
    loading: true,
    data: null,
    error: null,
  },
  legalEntityHistory: {
    loading: true,
    data: [],
    error: null,
  },
  creditDetails: null,
  companiesHouseResults: { searchByName: { total: 0, data: [] }, searchByNumber: { total: 0, data: [] } },
  companiesHouseCompanyProfile: {
    loading: false,
    data: null,
    error: null,
  },
  companyCertUrl: {
    loading: true,
    data: null,
    error: null,
  },
  creditApplications: {
    loading: true,
    data: [],
    error: null,
  },
  creditApplication: {
    loading: true,
    data: null,
    error: null,
  },
  creditApplicationForApproval: {
    loading: true,
    data: null,
    error: null,
  },
  experianCreditChecks: {
    loading: true,
    data: [],
    total: 0,
    error: null,
  },
  creditActivity: {
    loading: true,
    data: [],
    error: null,
  },
  insurerReportingActivity: {
    loading: false,
    data: null,
    error: null,
  },
  insurerReportingActivities: {
    loading: true,
    data: [],
    error: null,
  },
  scheduledCreditActions: {
    loading: true,
    data: [],
    error: null,
  },
  scheduledCreditAction: {
    loading: true,
    data: null,
    error: null,
  },
  agencyProspectSearch: {
    loading: false,
    data: [],
    error: null,
    pending: false,
  },
  agencyCustomerSearch: {
    loading: false,
    data: [],
    error: null,
    pending: false,
  },
  companyDetails: {
    loading: false,
    data: null,
    error: null,
  },
  contactInformation: {
    loading: false,
    data: null,
    error: null,
  },
  creditOverview: {
    loading: false,
    data: null,
    error: null,
  },
  agencyCustomerRequests: {
    loading: false,
    data: [],
    error: null,
  },
  agencyCustomers: {
    loading: false,
    data: [],
    error: null,
  },
  experianCreditCheckRequest: {
    loading: true,
    data: null,
    error: null,
  },
  creditRequest: {
    loading: false,
    data: null,
    error: null,
  },
  creditRequests: {
    loading: false,
    data: [],
    error: null,
  },
  currentTasks: {
    loading: false,
    data: [],
    filters: [],
    error: null,
    pagination: null,
  },
  creditReviewDates: {
    loading: false,
    data: [],
    error: null,
  },
  agencyContacts: {
    loading: false,
    data: [],
    creditController: null,
    error: null,
  },
  scheduledExperianActions: {
    loading: false,
    data: [],
    error: null,
  },
  experianCreditCheckDocuments: {
    loading: true,
    data: [],
    error: null,
  },
  ui: {
    submitting: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_GLOBAL_DEFAULT_PENDING:
      return {
        ...state,
        globalDefaults: {
          ...state.globalDefaults,
          loading: true,
          error: null,
        },
      };

    case types.GET_GLOBAL_DEFAULT_SUCCESS:
      return {
        ...state,
        globalDefaults: {
          loading: false,
          data: { ...state.globalDefaults.data, [action.payload.data.key]: action.payload.data.value },
          error: null,
        },
      };

    case types.GET_GLOBAL_DEFAULT_FAILURE:
      return {
        ...state,
        globalDefaults: {
          ...state.globalDefaults,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_LEGAL_ENTITIES_PENDING:
      return { ...state, loading: true, error: null };

    case types.GET_LEGAL_ENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        legalEntities: action.payload.legalEntities,
        pagination: action.payload.pagination,
      };

    case types.GET_LEGAL_ENTITIES_FAILURE:
      return { ...state, loading: false, legalEntities: [], error: action.payload.error };

    case types.SEARCH_LEGAL_ENTITIES_PENDING:
      return { ...state, loading: true, error: null };

    case types.SEARCH_LEGAL_ENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        legalEntities: action.payload.legalEntities,
        pagination: action.payload.pagination,
      };

    case types.SEARCH_LEGAL_ENTITIES_FAILURE:
      return {
        ...state,
        loading: false,
        legalEntities: initialState.legalEntities,
        pagination: initialState.pagination,
        error: action.payload.error,
      };

    case types.SEARCH_COMPANIES_HOUSE_PENDING:
      return { ...state, loading: true, error: null };

    case types.SEARCH_COMPANIES_HOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesHouseResults:
        action.payload.companiesHouseResults,
      };

    case types.SEARCH_COMPANIES_HOUSE_FAILURE:
      return { ...state,
        loading: false,
        companiesHouseResults: { searchByName: { total: 0, data: [] }, searchByNumber: { total: 0, data: [] } },
        error: action.payload.error,
      };


    case types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_PENDING:
      return {
        ...state,
        companiesHouseCompanyProfile: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        companiesHouseCompanyProfile: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
        companiesHouseResults: { searchByName: { total: 0, data: [] }, searchByNumber: { total: 0, data: [] } },
      };

    case types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        companiesHouseCompanyProfile: {
          loading: false,
          data: null,
          error: action.payload.error ,
        },
      };

    case types.ADD_LEGAL_ENTITIES_PENDING:
      return { ...state, loading: true, error: null };

    case types.ADD_LEGAL_ENTITIES_SUCCESS:
      return { ...state, loading: false };

    case types.ADD_LEGAL_ENTITIES_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.SET_PAGINATION:
      return { ...state, pagination: action.payload.pagination };

    case types.GET_LEGAL_ENTITY_PENDING:
      return {
        ...state,
        legalEntity: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_LEGAL_ENTITY_SUCCESS:
      return {
        ...state,
        legalEntity: {
          loading: false,
          data: action.payload.legalEntity,
          error: null,
        },
      };

    case types.GET_LEGAL_ENTITY_FAILURE:
      return {
        ...state,
        legalEntity: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_LEGAL_ENTITY_DETAILS_PENDING:
      return {
        ...state,
        legalEntityDetails: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_LEGAL_ENTITY_DETAILS_SUCCESS:
      return {
        ...state,
        legalEntityDetails: {
          loading: false,
          data: action.payload.legalEntityDetails,
          error: null,
        },
      };

    case types.GET_LEGAL_ENTITY_DETAILS_FAILURE:
      return {
        ...state,
        legalEntityDetails: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_LEGAL_ENTITY_HISTORY_PENDING:
      return { ...state, legalEntityHistory: initialState.legalEntityHistory };

    case types.GET_LEGAL_ENTITY_HISTORY_SUCCESS:
      return { ...state, legalEntityHistory: { loading: false, data: action.payload.revisions } };

    case types.GET_LEGAL_ENTITY_HISTORY_FAILURE:
      return { ...state, legalEntityHistory: { loading: false, data: [], error: action.payload.error } };

    case types.UPDATE_REGISTERED_COMPANY_PENDING:
    case types.UPDATE_NON_REGISTERED_COMPANY_PENDING:
      return { ...state, busy: true, error: null };

    case types.UPDATE_REGISTERED_COMPANY_SUCCESS:
    case types.UPDATE_NON_REGISTERED_COMPANY_SUCCESS:
      return {
        ...state,
        busy: false,
        legalEntityDetails: {
          loading: false,
          data: action.payload.legalEntityDetails,
          error: null,
        },
        legalEntity: {
          loading: false,
          data: null,
          error: null,
        },
      };

    case types.UPDATE_REGISTERED_COMPANY_FAILURE:
    case types.UPDATE_NON_REGISTERED_COMPANY_FAILURE:
      return {
        ...state,
        busy: false,
        error: action.payload.error,
      };

    case types.GET_CREDIT_DETAILS_PENDING:
      return { ...state, loading: true, creditDetails: null, error: null };

    case types.GET_CREDIT_DETAILS_SUCCESS:
      return { ...state, loading: false, creditDetails: action.payload.creditDetails };

    case types.GET_CREDIT_DETAILS_FAILURE:
      return { ...state, loading: false, creditDetails: null, error: action.payload.error };

    case types.TOGGLE_CREDIT_SUSPENSION_PENDING:
      return { ...state, loading: true };

    case types.TOGGLE_CREDIT_SUSPENSION_SUCCESS:
      return {
        ...state,
        loading: false,
        legalEntity: {
          ...state.legalEntity,
          data: {
            ...state.legalEntity.data,
            active_suspension: action.payload.activeSuspension,
            scheduled_suspension: action.payload.scheduledSuspension,
          },
        },
        creditOverview: {
          ...state.creditOverview,
          data: {
            ...state.creditOverview.data,
            active_suspension: action.payload.activeSuspension,
            scheduled_suspension: action.payload.scheduledSuspension,
          },
        },
      };

    case types.TOGGLE_CREDIT_SUSPENSION_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.CANCEL_CREDIT_SUSPENSION_PENDING:
      return {
        ...state,
        creditActivity: {
          ...state.creditActivity,
          loading: true,
          error: null,
        },
      };

    case types.CANCEL_CREDIT_SUSPENSION_SUCCESS:
      return {
        ...state,
        creditActivity: {
          ...state.creditActivity,
          loading: false,
          data: state.creditActivity.data.map((activity) => {
            if (
              ["suspend", "unsuspend"].includes(activity.action) &&
              activity.id === action.payload.data.id
            ) {
              return {
                ...activity,
                ...action.payload.data,
              };
            }
            return activity;
          }),
        },
        legalEntity: {
          ...state.legalEntity,
          data: {
            ...state.legalEntity.data,
            scheduled_suspension: null,
          },
        },
        creditOverview: {
          ...state.creditOverview,
          data: {
            ...state.creditOverview.data,
            scheduled_suspension: null,
          },
        },
      };

    case types.CANCEL_CREDIT_SUSPENSION_FAILURE:
      return {
        ...state,
        creditActivity: {
          ...state.creditActivity,
          loading: false,
          error: action.payload.err,
        },
      };

    case types.CREATE_CREDIT_APPLICATION_PENDING:
      return {
        ...state,
        creditApplication: {
          loading: true,
          data: null,
          error: null,
        },
        ui: {
          submitting: true,
        },
      };

    case types.CREATE_CREDIT_APPLICATION_SUCCESS:
      return {
        ...state,
        creditApplication: {
          loading: false,
          data: action.payload.creditApplication,
          error: null,
        },
        creditApplications: {
          ...state.creditApplications,
          data: [
            action.payload.creditApplication,
            ...state.creditApplications.data,
          ],
        },
        ui: {
          submitting: false,
        },
      };

    case types.CREATE_CREDIT_APPLICATION_FAILURE:
      return {
        ...state,
        creditApplication: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
        ui: {
          submitting: false,
        },
      };

    case types.CREATE_CREDIT_APPLICATION_COMMENT_PENDING:
      return { ...state, loading: true, error: null };

    case types.CREATE_CREDIT_APPLICATION_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        creditApplication: {
          loading: false,
          data: {
            ...state.creditApplication.data,
            comments: [action.payload.comment, ...state.creditApplication.data.comments],
          },
          error: null,
        },
      };

    case types.CREATE_CREDIT_APPLICATION_COMMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.COMPLETE_CREDIT_APPLICATION_PENDING:
      return {
        ...state,
        creditApplication: {
          data: null,
          loading: true,
          error: null,
        },
        ui: {
          submitting: true,
        },
      };

    case types.COMPLETE_CREDIT_APPLICATION_SUCCESS:
      return {
        ...state,
        creditApplications: {
          ...state.creditApplications,
          data: state.creditApplications.data.map((creditApplication) => {
            return creditApplication.id === action.payload.creditApplication.id
              ? { ...creditApplication, ...action.payload.creditApplication }
              : creditApplication;
          }),
        },
        creditApplication: {
          data: action.payload.creditApplication,
          loading: false,
          error: null,
        },
        ui: {
          submitting: false,
        },
      };

    case types.COMPLETE_CREDIT_APPLICATION_FAILURE:
      return {
        ...state,
        creditApplication: {
          data: null,
          loading: false,
          error: action.payload.error,
        },
        ui: {
          submitting: false,
        },
      };

    case types.CANCEL_CREDIT_APPLICATION_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.CANCEL_CREDIT_APPLICATION_SUCCESS:
      return {
        ...state,
        creditApplications: {
          ...state.creditApplications,
          data: state.creditApplications.data.map((creditApplication) => {
            return creditApplication.id === action.payload.creditApplication.id
              ? { ...creditApplication, ...action.payload.creditApplication }
              : creditApplication;
          }),
        },
        ui: {
          submitting: false,
        },
      };

    case types.CANCEL_CREDIT_APPLICATION_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_CREDIT_APPLICATION_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_CREDIT_APPLICATION_SUCCESS:
      return {
        ...state,
        creditApplications: {
          ...state.creditApplications,
          data: state.creditApplications.data.map((creditApplication) => {
            return creditApplication.id === action.payload.creditApplication.id
              ? { ...creditApplication, ...action.payload.creditApplication }
              : creditApplication;
          }),
        },
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_CREDIT_APPLICATION_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.USE_DISCRETIONARY_LIMIT_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.USE_DISCRETIONARY_LIMIT_SUCCESS:
      return {
        ...state,
        creditApplications: {
          ...state.creditApplications,
          data: state.creditApplications.data.map((creditApplication) => {
            return creditApplication.id === action.payload.creditApplication.id
              ? { ...creditApplication, ...action.payload.creditApplication }
              : creditApplication;
          }),
        },
        ui: {
          submitting: false,
        },
      };

    case types.USE_DISCRETIONARY_LIMIT_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.GET_EXPERIAN_CREDIT_CHECKS_PENDING:
      return { ...state, experianCreditChecks: initialState.experianCreditChecks };

    case types.GET_EXPERIAN_CREDIT_CHECKS_SUCCESS:
      return {
        ...state,
        experianCreditChecks: {
          loading: false,
          data: action.payload.experianCreditChecks.data,
          total: action.payload.experianCreditChecks.data.length,
        },
      };

    case types.GET_EXPERIAN_CREDIT_CHECKS_FAILURE:
      return {
        ...state,
        experianCreditChecks: {
          loading: false,
          data: [],
          total: 0,
          error: action.payload.error,
        },
      };

    case types.GET_CREDIT_ACTIVITY_PENDING:
      return { ...state, creditActivity: initialState.creditActivity };

    case types.GET_CREDIT_ACTIVITY_SUCCESS:
      return { ...state, creditActivity: { loading: false, data: action.payload.data } };

    case types.GET_CREDIT_ACTIVITY_FAILURE:
      return { ...state, creditActivity: { loading: false, data: [], error: action.payload.error } };

    case types.GET_CREDIT_APPLICATIONS_PENDING:
      return { ...state, creditApplications: initialState.creditApplications };

    case types.GET_CREDIT_APPLICATIONS_SUCCESS:
      return { ...state, creditApplications: { loading: false, data: action.payload.creditApplications } };

    case types.GET_CREDIT_APPLICATIONS_FAILURE:
      return { ...state, creditApplications: { loading: false, data: [], error: action.payload.error } };

    case types.GET_CREDIT_APPLICATION_PENDING:
      return {
        ...state,
        creditApplication: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_CREDIT_APPLICATION_SUCCESS:
      return {
        ...state,
        creditApplication: {
          loading: false,
          data: action.payload.creditApplication,
          error: null,
        },
      };

    case types.GET_CREDIT_APPLICATION_FAILURE:
      return {
        ...state,
        creditApplication: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_CREDIT_APPLICATION_FOR_APPROVAL_PENDING:
      return {
        ...state,
        creditApplicationForApproval: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_CREDIT_APPLICATION_FOR_APPROVAL_SUCCESS:
      return {
        ...state,
        creditApplicationForApproval: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_CREDIT_APPLICATION_FOR_APPROVAL_FAILURE:
      return {
        ...state,
        creditApplicationForApproval: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.ADD_INSURER_REPORTING_ACTIVITY_PENDING:
      return {
        ...state,
        insurerReportingActivity: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.ADD_INSURER_REPORTING_ACTIVITY_SUCCESS:
      return {
        ...state,
        insurerReportingActivity: {
          loading: false,
          data: action.payload.insurerReportingActivity,
        },
        insurerReportingActivities: {
          ...state.insurerReportingActivities,
          data: [
            ...state.insurerReportingActivities.data,
            action.payload.insurerReportingActivity,
          ],
        },
      };

    case types.ADD_INSURER_REPORTING_ACTIVITY_FAILURE:
      return {
        ...state,
        insurerReportingActivity: {
          data: null,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.UNREPORT_INSURER_REPORTING_ACTIVITY_PENDING:
      return { ...state, insurerReportingActivity: initialState.insurerReportingActivity };

    case types.UNREPORT_INSURER_REPORTING_ACTIVITY_SUCCESS:
      return {
        ...state,
        insurerReportingActivity: {
          loading: false,
          data: action.payload.insurerReportingActivity,
        },
        insurerReportingActivities: {
          ...state.insurerReportingActivities,
          data: _.filter(state.insurerReportingActivities.data, (x) => {
            return x.id !== action.payload.insurerReportingActivity.id;
          }),
        },
      };

    case types.UNREPORT_INSURER_REPORTING_ACTIVITY_FAILURE:
      return {
        ...state,
        insurerReportingActivity: {
          data: null,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_INSURER_REPORTING_ACTIVITIES_PENDING:
      return {
        ...state,
        insurerReportingActivities: initialState.insurerReportingActivities,
      };

    case types.GET_INSURER_REPORTING_ACTIVITIES_SUCCESS:
      return {
        ...state,
        insurerReportingActivities: {
          loading: false,
          data: action.payload.insurerReportingActivities,
        },
      };

    case types.GET_INSURER_REPORTING_ACTIVITIES_FAILURE:
      return {
        ...state,
        insurerReportingActivities: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.CREATE_SCHEDULED_CREDIT_ACTION_PENDING:
      return { ...state, scheduledCreditAction: initialState.scheduledCreditAction };

    case types.CREATE_SCHEDULED_CREDIT_ACTION_SUCCESS:
      return { ...state, scheduledCreditActions: { loading: false, data: [...state.scheduledCreditActions.data, action.payload.scheduledCreditAction] } };

    case types.CREATE_SCHEDULED_CREDIT_ACTION_FAILURE:
      return { ...state, scheduledCreditAction: { loading: false, data: null, error: action.payload.error } };

    case types.GET_SCHEDULED_CREDIT_ACTIONS_PENDING:
      return { ...state, scheduledCreditActions: initialState.scheduledCreditActions };

    case types.GET_SCHEDULED_CREDIT_ACTIONS_SUCCESS:
      return { ...state, scheduledCreditActions: { loading: false, data: action.payload.scheduledCreditActions } };

    case types.GET_SCHEDULED_CREDIT_ACTIONS_FAILURE:
      return { ...state, scheduledCreditActions: { loading: false, data: [], error: action.payload.error } };

    case types.GET_SCHEDULED_CREDIT_ACTION_PENDING:
      return { ...state, scheduledCreditAction: initialState.scheduledCreditAction };

    case types.GET_SCHEDULED_CREDIT_ACTION_SUCCESS:
      return { ...state, scheduledCreditAction: { loading: false, data: action.payload.scheduledCreditAction } };

    case types.GET_SCHEDULED_CREDIT_ACTION_FAILURE:
      return { ...state, scheduledCreditAction: { loading: false, data: null, error: action.payload.error } };

    case types.CANCEL_SCHEDULED_CREDIT_ACTION_PENDING:
      return { ...state, scheduledCreditAction: { ...state.scheduledCreditAction, loading: true, error: null } };

    case types.CANCEL_SCHEDULED_CREDIT_ACTION_SUCCESS:
      return {
        ...state,
        scheduledCreditAction: {
          ...state.scheduledCreditAction,
          loading: false,
        },
        scheduledCreditActions: {
          ...state.scheduledCreditActions,
          data: state.scheduledCreditActions?.data.map((scheduledCreditAction) => {
            return scheduledCreditAction.id === action.payload.id
              ? { ...scheduledCreditAction, ...action.payload }
              : scheduledCreditAction;
          }),
        },
      };

    case types.CANCEL_SCHEDULED_CREDIT_ACTION_FAILURE:
      return { ...state, scheduledCreditAction: { ...state.scheduledCreditAction, loading: false, error: action.payload.error } };

    case types.CREATE_SCHEDULED_EXPERIAN_ACTION_PENDING:
      return {
        ...state,
        scheduledExperianActions: {
          ...state.scheduledExperianActions,
          error: null,
        },
        ui: {
          ...state.ui,
          submitting: true,
        },
      };

    case types.CREATE_SCHEDULED_EXPERIAN_ACTION_SUCCESS:
      return {
        ...state,
        scheduledExperianActions: {
          ...state.scheduledExperianActions,
          data: [...state.scheduledExperianActions.data, action.payload.data],
          error: null,
        },
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.CREATE_SCHEDULED_EXPERIAN_ACTION_FAILURE:
      return {
        ...state,
        scheduledExperianActions: {
          ...state.scheduledExperianActions,
          error: action.payload.error,
        },
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.GET_SCHEDULED_EXPERIAN_ACTIONS_PENDING:
      return {
        ...state,
        scheduledExperianActions: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_SCHEDULED_EXPERIAN_ACTIONS_SUCCESS:
      return {
        ...state,
        scheduledExperianActions: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_SCHEDULED_EXPERIAN_ACTIONS_FAILURE:
      return {
        ...state,
        scheduledExperianActions: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_PENDING:
      return {
        ...state,
        agencyProspectSearch: {
          ...state.agencyProspectSearch,
          loading: true,
          error: null,
          data: [],
          pending: true,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_SUCCESS:
      return {
        ...state,
        agencyProspectSearch: {
          loading: false,
          data: action.payload.data,
          error: null,
          pending: false,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_FAILURE:
      return {
        ...state,
        agencyProspectSearch: {
          loading: false,
          data: [],
          error: action.payload.error,
          pending: false,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_CLEAR:
      return {
        ...state,
        agencyProspectSearch: {
          loading: false,
          data: [],
          error: null,
          pending: true,
        },
      };

    case types.AGENCY_CUSTOMER_SEARCH_PENDING:
      return {
        ...state,
        agencyCustomerSearch: {
          loading: true,
          error: null,
          data: [],
          pending: true,
        },
      };

    case types.AGENCY_CUSTOMER_SEARCH_SUCCESS:
      return {
        ...state,
        agencyCustomerSearch: {
          loading: false,
          data: action.payload.data,
          error: null,
          pending: false,
        },
      };

    case types.AGENCY_CUSTOMER_SEARCH_FAILURE:
      return {
        ...state,
        agencyCustomerSearch: {
          loading: false,
          data: [],
          error: action.payload.error,
          pending: false,
        },
      };

    case types.ADD_REGISTERED_COMPANY_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.ADD_REGISTERED_COMPANY_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.ADD_REGISTERED_COMPANY_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.ADD_NON_REGISTERED_COMPANY_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.ADD_NON_REGISTERED_COMPANY_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.ADD_NON_REGISTERED_COMPANY_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.GET_COMPANY_DETAILS_PENDING:
      return {
        ...state,
        companyDetails: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        companyDetails: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.UPDATE_COMPANY_DETAILS_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        companyDetails: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
        ui: {
          submitting: false,
        },
      };

    case types.GET_CONTACT_INFORMATION_PENDING:
      return {
        ...state,
        contactInformation: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_CONTACT_INFORMATION_SUCCESS:
      return {
        ...state,
        contactInformation: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_CONTACT_INFORMATION_FAILURE:
      return {
        ...state,
        contactInformation: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.UPDATE_CONTACT_INFORMATION_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_CONTACT_INFORMATION_SUCCESS:
      return {
        ...state,
        contactInformation: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
        ui: {
          submitting: false,
        },
      };

    case types.UPDATE_CONTACT_INFORMATION_FAILURE:
      return {
        ...state,
        contactInformation: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
        ui: {
          submitting: false,
        },
      };

    case types.GET_CREDIT_OVERVIEW_PENDING:
      return {
        ...state,
        creditOverview: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_CREDIT_OVERVIEW_SUCCESS:
      return {
        ...state,
        creditOverview: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_CREDIT_OVERVIEW_FAILURE:
      return {
        ...state,
        creditOverview: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.ADD_AGENCY_CUSTOMER_REQUEST_SUCCESS:
      return {
        ...state,
        agencyCustomerRequests: {
          ...state.agencyCustomerRequests,
          data: [
            ...state.agencyCustomerRequests.data,
            action.payload.data,
          ],
        },
      };

    case types.GET_AGENCY_CUSTOMER_REQUESTS_PENDING:
      return {
        ...state,
        agencyCustomerRequests: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMER_REQUESTS_SUCCESS:
      return {
        ...state,
        agencyCustomerRequests: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMER_REQUESTS_FAILURE:
      return {
        ...state,
        agencyCustomerRequests: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.UPDATE_AGENCY_CUSTOMER_REQUEST_SUCCESS:
    case types.REASSIGN_AGENCY_CUSTOMER_REQUEST_SUCCESS:
      return {
        ...state,
        agencyCustomerRequests: {
          ...state.agencyCustomerRequests,
          data: state.agencyCustomerRequests.data.map((agencyCustomerRequest) => {
            return agencyCustomerRequest.id === action.payload.data.id
              ? { ...agencyCustomerRequest, ...action.payload.data }
              : agencyCustomerRequest;
          }),
        },
      };

    case types.GET_AGENCY_CUSTOMERS_PENDING:
      return {
        ...state,
        agencyCustomers: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMERS_SUCCESS:
      return {
        ...state,
        agencyCustomers: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMERS_FAILURE:
      return {
        ...state,
        agencyCustomers: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.DISTRIBUTE_CREDIT_PENDING:
      return {
        ...state,
        creditOverview: {
          loading: true,
          data: state.creditOverview.data,
          error: null,
        },
      };

    case types.DISTRIBUTE_CREDIT_SUCCESS:
      return {
        ...state,
        legalEntity: {
          ...state.legalEntity,
          data: {
            ...state.legalEntity.data,
            undistributed_credit_limit: action.payload.legal_entity.undistributed_credit_limit,
          },
        },
        creditOverview: {
          loading: false,
          data: {
            ...state.creditOverview.data,
            undistributed_credit_limit: action.payload.legal_entity.undistributed_credit_limit,
          },
          error: null,
        },
        agencyCustomers: {
          ...state.agencyCustomers,
          data: action.payload.legal_entity.agency_customers,
        },
      };

    case types.DISTRIBUTE_CREDIT_FAILURE:
      return {
        ...state,
        creditOverview: {
          loading: false,
          data: state.creditOverview.data,
          error: action.payload.error,
        },
      };

    case types.VERIFY_AGENCY_CUSTOMER_SUCCESS:
      return {
        ...state,
        agencyCustomerRequests: {
          data: state.agencyCustomerRequests.data.filter((agencyCustomerRequest) => {
            return agencyCustomerRequest.id !== action.payload.agencyCustomerRequestId;
          }),
        },
        agencyCustomers: {
          ...state.agencyCustomers,
          data: [
            ...state.agencyCustomers.data,
            action.payload.data,
          ],
        },
        creditOverview: {
          ...state.creditOverview,
          data: {
            ...state.creditOverview.data,
            undistributed_credit_limit: action.payload.data.legal_entity.undistributed_credit_limit,
          },
        },
        creditRequests: {
          ...state.creditRequests,
          data: state.creditRequests.data.map((creditRequest) => {
            if (action.payload.data.agency && creditRequest.agency?.id === action.payload.data.agency.id) {
              creditRequest.is_agency_customer_verified = true;
            }
            else if (action.payload.data.prospect && creditRequest.prospect?.id === action.payload.data.prospect.id) {
              creditRequest.is_agency_customer_verified = true;
            }
            return creditRequest;
          }),
        },
      };

    case types.ADD_EXPERIAN_CREDIT_CHECK_PENDING:
      return {
        ...state,
        experianCreditCheckRequest: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.ADD_EXPERIAN_CREDIT_CHECK_SUCCESS:
      return {
        ...state,
        experianCreditCheckRequest: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
        creditOverview: {
          ...state.creditOverview,
          data: {
            ...state.creditOverview.data,
            credit_limit_excluding_vat: action.payload.data.legal_entity.credit_limit_excluding_vat,
            credit_limit_including_vat: action.payload.data.legal_entity.credit_limit_including_vat,
            undistributed_credit_limit: action.payload.data.legal_entity.undistributed_credit_limit,
            latest_experian_report: action.payload.data.legal_entity.latest_experian_report,
            experian_credit_checks: state.creditOverview.data?.experian_credit_checks
              ? [
                action.payload.data.experian_credit_check,
                ...state.creditOverview.data?.experian_credit_checks,
              ]
              : [
                action.payload.data.experian_credit_check,
              ],
          },
        },
        legalEntity: {
          ...state.legalEntity,
          data: {
            ...state.legalEntity.data,
            experian_credit_checks: state.legalEntity.data?.experian_credit_checks
              ? [
                action.payload.data.experian_credit_check,
                ...state.legalEntity.data?.experian_credit_checks,
              ]
              : [
                action.payload.data.experian_credit_check,
              ],
          },
        },
      };

    case types.ADD_EXPERIAN_CREDIT_CHECK_FAILURE:
      return {
        ...state,
        experianCreditCheckRequest: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.ADD_EXPERIAN_CREDIT_CHECK_CLEAR:
      return {
        ...state,
        experianCreditCheckRequest: {
          loading: false,
          data: null,
          error: null,
        },
      };

    case types.ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_PENDING:
      return {
        ...state,
        experianCreditCheckRequest: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_SUCCESS:
      return {
        ...state,
        experianCreditCheckRequest: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
        creditOverview: {
          ...state.creditOverview,
          data: {
            ...state.creditOverview.data,
            ...action.payload.data.agency_customer.legal_entity,
          },
        },
        agencyCustomers: {
          ...state.agencyCustomers,
          data: _.uniqBy([
            action.payload.data.agency_customer,
            ...state.agencyCustomers.data,
          ], "id"),
        },
        creditRequests: {
          ...state.creditRequests,
          data: _.uniqBy([
            action.payload.data.credit_request,
            ...state.creditRequests.data,
          ], "id"),
        },
        legalEntity: {
          ...state.legalEntity,
          data: {
            ...state.legalEntity.data,
            ...action.payload.data.agency_customer.legal_entity,
          },
        },
      };

    case types.ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_FAILURE:
      return {
        ...state,
        experianCreditCheckRequest: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_CREDIT_REQUEST_PENDING:
      return {
        ...state,
        creditRequest: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_CREDIT_REQUEST_SUCCESS:
      return {
        ...state,
        creditRequest: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_CREDIT_REQUEST_FAILURE:
      return {
        ...state,
        creditRequest: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_CREDIT_REQUESTS_PENDING:
      return {
        ...state,
        creditRequests: {
          loading: true,
          data: [],
          filters: [],
          error: null,
        },
      };

    case types.GET_CREDIT_REQUESTS_SUCCESS:
      return {
        ...state,
        creditRequests: {
          loading: false,
          data: action.payload.data,
          filters: action.payload.filters,
          error: null,
        },
      };

    case types.GET_CREDIT_REQUESTS_FAILURE:
      return {
        ...state,
        creditRequests: {
          loading: false,
          data: [],
          filters: [],
          error: action.payload.error,
        },
      };

    case types.DECLINE_CREDIT_REQUEST_PENDING:
    case types.DISTRIBUTE_CREDIT_REQUEST_PENDING:
      return {
        ...state,
        creditRequests: {
          ...state.creditRequests,
          loading: true,
        },
      };

    case types.DISTRIBUTE_CREDIT_REQUEST_SUCCESS:
    case types.DECLINE_CREDIT_REQUEST_SUCCESS:
      return {
        ...state,
        creditRequests: {
          ...state.creditRequests,
          loading: false,
          data: state.creditRequests.data.map(request =>
            request.id === action.payload.data.id
              ? { ...request, ...action.payload.data }
              : request
          ),
          filters: action.payload.filters,
        },
      };

    case types.DECLINE_CREDIT_REQUEST_FAILURE:
    case types.DISTRIBUTE_CREDIT_REQUEST_FAILURE:
      return {
        ...state,
        creditRequests: {
          ...state.creditRequests,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_CURRENT_TASKS_PENDING:
      return {
        ...state,
        currentTasks: {
          ...state.currentTasks,
          loading: true,
          data: [
            ...state.currentTasks.data,
          ],
          filters: [],
          error: null,
        },
      };

    case types.GET_CURRENT_TASKS_SUCCESS:
      return {
        ...state,
        currentTasks: {
          loading: false,
          data: action.payload.data,
          filters: action.payload.filters,
          error: null,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_CURRENT_TASKS_FAILURE:
      return {
        ...state,
        currentTasks: {
          ...state.currentTasks,
          loading: false,
          data: [],
          filters: [],
          error: action.payload.error,
        },
      };

    case types.GET_ALL_CREDIT_REVIEW_DATES_PENDING:
      return {
        ...state,
        creditReviewDates: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_ALL_CREDIT_REVIEW_DATES_SUCCESS:
      return {
        ...state,
        creditReviewDates: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_ALL_CREDIT_REVIEW_DATES_FAILURE:
      return {
        ...state,
        creditReviewDates: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_CONTACTS_PENDING:
      return {
        ...state,
        agencyContacts: {
          loading: true,
          data: [],
          creditController: null,
          error: null,
        },
      };

    case types.GET_AGENCY_CONTACTS_SUCCESS:
      return {
        ...state,
        agencyContacts: {
          loading: false,
          data: action.payload.data,
          creditController: action.payload.creditController,
          error: null,
        },
      };

    case types.GET_AGENCY_CONTACTS_FAILURE:
      return {
        ...state,
        agencyContacts: {
          loading: false,
          data: [],
          creditController: null,
          error: action.payload.error,
        },
      };

    case types.CREATE_CREDIT_REVIEW_DATE_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.CREATE_CREDIT_REVIEW_DATE_SUCCESS:
      return {
        ...state,
        creditOverview: {
          ...state.creditOverview,
          data: {
            ...state.creditOverview.data,
            latest_credit_review_date: action.payload.data,
          },
        },
        ui: {
          submitting: false,
        },
      };

    case types.CREATE_CREDIT_REVIEW_DATE_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.CHASE_CREDIT_APPLICATION_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.CHASE_CREDIT_APPLICATION_SUCCESS:
      return {
        ...state,
        creditApplications: {
          ...state.creditApplications,
          data: state.creditApplications.data
            .map((creditApplication) => {
              if (creditApplication.id === action.payload.data.credit_application.id) {
                (creditApplication.chases || []).unshift(action.payload.data);
              }
              return creditApplication;
            }),
        },
        ui: {
          submitting: false,
        },
      };

    case types.CHASE_CREDIT_APPLICATION_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_PENDING:
      return {
        ...state,
        experianCreditCheckDocuments: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_SUCCESS:
      return {
        ...state,
        experianCreditCheckDocuments: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_FAILURE:
      return {
        ...state,
        experianCreditCheckDocuments: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_ACTIVE_CREDIT_APPLICATIONS_PENDING:
      return {
        ...state,
        activeCreditApplications: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_ACTIVE_CREDIT_APPLICATIONS_SUCCESS:
      return {
        ...state,
        activeCreditApplications: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_ACTIVE_CREDIT_APPLICATIONS_FAILURE:
      return {
        ...state,
        activeCreditApplications: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
