import React, { PureComponent } from "react";
import styles from "./PermissionDenied.module.scss";

export default class PermissionDenied extends PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <p className={styles.header}>Permission Denied</p>
        <p className={styles.subHeader}>You do not have the permission(s) required to view this page</p>
      </div>
    );
  }
}
