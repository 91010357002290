import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const VERIFY_AGENCY_CUSTOMER = gql`
  mutation($id: ID!, $input: VerifyNonRegisteredAgencyCustomerInput) {
    verifyNonRegisteredAgencyCustomer(id: $id, input: $input) {
      id
      legal_name
      correspondence_name
      sole_trader_name
      address_line_1
      address_line_2
      town_or_city
      county
      postcode
      registered_charity_number
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 * @param {string} obj.legal_name
 * @param {string} obj.correspondence_name
 * @param {string} obj.sole_trader_name
 * @param {string} obj.address_line_1
 * @param {string} obj.address_line_2
 * @param {string} obj.town_or_city
 * @param {string} obj.county
 * @param {string} obj.postcode
 * @param {string} obj.registered_charity_number
 * @param {string} obj.verified_by
 * @returns
 */
export const verifyNonRegisteredAgencyCustomer = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_PENDING,
    });

    const response = await apolloClient.query({
      query: VERIFY_AGENCY_CUSTOMER,
      variables: {
        id: obj.agencyCustomerId,
        input: {
          legal_name: obj.legal_name,
          correspondence_name: obj.correspondence_name,
          sole_trader_name: obj.sole_trader_name,
          address_line_1: obj.address_line_1,
          address_line_2: obj.address_line_2,
          town_or_city: obj.town_or_city,
          county: obj.county,
          postcode: obj.postcode,
          registered_charity_number: obj.registered_charity_number,
          verified_by: obj.verified_by,
        },
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_SUCCESS,
      payload: {
        data: response.data.verifyNonRegisteredAgencyCustomer,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
