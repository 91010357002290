import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Close } from "@material-ui/icons";
import styles from "./Alert.module.scss";

export default function Alert(props) {
  const [visible, setVisible] = useState(props.visible);

  const getVariantStyle = () => {
    switch ((props.variant || "").toLowerCase()) {
      case "error":
        return styles.Error;

      case "info":
        return styles.Info;

      case "success":
        return styles.Success;

      case "warning":
        return styles.Warning;

      default:
        return "";
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.Alert,
        getVariantStyle(),
        props.float && styles.Float,
        props.className
      )}
      style={props.style}
    >
      {props.text && (
        <p className={styles.Text}>
          {props.text}
        </p>
      )}
      {props.children && props.children}
      {props.showClose && (
        <Close
          className={styles.Icon}
          onClick={() => setVisible(false)}
        />
      )}
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
  float: PropTypes.bool,
  style: PropTypes.object,
  visible: PropTypes.bool,
  showClose: PropTypes.bool,
};

Alert.defaultProps = {
  visible: true,
  showClose: true,
};
