import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { EditProspectPage, ViewProspectPage } from "../";

export default class ProspectPage extends Component {

  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/edit`} component={EditProspectPage} />
        <Route path={`${match.path}`} component={ViewProspectPage} />
      </Switch>
    );
  }

}
