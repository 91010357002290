/**
 *
 * @param {string} value
 * @param {object[]} validOptions
 * @param {string} validOptions[].label
 * @param {string} validOptions[].value
 * @returns {{ label: string, value: string }}
 */
const tryGetOption = (value, validOptions) => {
  const option = validOptions.find(x => x.value === value);

  return option === undefined ? null : option;
};

export default tryGetOption;
