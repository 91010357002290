import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Form, TextLink } from "../../../common/components";
import styles from "./SignInForm.module.scss";

class SignInForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    submitting: PropTypes.bool,
    onSignInClick: PropTypes.func.isRequired,
    onForgotPasswordClick: PropTypes.func.isRequired,
  }

  state = {
    email: "",
    password: "",
  };

  render() {
    const { className, submitting } = this.props;
    const { email, password } = this.state;
    const canSignIn = email && password;

    return (
      <Form className={classnames(className, styles.container)}>
        <Form.Group>
          <Form.Label htmlFor="email">
            Email Address
          </Form.Label>
          <Form.Input
            type="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={this.handleInputChange}
            tabIndex={1}
            fluid
          />
        </Form.Group>
        <Form.Group>
          <Form.Label
            className={styles.passwordLabel}
            htmlFor="password"
          >
            Password
          </Form.Label>
          <TextLink
            className={styles.forgotPasswordLink}
            onClick={this.handleForgotPasswordClicked}
            text="Forgot Password"
            tabIndex={4}
          />
          <Form.Input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={this.handleInputChange}
            tabIndex={2}
            fluid
          />
        </Form.Group>
        <Button
          className={styles.submitButton}
          type="submit"
          onClick={this.handleSignInClicked}
          busy={submitting}
          disabled={submitting || !canSignIn}
          tabIndex={3}
        >
          Sign In
        </Button>
      </Form>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleForgotPasswordClicked = () => {
    const { onForgotPasswordClick } = this.props;

    onForgotPasswordClick();
  }

  handleSignInClicked = () => {
    const { onSignInClick } = this.props;
    const { email, password } = this.state;

    onSignInClick({ email, password });
  }
}

export default SignInForm;
