import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { parseDate, parseTime } from "../../../../utils";
import styles from "./Comment.module.scss";

export default class Comment extends PureComponent {
  static propTypes = {
    author: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
    type: PropTypes.oneOf(["success", "error"]),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    commentRef: PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
    className: PropTypes.string,
  };

  render() {
    const { author, text, time, type, tags, commentRef, className } = this.props;

    return (
      <div className={classnames(styles.comment, className, styles[type])}>
        {tags &&
          <div className={styles.tagContainer}>
            {tags.map(tag => (
              <div key={tag.id} className={styles.tags}>
                {tag.label}
              </div>
            ))}
          </div>
        }
        <div className={styles.commentContent} ref={commentRef}>
          <div className={styles.infoHeader}>
            <div className={styles.author}>{author}</div>
            <div className={styles.time}>
              {parseDate(time)} - {parseTime(time)}
            </div>
          </div>
          <div className={styles.text}>
            {text.split("\n").map((str, i) => <div key={i} className={styles.textLine}>{str}</div>)}
          </div>
        </div>
      </div>
    );
  }
}
