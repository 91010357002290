import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Pill.module.scss";

export default function Pill({
  className,
  variant,
  children,
}) {

  return (
    <div className={classnames(styles.container, styles[variant], className)}>
      {children}
    </div>
  );
}

Pill.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["default", "warning", "danger", "noir", "compact"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Pill.defaultProps = {
  variant: "default",
};
