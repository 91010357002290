import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";

export default class PerformCreditCheckResultModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.bool.isRequired,
    submitText: PropTypes.string,
    submitDisabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  render() {
    const { onSubmit, onCancel, submitDisabled, submitText, subtitle, title, visible } = this.props;

    return (
      <ConfirmationModal
        title={title}
        visible={visible}
        primaryButtonDisabled={submitDisabled}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitText={submitText}>
        <div>
          {subtitle}
        </div>
      </ConfirmationModal>
    );
  }
}
