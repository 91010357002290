import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import EditPayrollGeneralPage from "./EditPayrollGeneralPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchPayrollGeneral: actions.getPayrollGeneral,
  }, dispatch),
  onCancel: (prospectId) => {
    dispatch(push(`/prospects/${prospectId}/payroll`));
  },
  onSubmit: ({ prospectId, data }) => {
    dispatch(actions.updatePayrollGeneral({
      prospectId,
      data,
      redirectUrl: `/prospects/${prospectId}/payroll`,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPayrollGeneralPage);
