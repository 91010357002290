export const nextTransition = (name, state) => {
  switch (state[name]) {
    case "NONE":
      return "ASC";

    case "ASC":
      return "DESC";

    default:
      return "NONE";
  }
};
