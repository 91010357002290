import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import ContactsTab from "./ContactsTab";

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  onFetchAgencyDisabledFields: actions.getAgencyDisabledFields,
  onFetchBackOfficeContacts: actions.getBackOfficeContacts,
  onFetchAgencyContacts: actions.getAgencyContacts,
  onAddAgencyContact: actions.addAgencyContact,
  onUpdateAgencyContact: actions.updateAgencyContact,
  onRemoveAgencyContact: actions.deleteAgencyContact,
  onArchiveAgencyContact: actions.archiveAgencyContact,
  onSaveBackOfficeContacts: actions.updateBackOfficeContacts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactsTab);
