import _ from "lodash";

export default class AgencyContractInformationFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data } = this;
    _.merge(data, data?.length_of_contract);
    let errors = {};

    if (!this._isFieldDisabled("contract_start_date")) {
      if (!data?.contract_start_date) {
        errors = _.set(errors, "contract_start_date", "Contract Start Date is required");
      }

      if (_.isEmpty(data?.contract_period) || !data?.contract_length) {
        errors = _.set(errors, "length_of_contract", "Contract Length is required");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  _isFieldDisabled = (fieldName) => {
    return this.props?.disabledFields?.includes(fieldName);
  }
}
