import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import { RequestHistoryList } from "../../components";
import styles from "./RequestHistoryTab.module.scss";
import { RequestHistoryFilter } from "../../containers";

export default class RequestHistoryTab extends Component {

  constructor(props) {
    super(props);

    this.onFiltersChange = this.onFiltersChange.bind(this);
  }

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    initialFilters: PropTypes.object,
    creditRequests: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
    }).isRequired,
    getCreditRequests: PropTypes.func.isRequired,
    showDeclineCreditRequestModal: PropTypes.func.isRequired,
    showDistributeCreditRequestModal: PropTypes.func.isRequired,
    showCommunicateToAgencyModal: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, getCreditRequests, initialFilters } = this.props;
    await getCreditRequests({
      legalEntityId,
      filters: initialFilters,
    });
  }

  async onFiltersChange(filters) {
    const { legalEntityId, getCreditRequests } = this.props;

    await getCreditRequests({
      legalEntityId,
      filters,
    });
  }

  render() {
    return (
      <Grid className={styles.container}>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Header>Request History</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <RequestHistoryFilter
              legalEntityId={this.props.legalEntityId}
              initialFilters={this.props.initialFilters}
              onFiltersChange={this.onFiltersChange}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <RequestHistoryList
              loading={this.props.creditRequests?.loading}
              data={this.props.creditRequests?.data}
              legalEntityId= {this.props.legalEntityId}
              handleCommunicateToAgencyRequest={this.props.showCommunicateToAgencyModal}
              handleDeclineCreditRequest={this.props.showDeclineCreditRequestModal}
              handleDistributeCreditRequest={this.props.showDistributeCreditRequestModal}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

}
