import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_CREDIT_APPLICATION_FOR_APPROVAL = gql`
  query(
    $legal_entity_id: ID!
    $credit_application_id: ID!
  ) {
    getLegalEntity(id: $legal_entity_id) {
      credit_application(id: $credit_application_id) {
        id
        created_by {
          first_name
          last_name
        }
        credit_limit_requested
        status
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.creditApplicationId
 * @returns
 */
export const getCreditApplicationForApproval = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CREDIT_APPLICATION_FOR_APPROVAL_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_CREDIT_APPLICATION_FOR_APPROVAL,
      variables: {
        legal_entity_id: obj.legalEntityId,
        credit_application_id: obj.creditApplicationId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    if (!response.data.getLegalEntity.credit_application) {
      throw new Error("Credit Application not found");
    }

    dispatch({
      type: types.GET_CREDIT_APPLICATION_FOR_APPROVAL_SUCCESS,
      payload: {
        data: response.data.getLegalEntity.credit_application,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_APPLICATION_FOR_APPROVAL_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
