import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./Bubble.module.scss";

export default class Bubble extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };
  render() {
    return (
      <div className={`${styles.bubble} ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}
