import React from "react";
import PropTypes from "prop-types";
import { CompaniesHouseSearchResultList } from "../";
import { ConfirmationModal, Pager } from "../../../common/components";
import styles from "./CompaniesHouseSearchResultsModal.module.scss";

export default function CompaniesHouseSearchResultsModal(props) {
  const renderResults = (name, searchText, results) => {
    if (results?.total > 0) {
      return (
        <>
          <div className={styles.resultsHeader}>
            {results.total} Result{results.total === 1 ? "" : "s"} for {name} {`"${searchText}"`}
          </div>
          <CompaniesHouseSearchResultList
            data={results.data || []}
            existingLegalEntities={results.existing_legal_entities || []}
            hideExistingWarning
            onItemClick={props.onItemClick}
          />
        </>
      );
    }

    return (
      <div className={styles.resultsHeader}>
        No Results for {name} {`"${searchText}"`}
      </div>
    );
  };

  const getPageCount = () => {
    if (props.results?.searchByName?.total > 0) {
      const pageCount = Math.max(0, props.results?.searchByName?.total / 3);

      // 1000 results is the max available from CH API.
      return pageCount > 1000 / 3
        ? Math.floor(1000 / 3)
        : Math.floor(pageCount);
    }

    return 0;
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Companies House"
      cancelText="Cancel"
      visible={props.visible}
      onCancel={props.onCancel}
    >
      <div className={props.loading && styles.loadingOverlay}>
        {renderResults("Registered Company Number", props.registeredCompanyNumber || "", props.results?.searchByNumber)}
        {renderResults("Exact Legal Name", props.exactLegalName || "", props.results?.searchByName)}
      </div>
      <div className={styles.pagination}>
        {props.results?.searchByName?.total > 0 && <Pager
          pageCount={getPageCount()}
          selectedPage={props.page}
          onPageChanged={props.onPageChanged}
        />}
      </div>
    </ConfirmationModal>
  );
}

CompaniesHouseSearchResultsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  registeredCompanyNumber: PropTypes.string,
  exactLegalName: PropTypes.string,
  results: PropTypes.shape({
    searchByNumber: PropTypes.shape({
      total: PropTypes.number.isRequired,
      data: PropTypes.array,
      existing_legal_entities: PropTypes.array,
    }),
    searchByName: PropTypes.shape({
      total: PropTypes.number.isRequired,
      data: PropTypes.array,
      existing_legal_entities: PropTypes.array,
    }),
  }),
  page: PropTypes.number,
  limit: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onPageChanged: PropTypes.func.isRequired,
};
