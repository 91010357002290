import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { CompanyDetailsForm, NonRegisteredCompanyDetailsForm } from "../../components";
import styles from "./EditCompanyDetailsPage.module.scss";

export default class EditCompanyDetailsPage extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    companyDetails: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
    }).isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool,
    }).isRequired,
    getCompanyDetails: PropTypes.func.isRequired,
    updateRegisteredCompany: PropTypes.func.isRequired,
    updateNonRegisteredCompany: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, getCompanyDetails } = this.props;

    await getCompanyDetails({ legalEntityId });
  }

  render() {
    if (!this.props.companyDetails.data || this.props.companyDetails.loading ) {
      return (
        <div className={styles.container}>
          <ActivityIndicator />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        {this.props.companyDetails.data?.is_registered_company? (
          <CompanyDetailsForm
            data={this.props.companyDetails.data}
            submitting={this.props.ui.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleRegisteredSubmit}
          />
        ) : (
          <NonRegisteredCompanyDetailsForm
            data={this.props.companyDetails.data}
            submitting={this.props.ui.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleNonRegisteredSubmit}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { legalEntityId, onCancel } = this.props;

    await onCancel(legalEntityId);
  }

  handleRegisteredSubmit = async (data) => {
    const { legalEntityId, updateRegisteredCompany } = this.props;

    await updateRegisteredCompany({
      legalEntityId,
      isRegisteredCharity: data.is_registered_charity,
      registeredCharityNumber: data.registered_charity_number,
      redirectUrl: `/credit-limit-management/legal-entities/${legalEntityId}/details`,
    });
  }

  handleNonRegisteredSubmit = async (data) => {
    const { legalEntityId, updateNonRegisteredCompany } = this.props;

    await updateNonRegisteredCompany({
      legalEntityId,
      ...data,
      redirectUrl: `/credit-limit-management/legal-entities/${legalEntityId}/details`,
    });
  }
}
