import gql from "graphql-tag";
import * as types from "../types";
import { showChangePasswordSuccessModal } from "./showChangePasswordSuccessModal";
import { hideModal } from "../../../modals/duck/actions/hideModal";
import { ChangePasswordModal } from "../../containers";
import { apolloClient } from "../../../../apolloClient";

export const CHANGE_PASSWORD = gql`
mutation($input: ChangePasswordRequest!) {
  changePassword(input: $input) {
    success
    message
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.currentPassword
 * @param {string} obj.newPassword
 * @returns
 */
export const changePassword = ({
  currentPassword,
  newPassword,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.CHANGE_PASSWORD_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: CHANGE_PASSWORD,
      variables: {
        input: {
          current_password: currentPassword,
          new_password: newPassword,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    if (!response.data.changePassword.success) {
      throw new Error(response.data.changePassword.message);
    }

    dispatch({
      type: types.CHANGE_PASSWORD_SUCCESS,
    });

    dispatch(showChangePasswordSuccessModal());
    dispatch(hideModal(ChangePasswordModal.displayName));
  }
  catch (err) {
    dispatch({
      type: types.CHANGE_PASSWORD_FAILURE,
      payload: {
        error: String(err),
      },
    });
  }
};
