import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_CUSTOMER = gql`
  query($id: ID!) {
    getAgencyCustomer(id: $id) {
      id
      agency {
        id
        known_as
      }
      prospect {
        id
        known_as
      }
      customer {
        id
        legal_name
        registered_company_number
        registered_charity_number
        sole_trader_name
        address_line_1
        address_line_2
        town_or_city
        county
        postcode
      }
      display_name
      legal_name
      company_type
      registered_company_number
      sole_trader_name
      is_registered_charity
      registered_charity_number
      credit_limit_requested
      address_line_1
      address_line_2
      town_or_city
      county
      postcode

      contracts {
        id
        display_name
        account_contact {
          first_name
          last_name
          telephone_number
        }
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 * @param {string} obj.redirectOnFailureUrl
 * @returns
 */
export const getAgencyCustomer = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_CUSTOMER_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_CUSTOMER,
      variables: {
        id: obj.agencyCustomerId,
      },
    });

    if (response.errors) {
      if (obj.redirectOnFailureUrl) {
        dispatch(push(obj.redirectOnFailureUrl));
      }
      throw new Error(response.errors[0].message);
    }

    dispatch({
      type: types.GET_AGENCY_CUSTOMER_SUCCESS,
      payload: {
        data: response.data.getAgencyCustomer,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_CUSTOMER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
