import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_BRANCH = gql`
mutation(
  $agency_id: ID!
  $input: UpdateAgencyBranchInput!
) {
  updateAgencyBranch(agency_id: $agency_id, input: $input) {
    id
  }
}
`;

export const updateBranch = (agencyId, branchId, data) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_BRANCH_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_BRANCH,
      variables: {
        agency_id: agencyId,
        input: {
          id: branchId,
          address_line_one: data.address_line_one,
          address_line_two: data.address_line_two,
          town_or_city: data.town_or_city,
          county: data.county,
          postcode: data.postcode,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_BRANCH_SUCCESS,
      payload: {
        branch: response.data.updateBranch,
      },
    });

    dispatch(push(`/agencies/${agencyId}/agency`));
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_BRANCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
