import PropTypes from "prop-types";

export default function ConditionalWrapper({
  condition,
  wrapper,
  children,
}) {

  return (
    condition ? wrapper(children) : children
  );
}

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
