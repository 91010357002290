import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY = gql`
query($id: ID!) {
  getAgency(id:$id) {
    id
    short_code
    known_as
    exact_legal_name
    company_number
    vat_number
    status
    can_remove_worker_types

    officers {
      items {
        name
        officer_role
      }
    }

    banking {
      agency_id
      sort_code
      account_number
      bank_name
      branch
      payee_name
    }

    logo_url
    trading_as
    website
    linked_in
    twitter
    is_new_agency
    is_existing_paye_scheme
    introduced_via_broker
    opera_version
    opera_company

    agency_status {
      id
      label
      value
    }

    preferences {
      payroll_frequency
    }

    worker_types {
      id
      name
    }

    tempest_mappings {
      tempest_id
    }
  }
}
`;

export const getAgency = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY,
      variables: {
        id,
      },
    });

    dispatch({
      type: types.GET_AGENCY_SUCCESS,
      payload: {
        agency: response.data.getAgency,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
