import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { AgencyDetailsForm } from "../../../components";
import styles from "./AgencyDetails.module.scss";

export default class AgencyDetails extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }),
    agencyDetails: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object.isRequired,
    }).isRequired,
    companyHistory: PropTypes.shape({
      trading_as: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    agencyDisabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    getAgencyDetails: PropTypes.func.isRequired,
    updateAgencyDetails: PropTypes.func.isRequired,
    getCompanyHistory: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    const { agencyId, getCompanyHistory, getAgencyDetails, getAgencyDisabledFields } = this.props;

    await Promise.all([
      getAgencyDetails(agencyId),
      getCompanyHistory(agencyId),
      getAgencyDisabledFields(
        agencyId,
        [
          "known_as",
          "trading_as",
          "website",
          "linked_in",
          "twitter",
          "industry_sectors",
        ]
      ),
    ]);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderAgencyDetailsSection()}
      </div>
    );
  }

  renderAgencyDetailsSection = () => {
    const {
      agencyDisabledFields,
      agencyDetails: {
        data,
        loading,
      },
      ui: {
        submitting,
      },
    } = this.props;

    if (loading) {
      return <ActivityIndicator />;
    }

    return (
      <AgencyDetailsForm
        agencyDetails={data}
        disabledFields={agencyDisabledFields?.data?.map(field => (field.disabled_field))}
        onDisabledFieldClick={this.handleDisabledFieldClicked}
        history={this.getAgencyDetailsHistory()}
        onSave={this.handleSaveClicked}
        onCancel={this.handleCancelClicked}
        submitting={submitting}
      />
    );
  }

  handleDisabledFieldClicked = (field) => {
    const { agencyDisabledFields, agencyId, history } = this.props;

    const agencyDisabledField = agencyDisabledFields.data.find(item => item.disabled_field === field);

    history.push(`/onboarding/agencies/${agencyId}/${agencyDisabledField.agency_onboarding_task_id}`);
  }

  handleCancelClicked = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/agency`);
  }

  handleSaveClicked = async (data) => {
    const { agencyId, updateAgencyDetails } = this.props;

    const agencyData = {
      agencyDetails: {
        known_as: data.known_as,
        correspondence_name: data.correspondence_name,
        trading_as: data.trading_as,
        website: data.website,
        linked_in: data.linked_in,
        twitter: data.twitter,
        agency_status_id: +data.agency_status?.id,
        previous_provider_id: +data.previous_provider?.id,
      },
      industrySectors: data.industry_sectors,
    };

    await updateAgencyDetails(agencyId, agencyData);
  }

  getAgencyDetailsHistory = () => {
    return {
      trading_as: this.props.companyHistory.trading_as,
    };
  }
}
