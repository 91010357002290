import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./TableColumn.scss";

export default class TableColumn extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    collapsed: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    name: PropTypes.string.isRequired,
    value: PropTypes.func.isRequired,
  }

  render() {
    const { className, children } = this.props;

    return (
      <div
        className={classnames("TableColumn", className)}
      >
        {children}
      </div>
    );
  }

}
