import { connect } from "react-redux";
import { push } from "connected-react-router";
import SystemLandingPage from "./SystemLandingPage";

const mapStateToProps = null;
const mapDispatchToProps = dispatch => ({
  onGoToDataExport: () => {
    dispatch(push("/system/data-export"));
  },
  onGoToDataImport: () => {
    dispatch(push("/system/data-import"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemLandingPage);
