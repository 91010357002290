import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_AGENCY_CONTACTS = gql`
query(
  $agency_id: ID!
  $sort_order: [SortOrder]
) {
  getAgency(id: $agency_id) {
    id

    contacts(sort_order: $sort_order) {
      id
      first_name
      last_name
      preferred_name
      email_address
    }

    credit_controller {
      id
      first_name
      last_name
    }
  }
}
`;


const GET_PROSPECT_CONTACTS = gql`
query(
  $prospect_id: ID!
  $sort_order: [SortOrder]
) {
  getProspect(id: $prospect_id) {
    id

    contacts(sort_order: $sort_order) {
      id
      first_name
      last_name
      preferred_name
      email_address
    }

    credit_controller {
      id
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @param {boolean} obj.isProspect
 * @returns
 */
export const getAgencyContacts = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_CONTACTS_PENDING,
    });


    const response = await apolloClient.query({
      query: obj.isProspect ? GET_PROSPECT_CONTACTS : GET_AGENCY_CONTACTS,
      variables: {
        [obj.isProspect ? "prospect_id" : "agency_id"]: obj.agencyId,
        sort_order: SortOrderConverter({
          first_name: "asc",
        }),
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const agency = obj.isProspect
      ? response.data.getProspect
      : response.data.getAgency;


    // Add "email" property to support old code
    agency.contacts.forEach((contact) => {
      contact.email = contact.email_address;
    });

    dispatch({
      type: types.GET_AGENCY_CONTACTS_SUCCESS,
      payload: {
        data: agency.contacts,
        creditController: agency.credit_controller,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_CONTACTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
