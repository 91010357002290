import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { TaskList } from "../../components";
import { TaskListFilter } from "../../containers";
import { Form, TextLink } from "../../../common/components";
import { parseDate, parseDateToNaturalLanguage, sorting } from "../../../../utils";
import { ItemCountSelector, Pager, SearchInput } from "../../../common/components";
import { DEFAULT_ITEMS_PER_PAGE } from "../../../../config";
import styles from "./CurrentTasksTab.module.scss";

export default class CurrentTasksTab extends Component {

  static propTypes = {
    currentTasks: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
      pagination: PropTypes.shape({
        pages: PropTypes.number.isRequired,
      }),
    }).isRequired,
    getCurrentTasks: PropTypes.func.isRequired,
    handleCreditLimitManagementTask: PropTypes.func.isRequired,
  }

  state = {
    page: 1,
    limit: DEFAULT_ITEMS_PER_PAGE,
    upToDate: new Date(),
    searchText: "",
    sortOrder: {
      taskName: "NONE",
      agency: "NONE",
      legalEntity: "NONE",
      urgent: "DESC",
      dueDate: "ASC",
    },
  }

  async componentDidMount() {
    await this.fetchTasks();
  }

  render() {
    return (
      <Grid className={styles.container}>
        <Grid.Row>
          <Grid.Column>
            <SearchInput
              name="searchText"
              className={styles.searchInput}
              value={this.state.searchText}
              placeholder="Search..."
              onChange={e => this.setState({ searchText: e.target.value })}
              onSearch={this.searchTasks}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.filterRow}>
          <Grid.Column className={styles.filterColumn}>
            <div className={styles.dateSelection}>
              <Form.Date
                name="upToDate"
                size="small"
                value={this.state.upToDate}
                onChange={this.handleNewDateSelected}
                min={parseDate(Date.now(), "YYYY-MM-DD")}
                useIcon
              />
              <div className={styles.dateChosen}>
                Showing up to <strong>
                  {parseDateToNaturalLanguage(this.state.upToDate)}
                </strong>
              </div>
              {!this.isTodaysDate(this.state.upToDate) && (
                <TextLink
                  className={styles.resetDate}
                  text="Back to today"
                  onClick={this.resetDate}
                />
              )}
            </div>
          </Grid.Column>
          <Grid.Column className={styles.filterColumn}>
            <TaskListFilter onFiltersChange={this.onFiltersChange}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <TaskList
              loading={this.props.currentTasks.loading}
              data={this.props.currentTasks.data}
              sortOrder={this.state.sortOrder}
              onTaskClick={this.handleTaskClick}
              onColumnClick={this.handleColumnClicked}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left" verticalAlign="middle">
            <Pager
              pageCount={this.props.currentTasks.pagination?.pages}
              selectedPage={this.state.page}
              onPageChanged={this.handlePageChanged}
            />
          </Grid.Column>
          <Grid.Column textAlign="right" verticalAlign="middle">
            <ItemCountSelector
              value={this.state.limit}
              onChange={this.handleItemCountChanged}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  handleTaskClick = (task) => {
    this.props.handleCreditLimitManagementTask({
      creditLimitManagementTaskId: task.id,
    });
  }

  handleColumnClicked = (name, order) => {
    const newSortOrder = { ...this.state.sortOrder };

    newSortOrder[name] = sorting.nextTransition(name, { [name]: order });

    this.setState({
      sortOrder: newSortOrder,
    }, this.fetchTasks);
  }

  handlePageChanged = (n) => {
    this.setState({
      page: n,
    }, this.fetchTasks);
  }

  handleItemCountChanged = (e) => {
    this.setState({
      page: 1,
      limit: e.value,
    }, this.fetchTasks);
  }

  handleNewDateSelected = (e) => {
    const newDate = e.target.value;
    const currentDate = new Date().setUTCHours(0, 0, 0, 0);

    if (newDate < currentDate) {
      return;
    }

    this.setState({
      upToDate: newDate,
    }, this.fetchTasks);
  }

  resetDate = () => {
    this.setState({
      upToDate: new Date(),
    }, this.fetchTasks);
  }

  searchTasks = () => {
    // Reset pagination before the search
    this.setState({
      page: 1,
    }, this.fetchTasks);
  }

  fetchTasks = async () => {
    const { limit, page, searchText, upToDate, sortOrder } = this.state;

    await this.props.getCurrentTasks({
      upToDate,
      pager: {
        page,
        limit,
      },
      searchText,
      sortOrder,
    });
  }

  onFiltersChange = async (filters) => {
    const { limit, page, searchText, upToDate, sortOrder } = this.state;

    await this.props.getCurrentTasks({
      upToDate,
      pager: {
        page,
        limit,
      },
      searchText,
      sortOrder,
      filters,
    });
  }

  isTodaysDate = (date) => {
    return new Date(date).setUTCHours(0,0,0,0) === new Date().setUTCHours(0,0,0,0);
  }
}
