import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_DATA_MISMATCHES = gql`
query($pager: Pager!) {
  getDataMismatches(pager: $pager) {
    total

    data {
      agency {
        id
        known_as
      }
      mismatches {
        display_name
        source_value
        target_value
        created_at
      }
    }
  }
}
`;

export const getDataMismatches = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_DATA_MISMATCHES_PENDING,
    });

    const pager = {
      page: 1,
      limit: 1000,
    };
    const response = await apolloClient.query({
      query: GET_DATA_MISMATCHES,
      variables: {
        pager,
      },
    });

    dispatch({
      type: types.GET_DATA_MISMATCHES_SUCCESS,
      payload: response.data.getDataMismatches,
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_DATA_MISMATCHES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
