import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import styles from "./EventLogEvent.module.scss";

export default function EventLogEvent(props) {

  return (
    <Table.Row>
      <Table.Cell className={styles.cell}>
        <div className={styles.iconContainer}>
          {props.icon}
        </div>
        <div className={styles.eventContent}>
          <div className={styles.eventTitle}>
            {props.title}
          </div>
          <div className={styles.eventDetails}>
            <div className={styles.eventUser}>
              {props.username}
            </div>
            <div className={styles.eventDate}>
              {props.date}
            </div>
            {props.body && (
              <div>
                {props.body}
              </div>
            )}
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

EventLogEvent.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  username: PropTypes.string,
  date: PropTypes.string.isRequired,
  body: PropTypes.object,
};
