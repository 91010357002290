import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "semantic-ui-react";
import BranchListViewItem from "./BranchListViewItem/BranchListViewItem";
import styles from "./BranchListView.module.scss";

export default function BranchListView(props) {
  return (
    <div className={styles.container}>
      <Accordion>
        <div className={styles.grid}>
          {props.children}
        </div>
      </Accordion>
    </div>
  );
}

BranchListView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

BranchListView.Item = BranchListViewItem;
