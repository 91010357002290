export const PAYROLL_FREQUENCY_OPTIONS = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "both", label: "Both" },
];

export const DEFAULT_PAYSLIP_PREFERENCE_OPTIONS = [
  { value: "email", label: "Email" },
  { value: "post", label: "Post" },
  { value: "both", label: "Both" },
];

export const DEFAULT_CUSTOMER_VAT_OPTIONS = [
  { value: "standard", label: "Standard" },
  { value: "exempt", label: "Exempt (0%)" },
  { value: "zero_rated", label: "Zero Rated (0%)" },
];

export const VAT_FREQUENCY_OPTIONS = [
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "annually", label: "Annually" },
];

export const CREDIT_APPLICATION_SOURCE_OPTIONS = [
  { value: "insurer", label: "Insurer" },
  { value: "broker", label: "Broker" },
  { value: "back_office", label: "Back Office" },
];

export const CREDIT_APPLICATION_STATUS_OPTIONS = [
  { value: "accepted", label: "Fully Approved" },
  { value: "partially_accepted", label: "Part Approved" },
  { value: "declined", label: "Declined" },
  { value: "cancelled", label: "Cancelled" },
  { value: "company_not_found", label: "Company Not Found" },
  { value: "pending", label: "Awaiting Result" },
  { value: "decrease", label: "Reduced" },
  { value: "removal", label: "Removed" },
  { value: "housekeeping", label: "Housekept" },
];

export const BROKER_CREDIT_APPLICATION_STATUS_OPTIONS = [
  { value: "accepted", label: "Go to Insurer" },
  { value: "declined", label: "Use Discretionary Limit" },
  { value: "cancelled", label: "Cancelled" },
  { value: "pending", label: "Awaiting Result" },
];

export const REQUEST_HISTORY_FILTER_FIELD_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "fully_approved", label: "Fully Approved" },
  { value: "partially_approved", label: "Part Approved" },
  { value: "declined", label: "Declined" },
];

export const CUSTOMER_DETAILS_CUSTOMER_TYPES = [
  { value: "limited_company", label: "Limited Company" },
  { value: "limited_liability_partnership", label: "LLP" },
  { value: "partnership", label: "Partnership" },
  { value: "sole_trader", label: "Sole Trader" },
  { value: "government", label: "Government" },
  { value: "international", label: "International" },
];

export const AGREED_PAYMENT_TERMS_OPTIONS = [
  { value: "days_after_invoice_date", label: "Days after Invoice Date" },
  { value: "day_of_month_following_invoice", label: "Day of month following invoice" },
];

export const WORKER_DECLARATION_OPTIONS = [
  { value: "hmrc_declaration_a", letter: "A", label: "This is my first job since last 6 April and I have not been receiving taxable Jobseeker's Allowance, Employment and Support Allowance, or taxable Incapacity Benefit, State or Occupational Pension." },
  { value: "hmrc_declaration_b", letter: "B", label: "This is now my only job but since 6 April I have had another job, or received taxable Jobseeker's Allowance, Employment and Support Allowance, or taxable Incapacity Benefit. I do not receive a State or or Occupational Pension." },
  { value: "hmrc_declaration_c", letter: "C", label: "As well as my new job, I have another job or receive a State or Occupational Pension." },
];

export const WORKER_INTERMEDIARY_TYPES = [
  { value: "self_employed", label: "Self Employed" },
  { value: "partnership", label: "Partnership" },
  { value: "llp", label: "Limited Liability Partnership" },
  { value: "ltd", label: "Limited Company including personal service companies" },
  { value: "non_uk", label: "Non-UK engagement" },
  { value: "other", label: "Another party operated PAYE on the workers payment" },
];
