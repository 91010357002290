import { push } from "connected-react-router";
import { CompleteCreditReviewTaskModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";


export const handleCreditReviewRequiredTask = creditLimitManagementTask => async (dispatch) => {
  dispatch(push(`/credit-limit-management/legal-entities/${creditLimitManagementTask.data.legal_entity_id}/credit-management`));

  dispatch(modalActions.showModal(CompleteCreditReviewTaskModal.displayName, {
    creditReviewId: creditLimitManagementTask.data.credit_review_date_id,
    legalEntityId: creditLimitManagementTask.data.legal_entity_id,
  }));
};
