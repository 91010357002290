import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./TableColumnSortable.module.scss";

export default class TableColumnSortable extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    collapsed: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.func.isRequired,
    sortOrder: PropTypes.oneOf(["NONE", "ASC", "DESC"]).isRequired,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    const { className, headerClassName, name, children, sortOrder, onClick } = this.props;

    return (
      <div
        className={classnames(className, styles.tableColumnSortable)}
        onClick={() => onClick && onClick(name, sortOrder)}
      >
        <div className={classnames(headerClassName, styles.sortableContent)} data-testid="sortableContent">
          {children}
          <div className={styles.arrows}>
            <FontAwesomeIcon
              className={classnames("up", this.isUpArrowVisible() && styles.visible)}
              icon={faAngleUp}
            />
            <FontAwesomeIcon
              className={classnames("down", this.isDownArrowVisible() && styles.visible)}
              icon={faAngleDown}
            />
          </div>
        </div>
      </div>
    );
  }

  isUpArrowVisible = () => {
    const { sortOrder } = this.props;

    return ["NONE", "ASC"].includes(sortOrder);
  }

  isDownArrowVisible = () => {
    const { sortOrder } = this.props;

    return ["NONE", "DESC"].includes(sortOrder);
  }

}
