import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Alert } from "../../../common/components";
import { CompanyView } from "../../components";
import styles from "./AgencyTab.module.scss";

export default class AgencyTab extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    companyInfo: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
      error: PropTypes.string,
    }),
    companyHistory: PropTypes.shape({
      registered_address: PropTypes.array,
      contract_start_date: PropTypes.array,
      legal_name: PropTypes.array,
      trading_as: PropTypes.array,
    }),
    onLoad: PropTypes.func.isRequired,
    onFetchCompanyInfo: PropTypes.func.isRequired,
    onDownloadDocument: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { prospectId, onFetchCompanyInfo, onLoad, match } = this.props;

    await onFetchCompanyInfo(prospectId);

    onLoad && onLoad(match.path);
  }

  render() {
    const { companyInfo, companyHistory } = this.props;

    if (companyInfo.loading) {
      return <ActivityIndicator />;
    }

    if (companyInfo.error) {
      return (
        <Alert
          className={styles.error}
          variant="error"
          text={companyInfo.error}
          showClose={false}
        />
      );
    }

    return (
      <CompanyView
        companyInfo={companyInfo}
        companyHistory={companyHistory}
        onLoad={this.handleLoad}
        onEdit={this.handleEdit}
        onDownload={this.handleDownload}
        onEditAgencyDetailsClick={() => this.handleEditClick("agency-details")}
        onEditBankInformationClick={() => this.handleEditClick("bank-information")}
        onEditBranchClick={branchId => this.handleEditClick(`branch/${branchId}`)}
        onEditTradingAddressClick={() => this.handleEditClick("trading-address")}
        onEditContractInformationClick={() => this.handleEditClick("contract-information")}
      />
    );
  }

  handleDownload = async (key) => {
    const { prospectId, onDownloadDocument } = this.props;

    await onDownloadDocument(prospectId, key.name);
  }

  handleEdit = async () => {
    const { prospectId, onEdit } = this.props;

    await onEdit(prospectId);
  }

  handleEditClick = (section) => {
    const { prospectId, onEditClick } = this.props;

    onEditClick(prospectId, section);
  }

}
