import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { PaginatedResponseFormatter } from "../../../../formatters";
import { SortOrderConverter } from "../../../../converters";

const GET_AGENCIES = gql`
query($pager: Pager, $sort_order: [SortOrder]) {
  getAgencies(pager: $pager, sort_order: $sort_order) {
    data {
      id
      known_as
      short_code
      last_payslip_date
      created_at

      worker_types {
        name
      }

      payroll_account_manager {
        first_name
        last_name
      }

      credit_controller {
        first_name
        last_name
      }

      agency_status {
        label
      }
    }
    total
  }
}
`;

export const getAgencies = ({
  pager: { page, limit },
  sortOrder,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCIES_PENDING,
    });
    const pager = {
      page: page || 1,
      limit: limit || 0,
    };
    const response = await apolloClient.query({
      query: GET_AGENCIES,
      variables: {
        pager,
        sort_order: SortOrderConverter(sortOrder),
      },
    });
    const { data } = response.data.getAgencies;
    const pagination = PaginatedResponseFormatter.format(
      response.data.getAgencies,
      pager.page,
      pager.limit
    );

    dispatch({
      type: types.GET_AGENCIES_SUCCESS,
      payload: {
        agencies: data,
        ...pagination,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCIES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
