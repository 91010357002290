import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_CONTACT_INFORMATION = gql`
query($id: ID!) {
  getLegalEntity(id: $id) {
    id
    meta {
      key
      value
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getContactInformation = ({ legalEntityId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CONTACT_INFORMATION_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_CONTACT_INFORMATION,
      variables: {
        id: legalEntityId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CONTACT_INFORMATION_SUCCESS,
      payload: {
        data: response.data.getLegalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CONTACT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
