import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { ReportingView } from "../../components";

export default class ReportingTab extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    reportingInfo: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object,
    }).isRequired,
    onFetchReportingInfo: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { prospectId, onFetchReportingInfo } = this.props;

    onFetchReportingInfo({ prospectId });
  }

  render() {
    const { reportingInfo } = this.props;

    if (reportingInfo.loading) {
      return <ActivityIndicator />;
    }

    return (
      <ReportingView
        reportingInfo={reportingInfo}
        onEditMiReportGroupingClick={() => this.handleEditClick("mi-report-grouping")}
      />
    );
  }

  handleEditClick = (section) => {
    const { prospectId, onEditClick } = this.props;

    onEditClick(prospectId, section);
  }

}
