import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions as agencyActions } from "../../agencies/duck";
import { actions } from "../duck";
import { Button, TextLink } from "../../common/components";
import { TaskCard, OnboardingInformation } from "../components";
import AgencyTaskPage from "./AgencyTaskPage";
import styles from "./AgencyTasksPage.module.scss";

class AgencyTasksPage extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    agency: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
      is_new_agency: PropTypes.bool,
      is_existing_paye_scheme: PropTypes.bool,
      introduced_via_broker: PropTypes.bool,
      worker_types: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
      })),
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    agencyTasks: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
        progress: PropTypes.shape({
          required_completed: PropTypes.number.isRequired,
          required_total: PropTypes.number.isRequired,
        }).isRequired,
        status: PropTypes.oneOf(["not_started", "in_progress", "done"]),
      })),
    }),
    agencyTask: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    getAgency: PropTypes.func.isRequired,
    getAgencyTasks: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { agencyId, agency, getAgencyTasks, getAgency } = this.props;

    // Only reload if it is a different agency OR we are missing some key information
    const needsToLoadAgency =
      agency?.id !== agencyId ||
      _.isNil(agency?.is_new_agency) ||
      _.isNil(agency?.worker_types);

    await Promise.all(_.compact([
      needsToLoadAgency && getAgency(agencyId),
      await getAgencyTasks({ agencyId }),
    ]));
  }

  render() {
    const { agencyId, agency, agencyTasks, history, match } = this.props;

    const tasks = this.getTasksInOrderOfPriority();

    return (
      <div className={styles.container}>
        <div className={styles.pageText}>Onboarding Agency</div>
        <div className={styles.headerFlex}>
          <div className={styles.agencyName}>
            {agency?.known_as}
            {agency?.short_code && (
              <span className={styles.agencyId}>({agency?.short_code})</span>
            )}
          </div>
          <div>
            <TextLink
              className={styles.textLink}
              to={`/agencies/${agencyId}`}
              text="View Agency Profile"
            />
            <Button variant="outline-primary" onClick={() => history.push("/onboarding/agencies")}>
              Back to Agencies
            </Button>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.tasks}>
            {this.renderTasks()}
          </div>
          <div className={styles.taskContent}>
            <OnboardingInformation
              loading={!agency}
              isNewAgency={agency?.is_new_agency}
              isExistingPayeScheme={agency?.is_existing_paye_scheme}
              introducedViaBroker={agency?.introduced_via_broker}
              workerTypes={(agency?.worker_types || []).map(workerType => workerType.name)}
            />
            <Switch>
              <Route exact path={`${match.path}/:taskId`} component={AgencyTaskPage} />
              {!agencyTasks.loading && tasks.length > 0 && (
                <Redirect to={`${match.path}/${tasks[0].id}`} />
              )}
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  renderTasks = () => {
    const { agencyTasks, agencyTask } = this.props;
    const taskId = agencyTask?.data?.id;

    if (agencyTasks.loading && agencyTasks.data.length === 0) {
      return <ActivityIndicator />;
    }

    return this.getTasksInOrderOfPriority().map(task => (
      <TaskCard
        key={task.onboarding_task.name}
        active={task.id === taskId}
        status={task.status}
        completed={task.status === "done"}
        name={task.onboarding_task.name}
        tasksCompleted={task.progress.required_completed}
        tasksTotal={task.progress.required_total}
        disabled={agencyTasks.loading || agencyTask.loading}
        onClick={() => this.onTaskSelected(task.id)}
      />
    ));
  }

  onTaskSelected = (taskId) => {
    const { agencyId, history } = this.props;

    history.push(`/onboarding/agencies/${agencyId}/${taskId}`);
  }

  getTasksInOrderOfPriority = () => {
    const { agencyTasks } = this.props;

    return _.chain(agencyTasks.data)
      .orderBy([
        task => task.status === "done" ? 1 : 0,
        "due_date",
        "onboarding_task.weight",
      ])
      .value();
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.onboarding,
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...agencyActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgencyTasksPage);
