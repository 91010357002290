export const GET_AGENCY_DOCUMENT_PENDING = "DOCUMENTS/GET_AGENCY_DOCUMENT_PENDING";
export const GET_AGENCY_DOCUMENT_SUCCESS = "DOCUMENTS/GET_AGENCY_DOCUMENT_SUCCESS";
export const GET_AGENCY_DOCUMENT_FAILURE = "DOCUMENTS/GET_AGENCY_DOCUMENT_FAILURE";

export const GET_AGENCY_DOCUMENTS_PENDING = "DOCUMENTS/GET_AGENCY_DOCUMENTS_PENDING";
export const GET_AGENCY_DOCUMENTS_SUCCESS = "DOCUMENTS/GET_AGENCY_DOCUMENTS_SUCCESS";
export const GET_AGENCY_DOCUMENTS_FAILURE = "DOCUMENTS/GET_AGENCY_DOCUMENTS_FAILURE";
export const GET_PROSPECT_DOCUMENTS_PENDING = "DOCUMENTS/GET_PROSPECT_DOCUMENTS_PENDING";
export const GET_PROSPECT_DOCUMENTS_SUCCESS = "DOCUMENTS/GET_PROSPECT_DOCUMENTS_SUCCESS";
export const GET_PROSPECT_DOCUMENTS_FAILURE = "DOCUMENTS/GET_PROSPECT_DOCUMENTS_FAILURE";
export const GET_PROSPECT_DOCUMENT_PENDING = "DOCUMENTS/GET_PROSPECT_DOCUMENT_PENDING";
export const GET_PROSPECT_DOCUMENT_SUCCESS = "DOCUMENTS/GET_PROSPECT_DOCUMENT_SUCCESS";
export const GET_PROSPECT_DOCUMENT_FAILURE = "DOCUMENTS/GET_PROSPECT_DOCUMENT_FAILURE";
export const DOWNLOAD_DOCUMENTS_PENDING = "DOCUMENTS/DOWNLOAD_DOCUMENTS_PENDING";
export const DOWNLOAD_DOCUMENTS_SUCCESS = "DOCUMENTS/DOWNLOAD_DOCUMENTS_SUCCESS";
export const DOWNLOAD_DOCUMENTS_FAILURE = "DOCUMENTS/DOWNLOAD_DOCUMENTS_FAILURE";
export const REMOVE_AGENCY_DOCUMENT_PENDING = "DOCUMENTS/REMOVE_AGENCY_DOCUMENT_PENDING";
export const REMOVE_AGENCY_DOCUMENT_SUCCESS = "DOCUMENTS/REMOVE_AGENCY_DOCUMENT_SUCCESS";
export const REMOVE_AGENCY_DOCUMENT_FAILURE = "DOCUMENTS/REMOVE_AGENCY_DOCUMENT_FAILURE";
export const REMOVE_PROSPECT_DOCUMENT_PENDING = "DOCUMENTS/REMOVE_PROSPECT_DOCUMENT_PENDING";
export const REMOVE_PROSPECT_DOCUMENT_SUCCESS = "DOCUMENTS/REMOVE_PROSPECT_DOCUMENT_SUCCESS";
export const REMOVE_PROSPECT_DOCUMENT_FAILURE = "DOCUMENTS/REMOVE_PROSPECT_DOCUMENT_FAILURE";
export const GET_AGENCY_DOCUMENT_TYPES_PENDING = "DOCUMENTS/GET_AGENCY_DOCUMENT_TYPES_PENDING";
export const GET_AGENCY_DOCUMENT_TYPES_SUCCESS = "DOCUMENTS/GET_AGENCY_DOCUMENT_TYPES_SUCCESS";
export const GET_AGENCY_DOCUMENT_TYPES_FAILURE = "DOCUMENTS/GET_AGENCY_DOCUMENT_TYPES_FAILURE";
export const UPLOAD_AGENCY_DOCUMENT_PENDING = "DOCUMENTS/UPLOAD_AGENCY_DOCUMENT_PENDING";
export const UPLOAD_AGENCY_DOCUMENT_SUCCESS = "DOCUMENTS/UPLOAD_AGENCY_DOCUMENT_SUCCESS";
export const UPLOAD_AGENCY_DOCUMENT_FAILURE = "DOCUMENTS/UPLOAD_AGENCY_DOCUMENT_FAILURE";
export const UPLOAD_PROSPECT_DOCUMENT_PENDING = "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENT_PENDING";
export const UPLOAD_PROSPECT_DOCUMENT_SUCCESS = "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENT_SUCCESS";
export const UPLOAD_PROSPECT_DOCUMENT_FAILURE = "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENT_FAILURE";
export const UPLOAD_AGENCY_DOCUMENTS_PENDING = "DOCUMENTS/UPLOAD_AGENCY_DOCUMENTS_PENDING";
export const UPLOAD_AGENCY_DOCUMENTS_SUCCESS = "DOCUMENTS/UPLOAD_AGENCY_DOCUMENTS_SUCCESS";
export const UPLOAD_AGENCY_DOCUMENTS_FAILURE = "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENTS_FAILURE";
export const UPLOAD_PROSPECT_DOCUMENTS_PENDING = "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENTS_PENDING";
export const UPLOAD_PROSPECT_DOCUMENTS_SUCCESS = "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENTS_SUCCESS";
export const UPLOAD_PROSPECT_DOCUMENTS_FAILURE = "DOCUMENTS/UPLOAD_PROSPECT_DOCUMENTS_FAILURE";
export const GET_ONBOARDING_DOCUMENTS_PENDING = "DOCUMENTS/GET_ONBOARDING_DOCUMENTS_PENDING";
export const GET_ONBOARDING_DOCUMENTS_SUCCESS = "DOCUMENTS/GET_ONBOARDING_DOCUMENTS_SUCCESS";
export const GET_ONBOARDING_DOCUMENTS_FAILURE = "DOCUMENTS/GET_ONBOARDING_DOCUMENTS_FAILURE";
export const GET_PROSPECT_ONBOARDING_DOCUMENTS_PENDING = "DOCUMENTS/GET_PROSPECT_ONBOARDING_DOCUMENTS_PENDING";
export const GET_PROSPECT_ONBOARDING_DOCUMENTS_SUCCESS = "DOCUMENTS/GET_PROSPECT_ONBOARDING_DOCUMENTS_SUCCESS";
export const GET_PROSPECT_ONBOARDING_DOCUMENTS_FAILURE = "DOCUMENTS/GET_PROSPECT_ONBOARDING_DOCUMENTS_FAILURE";
export const UPLOAD_AGENCY_ONBOARDING_DOCUMENT_PENDING = "DOCUMENTS/UPLOAD_AGENCY_ONBOARDING_DOCUMENT_PENDING";
export const UPLOAD_AGENCY_ONBOARDING_DOCUMENT_SUCCESS = "DOCUMENTS/UPLOAD_AGENCY_ONBOARDING_DOCUMENT_SUCCESS";
export const UPLOAD_AGENCY_ONBOARDING_DOCUMENT_FAILURE = "DOCUMENTS/UPLOAD_AGENCY_ONBOARDING_DOCUMENT_FAILURE";
export const UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_PENDING = "DOCUMENTS/UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_PENDING";
export const UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_SUCCESS = "DOCUMENTS/UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_SUCCESS";
export const UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_FAILURE = "DOCUMENTS/UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_FAILURE";
export const DOWNLOAD_ONBOARDING_DOCUMENTS_PENDING = "DOCUMENTS/DOWNLOAD_ONBOARDING_DOCUMENTS_PENDING";
export const DOWNLOAD_ONBOARDING_DOCUMENTS_SUCCESS = "DOCUMENTS/DOWNLOAD_ONBOARDING_DOCUMENTS_SUCCESS";
export const DOWNLOAD_ONBOARDING_DOCUMENTS_FAILURE = "DOCUMENTS/DOWNLOAD_ONBOARDING_DOCUMENTS_FAILURE";
