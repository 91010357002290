/* eslint-disable no-console */
import { Auth } from "aws-amplify";
import { push } from "connected-react-router";
import * as types from "../types";

export const logout = () => async (dispatch) => {
  await Auth.signOut();

  dispatch({
    type: types.LOGOUT,
  });

  dispatch(push("/"));
};
