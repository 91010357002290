import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { bytesToGigabytes } from "../../../../utils";

const UPLOAD_AGENCY_ONBOARDING_DOCUMENT = gql`
query($input: AgencyDocumentUploadUrlInput!) {
  getAgencyOnboardingDocumentUploadUrl(input: $input) {
    bucket
    key
    url
  }
}
`;

export const uploadAgencyOnboardingDocument = (document, agency_id, document_type) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_PENDING,
    });

    const fileSize = bytesToGigabytes(document.size);

    if (fileSize > 5) {
      throw Error("You can only upload files up to 5GB");
    }

    const response = await apolloClient.query({
      query: UPLOAD_AGENCY_ONBOARDING_DOCUMENT,
      variables: {
        input: {
          agency_id,
          document_type,
          filename: document.name,
        },
      },
    });
    const uploadUrl = response.data.getAgencyOnboardingDocumentUploadUrl;

    await fetch(uploadUrl.url, {
      method: "PUT",
      body: document,
    });

    dispatch({
      type: types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_SUCCESS,
    });

    return document.name;
  }
  catch (error) {
    dispatch({
      type: types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
