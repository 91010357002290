import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Table2 } from "../../../common/components";
import { toMoney } from "../../../../utils";
import styles from "./CustomerAccountList.module.scss";

export default class CustomerAccountList extends PureComponent {

  static propTypes = {
    customerAccounts: PropTypes.arrayOf(PropTypes.shape({
      customer_account: PropTypes.string.isRequired,
      distributed: PropTypes.number.isRequired,
      total_debt: PropTypes.number.isRequired,
      exposure: PropTypes.number.isRequired,
      credit_status: PropTypes.string.isRequired,
    })),
  }

  static defaultProps = {
    customerAccounts: [],
  }

  render() {
    const { customerAccounts } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.tableLabel}>inc-VAT*</div>
        <Table2 loading={false} data={customerAccounts}>
          <Table2.Column
            className={styles.customerAccountColumn}
            name="customerAccount"
            value={row => <span className={styles.customerAccountText}>{row.customer_account}</span>}
          >
          Customer Account
          </Table2.Column>
          <Table2.Column
            className={classnames(styles.moneyColumn, styles.placeholder)}
            name="distributed"
            value={row => toMoney(row.distributed)}
            collapsed
          >
          Distributed
          </Table2.Column>
          <Table2.Column
            className={classnames(styles.moneyColumn, styles.placeholder)}
            name="totalDebt"
            value={row => toMoney(row.total_debt)}
            collapsed
          >
          Total Debt
          </Table2.Column>
          <Table2.Column
            className={classnames(styles.moneyColumn, styles.placeholder)}
            name="exposure"
            value={row => toMoney(row.exposure)}
            collapsed
          >
          Exposure
          </Table2.Column>
          <Table2.Column
            className={classnames(styles.statusColumn, styles.placeholder)}
            name="status"
            value={row => row.credit_status}
          >
          Status
          </Table2.Column>
        </Table2>
      </div>
    );
  }

}
