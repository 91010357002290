import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { PayrollGeneralForm } from "../../components";
import styles from "./EditPayrollGeneralPage.module.scss";

export default class EditPayrollGeneralPage extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    payrollGeneral: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        workerTypes: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
        })),
      }),
      submitting: PropTypes.bool,
    }),
    onFetchPayrollGeneral: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { prospectId, onFetchPayrollGeneral } = this.props;

    await onFetchPayrollGeneral({ prospectId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.payrollGeneral?.loading ? (
          <ActivityIndicator />
        ) : (
          <PayrollGeneralForm
            data={this.props.payrollGeneral?.data}
            submitting={this.props.payrollGeneral?.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { prospectId, onCancel } = this.props;

    await onCancel(prospectId);
  }

  handleSubmit = async (data) => {
    const { prospectId, onSubmit } = this.props;

    await onSubmit({ prospectId, data });
  }

}
