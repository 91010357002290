import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./CompleteConvertToAgencyConfirmationModal.module.scss";

export default function CompleteConvertToAgencyConfirmationModal(props) {
  return (
    <ConfirmationModal
      className={styles.container}
      title="Convert to Agency"
      submitText="Convert to Agency"
      cancelText="Cancel"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div className={styles.line}>
        You are about to complete the conversion of this prospect into an agency. By continuing, you understand that this process cannot be reversed.
      </div>
    </ConfirmationModal>
  );
}

CompleteConvertToAgencyConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
