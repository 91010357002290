import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_ALL_CREDIT_REVIEW_DATES = gql`
query(
  $legal_entity_id: ID!
) {
  getAllCreditReviewDates(legal_entity_id: $legal_entity_id) {
    id
    legal_entity {
      id
    }

    comment
    next_review_date
    created_by {
      first_name
      last_name
    }

    created_at
  }
}
`;

/**
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getCreditReviewDates = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ALL_CREDIT_REVIEW_DATES_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_ALL_CREDIT_REVIEW_DATES,
      variables: {
        legal_entity_id: obj.legalEntityId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_ALL_CREDIT_REVIEW_DATES_SUCCESS,
      payload: {
        data: response.data.getAllCreditReviewDates,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_ALL_CREDIT_REVIEW_DATES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
