import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import { PublishRounded } from "@material-ui/icons";
import { DocumentList } from "../../components";
import styles from "./DocumentsTab.module.scss";

export default class DocumentsTab extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    prospectDocuments: PropTypes.shape({
      loading: PropTypes.bool,
      documents: PropTypes.array,
    }),
    documentTypes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })),
    getDocuments: PropTypes.func.isRequired,
    getDocumentTypes: PropTypes.func.isRequired,
    onDownloadDocument: PropTypes.func.isRequired,
    onShowDeleteDocumentModal: PropTypes.func.isRequired,
    onShowProspectDocumentVersionHistoryModal: PropTypes.func.isRequired,
    showUploadDocumentsModal: PropTypes.func.isRequired,
  };

  state = {}

  componentDidMount() {
    const { prospectId, getDocuments, getDocumentTypes } = this.props;

    getDocuments({ prospectId });
    getDocumentTypes();
  }

  render() {
    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Header>Documents</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <div className={styles.uploadContainer}>
              <PublishRounded className={styles.uploadIcon} />
              <span
                className={styles.uploadTitle}
                onClick={() => this.props.showUploadDocumentsModal({ prospectId: this.props.prospectId })}>
                  Upload New Documents
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DocumentList
              loading={this.props.prospectDocuments.loading}
              documents={this.props.prospectDocuments.documents}
              prospectId= {this.props.prospectId}
              documentTypes={this.props.documentTypes}
              onDownloadDocument={this.props.onDownloadDocument}
              onDeleteDocumentClick={this.props.onShowDeleteDocumentModal}
              onVersionHistoryClick={this.props.onShowProspectDocumentVersionHistoryModal}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
