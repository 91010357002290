import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { PaginatedResponseFormatter } from "../../../../formatters";

const GET_ONBOARDING_AGENCIES = gql`
query($pager: Pager, $search_options: OnboardingAgenciesSearchOptions!) {
  getOnboardingAgencies(pager: $pager, search_options: $search_options) {
    data {
      agency {
        id
        short_code
        name
      }
      tasks {
        id
        onboarding_task {
          name
        }
        definition {
          subtasks {
            name
            required
            complete
          }
        }
        status
        progress {
          required_total
          required_completed
          optional_total
          optional_completed
          total
        }
        completed_at
        created_at
        due_date
        is_applicable
      }
    }
    total
  }
}
`;

export const getOnboardingAgencies = ({
  pager,
  searchText,
  completed,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ONBOARDING_AGENCIES_PENDING,
      payload: {
        searchText,
      },
    });

    const response = await apolloClient.query({
      query: GET_ONBOARDING_AGENCIES,
      variables: {
        pager,
        search_options: {
          agency_id: searchText,
          agency_short_code: searchText,
          agency_name: searchText,
          completed,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const pagination = PaginatedResponseFormatter.format(
      response.data.getOnboardingAgencies,
      pager.page,
      pager.limit
    );

    dispatch({
      type: types.GET_ONBOARDING_AGENCIES_SUCCESS,
      payload: {
        data: response.data.getOnboardingAgencies.data,
        ...pagination,
        searchText,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_ONBOARDING_AGENCIES_FAILURE,
      payload: {
        error,
      },
    });
  }
};
