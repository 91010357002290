import { connect } from "react-redux";
import { push } from "connected-react-router";
import TaskListPage from "./TaskListPage";

const mapStateToProps = null;
const mapDispatchToProps = dispatch => ({
  onBackToCreditLimitManagementClick: () => {
    dispatch(push("/credit-limit-management"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskListPage);
