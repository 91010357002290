import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { PaginatedResponseFormatter } from "../../../../formatters";

const GET_ONBOARDING_PROSPECTS = gql`
query($pager: Pager, $search_options: OnboardingProspectsSearchOptions!) {
  getOnboardingProspects(pager: $pager, search_options: $search_options) {
    data {
      prospect {
        id
        known_as
      }
      tasks {
        id
        onboarding_task {
          name
        }
        definition {
          subtasks {
            name
            required
            complete
          }
        }
        status
        progress {
          required_total
          required_completed
          optional_total
          optional_completed
          total
        }
        completed_at
        created_at
        due_date
        is_applicable
      }
    }
    total
  }
}
`;

export const getOnboardingProspects = ({
  pager,
  searchText,
  completed,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ONBOARDING_PROSPECTS_PENDING,
      payload: {
        searchText,
      },
    });

    const response = await apolloClient.query({
      query: GET_ONBOARDING_PROSPECTS,
      variables: {
        pager,
        search_options: {
          prospect_name: searchText,
          completed,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const pagination = PaginatedResponseFormatter.format(
      response.data.getOnboardingProspects,
      pager.page,
      pager.limit
    );

    dispatch({
      type: types.GET_ONBOARDING_PROSPECTS_SUCCESS,
      payload: {
        data: response.data.getOnboardingProspects.data,
        ...pagination,
        searchText,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_ONBOARDING_PROSPECTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
