import _ from "lodash";

export default class ContractInformationFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (!this.isFieldDisabled("contract_start_date")) {
      if (!data?.contract_start_date) {
        errors = _.set(errors, "contract_start_date", "Contract Start Date is required");
      }
    }

    if (!this.isFieldDisabled("length_of_contract")) {
      if (data?.length_of_contract?.contract_period <= 0 || !data?.length_of_contract?.contract_length) {
        errors = _.set(errors, "length_of_contract", "Contract Length is required");
      }
    }

    if (!this.isFieldDisabled("fee")) {
      if (_.isNumber(data?.fee) && data?.fee < 0) {
        errors = _.set(errors, "fee", "Fee must be greater than or equal to zero");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  isFieldDisabled = (fieldName) => {
    return this.props?.data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
