import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_MI_REPORT_GROUPINGS = gql`
query($agency_id: ID!) {
  getAgency(id: $agency_id) {
    mi_report_grouping_required

    mi_report_groupings {
      active
      mi_report_grouping {
        id
        label
        value
      }
      values {
        id
        value
      }
    }
  }
}
`;

export const getAgencyMIReportGroupings = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_MI_REPORT_GROUPINGS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_MI_REPORT_GROUPINGS,
      variables: {
        agency_id: agencyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_MI_REPORT_GROUPINGS_SUCCESS,
      payload: {
        mi_report_grouping_required: response.data.getAgency.mi_report_grouping_required,
        mi_report_groupings: response.data.getAgency.mi_report_groupings,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_MI_REPORT_GROUPINGS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
