import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import EditContactInformationPage from "./EditContactInformationPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  onCancel: (legalEntityId) => {
    dispatch(push(`/credit-limit-management/legal-entities/${legalEntityId}/details`));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EditContactInformationPage);
