import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { HelpOutline } from "@material-ui/icons";
import { IconButton, Form, Modal } from "../../../common/components";
import { getExistingAccountReferences, isReferenceAvailable } from "../../services";
import { isAlphaNumeric } from "utils/string";
import styles from "./AddAccountReferenceModal.module.scss";

const INITIAL_FORM_STATE = {
  useExistingReference: false,
  reference: "",
  existingReferenceId: null,
};

export default function AddAccountReferenceModal(props) {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [existingReferences, setExistingReferences] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [errors, setErrors] = useState({});

  const { displayError, payrollAgencyCustomerRequestId } = props;

  useEffect(() => {
    let ignore = false;
    setExistingReferences(null);
    getExistingAccountReferences({ payrollAgencyCustomerRequestId: payrollAgencyCustomerRequestId })
      .then((references) => {
        if (!ignore) {
          setExistingReferences(references.map(ref => ({ value: ref.id, label: ref.reference })));
        }
      })
      .catch(error => displayError(error));

    return () => {
      ignore = true;
    };
  }, [displayError, payrollAgencyCustomerRequestId]);

  const saveReference = async () => {
    if (formData.useExistingReference) {
      await props.updateCustomerAccount({
        customerAccountId: props.customerAccountId,
        customer_account_reference_id: formData.existingReferenceId,
      });
    } else {
      await props.addCustomerAccountReference({
        reference: formData.reference + props.shortCode,
        payrollAgencyCustomerRequestId: props.payrollAgencyCustomerRequestId,
        customerAccountId: props.customerAccountId,
      });
    }

    props.hideModal(AddAccountReferenceModal.displayName);
  };

  const handleSubmit = () => {
    const reference = formData.useExistingReference
      ? existingReferences.find(x => x.value === formData.existingReferenceId)?.label
      : formData.reference  + props.shortCode;

    props.showConfirmationModal({
      title: "Are you sure?",
      children: <div>Please confirm you want to add the new customer account reference <strong>“{reference}”</strong>.</div>,
      submitText: "Add Customer Account Reference",
      cancelText: "Cancel",
      onSubmit: saveReference,
    });
  };

  const handleCancel = () => {
    props.hideModal(AddAccountReferenceModal.displayName);
  };

  const canComplete = () => {
    if (formData.useExistingReference) {
      if (!formData.existingReferenceId) {
        return false;
      }
    } else {
      if (formData.reference?.length !== 6) {
        return false;
      }
      if (errors.reference) {
        return false;
      }
    }
    return true;
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUseExistingReferenceChange = (e) => {
    setFormData({
      ...INITIAL_FORM_STATE,
      [e.target.name]: String(e.target.value) === "true",
    });
    setErrors({});
  };

  const handleReferenceChange = (e) => {
    if (!isAlphaNumeric(e.target.value)) {
      return;
    }

    if (e.target.value?.length === 6) {
      validate(e.target.value);
    }
    else if (e.target.value?.length < 6 && errors.reference) {
      setErrors({});
    }

    setFormData({ ...formData, [e.target.name]: e.target.value?.toUpperCase() });
  };

  const validate = async (reference) => {
    try {
      setIsValidating(true);

      const isAvailable = await isReferenceAvailable(reference + props.shortCode);

      if (!isAvailable) {
        setErrors({
          reference: "The Customer Account Reference entered is already taken",
        });
      }
      else {
        setErrors ({});
      }

      // Doing this inside the try block does mean it won't get unset in the
      // case of an error but hey ho, better than allowing the user to proceed.
      setIsValidating(false);
    } catch (error) {
      displayError(error);
    }
  };

  return (
    <Modal
      className={styles.container}
      title="Add Customer Account Reference"
      submitText="Add Customer Account Reference"
      visible={props.visible}
      busy={props.submitting || isValidating}
      primaryButtonDisabled={!canComplete()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <Form
        requiredKey
      >
        <Form.Section
          title="Customer Account Reference"
        >
          <Form.Group inline>
            <Form.Label
              required
              inline
              className={styles.label}
            >
              Use an existing Customer Account Reference?
            </Form.Label>
            <Form.RadioContainer>
              <Form.Radio
                value={true}
                name="useExistingReference"
                checked={formData.useExistingReference}
                text="Yes"
                onChange={handleUseExistingReferenceChange}
                disabled={(existingReferences ?? []).length === 0}
              />
              <Form.Radio
                value={false}
                name="useExistingReference"
                checked={!formData.useExistingReference}
                text="No"
                onChange={handleUseExistingReferenceChange}
              />
            </Form.RadioContainer>
          </Form.Group>

          {!formData.useExistingReference && (
            <Form.Group inline error={errors.reference}>
              <Form.Label
                required
                inline
                className={styles.label}
              >
              Customer Account Reference
              </Form.Label>
              <div className={styles.referenceContainer}>
                <Form.InputSemantic
                  className={styles.reference}
                  name="reference"
                  value={formData.reference}
                  placeholder="eg. GREEN1..."
                  maxLength={6}
                  onChange={handleReferenceChange}
                  highlightError={errors.reference}
                  label={{ basic: true, content: props.shortCode }}
                  labelPosition='right'
                />
                <IconButton
                  className={styles.tooltip}
                  icon={<HelpOutline />}
                  tooltip="The last 2 characters are the Agency Short Code"
                />
              </div>
            </Form.Group>)}

          {formData.useExistingReference && (
            <Form.Group inline>
              <Form.Label inline required>Customer Account Reference</Form.Label>
              <Form.Value>
                <Form.Select
                  name="existingReferenceId"
                  placeholder="Select Customer Account Reference..."
                  options={existingReferences ?? []}
                  fluid
                  value={formData.existingReferenceId}
                  onChange={handleFormChange}
                />
              </Form.Value>
            </Form.Group>
          )}
        </Form.Section>
      </Form>
    </Modal>
  );
}

AddAccountReferenceModal.displayName = "AddAccountReferenceModal";

AddAccountReferenceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  customerAccountId: PropTypes.string.isRequired,
  shortCode: PropTypes.string.isRequired,
  payrollAgencyCustomerRequestId: PropTypes.string.isRequired,
  addCustomerAccountReference: PropTypes.func.isRequired,
  updateCustomerAccount: PropTypes.func.isRequired,
  showConfirmationModal: PropTypes.func.isRequired,
  displayError: PropTypes.func.isRequired,
};
