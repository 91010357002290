import React from "react";
import PropTypes from "prop-types";
import { GroupWork as PlaceholderIcon } from "@material-ui/icons";
import { Placeholder } from "semantic-ui-react";
import { Form, IconButton } from "../../../../common/components";
import styles from "./PlaceholderCard.module.scss";

export default function PlaceholderCard(props) {
  return (
    <Form.Card
      className={styles.container}
      title={props.title || "Title"}
      rightAccessory={(
        <IconButton
          icon={<PlaceholderIcon />}
          disabled
        />
      )}
    >
      <Placeholder className={styles.placeholder} fluid>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </Form.Card>
  );
}

PlaceholderCard.propTypes = {
  title: PropTypes.string,
};
