import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Edit } from "@material-ui/icons";
import { Form, IconButton, TextLink } from "../../../../common/components";
import { formatUrl } from "../../../../../utils";
import styles from "./ContactInformationSection.module.scss";

export default function ContactInformationSection(props) {
  const getMetaValue = (key, defaultValue) => {
    return props.data?.meta?.find(x => x.key === key)?.value || defaultValue;
  };

  const website = getMetaValue("website", "");

  return (
    <Form.Card
      className={classnames(props.className, styles.container)}
      title="Contact Information"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <Form.Group inline>
        <Form.Label>Telephone Number</Form.Label>
        <Form.Value>
          {getMetaValue("telephone", "-")}
        </Form.Value>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Website</Form.Label>
        <Form.Value>
          {website ? (
            <TextLink
              className={styles.link}
              target="_blank"
              href={formatUrl(website)}
              text={website}
            />
          ) : "-"}
        </Form.Value>
      </Form.Group>
    </Form.Card>
  );
}

ContactInformationSection.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    meta: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string,
    })).isRequired,
  }),
  onEditClick: PropTypes.func,
};
