import _ from "lodash";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { capitalize, parseDate } from "utils";

const GET_PAYROLL_HISTORY = gql`
fragment RevisionHistory on Revision {
  operation
  updated_at
  document
}

query(
  $end_of_holiday_year_input: GetHistoryInput!
  $default_holiday_scheme_id_input: GetHistoryInput!
  $applying_registered_vat_input: GetHistoryInput!
  $payroll_frequency_input: GetHistoryInput!
  $vat_frequency_input: GetHistoryInput!
  $vat_period_ending_date_input: GetHistoryInput!
) {
  end_of_holiday_year: getHistory(input: $end_of_holiday_year_input) {
    ...RevisionHistory
  }


  default_holiday_scheme_id: getHistory(input: $default_holiday_scheme_id_input) {
    ...RevisionHistory
  }

  default_holiday_schemes: getList(identifier: "default_holiday_scheme") {
    values {
      id
      label
      value
    }
  }

  applying_registered_vat: getHistory(input: $applying_registered_vat_input) {
    ...RevisionHistory
  }

  payroll_frequency: getHistory(input: $payroll_frequency_input) {
    ...RevisionHistory
  }

  vat_frequency: getHistory(input: $vat_frequency_input) {
    ...RevisionHistory
  }

  vat_period_ending_date: getHistory(input: $vat_period_ending_date_input) {
    ...RevisionHistory
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @returns
 */
export const getPayrollHistory = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PAYROLL_HISTORY_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PAYROLL_HISTORY,
      variables: {
        end_of_holiday_year_input: {
          "document_id": agencyId,
          "model": "AgencyHolidayPayInfo",
          "fields": ["end_of_holiday_year_pay_period", "end_of_holiday_year_tax_year_end"],
          "limit": 5,
          "order": "desc",
        },
        default_holiday_scheme_id_input: {
          "document_id": agencyId,
          "model": "AgencyHolidayPayInfo",
          "fields": ["default_holiday_scheme_id"],
          "limit": 5,
          "order": "desc",
        },
        applying_registered_vat_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["applying_registered_vat"],
          "limit": 5,
          "order": "desc",
        },
        payroll_frequency_input: {
          "document_id": agencyId,
          "model": "AgencyPreferences",
          "fields": ["payroll_frequency"],
          "limit": 5,
          "order": "desc",
        },
        vat_frequency_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["vat_frequency"],
          "limit": 5,
          "order": "desc",
        },
        vat_period_ending_date_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["vat_period_ending_date"],
          "limit": 5,
          "order": "desc",
        },
      },
    });

    const history = {
      end_of_holiday_year: _.chain(response.data.end_of_holiday_year).map((revision) => {
        const data = JSON.parse(revision.document);

        if (!data.end_of_holiday_year_pay_period || !data.end_of_holiday_year_tax_year_end) {
          return null;
        }

        return {
          value: `${data.end_of_holiday_year_pay_period || "-"} / ${data.end_of_holiday_year_tax_year_end || "-"}`,
          updated_at: revision.updated_at,
        };
      }).compact().value(),
      default_holiday_scheme: _.chain(response.data.default_holiday_scheme_id).map((revision) => {
        const data = JSON.parse(revision.document);
        const value = data.default_holiday_scheme_id
          ? response.data.default_holiday_schemes?.values.find(x => String(x.id) === String(data.default_holiday_scheme_id))?.label
          : "-";

        return {
          value,
          updated_at: revision.updated_at,
        };
      }).value(),
      applying_registered_vat: _.chain(response.data.applying_registered_vat).map((revision) => {
        const data = JSON.parse(revision.document);
        if (_.isNull(data.applying_registered_vat)) {
          return null;
        }

        return {
          value: data.applying_registered_vat ? "Yes" : "No",
          updated_at: revision.updated_at,
        };
      }).value(),
      payroll_frequency: _.chain(response.data.payroll_frequency).map((revision) => {
        const data = JSON.parse(revision.document);

        return {
          value: data.payroll_frequency === "both" ? "Weekly, Monthly" : capitalize(data.payroll_frequency),
          updated_at: revision.updated_at,
        };
      }).value(),
      vat_frequency: _.chain(response.data.vat_frequency).map((revision) => {
        const data = JSON.parse(revision.document);

        return {
          value: capitalize(data.vat_frequency),
          updated_at: revision.updated_at,
        };
      }).value(),
      vat_period_ending_date: _.chain(response.data.vat_period_ending_date).map((revision) => {
        const data = JSON.parse(revision.document);

        return {
          value: parseDate(data.vat_period_ending_date),
          updated_at: revision.updated_at,
        };
      }).value(),
    };

    dispatch({
      type: types.GET_PAYROLL_HISTORY_SUCCESS,
      payload: {
        history,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PAYROLL_HISTORY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
