import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_DEPARTMENTS = gql`
query {
  getDepartments {
    id
    name
  }
}
`;

export const getDepartments = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_DEPARTMENTS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_DEPARTMENTS,
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_DEPARTMENTS_SUCCESS,
      payload: {
        departments: response.data.getDepartments,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_DEPARTMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
