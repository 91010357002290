import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form } from "../../../common/components";
import { capitalize, parseDate } from "../../../../utils";
import styles from "./CompaniesHouseDetails.module.scss";

export default function CompaniesHouseDetails(props) {
  return (
    <div className={styles.companyDetailsContainer}>
      <div className={styles.header}>
        <h1 className={styles.title}>
        Companies House Details
        </h1>
      </div>
      <Form className={styles.form}>
        <div className={styles.cols}>
          <div>
            <Form.Group inline>
              <Form.Label>Exact Legal Name</Form.Label>
              <Form.Value>
                {props.companyProfile?.company_name}
              </Form.Value>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Registered Company Number</Form.Label>
              <Form.Value>
                {props.companyProfile?.company_number}
              </Form.Value>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Company Type</Form.Label>
              <Form.Value>
                {capitalize(props.companyProfile?.type) || "-"}
              </Form.Value>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Incorporated Date</Form.Label>
              <Form.Value>
                {parseDate(props.companyProfile?.date_of_creation) || "-"}
              </Form.Value>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Last Account Period End On</Form.Label>
              <Form.Value>
                {parseDate(props.companyProfile?.accounts?.last_accounts?.made_up_to) || "-"}
              </Form.Value>
            </Form.Group>
            <Form.Group className={styles.alignStart} inline>
              <Form.Label className={styles.alignStartLabel}>Previous Names</Form.Label>
              <Form.Value>
                {props.companyProfile?.previous_company_names?.map((previousName, index) => (
                  <Form.FlexRow key={`previous_name_${index}`}>
                    <Form.ValueInline>
                      {previousName.name}
                    </Form.ValueInline>
                  </Form.FlexRow>
                )) || "-"}
              </Form.Value>
            </Form.Group>
          </div>
          <div>
            <Form.Group inline>
              <Form.Label>Company Status</Form.Label>
              <Form.Value>
                {capitalize(props.companyProfile?.company_status) || "-"}
              </Form.Value>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Company Status Detail</Form.Label>
              <Form.Value>
                {props.companyProfile?.company_status_detail || "-"}
              </Form.Value>
            </Form.Group>
            <Form.Group className={styles.alignStart} inline>
              <Form.Label className={styles.alignStartLabel}>Registered Address</Form.Label>
              <Form.Value>
                {_.chain([
                  props.companyProfile?.registered_office_address?.address_line_1,
                  props.companyProfile?.registered_office_address?.address_line_2,
                  props.companyProfile?.registered_office_address?.locality,
                  props.companyProfile?.registered_office_address?.region,
                  props.companyProfile?.registered_office_address?.postal_code,
                  props.companyProfile?.registered_office_address?.country,
                ]).compact().map((addressLine, index) => (
                  <Form.FlexRow key={`registered_address_${index}`}>
                    <Form.ValueInline>
                      {addressLine}
                    </Form.ValueInline>
                  </Form.FlexRow>
                )).value()}
              </Form.Value>
            </Form.Group>
          </div>
        </div>
      </Form>
    </div>
  );
}

CompaniesHouseDetails.propTypes = {
  companyProfile: PropTypes.shape({
    company_name: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
    company_status: PropTypes.string.isRequired,
    company_status_detail: PropTypes.string,
    type: PropTypes.string.isRequired,
    date_of_creation: PropTypes.string,
    previous_names: PropTypes.arrayOf(PropTypes.string),
    accounts: PropTypes.shape({
      last_accounts: PropTypes.shape({
        made_up_to: PropTypes.string,
      }),
    }),
    links: PropTypes.shape({
      self: PropTypes.string.isRequired,
    }),
    previous_company_names: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      effective_from: PropTypes.string,
    })),
    registered_office_address: PropTypes.shape({
      address_line_1: PropTypes.string,
      address_line_2: PropTypes.string,
      locality: PropTypes.string,
      region: PropTypes.string,
      postal_code: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};
