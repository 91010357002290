import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../duck";
import CreditOverview from "./CreditOverview";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
  currentUser: state.currentUser.user,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditOverview);

