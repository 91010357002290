import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UNSUSPEND_CREDIT = gql`
mutation($input: AddCreditSuspensionInput!) {
  addCreditSuspension(input: $input) {
    id
    date_of_effect
    type
    status
    comment
    created_by {
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} legalEntityId
 * @param {number} dateOfEffect
 * @returns
 */
export const unsuspendCredit = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UNSUSPEND_CREDIT,
      variables: {
        input: {
          legal_entity_id: obj.legalEntityId,
          type: "unsuspend",
          date_of_effect: obj.dateOfEffect,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_SUCCESS,
      payload: {
        activeSuspension: null,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
