import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const SAVE_AGENCY = gql`
mutation(
  $agency_id: ID!,
  $contract: UpdateAgencyContractInput,
  $agency_details: UpdateAgencyInput,
  $bank_information: UpdateAgencyBankInput
) {
  updateAgency(id: $agency_id, agency_input: $agency_details) {
    id
    known_as
    exact_legal_name
    company_number
    vat_number
    status

    officers {
      items {
        name
        officer_role
      }
    }

    companies_house_info {
      registered_office_address {
        address_line_1
        locality
        postal_code
      }
      accounts {
        last_accounts {
          made_up_to
        }
      }
      company_status
      company_status_detail
      type
      date_of_creation
    }

    contract {
      start_date
      contract_length
      contract_period
      date_first_traded
      fee
      funding_type
    }

    banking {
      agency_id
      sort_code
      account_number
      bank_name
      branch
      payee_name
    }

    logo_url
    trading_as
    website
  }
  updateAgencyContract(id: $agency_id, agency_contract_input: $contract) {
    id
  }
  updateAgencyBankInformation(agency_bank_input: $bank_information) {
    agency_id
  }
}
`;

export const saveAgencyCompanyDetails = ({
  agency_id,
  contract,
  agencyDetails,
  bankInformation,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.SAVE_AGENCY_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: SAVE_AGENCY,
      variables: {
        agency_id,
        contract,
        agency_details: agencyDetails,
        bank_information: bankInformation,
      },
    });

    dispatch({
      type: types.SAVE_AGENCY_SUCCESS,
      payload: {
        agency: response.data.updateAgency,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.SAVE_AGENCY_FAILURE,
      payload: {
        error,
      },
    });
  }
};
