import { EditPaymentDetailsModal } from "../../modals";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.customerAccountId
 * @param {string} obj.payrollAgencyCustomerRequestId
 *
 * @returns
 */
export const showEditPaymentDetailsModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(EditPaymentDetailsModal.displayName, {
    customerAccountId: obj.customerAccountId,
    payrollAgencyCustomerRequestId: obj.payrollAgencyCustomerRequestId,
  }));
};
