const formatNiNumber = (value) => {
  if (!value) {
    return;
  }

  const trailingSpace = value.endsWith(" ");
  const input = value.split(" ").join("").toUpperCase();
  const split = input.match(/.{1,2}/g); // add a space every 2 characters
  return split.join(" ").concat(trailingSpace ? " " : "");
};

export default formatNiNumber;
