import React from "react";
import PropTypes from "prop-types";
import { ErrorOutline } from "@material-ui/icons";
import { Button, InfoModal } from "../../../common/components";
import styles from "./PayrollTransferValidationModal.module.scss";

export default function PayrollTransferValidationModal({
  onConfirmClick,
  errors,
  ...otherProps
}) {
  return (
    <InfoModal
      {...otherProps}
      onClose={onConfirmClick}
      showCloseButton={false}
      style={{
        maxHeight: "none",
      }}
    >
      <div className={styles.title}>
        Unable to transfer to payroll
      </div>
      <p>We were unable to transfer this agency to the payroll system for the following reason(s):</p>
      {errors?.map((error, i) => (
        <div className={styles.error} key={i}>
          <div className={styles.message}>
            <ErrorOutline className={styles.errorIcon} />
            {error.message}
          </div>
          {error.fields &&
            <div className={styles.fields}>
              {error.fields.map((item, i) => (
                <div className={styles.group} key={i}>
                  <div className={styles.groupTitle}>{item.group}</div>
                  {item.fields?.map((field, i) => (
                    <div className={styles.field} key={i}>
                      {field}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }
        </div>
      ))}
      <div className={styles.actions}>
        <Button variant="primary" onClick={onConfirmClick}>Okay</Button>
      </div>
    </InfoModal>
  );
}

PayrollTransferValidationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          group: PropTypes.string.isRequired,
          fields: PropTypes.arrayOf(
            PropTypes.string.isRequired,
          ),
        }),
      ),
    }),
  ).isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};
