import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

export default function ExportTypeLabel(props) {
  const getColor = () => {
    switch (props.exportType) {
      case "prospect_export":
        return "blue";

      case "agency_export":
        return "orange";

      case "tempest_export":
        return "purple";

      default:
        return "";
    }
  };

  return (
    <Label color={getColor()}>
      {props.exportType}
    </Label>
  );
}

ExportTypeLabel.propTypes = {
  exportType: PropTypes.string,
};
