import React from "react";
import PropTypes from "prop-types";
import { Lock } from "@material-ui/icons";
import {
  Form,
  IconButton,
  SeeHistoryTooltip,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./CompaniesHouseRegisteredAddressSection.module.scss";

export default function CompaniesHouseRegisteredAddressSection(props) {
  return (
    <Form.Card
      className={styles.container}
      title="Companies House Registered Address"
      rightAccessory={(
        <IconButton
          className={styles.locked}
          icon={<Lock />}
          tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
        />
      )}
    >
      <Form.Value>
        <SeeHistoryTooltip variant="compact" data={props.companyHistory?.registered_address}>
          <div>
            {props.registeredOfficeAddress?.address_line_1 && (
              <>
                <span>{props.registeredOfficeAddress.address_line_1}</span>
                <br />
              </>
            )}
            {props.registeredOfficeAddress?.locality && (
              <>
                <span>{props.registeredOfficeAddress.locality}</span>
                <br />
              </>
            )}
            {props.registeredOfficeAddress?.postal_code && (
              <>
                <span>{props.registeredOfficeAddress.postal_code}</span>
                <br />
              </>
            )}
          </div>
        </SeeHistoryTooltip>
      </Form.Value>
    </Form.Card>
  );
}

CompaniesHouseRegisteredAddressSection.propTypes = {
  companyHistory: PropTypes.shape({
    registered_address: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  registeredOfficeAddress: PropTypes.shape({
    address_line_1: PropTypes.string,
    locality: PropTypes.string,
    postal_code: PropTypes.string,
  }),
};
