import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import {
  LegalEntityDetailsView,
  NonRegisteredLegalEntityDetailsView,
} from "../../components";
import styles from "./DetailsTab.module.scss";

export default function DetailsTab(props) {
  const { legalEntityId, getLegalEntityDetails, getLegalEntityHistory } = props;

  useEffect(() => {
    getLegalEntityDetails({ legalEntityId });
    getLegalEntityHistory({ legalEntityId });
  }, [legalEntityId, getLegalEntityDetails, getLegalEntityHistory]);

  const handleEditClick = (section) => {
    const { legalEntityId, onEditClick } = props;

    onEditClick(legalEntityId, section);
  };

  if (!props.legalEntityDetails?.data || props.legalEntityDetails.loading) {
    return <ActivityIndicator />;
  }

  if (props.legalEntityDetails?.data?.is_registered_company) {
    return (
      <LegalEntityDetailsView
        className={styles.container}
        legalEntity={props.legalEntityDetails?.data}
        legalEntityHistory={props.legalEntityHistory.data}
        onEditCompanyDetailsClick={() => handleEditClick("company-details")}
        onEditContactInformationClick={() => handleEditClick("contact-information")}
      />
    );
  }
  else {
    return (
      <NonRegisteredLegalEntityDetailsView
        legalEntity={props.legalEntityDetails?.data}
        onEditCompanyDetailsClick={() => handleEditClick("company-details")}
      />
    );
  }
}

DetailsTab.propTypes = {
  legalEntityId: PropTypes.string.isRequired,
  legalEntityDetails: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
  }),
  legalEntityHistory: PropTypes.shape({
    data: PropTypes.array,
  }),
  getLegalEntityDetails: PropTypes.func.isRequired,
  getLegalEntityHistory: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
