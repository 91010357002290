import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import { Repeat, Close } from "@material-ui/icons";
import { TrackingIcon, TrackingModal } from "../";
import styles from "./SubtaskTracker.module.scss";
import { parseDate } from "../../../../utils";
import { ActionPopup, IconButton } from "../../../common/components";

export default class SubtaskTracker extends Component {

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(["tracking", "chase", "resolve"]).isRequired,
      name: PropTypes.string.isRequired,
      dateTime: PropTypes.number.isRequired,
    })).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onTrackingDelete: PropTypes.func.isRequired,
  }

  state = { type: "", visible: false  }

  renderRows = () => {
    const { data } = this.props;
    return (
      <div>
        {data.map((value, index) => {
          const { type, dateTime, name } = value;
          return (
            <div key={index} className={styles.trackingRow}>
              {this.getRowIcon(type)}
              <div className={styles.trackingName}>
                {name}
              </div>
              <div className={styles.trackingDateTime}>
                {parseDate(dateTime, "DD/MM/YYYY HH:mm")}
              </div>
              {index === data.length - 1 &&
              <ActionPopup>
                <IconButton
                  className={styles.deleteButton}
                  icon={(<Close />)}
                  text="Delete"
                  onClick={() => this.props.onTrackingDelete(value.id)}
                />
              </ActionPopup>}
            </div>
          );
        })}
        {!data.some(val => val.type === "resolve") && (
          <div>
            <div className={styles.trackingRow}>
              {this.getRowIcon("chase")}
              <div className={styles.trackingLink} onClick={() => this.handleActionClick("chase")}>Chase</div>
            </div>
            <div className={styles.trackingRow}>
              {this.getRowIcon("resolve")}
              <div className={styles.trackingLink} onClick={() => this.handleActionClick("resolve")}>Resolve</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  getRowIcon = (type) => {
    return <TrackingIcon type={type} className={styles.trackingIcon} />;
  }

  render() {
    const { data } = this.props;
    return (
      <div className={classnames(styles.subtaskTracker, this.props.className)}>
        {data.length === 0
          ? (
            <>
              <Repeat
                className={styles.startTrackingIcon}
                onClick={() => this.handleActionClick("tracking")}
                data-tip data-for="tracking-tooltip"
              />
              <ReactTooltip
                id="tracking-tooltip"
                effect="solid"
                place="bottom"
              >
                Apply a tracker: This enables you to chase and resolve tasks
              </ReactTooltip>
            </>
          )
          : this.renderRows()
        }
        <TrackingModal
          type={this.state.type}
          visible={this.state.visible}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }

  handleActionClick = (type) => {
    this.setState({ type, visible: true });

  }

  handleSubmit = (data) => {
    const { type } = this.state;
    const { onSubmit } = this.props;

    onSubmit && onSubmit({
      dateTime: data.dateTime,
      name: data.input,
      type,
    });

    this.setState({ type: "", visible: false });
  }

  handleCancel = () => {
    this.setState({ type: "", visible: false });
  }

}
