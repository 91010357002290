import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import { ReportProblemOutlined as WarningIcon } from "@material-ui/icons";
import { Button, TextLink } from "../../../../common/components";
import styles from "./CompaniesHouseSearchResultItem.module.scss";

export default function CompaniesHouseSearchResultItem(props) {
  return (
    <div className={classnames(props.className, styles.container)}>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.name}>
            {props.name}
            {props.companyStatus !== "active" && (
              <Popup
                trigger={<WarningIcon className={styles.warningIcon} />}
                position="right center"
                flowing
              >
                <Popup.Content className={styles.content}>
                  {props.name} is currently {props.companyStatus}
                </Popup.Content>
              </Popup>
            )}
          </div>
          <div className={styles.companyNumber}>
            {props.companyNumber}
            <span className={styles.incorporatedDate}>
              Incorporated{" "}
              {props.incorporatedDate
                ? moment(props.incorporatedDate, "YYYY-MM-DD").format("Do MMMM YYYY")
                : "Date Unavailable"
              }
            </span>
          </div>
          <div className={styles.addressSnippet}>
            {props.addressSnippet}
          </div>
        </div>
        <div className={styles.right}>
          <Button
            variant={props.companyStatus === "active" ? "primary" : "danger"}
            onClick={props.onClick}
          >
            {props.hideExistingWarning && "Validate"}
            {props.hideExistingWarning || (
              <>{props.exists ? "Go To" : "Validate"}</>
            )}
          </Button>
          <TextLink
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://beta.companieshouse.gov.uk/company/${props.companyNumber}`}
            text="More Info"
            hideExternalLinkIcon
          />
        </div>
      </div>
      {props.hideExistingWarning || (
        <div className={styles.footer}>
          {props.exists && (
            <div className={styles.exists}>
              This Legal Entity already exists in Optia
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CompaniesHouseSearchResultItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  companyNumber: PropTypes.string.isRequired,
  companyStatus: PropTypes.string,
  incorporatedDate: PropTypes.number,
  addressSnippet: PropTypes.string,
  exists: PropTypes.bool,
  hideExistingWarning: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
