import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "semantic-ui-react";
import styles from "./UserDropDown.module.scss";

export default function UserDropDown(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      className={classnames(styles.container, props.className)}
      trigger={(
        <div className={styles.flexContainer}>
          <div className={styles.nameRole}>
            <div className={styles.name} data-testid="name">
              {props.user.metadata.first_name} {props.user.metadata.last_name}
            </div>
            <div className={styles.role} data-testid="role">
              {_.head(props.user.metadata.user_groups)?.name || "User"}
            </div>
          </div>
          <FontAwesomeIcon
            className={styles.icon}
            icon={isOpen ? faChevronUp : faChevronDown}
          />
        </div>
      )}
      icon={null}
      direction="left"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <Dropdown.Menu className={styles.menu}>
        <Dropdown.Item className={styles.menuItem}>
          My Account Preferences
        </Dropdown.Item>
        <Dropdown.Item className={styles.menuItem} onClick={props.onChangePassword}>
          Change Password
        </Dropdown.Item>
        <Dropdown.Item className={styles.signOutMenuItem} onClick={props.onLogout}>
          Sign Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

UserDropDown.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    metadata: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      user_groups: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
      })),
    }),
  }).isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
