import { DeleteDocumentModal } from "../../../documents/containers";
import { actions as documentActions } from "../../../documents/duck";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.prospectId
 * @param {object} obj.document
 *
 * @returns
 */
export const showDeleteDocumentModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(DeleteDocumentModal.displayName, {
    prospectId: obj.prospectId,
    document: obj.document,
    onDeleteDocument: data => dispatch(documentActions.removeProspectDocument(data)),
  }));
};
