import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import DownloadExperianDocumentsModal from "./DownloadExperianDocumentsModal";

const mapStateToProps = state => ({
  loading: state.creditLimitManagement.experianCreditCheckDocuments.loading,
  documents: state.creditLimitManagement.experianCreditCheckDocuments.data,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(DownloadExperianDocumentsModal);

container.displayName = "DownloadExperianDocumentsModal";

export default container;
