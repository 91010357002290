import gql from "graphql-tag";
import { apolloClient } from "../../../apolloClient";

const GET_CUSTOMER_ACCOUNT_REFERENCE = gql`
  query($reference: String) {
    getCustomerAccountReference(reference : $reference) {
      id
      reference
    }
  }
`;

/**
 *
 * @param {string} reference
 * @returns
 */
export default async function isReferenceAvailable (reference) {
  const response = await apolloClient.query({
    query: GET_CUSTOMER_ACCOUNT_REFERENCE,
    variables: {
      reference: reference,
    },
  });

  if (response.errors) {
    throw new Error(response.errors[0].message);
  }

  return !response.data.getCustomerAccountReference;
}
