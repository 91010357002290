import * as types from "../types";

/**
 *
 * @param {string} componentName
 * @returns
 */
export const hideModal = componentName => (dispatch) => {
  dispatch({
    type: types.HIDE_MODAL,
    payload: {
      componentName,
    },
  });
};
