import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_HMRC_INFO = gql`
query(
  $agency_id: ID!
  $disabled_field_filter_options: AgencyDisabledFieldsFilterOptions!
) {
  getAgency(id: $agency_id) {
    is_existing_paye_scheme

    hmrc_info {
      paye_scheme_reference
      accounts_office_reference
      claiming_employers_ni_allowance
      small_employers_relief
      intermediary_reporting
      apprenticeship_levy
    }

    worker_types {
      name
    }

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      agency_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 */
export const getHmrcInfo = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_HMRC_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_HMRC_INFO,
      variables: {
        agency_id: agencyId,
        disabled_field_filter_options: {
          field_names: [
            "hmrc_online",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_HMRC_INFO_SUCCESS,
      payload: {
        data: {
          ...response.data.getAgency.hmrc_info,
          is_existing_paye_scheme: response.data.getAgency.is_existing_paye_scheme,
          worker_types: response.data.getAgency.worker_types,
          disabled_fields: response.data.getAgency.disabled_fields,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_HMRC_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
