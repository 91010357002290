import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../common/components";
import styles from "./ValidateProspectModal.module.scss";

export default function ValidateProspectModal(props) {
  return (
    <ConfirmationModal
      className={styles.container}
      title="Are you sure?"
      submitText="Validate"
      cancelText="Go Back"
      submitVariant={props.companyStatus !== "active" ? "danger" : "primary"}
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      {props.companyStatus !== "active" && (
        <div className={styles.warning}>
          <div className={classnames(styles.line, styles.warningTitle)}>
            This company is not active
          </div>
          <div className={styles.line}>
            {`The company is currently marked as "${props.companyStatus}". It will not be available for invoicing.`}
          </div>
        </div>
      )}
      <div className={styles.line}>
        Are you sure that the company you have selected is correct?
      </div>
      <div className={styles.line}>
        Once selected, you cannot change this.
      </div>
    </ConfirmationModal>
  );
}

ValidateProspectModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  companyStatus: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
