import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormSection.module.scss";
import formStyles from "../Form.module.scss";

export default function FormSection({
  className,
  title,
  titleClassName,
  variant,
  includeSeparator,
  rightAccessory,
  children,
  underlined,
  required,
}) {
  const titleComponent = React.createElement(variant, {
    className: styles.title,
  }, title);

  return (
    <div className={classnames(styles.container, className)}>
      <div className={classnames(
        styles.heading,
        titleClassName,
        underlined && styles.underlined
      )}>
        <div className={styles.titleContainer}>
          {titleComponent || <span />}
          {required && <span className={styles.required}>* - Required</span>}
        </div>
        {rightAccessory}
      </div>
      <div className={styles.content}>
        {children}
      </div>
      {includeSeparator && <hr className={formStyles.separator} />}
    </div>
  );
}

FormSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
  includeSeparator: PropTypes.bool,
  rightAccessory: PropTypes.element,
  underlined: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

FormSection.defaultProps = {
  variant: "h2",
  underlined: false,
  required: false,
};
