import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { onboarding } from "../../../../utils";

const SAVE_PROSPECT_ONBOARDING_TASK = gql`
mutation($input: SaveProspectOnboardingTaskInput!) {
  saveProspectOnboardingTask(input: $input) {
    progress {
      required_completed
      required_total
    }
    status
    definition {
      subtasks {
        name
        required
        field
        tracking {
          enabled
          entries {
            id
            field_name
            type
            entry
            tracked_at
            created_at
            updated_at
          }
        }
        depends_on
      }
    }
  }
}
`;

export const saveProspectOnboardingTask = ({
  prospectOnboardingTaskId,
  taskId,
  data,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.SAVE_PROSPECT_ONBOARDING_TASK_PENDING,
    });

    const fields = Object.keys(data)
      .map(key => ({
        name: key,
        value: onboarding.parseFieldValue(data[key]),
      }));

    const response = await apolloClient.mutate({
      mutation: SAVE_PROSPECT_ONBOARDING_TASK,
      variables: {
        input: {
          prospect_onboarding_task_id: prospectOnboardingTaskId,
          fields,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const { progress, status, definition } = response.data.saveProspectOnboardingTask;

    dispatch({
      type: types.SAVE_PROSPECT_ONBOARDING_TASK_SUCCESS,
      payload: {
        taskId,
        task: {
          progress,
          status,
          definition: {
            ...definition,
            subtasks: definition.subtasks.map(subtask => ({
              ...subtask,
              field: JSON.parse(subtask.field),
              depends_on: JSON.parse(subtask.depends_on || "[]"),
            })),
          },
        },
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.SAVE_PROSPECT_ONBOARDING_TASK_FAILURE,
      payload: {
        error,
      },
    });
  }
}
;
