import React from "react";
import PropTypes from "prop-types";
import styles from "./Step.module.scss";

function Step(props) {
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  complete: PropTypes.bool.isRequired,
  onValidate: PropTypes.func,
};

Step.defaultProps = {
  complete: false,
  onValidate: () => true,
};

export default Step;
