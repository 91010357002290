import gql from "graphql-tag";

export const GET_LIST = gql`
query($identifier: String!, $sort_order: [SortOrder]) {
  getList(identifier: $identifier) {
    type
    placeholder
    values(sort_order: $sort_order) {
      id
      label
      value
      weight
      is_default
    }
    nullable
  }
}
`;
