import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FormValue.module.scss";

export default function FormValue({
  className,
  size,
  children,
}) {
  return (
    <div className={classnames(
      styles.container,
      styles[size],
      className
    )}>
      {children}
    </div>
  );
}

FormValue.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
};
