import React from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";
import { Heading, Option } from "../../../common/components";
import styles from "./OnboardingLandingPage.module.scss";

export default function OnboardingLandingPage(props) {
  return (
    <div className={styles.onboardingLandingPage}>
      <Heading
        className={styles.header}
        title="Onboarding"
      />
      <div>
        <Header dividing>
          Agencies
        </Header>
        <div className={styles.options}>
          <Option
            title="View as Agency"
            subtitle="This option allows you to select a specific agency for onboarding. You will only see that agency's associated tasks."
            onClick={() => props.onOptionClick("view_as_agency")}
          />
          <Option
            title="View as Task"
            subtitle="This option will enable you to select specific tasks to complete across multiple agencies."
            onClick={() => props.onOptionClick("view_as_task")}
          />
        </div>
        <Header dividing>
          Prospects
        </Header>
        <div className={styles.options}>
          <Option
            title="View as Prospect"
            subtitle="This option allows you to select a specific prospect for onboarding. You will only see that prospect's associated tasks."
            onClick={() => props.onOptionClick("view_as_prospect")}
          />
        </div>
      </div>
    </div>
  );
}

OnboardingLandingPage.propTypes = {
  onStartNewAgencyClick: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};
