import React, { Component } from "react";
import PropTypes from "prop-types";
import { Heading, Option } from "../../common/components";
import "./UserManagementLandingPage.scss";

class UserManagementLandingPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  render() {

    return (
      <div className="UserManagementLandingPage">
        <Heading className="heading" title="User Management" />
        <div className="content">
          <Option
            className="option"
            title="Users"
            subtitle="This option allows you to create and manage individual users."
            onClick={() => this.props.history.push("/manage/users")}
          />
          <Option
            className="option"
            title="Group Management"
            subtitle="This option allows you to create and manage user permission groups that can then be assigned to individual users."
            onClick={() => this.props.history.push("/manage/groups")}
          />
        </div>
      </div>
    );
  }
}

export default UserManagementLandingPage;
