import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import Step from "./Step/Step";
import styles from "./MultiStepForm.module.scss";

class MultiStepForm extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    busy: PropTypes.bool.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
  }

  state = {
    currentStep: 0,
  }

  render() {
    const steps = _.castArray(this.props.children);
    const { busy } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.nav}>
          {steps.map((child, index) => {
            const isCurrentStep = index === this.state.currentStep;
            const isPreviousStepComplete = index === 0 || steps[index - 1].props.complete;

            return (
              <div
                key={child.props.title}
                className={classnames(
                  styles.stepNav,
                  child.props.complete && styles.stepNavComplete,
                  isCurrentStep && styles.stepNavActive,
                  !isPreviousStepComplete && styles.stepNavDisabled,
                )}
                onClick={() => this.setState({ currentStep: index })}
              >
                <div className={styles.icon}>{!child.props.complete && index+1}</div>
                <div className={styles.title}>{child.props.title}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.content}>
          {steps[this.state.currentStep]}
          <ButtonGroup className={styles.actions}>
            <Button
              variant="outline-danger"
              onClick={this.props.onCancelClick}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.handleNextStep}
              disabled={busy}
              busy={busy}
            >
              {this.isLastStep() ? "Save" : "Continue"}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }

  handleNextStep = () => {
    if (this.validateCurrentStep()) {
      if (this.isLastStep()) {
        this.props.onSaveClick();
      }
      else {
        this.setState({ currentStep: this.state.currentStep + 1 });
      }
    }
  }

  isLastStep = () => {
    return this.state.currentStep === _.castArray(this.props.children).length - 1;
  }

  validateCurrentStep = () => {
    return _.castArray(this.props.children)[this.state.currentStep].props.onValidate();
  }

}

MultiStepForm.Step = Step;

export default MultiStepForm;
