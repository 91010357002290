import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import EditBranchPage from "./EditBranchPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
  branchId: ownProps.match.params.branchId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchBranch: actions.getBranch,
  }, dispatch),
  onCancel: (prospectId) => {
    dispatch(push(`/prospects/${prospectId}/agency`));
  },
  onSubmit: ({ prospectId, branchId, data }) => {
    dispatch(actions.updateBranch({
      prospectId,
      branchId,
      data,
      redirectUrl: `/prospects/${prospectId}/agency`,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBranchPage);
