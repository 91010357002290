import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_DISABLED_FIELDS = gql`
query($agency_id: ID!, $filter_options: [String]) {
  getAgencyDisabledFields(id: $agency_id, filter_options: $filter_options) {
    disabled_field
    agency_onboarding_task_id
  }
}
`;

export const getAgencyDisabledFields = (id, filter_options) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_DISABLED_FIELDS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_DISABLED_FIELDS,
      variables: {
        agency_id: id,
        filter_options,
      },
    });

    if (!response.data.getAgencyDisabledFields) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_DISABLED_FIELDS_SUCCESS,
      payload: {
        agencyDisabledFields: response.data.getAgencyDisabledFields,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_AGENCY_DISABLED_FIELDS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
