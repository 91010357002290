import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { PaginatedResponseFormatter } from "../../../../formatters";
import { SortOrderConverter } from "../../../../converters";

const SEARCH_AGENCIES = gql`
query($pager: Pager!, $search_options: AgencySearchOptions!, $sort_order: [SortOrder]) {
  searchAgencies(pager: $pager, search_options: $search_options, sort_order: $sort_order) {
    data {
      id
      short_code
      known_as
      correspondence_name
      last_payslip_date
      created_at

      worker_types {
        name
      }

      payroll_account_manager {
        first_name
        last_name
      }

      credit_controller {
        first_name
        last_name
      }

      agency_status {
        label
      }
    }
    total
  }
}
`;

export const searchAgencies = ({
  pager: { page, limit },
  searchOptions,
  sortOrder,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.SEARCH_AGENCIES_PENDING,
    });
    const pager = {
      page: page || 1,
      limit: limit || 0,
    };
    const response = await apolloClient.query({
      query: SEARCH_AGENCIES,
      variables: {
        pager,
        search_options: searchOptions,
        sort_order: SortOrderConverter(sortOrder),
      },
    });
    const { data } = response.data.searchAgencies;
    const pagination = PaginatedResponseFormatter.format(
      response.data.searchAgencies,
      pager.page,
      pager.limit
    );

    dispatch({
      type: types.SEARCH_AGENCIES_SUCCESS,
      payload: {
        agencies: data,
        ...pagination,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.SEARCH_AGENCIES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
