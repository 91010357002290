import React, { Component } from "react";
import PropTypes from "prop-types";
import { FilterDropdown, Modal } from "../../../common/components";
import styles from "./TaskCommentModal.module.scss";

export default class TaskCommentModal extends Component {

  static propTypes = {
    visible: PropTypes.bool,
    subtasks: PropTypes.arrayOf(PropTypes.shape({
      field_name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })),
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  static defaultProps = {
    subtasks: [],
  }

  state = {
    comment: "",
    selectedSubtasks: [],
  }

  render() {
    const { visible } = this.props;
    const { comment } = this.state;

    return (
      <Modal
        title={this.renderTitle()}
        visible={visible}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        primaryButtonDisabled={!comment}
        submitText="Save">
        <textarea
          placeholder="Click to leave a comment"
          className={styles.commentTextArea}
          onChange={this.handleChange}
          name="comment"
          value={comment}
        />
      </Modal>
    );
  }

  renderTitle = () => {
    const { subtasks } = this.props;
    const { selectedSubtasks } = this.state;

    return (
      <div className={styles.header}>
        <div className={styles.title}>Leave Comment</div>
        <FilterDropdown className={styles.dropdown} placeholder="Link to subtask(s)" selectedValues={selectedSubtasks} onChange={this.handleSubtaskSelected}>
          {subtasks.map(subtask => (
            <FilterDropdown.Item key={subtask.field_name} value={subtask.field_name}>
              {subtask.label}
            </FilterDropdown.Item>
          ))}
        </FilterDropdown>
      </div>
    );
  }

  handleSubtaskSelected = ({ values }) => {
    this.setState({
      selectedSubtasks: values,
    });
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { comment, selectedSubtasks } = this.state;

    onSubmit && onSubmit({
      comment,
      subtasks: selectedSubtasks,
    });

    this.setState({
      comment: "",
      selectedSubtasks: [],
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    this.setState({
      selectedSubtasks: [],
    });

    onCancel && onCancel();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

}
