import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import { Form, IconButton, SeeHistoryTooltip } from "../../../../common/components";
import { formatAnswer } from "../../../../../utils";
import styles from "./WtrHolidaySection.module.scss";

export default function WtrHolidaySection(props) {
  return (
    <Form.Card
      className={styles.container}
      title="WTR / Holiday"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <Form.Group inline>
        <Form.Label inline>
          Holiday Pot
        </Form.Label>
        <Form.ValueInline>{formatAnswer(props.hasHolidayPot, "-")}</Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>
          Default Holiday Scheme
        </Form.Label>
        <SeeHistoryTooltip variant="compact" data={props.payrollHistory?.default_holiday_scheme}>
          <Form.ValueInline>
            {props.defaultHolidayScheme || "-"}
          </Form.ValueInline>
        </SeeHistoryTooltip>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>
          Holiday Accrual Period
        </Form.Label>
        <Form.ValueInline>
          {props.holidayAccrualPeriod || "-"}
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>
          End of Holiday Year<br />
          (Pay Period / Tax Year)
        </Form.Label>
        <SeeHistoryTooltip variant="compact" data={props.payrollHistory?.end_of_holiday_year}>
          <Form.ValueInline>
            {props.endOfHolidayYearPayPeriod && props.endOfHolidayYearTaxYearEnd
              ? `${props.endOfHolidayYearPayPeriod} ${props.endOfHolidayYearTaxYearEnd}`
              : "-"
            }
          </Form.ValueInline>
        </SeeHistoryTooltip>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>
          Carry Over Accrual
        </Form.Label>
        <Form.ValueInline>
          {formatAnswer(props.canCarryOverAccrual, "-")}
        </Form.ValueInline>
      </Form.Group>
    </Form.Card>
  );
}

WtrHolidaySection.propTypes = {
  hasHolidayPot: PropTypes.bool,
  defaultHolidayScheme: PropTypes.string,
  holidayAccrualPeriod: PropTypes.string,
  endOfHolidayYearPayPeriod: PropTypes.number,
  endOfHolidayYearTaxYearEnd: PropTypes.number,
  canCarryOverAccrual: PropTypes.bool,
  payrollHistory: PropTypes.shape({
    default_holiday_scheme: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
    end_of_holiday_year: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  onEditClick: PropTypes.func,
};
