import React from "react";
import PropTypes from "prop-types";
import { NonRegisteredLegalEntityForm } from "../../components";
import { Heading } from "../../../common/components";
import styles from "./NewNonRegisteredCompanyPage.module.scss";

export default function NewNonRegisteredCompanyPage(props) {
  const handleCancel = () => {
    props.history.push("/credit-limit-management");
  };

  const handleGoBack = () => {
    props.onGoBack(props.initialQuestions);
  };

  const handleSubmit = (formData) => {
    props.addNonRegisteredCompany({
      ...formData,
      creditLimitRequested: props.initialQuestions.estimatedCreditLimit,
      agencyId: props.initialQuestions.agency.agencyId,
      isProspect: props.initialQuestions.agency.prospect,
    });
  };

  return (
    <div className={styles.container}>
      <Heading title="Add New Non-registered Company" />
      <NonRegisteredLegalEntityForm
        onCancel={handleCancel}
        onGoBack={handleGoBack}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

NewNonRegisteredCompanyPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  initialQuestions: PropTypes.shape({
    estimatedCreditLimit: PropTypes.number.isRequired,
    agency: PropTypes.shape({
      agencyId: PropTypes.string.isRequired,
      prospect: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  onGoBack: PropTypes.func.isRequired,
  addNonRegisteredCompany: PropTypes.func.isRequired,
  addLegalEntity: PropTypes.func.isRequired,
};
