import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import PartApproveCreditApplicationModalValidator from "./PartApproveCreditApplicationModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./PartApproveCreditApplicationModal.module.scss";

export default function PartApproveCreditApplicationModal(props) {
  const [state, setState] = useState({
    approvedCreditLimit: null,
    resultDate: moment().valueOf(),
    comments: "",
  });
  const [errors, setErrors] = useState({});

  const { creditApplicationId, getCreditApplicationForApproval, legalEntityId } = props;

  useEffect(() => {
    getCreditApplicationForApproval({
      legalEntityId,
      creditApplicationId,
    });
  }, [creditApplicationId, getCreditApplicationForApproval, legalEntityId]);

  const handleSubmit = () => {
    const validator = new PartApproveCreditApplicationModalValidator(state, props);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.completeCreditApplication({
      creditApplicationId,
      amountApproved: state.approvedCreditLimit,
      notes: state.comments,
      status: "partially_accepted",
      useDiscretionaryLimit: false,
    });
    props.hideModal(PartApproveCreditApplicationModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(PartApproveCreditApplicationModal.displayName);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Part Approve Credit Application"
      visible={props.visible}
      submitText="Approve Credit Application"
      submitDisabled={props.submitting}
      busy={props.loading}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Please enter the amount of credit that has been approved for this application.
      </div>
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline wide error={errors?.approvedCreditLimit}>
            <Form.Label className={styles.label} inline required>
              Approved credit amount
            </Form.Label>
            <Form.Number
              name="approvedCreditLimit"
              value={state.approvedCreditLimit}
              size="small"
              prefix="£"
              disabled={props.submitting}
              highlightError={errors?.approvedCreditLimit}
              formatNumber
              onChange={e => setState({ ...state, approvedCreditLimit: parseInt(e.target.value, 10) })}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.resultDate}>
            <Form.Label className={styles.label} inline required>
              Date result was obtained
            </Form.Label>
            <Form.Date
              name="resultDate"
              value={state.resultDate}
              size="small"
              disabled={props.submitting}
              highlightError={errors?.resultDate}
              max={parseDate(Date.now(), "YYYY-MM-DD")}
              onChange={e => setState({ ...state, resultDate: e.target.value })}
            />
          </Form.Group>
          <Form.Group inline wide>
            <Form.Label className={styles.commentsLabel} inline>
              Comments
            </Form.Label>
            <Form.TextArea
              className={styles.textArea}
              value={state.comments}
              placeholder="Enter comments..."
              rows={6}
              maxLength={255}
              disabled={props.submitting}
              onChange={e => setState({ ...state, comments: e.target.value })}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

PartApproveCreditApplicationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  creditApplicationId: PropTypes.string.isRequired,
  legalEntityId: PropTypes.string,
  creditLimitRequested: PropTypes.number,
  getCreditApplicationForApproval: PropTypes.func.isRequired,
  completeCreditApplication: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

PartApproveCreditApplicationModal.displayName = "PartApproveCreditApplicationModal";
