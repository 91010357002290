
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { AutoEnrolmentPensionsForm } from "../../../components";
import styles from "./AutoEnrolmentPensions.module.scss";

export default class AutoEnrolmentPensions extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    autoEnrolmentPensionInfo: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
      submitting: PropTypes.bool,
    }),
    onFetchAutoEnrolmentPensionInfo: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { agencyId, onFetchAutoEnrolmentPensionInfo } = this.props;

    await onFetchAutoEnrolmentPensionInfo({ agencyId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.autoEnrolmentPensionInfo?.loading ? (
          <ActivityIndicator />
        ) : (
          <AutoEnrolmentPensionsForm
            data={this.props.autoEnrolmentPensionInfo?.data}
            submitting={this.props.autoEnrolmentPensionInfo?.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onDisabledFieldClick={this.handleDisabledFieldClick}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { agencyId, onCancel } = this.props;

    await onCancel(agencyId);
  }

  handleSubmit = async (data) => {
    const { agencyId, onSubmit } = this.props;

    await onSubmit({ agencyId, data });
  }

  handleDisabledFieldClick = async (disabledField) => {
    const { agencyId, onDisabledFieldClick } = this.props;

    await onDisabledFieldClick({ agencyId, disabledField });
  }

}
