import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Lock } from "@material-ui/icons";
import { Form, IconButton, SeeHistoryTooltip } from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./CompaniesHouseRegisteredAddressSection.module.scss";

export default function CompaniesHouseRegisteredAddressSection(props) {
  return (
    <Form.Card
      className={classnames(props.className, styles.container)}
      title="Companies House Registered Address"
      history={(
        <SeeHistoryTooltip variant="compact" data={props.revisions?.address} />
      )}
      rightAccessory={(
        <IconButton
          icon={<Lock />}
          className={styles.lockIcon}
          tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
        />
      )}
    >
      <Form.Group inline>
        <Form.Value>
          {_.chain([
            props.data?.address_line_1,
            props.data?.address_line_2,
            props.data?.locality,
            props.data?.region,
            props.data?.postal_code,
            props.data?.country,
          ]).compact().map((addressLine, index) => (
            <Form.FlexRow key={`registered_address_${index}`}>
              <Form.ValueInline>
                {addressLine}
              </Form.ValueInline>
            </Form.FlexRow>
          )).value()}
        </Form.Value>
      </Form.Group>
    </Form.Card>
  );
}

CompaniesHouseRegisteredAddressSection.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    locality: PropTypes.string,
    region: PropTypes.string,
    postal_code: PropTypes.string,
    country: PropTypes.string,
  }),
  revisions: PropTypes.shape({
    address: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
};
