export const GET_AGENCIES_PENDING = "AGENCIES/GET_AGENCIES_PENDING";
export const GET_AGENCIES_SUCCESS = "AGENCIES/GET_AGENCIES_SUCCESS";
export const GET_AGENCIES_FAILURE = "AGENCIES/GET_AGENCIES_FAILURE";
export const GET_AGENCY_PENDING = "AGENCIES/GET_AGENCY_PENDING";
export const GET_AGENCY_SUCCESS = "AGENCIES/GET_AGENCY_SUCCESS";
export const GET_AGENCY_FAILURE = "AGENCIES/GET_AGENCY_FAILURE";
export const GET_AGENCY_CONTACTS_PENDING = "AGENCIES/GET_AGENCY_CONTACTS_PENDING";
export const GET_AGENCY_CONTACTS_SUCCESS = "AGENCIES/GET_AGENCY_CONTACTS_SUCCESS";
export const GET_AGENCY_CONTACTS_FAILURE = "AGENCIES/GET_AGENCY_CONTACTS_FAILURE";
export const GET_AGENCY_HISTORY_PENDING = "AGENCIES/GET_AGENCY_HISTORY_PENDING";
export const GET_AGENCY_HISTORY_SUCCESS = "AGENCIES/GET_AGENCY_HISTORY_SUCCESS";
export const GET_AGENCY_HISTORY_FAILURE = "AGENCIES/GET_AGENCY_HISTORY_FAILURE";
export const GET_WORKER_TYPES_PENDING = "AGENCIES/GET_WORKER_TYPES_PENDING";
export const GET_WORKER_TYPES_SUCCESS = "AGENCIES/GET_WORKER_TYPES_SUCCESS";
export const GET_WORKER_TYPES_FAILURE = "AGENCIES/GET_WORKER_TYPES_FAILURE";
export const GET_COMPANY_CERT_URL_PENDING = "AGENCIES/GET_COMPANY_CERT_URL_PENDING";
export const GET_COMPANY_CERT_URL_SUCCESS = "AGENCIES/GET_COMPANY_CERT_URL_SUCCESS";
export const GET_COMPANY_CERT_URL_FAILURE = "AGENCIES/GET_COMPANY_CERT_URL_FAILURE";
export const GET_SELECTED_COMPANY_PROFILE_PENDING = "AGENCIES/GET_SELECTED_COMPANY_PROFILE_PENDING";
export const GET_SELECTED_COMPANY_PROFILE_SUCCESS = "AGENCIES/GET_SELECTED_COMPANY_PROFILE_SUCCESS";
export const GET_SELECTED_COMPANY_PROFILE_FAILURE = "AGENCIES/GET_SELECTED_COMPANY_PROFILE_FAILURE";
export const SEARCH_AGENCIES_PENDING = "AGENCIES/SEARCH_AGENCIES_PENDING";
export const SEARCH_AGENCIES_SUCCESS = "AGENCIES/SEARCH_AGENCIES_SUCCESS";
export const SEARCH_AGENCIES_FAILURE = "AGENCIES/SEARCH_AGENCIES_FAILURE";
export const DISABLE_AGENCIES_PENDING = "AGENCIES/DISABLE_AGENCIES_PENDING";
export const DISABLE_AGENCIES_SUCCESS = "AGENCIES/DISABLE_AGENCIES_SUCCESS";
export const DISABLE_AGENCIES_FAILURE = "AGENCIES/DISABLE_AGENCIES_FAILURE";
export const SAVE_AGENCY_PENDING = "AGENCIES/SAVE_AGENCY_PENDING";
export const SAVE_AGENCY_SUCCESS = "AGENCIES/SAVE_AGENCY_SUCCESS";
export const SAVE_AGENCY_FAILURE = "AGENCIES/SAVE_AGENCY_FAILURE";
export const SET_PAGINATION = "AGENCIES/SET_PAGINATION";
export const GET_COMPANY_INFO_PENDING = "AGENCIES/GET_COMPANY_INFO_PENDING";
export const GET_COMPANY_INFO_SUCCESS = "AGENCIES/GET_COMPANY_INFO_SUCCESS";
export const GET_COMPANY_INFO_FAILURE = "AGENCIES/GET_COMPANY_INFO_FAILURE";
export const GET_COMPANY_HISTORY_PENDING = "AGENCIES/GET_COMPANY_HISTORY_PENDING";
export const GET_COMPANY_HISTORY_SUCCESS = "AGENCIES/GET_COMPANY_HISTORY_SUCCESS";
export const GET_COMPANY_HISTORY_FAILURE = "AGENCIES/GET_COMPANY_HISTORY_FAILURE";
export const UPDATE_COMPANY_INFO_PENDING = "AGENCIES/UPDATE_COMPANY_INFO_PENDING";
export const UPDATE_COMPANY_INFO_SUCCESS = "AGENCIES/UPDATE_COMPANY_INFO_SUCCESS";
export const UPDATE_COMPANY_INFO_FAILURE = "AGENCIES/UPDATE_COMPANY_INFO_FAILURE";
export const GET_PAYROLL_HISTORY_PENDING = "AGENCIES/GET_PAYROLL_HISTORY_PENDING";
export const GET_PAYROLL_HISTORY_SUCCESS = "AGENCIES/GET_PAYROLL_HISTORY_SUCCESS";
export const GET_PAYROLL_HISTORY_FAILURE = "AGENCIES/GET_PAYROLL_HISTORY_FAILURE";
export const GET_PAYROLL_INFO_PENDING = "AGENCIES/GET_PAYROLL_INFO_PENDING";
export const GET_PAYROLL_INFO_SUCCESS = "AGENCIES/GET_PAYROLL_INFO_SUCCESS";
export const GET_PAYROLL_INFO_FAILURE = "AGENCIES/GET_PAYROLL_INFO_FAILURE";
export const ADD_AGENCY_CONTACT_SUCCESS = "AGENCIES/ADD_AGENCY_CONTACT_SUCCESS";
export const ADD_AGENCY_CONTACT_PENDING = "AGENCIES/ADD_AGENCY_CONTACT_PENDING";
export const ADD_AGENCY_CONTACT_FAILURE = "AGENCIES/ADD_AGENCY_CONTACT_FAILURE";
export const UPDATE_AGENCY_CONTACT_SUCCESS = "AGENCIES/UPDATE_AGENCY_CONTACT_SUCCESS";
export const UPDATE_AGENCY_CONTACT_PENDING = "AGENCIES/UPDATE_AGENCY_CONTACT_PENDING";
export const UPDATE_AGENCY_CONTACT_FAILURE = "AGENCIES/UPDATE_AGENCY_CONTACT_FAILURE";
export const ARCHIVE_AGENCY_CONTACT_SUCCESS = "AGENCIES/ARCHIVE_AGENCY_CONTACT_SUCCESS";
export const ARCHIVE_AGENCY_CONTACT_PENDING = "AGENCIES/ARCHIVE_AGENCY_CONTACT_PENDING";
export const ARCHIVE_AGENCY_CONTACT_FAILURE = "AGENCIES/ARCHIVE_AGENCY_CONTACT_FAILURE";
export const DELETE_AGENCY_CONTACT_SUCCESS = "AGENCIES/DELETE_AGENCY_CONTACT_SUCCESS";
export const DELETE_AGENCY_CONTACT_PENDING = "AGENCIES/DELETE_AGENCY_CONTACT_PENDING";
export const DELETE_AGENCY_CONTACT_FAILURE = "AGENCIES/DELETE_AGENCY_CONTACT_FAILURE";
export const GET_BACK_OFFICE_CONTACTS_SUCCESS = "AGENCIES/GET_BACK_OFFICE_CONTACTS_SUCCESS";
export const GET_BACK_OFFICE_CONTACTS_PENDING = "AGENCIES/GET_BACK_OFFICE_CONTACTS_PENDING";
export const GET_BACK_OFFICE_CONTACTS_FAILURE = "AGENCIES/GET_BACK_OFFICE_CONTACTS_FAILURE";
export const UPDATE_BACK_OFFICE_CONTACTS_SUCCESS = "AGENCIES/UPDATE_BACK_OFFICE_CONTACTS_SUCCESS";
export const UPDATE_BACK_OFFICE_CONTACTS_PENDING = "AGENCIES/UPDATE_BACK_OFFICE_CONTACTS_PENDING";
export const UPDATE_BACK_OFFICE_CONTACTS_FAILURE = "AGENCIES/UPDATE_BACK_OFFICE_CONTACTS_FAILURE";
export const GET_AGENCY_DISABLED_FIELDS_PENDING = "AGENCIES/GET_AGENCY_DISABLED_FIELDS_PENDING";
export const GET_AGENCY_DISABLED_FIELDS_SUCCESS = "AGENCIES/GET_AGENCY_DISABLED_FIELDS_SUCCESS";
export const GET_AGENCY_DISABLED_FIELDS_FAILURE = "AGENCIES/GET_AGENCY_DISABLED_FIELDS_FAILURE";
export const AGENCY_SEARCH_PENDING = "AGENCIES/AGENCY_SEARCH_PENDING";
export const AGENCY_SEARCH_SUCCESS = "AGENCIES/AGENCY_SEARCH_SUCCESS";
export const AGENCY_SEARCH_FAILURE = "AGENCIES/AGENCY_SEARCH_FAILURE";
export const AGENCY_SEARCH_CLEAR = "AGENCIES/AGENCY_SEARCH_CLEAR";

export const GET_AGENCY_MI_REPORT_GROUPINGS_PENDING = "AGENCIES/GET_AGENCY_MI_REPORT_GROUPINGS_PENDING";
export const GET_AGENCY_MI_REPORT_GROUPINGS_SUCCESS = "AGENCIES/GET_AGENCY_MI_REPORT_GROUPINGS_SUCCESS";
export const GET_AGENCY_MI_REPORT_GROUPINGS_FAILURE = "AGENCIES/GET_AGENCY_MI_REPORT_GROUPINGS_FAILURE";
export const UPDATE_AGENCY_MI_REPORT_GROUPINGS_PENDING = "AGENCIES/UPDATE_AGENCY_MI_REPORT_GROUPINGS_PENDING";
export const UPDATE_AGENCY_MI_REPORT_GROUPINGS_SUCCESS = "AGENCIES/UPDATE_AGENCY_MI_REPORT_GROUPINGS_SUCCESS";
export const UPDATE_AGENCY_MI_REPORT_GROUPINGS_FAILURE = "AGENCIES/UPDATE_AGENCY_MI_REPORT_GROUPINGS_FAILURE";

export const UPDATE_AGENCY_DETAILS_PENDING = "AGENCIES/UPDATE_AGENCY_DETAILS_PENDING";
export const UPDATE_AGENCY_DETAILS_SUCCESS = "AGENCIES/UPDATE_AGENCY_DETAILS_SUCCESS";
export const UPDATE_AGENCY_DETAILS_FAILURE = "AGENCIES/UPDATE_AGENCY_DETAILS_FAILURE";
export const UPDATE_AGENCY_CONTRACT_INFORMATION_PENDING = "AGENCIES/UPDATE_AGENCY_CONTRACT_INFORMATION_PENDING";
export const UPDATE_AGENCY_CONTRACT_INFORMATION_SUCCESS = "AGENCIES/UPDATE_AGENCY_CONTRACT_INFORMATION_SUCCESS";
export const UPDATE_AGENCY_CONTRACT_INFORMATION_FAILURE = "AGENCIES/UPDATE_AGENCY_CONTRACT_INFORMATION_FAILURE";
export const UPDATE_AGENCY_TRADING_ADDRESS_PENDING = "AGENCIES/UPDATE_AGENCY_TRADING_ADDRESS_PENDING";
export const UPDATE_AGENCY_TRADING_ADDRESS_SUCCESS = "AGENCIES/UPDATE_AGENCY_TRADING_ADDRESS_SUCCESS";
export const UPDATE_AGENCY_TRADING_ADDRESS_FAILURE = "AGENCIES/UPDATE_AGENCY_TRADING_ADDRESS_FAILURE";
export const UPDATE_AGENCY_BANK_INFORMATION_PENDING = "AGENCIES/UPDATE_AGENCY_BANK_INFORMATION_PENDING";
export const UPDATE_AGENCY_BANK_INFORMATION_SUCCESS = "AGENCIES/UPDATE_AGENCY_BANK_INFORMATION_SUCCESS";
export const UPDATE_AGENCY_BANK_INFORMATION_FAILURE = "AGENCIES/UPDATE_AGENCY_BANK_INFORMATION_FAILURE";
export const UPDATE_AGENCY_VAT_INFORMATION_PENDING = "AGENCIES/UPDATE_AGENCY_VAT_INFORMATION_PENDING";
export const UPDATE_AGENCY_VAT_INFORMATION_SUCCESS = "AGENCIES/UPDATE_AGENCY_VAT_INFORMATION_SUCCESS";
export const UPDATE_AGENCY_VAT_INFORMATION_FAILURE = "AGENCIES/UPDATE_AGENCY_VAT_INFORMATION_FAILURE";
export const UPDATE_BRANCH_PENDING = "AGENCIES/UPDATE_BRANCH_PENDING";
export const UPDATE_BRANCH_SUCCESS = "AGENCIES/UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILURE = "AGENCIES/UPDATE_BRANCH_FAILURE";

export const GET_AGENCY_DETAILS_PENDING = "AGENCIES/GET_AGENCY_DETAILS_PENDING";
export const GET_AGENCY_DETAILS_SUCCESS = "AGENCIES/GET_AGENCY_DETAILS_SUCCESS";
export const GET_AGENCY_DETAILS_FAILURE = "AGENCIES/GET_AGENCY_DETAILS_FAILURE";
export const GET_AGENCY_TRADING_ADDRESS_PENDING = "AGENCIES/GET_AGENCY_TRADING_ADDRESS_PENDING";
export const GET_AGENCY_TRADING_ADDRESS_SUCCESS = "AGENCIES/GET_AGENCY_TRADING_ADDRESS_SUCCESS";
export const GET_AGENCY_TRADING_ADDRESS_FAILURE = "AGENCIES/GET_AGENCY_TRADING_ADDRESS_FAILURE";
export const GET_AGENCY_BANK_INFORMATION_PENDING = "AGENCIES/GET_AGENCY_BANK_INFORMATION_PENDING";
export const GET_AGENCY_BANK_INFORMATION_SUCCESS = "AGENCIES/GET_AGENCY_BANK_INFORMATION_SUCCESS";
export const GET_AGENCY_BANK_INFORMATION_FAILURE = "AGENCIES/GET_AGENCY_BANK_INFORMATION_FAILURE";
export const GET_AGENCY_CONTRACT_INFORMATION_PENDING = "AGENCIES/GET_AGENCY_CONTRACT_INFORMATION_PENDING";
export const GET_AGENCY_CONTRACT_INFORMATION_SUCCESS = "AGENCIES/GET_AGENCY_CONTRACT_INFORMATION_SUCCESS";
export const GET_AGENCY_CONTRACT_INFORMATION_FAILURE = "AGENCIES/GET_AGENCY_CONTRACT_INFORMATION_FAILURE";
export const GET_AGENCY_VAT_INFORMATION_PENDING = "AGENCIES/GET_AGENCY_VAT_INFORMATION_PENDING";
export const GET_AGENCY_VAT_INFORMATION_SUCCESS = "AGENCIES/GET_AGENCY_VAT_INFORMATION_SUCCESS";
export const GET_AGENCY_VAT_INFORMATION_FAILURE = "AGENCIES/GET_AGENCY_VAT_INFORMATION_FAILURE";
export const GET_BRANCH_PENDING = "AGENCIES/GET_BRANCH_PENDING";
export const GET_BRANCH_SUCCESS = "AGENCIES/GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAILURE = "AGENCIES/GET_BRANCH_FAILURE";

export const GET_AGENCY_PREFERENCES_PENDING = "AGENCIES/GET_AGENCY_PREFERENCES_PENDING";
export const GET_AGENCY_PREFERENCES_SUCCESS = "AGENCIES/GET_AGENCY_PREFERENCES_SUCCESS";
export const GET_AGENCY_PREFERENCES_FAILURE = "AGENCIES/GET_AGENCY_PREFERENCES_FAILURE";
export const UPDATE_AGENCY_PREFERENCES_PENDING = "AGENCIES/UPDATE_AGENCY_PREFERENCES_PENDING";
export const UPDATE_AGENCY_PREFERENCES_SUCCESS = "AGENCIES/UPDATE_AGENCY_PREFERENCES_SUCCESS";
export const UPDATE_AGENCY_PREFERENCES_FAILURE = "AGENCIES/UPDATE_AGENCY_PREFERENCES_FAILURE";

export const GET_AUTO_ENROLMENT_PENSION_INFO_PENDING = "AGENCIES/GET_AUTO_ENROLMENT_PENSION_INFO_PENDING";
export const GET_AUTO_ENROLMENT_PENSION_INFO_SUCCESS = "AGENCIES/GET_AUTO_ENROLMENT_PENSION_INFO_SUCCESS";
export const GET_AUTO_ENROLMENT_PENSION_INFO_FAILURE = "AGENCIES/GET_AUTO_ENROLMENT_PENSION_INFO_FAILURE";
export const UPDATE_AUTO_ENROLMENT_PENSION_INFO_PENDING = "AGENCIES/UPDATE_AUTO_ENROLMENT_PENSION_INFO_PENDING";
export const UPDATE_AUTO_ENROLMENT_PENSION_INFO_SUCCESS = "AGENCIES/UPDATE_AUTO_ENROLMENT_PENSION_INFO_SUCCESS";
export const UPDATE_AUTO_ENROLMENT_PENSION_INFO_FAILURE = "AGENCIES/UPDATE_AUTO_ENROLMENT_PENSION_INFO_FAILURE";

export const GET_HMRC_INFO_PENDING = "AGENCIES/GET_HMRC_INFO_PENDING";
export const GET_HMRC_INFO_SUCCESS = "AGENCIES/GET_HMRC_INFO_SUCCESS";
export const GET_HMRC_INFO_FAILURE = "AGENCIES/GET_HMRC_INFO_FAILURE";
export const UPDATE_HMRC_INFO_PENDING = "AGENCIES/UPDATE_HMRC_INFO_PENDING";
export const UPDATE_HMRC_INFO_SUCCESS = "AGENCIES/UPDATE_HMRC_INFO_SUCCESS";
export const UPDATE_HMRC_INFO_FAILURE = "AGENCIES/UPDATE_HMRC_INFO_FAILURE";

export const GET_PAYROLL_GENERAL_PENDING = "AGENCIES/GET_PAYROLL_GENERAL_PENDING";
export const GET_PAYROLL_GENERAL_SUCCESS = "AGENCIES/GET_PAYROLL_GENERAL_SUCCESS";
export const GET_PAYROLL_GENERAL_FAILURE = "AGENCIES/GET_PAYROLL_GENERAL_FAILURE";
export const UPDATE_PAYROLL_GENERAL_PENDING = "AGENCIES/UPDATE_PAYROLL_GENERAL_PENDING";
export const UPDATE_PAYROLL_GENERAL_SUCCESS = "AGENCIES/UPDATE_PAYROLL_GENERAL_SUCCESS";
export const UPDATE_PAYROLL_GENERAL_FAILURE = "AGENCIES/UPDATE_PAYROLL_GENERAL_FAILURE";

export const GET_HOLIDAY_PAY_INFO_PENDING = "AGENCIES/GET_HOLIDAY_PAY_INFO_PENDING";
export const GET_HOLIDAY_PAY_INFO_SUCCESS = "AGENCIES/GET_HOLIDAY_PAY_INFO_SUCCESS";
export const GET_HOLIDAY_PAY_INFO_FAILURE = "AGENCIES/GET_HOLIDAY_PAY_INFO_FAILURE";
export const UPDATE_HOLIDAY_PAY_INFO_PENDING = "AGENCIES/UPDATE_HOLIDAY_PAY_INFO_PENDING";
export const UPDATE_HOLIDAY_PAY_INFO_SUCCESS = "AGENCIES/UPDATE_HOLIDAY_PAY_INFO_SUCCESS";
export const UPDATE_HOLIDAY_PAY_INFO_FAILURE = "AGENCIES/UPDATE_HOLIDAY_PAY_INFO_FAILURE";

export const VALIDATE_PAYROLL_TRANSFER_PENDING = "AGENCIES/VALIDATE_PAYROLL_TRANSFER_PENDING";
export const VALIDATE_PAYROLL_TRANSFER_SUCCESS = "AGENCIES/VALIDATE_PAYROLL_TRANSFER_SUCCESS";
export const VALIDATE_PAYROLL_TRANSFER_FAILURE = "AGENCIES/VALIDATE_PAYROLL_TRANSFER_FAILURE";

export const GET_TRANSFERS_PENDING = "AGENCIES/GET_TRANSFERS_PENDING";
export const GET_TRANSFERS_SUCCESS = "AGENCIES/GET_TRANSFERS_SUCCESS";
export const GET_TRANSFERS_FAILURE = "AGENCIES/GET_TRANSFERS_FAILURE";

export const INITIATE_AGENCY_TRANSFER_PENDING = "AGENCIES/INITIATE_AGENCY_TRANSFER_PENDING";
export const INITIATE_AGENCY_TRANSFER_SUCCESS = "AGENCIES/INITIATE_AGENCY_TRANSFER_SUCCESS";
export const INITIATE_AGENCY_TRANSFER_FAILURE = "AGENCIES/INITIATE_AGENCY_TRANSFER_FAILURE";

export const GET_AGENCY_TRANSFER_INFORMATION_PENDING = "AGENCIES/GET_AGENCY_TRANSFER_INFORMATION_PENDING";
export const GET_AGENCY_TRANSFER_INFORMATION_SUCCESS = "AGENCIES/GET_AGENCY_TRANSFER_INFORMATION_SUCCESS";
export const GET_AGENCY_TRANSFER_INFORMATION_FAILURE = "AGENCIES/GET_AGENCY_TRANSFER_INFORMATION_FAILURE";
