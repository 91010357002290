import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const EXPORT_AGENCIES = gql`
mutation($options: AgencyExportOptions) {
  exportAgencies(options: $options) {
    export_id
    export_type
    status
    created_at
    created_by {
      first_name
      last_name
    }
    error
    url
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string[]} obj.agencyIds
 * @returns
 */
export const exportAgencies = ({ agencyIds }) => async (dispatch) => {
  try {
    dispatch({
      type: types.EXPORT_AGENCIES_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: EXPORT_AGENCIES,
      variables: {
        options: {
          agency_ids: agencyIds,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.EXPORT_AGENCIES_SUCCESS,
      payload: {
        data: response.data.exportAgencies,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.EXPORT_AGENCIES_FAILURE,
      payload: {
        error,
      },
    });
  }
};
