import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Icon } from "semantic-ui-react";
import LinkDropdownItem from "./LinkDropdownItem/LinkDropdownItem";
import styles from "./LinkDropdown.module.scss";

export default function LinkDropdown({
  text,
  children,
  ...otherProps
}) {
  return (
    <Dropdown
      className={styles.container}
      icon={(
        <Icon className={styles.icon} name="dropdown" />
      )}
      trigger={(
        <span className={styles.text}>{text}</span>
      )}
      {...otherProps}
    >
      <Dropdown.Menu className={styles.menu}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}

LinkDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.any,
};

LinkDropdown.Item = LinkDropdownItem;
