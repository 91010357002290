import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import MIReportGroup from "./MIReportGroup/MIReportGroup";
import MIReportGroupItem from "./MIReportGroupItem/MIReportGroupItem";
import MIReportGroupingFormValidator from "./MIReportGroupingFormValidator";
import { Alert, Button, ButtonGroup, DisabledFieldLock, Form } from "../../../common/components";
import { List } from "../../../lists/containers";
import { tooltips } from "../../../../constants";
import styles from "./MIReportGroupingForm.module.scss";

export default class MIReportGroupingForm extends Component {

  static propTypes = {
    readOnly: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      mi_report_grouping_required: PropTypes.bool.isRequired,
      mi_report_groupings: PropTypes.arrayOf(PropTypes.shape({
        mi_report_grouping: PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }).isRequired,
        active: PropTypes.bool.isRequired,
        values: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          value: PropTypes.string,
        })).isRequired,
      })).isRequired,
    }).isRequired,
    agencyDisabledFields: PropTypes.arrayOf(PropTypes.shape({
      agency_onboarding_task_id: PropTypes.string.isRequired,
      disabled_field: PropTypes.string.isRequired,
    })).isRequired,
    submitting: PropTypes.bool,
    onLoad: PropTypes.func,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onDisabledFieldClicked: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        mi_report_grouping_required: false,
        mi_report_groupings: [],
        ...props.data,
      },
      newGroups: [],
      newItems: [],
      errors: {},
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;

    onLoad && onLoad(
      Object.keys(this.state.data)
    );
  }

  render() {
    const isDisabled = this.isFieldDisabled("mi_report_grouping_required");
    const activeGroupings = this.state.data.mi_report_groupings.filter(grouping => grouping.active);

    return (
      <div className={styles.container}>
        <ButtonGroup className={styles.buttons}>
          {this.props.readOnly
            ? (
              <Button variant="secondary" onClick={this.handleEditClicked}>
                Edit Details
              </Button>
            ) : (
              <>
                <Button
                  className={styles.button}
                  variant="primary"
                  busy={this.props.submitting}
                  disabled={this.props.submitting || isDisabled}
                  onClick={this.handleSaveClick}
                >
                  Save
                </Button>
                <Button
                  className={styles.button}
                  variant="secondary"
                  disabled={this.props.submitting}
                  onClick={this.handleCancelClick}
                >
                  Cancel
                </Button>
              </>
            )
          }
        </ButtonGroup>
        <Form className={styles.form}>
          {isDisabled && (
            <Alert className={styles.onboardingAlert} variant="info" showClose={false}>
              <div className={styles.onboardingAlertContent}>
                <DisabledFieldLock
                  className={styles.lockIcon}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  onClick={() => this.handleDisabledFieldClicked("mi_report_grouping_required")}
                />
              The Onboarding Task for this has not been completed yet.
              </div>
            </Alert>
          )}
          <Form.Section
            className={classnames(isDisabled && styles.disabled)}
            title="MI Report Grouping"
          >
            <Form.Group>
              <Form.Label>Is MI Report Grouping Required?</Form.Label>
              {this.props.readOnly
                ? (
                  <Form.Input
                    value={this.state.data.mi_report_grouping_required ? "Yes" : "No"}
                    readOnly
                  />
                ) : (
                  <Form.FlexRow>
                    <Form.Radio
                      className={styles.inlineRadio}
                      name="mi_report_grouping_required"
                      text="Yes"
                      value={true}
                      onChange={this.handleRadioButtonChange}
                      checked={this.state.data.mi_report_grouping_required}
                    />
                    <Form.Radio
                      className={styles.inlineRadio}
                      name="mi_report_grouping_required"
                      text="No"
                      value={false}
                      onChange={this.handleRadioButtonChange}
                      checked={!this.state.data.mi_report_grouping_required}
                    />
                  </Form.FlexRow>
                )
              }
            </Form.Group>
            <Form.Group>
              <Form.Label>MI Split by:</Form.Label>
              <Form.Value>
                <List
                  identifier="mi_report_groupings"
                  value={activeGroupings.map(grouping => grouping.mi_report_grouping)}
                  disabled={!this.state.data.mi_report_grouping_required}
                  readOnly={this.props.readOnly}
                  onChange={this.handleReportGroupingsChange}
                />
              </Form.Value>
            </Form.Group>
            {activeGroupings.length > 0 && (
              <Form.Group className={styles.groupings}>
                {this.renderReportGroups()}
              </Form.Group>
            )}
          </Form.Section>
        </Form>
      </div>
    );
  }

  renderReportGroups = () => {
    return this.state.data.mi_report_groupings.map((grouping, groupIndex) => grouping.active && (
      <MIReportGroup
        key={groupIndex}
        title={grouping.mi_report_grouping.label}
        items={grouping.values}
        disabled={!this.state.data.mi_report_grouping_required}
        readOnly={this.props.readOnly}
        onAdd={() => this.handleAddReportGroupItem(groupIndex)}
      >
        {grouping.values.map(({ value }, itemIndex) => (
          <MIReportGroupItem
            key={this.createNewItemKey(groupIndex, itemIndex)}
            value={value}
            error={this.state.errors[this.createNewItemKey(groupIndex, itemIndex)]}
            disabled={!this.state.data.mi_report_grouping_required}
            readOnly={this.props.readOnly}
            canRemove={this.checkCanRemoveItem(groupIndex, itemIndex)}
            onChange={e => this.handleReportGroupItemChange(groupIndex, itemIndex, e.target.value)}
            onRemove={() => this.handleRemoveReportGroupItem(groupIndex, itemIndex)}
          />
        ))}
      </MIReportGroup>
    ));
  }

  handleRadioButtonChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value === "true",
      },
    });
  }

  handleReportGroupingsChange = (_value, item) => {
    const newData = { ...this.state.data };
    const newGroups = [ ...this.state.newGroups ];
    const index = newData.mi_report_groupings.findIndex(x => x.mi_report_grouping.id === item.id);

    if (this.state.newGroups.includes(index)) {
      // This ensures we can only remove groupings that have just been added
      newData.mi_report_groupings[index].active = !newData.mi_report_groupings[index].active;
    }
    else if (index >= 0) {
      // Track the fact that this is a newly activated grouping
      if (!newData.mi_report_groupings[index].active) {
        newGroups.push(index);
      }

      newData.mi_report_groupings[index].active = true;
    }
    else {
      newData.mi_report_groupings.push({
        mi_report_grouping: item,
        values: [],
        active: true,
      });
      // Track the fact that this is a newly activated grouping
      newGroups.push(newData.mi_report_groupings.length - 1);
    }

    this.setState({
      data: newData,
      newGroups,
    });
  }

  handleAddReportGroupItem = (groupIndex) => {
    const newData = { ...this.state.data };

    newData.mi_report_groupings[groupIndex].values.push({ value: "" });

    // We also need to track that this is a new item so that we can remove it
    this.setState({
      data: newData,
      newItems: [
        ...this.state.newItems,
        this.createNewItemKey(groupIndex, newData.mi_report_groupings[groupIndex].values.length - 1),
      ],
    });
  }

  handleRemoveReportGroupItem = (groupIndex, itemIndex) => {
    const newData = { ...this.state.data };

    newData.mi_report_groupings[groupIndex].values.splice(itemIndex, 1);

    this.setState({
      data: newData,
    });
  }

  handleReportGroupItemChange = (groupIndex, itemIndex, value) => {
    const newData = { ...this.state.data };

    newData.mi_report_groupings[groupIndex].values[itemIndex].value = value;

    this.setState({
      data: newData,
    });
  }

  handleEditClicked = () => {
    const { onEdit } = this.props;

    onEdit && onEdit();
  }

  handleCancelClick = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSaveClick = () => {
    const { onSubmit } = this.props;

    const validator = new MIReportGroupingFormValidator(this.state.data);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) return;

    onSubmit && onSubmit(this.state.data);
  }

  handleDisabledFieldClicked = (field) => {
    const { agencyDisabledFields, onDisabledFieldClicked } = this.props;

    const agencyDisabledField = agencyDisabledFields.find(item => item.disabled_field === field);

    onDisabledFieldClicked && onDisabledFieldClicked(agencyDisabledField);
  }

  checkCanRemoveItem = (groupIndex, itemIndex) => {
    const key = this.createNewItemKey(groupIndex, itemIndex);

    return this.state.newItems.includes(key);
  }

  createNewItemKey = (groupIndex, itemIndex) => {
    return `${groupIndex}_${itemIndex}`;
  }

  isEditDisabled = () => {
    return this.isFieldDisabled("mi_report_grouping_required");
  }

  isFieldDisabled = (field) => {
    const { agencyDisabledFields, readOnly } = this.props;

    return !readOnly && agencyDisabledFields?.some(item => (
      item.disabled_field === field
    ));
  }
}
