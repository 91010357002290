import _ from "lodash";

export default class InitialQuestionsFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isEmpty(data?.agency?.agencyId)) {
      errors = _.set(errors, "agencyId", "Agency is required");
    }

    if (!data?.estimatedCreditLimit || data?.estimatedCreditLimit === 0) {
      errors = _.set(errors, "estimatedCreditLimit", "Estimated Credit Limit is required");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
