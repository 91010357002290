import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../../common/components";
import { toMoney } from "../../../../../utils";
import styles from "./SuccessModal.module.scss";

export default function SuccessModal(props) {
  return (
    <ConfirmationModal
      className={classnames(styles.container, props.className)}
      title="Success!"
      submitText="Communicate"
      cancelText="Communicate later"
      cancelVariant="outline-primary"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      An Experian credit check on {props.legalEntityName} has been completed{" "}
      and <strong>{toMoney(props.reservedCredit)}</strong> has been successfully{" "}
      reserved for {props.agencyName}
    </ConfirmationModal>
  );
}

SuccessModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  agencyName: PropTypes.string.isRequired,
  legalEntityName: PropTypes.string.isRequired,
  reservedCredit: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
