import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const INITIATE_AGENCY_TRANSFER = gql`
mutation(
  $agency_id: ID!
) {
  initiateAgencyTransfer(agency_id: $agency_id) {
    transfer_id
    transfer_type
    agency_data
    status
    error
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 */
export const initiateAgencyTransfer = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.INITIATE_AGENCY_TRANSFER_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: INITIATE_AGENCY_TRANSFER,
      variables: {
        agency_id: agencyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.INITIATE_AGENCY_TRANSFER_SUCCESS,
      payload: {
        data: response.data.initiateAgencyTransfer,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.INITIATE_AGENCY_TRANSFER_FAILURE,
      payload: {
        error,
      },
    });
  }
};
