import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Checkbox from "../Checkbox";
import Table from "../Table";
import "./PermissionList.scss";

export default class PermissionList extends PureComponent {

  static propTypes = {
    availablePermissions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })).isRequired,
    selectedPermissions: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    onSelectionChanged: PropTypes.func,
    readOnly: PropTypes.bool,
  }

  render() {
    const { availablePermissions, selectedPermissions, readOnly } = this.props;

    return (
      <Table
        className="PermissionList"
        columns={[
          {
            className: "name",
            name: "Name",
            value: row => row.name,
          },
          {
            className: "description",
            name: "Description",
            value: row => row.description,
          },
          {
            className: "collapsed",
            name: "",
            value: row => <Checkbox checked={(selectedPermissions || []).includes(row.id)} onClick={this.handleCheckboxClicked.bind(this, row)} disabled={readOnly} />,
          },
        ]}
        data={availablePermissions}
      />
    );
  }

  handleCheckboxClicked = (permission) => {
    this.props.onSelectionChanged && this.props.onSelectionChanged(permission);
  };

}
