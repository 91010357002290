import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ButtonGroup.module.scss";

export default function ButtonGroup(props) {
  return (
    <div className={classnames(styles.container, props.className)}>
      {props.children}
    </div>
  );
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

