import { ChaseCreditApplicationModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";
import { chaseCreditApplication } from "./";

/**
 *
 * @param {object} obj
 * @param {string} obj.creditApplicationId
 * @param {string} obj.chasedBy
 */
export const showChaseCreditApplicationModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(ChaseCreditApplicationModal.displayName, {
    onCancel: () => dispatch(modalActions.hideModal(ChaseCreditApplicationModal.displayName)),
    onSubmit: (formData) => {
      dispatch(chaseCreditApplication({
        name: formData.name,
        creditApplicationId: obj.creditApplicationId,
        dateOfChase: formData.dateOfChase,
        methodOfCommunicationId: formData.contactMethod.id,
        comments: formData.comments,
        chasedBy: obj.chasedBy,
      }));

      dispatch(modalActions.hideModal(ChaseCreditApplicationModal.displayName));
    },
  }));
};
