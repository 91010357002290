import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_USER = gql`
query($id: ID!) {
  getUser(id: $id) {
    id
    cognito_username
    type
    status
    first_name
    last_name
    email
    created_at
    updated_at
    user_type {
      id
    }
    user_groups {
      id
    }
    department {
      id
      name
    }
  }
}
`;

export const getUser = id => async (dispatch) => {
  try {
    dispatch({ type: types.GET_USER_PENDING });

    const response = await apolloClient.query({
      query: GET_USER,
      variables: {
        id,
      },
    });

    dispatch({
      type: types.GET_USER_SUCCESS,
      payload: {
        user: response.data.getUser,
      },
    });
  }
  catch (err) {
    dispatch({ type: types.GET_USER_FAILURE, payload: { error: err } });
  }
};
