import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import {
  Button,
  Heading,
} from "../../../common/components";
import {
  AccountContactDetails,
  ContractDocumentDetails,
  ContractPaymentDetails,
  InvoiceContactDetails,
} from "../../components";
import styles from "./ViewContractPage.module.scss";

export default function ViewContractPage(props) {
  const { contractId, getContract, agencyCustomerId } = props;
  const { agency, prospect } = props.contract.data?.agency_customer || {};
  const { reference } = props.contract.data?.customer_account_reference || {};

  useEffect(() => {
    getContract({
      agencyCustomerId,
      contractId,
    });
  }, [contractId, getContract, agencyCustomerId]);

  const getCaption = () => {
    let caption = "";

    if (agency) {
      caption += agency.known_as;
    }
    if (prospect) {
      caption += <>{prospect.known_as} <strong>(Prospect)</strong></>;
    }

    if (reference) {
      caption += " | " + reference;
    }

    return caption;
  };

  if (props.contract.loading) {
    return <ActivityIndicator />;
  }

  return (
    <div>
      <Heading
        title={props.contract.data?.display_name} //! Currently this is missing from the input form
        caption={getCaption()}
        hideSeparator
        rightAccessory={
          <div className={styles.buttonContainer}>
            {agency && !reference && (
              <Button
                variant="primary"
                // onClick={() => props.showAddAccountReferenceModal({
                //   payrollAgencyCustomerRequestId: props.agencyCustomerId,
                //   customerAccountId: props.contract.data?.id,
                //   shortCode: agency.short_code,
                // })}
              >
                Add Account Reference
              </Button>
            )}
            <Button
              variant="outline-primary"
              onClick={() => props.navigateToCustomerProfile(agencyCustomerId)}
            >
              Back to Customer Profile
            </Button>
          </div>
        }
      />
      <hr className={styles.separator} />
      <div className={styles.cardRow}>
        <AccountContactDetails
          accountContact={props.contract.data?.account_contact}
          onEditClick={() =>
            props.showEditAccountContactDetailsModal({
              customerAccountId: contractId,
              payrollAgencyCustomerRequestId: agencyCustomerId,
            })
          }
        />
        <InvoiceContactDetails
          invoiceContact={props.contract.data?.invoice_contact}
        />
      </div>
      <div className={styles.cardRow}>
        <ContractPaymentDetails
          account={props.contract.data}
          onEditClick={() => props.showEditPaymentDetailsModal({
            customerAccountId: contractId,
            payrollAgencyCustomerRequestId: agencyCustomerId,
          })}
        />
        <ContractDocumentDetails account={props.contract.data} />
      </div>
    </div>
  );
}

ViewContractPage.propTypes = {
  contract: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
      account_contact: PropTypes.object.isRequired,
      invoice_contact: PropTypes.object,
      customer_account_reference: PropTypes.string,
      agency_customer: PropTypes.shape({
        agency: PropTypes.shape({
          known_as: PropTypes.string.isRequired,
          short_code: PropTypes.string.isRequired,
        }),
        prospect: PropTypes.shape({
          known_as: PropTypes.string.isRequired,
          short_code: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }),
  }).isRequired,
  contractId: PropTypes.string.isRequired,
  agencyCustomerId: PropTypes.string.isRequired,
  navigateToCustomerProfile: PropTypes.func.isRequired,
  getContract: PropTypes.func.isRequired,
  showAddAccountReferenceModal: PropTypes.func.isRequired,
  showEditAccountContactDetailsModal: PropTypes.func.isRequired,
  showEditPaymentDetailsModal: PropTypes.func.isRequired,
};
