import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { AddCircleOutline } from "@material-ui/icons";
import { actions } from "../duck";
import { Breadcrumb, Heading, IconButton, Option } from "../../common/components";
import "./GroupManagementLandingPage.scss";

class GroupManagementLandingPage extends Component {

  static propTypes = {
    getUserGroups: PropTypes.func.isRequired,
    userGroups: PropTypes.array.isRequired,
    gotoNewUserGroup: PropTypes.func.isRequired,
    gotoEditUserGroup: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getUserGroups();
  }

  render() {

    return (
      <div className="GroupManagementLandingPage">
        <Breadcrumb className="header-breadcrumb">
          <Breadcrumb.Item link="/manage" text="User Management" />
          <Breadcrumb.Item active="true" link="/manage/groups" text="Group Management" />
        </Breadcrumb>
        <Heading
          className="heading"
          title="Group Management"
          caption="You can view and edit your permissions for different roles that help manage your account."
          rightAccessory={<IconButton text="Create New Group" icon={<AddCircleOutline />} onClick={this.handleNewUserGroupClicked} />}
        />
        <div className="content">
          {this.props.userGroups.map(value => (
            <Option
              key={value.id}
              title={value.name}
              subtitle={value.description}
              onClick={this.handleUserGroupClicked.bind(this, value)}
              linkText="Edit Permissions"
            />
          ))}
        </div>
      </div>
    );
  }

  handleNewUserGroupClicked = () => {
    this.props.gotoNewUserGroup();
  }

  handleUserGroupClicked = (userGroup) => {
    this.props.gotoEditUserGroup(userGroup.id);
  }
}

const mapStateToProps = state => ({
  ...state.groupManagement,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupManagementLandingPage);
