import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table2 } from "../../../common/components";
import { parseDate } from "../../../../utils";
import "./AgencyHistoryList.scss";

export default class AgencyHistoryList extends PureComponent {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    revisions: PropTypes.arrayOf(PropTypes.shape({
      revision: PropTypes.string.isRequired,
      operation: PropTypes.string.isRequired,
      revision_changes: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string.isRequired,
        old_value: PropTypes.any,
        new_value: PropTypes.any,
      })).isRequired,
      created_at: PropTypes.number.isRequired,
    })).isRequired,
  }

  render() {
    const { loading, revisions } = this.props;

    return (
      <Table2
        className="AgencyHistoryList"
        loading={loading}
        data={revisions}
      >
        <Table2.Column
          collapsed
          name="revision"
          value={row => row.revision}
        >
          Revision
        </Table2.Column>
        <Table2.Column
          collapsed
          name="operation"
          value={row => row.operation}
        >
          Operation
        </Table2.Column>
        <Table2.Column
          name="path"
          value={row => row.path}
        >
          Property
        </Table2.Column>
        <Table2.Column
          name="old_value"
          value={row => row.old_value}
        >
          Old Value
        </Table2.Column>
        <Table2.Column
          name="new_value"
          value={row => row.new_value}
        >
          New Value
        </Table2.Column>
        <Table2.Column
          collapsed
          name="created_at"
          value={row => parseDate(row.created_at)}
        >
          Created At
        </Table2.Column>
      </Table2>
    );
  }

}
