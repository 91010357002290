import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./SuspensionConfirmationModal.module.scss";

export default function SuspensionConfirmationModal(props) {
  const text = props.isSuspending
    ? `Are you sure you want to suspend ${props.legalEntityName}?`
    : `Are you sure you want to unsuspend ${props.legalEntityName}?`;

  return (
    <ConfirmationModal
      className={styles.container}
      title="Are you sure?"
      submitText={props.isSuspending ? "Suspend" : "Unsuspend"}
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      {text}
    </ConfirmationModal>
  );
}

SuspensionConfirmationModal.propTypes = {
  isSuspending: PropTypes.bool,
  legalEntityName: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
