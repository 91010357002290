import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  ConfirmationModal,
  Form,
} from "../../../common/components";
import { isValidTelephone } from "utils";
import styles from "./NonRegisteredLegalEntityForm.module.scss";
import NonRegisteredLegalEntityFormValidator from "./NonRegisteredLegalEntityFormValidator";

export default function NonRegisteredLegalEntityForm(props) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formData, setFormData] = useState({
    isCharity: false,
  });
  const [errors, setErrors] = useState({});

  const handleFormChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });

  const handleRadioChange = e => setFormData({
    ...formData,
    [e.target.name]: String(e.target.value) === "true",
  });

  const handleTelephoneChange = (e) => {
    if (!isValidTelephone(e.target.value)) {
      return;
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const canComplete = () => {
    if (!formData.name) {
      return false;
    }
    if (!formData.addressLine1) {
      return false;
    }
    if (!formData.townOrCity) {
      return false;
    }
    if (!formData.county) {
      return false;
    }
    if (!formData.postcode) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const validator = new NonRegisteredLegalEntityFormValidator(formData);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }
    setShowConfirmationModal(true);
  };

  const handleConfirm = () => {
    setShowConfirmationModal(false);
    props.onSubmit(formData);
  };

  return (
    <>
      <Form className={styles.form} requiredKey>
        <Form.Section
          title="Company Details"
          size="large"
        >
          <Form.Group inline>
            <Form.Label inline required>Business Name</Form.Label>
            <Form.Input
              name="name"
              type="text"
              value={formData.name}
              placeholder="Enter Business Name..."
              size="large"
              onChange={handleFormChange}
              maxLength={255}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label inline required>Address Line 1</Form.Label>
            <Form.Input
              name="addressLine1"
              type="text"
              value={formData.addressLine1}
              placeholder="Address Line 1..."
              size="large"
              onChange={handleFormChange}
              maxLength={255}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label inline>Address Line 2</Form.Label>
            <Form.Input
              name="addressLine2"
              type="text"
              value={formData.addressLine2}
              placeholder="Address Line 2..."
              size="large"
              onChange={handleFormChange}
              maxLength={255}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label inline required>Town/City</Form.Label>
            <Form.Input
              name="townOrCity"
              type="text"
              value={formData.townOrCity}
              placeholder="Town or City..."
              size="large"
              onChange={handleFormChange}
              maxLength={255}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label inline required>County</Form.Label>
            <Form.Input
              name="county"
              type="text"
              value={formData.county}
              placeholder="County..."
              size="large"
              onChange={handleFormChange}
              maxLength={255}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label inline required>Postcode</Form.Label>
            <Form.Input
              name="postcode"
              type="text"
              value={formData.postcode}
              placeholder="Postcode..."
              size="small"
              onChange={handleFormChange}
              maxLength={8}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label inline required>Is the company a charity?</Form.Label>
            <Form.RadioContainer>
              <Form.Radio
                value={true}
                name="isRegisteredCharity"
                checked={formData.isRegisteredCharity}
                text="Yes"
                onChange={handleRadioChange}
              />
              <Form.Radio
                value={false}
                name="isRegisteredCharity"
                checked={!formData.isRegisteredCharity}
                text="No"
                onChange={handleRadioChange}
              />
            </Form.RadioContainer>
          </Form.Group>
        </Form.Section>

        {formData.isRegisteredCharity && (
          <Form.Group inline>
            <Form.Label inline>Charity Number</Form.Label>
            <Form.Input
              name="registeredCharityNumber"
              type="text"
              value={formData.registeredCharityNumber}
              placeholder="Enter Charity Number..."
              size="small"
              onChange={handleFormChange}
              maxLength={100}
            />
          </Form.Group>
        )}

        <Form.Section title="Communication">
          <Form.Group inline error={errors.telephone}>
            <Form.Label inline>Telephone Number</Form.Label>
            <Form.Input
              name="telephone"
              type="text"
              value={formData.telephone}
              placeholder="Enter Phone Number..."
              size="small"
              onChange={handleTelephoneChange}
              highlightError={errors.telephone}
              maxLength={30}
            />
          </Form.Group>

          <Form.Group inline error={errors.emailAddress}>
            <Form.Label inline>Email Address</Form.Label>
            <Form.Input
              name="emailAddress"
              type="email"
              value={formData.emailAddress}
              placeholder="Enter Email Address..."
              size="large"
              onChange={handleFormChange}
              highlightError={errors.emailAddress}
              maxLength={255}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label inline>Website</Form.Label>
            <Form.Input
              name="website"
              type="text"
              value={formData.website}
              placeholder="Website..."
              size="large"
              onChange={handleFormChange}
              maxLength={255}
            />
          </Form.Group>
        </Form.Section>
      </Form>

      <hr className={styles.separator} />

      <ButtonGroup className={styles.buttonContainer}>
        <Button
          className={styles.secondaryButton}
          variant="outline-danger"
          onClick={props.onCancel}
        >
            Cancel
        </Button>
        <div>
          <Button
            className={styles.secondaryButton}
            variant="outline-primary"
            onClick={props.onGoBack}
          >
              Go Back
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!canComplete()}
          >
              Complete
          </Button>
        </div>
      </ButtonGroup>

      {showConfirmationModal  && (
        <ConfirmationModal
          title="Are you sure?"
          className={styles.confirmationModal}
          visible
          submitText="Proceed to Credit Check"
          onSubmit={handleConfirm}
          cancelText="Go Back"
          onCancel={() => setShowConfirmationModal(false)}
        >
          <div>Are you sure that the information you have entered is correct? </div>
        </ConfirmationModal>
      )}
    </>
  );
}

NonRegisteredLegalEntityForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
};
