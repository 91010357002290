import gql from "graphql-tag";
import * as types from "../../../documents/duck/types";
import { apolloClient } from "../../../../apolloClient";

const DOWNLOAD_EXPERIAN_CREDIT_CHECK_DOCUMENT = gql`
  query(
    $legal_entity_id: ID!
    $experian_credit_check_id: ID!
  ) {
    getLegalEntity(id: $legal_entity_id) {
      ...on RegisteredCompany {
        experian_credit_check(id: $experian_credit_check_id) {
          download_url {
            url
          }
        }
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.experianCreditCheckId
 * @param {string} obj.versionId (optional)
 * @returns
 */
export const downloadExperianCreditCheckDocument = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_PENDING,
    });

    const response = await apolloClient.query({
      query: DOWNLOAD_EXPERIAN_CREDIT_CHECK_DOCUMENT,
      variables: {
        legal_entity_id: obj.legalEntityId,
        experian_credit_check_id: obj.experianCreditCheckId,
        version_id: obj.versionId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    if (!response.data.getLegalEntity?.experian_credit_check?.download_url) {
      throw new Error("Failed to get download URL for Experian Credit Check");
    }

    window.open(response.data.getLegalEntity.experian_credit_check.download_url.url, "_blank");

    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_SUCCESS,
    });
  }
  catch (err) {
    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
