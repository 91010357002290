import { bytesToGigabytes } from "../../../../utils";

export default class ExperianCreditCheckForAgencyModalValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (data?.dateOfResult > new Date().setUTCHours(24, 0, 0, 0)) {
      errors.dateOfResult = "Cannot select a date in the future";
    }

    if (data?.companyFound && !data?.experianCreditReport) {
      errors.experianCreditReport = "Experian Credit Report is required";
    }

    if (bytesToGigabytes(data?.experianCreditReport?.size) > 5) {
      errors.experianCreditReport = "File size is too large. Must be smaller than 5GB";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
