import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { PayrollGeneralForm } from "../../../components";
import styles from "./PayrollGeneral.module.scss";

export default class PayrollGeneral extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    payrollGeneral: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        workerTypes: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
        })),
      }),
      submitting: PropTypes.bool,
    }),
    onFetchPayrollGeneral: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { agencyId, onFetchPayrollGeneral } = this.props;

    await onFetchPayrollGeneral({ agencyId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.payrollGeneral?.loading ? (
          <ActivityIndicator />
        ) : (
          <PayrollGeneralForm
            data={this.props.payrollGeneral?.data}
            submitting={this.props.payrollGeneral?.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { agencyId, onCancel } = this.props;

    await onCancel(agencyId);
  }

  handleSubmit = async (data) => {
    const { agencyId, onSubmit } = this.props;

    await onSubmit({ agencyId, data });
  }

}
