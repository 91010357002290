import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PROSPECT_MI_REPORT_GROUPINGS = gql`
query($prospect_id: ID!) {
  getProspect(id: $prospect_id) {
    mi_report_grouping_required

    mi_report_groupings {
      active
      mi_report_grouping {
        id
        label
        value
      }
      values {
        id
        value
      }
    }
  }
}
`;

export const getProspectMIReportGroupings = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECT_MI_REPORT_GROUPINGS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PROSPECT_MI_REPORT_GROUPINGS,
      variables: {
        prospect_id: prospectId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_PROSPECT_MI_REPORT_GROUPINGS_SUCCESS,
      payload: {
        mi_report_grouping_required: response.data.getProspect.mi_report_grouping_required,
        mi_report_groupings: response.data.getProspect.mi_report_groupings,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PROSPECT_MI_REPORT_GROUPINGS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
