import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "../../../common/components";
import { options } from "../../../../constants";
import EditCustomerModalValidator from "./EditCustomerModalValidator";
import styles from "./EditCustomerModal.module.scss";

export default function EditCustomerModal(props) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { getAgencyCustomer, agencyCustomer, agencyCustomerId } = props;

  useEffect(() => {
    if (agencyCustomer?.id !== agencyCustomerId) {
      getAgencyCustomer({
        agencyCustomerId: agencyCustomerId,
      });
    }
  }, [getAgencyCustomer, agencyCustomer, agencyCustomerId]);

  useEffect(() => {
    setFormData(agencyCustomer ?? {});
  }, [agencyCustomer]);

  const handleCancel = () => {
    props.hideModal(EditCustomerModal.displayName);
  };

  const handleFormChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const handleIsCharityChange = (e) => {
    const newData = {
      ...formData,
      is_registered_charity: String(e.target.value) === "true",
    };

    if (!newData.is_registered_charity) {
      newData.registered_charity_number = null;
    }

    setFormData(newData);
  };

  const canSubmit = () => {
    if (formData.is_registered_charity && !formData.registered_charity_number) {
      return false;
    }

    if (
      agencyCustomer.company_type !== "government" &&
      [null, undefined, ""].includes(formData.credit_limit_requested)
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const validator = new EditCustomerModalValidator(formData);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }

    setErrors({});
    await props.updateAgencyCustomer({
      ...formData,
      agencyCustomerId: agencyCustomerId,
    });
    props.hideModal(EditCustomerModal.displayName);
  };

  return (
    <Modal
      className={styles.modal}
      title="Edit Customer"
      visible={true}
      busy={props.submitting}
      primaryButtonDisabled={props.loading || !canSubmit()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      submitText="Save"
      buttonPosition="middle"
    >
      <Form requiredKey>
        <Form.Group inline className={styles.group}>
          <Form.Label inline required>
            Customer (Exact Legal Name)
          </Form.Label>
          <Form.Value>
            <Form.Input
              type="text"
              value={agencyCustomer.legal_name}
              fluid
              disabled
            />
          </Form.Value>
        </Form.Group>

        <Form.Group inline className={styles.group}>
          <Form.Label inline required>
            Customer Type
          </Form.Label>
          <Form.Value>
            {/* Use input instead of dropdown for styling purposes */}
            <Form.Input
              value={
                options.CUSTOMER_DETAILS_CUSTOMER_TYPES.find(
                  x => x.value === agencyCustomer.company_type
                )?.label
              }
              fluid
              disabled
            />
          </Form.Value>
        </Form.Group>

        {["limited_company", "limited_liability_partnership"].includes(
          agencyCustomer.company_type
        ) && (
          <Form.Group inline className={styles.group}>
            <Form.Label inline required>
              Registered Company Number
            </Form.Label>
            <Form.Value>
              <Form.Input
                type="text"
                value={agencyCustomer.registered_company_number}
                fluid
                disabled
              />
            </Form.Value>
          </Form.Group>
        )}

        {agencyCustomer.company_type === "sole_trader" && (
          <Form.Group inline className={styles.group}>
            <Form.Label inline required>
              Sole Trader Name
            </Form.Label>
            <Form.Value>
              <Form.Input
                type="text"
                value={agencyCustomer.sole_trader_name}
                fluid
                disabled
              />
            </Form.Value>
          </Form.Group>
        )}

        <Form.Group inline className={styles.group}>
          <Form.Label inline required>
            Is it a charity?
          </Form.Label>
          <Form.RadioContainer>
            <Form.Radio
              name="is_registered_charity"
              value={true}
              checked={formData.is_registered_charity}
              text="Yes"
              onChange={handleIsCharityChange}
              disabled={props.submitting}
            />
            <Form.Radio
              name="is_registered_charity"
              value={false}
              checked={!formData.is_registered_charity}
              text="No"
              onChange={handleIsCharityChange}
              disabled={props.submitting}
            />
          </Form.RadioContainer>
        </Form.Group>

        {formData.is_registered_charity && (
          <Form.Group inline className={styles.group}>
            <Form.Label inline required>
              Registered Charity Number
            </Form.Label>
            <Form.Value>
              <Form.Input
                name="registered_charity_number"
                type="text"
                maxLength={255}
                value={formData.registered_charity_number}
                onChange={handleFormChange}
                fluid
                disabled={props.submitting}
              />
            </Form.Value>
          </Form.Group>
        )}

        {[
          "limited_company",
          "limited_liability_partnership",
          "partnership",
          "sole_trader",
        ].includes(agencyCustomer.company_type) && (
          <Form.Group
            inline
            className={styles.group}
            error={errors.credit_limit_requested}
          >
            <Form.Label inline required>
              Estimated Credit Limit
            </Form.Label>
            <Form.Value>
              <Form.Number
                name="credit_limit_requested"
                value={formData.credit_limit_requested}
                placeholder="eg. £10,000"
                prefix="£"
                onChange={handleFormChange}
                highlightError={errors.credit_limit_requested}
                formatNumber
                disabled={props.submitting}
              />
            </Form.Value>
          </Form.Group>
        )}
      </Form>
    </Modal>
  );
}

EditCustomerModal.displayName = "EditCustomerModal";

EditCustomerModal.propTypes = {
  agencyCustomer: PropTypes.shape({
    legal_name: PropTypes.string.isRequired,
    company_type: PropTypes.string.isRequired,
    registered_company_number: PropTypes.string,
    is_registered_charity: PropTypes.bool.isRequired,
    registered_charity_number: PropTypes.string,
    sole_trader_name: PropTypes.string,
    credit_limit_requested: PropTypes.number,
  }).isRequired,
  agencyCustomerId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  getAgencyCustomer: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateAgencyCustomer: PropTypes.func.isRequired,
};
