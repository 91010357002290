import { VerifyNonRegisteredAgencyCustomerModal } from "../../modals";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 *
 * @returns
 */
export const showVerifyNonRegisteredAgencyCustomerModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(VerifyNonRegisteredAgencyCustomerModal.displayName, {
    agencyCustomerId: obj.agencyCustomerId,
  }));
};
