import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "semantic-ui-react";
import BranchListItem from "./BranchListItem/BranchListItem";
import styles from "./BranchList.module.scss";

export default function BranchList(props) {
  return (
    <div className={styles.container}>
      <Accordion>
        {props.children}
      </Accordion>
    </div>
  );
}

BranchList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

BranchList.Item = BranchListItem;
