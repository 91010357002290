import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { Squares as ActivityIndicator } from "react-activity";
import ProspectTaskPage from "../ProspectTaskPage/ProspectTaskPageContainer";
import { OnboardingInformation, TaskCard } from "../../components";
import { Button, TextLink } from "../../../common/components";
import styles from "./ProspectTasksPage.module.scss";

export default class ProspectTasksPage extends Component {

  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    prospectId: PropTypes.string.isRequired,
    prospect: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        known_as: PropTypes.string.isRequired,
        is_new_agency: PropTypes.bool,
        is_existing_paye_scheme: PropTypes.bool,
        introduced_via_broker: PropTypes.bool,
        worker_types: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
        })),
      }),
    }),
    prospectTask: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    prospectTasks: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
        progress: PropTypes.shape({
          required_completed: PropTypes.number.isRequired,
          required_total: PropTypes.number.isRequired,
        }).isRequired,
        status: PropTypes.oneOf(["not_started", "in_progress", "done"]),
      })),
    }),
    onFetchProspect: PropTypes.func.isRequired,
    onFetchTasks: PropTypes.func.isRequired,
    onViewProfileClick: PropTypes.func.isRequired,
    onTaskClick: PropTypes.func.isRequired,
    onGoToProspects: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { prospectId, prospect, onFetchProspect, onFetchTasks } = this.props;

    // Only reload if it is a different prospect OR we are missing some key information
    const needsToLoadProspect =
      prospect?.data?.id !== prospectId ||
      _.isNil(prospect?.data?.is_new_agency) ||
      _.isNil(prospect?.data?.worker_types);

    await Promise.all(_.compact([
      needsToLoadProspect && onFetchProspect(prospectId),
      onFetchTasks(prospectId),
    ]));
  }

  render() {
    const { match, prospect, prospectTasks } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.pageText}>Onboarding Prospect</div>
        <div className={styles.headerFlex}>
          {prospect?.loading ? (
            <ActivityIndicator />
          ) : (
            <>
              <div>
                <span className={styles.prospectName}>{prospect?.data?.known_as}</span>
              </div>
              <div>
                <TextLink
                  className={styles.textLink}
                  text="View Prospect Profile"
                  onClick={this.handleViewProfileClick}
                />
                <Button variant="outline-primary" onClick={() => this.props.onGoToProspects()}>
                  Back to Prospects
                </Button>
              </div>
            </>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.tasks}>
            {this.renderTasks()}
          </div>
          <div className={styles.taskContent}>
            <OnboardingInformation
              loading={prospect?.loading}
              isNewAgency={prospect?.data?.is_new_agency}
              isExistingPayeScheme={prospect?.data?.is_existing_paye_scheme}
              introducedViaBroker={prospect?.data?.introduced_via_broker}
              workerTypes={(prospect?.data?.worker_types || []).map(workerType => workerType.name)}
            />
            <Switch>
              <Route exact path={`${match.path}/:taskId`} component={ProspectTaskPage} />
              {!prospectTasks.loading && prospectTasks.data.length > 0 && (
                <Redirect to={`${match.path}/${prospectTasks.data[0].id}`} />
              )}
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  renderTasks = () => {
    const { prospectTask, prospectTasks } = this.props;

    if (prospectTasks.loading && prospectTasks.data.length === 0) {
      return <ActivityIndicator />;
    }

    const tasks = _.chain(prospectTasks.data)
      .orderBy([
        task => task.status === "done" ? 1 : 0,
        "due_date",
        "onboarding_task.weight",
      ])
      .value();

    return tasks.map(task => (
      <TaskCard
        key={task.onboarding_task.name}
        active={task.id === prospectTask.data?.id}
        status={task.status}
        completed={task.status === "done"}
        name={task.onboarding_task.name}
        tasksCompleted={task.progress.required_completed}
        tasksTotal={task.progress.required_total}
        disabled={prospectTasks.loading || prospectTask.loading}
        onClick={() => this.handleTaskClick(task)}
      />
    ));
  }

  handleViewProfileClick = () => {
    const { prospect, onViewProfileClick } = this.props;

    onViewProfileClick(prospect.data.id);
  }

  handleTaskClick = ({ id }) => {
    const { prospectId, onTaskClick } = this.props;

    onTaskClick(prospectId, id);
  }

}
