import React from "react";
import PropTypes from "prop-types";
import { VerifiedUser } from "@material-ui/icons";
import { Alert, TextLink } from "../../../common/components";
import styles from "./VerifyAgencyCustomerAlert.module.scss";

export default function VerifyAgencyCustomerAlert(props) {
  return (
    <Alert variant="warning" showClose={false} className={props.className}>
      <div className={styles.alertWrapper}>
        <div className={styles.alertContent}>
          <VerifiedUser className={styles.icon} />
          <strong className={styles.notVerified}>Not Verified</strong>
          <div className={styles.unverifiedText}>
            This has not yet been verified
          </div>
          <TextLink
            className={styles.verifyLink}
            text="Verify now"
            onClick={props.onVerifyClick}
          />
        </div>
      </div>
    </Alert>
  );
}

VerifyAgencyCustomerAlert.propTypes = {
  className: PropTypes.string,
  onVerifyClick: PropTypes.func.isRequired,
};
