import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./TextLink.module.scss";

export default function TextLink({
  className,
  target,
  to,
  text,
  disabled,
  hideExternalLinkIcon,
  ...otherProps
}) {
  const externalLinkIcon = target === "_blank" && !hideExternalLinkIcon && (
    <div className={styles.externalLink}>
      <FontAwesomeIcon
        className={classnames(styles.icon, className)}
        icon={faExternalLinkAlt}
      />
    </div>
  );

  if (to) {
    return (
      <NavLink
        className={classnames(styles.container, className, disabled && styles.disabled)}
        target={target}
        to={to}
        {...otherProps}
      >
        {text}
        {externalLinkIcon}
      </NavLink>
    );
  }

  return (
    <a
      className={classnames(styles.container, className, disabled && styles.disabled)}
      target={target}
      {...otherProps}
    >
      {text}
      {externalLinkIcon}
    </a>
  );
}

TextLink.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hideExternalLinkIcon: PropTypes.bool,
};
