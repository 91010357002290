import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import NewNonRegisteredCompanyPage from "./NewNonRegisteredCompanyPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  initialQuestions: ownProps.location?.state?.data,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  onGoBack: (initialQuestions) => {
    dispatch(push("/credit-limit-management/request-credit-check", {
      data: initialQuestions,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewNonRegisteredCompanyPage);
