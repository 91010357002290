import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_AGENCY_ONBOARDING_TASK_COMMENT = gql`
mutation($input: AddAgencyOnboardingTaskCommentInput!) {
  addAgencyOnboardingTaskComment(input: $input) {
    id
    comment
    created_at
    created_by {
      first_name
      last_name
    }
    related_subtasks {
      field_name
      label
    }
  }
}
`;

export const addAgencyTaskComment = ({
  taskId,
  comment,
  subtasks,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_AGENCY_TASK_COMMENT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: ADD_AGENCY_ONBOARDING_TASK_COMMENT,
      variables: {
        input: {
          agency_onboarding_task_id: taskId,
          comment,
          related_subtasks: subtasks.map(subtask => ({
            field_name: subtask,
          })),
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.ADD_AGENCY_TASK_COMMENT_SUCCESS,
      payload: {
        taskId,
        comment: response.data.addAgencyOnboardingTaskComment,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.ADD_AGENCY_TASK_COMMENT_FAILURE,
      payload: {
        error,
      },
    });
  }
};
