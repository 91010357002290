import React, { Component } from "react";
import PropTypes from "prop-types";
import { SignInForm } from "../../components";
import Logo from "../../../../assets/images/logo.png";
import PanelImage from "../../../../assets/images/login@2x.jpg";
import styles from "./SignInPage.module.scss";

export default class SignInPage extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        message: PropTypes.string,
      }),
    }).isRequired,
    loginState: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
    }),
    login: PropTypes.func.isRequired,
  }

  componentDidMount() {
    // Call history replace to wipe the history so the inactive message
    // doesn't persist after navigating away.
    this.props.history.replace();
  }

  render() {
    const { location, loginState } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <img className={styles.image} src={PanelImage} alt="decorative side panel" />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.content}>
            <img src={Logo} className={styles.logo} alt="logo"/>
            {location?.state?.message && (
              <div className={styles.inactivityMessage}>
                {location.state.message}
              </div>
            )}
            <div className={styles.title}>
              Sign In To Get Started
            </div>
            <div className={styles.subtitle}>
              You can find your login details on the email that was sent to you
            </div>
            {loginState.error && (
              <div className={styles.error}>
                {loginState.error}
              </div>
            )}
            <SignInForm
              className={styles.form}
              submitting={loginState.loading}
              error={loginState.error}
              onForgotPasswordClick={this.handleForgotPasswordClicked}
              onSignInClick={this.handleSignInClicked}
            />
          </div>
          <div className={styles.footer}></div>
        </div>
      </div>
    );
  }

  handleForgotPasswordClicked = () => {
    const { history } = this.props;

    history.push("/reset-password");
  }

  handleSignInClicked = ({ email, password }) => {
    const { login } = this.props;

    login({
      username: email,
      password: password,
    });
  }
}
