import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_VAT_INFORMATION = gql`
query(
  $prospect_id: ID!
  $disabled_field_filter_options: ProspectDisabledFieldsFilterOptions!
) {
  getProspect(id: $prospect_id) {
    vat_number
    vat_period_ending_date
    vat_frequency
    next_vat_return_period_end
    applying_registered_vat

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      prospect_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getVatInformation = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_VAT_INFORMATION_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_VAT_INFORMATION,
      variables: {
        prospect_id: prospectId,
        disabled_field_filter_options: {
          field_names: [
            "vat_registration_number",
            "vat_period_ending_date",
            "vat_frequency",
            "applying_registered_vat",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_VAT_INFORMATION_SUCCESS,
      payload: {
        data: {
          ...response.data.getProspect,
          disabled_fields: response.data.getProspect.disabled_fields,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_VAT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
