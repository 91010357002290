import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import AddNewContractPage from "./AddNewContractPage";


const mapStateToProps = (state, ownProps) => ({
  ...state.customers,
  agencyCustomerId: ownProps.match.params.agencyCustomerId,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    showUnsavedChangesModal: modalActions.showUnsavedChangesModal,
  }, dispatch),
  onCancelAddNewCustomerContract: () => {
    dispatch(goBack());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewContractPage);
