import * as types from "./types";

const initialState = {
  loading: true,
  permissions: [],
  userGroups: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_PERMISSIONS_PENDING:
      return { ...state, loading: true, permissions: [] };

    case types.GET_PERMISSIONS_SUCCESS:
      return { ...state, loading: false, permissions: action.payload.permissions };

    case types.GET_PERMISSIONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_USER_GROUPS_PENDING:
      return { ...state, loading: true, userGroups: [] };

    case types.GET_USER_GROUPS_SUCCESS:
      return { ...state, loading: false, userGroups: action.payload.userGroups };

    case types.GET_USER_GROUPS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    default:
      return state;
  }
}
