import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { EditCompanyDetailsPage, EditContactInformationPage } from "../";
import { Heading } from "../../../common/components";
import styles from "./EditLegalEntityPage.module.scss";

export default class LegalEntityPage extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    legalEntity: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    getLegalEntity: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, legalEntity, getLegalEntity } = this.props;

    // Make sure we don't reload the Legal Entity every time we change the tab
    if (legalEntity.data?.id === legalEntityId) {
      return;
    }

    await getLegalEntity(legalEntityId);
  }

  render() {
    const { match } = this.props;

    return (
      <div className={styles.container}>
        <Heading
          title={this.renderHeaderTitle()}
          hideSeparator
        />
        <Switch>
          <Route exact path={`${match.path}/company-details`} component={EditCompanyDetailsPage} />
          <Route exact path={`${match.path}/contact-information`} component={EditContactInformationPage} />
        </Switch>
      </div>
    );
  }

  renderHeaderTitle = () => {
    const { legalEntity } = this.props;

    if (!legalEntity?.data) {
      return "";
    }

    return (
      <div className={styles.headerTitle}>
        <span className={styles.name}>{legalEntity.data.name}</span>
      </div>
    );
  }

}
