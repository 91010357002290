import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "../../../common/components";
import { List } from "../../../lists/containers";
import { parseDate } from "../../../../utils";
import ChaseCreditApplicationModalValidator from "./ChaseCreditApplicationModalValidator";
import styles from "./ChaseCreditApplicationModal.module.scss";

const INITIAL_FORM_STATE = {
  dateOfChase: Date.now(),
};

export default function ChaseCreditApplicationModal ({
  onCancel,
  onSubmit,
}) {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleContactMethodListReady = (list) => {
    setFormData({
      ...formData,
      contactMethod: list.data?.values?.find(value => value.is_default) || null,
    });
  };

  const handleContactMethodChange = (_, item) => setFormData({ ...formData, contactMethod: item });

  const canComplete = () => {
    if (!formData.name) {
      return false;
    }
    if (!formData.dateOfChase) {
      return false;
    }
    if (!formData.contactMethod) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const validator = new ChaseCreditApplicationModalValidator(formData);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }

    onSubmit(formData);
  };

  return (
    <Modal
      title="Chase Application"
      subtitle="Please enter the name of the person you contacted and when you contacted them."
      visible={true}
      primaryButtonDisabled={!canComplete()}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitText="Chase Application"
      className={styles.modal}
      buttonPosition="middle"
    >
      <Form requiredKey>
        <Form.Group inline className={styles.group}>
          <Form.Label inline required>Name</Form.Label>
          <Form.Value>
            <Form.Input
              name="name"
              placeholder="Enter name..."
              value={formData.name}
              onChange={handleFormChange}
            />
          </Form.Value>
        </Form.Group>

        <Form.Group inline className={styles.group} error={errors.dateOfChase}>
          <Form.Label inline required className={styles.dateLabel}>
              When it was chased
          </Form.Label>
          <Form.Value>
            <Form.Date
              name="dateOfChase"
              size="small"
              value={formData.dateOfChase}
              onChange={handleFormChange}
              max={parseDate(Date.now(), "YYYY-MM-DD")}
              highlightError={errors.dateOfChase}
              fluid
            />
          </Form.Value>
        </Form.Group>

        <Form.Group inline className={styles.group}>
          <Form.Label inline required>How were they contacted?</Form.Label>
          <Form.Value>
            <List
              identifier="method_of_communication"
              name="contactMethod"
              value={formData.contactMethod?.value}
              onChange={handleContactMethodChange}
              onReady={handleContactMethodListReady}
              fluid
            />
          </Form.Value>
        </Form.Group>

        <Form.Group inline className={styles.group}>
          <Form.Label inline className={styles.commentsLabel}>Comments</Form.Label>
          <Form.Value>
            <Form.TextArea
              placeholder="Enter comments..."
              name="comments"
              value={formData.comments}
              onChange={handleFormChange}
              className={styles.textArea}
              rows={6}
              maxLength={255}
            />
          </Form.Value>
        </Form.Group>
      </Form>
    </Modal>
  );
}

ChaseCreditApplicationModal.displayName = "ChaseCreditApplicationModal";

ChaseCreditApplicationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
