import React from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { ConfirmationModal } from "../../../common/components";
import styles from "./InsurerApplicationCreatedModal.module.scss";
import { toMoney } from "../../../../utils";

export default function InsurerApplicationCreatedModal (props) {

  if (props.error) {
    props.hideModal(InsurerApplicationCreatedModal.displayName);
  }

  const handleSubmit = () => {
    props.hideModal(InsurerApplicationCreatedModal.displayName);
  };

  return (
    <ConfirmationModal
      title="Application Created"
      visible={props.visible}
      className={styles.container}
      submitText="OK"
      onSubmit={handleSubmit}
      submitDisabled={props.loading}
    >
      {props.loading || !props.creditApplication? (
        <ActivityIndicator />
      ) : (
        <div>
          A new insurer application has been created for the requested amount of {toMoney(props.creditApplication.credit_limit_requested)}
        </div>
      )}
    </ConfirmationModal>
  );
}

InsurerApplicationCreatedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  creditApplication: PropTypes.shape({
    credit_limit_requested: PropTypes.number,
  }),
  error: PropTypes.string,
};

InsurerApplicationCreatedModal.displayName = "InsurerApplicationCreatedModal";
