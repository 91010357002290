import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Close, History } from "@material-ui/icons";
import { parseDate } from "../../../../utils";
import styles from "./CreditSuspensionScheduledLabel.module.scss";

export default function CreditSuspensionScheduledLabel(props) {
  return (
    <div className={classnames(props.className, styles.container)} >
      <div className={styles.iconWrapper} >
        <History className={styles.icon} />
      </div>
      <span className={styles.text}>
        Suspension scheduled for {parseDate(props.suspendedAt)}
      </span>
      <div
        className={styles.closeWrapper}
        onClick={() => props.onCancel({ creditSuspensionId: props.suspensionId })}
      >
        <Close className={styles.icon} />
      </div>
    </div>
  );
}

CreditSuspensionScheduledLabel.propTypes = {
  className: PropTypes.string,
  suspendedAt: PropTypes.number.isRequired,
  suspensionId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};
