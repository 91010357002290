import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import AgencyTaskPage from "../AgencyTaskPage";
import { OnboardingInformation, TaskCard } from "../../components";
import { Button, TextLink } from "../../../common/components";
import styles from "./TaskPage.module.scss";

export default class TaskPage extends Component {

  static propTypes = {
    onboardingTaskId: PropTypes.string.isRequired,
    agencyId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    task: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        outstanding_agencies: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          agency: PropTypes.shape({
            id: PropTypes.string.isRequired,
            known_as: PropTypes.string.isRequired,
          }).isRequired,
          progress: PropTypes.shape({
            required_completed: PropTypes.number.isRequired,
            required_total: PropTypes.number.isRequired,
          }).isRequired,
          status: PropTypes.oneOf([
            "not_started",
            "in_progress",
            "done",
          ]).isRequired,
          due_date: PropTypes.number,
        })),
      }),
      agency: PropTypes.shape({
        id: PropTypes.string.isRequired,
        known_as: PropTypes.string.isRequired,
        is_new_agency: PropTypes.bool,
        is_existing_paye_scheme: PropTypes.bool,
        introduced_via_broker: PropTypes.bool,
        worker_types: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
        })),
      }),
    }),
    onFetchOnboardingTask: PropTypes.func.isRequired,
    onViewProfileClick: PropTypes.func.isRequired,
    onGoToAgencyTask: PropTypes.func.isRequired,
    onGoToTasks: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { onboardingTaskId, agencyId, onFetchOnboardingTask } = this.props;

    await onFetchOnboardingTask({ onboardingTaskId, agencyId });
  }

  async componentDidUpdate() {
    await this.checkAgencyTask();
  }

  render() {
    const { agencyId, match, task } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.headerFlex}>
          <div className={styles.taskName}>
            {task.data?.name}
          </div>
          <div>
            <TextLink
              className={styles.toAgencyLink}
              text="View Agency Profile"
              disabled={task.loading}
              onClick={() => this.props.onViewProfileClick(agencyId)}
            />
            <Button variant="outline-primary" onClick={() => this.props.onGoToTasks()}>
              Back to Tasks
            </Button>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.tasks}>
            {this.renderAgencies()}
          </div>
          <div className={styles.taskContent}>
            <OnboardingInformation
              loading={task.loading}
              isNewAgency={task?.agency?.is_new_agency}
              isExistingPayeScheme={task?.agency?.is_existing_paye_scheme}
              introducedViaBroker={task?.agency?.introduced_via_broker}
              workerTypes={(task.agency?.worker_types || []).map(workerType => workerType.name)}
            />
            <Switch>
              <Route exact path={`${match.path}/:taskId`} component={AgencyTaskPage} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  renderAgencies = () => {
    return _.chain(this.props.task.data?.outstanding_agencies)
      .filter(outstandingAgencyTask => outstandingAgencyTask.status !== "done")
      .sortBy(outstandingAgencyTask => outstandingAgencyTask.due_date)
      .map(outstandingAgencyTask => (
        <TaskCard
          key={`${this.props.task.data?.id}_${outstandingAgencyTask.agency.id}`}
          active={outstandingAgencyTask.agency?.id === this.props.agencyId}
          name={outstandingAgencyTask.agency.known_as}
          status={outstandingAgencyTask.status}
          completed={outstandingAgencyTask.status === "done"}
          tasksCompleted={outstandingAgencyTask.progress.required_completed}
          tasksTotal={outstandingAgencyTask.progress.required_total}
          disabled={this.props.task.loading}
          onClick={() => this.props.onGoToAgencyTask({
            onboardingTaskId: this.props.onboardingTaskId,
            agencyId: outstandingAgencyTask.agency.id,
            agencyOnboardingTaskId: outstandingAgencyTask.id,
          })}
        />
      ))
      .value();
  }

  checkAgencyTask = async () => {
    const { onboardingTaskId, task, onGoToAgencyTask, onGoToTasks } = this.props;

    if (task.loading) {
      return;
    }

    if (!onboardingTaskId) {
      return await onGoToTasks();
    }

    // If we have no outstanding agencies then navigate back to the tasks page
    if (!task.agency && !task.data?.outstanding_agencies[0]?.agency?.id) {
      return await onGoToTasks();
    }

    // If all the outstanding tasks are done then navigate back to the tasks page
    // (This can happen while we are running through the tasks and completing them)
    if (task.data.outstanding_agencies.every(outstandingAgencyTask => outstandingAgencyTask.status === "done")) {
      return await onGoToTasks();
    }

    // Redirect to the first agency if we don't have any agency data
    // Note: This assumes we have come into this page without an agency ID
    if (!task.agency) {
      const highestPriorityOutstandingAgency = _.chain(task.data.outstanding_agencies)
        .filter(outstandingAgencyTask => outstandingAgencyTask.status !== "done")
        .sortBy(outstandingAgencyTask => outstandingAgencyTask.due_date)
        .first()
        .value();

      return await onGoToAgencyTask({
        onboardingTaskId,
        agencyId: highestPriorityOutstandingAgency.agency.id,
        agencyOnboardingTaskId: highestPriorityOutstandingAgency.id,
      });
    }

    // Redirect to the next agency if the current agency has completed the onboarding task
    let currentTaskIndex = task.data.outstanding_agencies.findIndex(outstandingAgencyTask => outstandingAgencyTask.agency.id === task.agency.id);

    if (currentTaskIndex < 0) {
      return await onGoToTasks();
    }

    if (task.data.outstanding_agencies[currentTaskIndex].status === "done") {
      const nextTasks = _.filter(task.data.outstanding_agencies, (outstandingAgencyTask, index) => {
        return index === currentTaskIndex || outstandingAgencyTask.status !== "done";
      });
      // Get the current index from our new array
      currentTaskIndex = nextTasks.findIndex(outstandingAgencyTask => outstandingAgencyTask.agency.id === task.agency.id);

      const nextTask = nextTasks.length - 1 > currentTaskIndex
        ? nextTasks[currentTaskIndex + 1]
        : nextTasks[0];

      return await onGoToAgencyTask({
        onboardingTaskId,
        agencyId: nextTask.agency.id,
        agencyOnboardingTaskId: nextTask.id,
      });
    }
  }

}
