const format = (response, page, itemsPerPage) => {
  const { total } = response;
  const pages = Math.ceil(total / itemsPerPage);

  return {
    pagination: {
      page: page || 1,
      limit: itemsPerPage,
      pages,
      total,
    },
  };
};

export default {
  format,
};
