import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BreadcrumbItem from "./BreadcrumbItem/BreadcrumbItem";
import styles from "./Breadcrumb.module.scss";

class Breadcrumb extends PureComponent {

    static propTypes = {
      className: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.node).isRequired,
    }

    render() {
      return (
        <div className={classnames(styles.Breadcrumb, this.props.className)}>
          {this.props.children.map((value, index) => {
            const isLast = (index === this.props.children.length - 1);
            return (
              <Fragment key={index}>
                <BreadcrumbItem className={styles.BreadcrumbItem} { ...value.props } />
                {!isLast && <FontAwesomeIcon className={styles.chevron} icon={faChevronRight} />}
              </Fragment>

            );
          })}
        </div>
      );
    }
}

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
