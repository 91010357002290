import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { Alert, DisabledFieldLock } from "../../../common/components";
import AgencyContacts from "./AgencyContacts/AgencyContacts";
import BackOfficeContacts from "./BackOfficeContacts/BackOfficeContacts";
import { tooltips } from "../../../../constants";
import styles from "./ContactsTab.module.scss";

const DISABLED_FIELD_TASK_NAME = "prospect_contacts";

export default class ContactsTab extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    prospectId: PropTypes.string.isRequired,
    prospectContacts: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
      submitting: PropTypes.bool,
    }),
    prospectDisabledFields: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          disabled_field: PropTypes.string.isRequired,
          prospect_onboarding_task: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ).isRequired,
      error: PropTypes.string,
    }).isRequired,
    backOfficeContacts: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
      submitting: PropTypes.bool,
    }),
    businessDevelopmentManagers: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
    }).isRequired,
    onFetchProspectDisabledFields: PropTypes.func.isRequired,
    onFetchBackOfficeContacts: PropTypes.func.isRequired,
    onFetchProspectContacts: PropTypes.func.isRequired,
    onFetchBusinessDevelopmentManagers: PropTypes.func.isRequired,
    onAddProspectContact: PropTypes.func.isRequired,
    onUpdateProspectContact: PropTypes.func.isRequired,
    onRemoveProspectContact: PropTypes.func.isRequired,
    onArchiveProspectContact: PropTypes.func.isRequired,
    onSaveBackOfficeContacts: PropTypes.func.isRequired,
  }

  static defaultProps = {
    prospectContacts: {
      loading: false,
      data: [],
    },
    backOfficeContacts: {
      loading: false,
      data: [],
    },
  }

  state = {
    showArchived: false,
  }

  async componentDidMount() {
    const {
      prospectId,
      onFetchProspectDisabledFields,
      onFetchProspectContacts,
      onFetchBackOfficeContacts,
      onFetchBusinessDevelopmentManagers,
    } = this.props;

    await Promise.all([
      onFetchProspectDisabledFields({ prospectId }),
      onFetchProspectContacts({ prospectId }),
      onFetchBackOfficeContacts({ prospectId }),
      onFetchBusinessDevelopmentManagers(),
    ]);
  }

  render() {
    const canEditContacts = this.canEditContacts();

    return (
      <>
        {!this.props.prospectDisabledFields?.loading && !canEditContacts && (
          <Alert className={styles.alert}
            variant={"warning"}
            showClose={false}>
            <div className={styles.onboardingAlertContent}>
              <DisabledFieldLock
                className={styles.lockIcon}
                tooltip={tooltips.CLICK_TO_GO_TO_TASK}
                onClick={() => this.handleOnboardingWarningClick()}
              />
                  The Contacts onboarding task must be complete before you can edit this page.
            </div>
          </Alert>
        )}
        <Grid className={styles.container} padded stackable stretched columns="equal" reversed="mobile">
          <Grid.Column>
            <AgencyContacts
              {...this.props.prospectContacts}
              showArchived={this.state.showArchived}
              onShowArchived={this.handleShowArchivedChanged}
              onContactAdd={this.handleAgencyContactAdded}
              onContactChange={this.handleAgencyContactChanged}
              onContactRemove={this.handleAgencyContactRemoved}
              onContactArchive={this.handleAgencyContactArchived}
              readOnly={!canEditContacts}
            />
          </Grid.Column>
          <Grid.Column>
            <BackOfficeContacts
              creditController={this.props.backOfficeContacts.data?.credit_controller}
              creditControllerBuddy={this.props.backOfficeContacts.data?.credit_controller_buddy}
              payrollAccountManager={this.props.backOfficeContacts.data?.payroll_account_manager}
              payrollAccountManagerBuddy={this.props.backOfficeContacts.data?.payroll_account_manager_buddy}
              businessDevelopmentManager={this.props.backOfficeContacts.data?.business_development_manager}
              businessDevelopmentManagers={this.props.businessDevelopmentManagers.data}
              onChange={this.handleBackOfficeContactsChanged}
              readOnly={!canEditContacts}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  handleOnboardingWarningClick = () => {
    const { history, prospectId, prospectDisabledFields } = this.props;

    const taskId = prospectDisabledFields?.data?.find(field =>
      field.disabled_field === DISABLED_FIELD_TASK_NAME).prospect_onboarding_task.id;

    history.push(`/onboarding/prospects/${prospectId}/${taskId}`);
  }

  handleAgencyContactAdded = (contact) => {
    const { prospectId, onAddProspectContact } = this.props;

    onAddProspectContact({
      prospectId,
      contact,
    });
  }

  handleAgencyContactChanged = (contact) => {
    const { prospectId, onUpdateProspectContact } = this.props;

    onUpdateProspectContact({
      prospectId,
      contact,
    });
  }

  handleAgencyContactRemoved = (contact) => {
    const { onRemoveProspectContact } = this.props;

    onRemoveProspectContact({
      contactId: contact.id,
    });
  }

  handleAgencyContactArchived = (contact) => {
    const { onArchiveProspectContact } = this.props;

    onArchiveProspectContact({
      contactId: contact.id,
    });
  }

  handleBackOfficeContactsChanged = (backOfficeContacts) => {
    const { prospectId, onSaveBackOfficeContacts } = this.props;

    onSaveBackOfficeContacts({
      prospectId,
      ...backOfficeContacts,
    });
  }

  handleShowArchivedChanged = () => {
    const { showArchived } = this.state;

    this.setState({
      showArchived: !showArchived,
    });
  }

  canEditContacts = () => {
    return !this.props.prospectDisabledFields?.data?.some(field =>
      field.disabled_field === DISABLED_FIELD_TASK_NAME);
  }
}
