import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Table } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import {
  CheckCircle,
  Close,
  Flag,
  History,
  MoreVert,
  WarningRounded,
} from "@material-ui/icons";
import { Icon } from "../../../common/components";
import { CreditApplicationCommentTooltip } from "../../components";
import { getUsersName, parseDate } from "../../../../utils";
import styles from "./CreditActivityList.module.scss";
import unreport from "../../../../assets/images/unreport-blue.svg";


export default function CreditActivityList(props) {
  const renderActionCell = (activity) => {
    switch (activity.action) {
      case "report":
        return (
          <>
            <Flag className={styles.flag} />
            <div>
              <div>Reported to Insurer ({activity.type})</div>
              {activity.comment && (
                <div className={styles.multiline}>
                  <CreditApplicationCommentTooltip
                    text="See Comment"
                    comment={activity.comment}
                    time={activity.date_of_effect}
                    user={activity.created_by}
                  />
                </div>
              )}
            </div>
          </>
        );

      case "unreport":
        return (
          <>
            <Icon icon={unreport} />
            <div>
              <div>Unreported ({activity.type})</div>
              {activity.comment && (
                <div className={styles.multiline}>
                  <CreditApplicationCommentTooltip
                    text="See Comment"
                    comment={activity.comment}
                    time={activity.date_of_effect}
                    user={activity.created_by}
                  />
                </div>
              )}
            </div>
          </>
        );

      case "suspend":
        return (
          <>
            <WarningRounded className={styles.suspendedIcon} />
            Suspended
            {activity.status === "cancelled" && (<strong> (Cancelled on {parseDate(activity.date_cancelled)})</strong>)}
          </>
        );

      case "unsuspend":
        return (
          <>
            <CheckCircle className={styles.unsuspendedIcon} />
            Unsuspended
            {activity.status === "cancelled" && (<strong> (Cancelled on {parseDate(activity.date_cancelled)})</strong>)}
          </>
        );

      default:
        return null;
    }
  };

  const renderActionMenu = (activity) => {
    return (
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => props.onCancelScheduledSuspension({ creditSuspensionId: activity.id })}
        >
          <div className={styles.statusCell}>
            <Close className={styles.cancelIcon}/>Cancel
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  };

  if (props.loading) {
    return <ActivityIndicator />;
  }

  return (
    <Table className={styles.table} basic>
      <Table.Header>
        <Table.HeaderCell>
          Date
        </Table.HeaderCell>
        <Table.HeaderCell>
          Action
        </Table.HeaderCell>
        <Table.HeaderCell>
          Action Performed By
        </Table.HeaderCell>
        <Table.HeaderCell>
          {/* This is for the action menu */}
        </Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {(props.data || []).length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan="4" className={styles.noResults}>
              There is no credit activity available to view.
            </Table.Cell>
          </Table.Row>
        ) : props.data.map((activity, i) => (
          <Table.Row key={`activity_${activity.type}_${i}`}>
            <Table.Cell>
              <div className={styles.statusCell}>
                {activity.status === "pending" && (
                  <History className={styles.scheduledIcon} />
                )}{parseDate(activity.date_of_effect)}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className={styles.statusCell}>
                {renderActionCell(activity)}
              </div>
            </Table.Cell>
            <Table.Cell>
              {getUsersName(activity.created_by)}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {activity.status === "pending" && (
                <Dropdown
                  trigger={<MoreVert className={styles.actionMenuIcon} />}
                  icon={null}
                  direction="left"
                  floating
                >
                  {renderActionMenu(activity)}
                </Dropdown>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

CreditActivityList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    date_of_effect: PropTypes.number.isRequired,
    date_cancelled: PropTypes.number,
    created_by: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    document_id: PropTypes.string,
  })),
  loading: PropTypes.bool.isRequired,
  onCancelScheduledSuspension: PropTypes.func.isRequired,
};
