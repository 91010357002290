import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import CreditOverview from "./CreditOverview/CreditOverviewContainer";
import InsurerApplication from "./InsurerApplication/InsurerApplicationContainer";
import BrokerRequest from "./BrokerRequest/BrokerRequestContainer";
import ScheduledCreditAction from "./ScheduledCreditAction/ScheduledCreditAction";

export default class CreditManagementTab extends Component {

  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={CreditOverview} />
        <Route exact path={`${match.path}/insurer-application/:creditApplicationId`} component={InsurerApplication} />
        <Route exact path={`${match.path}/broker-request/:creditApplicationId`} component={BrokerRequest} />
        <Route exact path={`${match.path}/scheduled-credit-actions/:scheduledCreditActionId`} component={ScheduledCreditAction} />
      </Switch>
    );
  }

}
