import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../../common/components";
import styles from "./ArchiveContactConfirmationModal.module.scss";

export default function ArchiveContactConfirmationModal(props) {
  return (
    <ConfirmationModal
      className={styles.container}
      title="Are you sure?"
      submitText="Archive Contact"
      cancelText="Go Back"
      submitVariant="danger"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div className={styles.line}>
        Are you sure you wish to archive this contact?
      </div>
    </ConfirmationModal>
  );
}

ArchiveContactConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
