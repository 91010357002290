import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_LEGAL_ENTITY_HISTORY = gql`
fragment RevisionHistory on Revision {
  operation
  updated_at
  revision_changes {
    path
    old_value
    new_value
  }
}

query(
  $name_input: GetHistoryInput!
  $legal_entity_id: ID!
) {
  name: getHistory(input: $name_input) {
    ...RevisionHistory
  }
  address: getLegalEntityAddressHistory(id: $legal_entity_id) {
    region
    country
    locality
    postal_code
    address_line_1
    address_line_2
    date_changed
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getLegalEntityHistory = ({ legalEntityId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_LEGAL_ENTITY_HISTORY_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_LEGAL_ENTITY_HISTORY,
      variables: {
        legal_entity_id: legalEntityId,
        name_input: {
          "document_id": legalEntityId,
          "model": "LegalEntity",
          "fields": ["name"],
          "limit": 5,
          "order": "desc",
        },
      },
    });

    const revisionsForField = (revisions, field) => revisions.reduce((result, revision) => [
      ...result,
      ...revision.revision_changes
        .filter(revisionChange => revisionChange.path === field)
        .map(revisionChange => ({
          value: revisionChange.new_value,
          updated_at: revision.updated_at,
        })),
    ], []);
    const revisions = {
      name: revisionsForField(response.data.name, "name"),
      address: response.data.address.map(value => ({
        value: [value.address_line_1, value.locality, value.postal_code].join(", "),
        updated_at: value.date_changed,
      })),
    };

    dispatch({
      type: types.GET_LEGAL_ENTITY_HISTORY_SUCCESS,
      payload: {
        revisions,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_LEGAL_ENTITY_HISTORY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
