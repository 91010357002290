import gql from "graphql-tag";
import { push } from "connected-react-router";
import { ExperianCreditCheckForAgencyModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";
import { addExperianCreditCheckForAgency } from "./";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_LEGAL_ENTITY = gql`
mutation($input: NonRegisteredLegalEntityInput!) {
  addNonRegisteredLegalEntity(input: $input) {
    id
  }
}
`;

const ADD_AGENCY_CUSTOMER_FOR_NON_REGISTERED = gql`
mutation($input: AddAgencyCustomerForNonRegisteredLegalEntityInput!) {
  addAgencyCustomerForNonRegisteredLegalEntity(input: $input) {
    id
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.website
 * @param {string} obj.isRegisteredCharity
 * @param {string} obj.registeredCharityNumber
 * @param {string} obj.agencyId
 * @param {number} obj.creditLimitRequested
 * @param {boolean} obj.validate
 * @param {boolean} obj.isProspect (optional)
 * @returns
 */
export const addNonRegisteredCompany = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_NON_REGISTERED_COMPANY_PENDING,
    });

    const legalEntityResponse = await apolloClient.query({
      query: ADD_LEGAL_ENTITY,
      variables: {
        input: {
          name: obj.name,
          telephone: obj.telephone,
          website: obj.website,
          email_address: obj.emailAddress,
          company_address: {
            address_line_one: obj.addressLine1,
            address_line_two: obj.addressLine2,
            town_or_city: obj.townOrCity,
            county: obj.county,
            postcode: obj.postcode,
          },
          is_registered_charity: obj.isRegisteredCharity,
          registered_charity_number: obj.isRegisteredCharity ? obj.registeredCharityNumber : null,
        },
      },
    });

    if (legalEntityResponse.errors) {
      throw legalEntityResponse.errors[0].message;
    }

    dispatch({
      type: types.ADD_NON_REGISTERED_COMPANY_SUCCESS,
      payload: {
        data: legalEntityResponse.data.addNonRegisteredLegalEntity,
      },
    });

    const legalEntityId = legalEntityResponse.data.addNonRegisteredLegalEntity.id;

    await dispatch(push(`/credit-limit-management/legal-entities/${legalEntityId}`));

    const agencyCustomerResponse = await apolloClient.mutate({
      mutation: ADD_AGENCY_CUSTOMER_FOR_NON_REGISTERED,
      variables: {
        input: {
          [obj.isProspect ? "prospect_id" : "agency_id"]: obj.agencyId,
          legal_entity_id: legalEntityId,
          credit_limit_requested: parseFloat(obj.creditLimitRequested || 0),
        },
      },
    });

    if (agencyCustomerResponse.errors) {
      throw agencyCustomerResponse.errors[0].message;
    }

    const agencyCustomerId = agencyCustomerResponse.data.addAgencyCustomerForNonRegisteredLegalEntity.id;

    dispatch(modalActions.showModal(ExperianCreditCheckForAgencyModal.displayName, {
      // onVerifyAgencyCustomer is called when the Experian form is submitted
      onVerifyAgencyCustomer: async (formData) => {
        dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));

        dispatch(addExperianCreditCheckForAgency({
          agencyCustomerId: agencyCustomerId,
          companyFound: formData.companyFound,
          experianCreditResult: formData.newCreditLimit,
          experianDateOfResult: formData.dateOfResult,
          experianCreditReport: formData.experianCreditReport,
        }));
      },
      onCancel: () => {
        dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));
      },
    }));
  }
  catch (err) {
    dispatch({
      type: types.ADD_NON_REGISTERED_COMPANY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
