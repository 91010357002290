import gql from "graphql-tag";

export const GET_HISTORY = gql`
query($input: GetHistoryInput!) {
  getHistory(input: $input) {
    id
    model
    document_id
    document
    operation
    revision
    created_at
    user {
      id
      first_name
      last_name
    }
    revision_changes {
      id
      path
      old_value
      new_value
    }
  }
}
`;
