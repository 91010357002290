import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { REACT_APP_INACTIVITY_TIMEOUT_DURATION } from "../../config";
import { forceLogout, logout } from "../../modules/currentUser/duck/actions";

const useInactivityTimeout = () => {
  const dispatch = useDispatch();
  const intervalRef = useRef();

  const startIdleMonitor = useCallback(() => {
    // Initialise logout time
    setIdleLogoutTime();

    // Start an interval to check the idle logout time
    intervalRef.current = setInterval(() => {
      const expiration = parseInt(localStorage.getItem("_idleLogoutTime") ?? 0, 10);

      if (expiration < Date.now()) {
        dispatch(forceLogout({ message: "Your session has expired due to inactivity." }));
      }
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    if (REACT_APP_INACTIVITY_TIMEOUT_DURATION) {
      // It seems its not currently possible to set an expiration on the Amplify session
      // so before we start the timer proper, check the expiration.
      const expiration = parseInt(localStorage.getItem("_idleLogoutTime") ?? 0, 10);

      if (expiration && expiration < Date.now()) {
        dispatch(logout());
        return;
      }

      document.addEventListener("keydown", setIdleLogoutTime, false);
      document.addEventListener("mousedown", setIdleLogoutTime, false); // Catches shift, alt, ctl, etc.
      document.addEventListener("mousemove", setIdleLogoutTime, false);
      document.addEventListener("scroll", setIdleLogoutTime, false);

      startIdleMonitor();
    }

    return () => {
      document.removeEventListener("keydown", setIdleLogoutTime, false);
      document.removeEventListener("mousedown", setIdleLogoutTime, false);
      document.removeEventListener("mousemove", setIdleLogoutTime, false);
      document.removeEventListener("scroll", setIdleLogoutTime, false);

      clearInterval(intervalRef.current);
    };
  }, [dispatch, startIdleMonitor]);

  const setIdleLogoutTime = () => {
    const newExpiration = Date.now() + REACT_APP_INACTIVITY_TIMEOUT_DURATION * 60 * 1000;
    localStorage.setItem("_idleLogoutTime", newExpiration);
  };

  return null;
};

export default useInactivityTimeout;
