import { DeclineCreditRequestModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {string} id id of the credit request to cancel
 */
export const showDeclineCreditRequestModal = id => async (dispatch) => {
  dispatch(modalActions.showModal(DeclineCreditRequestModal.displayName, {
    creditRequestId: String(id),
  }));
};
