import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import DistributeCreditRequestModal from "./DistributeCreditRequestModal";

const mapStateToProps = state => ({
  legalEntity: state.creditLimitManagement.legalEntity,
  agencyCustomers: state.creditLimitManagement.agencyCustomers,
  creditOverview: state.creditLimitManagement.creditOverview,
  currentUser: state.currentUser.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(DistributeCreditRequestModal);

container.displayName = "DistributeCreditRequestModal";

export default container;
