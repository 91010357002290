import _ from "lodash";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PROSPECT_CONTACTS = gql`
query($prospect_id: ID!) {
  getProspect(id: $prospect_id) {
    contacts {
      id
      first_name
      last_name
      preferred_name
      email_address
      contact_numbers {
        phone_number_type {
          id
          label
          value
        }
        phone_number
        is_preferred_number
      }
      job_role
      is_primary_contact
      ni_number
      archived
      created_at
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getProspectContacts = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECT_CONTACTS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PROSPECT_CONTACTS,
      variables: {
        prospect_id: prospectId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const orderedContacts = _.chain(response.data.getProspect.contacts)
      .sortBy(contact => -contact.is_primary_contact, contact => contact.created_at)
      .value();


    dispatch({
      type: types.GET_PROSPECT_CONTACTS_SUCCESS,
      payload: {
        prospectContacts: orderedContacts,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PROSPECT_CONTACTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
