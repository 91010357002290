import gql from "graphql-tag";
import * as types from "../types";
import { DOCUMENT_TYPES } from "../../../documents";
import { apolloClient } from "../../../../apolloClient";

const GET_PAYROLL_INFO = gql`
query(
  $prospect_id: ID!
  $pension_document_input: ProspectDocumentInput!
  $vat_document_input: ProspectDocumentInput!
  $disabled_field_filter_options: ProspectDisabledFieldsFilterOptions!
) {
  getProspect(id: $prospect_id) {
    vat_number
    is_new_agency

    auto_enrolment_pension_info {
      pension_company {
        label
      }
      pension_scheme
      staging_date
      default_postponement_months
      eligible_job_holder_postponement_months
      employer_pension_reference
    }
    is_existing_paye_scheme
    vat_period_ending_date
    vat_frequency
    next_vat_return_period_end
    applying_registered_vat
    previous_pension_provider {
      label
    }
    holiday_pay_info {
      has_holiday_pot
      end_of_holiday_year_pay_period
      end_of_holiday_year_tax_year_end
      can_carry_over_accrual
      default_holiday_scheme {
        label
      }
      holiday_accrual_period {
        label
      }
    }
    hmrc_info {
      paye_scheme_reference
      accounts_office_reference
      claiming_employers_ni_allowance
      small_employers_relief
      intermediary_reporting
    }
    preferences {
      payroll_frequency
      default_payslip_preference
      default_customer_vat_rate
    }
    worker_types {
      id
      name
    }

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      prospect_onboarding_task {
        id
      }
    }
  }

  getWorkerTypes {
    id
    name
  }

  getPensionDocument: getProspectDocument(input: $pension_document_input) {
    name
  }

  getVatDocument: getProspectDocument(input: $vat_document_input) {
    name
  }
}
`;

export const getPayrollInfo = ({
  prospectId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PAYROLL_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PAYROLL_INFO,
      variables: {
        prospect_id: prospectId,
        pension_document_input: {
          prospect_id: prospectId,
          document_type: DOCUMENT_TYPES.PENSION_DOCUMENT,
        },
        vat_document_input: {
          prospect_id: prospectId,
          document_type: DOCUMENT_TYPES.VAT_CERTIFICATE,
        },
        disabled_field_filter_options: {
          field_names: [
            "vat_registration_number",
          ],
        },
      },
    });

    dispatch({
      type: types.GET_PAYROLL_INFO_SUCCESS,
      payload: {
        payrollInfo: response.data.getProspect,
        workerTypes: response.data.getWorkerTypes,
        pensionDocument: response.data.getPensionDocument,
        vatDocument: response.data.getVatDocument,
        disabledFields: response.data.getProspect.disabled_fields,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PAYROLL_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
