import { push } from "connected-react-router";
import { showCommunicateToAgencyModal } from ".";

export const handleCreditRequestCommunicationRequiredTask = creditLimitManagementTask => async (dispatch) => {
  dispatch(push(`/credit-limit-management/legal-entities/${creditLimitManagementTask.data.legal_entity_id}/request-history`));
  dispatch(showCommunicateToAgencyModal({
    agencyId: creditLimitManagementTask.data.agency_id ?? creditLimitManagementTask.data.prospect_id,
    isProspect: !creditLimitManagementTask.data.agency_id,
    legalEntityId: creditLimitManagementTask.data.legal_entity_id,
    creditRequestId: creditLimitManagementTask.data.credit_request_id,
  }));
};
