import React from "react";
import PropTypes from "prop-types";
import { Lock } from "@material-ui/icons";
import classnames from "classnames";
import { Popup } from "semantic-ui-react";
import styles from "./DisabledFieldLock.module.scss";

export default function DisabledFieldLock({
  className,
  tooltip,
  onClick,
}) {
  return (
    <Popup
      trigger={(
        <Lock
          className={classnames(className, styles.lock, onClick && styles.clickable)}
          onClick={onClick}
        />
      )}
      inverted
    >
      <Popup.Content className={styles.content}>
        {tooltip}
      </Popup.Content>
    </Popup>
  );
}

DisabledFieldLock.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};
