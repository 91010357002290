import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MIReportGroup.module.scss";

export default function MIReportGroup(props) {
  return (
    <div
      className={classnames(
        styles.container,
        props.disabled && styles.disabled,
      )}
    >
      <div className={styles.title}>
        {props.title}
      </div>
      {props.children}
    </div>
  );
}

MIReportGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

