import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import { InfoOutlined } from "@material-ui/icons";
import DownloadButton from "./DownloadButton/DownloadButton";
import UploadButton from "./UploadButton/UploadButton";
import styles from "./FormDocument.module.scss";

export default function FormDocument(props) {
  const [downloadable, setDownloadable] = useState(props.value);

  return (
    <div className={classnames(styles.container, props.className)}>
      {props.value ? (
        <DownloadButton
          {...props}
          downloadable={downloadable}
          onDelete={() => {
            setDownloadable(false);
            props.onDelete();
          }}
        />
      ) : (
        <UploadButton {...props} />
      )}
      {props.error && (
        <div className={styles.error}>
          <InfoOutlined />
          {props.error}
        </div>
      )}
    </div>
  );
}

FormDocument.propTypes = {
  ..._.omit(DownloadButton.propTypes, "downloadable"),
  ...UploadButton.propTypes,
  error: PropTypes.string,
};
