import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_BACK_OFFICE_CONTACTS = gql`
query($prospect_id: ID!) {
  getProspect(id: $prospect_id) {
    credit_controller {
      id
      first_name
      last_name
    }

    credit_controller_buddy {
      id
      first_name
      last_name
    }

    payroll_account_manager {
      id
      first_name
      last_name
    }

    payroll_account_manager_buddy {
      id
      first_name
      last_name
    }

    business_development_manager {
      id
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getBackOfficeContacts = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BACK_OFFICE_CONTACTS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_BACK_OFFICE_CONTACTS,
      variables: {
        prospect_id: prospectId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_BACK_OFFICE_CONTACTS_SUCCESS,
      payload: response.data.getProspect,
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_BACK_OFFICE_CONTACTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
