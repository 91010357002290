import _ from "lodash";
import moment from "moment";

export default class GoToInsurerModalValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (data.resultDate > moment().valueOf()) {
      errors = _.set(errors, "resultDate", "You cannot select a date in the future");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
