import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./BadgePill.module.scss";

export default function BadgePill(props) {
  return (
    <div className={classnames(styles.container, props.pillStyle)}>
      <div className={styles.iconContainer}>
        {props.leftIcon}
      </div>
      <div className={classnames(styles.title, props.titleStyle)}>
        {props.title}
      </div>
    </div>
  );
}

BadgePill.propTypes = {
  title: PropTypes.string,
  pillStyle: PropTypes.object,
  leftIcon: PropTypes.object,
  titleStyle: PropTypes.object,
};
