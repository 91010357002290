export const GET_USER_GROUPS_PENDING = "GROUP_MANAGEMENT/GET_USER_GROUPS_PENDING";
export const GET_USER_GROUPS_SUCCESS = "GROUP_MANAGEMENT/GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_FAILURE = "GROUP_MANAGEMENT/GET_USER_GROUPS_FAILURE";
export const GET_USER_GROUP_PENDING = "GROUP_MANAGEMENT/GET_USER_GROUP_PENDING";
export const GET_USER_GROUP_SUCCESS = "GROUP_MANAGEMENT/GET_USER_GROUP_SUCCESS";
export const GET_USER_GROUP_FAILURE = "GROUP_MANAGEMENT/GET_USER_GROUP_FAILURE";
export const ADD_USER_GROUP_PENDING = "GROUP_MANAGEMENT/ADD_USER_GROUP_PENDING";
export const ADD_USER_GROUP_SUCCESS = "GROUP_MANAGEMENT/ADD_USER_GROUP_SUCCESS";
export const ADD_USER_GROUP_FAILURE = "GROUP_MANAGEMENT/ADD_USER_GROUP_FAILURE";
export const UPDATE_USER_GROUP_PENDING = "GROUP_MANAGEMENT/UPDATE_USER_GROUP_PENDING";
export const UPDATE_USER_GROUP_SUCCESS = "GROUP_MANAGEMENT/UPDATE_USER_GROUP_SUCCESS";
export const UPDATE_USER_GROUP_FAILURE = "GROUP_MANAGEMENT/UPDATE_USER_GROUP_FAILURE";
export const DELETE_USER_GROUP_PENDING = "GROUP_MANAGEMENT/DELETE_USER_GROUP_PENDING";
export const DELETE_USER_GROUP_SUCCESS = "GROUP_MANAGEMENT/DELETE_USER_GROUP_SUCCESS";
export const DELETE_USER_GROUP_FAILURE = "GROUP_MANAGEMENT/DELETE_USER_GROUP_FAILURE";
