import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { parseDate, toMoney } from "../../../../utils";
import { EXCLUSIVE_VAT_LABEL } from "../../../../config";
import styles from "./ExperianResponse.module.scss";

export default class ExperianResponse extends PureComponent {

  static propTypes = {
    initialRequestedAmount: PropTypes.number.isRequired,
    className: PropTypes.string,
    amountRemaining: PropTypes.number,
    nextAccountsDueDate: PropTypes.string,
    result: PropTypes.object.isRequired,
  }

  render() {
    const { initialRequestedAmount, nextAccountsDueDate, result, className, amountRemaining } = this.props;

    const showInitialRequestedAmount = initialRequestedAmount > 0;
    const showAmountRemaining = amountRemaining > 0;

    return (
      <div className={styles.container}>
        <div className={className}>
          <div className={styles.resultsFlex}>
            <div className={styles.resultContainer}>
              <p className={styles.resultKey}>Score</p>
              <p className={styles.resultValue}>
                -
              </p>
            </div>
            <div className={styles.resultContainer}>
              <p className={styles.resultKey}>Risk</p>
              <p className={classnames(styles.resultValue, styles.risk)}>
                -
              </p>
            </div>
            <div className={styles.resultContainer}>
              <p className={styles.resultKey}> Credit Limit ({EXCLUSIVE_VAT_LABEL})</p>
              <p className={styles.resultValue}>
                {result.experian_credit_result
                  ? toMoney(result.experian_credit_result)
                  : "-"}
              </p>
            </div>
            <div className={styles.resultContainer}>
              <p className={styles.resultKey}>Credit Rating</p>
              <p className={styles.resultValue}>
                -
              </p>
            </div>
            <div className={styles.resultContainer}>
              <p className={styles.resultKey}>Next Accounts Due Date</p>
              <p className={styles.resultValue}>
                {parseDate(nextAccountsDueDate) || "No Due Date Available"}
              </p>
            </div>
            {showInitialRequestedAmount && <div className={styles.resultContainer}>
              <p className={styles.resultKey}>Estimated Credit Needed</p>
              <p className={styles.resultValue}>
                {toMoney(initialRequestedAmount)}
              </p>
            </div>}
            {showAmountRemaining && <div className={styles.resultContainer}>
              <p className={styles.resultKey}>Amount Remaining</p>
              <p className={styles.resultValue}>
                {toMoney(amountRemaining)}
              </p>
            </div>}
          </div>
        </div>
      </div>
    );
  }

}
