import _ from "lodash";
import * as types from "./types";

const initialState = {
  prospect: {
    loading: true,
    data: null,
    error: null,
  },
  prospectMiReportGroupings: {
    loading: true,
    data: null,
    error: null,
  },
  prospects: {
    loading: true,
    data: [],
    error: null,
    searchText: "",
    pagination: {
      page: 1,
      limit: 25,
      pages: 1,
      total: 0,
    },
  },
  prospectContacts: {
    loading: true,
    data: [],
    error: null,
    submitting: false,
  },
  prospectDisabledFields: {
    loading: true,
    data: [],
    error: null,
  },
  agencyDetails: {
    loading: true,
    data: null,
    error: null,
    submitting: null,
  },
  backOfficeContacts: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  bankInformation: {
    loading: true,
    data: null,
    error: null,
    submitting: null,
  },
  branch: {
    loading: true,
    data: null,
    error: null,
    submitting: null,
  },
  businessDevelopmentManagers: {
    loading: true,
    data: [],
    error: null,
  },
  companyInfo: {
    loading: true,
    prospect: null,
    certificateOfIncorporationDocument: null,
    contractDocument: null,
    branches: [],
  },
  companyHistory: {
    loading: true,
    contract_start_date: [],
    legal_name: [],
    trading_as: [],
  },
  companiesHouseInfo: {
    loading: true,
    data: null,
    error: null,
  },
  companiesHouseResults: {
    loading: false,
    nameResults: {
      total: 0,
      data: [],
    },
    numberResults: {
      total: 0,
      data: [],
    },
    error: null,
  },
  contractInformation: {
    loading: true,
    data: null,
    error: null,
    submitting: null,
  },
  customers: {
    loading: false,
    data: [],
    error: null,
    pagination: {
      page: 1,
      pages: 1,
      limit: 25,
    },
  },
  holidayPayInfo: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  miReportGrouping: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  payrollInfo: {
    loading: true,
    data: null,
    workerTypes: [],
    error: null,
    hasNewOnboardingTasks: null,
  },
  payrollHistory: {
    loading: true,
    data: null,
    error: null,
  },
  payrollGeneral: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  pensionsInfo: {
    loading: true,
    data: null,
    error: null,
    submitting: null,
  },
  reportingInfo: {
    loading: true,
    data: null,
    error: null,
  },
  shortCodeAvailability: {
    loading: false,
    isAvailable: true,
    error: null,
  },
  tradingAddress: {
    loading: true,
    data: null,
    error: null,
    submitting: false,
  },
  vatInformation: {
    loading: true,
    data: null,
    error: null,
    submitting: null,
  },
  ui: {
    submitting: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_PROSPECT_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.ADD_PROSPECT_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.ADD_PROSPECT_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.ADD_PROSPECT_CONTACT_PENDING:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: true,
        },
      };

    case types.ADD_PROSPECT_CONTACT_SUCCESS:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          data: [
            ...state.prospectContacts.data,
            action.payload.prospectContact,
          ],
        },
      };

    case types.ADD_PROSPECT_CONTACT_FAILURE:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.ARCHIVE_PROSPECT_CONTACT_PENDING:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: true,
        },
      };

    case types.ARCHIVE_PROSPECT_CONTACT_SUCCESS:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          data: state.prospectContacts.data.map((contact) => {
            return contact.id !== action.payload.prospectContact.id
              ? contact
              : action.payload.prospectContact;
          }),
        },
      };

    case types.ARCHIVE_PROSPECT_CONTACT_FAILURE:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.CHECK_SHORT_CODE_EXISTS_PENDING:
      return {
        ...state,
        shortCodeAvailability: {
          loading: true,
          isAvailable: true,
          error: null,
        },
      };

    case types.CHECK_SHORT_CODE_EXISTS_SUCCESS:
      return {
        ...state,
        shortCodeAvailability: {
          loading: false,
          isAvailable: !action.payload.exists,
          error: null,
        },
      };

    case types.CHECK_SHORT_CODE_EXISTS_FAILURE:
      return {
        ...state,
        shortCodeAvailability: {
          loading: false,
          isAvailable: true,
          error: action.payload.error,
        },
      };

    case types.CONVERT_TO_AGENCY_PENDING:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitting: true,
        },
      };

    case types.CONVERT_TO_AGENCY_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.CONVERT_TO_AGENCY_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.DELETE_PROSPECT_CONTACT_PENDING:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: true,
        },
      };

    case types.DELETE_PROSPECT_CONTACT_SUCCESS:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          data: state.prospectContacts.data.filter((contact) => {
            return contact.id !== action.payload.prospectContact.id;
          }),
        },
      };

    case types.DELETE_PROSPECT_CONTACT_FAILURE:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_DETAILS_PENDING:
      return {
        ...state,
        agencyDetails: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_AGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        agencyDetails: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_AGENCY_DETAILS_FAILURE:
      return {
        ...state,
        agencyDetails: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_BANK_INFORMATION_PENDING:
      return {
        ...state,
        bankInformation: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_BANK_INFORMATION_SUCCESS:
      return {
        ...state,
        bankInformation: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_BANK_INFORMATION_FAILURE:
      return {
        ...state,
        bankInformation: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_BRANCH_PENDING:
      return {
        ...state,
        branch: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_BRANCH_SUCCESS:
      return {
        ...state,
        branch: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_BRANCH_FAILURE:
      return {
        ...state,
        branch: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_BUSINESS_DEVELOPMENT_MANAGERS_PENDING:
      return {
        ...state,
        businessDevelopmentManagers: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS:
      return {
        ...state,
        businessDevelopmentManagers: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_BUSINESS_DEVELOPMENT_MANAGERS_FAILURE:
      return {
        ...state,
        businessDevelopmentManagers: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_PENDING:
      return {
        ...state,
        companiesHouseInfo: initialState.companiesHouseInfo,
      };

    case types.GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_SUCCESS:
      return {
        ...state,
        companiesHouseInfo: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case types.GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_FAILURE:
      return {
        ...state,
        companiesHouseInfo: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_CONTRACT_INFORMATION_PENDING:
      return {
        ...state,
        contractInformation: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_CONTRACT_INFORMATION_SUCCESS:
      return {
        ...state,
        contractInformation: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_CONTRACT_INFORMATION_FAILURE:
      return {
        ...state,
        contractInformation: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_CUSTOMERS_PENDING:
      return {
        ...state,
        customers: initialState.customers,
      };

    case types.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: false,
          data: action.payload.customers,
          error: null,
        },
      };

    case types.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_HOLIDAY_PAY_INFO_PENDING:
      return {
        ...state,
        holidayPayInfo: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_HOLIDAY_PAY_INFO_SUCCESS:
      return {
        ...state,
        holidayPayInfo: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_HOLIDAY_PAY_INFO_FAILURE:
      return {
        ...state,
        holidayPayInfo: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_MI_REPORT_GROUPING_PENDING:
      return {
        ...state,
        miReportGrouping: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_MI_REPORT_GROUPING_SUCCESS:
      return {
        ...state,
        miReportGrouping: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_MI_REPORT_GROUPING_FAILURE:
      return {
        ...state,
        miReportGrouping: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_PROSPECT_PENDING:
    case types.GET_PROSPECT_TO_BE_CONVERTED_PENDING:
      return {
        ...state,
        prospect: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_PROSPECT_SUCCESS:
    case types.GET_PROSPECT_TO_BE_CONVERTED_SUCCESS:
      return {
        ...state,
        prospect: {
          loading: false,
          data: action.payload.prospect,
          error: null,
        },
      };

    case types.GET_PROSPECT_FAILURE:
    case types.GET_PROSPECT_TO_BE_CONVERTED_FAILURE:
      return {
        ...state,
        prospect: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_CONTACTS_PENDING:
      return {
        ...state,
        prospectContacts: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_PROSPECT_CONTACTS_SUCCESS:
      return {
        ...state,
        prospectContacts: {
          loading: false,
          data: action.payload.prospectContacts,
          error: null,
        },
      };

    case types.GET_PROSPECT_CONTACTS_FAILURE:
      return {
        ...state,
        prospectContacts: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_DISABLED_FIELDS_PENDING:
      return {
        ...state,
        prospectDisabledFields: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case types.GET_PROSPECT_DISABLED_FIELDS_SUCCESS:
      return {
        ...state,
        prospectDisabledFields: {
          loading: false,
          data: action.payload.prospectDisabledFields,
          error: null,
        },
      };

    case types.GET_PROSPECT_DISABLED_FIELDS_FAILURE:
      return {
        ...state,
        prospectDisabledFields: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_MI_REPORT_GROUPINGS_PENDING:
      return {
        ...state,
        prospectMiReportGroupings: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_PROSPECT_MI_REPORT_GROUPINGS_SUCCESS:
      return {
        ...state,
        prospectMiReportGroupings: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case types.GET_PROSPECT_MI_REPORT_GROUPINGS_FAILURE:
      return {
        ...state,
        prospectMiReportGroupings: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECTS_PENDING:
      return {
        ...state,
        prospects: {
          ...state.prospects,
          loading: true,
          error: null,
          searchText: action.payload.searchText,
        },
      };

    case types.GET_PROSPECTS_SUCCESS:
      // Occasionally search results may come back out of order so only update
      // if the result is the current searchText
      if (action.payload.searchText !== state.prospects.searchText) {
        return state;
      }

      return {
        ...state,
        prospects: {
          loading: false,
          data: action.payload.prospects,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_PROSPECTS_FAILURE:
      // Occasionally search results may come back out of order so only update
      // if the result is the current searchText
      if (action.payload.searchText !== state.prospects.searchText) {
        return state;
      }

      return {
        ...state,
        prospects: {
          ...initialState.prospects,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_BACK_OFFICE_CONTACTS_PENDING:
      return {
        ...state,
        backOfficeContacts: initialState.backOfficeContacts,
      };

    case types.GET_BACK_OFFICE_CONTACTS_SUCCESS:
      return {
        ...state,
        backOfficeContacts: {
          loading: false,
          data: action.payload,
        },
      };

    case types.GET_BACK_OFFICE_CONTACTS_FAILURE:
      return {
        ...state,
        backOfficeContacts: {
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_COMPANY_INFO_PENDING:
      return {
        ...state,
        companyInfo: {
          loading: true,
          prospect: null,
          certificateOfIncorporationDocument: null,
          contractDocument: null,
          branches: [],
          error: null,
        },
      };

    case types.GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfo: {
          loading: false,
          prospect: action.payload.prospect,
          certificateOfIncorporationDocument: action.payload.certificateOfIncorporationDocument,
          contractDocument: action.payload.contractDocument,
          branches: action.payload.branches,
        },
        companyHistory: action.payload.history,
      };

    case types.GET_COMPANY_INFO_FAILURE:
      return {
        ...state,
        companyInfo: {
          loading: false,
          prospect: null,
          certificateOfIncorporationDocument: null,
          contractDocument: null,
          branches: [],
          error: action.payload.error,
        },
      };

    case types.GET_COMPANY_HISTORY_PENDING:
      return {
        ...state,
        companyHistory: {
          loading: true,
          contract_start_date: [],
          legal_name: [],
          trading_as: [],
        },
      };

    case types.GET_COMPANY_HISTORY_SUCCESS:
      return {
        ...state,
        companyHistory: {
          loading: false,
          ...action.payload.revisions,
        },
      };

    case types.GET_COMPANY_HISTORY_FAILURE:
      return {
        ...state,
        companyHistory: {
          loading: false,
          contract_start_date: [],
          legal_name: [],
          trading_as: [],
          error: action.payload.error,
        },
      };

    case types.GET_PAYROLL_GENERAL_PENDING:
      return {
        ...state,
        payrollGeneral: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_PAYROLL_GENERAL_SUCCESS:
      return {
        ...state,
        payrollGeneral: {
          loading: false,
          data: action.payload,
          error: null,
          submitting: false,
        },
      };

    case types.GET_PAYROLL_GENERAL_FAILURE:
      return {
        ...state,
        payrollGeneral: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_PAYROLL_HISTORY_PENDING:
      return {
        ...state,
        payrollHistory: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_PAYROLL_HISTORY_SUCCESS:
      return {
        ...state,
        payrollHistory: {
          loading: false,
          data: action.payload.history,
          error: null,
        },
      };

    case types.GET_PAYROLL_HISTORY_FAILURE:
      return {
        ...state,
        payrollHistory: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };


    case types.GET_PAYROLL_INFO_PENDING:
      return {
        ...state,
        payrollInfo: initialState.payrollInfo,
      };

    case types.GET_PAYROLL_INFO_SUCCESS:
      return {
        ...state,
        payrollInfo: {
          loading: false,
          data: {
            ...action.payload.payrollInfo,
            pensionDocument: action.payload.pensionDocument,
            vatDocument: action.payload.vatDocument,
            disabledFields: action.payload.disabledFields,
          },
          workerTypes: action.payload.workerTypes,
        },
      };

    case types.GET_PAYROLL_INFO_FAILURE:
      return {
        ...state,
        payrollInfo: {
          ...initialState.payrollInfo,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_PENSIONS_INFO_PENDING:
      return {
        ...state,
        pensionsInfo: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_PENSIONS_INFO_SUCCESS:
      return {
        ...state,
        pensionsInfo: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_PENSIONS_INFO_FAILURE:
      return {
        ...state,
        pensionsInfo: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_REPORTING_INFO_PENDING:
      return {
        ...state,
        reportingInfo: initialState.reportingInfo,
      };

    case types.GET_REPORTING_INFO_SUCCESS:
      return {
        ...state,
        reportingInfo: {
          loading: false,
          data: action.payload.data,
        },
      };

    case types.GET_REPORTING_INFO_FAILURE:
      return {
        ...state,
        reportingInfo: {
          ...initialState.reportingInfo,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_TRADING_ADDRESS_PENDING:
      return {
        ...state,
        tradingAddress: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_TRADING_ADDRESS_SUCCESS:
      return {
        ...state,
        tradingAddress: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_TRADING_ADDRESS_FAILURE:
      return {
        ...state,
        tradingAddress: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.GET_VAT_INFORMATION_PENDING:
      return {
        ...state,
        vatInformation: {
          loading: true,
          data: null,
          error: null,
          submitting: false,
        },
      };

    case types.GET_VAT_INFORMATION_SUCCESS:
      return {
        ...state,
        vatInformation: {
          loading: false,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.GET_VAT_INFORMATION_FAILURE:
      return {
        ...state,
        vatInformation: {
          loading: false,
          data: null,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_PENDING:
      return {
        ...state,
        companiesHouseResults: {
          ...state.companiesHouseResults,
          loading: true,
          error: null,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_SUCCESS:
      return {
        ...state,
        companiesHouseResults: {
          loading: false,
          nameResults: action.payload.nameResults,
          numberResults: action.payload.numberResults,
          error: null,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_FAILURE:
      return {
        ...state,
        companiesHouseResults: {
          loading: false,
          nameResults: [],
          numberResults: [],
          error: action.payload.error,
        },
      };

    case types.UPDATE_AGENCY_DETAILS_PENDING:
      return {
        ...state,
        agencyDetails: {
          ...state.agencyDetails,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        agencyDetails: {
          ...state.agencyDetails,
          data: action.payload.prospect,
          error: null,
          submitting: false,
        },
        prospect: {
          ...state.prospect,
          data: _.merge(state.prospect.data, action.payload.prospect),
        },
      };

    case types.UPDATE_AGENCY_DETAILS_FAILURE:
      return {
        ...state,
        agencyDetails: {
          ...state.agencyDetails,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_BANK_INFORMATION_PENDING:
      return {
        ...state,
        bankInformation: {
          ...state.bankInformation,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_BANK_INFORMATION_SUCCESS:
      return {
        ...state,
        bankInformation: {
          ...state.bankInformation,
          data: action.payload.prospect,
          error: null,
          submitting: false,
        },
        prospect: {
          ...state.prospect,
          data: _.merge(state.prospect.data, action.payload.prospect),
        },
      };

    case types.UPDATE_BANK_INFORMATION_FAILURE:
      return {
        ...state,
        bankInformation: {
          ...state.bankInformation,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_CONTRACT_INFORMATION_PENDING:
      return {
        ...state,
        contractInformation: {
          ...state.contractInformation,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_CONTRACT_INFORMATION_SUCCESS:
      return {
        ...state,
        contractInformation: {
          ...state.contractInformation,
          data: action.payload.prospect,
          error: null,
          submitting: false,
        },
        prospect: {
          ...state.prospect,
          data: _.merge(state.prospect.data, action.payload.prospect),
        },
      };

    case types.UPDATE_CONTRACT_INFORMATION_FAILURE:
      return {
        ...state,
        contractInformation: {
          ...state.contractInformation,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_HOLIDAY_PAY_INFO_PENDING:
      return {
        ...state,
        holidayPayInfo: {
          ...state.holidayPayInfo,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_HOLIDAY_PAY_INFO_SUCCESS:
      return {
        ...state,
        holidayPayInfo: {
          ...state.holidayPayInfo,
          data: action.payload.data,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_HOLIDAY_PAY_INFO_FAILURE:
      return {
        ...state,
        holidayPayInfo: {
          ...state.holidayPayInfo,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_MI_REPORT_GROUPING_PENDING:
      return {
        ...state,
        miReportGrouping: {
          ...state.miReportGrouping,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_MI_REPORT_GROUPING_SUCCESS:
      return {
        ...state,
        miReportGrouping: {
          ...state.miReportGrouping,
          data: action.payload.prospect,
          error: null,
          submitting: false,
        },
        prospect: {
          ...state.prospect,
          data: _.merge(state.prospect.data, action.payload.prospect),
        },
      };

    case types.UPDATE_MI_REPORT_GROUPING_FAILURE:
      return {
        ...state,
        miReportGrouping: {
          ...state.miReportGrouping,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_PAYROLL_GENERAL_PENDING:
      return {
        ...state,
        payrollGeneral: {
          ...state.payrollGeneral,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_PAYROLL_GENERAL_SUCCESS:
      return {
        ...state,
        payrollGeneral: {
          ...state.payrollGeneral,
          data: action.payload,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_PAYROLL_GENERAL_FAILURE:
      return {
        ...state,
        payrollGeneral: {
          ...state.payrollGeneral,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_PENDING:
      return {
        ...state,
        backOfficeContacts: {
          ...state.backOfficeContacts,
          submitting: true,
        },
      };

    case types.UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_SUCCESS:
      return {
        ...state,
        backOfficeContacts: {
          ...state.backOfficeContacts,
          data: action.payload,
          error: action.payload.errors?.length > 0 ? action.payload.errors.join("\n") : null,
          submitting: false,
        },
      };

    case types.UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_FAILURE:
      return {
        ...state,
        backOfficeContacts: {
          ...state.backOfficeContacts,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_PROSPECT_CONTACT_PENDING:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: true,
        },
      };

    case types.UPDATE_PROSPECT_CONTACT_SUCCESS:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          data: state.prospectContacts.data
            .map((contact) => {
              return contact.id !== action.payload.prospectContact.id
                ? contact
                : action.payload.prospectContact;
            })
            .sort(contact => -contact.is_primary_contact),
        },
      };

    case types.UPDATE_PROSPECT_CONTACT_FAILURE:
      return {
        ...state,
        prospectContacts: {
          ...state.prospectContacts,
          submitting: false,
          error: action.payload.error,
        },
      };

    case types.UPDATE_TRADING_ADDRESS_PENDING:
      return {
        ...state,
        tradingAddress: {
          ...state.tradingAddress,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_TRADING_ADDRESS_SUCCESS:
      return {
        ...state,
        tradingAddress: {
          ...state.tradingAddress,
          data: action.payload.prospect,
          error: null,
          submitting: false,
        },
        prospect: {
          ...state.prospect,
          data: _.merge(state.prospect.data, action.payload.prospect),
        },
      };

    case types.UPDATE_TRADING_ADDRESS_FAILURE:
      return {
        ...state,
        tradingAddress: {
          ...state.tradingAddress,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_BRANCH_PENDING:
      return {
        ...state,
        branch: {
          ...state.branch,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        branch: {
          ...state.branch,
          data: action.payload.branch,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_BRANCH_FAILURE:
      return {
        ...state,
        branch: {
          ...state.branch,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_VAT_INFORMATION_PENDING:
      return {
        ...state,
        vatInformation: {
          ...state.vatInformation,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_VAT_INFORMATION_SUCCESS:
      return {
        ...state,
        vatInformation: {
          ...state.vatInformation,
          data: action.payload.prospect,
          error: null,
          submitting: false,
        },
        prospect: {
          ...state.prospect,
          data: _.merge(state.prospect.data, action.payload.prospect),
        },
      };

    case types.UPDATE_VAT_INFORMATION_FAILURE:
      return {
        ...state,
        vatInformation: {
          ...state.vatInformation,
          error: action.payload.error,
          submitting: false,
        },
      };

    case types.UPDATE_PENSIONS_INFO_PENDING:
      return {
        ...state,
        pensionsInfo: {
          ...state.pensionsInfo,
          error: null,
          submitting: true,
        },
      };

    case types.UPDATE_PENSIONS_INFO_SUCCESS:
      return {
        ...state,
        pensionsInfo: {
          ...state.pensionsInfo,
          data: action.payload.prospect,
          error: null,
          submitting: false,
        },
      };

    case types.UPDATE_PENSIONS_INFO_FAILURE:
      return {
        ...state,
        pensionsInfo: {
          ...state.pensionsInfo,
          error: action.payload.error,
          submitting: false,
        },
      };

    default:
      return state;
  }
}
