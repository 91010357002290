/* eslint-disable no-console */
import * as types from "./types";
import * as GQL_TYPES from "./gqltypes";
import { apolloClient } from "../../../apolloClient";

export const getPermissions = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PERMISSIONS_PENDING,
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_PERMISSIONS,
    });

    dispatch({
      type: types.GET_PERMISSIONS_SUCCESS,
      payload: {
        permissions: response.data.getPermissions,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PERMISSIONS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getUserGroups = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_USER_GROUPS_PENDING,
    });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_USER_GROUPS,
    });

    dispatch({
      type: types.GET_USER_GROUPS_SUCCESS,
      payload: {
        userGroups: response.data.getUserGroups,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_USER_GROUPS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const assignUserPermissionGroups = ({ user_id, permissionGroups }) => async (dispatch) => {
  try {
    dispatch({
      type: types.ASSIGN_USER_GROUP_USER_BATCH_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.ASSIGN_USER_GROUP_USER_BATCH(user_id, permissionGroups),
    });

    dispatch({
      type: types.ASSIGN_USER_GROUP_USER_BATCH_SUCCESS,
      payload: {
        assignedUserGroups: response.data,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.ASSIGN_USER_GROUP_USER_BATCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const unassignUserPermissionsGroups = ({ user_id, permissionGroups }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UNASSIGN_USER_GROUP_USER_BATCH_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.UNASSIGN_USER_GROUP_USER_BATCH(user_id, permissionGroups),
    });

    dispatch({
      type: types.UNASSIGN_USER_GROUP_USER_BATCH_SUCCESS,
      payload: {
        assignedUserGroups: response.data,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.UNASSIGN_USER_GROUP_USER_BATCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
