import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import { BranchListView } from "../../";
import {
  Form,
  IconButton,
} from "../../../../common/components";
import styles from "./BranchesSection.module.scss";

export default function BranchesSection(props) {
  const addressRenderer = (address) => {
    return (
      <div>
        {address?.address_line_one && (
          <>
            <span>{address.address_line_one}</span>
            <br />
          </>
        )}
        {address?.address_line_two && (
          <>
            <span>{address.address_line_two}</span>
            <br />
          </>
        )}
        {address?.town_or_city && (
          <>
            <span>{address.town_or_city}</span>
            <br />
          </>
        )}
        {address?.county && (
          <>
            <span>{address.county}</span>
            <br />
          </>
        )}
        {address?.postcode && (
          <>
            <span>{address.postcode}</span>
            <br />
          </>
        )}
      </div>
    );
  };

  return (
    <Form.Card
      className={styles.container}
      title="Branches"
    >
      <BranchListView>
        {props.branches.map((value, index) => (
          <div key={index}>
            <BranchListView.Item
              index={index}
              active={false}
              branch={{
                name: value.branch.value,
                address: addressRenderer(value),
              }}
              rightAccessory={(
                <IconButton
                  icon={<Edit />}
                  tooltip="Edit"
                  disabled={!props.onEditClick}
                  onClick={() => props.onEditClick(value.id)}
                />
              )}
              readOnly
            />
          </div>
        ))}
      </BranchListView>
    </Form.Card>
  );
}

BranchesSection.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.shape({
    address_line_one: PropTypes.string,
    address_line_two: PropTypes.string,
    town_or_city: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    branch: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }).isRequired,
  })),
  onEditClick: PropTypes.func,
};

BranchesSection.defaultProps = {
  branches: [],
};
