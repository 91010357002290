import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { MultiFieldFilterDropdown } from "modules/common/components";
import { options } from "../../../../constants";

export default function RequestHistoryFilter(props) {
  const formatInitialFilters = (initialFilters) => {
    const agencyIdValues = initialFilters?.agency_id?.map((agency_id) => {
      return "agency_" + agency_id;
    }) ?? [];

    const prospectIdValues = initialFilters?.prospect_id?.map((prospect_id) => {
      return "prospect_" + prospect_id;
    }) ?? [];

    return [{
      label: "Credit Requested By",
      values: [...agencyIdValues, ...prospectIdValues],
    }, {
      label: "Result",
      values: [...(initialFilters?.status ?? [])],
    }];
  };

  const initialFilters = formatInitialFilters(props.initialFilters);

  const [filters, setFilters] = useState(initialFilters);

  const agencyPossibleValues = props.creditRequests.filters
    ?.find(filter => filter.field === "agency.id")?.possible_values.map(possible_value => ({
      value: "agency_" + possible_value.value,
      displayValue: possible_value.display_value,
      checked: filters.find(filter => filter.label === "Credit Requested By").values.includes("agency_" + possible_value.value),
    })) ?? [];

  const prospectPossibleValues = props.creditRequests.filters
    ?.find(filter => filter.field === "prospect.id")?.possible_values.map(possible_value => ({
      value: "prospect_" + possible_value.value,
      displayValue: possible_value.display_value,
      checked: filters.find(filter => filter.label === "Credit Requested By").values.includes("prospect_" + possible_value.value),
    })) ?? [];

  const statusPossibleValues = props.creditRequests.filters
    ?.find(filter => filter.field === "status")?.possible_values.map(possible_value => ({
      value: possible_value.value,
      displayValue: options.REQUEST_HISTORY_FILTER_FIELD_OPTIONS?.find((option) => {
        return option.value === possible_value.value;
      })?.label || null,
      checked: filters.find(filter => filter.label === "Result").values.includes(possible_value.value),
    })) ?? [];

  const filterableFields = [{
    label: "Credit Requested By",
    possibleValues: [...agencyPossibleValues, ...prospectPossibleValues],
  }, {
    label: "Result",
    possibleValues: statusPossibleValues,
  }];

  const formatFiltersForRequest = (filters) => {
    const agencyValues = filters[
      filters.findIndex(filter => filter.label === "Credit Requested By")
    ].values
      .filter(value => value.startsWith("agency"))
      .map(value => value.slice(7));

    const prospectValues = filters[
      filters.findIndex(filter => filter.label === "Credit Requested By")
    ].values
      .filter(value => value.startsWith("prospect"))
      .map(value => value.slice(9));

    const statusValues = filters[filters.findIndex(filter => filter.label === "Result")].values;

    return {
      agency_id: agencyValues.length > 0 ? agencyValues : undefined,
      prospect_id: prospectValues.length > 0 ? prospectValues : undefined,
      status: statusValues.length > 0 ? statusValues : undefined,
    };
  };

  const onChange = (e) => {
    const incomingFilter = {
      label: e.split(":")[0],
      value: e.split(":")[1],
    };

    const newFilters = _.cloneDeep(filters);

    const filterIndex = newFilters.findIndex(filter => filter.label === incomingFilter.label);

    const isSelected = newFilters[filterIndex]
      .values.some(value => value === incomingFilter.value);

    if (isSelected) {
      _.remove(newFilters[filterIndex].values, value => value === incomingFilter.value);
    }
    else {
      newFilters[filterIndex].values.push(incomingFilter.value);
    }

    setFilters(newFilters);

    props.onFiltersChange(formatFiltersForRequest(newFilters));
  };

  const onClearFilters = () => {
    setFilters(initialFilters);

    props.onFiltersChange(formatFiltersForRequest(initialFilters));
  };

  return (
    <MultiFieldFilterDropdown
      fields={filterableFields}
      busy={props.creditRequests.loading}
      onChange={onChange}
      onClearFilters={onClearFilters} />
  );
}

RequestHistoryFilter.propTypes = {
  legalEntityId: PropTypes.string.isRequired,
  creditRequests: PropTypes.shape({
    loading: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      possible_values: PropTypes.arrayOf({
        value: PropTypes.string,
        display_value: PropTypes.string,
      }),
    }),
    ) }).isRequired,
  initialFilters: PropTypes.shape({
    agency_id: PropTypes.array,
    prospect_id: PropTypes.array,
    status: PropTypes.array,
  }),
  onFiltersChange: PropTypes.func.isRequired,
};
