import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, Form } from "../../../../../common/components";
import styles from "./BackOfficeContactModal.module.scss";
import BackOfficeContactModalValidator from "./BackOfficeContactModalValidator";

export default function BackOfficeContactModal(props) {
  const [state, setState] = useState({
    payrollAccountManagerId: props.payrollAccountManagerId,
    payrollAccountManagerBuddyId: props.payrollAccountManagerBuddyId,
    creditControllerId: props.creditControllerId,
    creditControllerBuddyId: props.creditControllerBuddyId,
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    const validator = new BackOfficeContactModalValidator(state);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }

    props.onSubmit(state);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      visible={props.visible}
      title="Edit Back Office Contacts"
      submitText="Save"
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
    >
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline error={errors.payrollAccountManagerId}>
            <Form.Label inline required>
              Payroll Account Manager
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "payroll",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.payrollAccountManagerId}
              onChange={e => setState({ ...state, payrollAccountManagerId: e.target.value })}
              highlightError={errors.payrollAccountManagerId}
              clearable
              fluid
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>
              Payroll Account Manager Buddy
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "payroll",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.payrollAccountManagerBuddyId}
              onChange={e => setState({ ...state, payrollAccountManagerBuddyId: e.target.value })}
              clearable
              fluid
            />
          </Form.Group>
          <Form.Group inline error={errors.creditControllerId}>
            <Form.Label inline required>
              Credit Controller
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "credit control",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.creditControllerId}
              onChange={e => setState({ ...state, creditControllerId: e.target.value })}
              highlightError={errors.creditControllerId}
              clearable
              fluid
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>
              Credit Controller Buddy
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "credit control",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.creditControllerBuddyId}
              onChange={e => setState({ ...state, creditControllerBuddyId: e.target.value })}
              clearable
              fluid
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

BackOfficeContactModal.propTypes = {
  visible: PropTypes.bool,
  payrollAccountManagerId: PropTypes.string,
  payrollAccountManagerBuddyId: PropTypes.string,
  creditControllerId: PropTypes.string,
  creditControllerBuddyId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
