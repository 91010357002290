import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import EditCustomerModal from "./EditCustomerModal";

const mapStateToProps = state => ({
  submitting: state.customers.ui.submitting,
  loading: state.customers.agencyCustomer.loading,
  agencyCustomer: state.customers.agencyCustomer.data ?? {},
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(EditCustomerModal);

container.displayName = EditCustomerModal.displayName;

export default container;
