import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import WtrHolidayFormValidator from "./WtrHolidayFormValidator";
import {
  Alert,
  Button,
  ButtonGroup,
  DisabledFieldLock,
  Form,
} from "../../../../common/components";
import { List } from "../../../../lists/containers";
import { tooltips } from "../../../../../constants";
import styles from "./WtrHolidayForm.module.scss";

const EDIT_DISABLED_FIELD_CHECK = "wtr_holiday.has_holiday_pot";

export default class WtrHolidayForm extends Component {

  static propTypes = {
    data: PropTypes.shape({
      has_holiday_pot: PropTypes.bool,
      default_holiday_scheme: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      holiday_accrual_period: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      end_of_holiday_year_pay_period: PropTypes.number,
      end_of_holiday_year_tax_year_end: PropTypes.number,
      can_carry_over_accrual: PropTypes.bool,
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        agency_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }),
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        has_holiday_pot: props.data?.has_holiday_pot,
        default_holiday_scheme: props.data?.default_holiday_scheme,
        holiday_accrual_period: props.data?.holiday_accrual_period,
        end_of_holiday_year_pay_period: props.data?.end_of_holiday_year_pay_period,
        end_of_holiday_year_tax_year_end: props.data?.end_of_holiday_year_tax_year_end,
        can_carry_over_accrual: props.data?.can_carry_over_accrual,
      },
      errors: {},
    };
  }

  render() {
    const isDisabled = this.isEditDisabled();

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={12}>
          {isDisabled && (
            <Alert className={styles.onboardingAlert} variant="info" showClose={false}>
              <div className={styles.onboardingAlertContent}>
                <DisabledFieldLock
                  className={styles.lockIcon}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  onClick={() => this.handleDisabledFieldClick(EDIT_DISABLED_FIELD_CHECK)}
                />
                The Onboarding Task for this has not been completed yet.
              </div>
            </Alert>
          )}
          <Form className={styles.form}>
            <Form.Section title="WTR / Holidays" titleClassName={styles.title}>
              <Form.Group
                className={classnames(
                  styles.radioGroup,
                  this.props.submitting && styles.submitting
                )}
                inline
                wide
                error={this.state.errors?.has_holiday_pot}
              >
                <Form.Label className={styles.radioLabel} inline>
                  Holiday Pot
                </Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="has_holiday_pot"
                    checked={this.state.data.has_holiday_pot}
                    text="Yes"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="has_holiday_pot"
                    checked={!this.state.data.has_holiday_pot}
                    text="No"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.default_holiday_scheme}
              >
                <Form.Label inline>
                  Default Holiday Scheme
                </Form.Label>
                <Form.Value>
                  <List
                    identifier="default_holiday_scheme"
                    size="medium"
                    value={this.state.data.default_holiday_scheme?.value}
                    tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                    disabled={isDisabled}
                    onChange={this.handleListValueChange}
                    highlightError={this.state.errors?.default_holiday_scheme}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group
                className={classnames(
                  styles.radioGroup,
                  this.props.submitting && styles.submitting
                )}
                inline
                wide
                error={this.state.errors?.holiday_accrual_period}
              >
                <Form.Label className={styles.radioLabel} inline>
                  Holiday Accrual Period
                </Form.Label>
                <Form.ValueInline>
                  <List
                    identifier="holiday_accrual_period"
                    size="medium"
                    value={this.state.data.holiday_accrual_period?.value}
                    tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                    disabled={isDisabled}
                    onChange={this.handleListValueChange}
                    highlightError={this.state.errors?.holiday_accrual_period}
                  />
                </Form.ValueInline>
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.end_of_holiday_year}
              >
                <Form.Label inline>
                  End of Holiday Year (Pay Period / Tax Year)
                </Form.Label>
                <Form.TaxPeriod
                  week={this.state.data.end_of_holiday_year_pay_period}
                  year={this.state.data.end_of_holiday_year_tax_year_end}
                  disabled={isDisabled || this.state.data.holiday_accrual_period?.value !== "holiday_year"}
                  onChange={this.handleTaxPeriodChange}
                  highlightError={this.state.errors?.end_of_holiday_year}
                />
              </Form.Group>
              <Form.Group
                className={classnames(
                  styles.radioGroup,
                  this.props.submitting && styles.submitting
                )}
                inline
                wide
                error={this.state.errors?.has_holiday_pot}
              >
                <Form.Label className={styles.radioLabel} inline>
                  Carry Over Accrual
                </Form.Label>
                <div>
                  <Form.Radio
                    value={true}
                    name="can_carry_over_accrual"
                    checked={this.state.data.can_carry_over_accrual}
                    text="Yes"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                  <Form.Radio
                    value={false}
                    name="can_carry_over_accrual"
                    checked={!this.state.data.can_carry_over_accrual}
                    text="No"
                    disabled={isDisabled}
                    onChange={this.handleRadioChange}
                  />
                </div>
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              disabled={this.props.submitting || isDisabled}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleRadioChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: String(e.target.value) === "true",
      },
    });
  }

  handleListValueChange = (e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: item,
      },
    });
  }

  handleTaxPeriodChange = ({ week, year }) => {
    this.setState({
      data: {
        ...this.state.data,
        end_of_holiday_year_pay_period: week,
        end_of_holiday_year_tax_year_end: year,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const data = { ...this.state.data };

    // We should only hit this if someone removes the disabled attribute from
    // the Submit button manually...
    if (this.isEditDisabled()) {
      return;
    }

    if (data.holiday_accrual_period?.value !== "holiday_year") {
      data.end_of_holiday_year_pay_period = null;
      data.end_of_holiday_year_tax_year_end = null;
    }

    const validator = new WtrHolidayFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit && onSubmit({
      ...data,
      default_holiday_scheme_id: +data.default_holiday_scheme?.id,
      holiday_accrual_period_id: +data.holiday_accrual_period?.id,
    }, this.props);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick && onDisabledFieldClick(disabledField);
  }

  isEditDisabled = () => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === EDIT_DISABLED_FIELD_CHECK);
  }

}

