import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Clear } from "@material-ui/icons";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Squares as ActivityIndicator } from "react-activity";
import { Popup, Grid } from "semantic-ui-react";
import MultiFieldFilterFieldRow from "./MultiFieldFilterFieldRow";
import styles from "./MultiFieldFilterDropdown.module.scss";

export default function MultiFieldFilterDropdown(props) {
  const getFilterAmount = () => {
    return props.fields.reduce((count, field) => {
      return count + _.filter(field.possibleValues, (possibleValue => possibleValue.checked)).length;
    }, 0);
  };

  return (
    <div className={styles.componentContainer}>
      <Popup
        trigger={(
          <div className={styles.filterContainer}>
            <FilterListIcon className={styles.filterIcon} />
            <span className={styles.filterTitle}>Filter</span>
          </div>
        )}
        on="click"
        position="bottom right"
        eventsEnabled
      >
        <Popup.Content className={styles.popupContent}>
          { props.busy ? (
            <ActivityIndicator />
          ) : (
            <Grid className={styles.popupContainer}>
              <Grid.Row className={styles.popupContentRow}>
                <div
                  className={classnames(styles.popupContentRowContainer, styles.clearFiltersContainer)}
                  onClick={props.onClearFilters} >
                  <Clear className={styles.clearFiltersIcon} />
                  <span className={styles.clearFiltersTitle}>Clear Filters</span>
                </div>
              </Grid.Row>
              {props.fields.map(field => (
                <MultiFieldFilterFieldRow
                  key={field.label}
                  field={field}
                  onChange={props.onChange} />
              ))}
            </Grid>
          )}
        </Popup.Content>
      </Popup>
      <div className={styles.filterAmountContainer}>
        <span className={styles.filterAmount}>{getFilterAmount()}</span>
      </div>
    </div>
  );
}

MultiFieldFilterDropdown.propTypes = {
  fields: PropTypes.arrayOf({
    label: PropTypes.string,
    possibleValues: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      checked: PropTypes.bool,
    }),
    ) }).isRequired,
  busy: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
};
