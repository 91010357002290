import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import DropDownItem from "./DropDownItem";
import Label from "../Label";
import "./DropDown.scss";

const keyCodes = {
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  ESCAPE: 27,
  ENTER: 13,
  SPACE: 32,
};

class DropDown extends Component {

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })),
  }

  state = { dropDownVisible: false, selectedIndex: -1 };

  dropdownRef = React.createRef();

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseDown, false);
  }

  handleMouseDown = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target) && this.state.dropDownVisible ) {
      this.setState({ dropDownVisible: false });
    }
  }

  handleOnChange = (value) => {
    const selectedValue = {
      name: this.props.name,
      value: value,
    };
    this.props.onChange(selectedValue);

  }

  handleDropDownClicked = () => {
    if (this.props.disabled) return;
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  handleChildClicked = (index) => {
    this.handleOnChange(this.props.data[index].value);
  }

  handleKeyDown = (e) => {
    if (this.state.dropDownVisible) {
      e.preventDefault();
      if (e.keyCode === keyCodes.DOWN_ARROW) {
        if (this.state.selectedIndex < this.props.data.length - 1) {
          this.setState({ selectedIndex: this.state.selectedIndex + 1 });
        }
      }
      else if (e.keyCode === keyCodes.UP_ARROW) {
        if (this.state.selectedIndex !== 0) {
          this.setState({ selectedIndex: this.state.selectedIndex +- 1 });
        }
      }
      else if (e.keyCode === keyCodes.ENTER || e.keyCode === keyCodes.SPACE) {
        if (this.state.selectedIndex >= 0) {
          this.handleOnChange(this.props.data[this.state.selectedIndex].value);
        }
        this.setState({ dropDownVisible: false });
      }
      else if (e.keyCode === keyCodes.ESCAPE) {
        this.setState({ dropDownVisible: false });
      }
    }
    else {
      if (e.keyCode === keyCodes.SPACE) {
        this.setState({ dropDownVisible: true });
      }
    }
  }

  calculateStyle = () => {
    if (this.dropdownRef && this.dropdownRef.current) {
      const getInt = str => Number(str.substring(0, str.length - 2));
      const bounds = this.dropdownRef.current.getBoundingClientRect();
      const top = bounds.height;
      const computed = window.getComputedStyle(this.dropdownRef.current);
      const borderWidths = getInt(computed.getPropertyValue("border-left-width")) + getInt(computed.getPropertyValue("border-right-width"));
      const width = bounds.width - borderWidths;
      return { top, width };
    }
    return {};
  }

  renderItems = () => {
    if (this.props.data) {
      return this.props.data.map((value, index) => (
        <DropDownItem
          key={index}
          value={String(value.value)}
          selected={this.state.selectedIndex === index}
          onClick={() => this.handleChildClicked(index)}
        >
          {value.text}
        </DropDownItem>
      ));
    }
  }

  render = () => {
    const { className, style, placeholder, value, disabled, readOnly } = this.props;
    const showDropDown = this.state.dropDownVisible && "visible";
    const fontColour = value ? "black" : "gray";
    const dropArrow = this.state.dropDownVisible ? faChevronUp : faChevronDown;
    const selectedValue = this.props.data?.find(val => String(val.value) === String(value));
    const textValue = value != null && selectedValue ? selectedValue.text : placeholder;
    return (
      <div
        className={classnames("DropDown", className, readOnly && "DropDown--readonly")}
        style={style}
        onClick={this.handleDropDownClicked}
        disabled={disabled}
        ref={this.dropdownRef}
        tabIndex={0}
        onKeyDown={this.handleKeyDown}
      >
        <Label className="DropDown--selected-value" selectable={false} style={{ color: fontColour }}>{textValue || ""}</Label>
        {readOnly ||
          <>
            <FontAwesomeIcon icon={dropArrow} />
            <div style={this.calculateStyle()} className={classnames("DropDownList", showDropDown)}>
              {this.renderItems()}
            </div>
          </>
        }
      </div>
    );
  }
}

DropDown.Item = DropDownItem;

export default DropDown;
