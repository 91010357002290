import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_AGENCY_CUSTOMERS = gql`
  query(
    $sort_order: [SortOrder]
    $pager: Pager
    $search_text: String
    ) {
      getAgencyCustomers(
        pager: $pager
        sort_order: $sort_order
        search_text: $search_text
    ) {
      data {
        id
        agency {
          id
          known_as
        }
        prospect {
          id
          known_as
        }
        display_name
        number_of_contracts
        company_type

        address_line_1
        credit_limit_requested
      }

      total
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {object[]} obj.sortOrder
 * @param {string} obj.searchText
 * @param {object[]} obj.filters
 * @param {object} obj.pager
 * @param {number} obj.pager.page
 * @param {number} obj.pager.limit
 * @returns
 */
export const getCustomers = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_CUSTOMERS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_CUSTOMERS,
      variables: {
        filter_options: {
          ...obj.filters,
        },
        pager: {
          page: obj.pager?.page ?? 1,
          limit: obj.pager?.limit ?? 25,
        },
        sort_order: SortOrderConverter(obj.sortOrder || {}),
        search_text: obj.searchText,
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    dispatch({
      type: types.GET_AGENCY_CUSTOMERS_SUCCESS,
      payload: {
        data: response.data.getAgencyCustomers.data,
        total: response.data.getAgencyCustomers.total,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_CUSTOMERS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
