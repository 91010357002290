import { Auth } from "aws-amplify";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const REFRESH_ME = gql`
mutation {
  refreshMe {
    id
    cognito_username
    type
    status
    first_name
    last_name
    email
    created_at
    updated_at

    user_groups {
      name
    }

    department {
      name
    }
  }
}
`;


export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CURRENT_USER_PENDING,
    });

    const user = await Auth.currentAuthenticatedUser();
    const response = await apolloClient.mutate({
      mutation: REFRESH_ME,
    });
    const metadata = response.data.refreshMe;

    const userWithMetadata = {
      ...user,
      metadata,
    };

    dispatch({
      type: types.GET_CURRENT_USER_SUCCESS,
      payload: {
        user: userWithMetadata,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CURRENT_USER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
