import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PROSPECT_ONBOARDING_TASKS = gql`
query($prospect_id: ID!, $filter_options: OnboardingTasksFilterOptions) {
  getProspectOnboardingTasks(prospect_id: $prospect_id, filter_options: $filter_options) {
    id
    onboarding_task {
      id
      name
      weight
    }
    progress {
      required_completed
      required_total
    }
    status
    due_date
  }
}
`;

export const getProspectTasks = ({
  prospectId,
  filterOptions,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PROSPECT_TASKS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PROSPECT_ONBOARDING_TASKS,
      variables: {
        prospect_id: prospectId,
        filter_options: filterOptions,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const prospectTasks = response.data.getProspectOnboardingTasks;

    dispatch({
      type: types.GET_PROSPECT_TASKS_SUCCESS,
      payload: {
        prospectTasks,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_PROSPECT_TASKS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
