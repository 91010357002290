import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Dropdown } from "semantic-ui-react";
import { DisabledFieldLock } from "../../";
import styles from "./FormSelect.module.scss";

export default function FormSelect({
  className,
  options,
  size,
  readOnly,
  highlightError,
  showLock,
  tooltip,
  onLockClick,
  onChange,
  ...otherProps
}) {
  if (readOnly) {
    const text = options
      ?.find(option => option.value === otherProps.value)
      ?.label;

    return (
      <div
        className={classnames(
          className,
          styles.readOnly,
        )}
      >
        {text || "-"}
      </div>
    );
  }

  if (otherProps.disabled && showLock) {
    return (
      <Dropdown
        className={classnames(
          className,
          styles.container,
          styles[size],
          styles.disabled,
          highlightError && styles.highlightError
        )}
        icon={showLock && (
          <DisabledFieldLock
            className={styles.lock}
            tooltip={tooltip}
            onClick={onLockClick}
          />
        )}
        defaultValue={otherProps.value}
        selection
        open={false}
        options={options.map(option => ({
          label: undefined,
          text: options.text || options.label,
          ...option,
        }))}
        {...otherProps}
      />
    );
  }

  return (
    <Dropdown
      className={classnames(
        className,
        styles.container,
        styles[size],
        otherProps.disabled && styles.disabled,
        highlightError && styles.highlightError
      )}
      defaultValue={otherProps.value}
      selection
      selectOnBlur={false}
      options={options.map(option => ({
        ...option,
        text: option.text || option.label,
        label: undefined,
      }))}
      onChange={(_e, { name, value }) => {
        onChange({
          target: { name, value },
        }, options.find(option => option.value === value));
      }}
      {...otherProps}
    />
  );
}

FormSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  readOnly: PropTypes.bool,
  highlightError: PropTypes.bool,
  clearable: PropTypes.bool,
  showLock: PropTypes.bool,
  tooltip: PropTypes.any,
  onLockClick: PropTypes.func,
  ...Dropdown.propTypes,
};
