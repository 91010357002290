import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DOWNLOAD_PROSPECT_DOCUMENT = gql`
query($input: ProspectDocumentUrlInput!) {
  getProspectDocumentDownloadUrl(input: $input) {
    url
  }
}
`;

export const downloadProspectDocument = ({
  prospectId,
  documentType,
  versionId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_PENDING,
    });

    const response = await apolloClient.query({
      query: DOWNLOAD_PROSPECT_DOCUMENT,
      variables: {
        input: {
          prospect_id: prospectId,
          document_type: documentType,
          version_id: versionId,
        },
      },
    });
    const url = response.data.getProspectDocumentDownloadUrl.url;

    window.open(url, "_blank");

    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_SUCCESS,
    });
  }
  catch (err) {
    dispatch({
      type: types.DOWNLOAD_DOCUMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
