import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { Flag } from "@material-ui/icons";
import { Alert, TextLink } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./LegalEntityReportAlert.module.scss";

export default function LegalEntityReportAlert(props) {
  const [expanded, setExpanded] = useState(false);

  if (props.loading) {
    return null;
  }

  if (props.data.length === 0) {
    return null;
  }

  const renderSingleEntry = () => {
    const insurerReportingActivity = props.data[0];

    return (
      <div className={styles.alertWrapper}>
        <div className={styles.alertContent}>
          <Flag className={styles.icon} />
          <div className={styles.reportText}>
            Reported for <strong>{insurerReportingActivity.report_reason.label}</strong> on {parseDate(insurerReportingActivity.reported_at)}
          </div>
          <TextLink
            className={styles.unreportLink}
            text="Unreport"
            onClick={() => props.onUnreportClick(insurerReportingActivity)}
          />
        </div>
      </div>
    );
  };

  const renderMultipleEntries = () => {
    return (
      <div className={styles.alertWrapper}>
        <div className={styles.alertContent}>
          <Flag className={styles.icon} />
          <div className={styles.reportText}>
            {props.data.length} Reports to insurer
          </div>
        </div>
        <Dropdown
          icon={expanded ? "chevron up" : "chevron down"}
          scrolling
          floating
          direction="left"
          open={expanded}
          onClose={() => setExpanded(false)}
          onOpen={() => setExpanded(true)}
        >
          <Dropdown.Menu>
            {props.data.map(insurerReportingActivity => (
              <>
                <Dropdown.Item
                  key={insurerReportingActivity.id}
                  className={styles.dropdownItem}
                  content={(
                    <div className={styles.reportItem}>
                      <div>
                        Reported for <strong>{insurerReportingActivity.report_reason.label}</strong> on {parseDate(insurerReportingActivity.reported_at)}
                      </div>
                      <TextLink
                        className={styles.unreportLink}
                        text="Unreport"
                        onClick={() => props.onUnreportClick(insurerReportingActivity)}
                      />
                    </div>
                  )}
                />
                <Dropdown.Divider className={styles.separator} />
              </>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  return (
    <Alert className={styles.alert} showClose={false}>
      {props.data.length === 1 && renderSingleEntry()}
      {props.data.length > 1 && renderMultipleEntries()}
    </Alert>
  );
}

LegalEntityReportAlert.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      report_reason: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }),
      reported_by: PropTypes.string.isRequired,
      reported_at: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onUnreportClick: PropTypes.func.isRequired,
};
