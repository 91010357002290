import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import { actions as prospectActions } from "../../../prospects/duck";
import ProspectTasksPage from "./ProspectTasksPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.onboarding,
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  onFetchProspect: (prospectId) => {
    dispatch(prospectActions.getProspect(prospectId));
  },
  onFetchTasks: (prospectId) => {
    dispatch(actions.getProspectTasks({ prospectId }));
  },
  onViewProfileClick: (id) => {
    dispatch(push(`/prospects/${id}`));
  },
  onTaskClick: (prospectId, taskId) => {
    dispatch(push(`/onboarding/prospects/${prospectId}/${taskId}`));
  },
  onGoToProspects: () => {
    dispatch(push("/onboarding/prospects"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProspectTasksPage);
