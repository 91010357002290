import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Accordion } from "semantic-ui-react";
import { ExpandMore } from "@material-ui/icons";
import { Form } from "../../../../common/components";
import styles from "./BranchListItem.module.scss";

export default function BranchListItem(props) {
  const [active, setActive] = useState(!!props?.active);

  const handleChange = (e) => {
    props.onChange && props.onChange(props.index, {
      ...props.branch,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Accordion.Title
        className={styles.container}
        index={props.index}
        active={active}
        onClick={() => setActive(!active)}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {props.branch.name}
          </div>
          <ExpandMore
            className={classnames(
              styles.icon,
              active ? styles.collapse : styles.expand
            )}
          />
        </div>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <Form>
          <Form.Section>
            <Form.Group error={props.errors?.address_line_one}>
              <Form.Label>Address Line 1</Form.Label>
              <Form.Input
                placeholder="Address Line 1..."
                name="address_line_one"
                value={props.branch?.address_line_one}
                readOnly={props.readOnly}
                disabled={props.disabled}
                onChange={handleChange}
                highlightError={props.errors?.address_line_one}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.address_line_two}>
              <Form.Label note={props.readOnly || "(Optional)"}>Address Line 2</Form.Label>
              <Form.Input
                placeholder="Address Line 2..."
                name="address_line_two"
                value={props.branch?.address_line_two}
                readOnly={props.readOnly}
                disabled={props.disabled}
                onChange={handleChange}
                highlightError={props.errors?.address_line_two}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.town_or_city}>
              <Form.Label>Town / City</Form.Label>
              <Form.Input
                placeholder="Town / City..."
                name="town_or_city"
                value={props.branch?.town_or_city}
                readOnly={props.readOnly}
                disabled={props.disabled}
                onChange={handleChange}
                highlightError={props.errors?.town_or_city}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.county}>
              <Form.Label>County</Form.Label>
              <Form.Input
                placeholder="County..."
                name="county"
                value={props.branch?.county}
                readOnly={props.readOnly}
                disabled={props.disabled}
                onChange={handleChange}
                highlightError={props.errors?.county}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.postcode}>
              <Form.Label size="small">Postcode</Form.Label>
              <Form.Input
                size="small"
                placeholder="Postcode..."
                name="postcode"
                value={props.branch?.postcode}
                readOnly={props.readOnly}
                disabled={props.disabled}
                onChange={handleChange}
                highlightError={props.errors?.postcode}
                maxLength={8}
              />
            </Form.Group>
          </Form.Section>
        </Form>
      </Accordion.Content>
    </>
  );
}

BranchListItem.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.bool,
  branch: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address_line_one: PropTypes.string.isRequired,
    address_line_two: PropTypes.string,
    town_or_city: PropTypes.string.isRequired,
    county: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    address_line_one: PropTypes.string,
    address_line_two: PropTypes.string,
    town_or_city: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
