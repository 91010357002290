import _ from "lodash";
import * as types from "./types";


//TODO: split these into 2 seperate reducers!
const initialState = {
  loading: true,
  agencyTasks: {
    loading: true,
    data: [],
    error: null,
  },
  agencyTask: {
    loading: true,
    data: null,
    error: null,
    isSaving: true,
    isCompleting: true,
  },
  newAgency: {
    loading: false,
    existingShortCodes: [],
    shortCodeExists: false,
    error: null,
  },
  onboardingAgencies: {
    loading: true,
    data: [],
    error: null,
    searchText: "",
    pagination: {
      page: 1,
      limit: 25,
      pages: 1,
      total: 0,
    },
  },
  onboardingProspects: {
    loading: true,
    data: [],
    error: null,
    searchText: "",
    pagination: {
      page: 1,
      limit: 25,
      pages: 1,
      total: 0,
    },
  },
  onboardingTasks: {
    loading: true,
    data: [],
    error:null,
  },
  prospectTasks: {
    loading: true,
    data: [],
    error: null,
  },
  prospectTask: {
    loading: true,
    data: null,
    error: null,
  },
  task: {
    loading: true,
    data: null,
    agency: null,
    error: null,
  },
  error: null,
  companiesHouseResults: {
    loading: false,
    nameResults: {
      total: 0,
      data: [],
    },
    numberResults: {
      total: 0,
      data: [],
    },
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_AGENCY_TASKS_PENDING:
      return {
        ...state,
        agencyTasks: {
          loading: true,
          error: null,
          ...state.agencyTasks,
        },
      };

    case types.GET_AGENCY_TASKS_SUCCESS:
      return {
        ...state,
        agencyTasks: {
          loading: false,
          data: action.payload.agencyTasks,
          error: null,
        },
      };

    case types.GET_AGENCY_TASKS_FAILURE:
      return {
        ...state,
        agencyTasks: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_TASK_PENDING:
      return { ...state, agencyTask: initialState.agencyTask };

    case types.GET_AGENCY_TASK_SUCCESS:
      return { ...state, agencyTask: { loading: false, data: action.payload.agencyTask, isSaving: false, isCompleting: false } };

    case types.GET_AGENCY_TASK_FAILURE:
      return { ...state, agencyTask: { loading: false, error: action.payload.error, isSaving: false, isCompleting: false } };

    case types.ADD_AGENCY_TASK_TRACKING_ENTRY_SUCCESS:
      return {
        ...state,
        agencyTask: {
          ...state.agencyTask,
          data: {
            ...state.agencyTask.data,
            definition: {
              ...state.agencyTask.data.definition,
              subtasks: state.agencyTask.data.definition.subtasks.map((subtask) => {
                return subtask.field.name === action.payload.trackingEntry.field_name
                  ? {
                    ...subtask,
                    tracking: {
                      ...subtask.tracking,
                      entries: [
                        ...subtask.tracking.entries,
                        action.payload.trackingEntry,
                      ],
                    },
                  }
                  : subtask;
              }),
            },
          },
        },
      };

    case types.ADD_PROSPECT_TASK_TRACKING_ENTRY_SUCCESS:
      return {
        ...state,
        prospectTask: {
          ...state.prospectTask,
          data: {
            ...state.prospectTask.data,
            definition: {
              ...state.prospectTask.data.definition,
              subtasks: state.prospectTask.data.definition.subtasks.map((subtask) => {
                return subtask.field.name === action.payload.trackingEntry.field_name
                  ? {
                    ...subtask,
                    tracking: {
                      ...subtask.tracking,
                      entries: [
                        ...subtask.tracking.entries,
                        action.payload.trackingEntry,
                      ],
                    },
                  }
                  : subtask;
              }),
            },
          },
        },
      };

    case types.ADD_AGENCY_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        agencyTask: {
          ...state.agencyTask,
          data: {
            ...state.agencyTask.data,
            comments: [
              ...state.agencyTask.data.comments,
              action.payload.comment,
            ],
          },
        },
      };

    case types.ADD_PROSPECT_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        prospectTask: {
          ...state.prospectTask,
          data: {
            ...state.prospectTask.data,
            comments: [
              ...state.prospectTask.data.comments,
              action.payload.comment,
            ],
          },
        },
      };

    case types.GET_ONBOARDING_AGENCIES_PENDING:
      return {
        ...state,
        onboardingAgencies: {
          ...state.onboardingAgencies,
          searchText: action.payload.searchText,
          loading: true,
          error: null,
        },
      };

    case types.GET_ONBOARDING_AGENCIES_SUCCESS:
      // Occassionally search results may come back out of order so only update
      // if the result is the current searchText
      if (action.payload.searchText !== state.onboardingAgencies.searchText) {
        return state;
      }

      return {
        ...state,
        onboardingAgencies: {
          ...state.onboardingAgencies,
          loading: false,
          data: action.payload.data,
          searchText: action.payload.searchText,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_ONBOARDING_AGENCIES_FAILURE:
      // Occassionally search results may come back out of order so only update
      // if the result is the current searchText
      if (action.payload.searchText !== state.onboardingAgencies.searchText) {
        return state;
      }

      return {
        ...state,
        onboardingAgencies: {
          ...state.onboardingAgencies,
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_ONBOARDING_PROSPECTS_PENDING:
      return {
        ...state,
        onboardingProspects: {
          ...state.onboardingProspects,
          searchText: action.payload.searchText,
          loading: true,
          error: null,
        },
      };

    case types.GET_ONBOARDING_PROSPECTS_SUCCESS:
      // Occassionally search results may come back out of order so only update
      // if the result is the current searchText
      if (action.payload.searchText !== state.onboardingProspects.searchText) {
        return state;
      }

      return {
        ...state,
        onboardingProspects: {
          ...state.onboardingProspects,
          loading: false,
          data: action.payload.data,
          searchText: action.payload.searchText,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_ONBOARDING_PROSPECTS_FAILURE:
      // Occassionally search results may come back out of order so only update
      // if the result is the current searchText
      if (action.payload.searchText !== state.onboardingProspects.searchText) {
        return state;
      }

      return {
        ...state,
        onboardingProspects: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_ONBOARDING_TASK_PENDING:
      return {
        ...state,
        onboardingTasks: {
          ...state.onboardingTasks,
          loading: true,
        },
      };

    case types.GET_ONBOARDING_TASK_SUCCESS:
      return {
        ...state,
        onboardingTasks: {
          ...state.onboardingTasks,
          loading: false,
          data: state.onboardingTasks.data.map((onboardingTask) => {
            return onboardingTask.id !== action.payload.onboardingTask.id
              ? onboardingTask
              : {
                ...onboardingTask,
                ...action.payload.onboardingTask,
              };
          }),
        },
      };

    case types.GET_ONBOARDING_TASK_FAILURE:
      return {
        ...state,
        onboardingTasks: {
          ...state.onboardingTasks,
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_ONBOARDING_TASKS_PENDING:
      return {
        ...state,
        onboardingTasks: {
          ...state.onboardingTasks,
          loading: true,
        },
      };

    case types.GET_ONBOARDING_TASKS_SUCCESS:
      return {
        ...state,
        onboardingTasks: {
          ...state.onboardingTasks,
          loading: false,
          data: action.payload.onboardingTasks,
        },
      };

    case types.GET_ONBOARDING_TASKS_FAILURE:
      return {
        ...state,
        onboardingTasks: {
          ...state.onboardingTasks,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_PENDING:
      return {
        ...state,
        task: {
          ...state.task,
          loading: true,
          error: null,
        },
      };

    case types.GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_SUCCESS:
      return {
        ...state,
        task: {
          loading: false,
          data: action.payload.onboardingTask,
          agency: action.payload.agency,
          error: null,
        },
      };

    case types.GET_OUTSTANDING_AGENCIES_WITH_ONBOARDING_TASK_FAILURE:
      return {
        ...state,
        task: {
          loading: false,
          data: null,
          agency: null,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_TASK_PENDING:
      return {
        ...state,
        prospectTask: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_PROSPECT_TASK_SUCCESS:
      return {
        ...state,
        prospectTask: {
          loading: false,
          data: action.payload.prospectTask,
          error: null,
        },
      };

    case types.GET_PROSPECT_TASK_FAILURE:
      return {
        ...state,
        prospectTask: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_TASKS_PENDING:
      return {
        ...state,
        prospectTasks: {
          loading: true,
          error: null,
          ...state.prospectTasks,
        },
      };

    case types.GET_PROSPECT_TASKS_SUCCESS:
      return {
        ...state,
        prospectTasks: {
          loading: false,
          data: action.payload.prospectTasks,
          error: null,
        },
      };

    case types.GET_PROSPECT_TASKS_FAILURE:
      return {
        ...state,
        prospectTasks: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_PENDING:
      return {
        ...state,
        companiesHouseResults: {
          ...state.companiesHouseResults,
          loading: true,
          error: null,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_SUCCESS:
      return {
        ...state,
        companiesHouseResults: {
          loading: false,
          nameResults: action.payload.nameResults,
          numberResults: action.payload.numberResults,
          error: null,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_FAILURE:
      return {
        ...state,
        companiesHouseResults: {
          loading: false,
          nameResults: [],
          numberResults: [],
          error: action.payload.error,
        },
      };

    case types.AGENCY_ONBOARDING_TASK_PROGRESS_CHANGED:
      return {
        ...state,
        agencyTasks: {
          ...state.agencyTasks,
          data: state.agencyTasks.data.map((agencyTask) => {
            return agencyTask.id !== action.payload.agencyOnboardingTaskId
              ? agencyTask
              : {
                ...agencyTask,
                progress: {
                  ...agencyTask.progress,
                  required_completed: action.payload.requiredSubtasksCompleted,
                  required_total: action.payload.requiredSubtasksTotal,
                },
              };
          }),
        },
      };

    case types.PROSPECT_ONBOARDING_TASK_PROGRESS_CHANGED:
      return {
        ...state,
        prospectTasks: {
          ...state.prospectTasks,
          data: state.prospectTasks.data.map((prospectTask) => {
            return prospectTask.id !== action.payload.prospectOnboardingTaskId
              ? prospectTask
              : {
                ...prospectTask,
                progress: {
                  ...prospectTask.progress,
                  required_completed: action.payload.requiredSubtasksCompleted,
                  required_total: action.payload.requiredSubtasksTotal,
                },
              };
          }),
        },
      };

    case types.SAVE_AGENCY_ONBOARDING_TASK_PENDING:
      return {
        ...state,
        loading: true,
        agencyTask: {
          ...state.agencyTask,
          isSaving: true,
        },
      };

    case types.SAVE_AGENCY_ONBOARDING_TASK_SUCCESS:
      return {
        ...state,
        agencyTasks: {
          ...state.agencyTasks,
          data: state.agencyTasks.data.map((agencyTask) => {
            return agencyTask.id === action.payload.taskId
              ? {
                ...agencyTask,
                ...action.payload.task,
              }
              : agencyTask;
          }),
        },
        agencyTask: {
          ...state.agencyTask,
          data: _.merge({}, state.agencyTask.data, action.payload.task),
          isSaving: false,
        },
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            outstanding_agencies: state.task.data?.outstanding_agencies?.map((outstandingAgency) => {
              return outstandingAgency.id === action.payload.taskId
                ? _.merge({}, outstandingAgency, action.payload.task)
                : outstandingAgency;
            }),
          },
        },
      };

    case types.SAVE_AGENCY_ONBOARDING_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        agencyTask: {
          isSaving: false,
          error: action.payload.error,
        },
      };

    case types.SAVE_PROSPECT_ONBOARDING_TASK_PENDING:
      return {
        ...state,
        loading: true,
        prospectTask: {
          ...state.prospectTask,
          isSaving: true,
        },
      };

    case types.SAVE_PROSPECT_ONBOARDING_TASK_SUCCESS:
      return {
        ...state,
        prospectTasks: {
          ...state.prospectTasks,
          data: state.prospectTasks.data.map((prospectTask) => {
            return prospectTask.id === action.payload.taskId
              ? {
                ...prospectTask,
                ...action.payload.task,
              }
              : prospectTask;
          }),
        },
        prospectTask: {
          ...state.prospectTask,
          isSaving: false,
        },
      };

    case types.SAVE_PROSPECT_ONBOARDING_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        prospectTask: {
          isSaving: false,
          error: action.payload.error,
        },
      };

    case types.COMPLETE_AGENCY_ONBOARDING_TASK_PENDING:
      return {
        ...state,
        loading: true,
        agencyTask: {
          ...state.agencyTask,
          isCompleting: true,
        },
      };

    case types.COMPLETE_AGENCY_ONBOARDING_TASK_SUCCESS:
      return {
        ...state,
        agencyTasks: {
          ...state.agencyTasks,
          data: state.agencyTasks.data.map((agencyTask) => {
            return agencyTask.id === action.payload.taskId
              ? {
                ...agencyTask,
                status: "done",
                completed_at: Date.now(),
              }
              : agencyTask;
          }),
        },
        agencyTask: {
          ...state.agencyTask,
          isCompleting: false,
          data: {
            ...state.agencyTask.data,
            status: "done",
          },
        },
        task: {
          ...state.task,
          data: {
            ...state.task.data,
            outstanding_agencies: state.task.data?.outstanding_agencies?.map((outstandingAgency) => {
              return outstandingAgency.id === action.payload.taskId
                ? { ...outstandingAgency, status: "done" }
                : outstandingAgency;
            }),
          },
        },
      };

    case types.COMPLETE_AGENCY_ONBOARDING_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        agencyTask: {
          ...state.agencyTask,
          isCompleting: false,
        },
      };

    case types.COMPLETE_PROSPECT_ONBOARDING_TASK_PENDING:
      return {
        ...state,
        loading: true,
        prospectTask: {
          ...state.prospectTask,
          isCompleting: true,
        },
      };

    case types.COMPLETE_PROSPECT_ONBOARDING_TASK_SUCCESS:
      return {
        ...state,
        prospectTasks: {
          ...state.prospectTasks,
          data: state.prospectTasks.data.map((prospectTask) => {
            return prospectTask.id === action.payload.taskId
              ? {
                ...prospectTask,
                status: "done",
                completed_at: Date.now(),
              }
              : prospectTask;
          }),
        },
        prospectTask: {
          ...state.prospectTask,
          isCompleting: false,
          data: {
            ...state.prospectTask.data,
            status: "done",
          },
        },
      };

    case types.COMPLETE_PROSPECT_ONBOARDING_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        prospectTask: {
          ...state.prospectTask,
          isCompleting: false,
        },
      };

    case types.DELETE_AGENCY_TASK_TRACKING_ENTRY_PENDING:
      return { ...state, loading: true };

    case types.DELETE_AGENCY_TASK_TRACKING_ENTRY_SUCCESS:
      return {
        ...state,
        agencyTask: {
          ...state.agencyTask,
          data: {
            ...state.agencyTask.data,
            definition: {
              ...state.agencyTask.data.definition,
              subtasks: state.agencyTask.data.definition.subtasks.map((subtask) => {
                return subtask.field.name === action.payload.trackingEntry.field_name
                  ? {
                    ...subtask,
                    tracking: {
                      ...subtask.tracking,
                      entries: subtask.tracking.entries.filter(entry => entry.id !== action.payload.trackingEntry.id),
                    },
                  }
                  : subtask;
              }),
            },
          },
        },
      };

    case types.DELETE_AGENCY_TASK_TRACKING_ENTRY_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.DELETE_PROSPECT_TASK_TRACKING_ENTRY_PENDING:
      return { ...state, loading: true };

    case types.DELETE_PROSPECT_TASK_TRACKING_ENTRY_SUCCESS:
      return {
        ...state,
        prospectTask: {
          ...state.prospectTask,
          data: {
            ...state.prospectTask.data,
            definition: {
              ...state.prospectTask.data.definition,
              subtasks: state.prospectTask.data.definition.subtasks.map((subtask) => {
                return subtask.field.name === action.payload.trackingEntry.field_name
                  ? {
                    ...subtask,
                    tracking: {
                      ...subtask.tracking,
                      entries: subtask.tracking.entries.filter(entry => entry.id !== action.payload.trackingEntry.id),
                    },
                  }
                  : subtask;
              }),
            },
          },
        },
      };

    case types.DELETE_PROSPECT_TASK_TRACKING_ENTRY_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.CHECK_AGENCY_SHORT_CODE_PENDING:
      return { ...state, newAgency: { ...state.newAgency, loading: true } };

    case types.CHECK_AGENCY_SHORT_CODE_SUCCESS:
      return {
        ...state,
        newAgency: {
          ...state.newAgency,
          loading: false,
          shortCodeExists: action.payload.exists,
          error: null,
        },
      };

    case types.CHECK_AGENCY_SHORT_CODE_FAILURE:
      return {
        ...state,
        newAgency: {
          ...state.newAgency,
          loading: false,
          shortCodeExists: false,
          error: action.payload.error,
        },
      };

    case types.GET_EXISTING_AGENCY_SHORT_CODES_PENDING:
      return { ...state, newAgency: { ...state.newAgency, loading: true } };

    case types.GET_EXISTING_AGENCY_SHORT_CODES_SUCCESS:
      return {
        ...state,
        newAgency: {
          ...state.newAgency,
          loading: false,
          existingShortCodes: action.payload.shortCodes,
          error: null,
        },
      };

    case types.GET_EXISTING_AGENCY_SHORT_CODES_FAILURE:
      return {
        ...state,
        newAgency: {
          ...state.newAgency,
          loading: false,
          existingShortCodes: [],
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
