import * as types from "./types";

const initialState = {
  loading: true,
  documents: [],
  folders: [],
  hasNextPage: false,
  agencyDocument: {
    loading: true,
    data: null,
    error: null,
  },
  agencyDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  agencyOnboardingDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  prospectDocument: {
    loading: true,
    data: null,
    error: null,
  },
  prospectDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  prospectOnboardingDocuments: {
    loading: true,
    documents: [],
    folders: [],
    hasNextPage: false,
    error: null,
  },
  ui: {
    submitting: false,
  },
  error: "",
  documentTypes: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_AGENCY_DOCUMENT_PENDING:
      return {
        ...state,
        agencyDocument: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_AGENCY_DOCUMENT_SUCCESS:
      return {
        ...state,
        agencyDocument: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_AGENCY_DOCUMENT_FAILURE:
      return {
        ...state,
        agencyDocument: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_DOCUMENTS_PENDING:
      return {
        ...state,
        agencyDocuments: {
          loading: true,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: null,
        },
      };

    case types.GET_AGENCY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        agencyDocuments: {
          loading: false,
          documents: action.payload.documents,
          folders: action.payload.folders,
          hasNextPage: action.payload.hasNextPage,
          error: null,
        },
      };

    case types.GET_AGENCY_DOCUMENTS_FAILURE:
      return {
        ...state,
        agencyDocuments: {
          loading: false,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_DOCUMENTS_PENDING:
      return {
        ...state,
        prospectDocuments: initialState.prospectDocuments,
      };

    case types.GET_PROSPECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        prospectDocuments: {
          loading: false,
          documents: action.payload.documents,
          folders: action.payload.folders,
          hasNextPage: action.payload.hasNextPage,
          error: null,
        },
      };

    case types.GET_PROSPECT_DOCUMENTS_FAILURE:
      return {
        ...state,
        prospectDocuments: {
          loading: false,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: action.payload.error,
        },
      };

    case types.GET_PROSPECT_DOCUMENT_PENDING:
      return {
        ...state,
        prospectDocument: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_PROSPECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        prospectDocument: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_PROSPECT_DOCUMENT_FAILURE:
      return {
        ...state,
        prospectDocument: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.DOWNLOAD_DOCUMENTS_PENDING:
      return { ...state, loading: true };

    case types.DOWNLOAD_DOCUMENTS_SUCCESS:
      return { ...state, loading: false };

    case types.DOWNLOAD_DOCUMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.REMOVE_PROSPECT_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.REMOVE_PROSPECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        prospectDocuments: {
          ...state.prospectDocuments,
          documents: !action.payload.versionId
            ? state.prospectDocuments.documents.filter(document => document.name !== action.payload.document.name)
            : state.prospectDocuments.documents,
        },
        prospectDocument: {
          ...state.prospectDocument,
          data: {
            ...state.prospectDocument.data,
            versions: action.payload.versionId
              ? state.prospectDocument.data?.versions.filter(version => version.versionId !== action.payload.versionId)
              : state.prospectDocument.data?.versions,
          },
        },
      };

    case types.REMOVE_PROSPECT_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case types.REMOVE_AGENCY_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.REMOVE_AGENCY_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        agencyDocuments: {
          ...state.agencyDocuments,
          documents: !action.payload.versionId
            ? state.agencyDocuments.documents.filter(document => document.name !== action.payload.document.name)
            : state.agencyDocuments.documents,
        },
        agencyDocument: {
          ...state.agencyDocument,
          data: {
            ...state.agencyDocument.data,
            versions: action.payload.versionId
              ? state.agencyDocument.data?.versions.filter(version => version.versionId !== action.payload.versionId)
              : state.agencyDocument.data?.versions,
          },
        },
      };

    case types.REMOVE_AGENCY_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case types.GET_AGENCY_DOCUMENT_TYPES_PENDING:
      return { ...state, loading: true };

    case types.GET_AGENCY_DOCUMENT_TYPES_SUCCESS:
      return { ...state, loading: false, documentTypes: action.payload };

    case types.GET_AGENCY_DOCUMENT_TYPES_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.UPLOAD_AGENCY_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: "",
        agencyDocuments: {
          ...state.agencyDocuments,
          error: null,
        },
      };

    case types.UPLOAD_AGENCY_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyDocuments: {
          ...state.agencyDocuments,
          ...action.payload.getAgencyDocuments,
        },
      };

    case types.UPLOAD_AGENCY_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        agencyDocuments: {
          ...state.agencyDocuments,
          error: action.payload.fileSizeError ? action.payload.error.message : "Please try again.",
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: "",
        prospectDocuments: {
          ...state.prospectDocuments,
          error: null,
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        prospectDocuments: {
          ...state.prospectDocuments,
          ...action.payload.getProspectDocuments,
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        prospectDocuments: {
          ...state.prospectDocuments,
          error: action.payload.fileSizeError ? action.payload.error.message : "Please try again.",
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENTS_PENDING:
      return {
        ...state,
        error: "",
        prospectDocuments: {
          ...state.prospectDocuments,
          error: null,
        },
        ui: {
          submitting: true,
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPLOAD_PROSPECT_DOCUMENTS_FAILURE:
      return {
        ...state,
        prospectDocuments: {
          ...state.prospectDocuments,
          error: action.payload.fileSizeError ? action.payload.error.message : "Please try again.",
        },
        ui: {
          submitting: false,
        },
      };

    case types.UPLOAD_AGENCY_DOCUMENTS_PENDING:
      return {
        ...state,
        error: "",
        agencyDocuments: {
          ...state.agencyDocuments,
          error: null,
        },
        ui: {
          submitting: true,
        },
      };

    case types.UPLOAD_AGENCY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
      };

    case types.UPLOAD_AGENCY_DOCUMENTS_FAILURE:
      return {
        ...state,
        agencyDocuments: {
          ...state.agencyDocuments,
          error: action.payload.fileSizeError ? action.payload.error.message : "Please try again.",
        },
        ui: {
          submitting: false,
        },
      };

    case types.GET_ONBOARDING_DOCUMENTS_PENDING:
      return { ...state, loading: true };

    case types.GET_ONBOARDING_DOCUMENTS_SUCCESS:
      return { ...state, loading: false, ...action.payload };

    case types.GET_ONBOARDING_DOCUMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_PROSPECT_ONBOARDING_DOCUMENTS_PENDING:
      return {
        ...state,
        prospectOnboardingDocuments: {
          loading: true,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: null,
        },
      };

    case types.GET_PROSPECT_ONBOARDING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        prospectOnboardingDocuments: {
          loading: false,
          documents: action.payload.documents,
          folders: action.payload.folders,
          hasNextPage: action.payload.hasNextPage,
          error: null,
        },
      };

    case types.GET_PROSPECT_ONBOARDING_DOCUMENTS_FAILURE:
      return {
        ...state,
        prospectOnboardingDocuments: {
          loading: false,
          documents: [],
          folders: [],
          hasNextPage: false,
          error: action.payload.error,
        },
      };

    case types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_PENDING:
      return { ...state, loading: true, error: "" };

    case types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_SUCCESS:
      return { ...state, loading: false };

    case types.UPLOAD_AGENCY_ONBOARDING_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_PENDING:
      return { ...state, loading: true, error: "" };

    case types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_SUCCESS:
      return { ...state, loading: false };

    case types.UPLOAD_PROSPECT_ONBOARDING_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.DOWNLOAD_ONBOARDING_DOCUMENTS_PENDING:
      return { ...state, loading: true };

    case types.DOWNLOAD_ONBOARDING_DOCUMENTS_SUCCESS:
      return { ...state, loading: false };

    case types.DOWNLOAD_ONBOARDING_DOCUMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    default:
      return state;
  }
}
