import React, { useState } from "react";
import PropTypes from "prop-types";
import InsurerApplicationModalValidator from "./InsurerApplicationModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import { options } from "../../../../constants";
import { toMoney } from "../../../../utils";
import styles from "./InsurerApplicationModal.module.scss";

export default function InsurerApplicationModal(props) {
  const [state, setState] = useState({
    amountApplyingFor: props.requestedAmount,
    source: options.CREDIT_APPLICATION_SOURCE_OPTIONS[0].value,
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    const validator = new InsurerApplicationModalValidator(state, props);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.onSubmit(state);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Insurer Application"
      submitText="Submit Application"
      visible={props.visible}
      busy={props.busy}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
    >
      <div className={styles.content}>
        <div>
          Complete the form below to submit an insurer application.
        </div>
        <Form className={styles.form} requiredKey>
          <Form.Group inline>
            <Form.Label className={styles.label} inline>
              Current Credit Limit (Excl. VAT)
            </Form.Label>
            <Form.ValueInline>
              <strong>{toMoney(props.currentCreditLimit)}</strong>
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline error={errors?.amountApplyingFor}>
            <Form.Label className={styles.label} inline required>
              Amount applying for (Excl. VAT)
            </Form.Label>
            <Form.Number
              name="amountApplyingFor"
              value={state.amountApplyingFor}
              placeholder="eg. £10,000"
              prefix="£"
              onChange={e => setState({ ...state, amountApplyingFor: e.target.value })}
              highlightError={errors?.amountApplyingFor}
              formatNumber
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Label className={styles.label} inline required>
              Source
            </Form.Label>
            <Form.Value>
              <Form.Select
                name="source"
                options={options.CREDIT_APPLICATION_SOURCE_OPTIONS}
                fluid
                value={state.source}
                onChange={e => setState({ ...state, source: e.target.value })}
              />
            </Form.Value>
          </Form.Group>
        </Form>
      </div>
    </ConfirmationModal>
  );
}

InsurerApplicationModal.propTypes = {
  currentCreditLimit: PropTypes.number.isRequired,
  requestedAmount: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  busy: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

InsurerApplicationModal.displayName = "InsurerApplicationModal";
