import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Heading,
} from "../../../common/components";
import {
  InitialQuestionsForm,
  InitialQuestionsFormValidator,
} from "../../components/CreditCheckForms";
import styles from "./PerformCreditCheckPage.module.scss";

export default class PerformCreditCheckPage extends Component {

  static propTypes = {
    agencyCustomerSearch: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        agency_customers: PropTypes.arrayOf(PropTypes.shape({
          agency_id: PropTypes.string.isRequired,
        })),
      }),
      loading: PropTypes.bool,
    }),
    initialQuestions: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onGoToAddNewCompany: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.initialQuestions || {
        companyLocation: "uk",
        limitedCompany: true,
        estimatedCreditLimit: null,
        agency: {},
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.props.onCancel();
  }

  render() {
    return (
      <div>
        <Heading title="Request Credit Check" className={styles.heading} />
        <div className={styles.container}>
          <div className={styles.form}>
            <InitialQuestionsForm
              data={this.state.data}
              errors={this.state.errors}
              onChange={this.handleInitialQuestionsChange}
              onAgencyChange={this.handleAgencyChange}
            />
          </div>
          <div className={styles.buttons}>
            <Button
              variant="primary"
              className={styles.button}
              onClick={this.handleContinueClicked}
              disabled={this.props.agencyCustomerSearch.loading}
            >
              Continue
            </Button>
            <Button
              variant="outline-danger"
              className={styles.button}
              onClick={this.handleCancelClicked}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }

  handleInitialQuestionsChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleAgencyChange = (agency) => {
    this.setState({
      data: {
        ...this.state.data,
        agency: {
          ...agency,
        },
      },
    });
  }

  handleCancelClicked = () => {
    const { onCancel } = this.props;

    onCancel();
  }

  handleContinueClicked = () => {
    const { onGoToAddNewCompany } = this.props;
    const { data } = this.state;

    const validator = new InitialQuestionsFormValidator(data);
    const validationResult = (validator && validator.validate()) || { errors: [], success: true };

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) return;

    onGoToAddNewCompany(data);
  }
}
