import _ from "lodash";
import moment from "moment";

export default class PartApproveCreditApplicationModalValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data, props } = this;
    let errors = {};

    if (!data.approvedCreditLimit) {
      errors = _.set(errors, "approvedCreditLimit", "Approved credit amount is required");
    }

    if (data.approvedCreditLimit <= 0) {
      errors = _.set(errors, "approvedCreditLimit", "Approved credit must be greater than zero");
    }

    if (data.approvedCreditLimit >= props.creditLimitRequested) {
      errors = _.set(errors, "approvedCreditLimit", "Approved credit must be less than the requested amount");
    }

    if (!data.resultDate) {
      errors = _.set(errors, "resultDate", "Date of report is required");
    }

    if (data.resultDate > moment().valueOf()) {
      errors = _.set(errors, "resultDate", "You cannot select a date in the future");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
