import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import BankInformationFormValidator from "./BankInformationFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import { isNumber } from "../../../../../utils";
import styles from "./BankInformationForm.module.scss";

export default class BankInformationForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      sort_code: PropTypes.string,
      account_number: PropTypes.string,
      payee_name: PropTypes.string,
      bank_name: PropTypes.string,
      branch: PropTypes.string,
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        prospect_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        sort_code: props.data?.sort_code,
        account_number: props.data?.account_number,
        payee_name: props.data?.payee_name,
        bank_name: props.data?.bank_name,
        branch: props.data?.branch,
      },
      errors: {},
    };
  }

  render() {
    const { submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={14}>
          <Form className={styles.form}>
            <Form.Section title="Bank Information" titleClassName={styles.title}>
              <Form.Group inline wide error={errors?.sort_code}>
                <Form.Label inline>
                  Sort Code
                </Form.Label>
                <Form.Input
                  name="sort_code"
                  value={data.sort_code}
                  size="small"
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("sort_code")}
                  showLock={this.isFieldDisabled("sort_code")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("sort_code")}
                  highlightError={errors?.sort_code}
                  maskOptions={{
                    mask: "99-99-99",
                    maskChar: null,
                  }}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.account_number}>
                <Form.Label inline>
                  Account Number
                </Form.Label>
                <Form.Input
                  name="account_number"
                  value={data.account_number}
                  size="medium"
                  maxLength={8}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("account_number")}
                  showLock={this.isFieldDisabled("account_number")}
                  onChange={this.handleNumberChange}
                  onLockClick={() => this.handleDisabledFieldClick("account_number")}
                  highlightError={errors?.account_number}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.payee_name}>
                <Form.Label inline>
                  Payee Name
                </Form.Label>
                <Form.Input
                  name="payee_name"
                  value={data.payee_name}
                  size="medium"
                  maxLength={255}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("payee_name")}
                  showLock={this.isFieldDisabled("payee_name")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("payee_name")}
                  highlightError={errors?.payee_name}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.bank_name}>
                <Form.Label inline note="(Optional)">
                  Bank Name
                </Form.Label>
                <Form.Input
                  name="bank_name"
                  value={data.bank_name}
                  size="medium"
                  maxLength={255}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("bank_name")}
                  showLock={this.isFieldDisabled("bank_name")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("bank_name")}
                  highlightError={errors?.bank_name}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.branch}>
                <Form.Label inline note="(Optional)">
                  Branch
                </Form.Label>
                <Form.Input
                  name="branch"
                  value={data.branch}
                  size="medium"
                  maxLength={255}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("branch")}
                  showLock={this.isFieldDisabled("branch")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("branch")}
                  highlightError={errors?.branch}
                />
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={submitting}
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleNumberChange = (e) => {
    if (!isNumber(e.target.value)) {
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleItemChange = (e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: item,
      },
    });
  }

  handleSelectedValuesChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.name]: e.target.values,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const validator = new BankInformationFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    const dataExcludingDisabledFields = _.omitBy(data, (_value, key) => {
      return this.isFieldDisabled(key);
    });

    onSubmit && onSubmit(dataExcludingDisabledFields);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick && onDisabledFieldClick(disabledField);
  }

  isFieldDisabled = (fieldName) => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
