import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EditAccountContactDetailsModalValidator from "./EditAccountContactDetailsModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import styles from "./EditAccountContactDetailsModal.module.scss";

export default function EditAccountContactDetailsModal(props) {
  const [formData, setFormData] = useState({
    fullName: "",
    telephoneNumber: "",
    emailAddress: "",
    addressLine1: "",
    addressLine2: "",
    townOrCity: "",
    county: "",
    postcode: "",
  });
  const [errors, setErrors] = useState({});

  const { customerAccountId, payrollAgencyCustomerRequestId, getCustomerAccount } = props;

  useEffect(() => {
    getCustomerAccount({
      customerAccountId,
      payrollAgencyCustomerRequestId,
    });
  }, [customerAccountId, payrollAgencyCustomerRequestId, getCustomerAccount]);

  useEffect(() => {
    if (!props.submitting) {
      setFormData({
        fullName: props.accountContactDetails?.full_name,
        telephoneNumber: props.accountContactDetails?.telephone_number,
        emailAddress: props.accountContactDetails?.email_address,
        addressLine1: props.accountContactDetails?.address_line_1,
        addressLine2: props.accountContactDetails?.address_line_2,
        townOrCity: props.accountContactDetails?.town_or_city,
        county: props.accountContactDetails?.county,
        postcode: props.accountContactDetails?.postcode,
      });
    }
  }, [props]);

  const handleSubmit = async () => {
    const validator = new EditAccountContactDetailsModalValidator(formData);
    const { success, errors } = validator.validate();

    setErrors(errors);

    if (!success) {
      return;
    }

    await props.updateAccountContactDetails({
      customerAccountId,
      accountContactDetails: formData,
    });
    props.hideModal(EditAccountContactDetailsModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(EditAccountContactDetailsModal.displayName);
  };

  const handleOnChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <ConfirmationModal
      className={styles.container}
      title="Edit Account Contact & Address"
      visible={props.visible}
      submitText="Save"
      submitDisabled={props.loading}
      busy={props.submitting}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline wide error={errors?.fullName}>
            <Form.Label className={styles.label} inline required>
              Account Contact Full Name (FAO)
            </Form.Label>
            <Form.Input
              name="fullName"
              value={formData.fullName}
              placeholder="Enter Contact Name..."
              maxLength={255}
              disabled={props.submitting || props.loading}
              highlightError={errors?.fullName}
              onChange={handleOnChange}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.telephoneNumber}>
            <Form.Label className={styles.label} inline required>
              Telephone Number
            </Form.Label>
            <Form.Input
              name="telephoneNumber"
              value={formData.telephoneNumber || ""}
              placeholder="Enter Phone Number..."
              maxLength={50}
              disabled={props.submitting || props.loading}
              highlightError={errors?.telephoneNumber}
              onChange={handleOnChange}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.emailAddress}>
            <Form.Label className={styles.label} inline>
              Email Address
            </Form.Label>
            <Form.Input
              name="emailAddress"
              value={formData.emailAddress}
              placeholder="Enter Email Address..."
              disabled={props.submitting || props.loading}
              highlightError={errors?.emailAddress}
              onChange={handleOnChange}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.addressLine1}>
            <Form.Label className={styles.label} inline required>
              Address Line 1
            </Form.Label>
            <Form.Input
              name="addressLine1"
              value={formData.addressLine1}
              placeholder="Enter Address Line 1..."
              maxLength={255}
              disabled={props.submitting || props.loading}
              highlightError={errors?.addressLine1}
              onChange={handleOnChange}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.addressLine2}>
            <Form.Label className={styles.label} inline>
              Address Line 2
            </Form.Label>
            <Form.Input
              name="addressLine2"
              value={formData.addressLine2}
              placeholder="Enter Address Line 2..."
              maxLength={255}
              disabled={props.submitting || props.loading}
              highlightError={errors?.addressLine2}
              onChange={handleOnChange}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.townOrCity}>
            <Form.Label className={styles.label} inline required>
              Town / City
            </Form.Label>
            <Form.Input
              name="townOrCity"
              value={formData.townOrCity}
              placeholder="Enter Town / City..."
              maxLength={255}
              disabled={props.submitting || props.loading}
              highlightError={errors?.townOrCity}
              onChange={handleOnChange}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.county}>
            <Form.Label className={styles.label} inline>
              County
            </Form.Label>
            <Form.Input
              name="county"
              value={formData.county}
              placeholder="Enter County..."
              maxLength={255}
              disabled={props.submitting || props.loading}
              highlightError={errors?.county}
              onChange={handleOnChange}
            />
          </Form.Group>
          <Form.Group inline wide error={errors?.postcode}>
            <Form.Label className={styles.label} inline required>
              Postcode
            </Form.Label>
            <Form.Input
              name="postcode"
              value={formData.postcode}
              placeholder="Enter Postcode..."
              maxLength={8}
              disabled={props.submitting || props.loading}
              highlightError={errors?.postcode}
              onChange={handleOnChange}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

EditAccountContactDetailsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  customerAccountId: PropTypes.string.isRequired,
  payrollAgencyCustomerRequestId: PropTypes.string.isRequired,
  accountContactDetails: PropTypes.shape({
    full_name: PropTypes.string,
    telephone_number: PropTypes.string,
    email_address: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    town_or_city: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
  getCustomerAccount: PropTypes.func.isRequired,
  updateAccountContactDetails: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

EditAccountContactDetailsModal.displayName = "EditAccountContactDetailsModal";
