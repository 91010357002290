import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../../common/components";
import { toMoney } from "../../../../../utils";
import styles from "./InsufficientCreditModal.module.scss";

export default function InsufficientCreditModal(props) {
  return (
    <ConfirmationModal
      className={classnames(styles.container, props.className)}
      title="Insufficient Credit"
      submitText="Continue to Insurer Application"
      cancelText="Come back later"
      cancelVariant="outline-primary"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div>
        An Experian credit check on {props.legalEntityName} has been completed on behalf of{" "}
        {props.agencyName}. Unfortunately the result of {toMoney(props.approvedAmount)} is insufficient{" "}
        to fulfil the requested amount of {toMoney(props.requestedAmount)}.
      </div>
      <div className={styles.footer}>
        You can either continue with an insurer application, or come back later.
      </div>
    </ConfirmationModal>
  );
}


InsufficientCreditModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  agencyName: PropTypes.string.isRequired,
  legalEntityName: PropTypes.string.isRequired,
  approvedAmount: PropTypes.number.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
