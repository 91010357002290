import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_TRANSFERS = gql`
query(
  $agency_id: ID!
) {
  getAgency(id: $agency_id) {
    id
    transfers {
      transfer_id
      transfer_type
      status
      error
      extended_error
      created_by {
        first_name
        last_name
      }
      created_at
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 */
export const getTransfers = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_TRANSFERS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_TRANSFERS,
      variables: {
        agency_id: agencyId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_TRANSFERS_SUCCESS,
      payload: {
        transfers: response.data.getAgency.transfers,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_TRANSFERS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
