/**
 * Returns true if the supplied string contains
 * any lowercase characters, returns false otherwise.
 * @param {string} string
 * @returns bool
 */
const containsLowercaseCharacters = (string) => {
  return string.toUpperCase() !== string;
};

export default containsLowercaseCharacters;
