import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./TabControl.module.scss";

class TabControl extends Component {

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    onTabChanged: PropTypes.func,
    defaultIndex: PropTypes.number,
  }

  constructor(props) {
    super(props);

    this.state = { selectedIndex: props.defaultIndex || 0 };
  }

  handleHeaderClicked = (index, title, disabled) => {
    if (disabled) {
      return;
    }
    this.setState({ selectedIndex: index });
    this.props.onTabChanged && this.props.onTabChanged(title, index);
  }

  render() {
    const children = React.Children.toArray(this.props.children); //ensures array
    return (
      <div className={classnames(styles.tabControl, this.props.className)}>
        <div className={styles.headers}>
          {children.map((value, index) => {
            const title = value.props.title;
            const className = this.state.selectedIndex === index ? styles.active : undefined;
            return <div onClick={() => this.handleHeaderClicked(index, title, value.props.disabled )} className={classnames(className, value.props.disabled && styles.disabled)} key={index}>{title}</div>;
          })}
        </div>
        <hr className={styles.separator}/>
        <div className={styles.tabContent}>
          {children[this.state.selectedIndex].props.children}
        </div>
      </div>
    );
  }
}

export default TabControl;
