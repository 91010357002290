import React, { Component } from "react";
import PropTypes from "prop-types";
import MIReportGroup from "./MIReportGroup/MIReportGroup";
import MIReportGroupItem from "./MIReportGroupItem/MIReportGroupItem";
import { Button, ButtonGroup, Form } from "../../../../common/components";
import { List } from "../../../../lists/containers";
import styles from "./MiReportGroupingSection.module.scss";

export default class MIReportGroupingForm extends Component {

  static propTypes = {
    data: PropTypes.shape({
      mi_report_grouping_required: PropTypes.bool.isRequired,
      mi_report_groupings: PropTypes.arrayOf(PropTypes.shape({
        mi_report_grouping: PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }).isRequired,
        active: PropTypes.bool.isRequired,
        values: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          value: PropTypes.string,
        })).isRequired,
      })).isRequired,
    }).isRequired,
    submitting: PropTypes.bool,
    onEditClick: PropTypes.func,
  }

  render() {
    const activeGroupings = this.props.data?.mi_report_groupings?.filter(grouping => grouping.active);

    return (
      <div className={styles.container}>
        <ButtonGroup className={styles.buttons}>
          <Button variant="secondary" onClick={this.handleEditClicked}>
            Edit
          </Button>
        </ButtonGroup>
        <Form className={styles.form}>
          <Form.Section title="MI Report Grouping">
            <Form.Group>
              <Form.Label>Is MI Report Grouping Required?</Form.Label>
              <Form.Input
                value={this.props.data?.mi_report_grouping_required ? "Yes" : "No"}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>MI Split by:</Form.Label>
              <Form.Value>
                <List
                  identifier="mi_report_groupings"
                  value={activeGroupings.map(grouping => grouping.mi_report_grouping)}
                  disabled={!this.props.data?.mi_report_grouping_required}
                  readOnly
                />
              </Form.Value>
            </Form.Group>
            {activeGroupings.length > 0 && (
              <Form.Group className={styles.groupings}>
                {this.renderReportGroups()}
              </Form.Group>
            )}
          </Form.Section>
        </Form>
      </div>
    );
  }

  renderReportGroups = () => {
    return this.props.data?.mi_report_groupings?.map((grouping, groupIndex) => grouping.active && (
      <MIReportGroup
        key={groupIndex}
        title={grouping.mi_report_grouping.label}
        items={grouping.values}
        disabled={!this.props.data?.mi_report_grouping_required}
        readOnly
      >
        {grouping.values.map(({ value }, itemIndex) => (
          <MIReportGroupItem
            key={`${groupIndex}_${itemIndex}`}
            value={value}
            disabled={!this.props.data?.mi_report_grouping_required}
            readOnly
          />
        ))}
      </MIReportGroup>
    ));
  }

  handleEditClicked = () => {
    const { onEditClick } = this.props;

    onEditClick && onEditClick();
  }
}
