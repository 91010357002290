import React from "react";
import PropTypes from "prop-types";
import { Form } from "../../../common/components";
import { getUsersName } from "../../../../utils";
import styles from "./InvoiceContactDetails.module.scss";

export default function InvoiceContactDetails(props) {
  return (
    <Form.Card
      title="Invoice Contact & Address"
      variant="h2"
      className={styles.card}
    >
      <Form.Group
        className={styles.alignedFormGroup}
        inline
      >
        <Form.Label fluid>
          Do you have an Invoice Contact and Address?
        </Form.Label>
        <Form.Label className={styles.value}>
          {props.invoiceContact
            ? "Yes"
            : "No"
          }
        </Form.Label>
      </Form.Group>

      {props.invoiceContact && (
        <>
          <Form.Group inline>
            <Form.Label>
              Invoice Contact Full Name (FAO)
            </Form.Label>
            <Form.Label className={styles.value}>
              {getUsersName(props.invoiceContact) ?? "-"}
            </Form.Label>
          </Form.Group>

          <Form.Group inline>
            <Form.Label>
              Telephone Number
            </Form.Label>
            <Form.Label className={styles.value}>
              {props.invoiceContact?.telephone_number ?? "-"}
            </Form.Label>
          </Form.Group>

          <Form.Group inline>
            <Form.Label>
              Email Address
            </Form.Label>
            <Form.Label className={styles.value}>
              {props.invoiceContact?.email_address ?? "-"}
            </Form.Label>
          </Form.Group>

          <Form.Group
            className={styles.alignedFormGroup}
            inline
          >
            <Form.Label>
              Address
            </Form.Label>
            <Form.Label className={styles.value}>
              <div>
                {props.invoiceContact?.address_line_1}
              </div>
              {props.invoiceContact?.address_line_2 && (
                <div>
                  {props.invoiceContact?.address_line_2}
                </div>
              )}
              <div>
                {props.invoiceContact?.town_or_city}
              </div>
              {props.invoiceContact?.county && (
                <div>
                  {props.invoiceContact?.county}
                </div>
              )}
              <div>
                {props.invoiceContact?.postcode}
              </div>
            </Form.Label>
          </Form.Group>
        </>
      )}
    </Form.Card>
  );
}

InvoiceContactDetails.propTypes = {
  invoiceContact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    telephone_number: PropTypes.string,
    email_address: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    town_or_city: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
};
