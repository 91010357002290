import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Modal } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./InfoModal.module.scss";

export default function InfoModal(props) {
  return (
    <Modal
      className={classnames(styles.container, props.className)}
      style={props.style}
      open={props.visible}
      dimmer={{
        className: styles.dimmer,
      }}
      centered
      onClose={props.onClose}
    >
      {props.showCloseButton && (
        <Modal.Header className={styles.header}>
          <div className={styles.closeButton}>
            <FontAwesomeIcon icon={faTimes} size="2x" onClick={props.onClose} />
          </div>
        </Modal.Header>
      )}
      <Modal.Content>
        {props.children}
      </Modal.Content>
    </Modal>
  );
}

InfoModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  style: PropTypes.object,
  showCloseButton: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

InfoModal.defaultProps = {
  showCloseButton: true,
};
