import React from "react";
import PropTypes from "prop-types";
import { DOCUMENT_TYPES } from "../../../documents";
import { Form } from "../../../common/components";
import { CompanyTypeConverter } from "../../../../converters";
import { capitalize } from "../../../../utils";
import styles from "./CompaniesHouseInfo.module.scss";

export default function CompaniesHouseInfo(props) {
  return (
    <Form className={styles.form}>
      <Form.Section title="Companies House">
        <Form.Group>
          <Form.Label>Registered Company Number</Form.Label>
          <Form.Input
            name="companyNumber"
            value={props.companyProfile.company_number}
            readOnly={true}
          />
        </Form.Group>
        {props.certificateOfIncorporationUrl && (
          <Form.Group>
            <Form.Document
              value={DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION}
              onDownload={() => {
                window.open(props.certificateOfIncorporationUrl, "_blank");
              }}
              readOnly
            />
          </Form.Group>

        )}
        <Form.Group>
          <Form.Label>Exact Legal Name</Form.Label>
          <Form.Input
            name="legalName"
            value={props.companyProfile.company_name}
            readOnly={true}
            fluid
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Last Accounts Period End On</Form.Label>
          <Form.Date
            name="lastAccountsPeriod"
            value={props.companyProfile.accounts?.last_accounts?.made_up_to}
            readOnly={true}
            fluid
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Form.Input
            name="type"
            value={CompanyTypeConverter.to(props.companyProfile.type)}
            readOnly={true}
            fluid
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Company Status</Form.Label>
          <Form.Input
            name="companyStatus"
            value={capitalize(props.companyProfile.company_status)}
            readOnly={true}
            fluid
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Company Status Detail</Form.Label>
          <Form.Input
            name="companyStatusDetail"
            value={props.companyProfile.company_status_detail}
            readOnly={true}
            fluid
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Previous Names</Form.Label>
          {props.companyProfile?.previous_company_names
            ? props.companyProfile?.previous_company_names.map(value => (
              <Form.Input
                key={value.name}
                name={value.name}
                value={value.name}
                readOnly={true}
                fluid
              />
            ))
            : <Form.Input value="" readOnly={true} />}
        </Form.Group>
      </Form.Section>
      <Form.Section title="Registered Address">
        <Form.Group>
          <Form.Label>Address</Form.Label>
          <Form.Input
            name="addressLineOne"
            value={props.companyProfile.registered_office_address.address_line_1}
            readOnly={true}
          />
        </Form.Group>
        <Form.FlexRow>
          <Form.Group>
            <Form.Label fluid>Town/City</Form.Label>
            <Form.Input
              name="townOrCity"
              value={props.companyProfile.registered_office_address.address_line_2 || props.companyProfile.registered_office_address.locality}
              readOnly={true}
              fluid
            />
          </Form.Group>
          <Form.Group>
            <Form.Label fluid>Address</Form.Label>
            <Form.Input
              name="postcode"
              value={props.companyProfile.registered_office_address.postal_code}
              readOnly={true}
              fluid
            />
          </Form.Group>
        </Form.FlexRow>
      </Form.Section>
    </Form>
  );
}

CompaniesHouseInfo.propTypes = {
  companyProfile: PropTypes.shape({
    company_name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
    accounts: PropTypes.shape({
      last_accounts: PropTypes.shape({
        made_up_to: PropTypes.string.isRequired,
      }),
    }),
    company_status: PropTypes.string.isRequired,
    company_status_detail: PropTypes.string,
    previous_company_names: PropTypes.arrayOf(PropTypes.shape({
      ceased_on: PropTypes.string.isRequired,
      effective_from: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
    registered_office_address: PropTypes.shape({
      address_line_1: PropTypes.string.isRequired,
      address_line_2: PropTypes.string.isRequired,
      locality: PropTypes.string.isRequired,
      postal_code: PropTypes.string.isRequired,
    }),
  }),
  certificateOfIncorporationUrl: PropTypes.string,
};
