import _ from "lodash";

export default class InsurerApplicationModalValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data, props } = this;
    const errors = {};

    if (_.toNumber(data?.amountApplyingFor) === 0) {
      errors.amountApplyingFor = "Amount Applying For is required";
    }
    else if (_.toNumber(data?.amountApplyingFor) < 0) {
      errors.amountApplyingFor = "Amount Applying For must be a positive number";
    }
    else if (data?.amountApplyingFor < props?.currentCreditLimit) {
      errors.amountApplyingFor = "Amount Applying For must be greater than the Current Credit Limit";
    }

    if (_.isEmpty(data?.source)) {
      errors.source = "Source is required";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
