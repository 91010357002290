import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../../duck";
import PayrollGeneral from "./PayrollGeneral";

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchPayrollGeneral: actions.getPayrollGeneral,
  }, dispatch),
  onCancel: (agencyId) => {
    dispatch(push(`/agencies/${agencyId}/payroll`));
  },
  onSubmit: ({ agencyId, data }) => {
    dispatch(actions.updatePayrollGeneral({
      agencyId,
      data,
      redirectUrl: `/agencies/${agencyId}/payroll`,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollGeneral);
