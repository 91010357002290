const formatAnswer = (answer, defaultValue = "") => {
  switch (answer) {
    case true:
      return "Yes";

    case false:
      return "No";

    default:
      return defaultValue;
  }
};

export default formatAnswer;
