import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT = gql`
query($company_number: String!) {
  getCompaniesHouseCompanyProfile(companyNumber: $company_number) {
    company_name
    type
    company_number
    date_of_creation
    accounts {
      last_accounts {
        made_up_to
      }
    }
    links {
      self
    }
    previous_company_names {
      name
      ceased_on
      effective_from
    }
    company_status
    company_status_detail
    registered_office_address {
      region
      postal_code
      locality
      address_line_2
      country
      address_line_1
    }
  }

  getCompaniesCertificateOfIncorporationUrl(companyNumber: $company_number) {
    url
  }
}
`;

const defaultOptions = {
  companyNumber: "",
};

export const getCompaniesHouseInfoForNewProspect = (options = defaultOptions) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT,
      variables: {
        company_number: options.companyNumber,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_SUCCESS,
      payload: {
        companyProfile: response.data.getCompaniesHouseCompanyProfile,
        certificateOfIncorporationUrl: response.data.getCompaniesCertificateOfIncorporationUrl?.url,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_FAILURE,
      payload: {
        error,
      },
    });
  }
};
