import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import {
  Button,
  Heading,
} from "../../../common/components";
import { AgencyCustomerDetailsCard, CustomerContractsTable, VerifyAgencyCustomerAlert } from "../../components";
import styles from "./ViewAgencyCustomerPage.module.scss";

export default function ViewAgencyCustomerPage(props) {
  const { getAgencyCustomer, agencyCustomerId } = props;

  useEffect(() => {
    getAgencyCustomer({
      agencyCustomerId: agencyCustomerId,
      redirectOnFailureUrl: "/customers",
    });
  }, [getAgencyCustomer, agencyCustomerId]);

  if (props.agencyCustomer.loading) {
    return <ActivityIndicator />;
  }

  const { data } = props.agencyCustomer;

  const getAgencyName = () => {
    if (data?.agency) {
      return data.agency.known_as;
    }
    if (data?.prospect) {
      return <>{data.prospect.known_as} <strong>(Prospect)</strong></>;
    }
    return null;
  };

  const handleVerifyClick = () => {
    const { id, company_type, legal_name, registered_company_number } = props.agencyCustomer?.data;

    if (["limited_company", "limited_liability_partnership"].includes(company_type)) {
      props.navigateToVerifyRegisteredCompanyPage({
        agencyCustomerId: id,
        exactLegalName: legal_name,
        registeredCompanyNumber: registered_company_number,
      });
    }

    if (["sole_trader", "partnership"].includes(company_type)) {
      props.showVerifyNonRegisteredAgencyCustomerModal({
        agencyCustomerId: id,
      });
    }
  };

  return (
    <div>
      <Heading
        title={data?.display_name}
        caption={getAgencyName()}
        hideSeparator
        rightAccessory={
          <Button variant="outline-primary" onClick={props.navigateToCustomers}>
            Back to Customers
          </Button>
        }
      />
      {!data?.customer && (
        <VerifyAgencyCustomerAlert
          className={styles.verificationAlert}
          onVerifyClick={handleVerifyClick}
        />
      )}
      <hr className={styles.separator} />
      <AgencyCustomerDetailsCard
        agencyCustomer={data}
        onEditClick={() =>
          props.showEditCustomerModal({
            agencyCustomerId,
          })
        }
      />
      <CustomerContractsTable
        contracts={data?.contracts}
        customerId={data?.id}
        onAddContract={() => props.navigateToAddContract(props.agencyCustomerId)}
      />
    </div>
  );
}

ViewAgencyCustomerPage.propTypes = {
  agencyCustomerId: PropTypes.string.isRequired,
  agencyCustomer: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
      company_type: PropTypes.string,
      registered_company_number: PropTypes.string,
      credit_limit_requested: PropTypes.number,
      agency: PropTypes.shape({
        known_as: PropTypes.string.isRequired,
      }),
      prospect: PropTypes.shape({
        known_as: PropTypes.string.isRequired,
      }),
      customer: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      customer_accounts: PropTypes.array,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  navigateToAddContract: PropTypes.func.isRequired,
  navigateToCustomers: PropTypes.func.isRequired,
  navigateToVerifyRegisteredCompanyPage: PropTypes.func.isRequired,
  getAgencyCustomer: PropTypes.func.isRequired,
  showVerifyNonRegisteredAgencyCustomerModal: PropTypes.func.isRequired,
  showEditCustomerModal: PropTypes.func.isRequired,
};
