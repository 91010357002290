import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PROSPECT_DOCUMENTS = gql`
query($input: ProspectDocumentsInput!) {
  getProspectDocuments(input: $input) {
    documents {
      name
      size
      lastModified
      versions {
        versionId
        isLatest
      }
      metadata {
        user {
          first_name
          last_name
        }
        date_uploaded
        document_type
      }
    }
    folders
    hasNextPage
  }
}
`;

export const getProspectDocuments = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_PROSPECT_DOCUMENTS_PENDING });

    const response = await apolloClient.query({
      query: GET_PROSPECT_DOCUMENTS,
      variables: {
        input: {
          prospect_id: prospectId,
        },
      },
    });

    dispatch({
      type: types.GET_PROSPECT_DOCUMENTS_SUCCESS,
      payload: response.data.getProspectDocuments,
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PROSPECT_DOCUMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
