import React, { Component } from "react";
import PropTypes from "prop-types";
import { DataExportList } from "../../components";
import { Heading, LinkDropdown } from "../../../common/components";
import styles from "./DataExportPage.module.scss";

export default class DataExportPage extends Component {

  static propTypes = {
    dataExports: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.string,
    }).isRequired,
    onExportAgencies: PropTypes.func.isRequired,
    onExportProspects: PropTypes.func.isRequired,
    onExportTempest: PropTypes.func.isRequired,
    onFetchDataExports: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    await this.props.onFetchDataExports();
  }

  render() {
    return (
      <div className={styles.container}>
        <Heading
          className="heading"
          title="Data Export"
          rightAccessory={this.renderDropdown()}
        />
        <div className="content">
          <DataExportList
            loading={this.props.dataExports.loading}
            data={this.props.dataExports.data}
          />
        </div>
      </div>
    );
  }

  renderDropdown = () => {
    return (
      <LinkDropdown text="Export" direction="left">
        <LinkDropdown.Item className={styles.link} onClick={this.handleExportProspectsClick}>
          Export Prospects
        </LinkDropdown.Item>
        <LinkDropdown.Item className={styles.link} onClick={this.handleExportAgenciesClick}>
          Export Agencies
        </LinkDropdown.Item>
        <LinkDropdown.Item className={styles.link} onClick={this.handleExportTempestDataClick}>
          Export Tempest Data
        </LinkDropdown.Item>
      </LinkDropdown>
    );
  }

  handleExportProspectsClick = () => {
    this.props.onExportProspects({
      prospectIds: null,
    });
  }

  handleExportAgenciesClick = () => {
    this.props.onExportAgencies({
      agencyIds: null,
    });
  }

  handleExportTempestDataClick = () => {
    this.props.onExportTempest({
      agencyShortCodes: null,
    });
  }

}
