import gql from "graphql-tag";
import * as types from "../types";
import { DOCUMENT_TYPES } from "../../../documents";
import { apolloClient } from "../../../../apolloClient";

const GET_PAYROLL_INFO = gql`
query(
  $agency_id: ID!
  $pension_document_input: AgencyDocumentInput!
  $vat_document_input: AgencyDocumentInput!
) {
  getAgency(id: $agency_id) {
    vat_number

    auto_enrolment_pension_info {
      pension_company {
        label
      }
      pension_scheme_weekly
      pension_scheme_monthly
      staging_date
      payment_source_reference_weekly
      payment_source_reference_monthly
      default_postponement_months
      eligible_job_holder_postponement_months
      employer_pension_reference
    }

    is_new_agency
    is_existing_paye_scheme
    vat_period_ending_date
    vat_frequency
    next_vat_return_period_end
    applying_registered_vat
    previous_pension_provider {
      label
    }

    hmrc_info {
      paye_scheme_reference
      accounts_office_reference
      claiming_employers_ni_allowance
      small_employers_relief
      intermediary_reporting
      apprenticeship_levy
    }

    holiday_pay_info {
      has_holiday_pot
      end_of_holiday_year_pay_period
      end_of_holiday_year_tax_year_end
      can_carry_over_accrual
      default_holiday_scheme {
        label
      }
      holiday_accrual_period {
        label
      }
    }

    preferences {
      payroll_frequency
      default_payslip_preference
      default_customer_vat_rate
    }

    worker_types {
      id
      name
    }
  }

  getWorkerTypes {
    id
    name
  }

  getPensionDocument: getAgencyDocument(input: $pension_document_input) {
    name
  }

  getVatDocument: getAgencyDocument(input: $vat_document_input) {
    name
  }
}
`;

export const getPayrollInfo = agencyId => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PAYROLL_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PAYROLL_INFO,
      variables: {
        agency_id: agencyId,
        pension_document_input: {
          agency_id: agencyId,
          document_type: DOCUMENT_TYPES.PENSION_DOCUMENT,
        },
        vat_document_input: {
          agency_id: agencyId,
          document_type: DOCUMENT_TYPES.VAT_CERTIFICATE,
        },
      },
    });

    dispatch({
      type: types.GET_PAYROLL_INFO_SUCCESS,
      payload: {
        payrollInfo: response.data.getAgency,
        workerTypes: response.data.getWorkerTypes,
        pensionDocument: response.data.getPensionDocument,
        vatDocument: response.data.getVatDocument,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PAYROLL_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
