import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Switch, Redirect, Route, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../duck";
import { REACT_APP_FEATURE_FLAG_SHOW_TRANSFER_TAB } from "../../../config";
import {
  AgencyHeading,
  PayrollTransferConfirmationModal,
  PayrollTransferValidationModal,
} from "../components";
import { CompanyTab, HistoryTab, DocumentsTab, ContactsTab, PayrollTab, ReportingTab, TransfersTab } from "../tabs";
import { Button, ConditionalTooltip, TabControlRouter } from "../../common/components";
import styles from "./AgencyPage.module.scss";

class AgencyPage extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agency: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
      logo_url: PropTypes.string,
      opera_version: PropTypes.string,
      opera_company: PropTypes.string,
      agency_status: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }).isRequired,
      preferences: PropTypes.shape({
        payroll_frequency: PropTypes.oneOf(["weekly", "monthly", "both"]),
      }),
      tempest_mappings: PropTypes.array.isRequired,
      transfers: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string,
      })),
    }),
    payrollTransferValidation: PropTypes.shape({
      data: PropTypes.shape({
        success: PropTypes.bool,
        results: PropTypes.object,
      }),
    }),
    getAgency: PropTypes.func.isRequired,
    getAgencyTransferInformation: PropTypes.func.isRequired,
    initiateAgencyTransfer: PropTypes.func.isRequired,
    validatePayrollTransfer: PropTypes.func.isRequired,
  }

  initialState = {
    showBackToAgencies: true,
    showTransferToPayrollModal: false,
  }

  state = this.initialState;

  async componentDidMount() {
    const { agencyId, agency, getAgency, getAgencyTransferInformation } = this.props;

    // Make sure we don't reload the Agency every time we change the tab
    if (agency?.id !== agencyId) {
      await getAgency(agencyId);
    }

    await getAgencyTransferInformation({ agencyId });
  }

  render() {
    const { match } = this.props;

    return (
      <div>
        {this.renderAgencyInfo()}
        <div className={styles.navBar}>
          <TabControlRouter onTabChanged={this.handleTabChanged}>
            <Link title="Agency" to={`${match.url}/agency`} />
            <Link title="Contacts" to={`${match.url}/contacts`} />
            <Link title="Payroll" to={`${match.url}/payroll`} />
            <Link title="Customers" to={`${match.url}/customers`} disabled />
            <Link title="Workers" to={`${match.url}/workers`} disabled />
            {/* <Link title="History" to={`${match.url}/history`} /> */}
            <Link title="Documents" to={`${match.url}/documents`} />
            <Link title="Reporting" to={`${match.url}/reporting`} />
            {REACT_APP_FEATURE_FLAG_SHOW_TRANSFER_TAB && (
              <Link title="Transfers" to={`${match.url}/transfers`} />
            )}
          </TabControlRouter>
        </div>
        <Switch>
          <Route exact path={`${match.path}/agency`} render={props => (
            <CompanyTab {...props} onLoad={this.handleTabLoad} />
          )} />
          <Route exact path={`${match.path}/contacts`} render={props => (
            <ContactsTab {...props} onLoad={this.handleTabLoad} />
          )} />
          <Route exact path={`${match.path}/payroll`} render={props => (
            <PayrollTab {...props} onLoad={this.handleTabLoad} />
          )} />
          <Route exact path={`${match.path}/customers`}>
            <Redirect to={`${match.url}/agency`} />
          </Route>
          <Route exact path={`${match.path}/workers`}>
            <Redirect to={`${match.url}/agency`} />
          </Route>
          <Route exact path={`${match.path}/history`} render={props => (
            <HistoryTab {...props} onLoad={this.handleTabLoad} />
          )} />
          <Route exact path={`${match.path}/documents`} render={props => (
            <DocumentsTab {...props} onLoad={this.handleTabLoad} />
          )} />
          <Route exact path={`${match.path}/reporting`} render={props => (
            <ReportingTab {...props} onLoad={this.handleTabLoad} />
          )} />
          <Route exact path={`${match.path}/reporting/:mode`} render={props => (
            <ReportingTab {...props} onLoad={this.handleTabLoad} />
          )} />
          {REACT_APP_FEATURE_FLAG_SHOW_TRANSFER_TAB && (
            <Route exact path={`${match.path}/transfers`} render={props => (
              <TransfersTab {...props} onLoad={this.handleTabLoad} />
            )} />
          )}
        </Switch>
      </div>
    );
  }

  renderAgencyInfo = () => {
    const { agency, payrollTransferValidation } = this.props;
    const isAgencyActive = agency?.agency_status?.value === "live_active";

    return (
      <div className={styles.agencyInfo}>
        <AgencyHeading
          className={styles.header}
          loading={!agency}
          agency={{
            shortCode: agency?.short_code,
            name: agency?.known_as,
            logoUrl: agency?.logo_url,
            operaInfo: {
              version: agency?.opera_version,
              company: agency?.opera_company,
            },
          }}
        />
        <div className={styles.actions}>
          {this.canTransferToPayroll() && (
            <ConditionalTooltip
              identifier="payroll_transfer"
              condition={!isAgencyActive}
              content="Transfer to payroll system can only be completed for Live (Active) agencies."
            >
              <Button
                variant="primary"
                disabled={!isAgencyActive || payrollTransferValidation?.loading}
                busy={payrollTransferValidation?.loading}
                onClick={this.handleTransferToPayroll}
              >
                Transfer to Payroll
              </Button>
            </ConditionalTooltip>
          )}
          {this.state.showBackToAgencies && (
            <div className={styles.backToAgencies}>
              <Button variant="secondary" onClick={this.handleBackToAgencies}>Back to Agencies</Button>
            </div>
          )}
        </div>
        {payrollTransferValidation?.data?.success ? (
          <PayrollTransferConfirmationModal
            visible={!payrollTransferValidation?.loading && this.state.showTransferToPayrollModal}
            onCancel={this.handleCloseTransferToPayrollModal}
            onSubmit={this.handleConfirmTransferToPayroll}
          />
        ) : (
          <PayrollTransferValidationModal
            visible={!payrollTransferValidation?.loading && this.state.showTransferToPayrollModal}
            errors={payrollTransferValidation?.data?.results}
            onConfirmClick={this.handleCloseTransferToPayrollModal}
          />
        )}
      </div>
    );
  }

  handleTabChanged = (title) => {
    switch (title) {
      case "Agency":
        break;

      case "People":
        break;

      case "Payroll":
        break;

      case "Customers":
        break;

      case "Workers":
        break;

      default:
        break;
    }

    this.setState({
      showBackToAgencies: false,
    });
  }

  handleTabLoad = (url) => {
    this.setState({
      showBackToAgencies: ["/agencies/:agencyId/agency", "/agencies/:agencyId/payroll"].includes(url),
    });
  }

  handleBackToAgencies = () => {
    const { history } = this.props;

    history.push("/agencies/");
  }

  handleTransferToPayroll = () => {
    const { agencyId, validatePayrollTransfer } = this.props;

    this.setState({
      showTransferToPayrollModal: true,
    }, () => validatePayrollTransfer({ agencyId }));
  }

  handleCloseTransferToPayrollModal = () => {
    this.setState({
      showTransferToPayrollModal: false,
    });
  }

  handleConfirmTransferToPayroll = async () => {
    const {
      agencyId,
      getAgency,
      getAgencyTransferInformation,
      initiateAgencyTransfer,
    } = this.props;

    await initiateAgencyTransfer({ agencyId });
    await getAgency(agencyId);
    await getAgencyTransferInformation({ agencyId });

    this.setState({
      showTransferToPayrollModal: false,
    });
  }

  canTransferToPayroll = () => {
    const { agency } = this.props;

    // return false if we have incomplete data
    if (agency?.tempest_mappings?.length > 0
      && agency?.tempest_mappings.every(mapping => _.isNil(mapping.payroll_frequency))) {
      return false;
    }

    // return false if there is an in progress transfer
    if (agency?.transfers?.some(transfer => ["pending", "running"].includes(transfer.status))) {
      return false;
    }

    if (agency?.preferences?.payroll_frequency === "weekly") {
      return !agency?.tempest_mappings.some(x => x.payroll_frequency === "weekly");
    }

    if (agency?.preferences?.payroll_frequency === "monthly") {
      return !agency?.tempest_mappings.some(x => x.payroll_frequency === "monthly");
    }

    return agency && (agency?.tempest_mappings ?? []).length < 2;
  }

}

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgencyPage);
