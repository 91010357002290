import { connect } from "react-redux";
import { push } from "connected-react-router";
import OnboardingLandingPage from "./OnboardingLandingPage";

const mapStateToProps = null;
const mapDispatchToProps = dispatch => ({
  onOptionClick: (optionName) => {
    switch (optionName) {
      case "view_as_agency":
        dispatch(push("/onboarding/agencies"));
        break;

      case "view_as_task":
        dispatch(push("/onboarding/tasks"));
        break;

      case "view_as_prospect":
        dispatch(push("/onboarding/prospects"));
        break;

      default:
        // eslint-disable-next-line no-console
        console.error("Unknown option:", optionName);
        break;
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingLandingPage);
