import { connect } from "react-redux";
import { push } from "connected-react-router";
import SelfBillingOrganisationsPage from "./SelfBillingOrganisationsPage";

const mapStateToProps = () => ({
  selfBillingOrganisations: {
    data: [],
    loading: false,
  },
});
const mapDispatchToProps = dispatch => ({
  onAddNewClick: () => {
    dispatch(push("/credit-limit-management/self-billing-organisations/new"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelfBillingOrganisationsPage);
