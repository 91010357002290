import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { TradingAddressForm } from "../../components";
import styles from "./EditTradingAddressPage.module.scss";

export default class EditTradingAddressPage extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    tradingAddress: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
      submitting: PropTypes.bool,
    }),
    onFetchTradingAddress: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { prospectId, onFetchTradingAddress } = this.props;

    await onFetchTradingAddress({ prospectId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.tradingAddress?.loading ? (
          <ActivityIndicator />
        ) : (
          <TradingAddressForm
            data={this.props.tradingAddress?.data}
            submitting={this.props.tradingAddress?.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onDisabledFieldClick={this.handleDisabledFieldClick}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { prospectId, onCancel } = this.props;

    await onCancel(prospectId);
  }

  handleSubmit = async (data) => {
    const { prospectId, onSubmit } = this.props;

    await onSubmit({ prospectId, data });
  }

  handleDisabledFieldClick = async (disabledField) => {
    const { prospectId, onDisabledFieldClick } = this.props;

    await onDisabledFieldClick({ prospectId, disabledField });
  }

}
