

import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const IMPORT_TEMPEST = gql`
mutation {
  importTempest {
    upload_url
    data_import {
      import_id
      import_type
      status
      error
      created_at
      created_by {
        first_name
        last_name
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {any} obj.file The file to upload (taken from the input html element)
 * @returns
 */
export const importTempest = ({ file }) => async (dispatch) => {
  try {
    dispatch({
      type: types.IMPORT_TEMPEST_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: IMPORT_TEMPEST,
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.IMPORT_TEMPEST_SUCCESS,
      payload: {
        data: response.data.importTempest.data_import,
      },
    });

    await fetch(response.data.importTempest.upload_url, {
      method: "PUT",
      body: file,
    });
  }
  catch (error) {
    dispatch({
      type: types.IMPORT_TEMPEST_FAILURE,
      payload: {
        error,
      },
    });
  }
};
