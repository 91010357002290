import _ from "lodash";

export const parseFieldValue = (value) => {
  if (_.isNil(value) || value === "") {
    return null;
  }
  if (_.isArray(value) || _.isObjectLike(value)) {
    return JSON.stringify(value);
  }
  return String(value);
};
