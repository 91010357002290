import { AddAccountReferenceModal } from "../../modals";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.payrollAgencyCustomerRequestId
 * @param {string} obj.customerAccountId
 * @param {string} obj.shortCode
 */
export const showAddAccountReferenceModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(AddAccountReferenceModal.displayName, {
    payrollAgencyCustomerRequestId: obj.payrollAgencyCustomerRequestId,
    customerAccountId: obj.customerAccountId,
    shortCode: obj.shortCode,
  }));
};
