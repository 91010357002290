import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_ONBOARDING_TASKS = gql`
query($search_options: OnboardingTasksSearchOptions!) {
  getOnboardingTasks(search_options: $search_options) {
    id
    name
    completed_agencies {
      status
      agency {
        short_code
        name
      }
    }
    outstanding_agencies {
      id
      agency {
        short_code
      }
      status
      due_date
    }
  }
}
`;

export const getOnboardingTasks = ({
  searchText,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ONBOARDING_TASKS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_ONBOARDING_TASKS,
      variables: {
        search_options: {
          applicable_type: "AGENCY",
          task_name: searchText,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_ONBOARDING_TASKS_SUCCESS,
      payload: {
        onboardingTasks: response.data.getOnboardingTasks,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_ONBOARDING_TASKS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
