import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import TransfersTab from "./TransfersTab";

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchTransfers: actions.getTransfers,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransfersTab);
