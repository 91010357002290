import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Table } from "semantic-ui-react";
import {
  CancelOutlined,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import { VerifiedIcon } from "../../";
import { TextLink } from "../../../../common/components";
import { toMoney } from "../../../../../utils";
import styles from "./AgencyCustomerRow.module.scss";

export default function AgencyCustomerRow(props) {
  const isStriped = props.index % 2 === 1;

  const renderTradingStatusLabel = () => {
    return (
      <>
        <CancelOutlined className={styles.notTradingIcon} />
        Not Trading
      </>
    );
  };

  return (
    <>
      <Table.Row
        key={`agency_customer_${props.agencyCustomer.id}`}
        className={classnames(
          styles.agencyCustomerRow,
          isStriped && styles.stripe,
          props.expanded && styles.expandedRow,
        )}
        onClick={() => props.onRowClick(props.agencyCustomer)}
      >
        <Table.Cell className={styles.agencyNameCell}>
          <div>
            <div className={styles.agencyNameWrapper}>
              <TextLink
                className={styles.agencyName}
                text={props.agencyCustomer.agency?.known_as ?? props.agencyCustomer.prospect?.known_as}
                to={props.agencyCustomer.agency
                  ? `/agencies/${props.agencyCustomer.agency?.id}`
                  : `/prospects/${props.agencyCustomer.prospect?.id}`}
              />
              {props.agencyCustomer.agency_customer_request?.verified_at && (
                <VerifiedIcon
                  className={styles.verifiedIcon}
                  verifiedAt={props.agencyCustomer.agency_customer_request.verified_at}
                  verifiedBy={{
                    firstName: props.agencyCustomer.agency_customer_request.verified_by?.first_name,
                    lastName: props.agencyCustomer.agency_customer_request.verified_by?.last_name,
                  }}
                  mismatchInfo={{
                    reason: props.agencyCustomer.agency_customer_request?.verify_mismatch_reason?.label,
                    details: props.agencyCustomer.agency_customer_request?.verify_mismatch_reason_details,
                  }}
                />
              )}
            </div>
            {!props.agencyCustomer.agency && (
              <div className={styles.prospectTag}>Prospect</div>
            )}
            {props.agencyCustomer.credit_requests?.length > 0 &&
              props.agencyCustomer.credit_requests[0].status === "pending" &&  (
                <div className={styles.creditRequestWaitingContainer}>
                  <span className={styles.creditRequestWaitingTitle}>
                    {props.agencyCustomer.agency && ("Agency ")}request awaiting distribution
                  </span>
                  <TextLink
                    className={styles.creditRequestWaitingLink}
                    text="Go to request"
                    to={{
                      pathname: `/credit-limit-management/legal-entities/${props.agencyCustomer.credit_requests[0].legal_entity?.id}/request-history`,
                      state: {
                        filters: {
                          agency_id: props.agencyCustomer.agency ? [props.agencyCustomer.agency.id] : undefined,
                          prospect_id: props.agencyCustomer.prospect ? [props.agencyCustomer.prospect.id] : undefined,
                          status: ["pending"],
                        },
                      },
                    }} />
                </div>
              )}
          </div>
        </Table.Cell>
        <Table.Cell textAlign="right">
          {_.isNull(props.agencyCustomer.allocated_credit)
            ? "-"
            : toMoney(props.agencyCustomer.allocated_credit)
          }
        </Table.Cell>
        <Table.Cell textAlign="right">
          {_.isNull(props.agencyCustomer.allocated_credit)
            ? "-"
            : toMoney(props.agencyCustomer.debt_amount || 0)
          }
        </Table.Cell>
        <Table.Cell textAlign="right">
          {_.isNull(props.agencyCustomer.allocated_credit)
            ? "-"
            : toMoney(props.agencyCustomer.exposure_amount || 0)
          }
        </Table.Cell>
        <Table.Cell textAlign="right" verticalAlign="middle">
          {props.expanded
            ? <ExpandLess className={styles.icon} />
            : <ExpandMore className={styles.icon} />
          }
        </Table.Cell>
      </Table.Row>
      {props.expanded && props.agencyCustomer.customer_accounts.map(customerAccount => (
        <React.Fragment key={`customer_account_${customerAccount.id}`}>
          <Table.Row
            className={classnames(
              styles.customerAccountRow,
              isStriped && styles.stripe,
            )}
          >
            <Table.Cell>
              {customerAccount.display_name}
            </Table.Cell>
            <Table.Cell
              className={styles.customerAccountCredit}
              textAlign="right"
            >
              {_.isNull(customerAccount.allocated_credit)
                ? "-"
                : toMoney(customerAccount.allocated_credit)
              }
            </Table.Cell>
            <Table.Cell
              className={styles.customerAccountCredit}
              textAlign="right"
            >
              {_.isNull(customerAccount.allocated_credit)
                ? "-"
                : toMoney(0)
              }
            </Table.Cell>
            <Table.Cell
              className={styles.customerAccountCredit}
              textAlign="right"
            >
              {_.isNull(customerAccount.allocated_credit)
                ? "-"
                : toMoney(0)
              }
            </Table.Cell>
            <Table.Cell colSpan={2}>
              <div className={styles.tradingStatus}>
                {renderTradingStatusLabel()}
              </div>
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      ))}
    </>
  );
}

AgencyCustomerRow.propTypes = {
  index: PropTypes.number.isRequired,
  agencyCustomer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    agency: PropTypes.shape({
      id: PropTypes.string.isRequired,
      known_as: PropTypes.string.isRequired,
    }),
    prospect: PropTypes.shape({
      id: PropTypes.string.isRequired,
      known_as: PropTypes.string.isRequired,
    }),
    allocated_credit: PropTypes.number,
    debt_amount: PropTypes.number,
    exposure_amount: PropTypes.number,
    agency_customer_request: PropTypes.shape({
      verified_at: PropTypes.number,
      verified_by: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }),
    }),
    customer_accounts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
      allocated_credit: PropTypes.number.isRequired,
    })),
    credit_requests: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string.isRequired,
      legal_entity: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    })),
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func.isRequired,
};
