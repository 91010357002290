import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Form } from "../../../common/components";
import CompleteSignUpFormValidator from "./CompleteSignUpFormValidator";
import styles from "./CompleteSignUpForm.module.scss";

export default class CompleteSignUpForm extends Component {

  static propTypes = {
    className: PropTypes.string,
    submitting: PropTypes.bool,
    onSubmit: PropTypes.func,
  }

  state = {
    password: "",
    confirmPassword: "",
    errors: {},
  }

  render() {
    return (
      <Form className={classnames(styles.container, this.props.className)}>
        <Form.Group error={this.state.errors?.password}>
          <Form.Label>Password</Form.Label>
          <Form.Input
            name="password"
            type="password"
            value={this.state.password}
            onChange={this.handleInputChange}
            fluid
            highlightError={this.state.errors?.password}
          />
        </Form.Group>
        <Form.Group error={this.state.errors?.confirmPassword}>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Input
            name="confirmPassword"
            type="password"
            value={this.state.confirmPassword}
            onChange={this.handleInputChange}
            fluid
            highlightError={this.state.errors?.confirmPassword}
          />
        </Form.Group>
        <Button
          className={styles.button}
          variant="primary"
          busy={this.props.submitting}
          disabled={this.props.submitting}
          onClick={this.handleSubmit}
        >
          Complete Sign Up
        </Button>
      </Form>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;

    const validator = new CompleteSignUpFormValidator(this.state);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit && onSubmit(this.state);
  }

}
