import _ from "lodash";
import { isSubtaskDependencyValid } from "./is-subtask-dependency-valid";

export const isSubtaskVisible = (subtask, state = {}) => {
  return _.chain(subtask.depends_on || [])
    .filter(dependency => dependency?.type === "visibility")
    .every((dependency) => {
      if (dependency.$and) {
        return dependency.$and.every(innerDependency => isSubtaskDependencyValid(innerDependency, state));
      }
      if (dependency.$or) {
        return dependency.$or.some(innerDependency => isSubtaskDependencyValid(innerDependency, state));
      }
      return isSubtaskDependencyValid(dependency, state);
    })
    .value();
};
