import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_BUSINESS_DEVELOPMENT_MANAGERS = gql`
query($filter_options: UserFilterOptions, $sort_order: [SortOrder]) {
  getUsers(filter_options: $filter_options, sort_order: $sort_order) {
    id
    first_name
    last_name
  }
}
`;

export const getBusinessDevelopmentManagers = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_BUSINESS_DEVELOPMENT_MANAGERS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_BUSINESS_DEVELOPMENT_MANAGERS,
      variables: {
        filter_options: {
          user_type: "back_office",
          department: "sales",
        },
        sort_order: [
          {
            field: "first_name",
            order: "asc",
          },
        ],
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS,
      payload: {
        data: response.data.getUsers,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_BUSINESS_DEVELOPMENT_MANAGERS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
