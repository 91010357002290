import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Dropdown } from "semantic-ui-react";
import {
  CancelOutlined,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  CloseRounded,
  ExpandMoreRounded,
  HelpOutlineRounded,
  HistoryRounded,
} from "@material-ui/icons";
import { BadgePill, Button } from "../../../../common/components";
import { InsurerApplicationEventLog } from "../../../components";
import { options } from "../../../../../constants";
import styles from "./InsurerApplication.module.scss";

export default class InsurerApplication extends Component {

  static propTypes = {
    loading: PropTypes.bool,
    legalEntityId: PropTypes.string.isRequired,
    creditApplicationId: PropTypes.string.isRequired,
    creditApplication: PropTypes.shape({
      data: PropTypes.shape({
        status: PropTypes.string.isRequired,
      }),
    }),
    getCreditApplication: PropTypes.func.isRequired,
    createCreditApplicationComment: PropTypes.func.isRequired,
    completeCreditApplication: PropTypes.func.isRequired,
    cancelCreditApplication: PropTypes.func.isRequired,
    showCompanyNotFoundModal: PropTypes.func.isRequired,
    showCreditApplicationCommentModal: PropTypes.func.isRequired,
    showDeclineCreditApplicationModal: PropTypes.func.isRequired,
    showFullyApproveCreditApplicationModal: PropTypes.func.isRequired,
    showPartApproveCreditApplicationModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { legalEntityId, creditApplicationId, getCreditApplication } = this.props;

    getCreditApplication(legalEntityId, creditApplicationId);
  }

  handleCloseClicked = () => {
    const { legalEntityId, onClose } = this.props;
    onClose(legalEntityId);
  }

  handleLeaveCommentClicked = () => {
    const { showCreditApplicationCommentModal, creditApplicationId } = this.props;

    showCreditApplicationCommentModal({ creditApplicationId });
  }

  getBadgeTitleClassName = () => {
    const creditApplication = this.props.creditApplication.data;
    switch (creditApplication?.status) {
      case "pending":
        return styles.pendingStatusTitle;
      case "partially_accepted":
        return styles.partiallyAcceptedStatusTitle;
      case "accepted":
        return styles.acceptedStatusTitle;
      case "declined":
        return styles.declinedStatusTitle;
      case "company_not_found":
        return styles.companyNotFoundStatusTitle;
      case "cancelled":
        return styles.cancelledStatusTitle;
      default:
        return null;
    }
  }

  getBadgeClassName = () => {
    const creditApplication = this.props.creditApplication.data;
    switch (creditApplication?.status) {
      case "pending":
        return styles.pendingStatusBadge;
      case "partially_accepted":
        return styles.partiallyAcceptedStatusBadge;
      case "accepted":
        return styles.acceptedStatusBadge;
      case "declined":
        return styles.declinedStatusBadge;
      case "company_not_found":
        return styles.companyNotFoundStatusBadge;
      case "cancelled":
        return styles.cancelledStatusBadge;
      default:
        return null;
    }
  }

  getBadgeIcon = () => {
    const creditApplication = this.props.creditApplication.data;
    switch (creditApplication?.status) {
      case "pending":
        return <HistoryRounded className={styles.pendingStatusIcon} />;
      case "partially_accepted":
        return <CheckCircleOutlineRounded className={styles.partiallyAcceptedStatusIcon} />;
      case "accepted":
        return <CheckCircleRounded className={styles.acceptedStatusIcon} />;
      case "declined":
        return <CancelOutlined className={styles.declinedStatusIcon} />;
      case "company_not_found":
        return <HelpOutlineRounded className={styles.companyNotFoundStatusIcon} />;
      case "cancelled":
        return <CloseRounded className={styles.cancelledStatusIcon} />;
      default:
        return null;
    }
  }

  onFullyApproveClick = () => {
    this.props.showFullyApproveCreditApplicationModal({
      creditApplicationId: this.props.creditApplicationId,
      legalEntityId: this.props.legalEntityId,
    });
  }

  onPartApproveClick = () => {
    this.props.showPartApproveCreditApplicationModal({
      creditApplicationId: this.props.creditApplicationId,
      legalEntityId: this.props.legalEntityId,
    });
  }

  onDeclineClick = () => {
    this.props.showDeclineCreditApplicationModal({
      creditApplicationId: this.props.creditApplicationId,
      legalEntityId: this.props.legalEntityId,
    });
  }

  onCompanyNotFoundClick = () => {
    this.props.showCompanyNotFoundModal({
      creditApplicationId: this.props.creditApplicationId,
    });
  }
  render() {
    return this.props.loading
      ? <ActivityIndicator />
      : (
        <>
          <div className={styles.headerContainer}>
            <div className={styles.headerTitle}>Credit Application</div>
            <BadgePill
              title={
                options.CREDIT_APPLICATION_STATUS_OPTIONS?.find(
                option => option.value === this.props.creditApplication.data?.status
              )?.label || this.props.creditApplication.data?.status
              }
              leftIcon={this.getBadgeIcon()}
              pillStyle={this.getBadgeClassName()}
              titleStyle={this.getBadgeTitleClassName()} />
            {this.props.creditApplication.data?.status === "pending" && (
              <div className={styles.recordResultContainer}>
                <Dropdown
                  trigger={(
                    <>
                      <div className={styles.recordResultTitle}>
                          Record Result
                      </div>
                      <ExpandMoreRounded className={styles.recordResultIcon} />
                    </>
                  )}
                  className={styles.dropdown}
                  icon={null}
                  direction="left"
                  floating>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.onFullyApproveClick()}>
                      <div className={styles.actionItem}>
                        <CheckCircleRounded className={styles.fullyApproveActionIcon}/>
                        Fully Approve
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.onPartApproveClick()}>
                      <div className={styles.actionItem}>
                        <CheckCircleOutlineRounded className={styles.partiallyApproveActionIcon}/>
                        Part Approve
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.onDeclineClick()}>
                      <div className={styles.actionItem}>
                        <CancelOutlined className={styles.declineActionIcon}/>
                        Decline
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.onCompanyNotFoundClick()}>
                      <div className={styles.actionItem}>
                        <HelpOutlineRounded className={styles.companyNotFoundActionIcon}/>
                        Company Not Found
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <Button variant="secondary" className={styles.button} onClick={this.handleCloseClicked}>Back to Legal Entity</Button>
          </div>
          <hr className={styles.separator} />
          <div className={styles.subHeaderContainer}>
            <div className={styles.subHeaderTitle}>Application Overview</div>
            <Button variant="secondary" className={styles.button} onClick={this.handleLeaveCommentClicked}>Leave Comment</Button>
          </div>
          <InsurerApplicationEventLog
            creditApplication={this.props.creditApplication.data}/>
        </>
      );
  }
}
