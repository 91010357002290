import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class TableCell extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  render() {
    const { className, style, children } = this.props;

    return (
      <div
        className={classnames("TableCell", className)}
        style={style}
      >
        {Array.isArray(children)
          ? children.map((child, i) => <div className="cell-row" key={i}>{child}</div>)
          : children
        }
      </div>
    );
  }

}
