import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_AGENCY_CUSTOMER_REQUEST = gql`
mutation($input: AddAgencyCustomerRequestInput!) {
  addAgencyCustomerRequest(input: $input) {
    id
    registered_company_number
    exact_legal_name
    credit_limit_requested
    created_at

    agency {
      id
      known_as

      extended {
        logo_url
      }
    }

    prospect {
      id
      known_as

      extended {
        logo_url
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @param {string} obj.legalEntityId
 * @param {number} obj.creditLimitRequested
 * @param {string} obj.registeredCompanyNumber
 * @param {string} obj.exactLegalName
 * @param {string} obj.redirectUrl (optional)
 * @param {boolean} obj.isProspect (optional)
 * @returns
 */
export const addAgencyCustomerRequest = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_AGENCY_CUSTOMER_REQUEST_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: ADD_AGENCY_CUSTOMER_REQUEST,
      variables: {
        input: {
          [obj.isProspect ? "prospect_id" : "agency_id"]: obj.agencyId,
          legal_entity_id: obj.legalEntityId,
          credit_limit_requested: obj.creditLimitRequested || 0,
          registered_company_number: obj.registeredCompanyNumber,
          exact_legal_name: obj.exactLegalName,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.ADD_AGENCY_CUSTOMER_REQUEST_SUCCESS,
      payload: {
        data: response.data.addAgencyCustomerRequest,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.ADD_AGENCY_CUSTOMER_REQUEST_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
