import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import styles from "./MaskedValue.module.scss";

export default function MaskedValue({
  value,
  maskOptions,
  style,
}) {
  const defaultValue = value || "-";

  return (
    <InputMask
      className={styles.valueInput}
      value={defaultValue}
      disabled={true}
      readOnly={true}
      {...value && { ...maskOptions }}
      style={style}
    />
  );
}

MaskedValue.propTypes = {
  value: PropTypes.string,
  maskOptions: PropTypes.shape({
    maskChar: PropTypes.any,
    mask: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object,
};

MaskedValue.defaultProps = {
  maskOptions: {
    maskChar: null,
  },
};
