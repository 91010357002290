import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import FormMultiSelectItem from "./FormMultiSelectItem/FormMultiSelectItem";
import styles from "./FormMultiSelect.module.scss";
import { Pill } from "../../";

export default class FormMultiSelect extends Component {

  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(["default", "small", "medium", "large"]),
    inline: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.any),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    pillVariant: PropTypes.oneOf(["noir", "compact"]),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })),
    onClear: PropTypes.func.isRequired,
  }

  static defaultProps = {
    size: "default",
    placeholder: "Select a value",
    values: [],
    options: [],
    pillVariant: "noir",
  }

  dropdownRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      dropDownVisible: false,
    };
  }

  render() {
    const { className, size, disabled, readOnly, inline } = this.props;

    return (
      <div
        className={classnames(
          styles.container,
          !inline && styles[size],
          readOnly && styles.readOnly,
          disabled && styles.disabled,
          className,
          inline && styles.inline,
        )}
        ref={this.dropdownRef}
        tabIndex={0}
        onClick={this.handleDropDownClicked}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
      >
        {readOnly
          ? this.renderCompletedValues()
          : this.renderMultiSelect()
        }
      </div>
    );
  }

  renderMultiSelect = () => {
    const { options, onClear } = this.props;
    const { dropDownVisible } = this.state;

    const textValue = this.getTextValue();
    const dropArrow = this.state.dropDownVisible ? faChevronUp : faChevronDown;
    const selectedIndexes = this.getSelectedIndexes();

    return (
      <>
        <div
          className={styles.label}
          selectable={false}
        >
          {textValue}
        </div>
        <>
          <FontAwesomeIcon icon={dropArrow} />
          <div className={classnames(styles.list, dropDownVisible && styles.visible)} style={this.calculateStyle()}>
            {onClear && <div className={styles.clearFilter} onClick={this.handleClearFilters}>Clear</div>}
            {options.map((option, index) => (
              <FormMultiSelectItem
                key={`${option.value}_${index}`}
                label={option.label}
                value={option.value}
                checked={selectedIndexes.includes(index)}
                onClick={this.handleItemClicked.bind(this, index)}
              />
            ))}
          </div>
        </>
      </>
    );
  }

  renderCompletedValues = () => {
    const { options, values, pillVariant, inline } = this.props;

    if (values.length === 0) {
      return <span className={styles.defaultValue}>-</span>;
    }

    const completedLabels = _.chain(values)
      .map(value => options.find(option => _.snakeCase(option.value) === _.snakeCase(value)))
      .map(value => value?.label)
      .compact()
      .value();

    return (
      <div className={classnames(styles.completedValues, inline && styles.inline)}>
        {completedLabels.map(label => (
          <Pill
            key={label}
            className={styles.completedValue}
            variant={pillVariant}
          >
            {label}
          </Pill>
        ))}
      </div>
    );
  }


  getTextValue = () => {
    const { placeholder, options } = this.props;
    const selectedIndexes = this.getSelectedIndexes();

    if (selectedIndexes.length === 0) {
      return placeholder;
    }

    if (selectedIndexes.length === 1) {
      return options[selectedIndexes[0]].label;
    }

    return `${selectedIndexes.length} selected`;
  }

  getSelectedIndexes = () => {
    const { values, options } = this.props;

    return values.map(value => options.findIndex(option => _.snakeCase(option.value) === _.snakeCase(value)));
  }

  handleMouseDown = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target) && this.state.dropDownVisible ) {
      this.setState({ dropDownVisible: false });
    }
  }

  handleDropDownClicked = () => {
    if (this.props.disabled) return;
    this.setState({ dropDownVisible: !this.state.dropDownVisible });
  }

  handleBlur = () => {
    this.setState({
      dropDownVisible: false,
    });
  }

  handleItemClicked = (index) => {
    const { onChange, options, name } = this.props;
    const selectedIndexes = this.getSelectedIndexes();

    const newSelectedIndexes = selectedIndexes.includes(index)
      ? selectedIndexes.filter(idx => idx !== index)
      : [...selectedIndexes, index];

    onChange && onChange({
      name,
      target: {
        values: newSelectedIndexes.map(i => options[i].value),
      },
    });
  }

  handleClearFilters = () => {
    const { onClear } = this.props;

    onClear && onClear();
  }

  calculateStyle = () => {
    if (this.dropdownRef && this.dropdownRef.current) {
      const getInt = str => Number(str.substring(0, str.length - 2));
      const bounds = this.dropdownRef.current.getBoundingClientRect();
      const top = bounds.height;
      const computed = window.getComputedStyle(this.dropdownRef.current);
      const borderWidths = getInt(computed.getPropertyValue("border-left-width")) + getInt(computed.getPropertyValue("border-right-width"));
      const width = bounds.width - borderWidths;
      return { top, width };
    }
    return {};
  }

}

FormMultiSelect.Item = FormMultiSelectItem;
