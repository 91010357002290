import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ProspectsPage from "./ProspectsPage";

const mapStateToProps = state => ({
  ...state.prospects.prospects,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onSearch: actions.getProspects,
  }, dispatch),
  onGoToProspect: (prospectId) => {
    dispatch(push(`/prospects/${prospectId}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProspectsPage);
