import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./CancelBrokerRequestModal.module.scss";

export default function CancelBrokerRequestModal (props) {

  const handleSubmit = () => {
    props.cancelCreditApplication({
      credit_application_id: props.creditApplicationId,
    });
    props.hideModal(CancelBrokerRequestModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(CancelBrokerRequestModal.displayName);
  };

  return (
    <ConfirmationModal
      title="Are you sure?"
      visible
      className={styles.container}
      submitDisabled={props.submitting}
      submitText="Cancel Broker Request"
      cancelText="Go Back"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      cancelVariant="outline-primary"
      submitVariant="danger"
    >
      <div>
        Are you sure you want to cancel this broker request? No changes will be made to this account.
      </div>
    </ConfirmationModal>
  );
}

CancelBrokerRequestModal.propTypes = {
  submitting: PropTypes.bool,
  creditApplicationId: PropTypes.string.isRequired,
  cancelCreditApplication: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

CancelBrokerRequestModal.displayName = "CancelBrokerRequestModal";
