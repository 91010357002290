import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_CUSTOMER_ACCOUNT_REFERENCE = gql`
  mutation($input: AddCustomerAccountReferenceInput!) {
    addCustomerAccountReference(input : $input) {
      id
      reference
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.reference
 * @param {string} obj.payrollAgencyCustomerRequestId
 * @param {string} obj.customerAccountId
 * @returns
 */
export const addCustomerAccountReference = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_CUSTOMER_ACCOUNT_REFERENCE_PENDING,
    });

    const response = await apolloClient.query({
      query: ADD_CUSTOMER_ACCOUNT_REFERENCE,
      variables: {
        input: {
          reference: obj.reference,
          payroll_agency_customer_request_id: obj.payrollAgencyCustomerRequestId,
          customer_account_id: obj.customerAccountId,
        },
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    dispatch({
      type: types.ADD_CUSTOMER_ACCOUNT_REFERENCE_SUCCESS,
      payload: {
        data: response.data.addCustomerAccountReference,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.ADD_CUSTOMER_ACCOUNT_REFERENCE_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
