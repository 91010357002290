import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const COMPLETE_CREDIT_REVIEW_DATE = gql`
mutation($input: CompleteCreditReviewDateInput!) {
  completeCreditReviewDate(input: $input) {
    id
    legal_entity {
      id
    }

    comment
    next_review_date
    created_by {
      first_name
      last_name
    }

    created_at
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.creditReviewId
 * @param {string} obj.redirectUrl
 * @returns
 */
export const completeCreditReview = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.COMPLETE_CREDIT_REVIEW_PENDING,
    });
    const response = await apolloClient.mutate({
      mutation: COMPLETE_CREDIT_REVIEW_DATE,
      variables: {
        input: {
          credit_review_date_id: obj.creditReviewId,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.COMPLETE_CREDIT_REVIEW_SUCCESS,
      payload: {
        creditReview: response.data.completeCreditReview,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.COMPLETE_CREDIT_REVIEW_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
