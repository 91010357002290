import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { Edit, Lock } from "@material-ui/icons";
import { BranchListView } from "..";
import {
  DocumentDownload,
  Form,
  IconButton,
  MaskedValue,
  SeeHistoryTooltip,
  TextLink,
} from "../../../common/components";
import { DOCUMENT_TYPES } from "../../../documents";
import { List } from "../../../lists/containers";
import { tooltips } from "../../../../constants";
import { CompanyTypeConverter } from "../../../../converters";
import { capitalize, formatSortCode, formatUrl, parseDate } from "utils";
import styles from "./CompanyView.module.scss";

export default class CompanyView extends Component {

  static propTypes = {
    readOnly: PropTypes.bool.isRequired,
    companyInfo: PropTypes.shape({
      agency: PropTypes.shape({
        id: PropTypes.string.isRequired,
        short_code: PropTypes.string.isRequired,
        known_as: PropTypes.string.isRequired,
        company_number: PropTypes.string.isRequired,
        exact_legal_name: PropTypes.string.isRequired,
        correspondence_name: PropTypes.string.isRequired,
        officers: PropTypes.shape({
          items: PropTypes.shape({
            name: PropTypes.string.isRequired,
            officer_role: PropTypes.string.isRequired,
          }),
        }),
        companies_house_info: PropTypes.shape({
          accounts: PropTypes.shape({
            last_accounts: PropTypes.shape({
              made_up_to: PropTypes.string,
            }),
          }),
          registered_office_address: PropTypes.shape({
            address_line_1: PropTypes.string,
            locality: PropTypes.string,
            postal_code: PropTypes.string,
          }),
          company_status: PropTypes.string,
          company_status_detail: PropTypes.string,
          type: PropTypes.string,
          date_of_creation: PropTypes.string,
        }),
        contract: PropTypes.shape({
          start_date: PropTypes.instanceOf(Date),
          contract_length: PropTypes.number,
          contract_period: PropTypes.string,
          date_first_traded: PropTypes.instanceOf(Date),
          fee: PropTypes.number,
          funding_type: PropTypes.string,
        }),
        banking: PropTypes.shape({
          agency_id: PropTypes.string,
          sort_code: PropTypes.string,
          account_number: PropTypes.string,
          bank_name: PropTypes.string,
          branch: PropTypes.string,
          payee_name: PropTypes.string,
        }),
        trading_as: PropTypes.string,
        website: PropTypes.string,
        linked_in: PropTypes.string,
        twitter: PropTypes.string,
        introduced_via_broker: PropTypes.bool,
        is_ledger_buyout: PropTypes.bool,
        trading_address: PropTypes.shape({
          address_line_one: PropTypes.string,
          address_line_two: PropTypes.string,
          town_or_city: PropTypes.string,
          county: PropTypes.string,
          postcode: PropTypes.string,
        }).isRequired,
        agency_status: PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
        previous_provider: PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
        industry_sectors: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
        })),
        branches: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
          address_line_one: PropTypes.string,
          address_line_two: PropTypes.string,
          town_or_city: PropTypes.string,
          county: PropTypes.string,
          postcode: PropTypes.string,
          branch: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }).isRequired,
        })),
      }),
      certificateOfIncorporationDocument: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      contractDocument: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
    companyHistory: PropTypes.shape({
      correspondence_name: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      registered_address: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      contract_start_date: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      legal_name: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      trading_as: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      sort_code: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      account_number: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    agencyDisabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    onLoad: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      data: {
        known_as: props.companyInfo.agency.known_as,
        correspondence_name: props.companyInfo.agency.correspondence_name,
        industry_sectors: props.companyInfo.agency.industry_sectors.map(industrySector => industrySector.name),
        trading_as: props.companyInfo.agency.trading_as,
        trading_address: props.companyInfo.agency.trading_address,
        website: props.companyInfo.agency.website,
        linkedIn: props.companyInfo.agency.linked_in,
        twitter: props.companyInfo.agency.twitter,
        agency_status: props.companyInfo.agency.agency_status,
        contract_start_date: props.companyInfo.agency.contract?.start_date,
        length_of_contract: {
          contract_length: props.companyInfo.agency.contract?.contract_length,
          contract_period: props.companyInfo.agency.contract?.contract_period,
        },
        date_first_traded: props.companyInfo.agency.contract?.date_first_traded,
        contract_fee: props.companyInfo.agency.contract?.fee,
        contract_funding_type: props.companyInfo.agency.contract?.funding_type,
        sort_code: props.companyInfo.agency.banking?.sort_code,
        account_number: props.companyInfo.agency.banking?.account_number,
        bank_name: props.companyInfo.agency.banking?.bank_name,
        branch: props.companyInfo.agency.banking?.branch,
        payee_name: props.companyInfo.agency.banking?.payee_name,
        branches: props.companyInfo.agency.branches,
        previous_provider: props.companyInfo.agency.previous_provider,
      },
      errors: {},
      showAgencyStatusUpdateModal: false,
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;

    onLoad && onLoad([
      ...Object.keys(this.state.data),
      "trading_address_line_one",
      "trading_address_line_two",
      "trading_address_town_or_city",
      "trading_address_county",
      "trading_address_postcode",
    ]);
  }

  render() {
    const { companyInfo } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.agencyProfile}>
          <div className={styles.agencyDetail}>
            {this.renderAgencyDetailsSection()}
          </div>
          <div className={styles.companiesHouseDetail}>
            {this.renderCompaniesHouseSection()}
          </div>
          <div className={classnames(styles.row, styles.addresses)}>
            <div className={styles.companiesHouseAddress}>
              {this.renderCompaniesHouseAddressSection()}
            </div>
            <div className={styles.tradingAddress}>
              {this.renderTradingAddressSection()}
            </div>
          </div>
          <div className={classnames(styles.row, styles.contractBank)}>
            <div className={styles.contractInfo}>
              {this.renderContractInformationSection()}
            </div>
            <div className={styles.bankInfo}>
              {this.renderBankInformationSection()}
            </div>
          </div>
          {!_.isEmpty(companyInfo.agency.branches) &&
            <div className={styles.branches}>
              {this.renderBranchesSection()}
            </div>
          }
        </div>
      </div>
    );
  }

  renderCompaniesHouseAddressSection = () => {
    const {
      companyInfo: {
        agency,
      },
    } = this.props;

    return (
      <Form.Card
        title="Companies House Registered Address"
        rightAccessory={(
          <IconButton
            className={styles.locked}
            icon={<Lock />}
            tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
          />
        )}
      >
        <Form.ValueInline>
          <SeeHistoryTooltip
            variant="compact"
            data={this.getRegisteredAddressHistory()}
            currentValue={(this.getRegisteredAddressHistory() ?? [])[0]?.value}
          >
            {this.getAddress(agency.companies_house_info?.registered_office_address)}
          </SeeHistoryTooltip>
        </Form.ValueInline>
      </Form.Card>
    );
  }

  renderCompaniesHouseSection = () => {
    const { data } = this.state;
    const {
      companyInfo: {
        agency,
        certificateOfIncorporationDocument,
      },
    } = this.props;
    const documents = [
      {
        name: DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION,
        file: certificateOfIncorporationDocument,
      },
    ];
    const downloads = documents.filter(doc => doc.file);

    return (
      <Form.Card
        title="Companies House Details"
        rightAccessory={(
          <IconButton
            className={styles.locked}
            icon={<Lock />}
            tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
          />
        )}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.cols}>
            <div className={styles.col}>
              <Form.Group inline>
                <Form.Label>Exact Legal Name</Form.Label>
                <Form.ValueInline>
                  <SeeHistoryTooltip variant="compact" data={this.getExactLegalNameHistory()}>{agency.exact_legal_name}</SeeHistoryTooltip>
                </Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Registered Company Number</Form.Label>
                <Form.ValueInline>
                  {agency.company_number}
                </Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Company Type</Form.Label>
                <Form.ValueInline>
                  {CompanyTypeConverter.to(agency.companies_house_info?.type)}
                </Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Incorporated Date</Form.Label>
                <Form.ValueInline>
                  {parseDate(agency.companies_house_info?.date_of_creation)}
                </Form.ValueInline>
              </Form.Group>
            </div>
            <div className={styles.col}>
              <Form.Group inline>
                <Form.Label> Company Status</Form.Label>
                <Form.ValueInline>
                  {capitalize(agency.companies_house_info?.company_status) || "-"}
                </Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Status Details</Form.Label>
                <Form.ValueInline>
                  {agency.companies_house_info?.company_status_detail || "-"}
                </Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Last Accounts Made Up To</Form.Label>
                <Form.ValueInline>
                  {parseDate(agency.companies_house_info?.accounts?.last_accounts?.made_up_to) || "-"}
                </Form.ValueInline>
              </Form.Group>
              {data.officers?.items && <Form.Group>
                <Form.Label>Officers</Form.Label>
                {data.officers.items.map(value => (
                  <Form.FlexRow key={value.name}>
                    <Form.ValueInline>{value.name} {value.officer_role}</Form.ValueInline>
                  </Form.FlexRow>
                ))}
              </Form.Group>}
            </div>
          </div>
          <DocumentDownload documents={downloads} onDownload={this.handleDownload} variant="footer" />
        </div>
      </Form.Card>
    );
  }

  renderContractInformationSection = () => {
    const { data } = this.state;
    const {
      companyInfo: {
        agency,
        contractDocument,
      },
    } = this.props;

    const documents = [
      {
        name: DOCUMENT_TYPES.CONTRACT,
        file: contractDocument,
      },
    ];
    const downloads = documents.filter(doc => doc.file);

    return (
      <Form.Card
        title="Contract Information"
        rightAccessory={(
          <IconButton
            icon={<Edit />}
            className={styles.edit}
            tooltip="Edit"
            onClick={() => this.handleEditClicked("contract-information")}
          />
        )}
      >
        <div className={styles.contentWrapper}>
          <Form.Group inline>
            <Form.Label>Contract Start Date</Form.Label>
            <Form.ValueInline>
              <SeeHistoryTooltip variant="compact" data={this.getContractStartDateHistory()}>{parseDate(data.contract_start_date) || "-"}</SeeHistoryTooltip>
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Length Of Contract</Form.Label>
            <Form.ValueInline>
              {data.length_of_contract?.contract_length && data.length_of_contract.contract_period ?
                `${data.length_of_contract.contract_length} ${data.length_of_contract.contract_period}`
                : "-"
              }
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Date First Traded</Form.Label>
            <Form.ValueInline>
              {parseDate(data.date_first_traded) || "-"}
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Fee %</Form.Label>
            <Form.ValueInline>{data.contract_fee || "-"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Funding Type</Form.Label>
            <Form.ValueInline>{data.contract_funding_type || "-"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Introduced via Broker?</Form.Label>
            <Form.ValueInline>{agency.introduced_via_broker ? "Yes" : "No"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Ledger Buyout?</Form.Label>
            <Form.ValueInline>{agency.is_ledger_buyout ? "Yes" : "No"}</Form.ValueInline>
          </Form.Group>
          <DocumentDownload documents={downloads} onDownload={this.handleDownload} variant="footer" />
        </div>
      </Form.Card>
    );
  }

  renderAgencyDetailsSection = () => {
    const { data } = this.state;
    const {
      readOnly,
      companyInfo: {
        agency,
      },
    } = this.props;

    return (
      <Form.Card
        title="Agency Details"
        rightAccessory={(
          <IconButton
            icon={<Edit />}
            className={styles.edit}
            tooltip="Edit"
            onClick={() => this.handleEditClicked("agency-details")}
          />
        )}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.cols}>
            <div className={styles.col}>
              <Form.Group inline>
                <Form.Label>Agency Known As Name</Form.Label>
                <Form.ValueInline>{data.known_as || "-"}</Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Exact Legal Name (Payroll Version)</Form.Label>
                <SeeHistoryTooltip
                  variant="compact"
                  data={this.getCorrespondenceNameHistory()}
                  currentValue={data.correspondence_name}
                >
                  <Form.ValueInline>{data.correspondence_name || "-"}</Form.ValueInline>
                </SeeHistoryTooltip>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Trading As</Form.Label>
                <SeeHistoryTooltip
                  variant="compact"
                  data={this.getTradingAsHistory()}
                  currentValue={data.trading_as}
                >
                  <Form.ValueInline>{data.trading_as || "-"}</Form.ValueInline>
                </SeeHistoryTooltip>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Short Code</Form.Label>
                <Form.ValueInline>{agency.short_code}</Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Industry Sector</Form.Label>
                <Form.Value className={styles.industrySector}>
                  <List
                    identifier="industry_sectors"
                    value={data.industry_sectors}
                    readOnly={readOnly}
                    onChange={this.handleSelectedValuesChange}
                    pillVariant="compact"
                    inline
                  />
                </Form.Value>
              </Form.Group>
            </div>
            <div className={styles.col}>
              <Form.Group inline>
                <Form.Label>Agency Status</Form.Label>
                <Form.ValueInline>
                  {data.agency_status?.label}
                </Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Previous Provider</Form.Label>
                <Form.ValueInline>
                  {data.previous_provider?.label || "No previous provider"}
                </Form.ValueInline>
              </Form.Group>
              <Form.Group inline>
                <Form.Label>Agency Website URL</Form.Label>
                <Form.ValueInline>
                  {data.website ?
                    <TextLink
                      className={styles.link}
                      target="_blank"
                      href={formatUrl(data.website)}
                      text={data.website}
                    />
                    :
                    "-"
                  }
                </Form.ValueInline>
              </Form.Group>

              <Form.Group inline>
                <Form.Label>LinkedIn Page</Form.Label>
                <Form.ValueInline>
                  {data.linkedIn ?
                    <TextLink
                      className={styles.link}
                      target="_blank"
                      href={formatUrl(data.linkedIn)}
                      text={data.linkedIn}
                    />
                    :
                    "-"
                  }
                </Form.ValueInline>
              </Form.Group>

              <Form.Group inline>
                <Form.Label>Twitter</Form.Label>
                <Form.ValueInline>
                  {data.twitter ?
                    <TextLink
                      className={styles.link}
                      target="_blank"
                      href={formatUrl(data.twitter)}
                      text={data.twitter}
                    />
                    :
                    "-"
                  }
                </Form.ValueInline>
              </Form.Group>
            </div>
          </div>
        </div>
      </Form.Card>
    );
  }

  renderTradingAddressSection = () => {
    const { trading_address } = this.state.data;

    return (
      <Form.Card
        title="Trading Address"
        rightAccessory={(
          <IconButton
            icon={<Edit />}
            className={styles.edit}
            tooltip="Edit"
            onClick={() => this.handleEditClicked("trading-address")}
          />
        )}
      >
        <Form.ValueInline>
          {trading_address?.address_line_one && (
          <>
            <span>{trading_address.address_line_one}</span>
            <br />
          </>
          )}
          {trading_address?.address_line_two && (
          <>
            <span>{trading_address.address_line_two}</span>
            <br />
          </>
          )}
          {trading_address?.town_or_city && (
          <>
            <span>{trading_address.town_or_city}</span>
            <br />
          </>
          )}
          {trading_address?.county && (
          <>
            <span>{trading_address.county}</span>
            <br />
          </>
          )}
          {trading_address?.postcode && (
          <>
            <span>{trading_address.postcode}</span>
            <br />
          </>
          )}
        </Form.ValueInline>
      </Form.Card>
    );
  }

  renderBankInformationSection = () => {
    const { data } = this.state;

    return (
      <Form.Card
        title="Bank Information"
        rightAccessory={(
          <IconButton
            icon={<Edit />}
            className={styles.edit}
            tooltip="Edit"
            onClick={() => this.handleEditClicked("bank-information")}
          />
        )}
      >
        <Form.Group inline>
          <Form.Label>Sort Code</Form.Label>
          <SeeHistoryTooltip variant="compact" data={this.getSortCodeHistory()}>
            <MaskedValue value={data.sort_code} maskOptions={ { mask: "99-99-99" } } style={ { width: "70px" } } />
          </SeeHistoryTooltip>
        </Form.Group>
        <Form.Group inline>
          <Form.Label>Account Number</Form.Label>
          <SeeHistoryTooltip variant="compact" data={this.getAccountNumberHistory()}>
            <Form.ValueInline>{data.account_number || "-"}</Form.ValueInline>
          </SeeHistoryTooltip>
        </Form.Group>
        <Form.Group inline>
          <Form.Label>Payee Name</Form.Label>
          <Form.ValueInline>{data.payee_name || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label>Bank Name</Form.Label>
          <Form.ValueInline>{data.bank_name || "-"}</Form.ValueInline>
        </Form.Group>
        <Form.Group inline>
          <Form.Label>Branch</Form.Label>
          <Form.ValueInline>{data.branch || "-"}</Form.ValueInline>
        </Form.Group>
      </Form.Card>
    );
  }

  renderBranchesSection = () => {
    const { data } = this.state;

    return (
      <Form.Card title="Branches">
        <BranchListView>
          {data.branches.map((value, index) => (
            <div className={styles.branchItem} key={index}>
              <BranchListView.Item
                index={index}
                active={false}
                branch={{
                  name: value.branch.value,
                  address: this.getAddress(_.pick(value, ["address_line_one", "address_line_two", "town_or_city", "county", "postcode"])),
                }}
                disabled={this.isFieldDisabled("branch_name")}
                rightAccessory={(
                  <IconButton
                    icon={<Edit />}
                    className={styles.edit}
                    tooltip="Edit"
                    onClick={() => this.handleEditClicked(`branch/${value.id}`)}
                  />
                )}
                readOnly
              />
            </div>
          ))}
        </BranchListView>
      </Form.Card>
    );
  }

  handleEditClicked = (section) => {
    const { onEdit } = this.props;

    onEdit && onEdit(section);
  }

  handleDownload = (document) => {
    const { onDownload } = this.props;

    onDownload && onDownload(document);
  }

  getCorrespondenceNameHistory = () => {
    return this.props.companyHistory.correspondence_name;
  }

  getExactLegalNameHistory = () => {
    return this.props.companyHistory.legal_name;
  }

  getRegisteredAddressHistory = () => {
    return this.props.companyHistory.registered_address;
  }

  getContractStartDateHistory = () => {
    return this.props.companyHistory.contract_start_date;
  }

  getTradingAsHistory = () => {
    return this.props.companyHistory.trading_as;
  }

  getSortCodeHistory = () => {
    return this.props.companyHistory.sort_code?.map(revision => ({
      ...revision,
      value: formatSortCode(revision.value),
    }));
  }

  getAccountNumberHistory = () => {
    return this.props.companyHistory.account_number;
  }

  getAddress = (address) => {
    const addressValue = _.values(_.omitBy(address, _.isNil)).map((line, index) => (
      <span key={index}>
        {line}<br />
      </span>
    ));

    return (
      <div>
        {addressValue}
      </div>
    );
  }

  isFieldDisabled = () => {
    return false;
  }

}
