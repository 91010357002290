import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_PROSPECT_ONBOARDING_TASK_TRACKING_ENTRY = gql`
mutation($input: AddProspectOnboardingTaskTrackingEntryInput!) {
  addProspectOnboardingTaskTrackingEntry(input: $input) {
    id
    field_name
    type
    entry
    tracked_at
  }
}
`;

export const addProspectTaskTrackingEntry = ({
  taskId,
  fieldName,
  type,
  name,
  trackedAt,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_PROSPECT_TASK_TRACKING_ENTRY_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: ADD_PROSPECT_ONBOARDING_TASK_TRACKING_ENTRY,
      variables: {
        input: {
          prospect_onboarding_task_id: taskId,
          field_name: fieldName,
          type,
          entry: name,
          tracked_at: trackedAt,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.ADD_PROSPECT_TASK_TRACKING_ENTRY_SUCCESS,
      payload: {
        taskId,
        trackingEntry: response.data.addProspectOnboardingTaskTrackingEntry,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.ADD_PROSPECT_TASK_TRACKING_ENTRY_FAILURE,
      payload: {
        error,
      },
    });
  }
};
