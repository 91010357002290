import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_HOLIDAY_PAY_INFO = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectHolidayPayInput!
) {
  updateProspectHolidayPayInfo(prospect_id: $prospect_id, input: $input) {
    has_holiday_pot
    end_of_holiday_year_pay_period
    end_of_holiday_year_tax_year_end
    can_carry_over_accrual
    default_holiday_scheme {
      id
      value
    }
    holiday_accrual_period {
      id
      value
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {string} obj.data.has_holiday_pot
 * @param {string} obj.data.end_of_holiday_year_pay_period
 * @param {string} obj.data.end_of_holiday_year_tax_year_end
 * @param {string} obj.data.can_carry_over_accrual
 * @param {number} obj.data.default_holiday_scheme_id
 * @param {number} obj.data.holiday_accrual_period_id
 * @param {string} obj.redirectUrl
 *
 */
export const updateHolidayPayInfo = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_HOLIDAY_PAY_INFO_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_HOLIDAY_PAY_INFO,
      variables: {
        prospect_id: prospectId,
        input: {
          has_holiday_pot: data.has_holiday_pot,
          end_of_holiday_year_pay_period: data.end_of_holiday_year_pay_period,
          end_of_holiday_year_tax_year_end: data.end_of_holiday_year_tax_year_end,
          can_carry_over_accrual: data.can_carry_over_accrual,
          default_holiday_scheme_id: data.default_holiday_scheme_id,
          holiday_accrual_period_id: data.holiday_accrual_period_id,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_HOLIDAY_PAY_INFO_SUCCESS,
      payload: {
        data: response.data.updateProspectHolidayPayInfo,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_HOLIDAY_PAY_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
