import { push } from "connected-react-router";
import * as types from "./types";
import * as GQL_TYPES from "./gqltypes";
import { apolloClient } from "../../../apolloClient";

export const gotoUserGroups = () => (dispatch) => {
  dispatch(push("/manage/groups"));
};

export const gotoEditUserGroup = userGroupId => (dispatch) => {
  dispatch(push(`/manage/groups/edit/${userGroupId}`));
};

export const gotoNewUserGroup = () => (dispatch) => {
  dispatch(push("/manage/groups/new"));
};

export const gotoEditUser = userId => (dispatch) => {
  dispatch(push(`/manage/users/edit/${userId}`));
};

export const getUserGroups = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_USER_GROUPS_PENDING });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_USER_GROUPS,
    });

    dispatch({
      type: types.GET_USER_GROUPS_SUCCESS,
      payload: {
        userGroups: response.data.getUserGroups,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_USER_GROUPS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const getUserGroup = userGroupId => async (dispatch) => {
  try {
    dispatch({ type: types.GET_USER_GROUP_PENDING });

    const response = await apolloClient.query({
      query: GQL_TYPES.GET_USER_GROUP,
      variables: {
        id: userGroupId,
      },
    });
    const userGroup = response.data.getUserGroup;

    if (!userGroup) {
      return dispatch(push("/manage/groups"));
    }

    dispatch({
      type: types.GET_USER_GROUP_SUCCESS,
      payload: {
        userGroup,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_USER_GROUP_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const addUserGroup = ({ name, description, permissions }) => async (dispatch) => {
  try {
    dispatch({ type: types.ADD_USER_GROUP_PENDING });

    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.ADD_USER_GROUP,
      variables: {
        input: {
          name,
          description,
          permissions,
        },
      },
    });

    dispatch({
      type: types.ADD_USER_GROUP_SUCCESS,
      payload: {
        userGroup: response.data.addUserGroup,
      },
    });
    dispatch(push("/manage/groups"));
  }
  catch (err) {
    dispatch({
      type: types.ADD_USER_GROUP_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const updateUserGroup = ({ userGroupId, name, description, permissions }) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_USER_GROUP_PENDING });

    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.UPDATE_USER_GROUP,
      variables: {
        id: userGroupId,
        input: {
          name,
          description,
          permissions,
        },
      },
    });

    dispatch({
      type: types.UPDATE_USER_GROUP_SUCCESS,
      payload: {
        userGroup: response.data.updateUserGroup,
      },
    });
    dispatch(push("/manage/groups"));
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_USER_GROUP_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};

export const deleteUserGroup = ({ userGroupId }) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_USER_GROUP_PENDING });

    const response = await apolloClient.mutate({
      mutation: GQL_TYPES.DELETE_USER_GROUP,
      variables: {
        id: userGroupId,
      },
    });

    dispatch({
      type: types.DELETE_USER_GROUP_SUCCESS,
      payload: {
        userGroup: response.data.deleteUserGroup,
      },
    });
    dispatch(push("/manage/groups"));
  }
  catch (err) {
    dispatch({
      type: types.DELETE_USER_GROUP_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
