import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import { actions } from "../../duck";
import { CreditActivityList } from "../../components";
import styles from "./CreditActivityTab.module.scss";

export class CreditActivityTab extends Component {

  static propTypes = {
    legalEntityId: PropTypes.string.isRequired,
    creditActivity: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.array.isRequired,
    }).isRequired,
    getCreditActivity: PropTypes.func.isRequired,
    showCancelScheduledCreditSuspensionModal: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { legalEntityId, getCreditActivity } = this.props;

    await getCreditActivity(legalEntityId);
  }

  render() {
    const { creditActivity, showCancelScheduledCreditSuspensionModal } = this.props;

    return (
      <Grid className={styles.container}>
        <Grid.Row>
          <Grid.Column textAlign="left">
            <Header>Credit Activity</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {creditActivity.loading
              ? <ActivityIndicator />
              : <CreditActivityList
                loading={creditActivity.loading}
                data={creditActivity.data}
                onCancelScheduledSuspension={showCancelScheduledCreditSuspensionModal}
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

}

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditActivityTab);
