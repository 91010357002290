import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_CONTRACT_INFORMATION = gql`
query(
  $prospect_id: ID!
  $disabled_field_filter_options: ProspectDisabledFieldsFilterOptions!
) {
  getProspect(id: $prospect_id) {
    introduced_via_broker
    is_ledger_buyout
    is_new_agency

    contract {
      start_date
      contract_length
      contract_period
      date_first_traded
      fee
      funding_type
    }

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      prospect_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getContractInformation = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CONTRACT_INFORMATION_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_CONTRACT_INFORMATION,
      variables: {
        prospect_id: prospectId,
        disabled_field_filter_options: {
          field_names: [
            "contract_start_date",
            "length_of_contract",
            "date_first_traded",
            "fee",
            "funding_type",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CONTRACT_INFORMATION_SUCCESS,
      payload: {
        data: response.data.getProspect,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CONTRACT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
