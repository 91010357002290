import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_VAT_INFORMATION = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectVatInformationInput!
) {
  updateProspectVatInformation(prospect_id: $prospect_id, input: $input) {
    vat_number
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {bool} obj.data.applying_registered_vat
 * @param {string} obj.data.vat_number
 * @param {string} obj.data.vat_frequency
 * @param {string} obj.data.vat_period_ending_date
 * @param {string} obj.redirectUrl
 *
 */
export const updateVatInformation = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_VAT_INFORMATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_VAT_INFORMATION,
      variables: {
        prospect_id: prospectId,
        input: {
          ...data,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_VAT_INFORMATION_SUCCESS,
      payload: {
        prospect: {
          vat: response.data.updateProspectVatInformation,
        },
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_VAT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
