import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import ContactsTab from "./ContactsTab";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  onFetchProspectDisabledFields: actions.getProspectDisabledFields,
  onFetchBackOfficeContacts: actions.getBackOfficeContacts,
  onFetchProspectContacts: actions.getProspectContacts,
  onFetchBusinessDevelopmentManagers: actions.getBusinessDevelopmentManagers,
  onAddProspectContact: actions.addProspectContact,
  onUpdateProspectContact: actions.updateProspectContact,
  onRemoveProspectContact: actions.deleteProspectContact,
  onArchiveProspectContact: actions.archiveProspectContact,
  onSaveBackOfficeContacts: actions.updateProspectBackOfficeContacts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactsTab);
