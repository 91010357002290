import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_HOLIDAY_PAY_INFO = gql`
query(
  $agency_id: ID!
  $disabled_field_filter_options: AgencyDisabledFieldsFilterOptions!
) {
  getAgency(id: $agency_id) {
    holiday_pay_info {
      has_holiday_pot
      end_of_holiday_year_pay_period
      end_of_holiday_year_tax_year_end
      can_carry_over_accrual
      default_holiday_scheme {
        id
        value
      }
      holiday_accrual_period {
        id
        value
      }
    }

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      agency_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 */
export const getHolidayPayInfo = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_HOLIDAY_PAY_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_HOLIDAY_PAY_INFO,
      variables: {
        agency_id: agencyId,
        disabled_field_filter_options: {
          field_names: [
            "wtr_holiday.has_holiday_pot",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_HOLIDAY_PAY_INFO_SUCCESS,
      payload: {
        data: {
          ...response.data.getAgency.holiday_pay_info,
          disabled_fields: response.data.getAgency.disabled_fields,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_HOLIDAY_PAY_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
