import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import styles from "./ItemCountSelector.module.scss";
import config from "../../../../config";

const { ITEMS_PER_PAGE, DEFAULT_ITEMS_PER_PAGE } = config;

export default class ItemCountSelector extends PureComponent {

  static propTypes = {
    allowedValues: PropTypes.arrayOf(PropTypes.number),
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allowedValues: ITEMS_PER_PAGE,
    name: "ItemCountSelector",
    value: DEFAULT_ITEMS_PER_PAGE,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.allowedValues.includes(props.value)
        ? props.value
        : props.allowedValues[0],
    };
  }

  render() {
    const { allowedValues } = this.props;
    const { selectedValue } = this.state;

    const options = allowedValues.map((v, i) => ({
      key: i,
      text: String(v),
      value: v,
    }));

    return (
      <div className={styles.container}>
        <Dropdown
          className={styles.dropdown}
          name={this.props.name}
          options={options}
          value={selectedValue}
          onChange={this.handleChange}
          selection
        />
        <div>per page</div>
      </div>
    );
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      selectedValue: value,
    });
    this.props.onChange({
      name,
      value,
    });
  }

}
