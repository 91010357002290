import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Input.module.scss";

export default class Input extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.object,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    type: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    iconPosition: "left",
  }

  render() {
    const { className, icon, iconPosition, type, onClick, disabled, readOnly, ...otherProps } = this.props;
    const input = type === "textarea"
      ? (
        <textarea
          className={classnames(styles.input, styles[type], icon && styles.icon, className)}
          type={type || "text"}
          disabled={disabled || readOnly}
          {...otherProps}
        />
      ) : (
        <input
          className={classnames(styles.input, styles[type], icon && styles.icon, className)}
          type={type || "text"}
          disabled={disabled || readOnly}
          autoComplete="off"
          {...otherProps}
        />
      );

    return (
      <div
        className={classnames(
          styles.container,
          disabled && styles.disabled,
          readOnly && styles.readOnly,
          icon && iconPosition === "left" ? styles.iconLeft : styles.iconRight,
          styles[`${type}Container`],
          className
        )}
        onClick={onClick}
        disabled={disabled}
        readOnly={readOnly}
      >
        {icon && icon}
        {input}
      </div>
    );
  }

}
