import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Checkbox, Table } from "..";
import "./ItemList.scss";
import { AddCircleOutline, Archive } from "@material-ui/icons";
import IconButton from "../IconButton";

export default class ItemList extends Component {

  static defaultProps = {
    showCheckBoxes: true,
  }

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string,
      style: PropTypes.object,
      name: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]).isRequired,
      value: PropTypes.func.isRequired,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    onNewClicked: PropTypes.func,
    onArchiveClicked: PropTypes.func,
    newButtonLabel: PropTypes.string,
    onItemClicked: PropTypes.func,
    showCheckBoxes: PropTypes.bool,
  }

  state = {
    selectAll: false,
    selectedIndices: [],
  }

  handleNewClicked = () => {
    this.props.onNewClicked();
  }

  handleArchiveClicked = () => {
    const { data } = this.props;
    const { selectedIndices } = this.state;
    const itemsToArchive = (data || []).filter((_, i) => selectedIndices.includes(i));

    this.props.onArchiveClicked(itemsToArchive);
  }

  handleSelectAllClicked = () => {
    const { data } = this.props;
    const { selectAll } = this.state;

    this.setState({
      selectAll: !selectAll,
      selectedIndices: !selectAll
        ? [...Array((data || []).length).keys()]
        : [],
    });
  }

  handleItemChecked = (index) => {
    const { data } = this.props;
    const { selectedIndices } = this.state;
    const found = selectedIndices.indexOf(index);

    if (found >= 0) {
      selectedIndices.splice(found, 1);
    } else {
      selectedIndices.push(index);
    }

    this.setState({
      selectAll: selectedIndices.length === (data || []).length,
      selectedIndices,
    });
  }

  renderData = () => {
    const { columns, data, onItemClicked, showCheckBoxes } = this.props;
    const { loading } = this.props;
    const { selectAll, selectedIndices } = this.state;

    if (loading) {
      return (
        <div className={classnames("ItemList", loading && "loading")}>
          Loading...
        </div>
      );
    }

    if ((data || []).length === 0) {
      return (
        <div className={classnames("ItemList", "empty")}>
          No Data
        </div>
      );
    }

    const cols = [
      ...(columns || []),
    ];
    if (showCheckBoxes) {
      cols.unshift({
        className: "collapsed",
        name: <Checkbox checked={selectAll} onClick={this.handleSelectAllClicked} />,
        value: (_, i) => <Checkbox checked={selectedIndices.includes(i)} onClick={this.handleItemChecked.bind(this, i)} />,
      });
    }

    return (
      <Table
        columns={cols}
        data={data}
        onRowClicked={onItemClicked}
      />
    );
  }

  render() {
    const { loading, newButtonLabel } = this.props;
    const { selectedIndices } = this.state;
    const canArchive = selectedIndices.length > 0;

    return (
      <div className="ItemList">
        <div className="buttons">
          {this.props.onNewClicked &&
            <IconButton icon={(<AddCircleOutline />)} onClick={this.handleNewClicked} disabled={loading} text={newButtonLabel || "New"} />
          }
          {this.props.onArchiveClicked &&
            <IconButton icon={(<Archive />)} onClick={this.handleArchiveClicked} disabled={loading || !canArchive} text="Archive"/>
          }
        </div>
        {this.renderData()}
      </div>
    );
  }

}
