import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./CollapseContainer.module.scss";

export default class CollapseContainer extends Component {

  static propTypes = {
    collapsed: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  }

  state = { height: 0 };

  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
  }

  componentDidMount() {
    const height = this.parentRef.clientHeight;
    this.setState({ height });
    if (this.props.collapsed) {
      this.collapseSection();
    }
    else {
      this.expandSection();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collapsed !== this.props.collapsed) {
      if (prevProps.collapsed) {
        this.expandSection();
      }
      else {
        this.collapseSection();
      }
    }
  }

  collapseSection = () => {
    const element = this.parentRef;
    const { height } = this.state;
    const elementTransition = element.style.transition;
    element.style.transition = "";
    requestAnimationFrame(() => {
      element.style.height = height + "px";
      element.style.transition = elementTransition;
      requestAnimationFrame(() => {
        element.style.height = 0 + "px";
      });
    });
  }

  expandSection = () => {
    const element = this.parentRef;
    var sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + "px";

    function removeListener() {
      element.removeEventListener("transitionend", removeListener);
      element.style.height = null;
    }
    element.addEventListener("transitionend", removeListener);
  }

  render() {
    return (
      <div ref={ref => this.parentRef = ref} className={styles.collapseContainer}>
        {this.props.children}
      </div>
    );
  }
}
