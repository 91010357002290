import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class TableColumn extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  render() {
    const { className, style, children } = this.props;

    return (
      <div
        className={classnames("TableColumn", className)}
        style={style}
      >
        {children}
      </div>
    );
  }

}
