import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_PAYROLL_GENERAL = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectWorkerTypesInput!
) {
  updateProspectWorkerTypes(prospect_id: $prospect_id, input: $input) {
    data {
      id
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {Array.<number>} obj.data.workerTypes
 * @param {string} obj.redirectUrl
 *
 */
export const updatePayrollGeneral = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_PAYROLL_GENERAL_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_PAYROLL_GENERAL,
      variables: {
        prospect_id: prospectId,
        input: {
          worker_types: data.workerTypes,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_PAYROLL_GENERAL_SUCCESS,
      payload: {
        workerTypes: response.data.updateProspectWorkerTypes.data,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_PAYROLL_GENERAL_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
