import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, Form } from "../../../common/components";
import styles from "./SuspendModal.module.scss";

export default function SuspendModal(props) {
  const [formData, setFormData] = useState({
    dateOfEffect: new Date().getTime(),
  });

  const canSubmit = () => Boolean(formData.dateOfEffect);

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleDateChange = (e) => {
    // It is useful to capture the time for sorting purposes in case of same-day events
    const date = new Date(e.target.value);
    const now = new Date();
    date.setUTCHours(now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

    setFormData({ ...formData, [e.target.name]: date.getTime() });
  };

  const handleSubmit = () => props.onSubmit(formData);

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <ConfirmationModal
      title="Suspend Legal Entity"
      submitText="Suspend"
      busy={props.submitting}
      className={styles.modal}
      submitDisabled={!canSubmit()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      visible
    >
      <div>
        Please enter the date that this Legal Entity should be suspended from.
      </div>
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline >
            <Form.Label className={styles.label} inline required>
              Date of suspension
            </Form.Label>
            <Form.Date
              name="dateOfEffect"
              value={formData.dateOfEffect}
              size="small"
              disabled={props.submitting}
              onChange={handleDateChange}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Label className={styles.commentsLabel} inline>
              Comments
            </Form.Label>
            <Form.TextArea
              name="comment"
              className={styles.textArea}
              value={formData.comment}
              placeholder="Enter comments..."
              rows={6}
              maxLength={255}
              disabled={props.submitting}
              onChange={handleFormChange}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

SuspendModal.displayName = "SuspendModal";

SuspendModal.propTypes = {
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
