import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import { Info } from "@material-ui/icons";
import styles from "./SubtaskLabel.module.scss";

export default function SubtaskLabel({
  className,
  name,
  required,
  dueDate,
  tooltip,
}) {
  let variant = "";
  let dueDateTooltip = "";

  if (dueDate) {
    const now = moment();
    const due = moment(dueDate);
    const days = due.diff(now, "days");

    if (days > 1) {
      variant = "due";
      dueDateTooltip = `Due in ${days} days`;
    }
    else if (days === 1) {
      variant = "due";
      dueDateTooltip = "Due in 1 day";
    }
    else if (days === 0) {
      variant = "overdue";
      dueDateTooltip = "Due today";
    }
    else if (days === -1) {
      variant = "overdue";
      dueDateTooltip = "Overdue by 1 day";
    }
    else {
      variant = "overdue";
      dueDateTooltip = `Overdue by ${-days} days`;
    }
  }

  return (
    <div className={classnames(styles.container, className)}>
      <Popup
        trigger={(
          <div className={styles[variant]}>
            <span dangerouslySetInnerHTML={{ __html: name }} />
            {required && <span className={styles.required}>*</span>}
          </div>
        )}
        position="bottom center"
        disabled={!dueDateTooltip}
      >
        <Popup.Content>
          {dueDateTooltip}
        </Popup.Content>
      </Popup>
      {tooltip && (
        <Popup
          trigger={(
            <Info className={styles.tooltip} />
          )}
          offset={[-10, 0]}
        >
          <Popup.Content>
            {tooltip}
          </Popup.Content>
        </Popup>
      )}
    </div>
  );
}

SubtaskLabel.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  dueDate: PropTypes.number,
  tooltip: PropTypes.string,
};
