import { DownloadExperianDocumentsModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.experianCreditCheckId
 * @returns
 */
export const showDownloadExperianDocumentsModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(DownloadExperianDocumentsModal.displayName, {
    legalEntityId: obj.legalEntityId,
    experianCreditCheckId: obj.experianCreditCheckId,
  }));
};
