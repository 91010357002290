import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { HelpOutline } from "@material-ui/icons";
import { IconButton, ConfirmationModal, Form } from "../../../common/components";
import { isAlphaNumeric } from "utils/string";
import styles from "./VerifyNonRegisteredAgencyCustomerModal.module.scss";

const INITIAL_STATE = {
  legal_name: null,
  correspondence_name: null,
  sole_trader_name: null,
  address_line_1: null,
  address_line_2: null,
  town_or_city: null,
  postcode: null,
  verified_by: null,
  isCharityNumberCorrect: null,
  correctCharityNumber: null,
};

const VERIFIED_BY_OPTIONS = [
  { value: "experian", label: "Experian" },
  { value: "insurer", label: "Insurer" },
  { value: "other", label: "Other" },
];

export default function VerifyNonRegisteredAgencyCustomerModal(props) {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const { getAgencyCustomer, agencyCustomer, agencyCustomerId, loading } = props;

  useEffect(() => {
    // In the initial addition of this modal this won't run because
    // the page will fetch the customer.
    if (!loading && agencyCustomer?.id !== agencyCustomerId) {
      getAgencyCustomer({
        agencyCustomerId: agencyCustomerId,
      });
    }
  }, [getAgencyCustomer, agencyCustomer, agencyCustomerId, loading]);

  useEffect(() => {
    setFormData({
      ...INITIAL_STATE,
      ...agencyCustomer,
    });
  }, [agencyCustomer]);

  const canSubmit = () => {
    if (!formData.legal_name) return false;
    if (!formData.correspondence_name) return false;
    if (!formData.address_line_1) return false;
    if (!formData.town_or_city) return false;
    if (!formData.postcode) return false;
    if (!formData.verified_by) return false;

    if (agencyCustomer.company_type === "sole_trader") {
      if (!formData.sole_trader_name) {
        return false;
      }
    }

    if (formData.is_registered_charity) {
      if (formData.isCharityNumberCorrect === null) {
        return false;
      }
      if (formData.isCharityNumberCorrect === false && !formData.correctCharityNumber) {
        return false;
      }
    }

    return true;
  };

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handlePostcodeChange = (e) => {
    if (!isAlphaNumeric(e.target.value, true)) {
      return;
    }

    setFormData({
      ...formData,
      postcode: e.target.value?.toUpperCase(),
    });
  };

  const handleSubmit = async () => {
    await props.verifyNonRegisteredAgencyCustomer({
      ...formData,
      agencyCustomerId,
      registered_charity_number: formData.correctCharityNumber ?? formData.registered_charity_number,
    });

    props.hideModal(VerifyNonRegisteredAgencyCustomerModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(VerifyNonRegisteredAgencyCustomerModal.displayName);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title={
        <>
          Verify Legal Entity for {agencyCustomer.agency?.known_as ?? agencyCustomer.prospect?.known_as}
          {!agencyCustomer.agency && agencyCustomer.prospect && (
            <span className={styles.prospectTag}> Prospect</span>
          )}
        </>
      }
      submitText="Verify"
      cancelText="Cancel"
      submitDisabled={props.loading || !canSubmit()}
      busy={props.submitting}
      visible={props.visible}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      scrolling
    >
      <div className={styles.line}>
        Please verify that the <strong>Non-registered Legal Entity</strong> is correct.
      </div>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Form requiredKey>
            <Form.Group inline className={styles.group}>
              <Form.Label inline required>
                Customer (Exact Legal Name)
              </Form.Label>
              <Form.Input
                name="legal_name"
                placeholder="Enter Customer (Exact Legal Name)..."
                value={formData.legal_name}
                maxLength={255}
                disabled={props.submitting}
                onChange={handleFormChange}
                fluid
              />
            </Form.Group>

            <Form.Group inline className={styles.group}>
              <Form.Label inline required>
                Exact Legal Name (Payroll Version)
              </Form.Label>
              <div className={styles.tooltipInputContainer}>
                <Form.Input
                  className={styles.tooltipInput}
                  name="correspondence_name"
                  placeholder="Enter Exact Legal Name (Payroll Version)"
                  value={formData.correspondence_name}
                  maxLength={160}
                  disabled={props.submitting}
                  onChange={handleFormChange}
                  fluid
                />
                <IconButton
                  className={styles.tooltip}
                  icon={<HelpOutline />}
                  tooltip="Usually the exact legal name but case corrected in line with how the legal entity formats their name. Long company names can also be reduced in length to fit with the Payroll System requirements of maximum 60 characters."
                />
              </div>
            </Form.Group>

            {agencyCustomer.company_type === "sole_trader" && (
              <Form.Group inline className={styles.group}>
                <Form.Label inline required>
                Sole Trader Name
                </Form.Label>
                <Form.Input
                  name="sole_trader_name"
                  placeholder="Enter Sole Trader Name..."
                  value={formData.sole_trader_name}
                  maxLength={255}
                  disabled={props.submitting}
                  onChange={handleFormChange}
                  fluid
                />
              </Form.Group>
            )}

            <Form.Group inline className={styles.group}>
              <Form.Label inline required>
                Address Line 1
              </Form.Label>
              <Form.Input
                name="address_line_1"
                placeholder="Enter Address Line 1..."
                value={formData.address_line_1}
                maxLength={100}
                disabled={props.submitting}
                onChange={handleFormChange}
                fluid
              />
            </Form.Group>

            <Form.Group inline className={styles.group}>
              <Form.Label inline>
                Address Line 2
              </Form.Label>
              <Form.Input
                name="address_line_2"
                placeholder="Enter Address Line 2..."
                value={formData.address_line_2}
                maxLength={100}
                disabled={props.submitting}
                onChange={handleFormChange}
                fluid
              />
            </Form.Group>

            <Form.Group inline className={styles.group}>
              <Form.Label inline required>
                Town/City
              </Form.Label>
              <Form.Input
                name="town_or_city"
                placeholder="Enter Town or City..."
                value={formData.town_or_city}
                maxLength={100}
                disabled={props.submitting}
                onChange={handleFormChange}
                fluid
              />
            </Form.Group>

            <Form.Group inline className={styles.group}>
              <Form.Label inline>
                County
              </Form.Label>
              <Form.Input
                name="county"
                placeholder="Enter County..."
                value={formData.county}
                maxLength={100}
                disabled={props.submitting}
                onChange={handleFormChange}
                fluid
              />
            </Form.Group>

            <Form.Group inline className={styles.group}>
              <Form.Label inline required>
                Postcode
              </Form.Label>
              <Form.Input
                name="postcode"
                placeholder="Enter Postcode..."
                value={formData.postcode}
                maxLength={8}
                disabled={props.submitting}
                onChange={handlePostcodeChange}
                fluid
              />
            </Form.Group>

            <Form.Group inline className={styles.group}>
              <Form.Label inline required>
                Is the company a charity?
              </Form.Label>
              <div>
                {agencyCustomer.is_registered_charity ? "Yes" : "No"}
              </div>
            </Form.Group>

            {agencyCustomer.is_registered_charity && (
              <>
                <Form.Group inline className={styles.group}>
                  <Form.Label inline>
                    Charity Number
                  </Form.Label>
                  <div>
                    {agencyCustomer.registered_charity_number}
                  </div>
                </Form.Group>

                <Form.Group inline className={styles.group}>
                  <Form.Label inline required>
                    Is the Charity Number correct?
                  </Form.Label>
                  <Form.RadioContainer>
                    <Form.Radio
                      value={true}
                      name="isCharityNumberCorrect"
                      checked={formData.isCharityNumberCorrect === true}
                      text="Yes"
                      disabled={props.submitting}
                      onChange={() =>
                        setFormData({ ...formData, isCharityNumberCorrect: true })
                      }
                    />
                    <Form.Radio
                      value={false}
                      name="isCharityNumberCorrect"
                      checked={formData.isCharityNumberCorrect === false}
                      text="No"
                      disabled={props.submitting}
                      onChange={() =>
                        setFormData({ ...formData, isCharityNumberCorrect: false, correctCharityNumber: null })
                      }
                    />
                  </Form.RadioContainer>
                </Form.Group>

                {formData.isCharityNumberCorrect === false && (
                  <Form.Group inline className={styles.group}>
                    <Form.Label inline required>
                      Correct Charity Number
                    </Form.Label>
                    <Form.Input
                      name="correctCharityNumber"
                      placeholder="Enter Correct Charity Number..."
                      value={formData.correctCharityNumber}
                      maxLength={255}
                      disabled={props.submitting}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          correctCharityNumber: e.target.value,
                        })
                      }
                      fluid
                    />
                  </Form.Group>
                )}
              </>
            )}

            <Form.Group inline>
              <Form.Label className={styles.label} required>
              Verified by
              </Form.Label>
              <Form.Select
                name="verified_by"
                placeholder="Select Verified by..."
                size="large"
                value={formData.verified_by}
                className={styles.list}
                options={VERIFIED_BY_OPTIONS}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </>
      )}
      <br/>
    </ConfirmationModal>
  );
}

VerifyNonRegisteredAgencyCustomerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  agencyCustomerId: PropTypes.string.isRequired,
  agencyCustomer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    agency: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }),
    prospect: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }),
    display_name: PropTypes.string.isRequired,
    company_type: PropTypes.string.isRequired,
    legal_name: PropTypes.string.isRequired,
    registered_company_number: PropTypes.string.isRequired,
    is_registered_charity: PropTypes.string.isRequired,
    registered_charity_number: PropTypes.string,
  }),
  getAgencyCustomer: PropTypes.func.isRequired,
  verifyNonRegisteredAgencyCustomer: PropTypes.func.isRequired,
};

VerifyNonRegisteredAgencyCustomerModal.displayName = "VerifyNonRegisteredAgencyCustomerModal";
