import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_CREDIT_OVERVIEW = gql`
query($legal_entity_id: ID!) {
  getLegalEntity(id: $legal_entity_id) {
    credit_limit_excluding_vat
    credit_limit_including_vat
    undistributed_credit_limit
    active_suspension {
      date_of_effect
    }
    scheduled_suspension {
      id
      type
      date_of_effect
    }

    latest_experian_report{
      result_excluding_vat
      date_of_result
      status
    }

    latest_insurer_report {
      type
      result_excluding_vat
      date_of_result
      status
    }

    latest_credit_review_date {
      next_review_date
      comment
      created_by {
        first_name
        last_name
      }
      created_at
    }

    ...on RegisteredCompany {
      experian_credit_checks(
        pager: { page: 1, limit: 1 }
        sort_order: [{ field: "created_at", order: desc}]
      ) {
        id
        experian_credit_result
        approved_credit
        created_at
      }
    }

    credit_applications(
      filter_options: { status: "accepted" }
      pager: { page: 1, limit: 1 }
      sort_order: [{ field: "created_at", order: desc}]
    ) {
      id
      approved_credit_excluding_vat
      created_at
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getCreditOverview = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CREDIT_OVERVIEW_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_CREDIT_OVERVIEW,
      variables: {
        legal_entity_id: obj.legalEntityId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CREDIT_OVERVIEW_SUCCESS,
      payload: {
        data: response.data.getLegalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_OVERVIEW_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
