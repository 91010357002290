import { EditCustomerModal } from "../../modals";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 *
 * @returns
 */
export const showEditCustomerModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(EditCustomerModal.displayName, {
    agencyCustomerId: obj.agencyCustomerId,
  }));
};
