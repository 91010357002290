import { push } from "connected-react-router";
import {
  showExperianCreditCheckForAgencyModal,
  verifyAgencyCustomer,
} from "./";
import { VerifyAgencyCustomerModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.agencyCustomerRequestId
 * @param {string} obj.agencyId
 * @param {string} obj.agencyName
 * @param {boolean} obj.isProspect
 * @param {number} obj.creditLimitRequested
 * @param {object} obj.enteredByUser
 * @param {string} obj.enteredByUser.registeredCompanyNumber
 * @param {string} obj.enteredByUser.exactLegalName
 * @param {object} obj.selectedFromCompaniesHouse
 * @param {string} obj.selectedFromCompaniesHouse.registeredCompanyNumber
 * @param {string} obj.selectedFromCompaniesHouse.exactLegalName
 * @param {boolean} obj.hasBeenCreditChecked
 *
 * @returns
 */
export const showVerifyAgencyCustomerModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(VerifyAgencyCustomerModal.displayName, {
    data: {
      agencyName: obj.agencyName,
      isProspect: obj.isProspect,
      enteredByUser: {
        registeredCompanyNumber: obj.enteredByUser.registeredCompanyNumber,
        exactLegalName: obj.enteredByUser.exactLegalName,
      },
      selectedFromCompaniesHouse: {
        registeredCompanyNumber: obj.selectedFromCompaniesHouse.registeredCompanyNumber,
        exactLegalName: obj.selectedFromCompaniesHouse.exactLegalName,
      },
      hasBeenCreditChecked: obj.hasBeenCreditChecked,
    },
    onChangeCompaniesHouseSelectionClick: () => {
      dispatch(push("/credit-limit-management/registered-company/new", {
        data: {
          registeredCompanyNumber: obj.enteredByUser.registeredCompanyNumber,
          exactLegalName: obj.enteredByUser.exactLegalName,
          agency: {
            agencyId: obj.agencyId,
            agencyName: obj.agencyName,
            prospect: obj.isProspect,
          },
          agencyCustomerRequestId: obj.agencyCustomerRequestId,
          estimatedCreditLimit: obj.creditLimitRequested,
        },
      }));
    },
    onSubmit: (data) => {
      // If we already have an Experian credit check then we don't
      // need to do one again
      if (obj.hasBeenCreditChecked) {
        dispatch(verifyAgencyCustomer({
          legalEntityId: obj.legalEntityId,
          agencyCustomerRequestId: obj.agencyCustomerRequestId,
          verifyMismatchReasonId: data.mismatchReasonId,
          verifyMismatchReasonDetails: data.mismatchReasonDetails,
        }));
      }
      else {
        dispatch(showExperianCreditCheckForAgencyModal({
          legalEntityId: obj.legalEntityId,
          agencyCustomerRequestId: obj.agencyCustomerRequestId,
          verifyMismatchReasonId: data.mismatchReasonId,
          verifyMismatchReasonDetails: data.mismatchReasonDetails,
        }));
      }

      dispatch(modalActions.hideModal(VerifyAgencyCustomerModal.displayName));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(VerifyAgencyCustomerModal.displayName));
    },
  }));
};
