import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const GET_CREDIT_APPLICATIONS = gql`
query($legal_entity_id: ID!) {
  getLegalEntityCreditApplications(
    legal_entity_id: $legal_entity_id
    pager: {
      page: 1
      limit: 100
    }
    include_completed: false
  ) {
    data {
      id
      created_at
      date_of_request
      credit_limit_requested
      approved_credit_excluding_vat
      use_discretionary_limit
      status
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legal_entity_id
 * @returns
 */
export const getActiveCreditApplications = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ACTIVE_CREDIT_APPLICATIONS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_CREDIT_APPLICATIONS,
      variables: {
        legal_entity_id: obj.legalEntityId,
      },
    });

    if (response.errors) {
      throw Error(response.errors[0].message);
    }

    dispatch({
      type: types.GET_ACTIVE_CREDIT_APPLICATIONS_SUCCESS,
      payload: {
        data: response.data.getLegalEntityCreditApplications.data,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_ACTIVE_CREDIT_APPLICATIONS_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
