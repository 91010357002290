import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_TRANSFER_INFORMATION = gql`
query($id: ID!) {
  getAgency(id:$id) {
    id

    preferences {
      payroll_frequency
    }

    tempest_mappings {
      tempest_id
      payroll_frequency
    }

    transfers {
      status
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @returns
 */
export const getAgencyTransferInformation = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_TRANSFER_INFORMATION_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_TRANSFER_INFORMATION,
      variables: {
        id: obj.agencyId,
      },
    });

    dispatch({
      type: types.GET_AGENCY_TRANSFER_INFORMATION_SUCCESS,
      payload: {
        data: response.data.getAgency,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_TRANSFER_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
