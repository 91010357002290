import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_ONBOARDING_TASK = gql`
query($agency_onboarding_task_id: ID!) {
  getAgencyOnboardingTask(agency_onboarding_task_id: $agency_onboarding_task_id) {
    id
    status
    definition {
      subtasks {
        group
        description
        name
        required
        field
        label
        collapsable
        tracking {
          enabled
          entries {
            id
            field_name
            type
            entry
            tracked_at
            created_at
            updated_at
          }
        }
        depends_on
        due_date
      }
    }
    comments {
      id
      comment
      created_at
      created_by {
        first_name
        last_name
      }
      related_subtasks {
        field_name
        label
      }
    }
    agency {
      id
    }
  }
}
`;

export const getAgencyTask = ({
  agencyId,
  agencyOnboardingTaskId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_TASK_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_ONBOARDING_TASK,
      variables: {
        agency_onboarding_task_id: agencyOnboardingTaskId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const task = response.data.getAgencyOnboardingTask;

    // Make sure we load the onboarding task for the selected agency
    if (task.agency.id !== agencyId) {
      dispatch(push(`/onboarding/agencies/${agencyId}`));
      return;
    }

    const agencyTask = {
      ...task,
      definition: {
        ...task.definition,
        subtasks: task.definition.subtasks.map(subtask => ({
          ...subtask,
          field: JSON.parse(subtask.field),
          label: JSON.parse(subtask.label),
          depends_on: JSON.parse(subtask.depends_on || "[]"),
        })),
      },
    };

    dispatch({
      type: types.GET_AGENCY_TASK_SUCCESS,
      payload: {
        agencyTask,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_AGENCY_TASK_FAILURE,
      payload: {
        error,
      },
    });
  }
};
