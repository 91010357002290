import React, { useState } from "react";
import PropTypes from "prop-types";
import UserFormValidator from "./UserFormValidator";
import { Button, Form } from "../../../common/components";
import { List } from "../../../lists/containers";
import styles from "./UserForm.module.scss";

export default function UserForm(props) {
  const [state, setState] = useState({
    ...props.user,
    userGroups: props.user?.userGroups?.map(x => x.id),
  });
  const [errors, setErrors] = useState({});
  const [userTypes, setUserTypes] = useState([]);

  const getUserTypeValue = (userTypeId) => {
    return userTypes.find(x => x.id === userTypeId)?.value;
  };

  const handleInputChanged = (e) => {
    const newState = { ...state, [e.target.name]: e.target.value };
    setState(newState);
  };

  const handleUserTypeChanged = (e) => {
    const userTypeId = userTypes.find(x => x.value === e.target.value)?.id;
    const newState = { ...state, userTypeId };
    setState(newState);
  };

  const handleSubmit = () => {
    const validator = new UserFormValidator(state);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.onSubmit(state);
  };

  return (
    <div className={styles.container}>
      <Form className={styles.form}>
        <Form.Section
          title="Employee Details"
          rightAccessory={(
            <div className={styles.allFieldsRequired}>
              All fields are required
            </div>
          )}
        >
          <Form.Group inline error={errors?.firstName}>
            <Form.Label inline>First Name</Form.Label>
            <Form.Input
              name="firstName"
              value={state.firstName}
              type="text"
              highlightError={errors?.firstName}
              onChange={handleInputChanged}
              required
              fluid
            />
          </Form.Group>
          <Form.Group inline error={errors?.lastName}>
            <Form.Label inline>Last Name</Form.Label>
            <Form.Input
              name="lastName"
              value={state.lastName}
              type="text"
              highlightError={errors?.lastName}
              onChange={handleInputChanged}
              required
              fluid
            />
          </Form.Group>
          <Form.Group inline error={errors?.email}>
            <Form.Label inline>Email Address</Form.Label>
            <Form.Input
              name="email"
              value={state.email}
              type="email"
              highlightError={errors?.email}
              onChange={handleInputChanged}
              required
              fluid
            />
          </Form.Group>
          <Form.Group inline error={errors?.userTypeId}>
            <Form.Label inline>User Type</Form.Label>
            <Form.Value>
              <List
                identifier="user_types"
                name="userType"
                value={getUserTypeValue(state.userTypeId)}
                highlightError={errors?.userTypeId}
                search
                fluid
                onReady={(list) => {
                  setUserTypes(list.data.values);
                }}
                onChange={handleUserTypeChanged}
              />
            </Form.Value>
          </Form.Group>
          <Form.Group inline error={errors?.departmentId}>
            <Form.Label inline>Select Department</Form.Label>
            <Form.Value>
              <Form.Select
                name="departmentId"
                value={state.departmentId}
                placeholder="Select Department..."
                options={[
                  {
                    label: "No department",
                    value: 0,
                  },
                  ...(props.departments?.map(value => ({
                    label: value.name,
                    value: value.id,
                  })) || []),
                ]}
                highlightError={errors?.departmentId}
                search
                fluid
                onChange={handleInputChanged}
              />
            </Form.Value>
          </Form.Group>
        </Form.Section>
        <Form.Section title="Permissions">
          <Form.Group inline error={errors?.userGroups}>
            <Form.Label inline>Select Permission Groups</Form.Label>
            <Form.Value>
              <Form.Select
                name="userGroups"
                placeholder="Select Permission Groups..."
                value={state.userGroups}
                options={props.userGroups?.map(value => ({
                  label: value.name,
                  value: value.id,
                })) || []}
                highlightError={errors?.userGroups}
                search
                multiple
                fluid
                onChange={handleInputChanged}
              />
            </Form.Value>
          </Form.Group>
        </Form.Section>
      </Form>
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          variant="primary"
          busy={props.submitting}
          disabled={props.submitting}
          onClick={handleSubmit}
        >
          {props.submitText || "Create User"}
        </Button>
        <Button
          className={styles.button}
          variant="outline-danger"
          disabled={props.submitting}
          onClick={() => props.onCancel()}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

UserForm.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    userTypeId: PropTypes.string,
    departmentId: PropTypes.string.isRequired,
    userGroups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  userGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  submitText: PropTypes.string,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  submitText: "Create User",
};
