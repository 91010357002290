import { DateOfNextReviewModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";
import { createCreditReviewDate } from "./";

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {number} obj.dateOfNextReview
 * @returns
 */
export const showDateOfNextReviewModal = obj => (dispatch) => {
  dispatch(modalActions.showModal(DateOfNextReviewModal.displayName, {
    dateOfNextReview: obj.dateOfNextReview,
    onSubmit: (data) => {
      dispatch(createCreditReviewDate({
        legalEntityId: obj.legalEntityId,
        dateOfNextReview: data.dateOfNextReview,
        comment: data.comments,
      }));

      dispatch(modalActions.hideModal(DateOfNextReviewModal.displayName));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(DateOfNextReviewModal.displayName));
    },
  }));
};
