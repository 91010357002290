import * as types from "./types";


//TODO: split these into 2 separate reducers!
const initialState = {
  loading: true,
  saving: false,
  userGroups: [],
  userGroup: {
    isDefault: true,
    id: "",
    name: "My Group",
    description: "",
    permissions: [],
    users: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case types.GET_USER_GROUPS_PENDING:
      return { ...state, loading: true };

    case types.GET_USER_GROUPS_SUCCESS:
      return { ...state, loading: false, userGroups: action.payload.userGroups };

    case types.GET_USER_GROUPS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_USER_GROUP_PENDING:
      return { ...state, loading: true, userGroup: initialState.userGroup };

    case types.GET_USER_GROUP_SUCCESS:
      return { ...state, loading: false, userGroup: action.payload.userGroup };

    case types.GET_USER_GROUP_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.ADD_USER_GROUP_PENDING:
      return { ...state, saving: true };

    case types.ADD_USER_GROUP_SUCCESS:
      return { ...state, saving: false, userGroups: [...(state.userGroups || []), action.payload.userGroup] };

    case types.ADD_USER_GROUP_FAILURE:
      return { ...state, saving: false, error: action.payload.error };

    case types.UPDATE_USER_GROUP_PENDING:
      return { ...state, saving: true };

    case types.UPDATE_USER_GROUP_SUCCESS:
      return { ...state, saving: false, userGroup: action.payload.userGroup };

    case types.UPDATE_USER_GROUP_FAILURE:
      return { ...state, saving: false, error: action.payload.error };

    case types.DELETE_USER_GROUP_PENDING:
      return { ...state, saving: true };

    case types.DELETE_USER_GROUP_SUCCESS:
      return { ...state, saving: false, userGroups: (state.userGroups || []).filter(userGroup => userGroup.id !== action.payload.userGroup.id) };

    case types.DELETE_USER_GROUP_FAILURE:
      return { ...state, saving: false, error: action.payload.error };

    default:
      return state;
  }
}
