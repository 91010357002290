import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { AgencyContractInformationForm } from "../../../components";
import styles from "./AgencyContractInformation.module.scss";

export default class AgencyContractInformation extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }),
    agencyContractInformation: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object.isRequired,
    }).isRequired,
    companyHistory: PropTypes.shape({
      contract_start_date: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    agencyDisabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    getAgencyContractInformation: PropTypes.func.isRequired,
    updateAgencyContractInformation: PropTypes.func.isRequired,
    getCompanyHistory: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    const { agencyId, getCompanyHistory, getAgencyContractInformation, getAgencyDisabledFields } = this.props;

    await Promise.all([
      getAgencyContractInformation(agencyId),
      getCompanyHistory(agencyId),
      getAgencyDisabledFields(
        agencyId,
        [
          "contract_start_date",
          "length_of_contract",
        ]
      ),
    ]);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderAgencyContractInformationSection()}
      </div>
    );
  }

  renderAgencyContractInformationSection = () => {
    const {
      agencyDisabledFields,
      companyHistory,
      agencyContractInformation: {
        data,
        loading,
      },
      ui: {
        submitting,
      },
    } = this.props;

    if (loading) {
      return <ActivityIndicator />;
    }

    return (
      <AgencyContractInformationForm
        agencyContractInformation={data}
        disabledFields={agencyDisabledFields?.data?.map(field => (field.disabled_field))}
        onDisabledFieldClick={this.handleDisabledFieldClicked}
        history={companyHistory}
        onSave={this.handleSaveClicked}
        onCancel={this.handleCancelClicked}
        submitting={submitting}
      />
    );
  }

  handleDisabledFieldClicked = (field) => {
    const { agencyDisabledFields, agencyId, history } = this.props;

    const agencyDisabledField = agencyDisabledFields.data.find(item => item.disabled_field === field);

    history.push(`/onboarding/agencies/${agencyId}/${agencyDisabledField.agency_onboarding_task_id}`);
  }

  handleCancelClicked = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/agency`);
  }

  handleSaveClicked = async (data) => {
    const { agencyId, updateAgencyContractInformation } = this.props;

    const agencyData = {
      contract: {
        start_date: data.contract_start_date,
        contract_length: parseInt(data.length_of_contract.contract_length || 0, 10),
        contract_period: data.length_of_contract.contract_period,
        date_first_traded: data.date_first_traded,
        fee: parseFloat(data.contract_fee),
        funding_type: data.contract_funding_type,
      },
      agency: {
        is_ledger_buyout: data.is_ledger_buyout,
        introduced_via_broker: data.introduced_via_broker,
      },
    };

    await updateAgencyContractInformation(agencyId, agencyData);
  }

  getAgencyContractInformationHistory = () => {
    return {
      contract_start_date: this.props.companyHistory.contract_start_date,
    };
  }
}
