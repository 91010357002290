import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_GLOBAL_DEFAULT = gql`
query($global_default_id: ID!) {
  getGlobalDefault (key: $global_default_id) {
    key
    value
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.globalDefaultId
 * @returns
 */
export const getGlobalDefault = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_GLOBAL_DEFAULT_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_GLOBAL_DEFAULT,
      variables: {
        global_default_id: obj.globalDefaultId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_GLOBAL_DEFAULT_SUCCESS,
      payload: {
        data: response.data.getGlobalDefault,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_GLOBAL_DEFAULT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
