import gql from "graphql-tag";
import { push } from "connected-react-router";
import {
  showInsurerApplicationModal,
  showCommunicateToAgencyModal,
} from "./";
import * as types from "../types";
import {
  ExperianCreditCheckForAgencyModal,
} from "../../components";
import { actions as modalActions } from "../../../modals/duck";
import { apolloClient } from "../../../../apolloClient";

const RESPONSE_FRAGMENT = gql`
fragment coreAddExperianCreditCheckForAgencyResponseFields on AddExperianCreditCheckForAgencyResponse {
  status
  agency_customer {
    id
    allocated_credit
    initial_credit_limit_requested

    agency {
      id
      known_as
    }

    prospect {
      id
      known_as
    }

    legal_entity {
      id
      name
      credit_limit_excluding_vat
      credit_limit_including_vat
      undistributed_credit_limit

      ...on RegisteredCompany {
        experian_credit_checks(
          pager: { page: 1, limit: 1 }
          sort_order: [{ field: "created_at", order: desc}]
        ) {
          id
          date_of_result
          experian_credit_result
          approved_credit
          created_at
        }
      }
    }

    agency_customer_request {
      verified_at
      verified_by {
        first_name
        last_name
      }
      verify_mismatch_reason {
        label
      }
      verify_mismatch_reason_details
    }

    customer_accounts {
      id
      display_name
      allocated_credit
    }
  }

  credit_request {
    id
    agency {
      id
      known_as
    }
    prospect {
      id
      known_as
    }
    credit_requested
    credit_distributed
    created_at
  }
}
`;

const EXPERIAN_CREDIT_CHECK_FRAGMENT = gql`
fragment coreExperianCreditCheckFields on ExperianCreditCheck {
  id
  experian_credit_result
  date_of_result
  approved_credit
  approved_credit_including_vat
}`;

const ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_WITH_DOCUMENT = gql`
${RESPONSE_FRAGMENT}
${EXPERIAN_CREDIT_CHECK_FRAGMENT}
mutation(
  $input: AddExperianCreditCheckForAgencyInput!
  $filename: String!
) {
  addExperianCreditCheckForAgency(input: $input) {
    ...coreAddExperianCreditCheckForAgencyResponseFields

    experian_credit_check {
      ...coreExperianCreditCheckFields
      upload_url(filename: $filename) {
        url
      }
    }
  }
}
`;

const ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_WITHOUT_DOCUMENT = gql`
${RESPONSE_FRAGMENT}
${EXPERIAN_CREDIT_CHECK_FRAGMENT}
mutation($input: AddExperianCreditCheckForAgencyInput!) {
  addExperianCreditCheckForAgency(input: $input) {
    ...coreAddExperianCreditCheckForAgencyResponseFields
    experian_credit_check {
      ...coreExperianCreditCheckFields
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 * @param {Boolean} obj.companyFound
 * @param {number} obj.experianCreditResult
 * @param {number} obj.experianDateOfResult
 * @param {object} obj.experianCreditReport
 * @param {string} obj.experianCreditReport.name
 * @returns
 */
export const addExperianCreditCheckForAgency = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_PENDING,
    });

    const mutation = obj.experianCreditReport ?
      ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_WITH_DOCUMENT :
      ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_WITHOUT_DOCUMENT;

    const response = await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          agency_customer_id: obj.agencyCustomerId,
          company_found: obj.companyFound,
          experian_credit_result: parseFloat(obj.experianCreditResult || 0),
          date_of_result: obj.experianDateOfResult,
        },
        filename: obj.experianCreditReport?.name,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    if (response.data.addExperianCreditCheckForAgency.experian_credit_check.upload_url?.url) {
      await fetch(response.data.addExperianCreditCheckForAgency.experian_credit_check.upload_url.url, {
        method: "PUT",
        body: obj.experianCreditReport,
      });
    }

    const creditManagementUrl = `/credit-limit-management/legal-entities/${response.data.addExperianCreditCheckForAgency.agency_customer.legal_entity.id}/credit-management`;

    dispatch({
      type: types.ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_SUCCESS,
      payload: {
        data: response.data.addExperianCreditCheckForAgency,
      },
    });

    const isProspect = !response.data.addExperianCreditCheckForAgency.credit_request.agency;
    const agency = response.data.addExperianCreditCheckForAgency.agency_customer.agency ??
      response.data.addExperianCreditCheckForAgency.agency_customer.prospect;

    dispatch(modalActions.showModal(ExperianCreditCheckForAgencyModal.displayName, {
      data: {
        agencyName: agency.known_as,
        legalEntityName: response.data.addExperianCreditCheckForAgency.agency_customer.legal_entity.name,
        approvedCredit: response.data.addExperianCreditCheckForAgency.experian_credit_check.approved_credit_including_vat,
        reservedCredit: response.data.addExperianCreditCheckForAgency.credit_request.credit_distributed,
        requestedAmount: response.data.addExperianCreditCheckForAgency.credit_request.credit_requested,
        status: response.data.addExperianCreditCheckForAgency.status,
      },
      onCancel: () => {
        dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));
        dispatch(push(creditManagementUrl));
      },
      onContinueToInsurerApplication: () => {
        dispatch(showInsurerApplicationModal({
          legalEntityId: response.data.addExperianCreditCheckForAgency.agency_customer.legal_entity.id,
          currentCreditLimit: response.data.addExperianCreditCheckForAgency.agency_customer.legal_entity.credit_limit_excluding_vat,
          requestedAmount: response.data.addExperianCreditCheckForAgency.credit_request.credit_requested,
        }));

        dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));
      },
      onCommunicateToAgency: () => {
        dispatch(showCommunicateToAgencyModal({
          agencyId: agency.id,
          legalEntityId: response.data.addExperianCreditCheckForAgency.agency_customer.legal_entity.id,
          creditRequestId: response.data.addExperianCreditCheckForAgency.credit_request.id,
          isProspect,
        }));

        dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));
      },
    }));
  }
  catch (err) {
    dispatch({
      type: types.ADD_EXPERIAN_CREDIT_CHECK_FOR_AGENCY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
