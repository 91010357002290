import React from "react";
import PropTypes from "prop-types";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { connectRouter, routerMiddleware, ConnectedRouter } from "connected-react-router";
import history from "./history";
import * as reducers from "./reducers";
import {  ScrollToTop } from "./decorators";


export const store = createStore(
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  }),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware,
      // createLogger(),
    )
  ),
);

const Root = props => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop />
      {props.children}
    </ConnectedRouter>
  </Provider>
);

Root.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Root;
