import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import UnreportModalValidator from "./UnreportModalValidator";
import { ConfirmationModal, Form } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./UnreportModal.module.scss";

export default function UnreportModal(props) {
  const [state, setState] = useState({
    unreportedAt: moment().valueOf(),
    unreportedComment: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = e => setState({
    ...state,
    [e.target.name]: e.target.value,
  });

  const handleSubmit = () => {
    const validator = new UnreportModalValidator(state);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.onSubmit(state);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Unreport"
      visible={props.visible}
      submitText="Unreport"
      submitDisabled={props.submitting}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <div>
        Remove report to insurer for <strong>{props.reportedReason}</strong> raised on {parseDate(props.reportedAt)}.
      </div>
      <Form>
        <Form.Section>
          <Form.Group inline wide error={errors?.unreportedAt}>
            <Form.Label className={styles.label} inline >
              Date unreported
            </Form.Label>
            <Form.Date
              name="unreportedAt"
              value={state.unreportedAt}
              size="small"
              disabled={props.submitting}
              highlightError={errors?.unreportedAt}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group inline wide>
            <Form.Label className={styles.commentsLabel} inline>
              Comments
            </Form.Label>
            <Form.TextArea
              className={styles.textArea}
              name="unreportedComment"
              value={state.unreportedComment}
              placeholder="Enter comments..."
              rows={6}
              maxLength={255}
              disabled={props.submitting}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

UnreportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  reportedAt: PropTypes.number.isRequired,
  reportedReason: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

UnreportModal.displayName = "UnreportModal";
