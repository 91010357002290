import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import styles from "./FormGroup.module.scss";

export default function FormGroup({
  className,
  children,
  error,
  inline,
  wide,
}) {
  return (
    <div className={classnames(styles.container, className, inline && styles.inline, wide && styles.wide, error && styles.hasError)}>
      {children}
      {error && (
        <div className={styles.error}><InfoOutlinedIcon />{error}</div>)
      }
    </div>
  );
}

FormGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  error: PropTypes.string,
  inline: PropTypes.bool,
  wide: PropTypes.bool,
};
