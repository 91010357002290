import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { CheckCircleOutlineRounded } from "@material-ui/icons";
import { ConfirmationModal } from "../../../common/components";
import { toMoney } from "../../../../utils";
import styles from "./CreditDistributedModal.module.scss";

export default function CreditDistributedModal(props) {
  return (
    <ConfirmationModal
      className={classnames(styles.container, props.className)}
      submitText="Communicate"
      cancelText="Communicate later"
      cancelVariant="outline-primary"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div className={styles.content}>
        <CheckCircleOutlineRounded className={styles.icon} />
        <div className={styles.text}>
          <strong>{toMoney(props.distributedCredit)}</strong> has been successfully distributed for{" "}
          {props.legalEntityName} on behalf of {props.agencyName}
        </div>
      </div>
    </ConfirmationModal>
  );
}

CreditDistributedModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  agencyName: PropTypes.string.isRequired,
  legalEntityName: PropTypes.string.isRequired,
  distributedCredit: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CreditDistributedModal.displayName = "CreditDistributedModal";
