import * as types from "../types";

/**
 * Want to show an error but not otherwise changing global state?
 * Then this action is right for you!
 *
 * @param {Error} error
 * @param {string} error.message
 * @returns
 */
export const displayError = error => async (dispatch) => {
  dispatch({
    type: types.DISPLAY_ERROR,
    payload: {
      error: error,
    },
  });
};
