import { ApolloClient, ApolloLink, InMemoryCache } from "apollo-boost";
import { Auth } from "aws-amplify";
import config from "./config";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { RetryLink } from "apollo-link-retry";

const authLink = setContext(async (_, { headers }) => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const token = accessToken.getJwtToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }
  catch (err) {
    return { headers };
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: error => !!error,
  },
});

const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data?.errors?.length > 0) {
      // Catch potential issues when the API is connecting to another service
      if (data.errors.some(error => error.message.match(/ECONNREFUSED/))) {
        throw new Error("Service currently unavailable");
      }
    }
    return data;
  });
});

const httpLink = createHttpLink({
  uri: config.REACT_APP_GRAPHQL_ENDPOINT,
});

const link = ApolloLink.from([
  authLink,
  retryLink,
  errorLink,
  httpLink,
]);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
