import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_MI_REPORT_GROUPING = gql`
query($prospect_id: ID!) {
  getProspect(id: $prospect_id) {
    mi_report_grouping_required

    mi_report_groupings {
      active
      mi_report_grouping {
        id
        label
        value
      }
      values {
        id
        value
      }
    }

    disabled_fields {
      disabled_field
      prospect_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getMiReportGrouping = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_MI_REPORT_GROUPING_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_MI_REPORT_GROUPING,
      variables: {
        prospect_id: prospectId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_MI_REPORT_GROUPING_SUCCESS,
      payload: {
        data: response.data.getProspect,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_MI_REPORT_GROUPING_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
