import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import {
  DocumentDownload,
  Form,
  IconButton,
  SeeHistoryTooltip,
} from "../../../../common/components";
import { DOCUMENT_TYPES } from "../../../../documents";
import { formatAnswer, parseDate } from "../../../../../utils";
import styles from "./ContractInformationSection.module.scss";

export default function ContractInformationSection(props) {
  const documents = [
    {
      name: DOCUMENT_TYPES.CONTRACT,
      file: props.contract,
    },
  ];

  return (
    <Form.Card
      className={styles.container}
      title="Contract Information"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <Form.Group inline>
        <Form.Label>Contract Start Date</Form.Label>
        <Form.ValueInline>
          <SeeHistoryTooltip variant="compact" data={props.companyHistory?.contract_start_date}>
            {parseDate(props.contractStartDate) || "-"}
          </SeeHistoryTooltip>
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Length Of Contract</Form.Label>
        <Form.ValueInline>
          {props.lengthOfContract?.length && props.lengthOfContract.period ?
            `${props.lengthOfContract.length} ${props.lengthOfContract.period}`
            : "-"
          }
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Date First Traded</Form.Label>
        <Form.ValueInline>
          {parseDate(props.dateFirstTraded) || "-"}
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Fee %</Form.Label>
        <Form.ValueInline>
          {props.contractFee || "-"}
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Funding Type</Form.Label>
        <Form.ValueInline>
          {props.contractFundingType || "-"}
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Introduced via Broker?</Form.Label>
        <Form.ValueInline>
          {formatAnswer(props.introducedViaBroker)}
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label>Ledger Buyout?</Form.Label>
        <Form.ValueInline>
          {formatAnswer(props.ledgerBuyout, "Unknown")}
        </Form.ValueInline>
      </Form.Group>
      <DocumentDownload
        variant="footer"
        documents={documents.filter(doc => doc.file)}
        onDownload={props.onDownload}
      />
    </Form.Card>
  );
}

ContractInformationSection.propTypes = {
  companyHistory: PropTypes.shape({
    contract_start_date: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  contract: PropTypes.string,
  contractFee: PropTypes.string,
  contractFundingType: PropTypes.string,
  contractStartDate: PropTypes.string,
  dateFirstTraded: PropTypes.string,
  introducedViaBroker: PropTypes.string,
  ledgerBuyout: PropTypes.bool,
  lengthOfContract: PropTypes.shape({
    length: PropTypes.number.isRequired,
    period: PropTypes.string.isRequired,
  }),
  onDownload: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
};
