import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { AccessTime, Check, ExpandMore, Warning } from "@material-ui/icons";
import ReactTooltip from "react-tooltip";
import { PriorityPill } from "../../";
import { CollapseContainer, Pill, Table2 } from "../../../../common/components";
import { OnboardingStatusConverter } from "../../../../../converters";
import { isOverdue } from "../../../../../utils";
import styles from "./OnboardingEntityListItem.module.scss";

export default function OnboardingEntityListItem(props) {
  const totalTasks = props.tasks.length;
  const completedTasks = props.tasks.filter(task => task.status === "done").length;
  const filteredTasks = _.chain(props.tasks)
    .filter(task => props.showCompletedTasks || ["not_started", "in_progress"].includes(task.status))
    .sortBy(task => task.due_date)
    .value();
  const numberOfOverdueTasks = filteredTasks.reduce((r, v) => {
    return r += (v.status !== "done" && isOverdue(new Date().getTime(), v.due_date)) ? 1 : 0;
  }, 0);

  return (
    <div className={classnames(props.className, styles.container)}>
      <div className={styles.header} onClick={() => props.onExpandToggle(props.entity.id)}>
        <div className={styles.leftHeader}>
          <div className={styles.entityName} data-tip data-for={`tooltip_${props.entity.id}`}>
            {props.entity.name} {props.entity.entityType === "agency" && (
              <span className={styles.entityId}>({props.entity.shortCode || props.entity.id })</span>
            )}
          </div>
          {props.entity.name?.length > 40 && (
            <ReactTooltip id={`tooltip_${props.entity.id}`}>
              {props.entity.name}
            </ReactTooltip>
          )}
          <div className={styles.tasksCompleted}>{`${completedTasks}/${totalTasks} Tasks Completed`}</div>
          {numberOfOverdueTasks > 0 && (
            <Pill className={styles.overduePill} variant="danger">
              {numberOfOverdueTasks === 1
                ? "1 Overdue Task"
                : `${numberOfOverdueTasks} Overdue Tasks`
              }
            </Pill>
          )}
        </div>
        <span className={styles.clickableIcon}>
          <ExpandMore
            className={classnames(
              styles.expandIcon,
              props.isExpanded ? styles.collapse : styles.expand
            )}
            fontSize="inherit"
          />
        </span>
      </div>
      <CollapseContainer collapsed={!props.isExpanded}>
        <Table2
          data={filteredTasks}
          rowClassName={styles.rowClassName}
        >
          <Table2.Column
            collapsed
            cellClassName={styles.tableCell}
            name="task"
            value={row => (
              <span className={styles.taskName} onClick={() => props.onViewClick({ entity: props.entity, task: row })}>
                {row.onboarding_task.name}
              </span>
            )}
          >
            Task
          </Table2.Column>
          <Table2.Column
            collapsed
            cellClassName={styles.tableCell}
            name="task"
            value={row => (
              <div className={styles.taskStatus}>
                {row.status === "not_started" && (
                  <Warning className={styles.notStartedIcon} />
                )}
                {row.status === "in_progress" && (
                  <AccessTime className={styles.inProgressIcon} />
                )}
                {row.status === "done" && (
                  <Check className={styles.doneIcon} />
                )}
                {OnboardingStatusConverter.to(row.status)}
              </div>
            )}
          >
            Status
          </Table2.Column>
          <Table2.Column
            collapsed
            className={styles.centerColumn}
            cellClassName={styles.tableCell}
            name="task"
            value={(row) => {
              return `${row.progress.required_completed} / ${row.progress.required_total}`;
            }}
          >
            Subtasks Completed
          </Table2.Column>
          <Table2.Column
            collapsed
            className={styles.centerColumn}
            cellClassName={styles.tableCell}
            name="priority"
            value={row => row.status !== "done" && <PriorityPill dueDate={row.due_date} />}
          >
            Priority
          </Table2.Column>
        </Table2>
      </CollapseContainer>
    </div>
  );
}

OnboardingEntityListItem.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    shortCode: PropTypes.string,
    entityType: PropTypes.oneOf(["agency", "prospect"]),
  }),
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    onboarding_task: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    definition: PropTypes.shape({
      subtasks: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        complete: PropTypes.bool.isRequired,
      })),
    }),
    status: PropTypes.string.isRequired,
    progress: PropTypes.shape({
      required_total: PropTypes.number.isRequired,
      required_completed: PropTypes.number.isRequired,
      optional_total: PropTypes.number.isRequired,
      optional_completed: PropTypes.number.isRequired,
    }),
    completed_at: PropTypes.string,
    due_date: PropTypes.number,
  })),
  isExpanded: PropTypes.bool.isRequired,
  showCompletedTasks: PropTypes.bool.isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
};
