import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon as CustomIcon } from "../";
import uploadIcon from "../../../../assets/images/experian_report_upload.svg";
import styles from "./DocumentUpload.module.scss";

export default function DocumentUpload(props) {
  const inputRef = useRef();
  const [hovering, setHovering] = useState(false);

  const selectFiles = (files) => {
    props.onUpload({ newFiles: files });
  };

  const dragOver = (e) => {
    e.preventDefault();
    setHovering(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setHovering(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHovering(false);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setHovering(false);
    selectFiles(e.dataTransfer.files);
  };

  const handleFileSelected = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    selectFiles(e.target.files);
  };

  return (
    <div
      className={classnames(
        props.className,
        styles.container,
        hovering && styles.hovering,
        props.disabled && styles.disabled,
      )}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}>
      <CustomIcon className={styles.uploadIcon} icon={uploadIcon} />
      <div className={styles.placeholder}>
        Drag and drop new documents here,{" "}
        or <span className={styles.link} onClick={() => inputRef.current.click()}>browse...</span>
      </div>
      <input
        className={styles.hidden}
        ref={inputRef}
        type="file"
        onChange={handleFileSelected}
      />
    </div>
  );
}

DocumentUpload.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
};
