import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import ChangePasswordModal from "./ChangePasswordModal";

const mapStateToProps = state => ({
  password: state.currentUser.changePassword,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);

container.displayName = ChangePasswordModal.displayName;

export default container;
