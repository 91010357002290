import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  ConfirmationModal,
  DocumentUpload,
  DocumentUploadCard,
} from "../../../common/components";
import styles from "./UploadDocumentsModal.module.scss";
import UploadDocumentsModalValidator from "./UploadDocumentsModalValidator";

export default function UploadDocumentsModal (props) {
  const [files, setFiles] = useState([]);
  const [availableDocTypes, setAvailableDocTypes]= useState(props.documentTypes);
  const [errors, setErrors] = useState({});

  const { documentTypes } = props;

  useEffect(() => {
    setAvailableDocTypes(documentTypes);
  }, [documentTypes]);

  useEffect(() => {
    const validator = new UploadDocumentsModalValidator(files);
    const { errors } = validator.validate();
    setErrors(errors);
  }, [files]);

  const canSubmit = () => {
    if (!_.isEmpty(errors)) {
      return false;
    }

    if (_.isEmpty(files)) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    await props.uploadDocuments({
      agencyId: props.agencyId,
      prospectId: props.prospectId,
      files,
    });

    props.hideModal(UploadDocumentsModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(UploadDocumentsModal.displayName);
  };

  const handleFileUpload = ({ newFiles }) => {
    setFiles([...files, ...newFiles]);
  };

  const handleFileDelete = (fileToDelete) => {
    setFiles(files.filter(file => file !== fileToDelete));
  };

  const handleDocumentTypeSelected = (selectedDocumentType, selectedFile) => {
    setFiles(files.map((file) => {
      if (file !== selectedFile) {
        return file;
      }
      file.documentType = selectedDocumentType;
      return file;
    }));
    const selectedDocumentTypes = files.map(file => file.documentType);
    setAvailableDocTypes(documentTypes.filter((documentType) => {
      return !selectedDocumentTypes.includes(documentType);
    }));
  };

  return (
    <ConfirmationModal
      title="Upload New Documents"
      visible={props.visible}
      className={styles.container}
      submitText="Upload Documents"
      submitDisabled={!canSubmit() || props.loading}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      busy={props.submitting}>
      <div className={styles.maxFileSize}>
        Maximum file size: 5GB
      </div>
      <DocumentUpload onUpload={handleFileUpload}/>
      {files.map((file, i) => (
        <DocumentUploadCard
          key={i}
          className={styles.documentUploadCardContainer}
          documentTypes={availableDocTypes}
          error={errors[i]}
          file={file}
          loading={props.loading}
          onDelete={handleFileDelete}
          onDocumentTypeSelected={handleDocumentTypeSelected}/>
      ))}
    </ConfirmationModal>
  );
}

UploadDocumentsModal.propTypes = {
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  agencyId: PropTypes.string,
  prospectId: PropTypes.string,
  documentTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
  })),
  hideModal: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
};

UploadDocumentsModal.displayName = "UploadDocumentsModal";
