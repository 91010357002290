import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import {
  CompaniesHouseInfo,
  ConvertToAgencyForm,
  CompleteConvertToAgencyConfirmationModal,
} from "../../components";
import styles from "./ConvertToAgencyPage.module.scss";

export default class ConvertToAgencyPage extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    prospect: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        worker_types: PropTypes.array,
        is_new_agency: PropTypes.bool,
        is_ledger_buyout: PropTypes.bool,
        is_existing_paye_scheme: PropTypes.bool,
        introduced_via_broker: PropTypes.bool,
        previous_provider: PropTypes.string,
        known_as: PropTypes.string,
        trading_as: PropTypes.string,
        companies_house_info: PropTypes.object,
        contractual_agreement_onboarding_task: PropTypes.shape({
          completed_at: PropTypes.number,
        }),
      }),
    }),
    shortCodeAvailability: PropTypes.shape({
      loading: PropTypes.bool,
      isAvailable: PropTypes.bool,
    }),
    ui: PropTypes.shape({
      submitting: PropTypes.bool,
    }).isRequired,
    data: PropTypes.object,
    onFetchProspect: PropTypes.func.isRequired,
    onCheckShortCodeExists: PropTypes.func.isRequired,
    onViewExistingAgencyShortCodes: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  state = {
    showConvertToAgencyModal: false,
    data: null,
  }

  async componentDidMount() {
    const { prospectId, onFetchProspect } = this.props;

    await onFetchProspect(prospectId);
  }

  render() {
    return (
      <>
        <h1 className={styles.title}>Convert to Agency</h1>
        <hr className={styles.separator} />
        <Grid padded stackable divided columns={2} className={styles.container}>
          <Grid.Column>
            {this.renderCompaniesHouseInfo()}
          </Grid.Column>
          <Grid.Column>
            {this.renderForm()}
          </Grid.Column>
        </Grid>
        <CompleteConvertToAgencyConfirmationModal
          visible={this.state.showConvertToAgencyModal}
          onCancel={this.handleCancelConvertToAgency}
          onSubmit={this.handleConfirmConvertToAgency}
        />
      </>
    );
  }

  renderCompaniesHouseInfo = () => {
    if (this.props.prospect.loading) {
      return <ActivityIndicator />;
    }

    if (!this.props.prospect.data?.companies_house_info) {
      return <ActivityIndicator />;
    }

    return (
      <CompaniesHouseInfo
        companyProfile={this.props.prospect.data.companies_house_info}
        // certificateOfIncorporationUrl={this.props.companiesHouseInfo.data.certificateOfIncorporationUrl}
      />
    );
  }

  renderForm = () => {
    if (this.props.prospect.loading) {
      return <ActivityIndicator />;
    }

    return (
      <ConvertToAgencyForm
        className={styles.form}
        submitting={this.props.ui.submitting}
        data={{
          workerTypes: this.props.prospect.data.worker_types,
          isNewAgency: this.props.prospect.data.is_new_agency,
          isLedgerBuyout: this.props.prospect.data.is_ledger_buyout,
          isExistingPayeScheme: this.props.prospect.data.is_existing_paye_scheme,
          introducedViaBroker: this.props.prospect.data.introduced_via_broker,
          previousProvider: this.props.prospect.data.previous_provider,
          knownAs: this.props.prospect.data.known_as,
          tradingAs: this.props.prospect.data.trading_as,
        }}
        initialState={this.props.data}
        shortCodeAvailability={this.props.shortCodeAvailability}
        showContractualAgreementWarning={!this.props.prospect.data.contractual_agreement_onboarding_task?.completed_at}
        onCheckShortCodeAvailability={this.handleCheckShortCodeAvailability}
        onViewExistingAgencyShortCodes={this.handleViewExistingAgencyShortCodes}
        onCancel={this.handleCancel}
        onSubmit={this.handleConvertToAgencyClick}
      />
    );
  }

  handleCheckShortCodeAvailability = async ({ shortCode }) => {
    const { onCheckShortCodeExists } = this.props;

    onCheckShortCodeExists && await onCheckShortCodeExists({ shortCode });
  }

  handleViewExistingAgencyShortCodes = async (data) => {
    const { prospectId, onViewExistingAgencyShortCodes } = this.props;

    onViewExistingAgencyShortCodes && await onViewExistingAgencyShortCodes(prospectId, data);
  }

  handleConvertToAgencyClick = (data) => {
    this.setState({
      showConvertToAgencyModal: true,
      data,
    });
  }

  handleCancelConvertToAgency = async () => {
    this.setState({
      showConvertToAgencyModal: false,
      data: null,
    });
  }

  handleConfirmConvertToAgency = () => {
    const { prospectId, onSubmit } = this.props;
    const { data } = this.state;

    this.setState({
      showConvertToAgencyModal: false,
    }, () => onSubmit(prospectId, data));
  }

  handleCancel = async () => {
    const { prospectId, onCancel } = this.props;

    onCancel && await onCancel(prospectId);
  }

}
