import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Adjust, ArrowForward, ArrowBack } from "@material-ui/icons";
import styles from "./TrackingIcon.module.scss";

export default class TrackingIcon extends PureComponent {

  static propTypes = {
    type: PropTypes.oneOf(["tracking","chase","resolve"]),
    className: PropTypes.string,
  };

  render() {
    const { type, className, ...otherProps } = this.props;

    switch (type) {
      case "tracking":
        return (
          <Adjust
            className={classnames(
              styles.icon,
              className,
            )}
            {...otherProps}
          />
        );

      case "chase":
        return (
          <ArrowForward
            className={classnames(
              styles.icon,
              className,
            )}
            {...otherProps}
          />
        );

      case "resolve":
        return (
          <ArrowBack
            className={classnames(
              styles.icon,
              className,
            )}
            {...otherProps}
          />
        );

      default:
        return null;
    }
  }

}
