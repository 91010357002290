import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const UPDATE_PROSPECT = gql`
mutation(
  $id: ID!
  $update_input: UpdateProspectInput!
  $worker_types_input: UpdateProspectWorkerTypesInput!
) {
  updateProspect(id: $id, input: $update_input) {
    id
  }

  updateProspectWorkerTypes(prospect_id: $id, input: $worker_types_input) {
    data {
      id
    }
  }
}
`;

export const CONVERT_TO_AGENCY = gql`
mutation(
  $id: ID!
  $input: ConvertToAgencyInput!
) {
  convertToAgency(id: $id, input: $input) {
    id
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {string} obj.data.knownAs
 * @param {string} obj.data.tradingAs
 * @param {string} obj.data.shortCode
 * @param {bool} obj.data.isNewAgency
 * @param {bool} obj.data.isLedgerBuyout
 * @param {bool} obj.data.isExistingPayeScheme
 * @param {bool} obj.data.introducedViaBroker
 * @param {number[]} obj.data.workerTypes
 * @param {number} obj.data.previousProviderId
 * @param {number} obj.data.agencyStatusId
 *
 */
export const convertToAgency = ({ prospectId, data }) => async (dispatch) => {
  try {
    dispatch({
      type: types.CONVERT_TO_AGENCY_PENDING,
    });

    const updateProspectResponse = await apolloClient.mutate({
      mutation: UPDATE_PROSPECT,
      variables: {
        id: prospectId,
        update_input: {
          known_as: data.knownAs,
          trading_as: data.tradingAs,
          is_new_agency: data.isNewAgency,
          is_ledger_buyout: data.isLedgerBuyout,
          is_existing_paye_scheme: data.isExistingPayeScheme,
          introduced_via_broker: data.introducedViaBroker,
          previous_provider_id: data.previousProvider?.id || null,
        },
        worker_types_input: {
          worker_types: data.workerTypes.map(workerType => workerType.id),
        },
      },
    });

    if (updateProspectResponse.errors) {
      throw updateProspectResponse.errors[0].message;
    }

    const convertToAgencyResponse = await apolloClient.mutate({
      mutation: CONVERT_TO_AGENCY,
      variables: {
        id: prospectId,
        input: {
          agency_status_id: data.agencyStatus.id,
          short_code: data.shortCode,
        },
      },
    });

    if (convertToAgencyResponse.errors) {
      throw convertToAgencyResponse.errors[0].message;
    }

    dispatch({
      type: types.CONVERT_TO_AGENCY_SUCCESS,
      payload: {
        prospect: convertToAgencyResponse.data.convertToAgency,
      },
    });
    dispatch(push(`/onboarding/agencies/${convertToAgencyResponse.data.convertToAgency.id}`));
  }
  catch (error) {
    dispatch({
      type: types.CONVERT_TO_AGENCY_FAILURE,
      payload: {
        error,
      },
    });
  }
};
