import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const CANCEL_CREDIT_SUSPENSION = gql`
mutation($id: ID!) {
  cancelCreditSuspension(id: $id) {
    id
    date_of_effect
    date_cancelled
    type
    status
    comment
    created_by {
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.creditSuspensionId
 * @returns
 */
export const cancelCreditSuspension = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.CANCEL_CREDIT_SUSPENSION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: CANCEL_CREDIT_SUSPENSION,
      variables: {
        id: obj.creditSuspensionId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.CANCEL_CREDIT_SUSPENSION_SUCCESS,
      payload: {
        data: response.data.cancelCreditSuspension,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.CANCEL_CREDIT_SUSPENSION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
