import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Dropdown } from "semantic-ui-react";
import {
  CloseRounded,
  ExpandMoreRounded,
  HistoryRounded,
  ThumbDownRounded,
  ThumbUpRounded,
} from "@material-ui/icons";
import { BadgePill, Button } from "../../../../common/components";
import { BrokerRequestEventLog } from "../../../components";
import { options } from "../../../../../constants";
import styles from "./BrokerRequest.module.scss";

export default class BrokerRequest extends Component {

  static propTypes = {
    loading: PropTypes.bool,
    legalEntityId: PropTypes.string.isRequired,
    creditApplicationId: PropTypes.string.isRequired,
    creditApplication: PropTypes.shape({
      data: PropTypes.shape({
        status: PropTypes.string.isRequired,
      }),
    }),
    getCreditApplication: PropTypes.func.isRequired,
    showCreditApplicationCommentModal: PropTypes.func.isRequired,
    showGoToInsurerModal: PropTypes.func.isRequired,
    showUseDiscretionaryLimitModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { legalEntityId, creditApplicationId, getCreditApplication } = this.props;

    getCreditApplication(legalEntityId, creditApplicationId);
  }

  handleCloseClicked = () => {
    const { legalEntityId, onClose } = this.props;
    onClose(legalEntityId);
  }

  handleLeaveCommentClicked = () => {
    const { showCreditApplicationCommentModal, creditApplicationId } = this.props;

    showCreditApplicationCommentModal({ creditApplicationId });
  }

  getBadgeTitleClassName = () => {
    const creditApplication = this.props.creditApplication.data;
    switch (creditApplication?.status) {
      case "pending":
        return styles.pendingStatusTitle;
      case "accepted":
        return styles.acceptedStatusTitle;
      case "declined":
        return styles.declinedStatusTitle;
      case "cancelled":
        return styles.cancelledStatusTitle;
      default:
        return null;
    }
  }

  getBadgeClassName = () => {
    const creditApplication = this.props.creditApplication.data;
    switch (creditApplication?.status) {
      case "pending":
        return styles.pendingStatusBadge;
      case "accepted":
        return styles.acceptedStatusBadge;
      case "declined":
        return styles.declinedStatusBadge;
      case "cancelled":
        return styles.cancelledStatusBadge;
      default:
        return null;
    }
  }

  getBadgeIcon = () => {
    const creditApplication = this.props.creditApplication.data;
    switch (creditApplication?.status) {
      case "pending":
        return <HistoryRounded className={styles.pendingStatusIcon} />;
      case "accepted":
        return <ThumbUpRounded className={styles.acceptedStatusIcon} />;
      case "declined":
        return <ThumbDownRounded className={styles.declinedStatusIcon} />;
      case "cancelled":
        return <CloseRounded className={styles.cancelledStatusIcon} />;
      default:
        return null;
    }
  }

  onGoToInsurerClick = () => {
    this.props.showGoToInsurerModal({
      creditApplicationId: this.props.creditApplicationId,
      legalEntityId: this.props.legalEntityId,
    });
  }

  onUseDiscretionaryLimitClick = () => {
    this.props.showUseDiscretionaryLimitModal({
      creditApplicationId: this.props.creditApplicationId,
    });
  }
  render() {
    if (this.props.loading) {
      return <ActivityIndicator />;
    }
    return (
      <>
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>Broker Request</div>
          <BadgePill
            title={
              options.BROKER_CREDIT_APPLICATION_STATUS_OPTIONS?.find(
              option => option.value === this.props.creditApplication.data?.status
            )?.label || this.props.creditApplication.data?.status
            }
            leftIcon={this.getBadgeIcon()}
            pillStyle={this.getBadgeClassName()}
            titleStyle={this.getBadgeTitleClassName()} />
          {this.props.creditApplication.data?.status === "pending" && (
            <div className={styles.recordResultContainer}>
              <Dropdown
                trigger={(
                  <>
                    <div className={styles.recordResultTitle}>
                        Record Result
                    </div>
                    <ExpandMoreRounded className={styles.recordResultIcon} />
                  </>
                )}
                className={styles.dropdown}
                icon={null}
                direction="left"
                floating>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.onGoToInsurerClick()}>
                    <div className={styles.actionItem}>
                      <ThumbUpRounded className={styles.goToInsurerActionIcon}/>
                      Go To Insurer
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.onUseDiscretionaryLimitClick()}>
                    <div className={styles.actionItem}>
                      <ThumbDownRounded className={styles.useDiscretionaryLimitActionIcon}/>
                      Use Discretionary Limit
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <Button variant="secondary" className={styles.button} onClick={this.handleCloseClicked}>Back to Legal Entity</Button>
        </div>
        <hr className={styles.separator} />
        <div className={styles.subHeaderContainer}>
          <div className={styles.subHeaderTitle}>Request Overview</div>
          <Button variant="secondary" className={styles.button} onClick={this.handleLeaveCommentClicked}>Leave Comment</Button>
        </div>
        <BrokerRequestEventLog
          creditApplication={this.props.creditApplication.data}/>
      </>
    );
  }
}
