import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { actions } from "../../../duck";
import BrokerRequest from "./BrokerRequest";


const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  legalEntityId: ownProps.match.params.legalEntityId,
  creditApplicationId: ownProps.match.params.creditApplicationId,
  loading: state.creditLimitManagement.creditApplication.loading,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  onClose: (legalEntityId) => {
    dispatch(push(`/credit-limit-management/legal-entities/${legalEntityId}/credit-applications`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BrokerRequest);
