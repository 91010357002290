import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import {
  Form,
  IconButton,
  TextLink,
} from "../../../common/components";
import { getAbsoluteUrl } from "../../../../utils";
import styles from "./NonRegisteredLegalEntityDetailsView.module.scss";

export default function NonRegisteredLegalEntityDetailsView(props) {
  const { legalEntity } = props;

  return (
    <div>
      <Form.Card
        title="Company Details"
        rightAccessory={(
          <IconButton
            icon={<Edit />}
            tooltip="Edit"
            onClick={props.onEditCompanyDetailsClick}
          />
        )}
      >

        <div className={styles.cols}>
          <div>
            <Form.Group inline>
              <Form.Label>Business Name</Form.Label>
              <Form.ValueInline>
                {legalEntity.name ?? "-"}
              </Form.ValueInline>
            </Form.Group>
            <Form.Group className={styles.bigGroup} inline>
              <Form.Label className={styles.bigGroupLabel}>Address</Form.Label>
              <Form.Value>
                {legalEntity.company_address ? (
                  <>
                    <div>{legalEntity.company_address.address_line_one}</div>
                    <div>{legalEntity.company_address.address_line_two}</div>
                    <div>{legalEntity.company_address.town_or_city}</div>
                    <div>{legalEntity.company_address.county}</div>
                    <div>{legalEntity.company_address.postcode}</div>
                  </>
                ) : "-"}
              </Form.Value>
            </Form.Group>
          </div>
          <div>
            <Form.Group inline>
              <Form.Label>Telephone Number</Form.Label>
              <Form.ValueInline>
                {legalEntity.telephone ?? "-"}
              </Form.ValueInline>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Email Address</Form.Label>
              <Form.ValueInline>
                {legalEntity.email_address ?? "-"}
              </Form.ValueInline>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Website</Form.Label>
              <Form.ValueInline>
                {legalEntity.website ? (
                  <TextLink
                    className={styles.link}
                    text={legalEntity.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getAbsoluteUrl(legalEntity.website)}
                  />
                ) : "-"}
              </Form.ValueInline>
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Is the company a charity?</Form.Label>
              <Form.ValueInline>
                {legalEntity?.__typename === "Charity" ? "Yes" : "No"}
              </Form.ValueInline>
            </Form.Group>
            {legalEntity?.__typename === "Charity"  && (
              <Form.Group inline>
                <Form.Label>Registered Charity Number</Form.Label>
                <Form.ValueInline>
                  {legalEntity?.registered_charity_number || "-"}
                </Form.ValueInline>
              </Form.Group>
            )}
          </div>
        </div>
      </Form.Card>
    </div>
  );
}

NonRegisteredLegalEntityDetailsView.propTypes = {
  legalEntity: {
    __typename: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    registered_charity_number: PropTypes.string,
    telephone: PropTypes.string,
    email_address: PropTypes.string,
    website: PropTypes.string,
    company_address: PropTypes.shape({
      address_line_one: PropTypes.string,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
    }).isRequired,
  },
  onEditCompanyDetailsClick: PropTypes.func.isRequired,
};
