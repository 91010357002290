/**
 * Returns true if the supplied string
 * is entirely alphanumeric, returns
 * false otherwise.
 * @param {string} string
 * @param {bool} allowSpaces
 * @returns bool
 */
const isAlphaNumeric = (string, allowSpaces = false) => {
  const pattern = allowSpaces ? "^[a-zA-Z0-9 ]*$" : "^[a-zA-Z0-9]*$";

  return Boolean(string.match(pattern));
};

export default isAlphaNumeric;
