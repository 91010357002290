import { Auth } from "aws-amplify";
import { push } from "connected-react-router";
import * as types from "../types";

/**
 * @param  {string} {obj.message} A message to display to the user on the sign-in page
 */
export const forceLogout = obj => async (dispatch) => {
  await Auth.signOut();

  dispatch({
    type: types.LOGOUT,
  });

  dispatch(push("/sign-in", { message: obj.message }));
};
