import * as types from "./types";

const initialState = {
  login: {
    loading: false,
    error: null,
  },
  resetPassword: {
    loading: false,
    email: null,
    isInProgress: false,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN_PENDING:
      return {
        ...state,
        login: {
          ...initialState.login,
          loading: true,
        },
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...initialState.login,
        },
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...initialState.login,
          error: action.payload.error,
        },
      };

    case types.GET_RESET_PASSWORD_CODE_PENDING:
      return {
        ...state,
        resetPassword: {
          ...initialState.resetPassword,
          loading: true,
        },
      };

    case types.GET_RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...initialState.resetPassword,
          email: action.payload.email,
          isInProgress: true,
        },
      };

    case types.GET_RESET_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        resetPassword: {
          ...initialState.resetPassword,
          error: action.payload.error,
        },
      };

    case types.VERIFY_RESET_PASSWORD_CODE_PENDING:
      return {
        ...state,
        resetPassword: {
          ...initialState.resetPassword,
          loading: true,
          email: state.resetPassword.email,
          isInProgress: true,
        },
      };

    case types.VERIFY_RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...initialState.resetPassword,
        },
      };

    case types.VERIFY_RESET_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        resetPassword: {
          ...initialState.resetPassword,
          email: state.resetPassword.email,
          isInProgress: true,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
