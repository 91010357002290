import _ from "lodash";
import mapKeysDeep from "map-keys-deep-lodash";

const toCamelCase = (obj) => {
  return mapKeysDeep(obj, (_value, key) => _.camelCase(key));
};

const toSnakeCase = (obj) => {
  return mapKeysDeep(obj, (_value, key) => _.snakeCase(key));
};

export default {
  toCamelCase,
  toSnakeCase,
};
