import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Squares as ActivityIndicator } from "react-activity";
import { actions } from "../../duck";
import { MIReportGroupingForm } from "../../components";

export class ReportingTab extends Component {

  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["", "edit"]),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyMiReportGroupings: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.shape({
        required: PropTypes.bool.isRequired,
        groupings: PropTypes.array.isRequired,
      }),
      submitting: PropTypes.bool,
    }).isRequired,
    agencyDisabledFields: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.string,
      ).isRequired,
    }).isRequired,
    getAgencyMIReportGroupings: PropTypes.func.isRequired,
    updateAgencyMIReportGroupings: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { agencyId, getAgencyMIReportGroupings, match, onLoad } = this.props;

    getAgencyMIReportGroupings({ agencyId });
    onLoad && onLoad(match.path);
  }

  render() {
    if (this.props.agencyMiReportGroupings.loading) {
      return <ActivityIndicator />;
    }

    return (
      <MIReportGroupingForm
        readOnly={this.props.mode !== "edit"}
        data={this.props.agencyMiReportGroupings.data}
        submitting={this.props.agencyMiReportGroupings.submitting}
        agencyDisabledFields={this.props.agencyDisabledFields.data}
        onLoad={this.handleLoad}
        onEdit={this.handleEdit}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        onDisabledFieldClicked={this.handleDisabledFieldClicked}
      />
    );
  }

  handleLoad = async (data) => {
    const { agencyId, getAgencyDisabledFields } = this.props;

    await getAgencyDisabledFields(agencyId, data);
  }

  handleDisabledFieldClicked = (disabledField) => {
    const { history, agencyId } = this.props;

    history.push(`/onboarding/agencies/${agencyId}/${disabledField.agency_onboarding_task_id}`);
  }

  handleEdit = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/reporting/edit`);
  }

  handleCancel = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/reporting`);
  }

  handleSubmit = (data) => {
    const { agencyId, updateAgencyMIReportGroupings } = this.props;

    updateAgencyMIReportGroupings({ agencyId, data });
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
  mode: ownProps.match.params.mode,
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportingTab);
