import React from "react";
import PropTypes from "prop-types";
import { Divider } from "semantic-ui-react";
import { Close, InfoOutlined, ReportProblemOutlined } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import styles from "./RecipientInput.module.scss";

const renderName = (contact) => {
  return contact.preferred_name
    ? `${contact.first_name} ${contact.last_name} (${contact.preferred_name})`
    : `${contact.first_name} ${contact.last_name}`;
};

export default function RecipientInput(props) {
  const handleContactSelected = (e) => {
    const selectedContact = props.contacts.find(contact => contact.id === e.target.value);

    props.onChange({
      contactId: e.target.value,
      recipientType: props.recipientType,
      email: selectedContact?.email || "",
    });
  };

  const options = props.showNewContactOption
    ? [
      {
        label: <div>Create New Contact</div>,
        content: <div>Create New Contact</div>,
        value: -1,
      },
      {
        content: <Divider className={styles.divider}/>,
        disabled: true,
      },
    ]
    : [];

  const recipientOptions = props.contacts.map(contact => ({
    label: renderName(contact),
    value: contact.id,
    content: (
      <div>
        {renderName(contact)}
        <div className={styles.email}>
          {contact.email}
        </div>
      </div>
    ),
    disabled: props.disabledContacts.includes(contact.id),
  }));
  recipientOptions.forEach(option => options.push(option));

  const renderInput = () => {
    return (
      <Form.Select
        id={props.showNewContactOption ? styles.dropdownWithCreateNew : null}
        options={options}
        value={props.contactId}
        highlightError={props.highlightError}
        fluid
        onChange={handleContactSelected}
      />
    );
  };

  const renderEmailInput = () => {
    const selectedContact = props.contacts.find(contact => contact.id === props.contactId);

    if (!selectedContact) {
      return null;
    }

    if (selectedContact.email) {
      return null;
    }

    return (
      <div className={styles.emailInputContainer}>
        <div className={styles.emailInputWarning}>
          <ReportProblemOutlined className={styles.warningIcon} />
          <div className={styles.warningText}>
            This contact does not have an email address attached to them. Please add an email address to continue.
          </div>
        </div>
        <Form.Input
          className={styles.emailInput}
          type="email"
          value={props.email}
          placeholder="Enter Email Address..."
          fluid
          onChange={e => props.onChange({
            contactId: props.contactId,
            recipientType: props.recipientType,
            email: e.target.value,
          })}
        />
      </div>
    );
  };

  if (props.index === 0) {
    return [
      renderInput(),
      renderEmailInput(),
    ];
  }

  return (
    <div className={styles.container}>
      <div className={styles.recipientTypeRow}>
        {props.highlightError && (
          <InfoOutlined className={styles.errorIcon} />
        )}
        <Form.Select
          className={styles.recipientType}
          options={props.recipientTypes}
          value={props.recipientType}
          onChange={e => props.onChange({
            contactId: props.contactId,
            recipientType: e.target.value,
          })}
        />
        {renderInput()}
        <IconButton
          className={styles.removeButton}
          icon={(<Close />)}
          iconPosition="left"
          onClick={props.onRemove}
        />
      </div>
      {renderEmailInput()}
    </div>
  );
}

RecipientInput.propTypes = {
  index: PropTypes.number,
  recipientTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    preferredName: PropTypes.string,
    email: PropTypes.string,
  })).isRequired,
  recipientType: PropTypes.oneOf(["to", "cc", "bcc"]).isRequired,
  contactId: PropTypes.string,
  email: PropTypes.string,
  disabledContacts: PropTypes.arrayOf(PropTypes.string),
  highlightError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  showNewContactOption: PropTypes.bool,
};

RecipientInput.defaultProps = {
  contacts: [],
};
