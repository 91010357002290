import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form, Modal } from "../../../common/components";
import { parseDate, toMoney } from "../../../../utils";
import CreateCreditApplicationModalValidator from "./CreateCreditApplicationModalValidator";
import styles from "./CreateCreditApplicationModal.module.scss";

const INITIAL_FORM_STATE = {
  typeOfCheck: null,
  amountApplyingFor: null,
  dateOfRequest: Date.now(),
};

export default function CreateCreditApplicationModal (props) {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});

  const busy = props.submitting || props.loading;

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChangeTypeOfcheck = e => setFormData({ ...INITIAL_FORM_STATE, typeOfCheck: e.target.value });

  const canComplete = () => {
    const { typeOfCheck, amountApplyingFor, dateOfRequest } = formData;
    if (!typeOfCheck) {
      return false;
    }
    if (!dateOfRequest) {
      return false;
    }
    if (!amountApplyingFor) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const validator = new CreateCreditApplicationModalValidator(formData);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }

    props.createCreditApplication({
      legalEntityId: props.legalEntityId,
      amountNeeded: parseInt(formData.amountApplyingFor),
      source: formData.typeOfCheck,
      dateOfRequest: formData.dateOfRequest,
      comment: formData.comment,
    });

    props.hideModal(CreateCreditApplicationModal.displayName);
  };

  const handleClose = () => {
    props.hideModal(CreateCreditApplicationModal.displayName);
  };

  return (
    <Modal
      title="Manage Credit"
      subtitle="Complete the form below to submit an insurer or broker application."
      busy={busy}
      primaryButtonDisabled={!canComplete()}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      submitText="Complete"
      className={styles.modal}
      buttonPosition="middle"
      visible
    >
      <Form.Group inline className={styles.currentLimitGroup}>
        <Form.Label inline>Current credit limit (Excl. VAT)</Form.Label>
        <Form.Value className={styles.fixedFormNumber}>
          {!_.isNil(props.creditOverview?.credit_limit_excluding_vat)
            ? toMoney(props.creditOverview.credit_limit_excluding_vat)
            : "£-"}
        </Form.Value>
      </Form.Group>

      <Form.Group inline>
        <Form.Label inline required>Source</Form.Label>
        <Form.RadioContainer>
          <Form.Radio
            value="broker"
            name="typeOfCheck"
            checked={formData.typeOfCheck === "broker"}
            text="Broker Request"
            onChange={handleChangeTypeOfcheck}
          />
          <Form.Radio
            value="insurer"
            name="typeOfCheck"
            checked={formData.typeOfCheck === "insurer"}
            text="Insurer Application"
            onChange={handleChangeTypeOfcheck}
          />
        </Form.RadioContainer>
      </Form.Group>

      <Form.Group inline className={styles.group}>
        <Form.Label inline required>Amount applying for (Excl. VAT)</Form.Label>
        <Form.Number
          name="amountApplyingFor"
          value={formData.amountApplyingFor}
          placeholder="eg. £10,000"
          prefix="£"
          onChange={handleFormChange}
          formatNumber
        />
      </Form.Group>

      <Form.Group inline className={styles.group} error={errors.dateOfRequest}>
        <Form.Label inline required className={styles.dateLabel}>
          Date of request
        </Form.Label>
        <Form.Value>
          <Form.Date
            name="dateOfRequest"
            size="small"
            value={formData.dateOfRequest}
            onChange={handleFormChange}
            disabled={busy}
            highlightError={errors.dateOfRequest}
            max={parseDate(Date.now(), "YYYY-MM-DD")}
            fluid
          />
        </Form.Value>
      </Form.Group>

      <Form.Group inline className={styles.group}>
        <Form.Label inline className={styles.commentsLabel}>Comments</Form.Label>
        <Form.Value>
          <Form.TextArea
            placeholder="Enter comments..."
            name="comment"
            value={formData.comment}
            onChange={handleFormChange}
            className={styles.textArea}
            rows={6}
            maxLength={255}
          />
        </Form.Value>
      </Form.Group>
    </Modal>
  );
}

CreateCreditApplicationModal.displayName = "CreateCreditApplicationModal";

CreateCreditApplicationModal.propTypes = {
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  legalEntityId: PropTypes.string.isRequired,
  creditOverview: PropTypes.shape({
    credit_limit_excluding_vat: PropTypes.number,
  }).isRequired,
  createCreditApplication: PropTypes.func.isRequired,
};
