export const GET_USERS_PENDING = "USER_MANAGEMENT/GET_USERS_PENDING";
export const GET_USERS_SUCCESS = "USER_MANAGEMENT/GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "USER_MANAGEMENT/GET_USERS_FAILURE";

export const GET_USER_PENDING = "USER_MANAGEMENT/GET_USER_PENDING";
export const GET_USER_SUCCESS = "USER_MANAGEMENT/GET_USER_SUCCESS";
export const GET_USER_FAILURE = "USER_MANAGEMENT/GET_USER_FAILURE";

export const ADD_USER_PENDING = "USER_MANAGEMENT/ADD_USER_PENDING";
export const ADD_USER_SUCCESS = "USER_MANAGEMENT/ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "USER_MANAGEMENT/ADD_USER_FAILURE";

export const UPDATE_USER_PENDING = "USER_MANAGEMENT/UPDATE_USER_PENDING";
export const UPDATE_USER_SUCCESS = "USER_MANAGEMENT/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "USER_MANAGEMENT/UPDATE_USER_FAILURE";

export const GET_DEPARTMENTS_PENDING = "USER_MANAGEMENT/GET_DEPARTMENTS_PENDING";
export const GET_DEPARTMENTS_SUCCESS = "USER_MANAGEMENT/GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "USER_MANAGEMENT/GET_DEPARTMENTS_FAILURE";

export const GET_USER_GROUPS_PENDING = "USER_MANAGEMENT/GET_USER_GROUPS_PENDING";
export const GET_USER_GROUPS_SUCCESS = "USER_MANAGEMENT/GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_FAILURE = "USER_MANAGEMENT/GET_USER_GROUPS_FAILURE";
