import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { capitalize, isNumber } from "../../../../../utils";
import styles from "./FormDatePeriod.module.scss";
import { DisabledFieldLock } from "../../";

export default function FormDatePeriod({
  className,
  value,
  period,
  readOnly,
  disabled,
  onChange,
  showLock,
  tooltip,
  onLockClick,
}) {
  const handleValueChange = (e) => {
    if (!isNumber(e.target.value)) {
      return;
    }

    onChange && onChange({ value: e.target.value, period });
  };

  const handlePeriodChange = (e) => {
    onChange && onChange({ value, period: e.target.value });
  };

  const renderPeriod = () => {
    if (readOnly) {
      return (
        <div
          className={classnames(
            styles.inputContainer,
            readOnly && styles.readOnly,
            disabled && styles.disabled,
          )}
        >
          {capitalize(period)}
        </div>
      );
    }

    return (
      <select
        className={classnames(
          styles.select,
          readOnly && styles.readOnly,
          disabled && styles.disabled,
        )}
        value={period}
        onChange={handlePeriodChange}
      >
        <option value="months">Months</option>
        <option value="years">Years</option>
      </select>
    );
  };

  const renderInput = () => {
    return (
      <div
        className={classnames(
          styles.inputContainer,
          readOnly && styles.readOnly,
          disabled && styles.disabled,
        )}
      >
        <input
          className={styles.inputText}
          type="text"
          value={value}
          onChange={handleValueChange}
          disabled={disabled}
        />
      </div>
    );
  };

  const renderLock = () => {
    return showLock && (
      <DisabledFieldLock tooltip={tooltip} onClick={onLockClick} />
    );
  };

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.inputs}>
        {renderInput()}
        {renderPeriod()}
        {renderLock()}
      </div>
    </div>
  );
}

FormDatePeriod.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  period: PropTypes.oneOf(["months", "years"]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  showLock: PropTypes.bool,
  tooltip: PropTypes.any,
  onChange: PropTypes.func,
  onLockClick: PropTypes.func,
};

FormDatePeriod.defaultProps = {
  value: 0,
  period: "months",
};
