import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ConvertToAgencyPage from "./ConvertToAgencyPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
  data: ownProps.location?.state?.data,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    onFetchProspect: actions.getProspectToBeConverted,
    onCheckShortCodeExists: actions.checkShortCodeExists,
  }, dispatch),
  onViewExistingAgencyShortCodes: (prospectId, data) => {
    dispatch(push("/onboarding/new/existing-short-codes", {
      from: `/convert-to-agency/${prospectId}`,
      data,
    }));
  },
  onSubmit: (prospectId, data) => {
    dispatch(actions.convertToAgency({ prospectId, data }));
  },
  onCancel: (prospectId) => {
    dispatch(push(`/prospects/${prospectId}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConvertToAgencyPage);
