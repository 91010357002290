import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Squares as ActivityIndicator } from "react-activity";
import { Dropdown, Popup } from "semantic-ui-react";
import {
  CloseRounded,
  InsertDriveFileRounded,
  ExpandMoreRounded,
  ErrorOutlineRounded,
} from "@material-ui/icons";
import { formatBytes } from "../../../../utils";
import styles from "./DocumentUploadCard.module.scss";

export default function DocumentUploadCard(props) {
  const [selectedDocType, setSelectedDocType] = useState(null);


  const onDocumentTypeSelected = (documentType) => {
    setSelectedDocType(documentType);
    props.onDocumentTypeSelected(documentType, props.file);
  };

  return (
    <div
      className={classnames(
        props.className,
        styles.container,
        props.error && styles.error,
        props.disabled && styles.disabled,
      )}>
      <div className={styles.fileContainer}>
        <InsertDriveFileRounded className={styles.fileIcon} />
        <div>
          <div className={styles.fileName}>
            {props.file.name}
          </div>
          <div className={styles.fileSize}>
            {formatBytes(props.file.size)}
          </div>
          <Dropdown
            trigger={
              <>
                <div className={styles.dropdownTitle}>
                  {selectedDocType?.type ?? "Select Document Type"}
                </div>
                <ExpandMoreRounded className={styles.dropdownIcon} />
              </>
            }
            className={styles.dropdown}
            icon={null}
            floating
            scrolling
            direction="right">
            <Dropdown.Menu>
              {props.loading ? <ActivityIndicator /> : (
                <>
                  <Dropdown.Header />
                  {props.documentTypes?.map(documentType => (
                    <Dropdown.Item
                      key={documentType.id}
                      text={documentType.type}
                      value={documentType.id}
                      onClick={() => onDocumentTypeSelected(documentType)}/>
                  ))}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {props.error && (
        <Popup
          trigger={(<ErrorOutlineRounded className={styles.errorIcon}/>)}
          position="left center"
          on="hover"
          popper={{ style: { zIndex: 2000 } }}>
          <Popup.Content>
            {props.error}
          </Popup.Content>
        </Popup>
      )}
      <CloseRounded className={styles.deleteIcon} onClick={() => props.onDelete(props.file)} />
    </div>
  );
}

DocumentUploadCard.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  documentTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
  })),
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onDocumentTypeSelected: PropTypes.func,
};
