import React from "react";
import PropTypes from "prop-types";
import {
  Heading,
  Button,
} from "../../../common/components";
import { SelfBillingOrganisationList } from "../../components";
import styles from "./SelfBillingOrganisationsPage.module.scss";

export default function SelfBillingOrganisationsPage(props) {
  return (
    <div className={styles.container}>
      <Heading
        title="Self-Billing Organisations"
        rightAccessory={(
          <Button
            variant="primary"
            onClick={props.onAddNewClick}
          >
            Add Self-Billing Organisation
          </Button>
        )}
      />
      <SelfBillingOrganisationList
        data={props.selfBillingOrganisations.data}
        loading={props.selfBillingOrganisations.loading}
      />
    </div>
  );
}

SelfBillingOrganisationsPage.propTypes = {
  selfBillingOrganisations: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.array,
  }),
  onAddNewClick: PropTypes.func.isRequired,
};
