import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import { Form, IconButton } from "../../../common/components";
import { getUsersName } from "../../../../utils";
import styles from "./AccountContactDetails.module.scss";

export default function AccountContactDetails(props) {
  const contact = props.accountContact ?? {};

  return (
    <Form.Card
      title="Account Contact & Address"
      variant="h2"
      className={styles.card}
      rightAccessory={
        <IconButton
          icon={
            <div className={styles.edit}>
              <Edit /> Edit
            </div>
          }
          // onClick={props.onEditClick}
        />
      }
    >
      <Form.Group inline>
        <Form.Label>
          Account Contact Full Name (FAO)
        </Form.Label>
        <Form.Label className={styles.value}>
          {getUsersName(contact) ?? "-"}
        </Form.Label>
      </Form.Group>

      <Form.Group inline>
        <Form.Label>
          Telephone Number
        </Form.Label>
        <Form.Label className={styles.value}>
          {contact.telephone_number ?? "-"}
        </Form.Label>
      </Form.Group>

      <Form.Group inline>
        <Form.Label>
          Email Address
        </Form.Label>
        <Form.Label className={styles.value}>
          {contact.email_address ?? "-"}
        </Form.Label>
      </Form.Group>

      <Form.Group
        className={styles.addressFormGroup}
        inline
      >
        <Form.Label>
          Address
        </Form.Label>
        <Form.Label className={styles.value}>
          <div>
            {contact.address_line_1}
          </div>
          {contact.address_line_2 && (
            <div>
              {contact.address_line_2}
            </div>
          )}
          <div>
            {contact.town_or_city}
          </div>
          {contact.county && (
            <div>
              {contact.county}
            </div>
          )}
          <div>
            {contact.postcode}
          </div>
        </Form.Label>
      </Form.Group>
    </Form.Card>
  );
}

AccountContactDetails.propTypes = {
  accountContact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    telephone_number: PropTypes.string,
    email_address: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    town_or_city: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
  onEditClick: PropTypes.func.isRequired,
};
