import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { Modal as SemanticModal } from "semantic-ui-react";
import { Button } from "../";
import styles from "./Modal.module.scss";

export default function Modal(props) {
  const { className, ...otherProps } = props;

  return (
    <SemanticModal
      {..._.pick(Object.keys(SemanticModal.propTypes), otherProps)}
      className={classnames(styles.container, className)}
      open={props.visible}
      dimmer={{
        className: styles.dimmer,
      }}
      centered
    >
      <SemanticModal.Header className={styles.title}>
        {props.title}
        {props.subtitle && (
          <div className={styles.subtitle}>
            {props.subtitle}
          </div>
        )}
      </SemanticModal.Header>
      <SemanticModal.Content scrolling={props.scrolling}>
        {props.children}
      </SemanticModal.Content>
      <SemanticModal.Actions
        className={classnames(
          styles.actions,
          styles[props.buttonPosition]
        )}
      >
        {props.onCancel && (
          <Button variant={props.cancelVariant || "outline-danger"} onClick={props.onCancel}>
            {props.cancelText}
          </Button>
        )}
        {props.onSubmit && (
          <Button variant={props.submitVariant || "primary"} disabled={props.primaryButtonDisabled} busy={props.busy} onClick={props.onSubmit}>
            {props.submitText}
          </Button>
        )}
      </SemanticModal.Actions>
    </SemanticModal>
  );
}

Modal.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  scrolling: PropTypes.bool.isRequired,
  busy: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  cancelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  submitVariant: PropTypes.string,
  cancelVariant: PropTypes.string,
  className: PropTypes.string,
  buttonPosition: PropTypes.oneOf([
    "left",
    "middle",
    "right",
  ]),
  primaryButtonDisabled: PropTypes.bool,
};

Modal.defaultProps = {
  submitText: "Submit",
  cancelText: "Cancel",
  buttonPosition: "middle",
  primaryButtonDisabled: false,
  scrolling: false,
};
