import { VerifyRegisteredAgencyCustomerModal } from "../../modals";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 * @param {object} obj.companiesHouse
 * @param {string} obj.companiesHouse.registeredCompanyNumber
 * @param {string} obj.companiesHouse.exactLegalName
 *
 * @returns
 */
export const showVerifyRegisteredAgencyCustomerModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(VerifyRegisteredAgencyCustomerModal.displayName, {
    agencyCustomerId: obj.agencyCustomerId,
    companiesHouse: obj.companiesHouse,
  }));
};
