import { InsufficientUndistributedCreditModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityName
 * @param {number} obj.undistributedCredit
 * @param {number} obj.requestedAmount
 *
 * @returns
 */
export const showInsufficientUndistributedCreditModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(InsufficientUndistributedCreditModal.displayName, {
    legalEntityName: obj.legalEntityName,
    undistributedCredit: obj.undistributedCredit,
    requestedAmount: obj.requestedAmount,
    onSubmit: () => {
      dispatch(modalActions.hideModal(InsufficientUndistributedCreditModal.displayName));
    },
  }));
};
