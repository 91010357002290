import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import AutoEnrolmentPensionsFormValidator from "./AutoEnrolmentPensionsFormValidator";
import {
  Alert,
  Button,
  ButtonGroup,
  DisabledFieldLock,
  Form,
} from "../../../../common/components";
import { List } from "../../../../lists/containers";
import { tooltips } from "../../../../../constants";
import styles from "./AutoEnrolmentPensionsForm.module.scss";

const EDIT_DISABLED_FIELD_CHECK = "auto_enrolment_pensions.pension_company";

export default class AutoEnrolmentPensionsForm extends Component {

  static propTypes = {
    data: PropTypes.shape({
      is_new_agency: PropTypes.bool.isRequired,
      pension_company: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      pension_scheme_weekly: PropTypes.string,
      pension_scheme_monthly: PropTypes.string,
      staging_date: PropTypes.number,
      payment_source_reference_weekly: PropTypes.string,
      payment_source_reference_monthly: PropTypes.string,
      default_postponement_months: PropTypes.number,
      eligible_job_holder_postponement_months: PropTypes.number,
      employer_pension_reference: PropTypes.string,
      payroll_frequency: PropTypes.oneOf(["weekly", "monthly", "both"]),
      previous_pension_provider: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        agency_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }),
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        pension_company: props.data?.pension_company,
        pension_scheme_weekly: props.data?.pension_scheme_weekly,
        pension_scheme_monthly: props.data?.pension_scheme_monthly,
        staging_date: props.data?.staging_date,
        payment_source_reference_weekly: props.data?.payment_source_reference_weekly,
        payment_source_reference_monthly: props.data?.payment_source_reference_monthly,
        default_postponement_months: props.data?.default_postponement_months,
        eligible_job_holder_postponement_months: props.data?.eligible_job_holder_postponement_months,
        employer_pension_reference: props.data?.employer_pension_reference,
        previous_pension_provider: props.data?.previous_pension_provider,
        is_new_agency: props.data?.is_new_agency,
      },
      errors: {},
    };
  }

  render() {
    const isDisabled = this.isEditDisabled();

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={12}>
          {isDisabled && (
            <Alert className={styles.onboardingAlert} variant="info" showClose={false}>
              <div className={styles.onboardingAlertContent}>
                <DisabledFieldLock
                  className={styles.lockIcon}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  onClick={() => this.handleDisabledFieldClick(EDIT_DISABLED_FIELD_CHECK)}
                />
                The Onboarding Task for this has not been completed yet.
              </div>
            </Alert>
          )}
          <Form className={styles.form}>
            <Form.Section title="Auto-Enrolment Pensions" titleClassName={styles.title}>
              {!this.props.data.is_new_agency &&
                <Form.Group
                  className={classnames(this.props.submitting && styles.submitting)}
                  inline
                  wide
                  error={this.state.errors?.previous_pension_provider}
                >
                  <Form.Label inline>
                    Previous Pension Provider
                  </Form.Label>
                  <Form.Value>
                    <List
                      identifier="previous_pension_company"
                      name="previous_pension_provider"
                      size="medium"
                      value={this.state.data.previous_pension_provider?.value}
                      disabled={isDisabled}
                      highlightError={this.state.errors?.previous_pension_provider}
                      onChange={this.handleListValueChange}
                    />
                  </Form.Value>
                </Form.Group>
              }
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.pension_company}
              >
                <Form.Label inline>
                  Pension Company
                </Form.Label>
                <Form.Value>
                  <List
                    identifier="pension_company"
                    size="medium"
                    value={this.state.data.pension_company?.value}
                    disabled={isDisabled}
                    highlightError={this.state.errors?.pension_company}
                    onReady={this.handlePensionCompanyReady}
                    onChange={this.handleListValueChange}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.staging_date}
              >
                <Form.Label inline>
                  Staging Date
                </Form.Label>
                <Form.Value>
                  <Form.Date
                    name="staging_date"
                    value={this.state.data.staging_date}
                    disabled={isDisabled}
                    highlightError={this.state.errors?.staging_date}
                    onChange={this.handleInputChange}
                  />
                </Form.Value>
              </Form.Group>
              {this.canShowWeeklyFields() && (
                <Form.Group
                  className={classnames(this.props.submitting && styles.submitting)}
                  inline
                  wide
                  error={this.state.errors?.pension_scheme_weekly}
                >
                  <Form.Label inline>
                    Pension Scheme (Weekly)
                  </Form.Label>
                  <Form.Value>
                    <Form.Input
                      name="pension_scheme_weekly"
                      value={this.state.data.pension_scheme_weekly}
                      maxLength={6}
                      disabled={isDisabled}
                      highlightError={this.state.errors?.pension_scheme_weekly}
                      onChange={this.handleInputChange}
                    />
                  </Form.Value>
                </Form.Group>
              )}
              {this.canShowMonthlyFields() && (
                <Form.Group
                  className={classnames(this.props.submitting && styles.submitting)}
                  inline
                  wide
                  error={this.state.errors?.pension_scheme_monthly}
                >
                  <Form.Label inline>
                    Pension Scheme (Monthly)
                  </Form.Label>
                  <Form.Value>
                    <Form.Input
                      name="pension_scheme_monthly"
                      value={this.state.data.pension_scheme_monthly}
                      maxLength={6}
                      disabled={isDisabled}
                      highlightError={this.state.errors?.pension_scheme_monthly}
                      onChange={this.handleInputChange}
                    />
                  </Form.Value>
                </Form.Group>
              )}
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.employer_pension_reference}
              >
                <Form.Label inline>
                  Employer Pension Reference
                </Form.Label>
                <Form.Value>
                  <Form.Input
                    name="employer_pension_reference"
                    value={this.state.data.employer_pension_reference}
                    maxLength={30}
                    disabled={isDisabled}
                    highlightError={this.state.errors?.employer_pension_reference}
                    onChange={this.handleInputChange}
                  />
                </Form.Value>
              </Form.Group>
              {this.canShowWeeklyFields() && this.isNest() && (
                <Form.Group
                  className={classnames(this.props.submitting && styles.submitting)}
                  inline
                  wide
                  error={this.state.errors?.payment_source_reference_weekly}
                >
                  <Form.Label inline>
                    Payment Source Reference (Weekly)
                  </Form.Label>
                  <Form.Value>
                    <Form.Input
                      name="payment_source_reference_weekly"
                      value={this.state.data.payment_source_reference_weekly}
                      maxLength={255}
                      disabled={isDisabled}
                      highlightError={this.state.errors?.payment_source_reference_weekly}
                      onChange={this.handleInputChange}
                    />
                  </Form.Value>
                </Form.Group>
              )}
              {this.canShowMonthlyFields() && this.isNest() && (
                <Form.Group
                  className={classnames(this.props.submitting && styles.submitting)}
                  inline
                  wide
                  error={this.state.errors?.payment_source_reference_monthly}
                >
                  <Form.Label inline>
                    Payment Source Reference (Monthly)
                  </Form.Label>
                  <Form.Value>
                    <Form.Input
                      name="payment_source_reference_monthly"
                      value={this.state.data.payment_source_reference_monthly}
                      maxLength={255}
                      disabled={isDisabled}
                      highlightError={this.state.errors?.payment_source_reference_monthly}
                      onChange={this.handleInputChange}
                    />
                  </Form.Value>
                </Form.Group>
              )}
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.default_postponement_months}
              >
                <Form.Label inline>
                  Default Postponement Months
                </Form.Label>
                <Form.Value>
                  <Form.Number
                    name="default_postponement_months"
                    value={this.state.data.default_postponement_months}
                    disabled={isDisabled}
                    highlightError={this.state.errors?.default_postponement_months}
                    onChange={this.handleInputChange}
                  />
                </Form.Value>
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.eligible_job_holder_postponement_months}
              >
                <Form.Label inline>
                  Eligible Job Holder Postponement Months
                </Form.Label>
                <Form.Value>
                  <Form.Number
                    name="eligible_job_holder_postponement_months"
                    value={this.state.data.eligible_job_holder_postponement_months}
                    disabled={isDisabled}
                    highlightError={this.state.errors?.eligible_job_holder_postponement_months}
                    onChange={this.handleInputChange}
                  />
                </Form.Value>
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              disabled={this.props.submitting || isDisabled}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handlePensionCompanyReady = (list) => {
    if (!this.state.data.pension_company) {
      this.setState({
        data: {
          ...this.state.data,
          pension_company: list.data?.values?.find(x => x.is_default) || null,
        },
      });
    }
  }

  handleListValueChange = (e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: item,
      },
    });
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const data = this.updateWeeklyAndMonthlyData({
      ...this.state.data,
      pension_company_id: +this.state.data.pension_company?.id,
      default_postponement_months: +this.state.data.default_postponement_months,
      eligible_job_holder_postponement_months: +this.state.data.eligible_job_holder_postponement_months,
    });

    // We should only hit this if someone removes the disabled attribute from
    // the Submit button manually...
    if (this.isEditDisabled()) {
      return;
    }

    const validator = new AutoEnrolmentPensionsFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit({
      ...data,
      pension_company_id: +data.pension_company?.id,
      previous_pension_provider_id: +data.previous_pension_provider?.id,
    }, this.props);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick(disabledField);
  }

  isEditDisabled = () => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === EDIT_DISABLED_FIELD_CHECK);
  }

  canShowWeeklyFields = () => {
    return ["weekly", "both"].includes(this.props.data.payroll_frequency);
  }

  canShowMonthlyFields = () => {
    return ["monthly", "both"].includes(this.props.data.payroll_frequency);
  }

  isNest = () => {
    const { data: { pension_company } } = this.state;

    return pension_company?.value === "nest";
  }

  updateWeeklyAndMonthlyData = (data) => {
    switch (this.props.data.payroll_frequency) {
      case "weekly":
        return {
          ...data,
          pension_scheme_monthly: null,
          payment_source_reference_monthly: null,
        };

      case "monthly":
        return {
          ...data,
          pension_scheme_weekly: null,
          payment_source_reference_weekly: null,
        };

      case "both":
        return data;

      default:
        return {
          ...data,
          pension_scheme_weekly: null,
          pension_scheme_monthly: null,
          payment_source_reference_weekly: null,
          payment_source_reference_monthly: null,
        };
    }
  }

}

