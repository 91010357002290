import _ from "lodash";

export default class AgencyDetailsFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isEmpty(data?.known_as)) {
      errors = _.set(errors, "known_as", "Known As is required");
    }

    if (_.isEmpty(data?.correspondence_name)) {
      errors = _.set(errors, "correspondence_name", "Exact Legal Name is required");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
