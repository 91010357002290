import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Dropdown } from "semantic-ui-react";
import styles from "./LinkDropdownItem.module.scss";

export default function LinkDropdownItem({ className, ...otherProps }) {
  return (
    <Dropdown.Item className={classnames(className, styles.container)} {...otherProps} />
  );
}

LinkDropdownItem.propTypes = {
  className: PropTypes.string,
};
