import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { isNumber } from "../../../../../utils";
import styles from "./ContactInformationForm.module.scss";

export default class ContactInformationForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      meta: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string,
      })),
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        telephone: props.data?.meta?.find(m => m.key === "telephone")?.value || "",
        website: props.data?.meta?.find(m => m.key === "website")?.value || "",
      },
    };
  }

  render() {
    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={14}>
          <Form className={styles.form}>
            <Form.Section title="Contact Information" titleClassName={styles.title}>
              <Form.Group inline wide>
                <Form.Label>Telephone Number</Form.Label>
                <Form.Input
                  name="telephone"
                  type="text"
                  value={this.state.data.telephone}
                  onChange={this.handleTelephoneChange}
                  maxLength={30}
                />
              </Form.Group>
              <Form.Group inline wide>
                <Form.Label>Website</Form.Label>
                <Form.Input
                  name="website"
                  type="text"
                  value={this.state.data.website}
                  size="large"
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              disabled={this.props.submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleTelephoneChange = (e) => {
    if (!isNumber(e.target.value)) {
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    onSubmit && onSubmit(data);
  }

}
