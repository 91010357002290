import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_TRADING_ADDRESS = gql`
mutation(
  $agency_id: ID!,
  $agency_trading_address: UpdateAgencyInput,
) {
  updateAgency(id: $agency_id, agency_input: $agency_trading_address) {
    id
  }
}
`;

export const updateAgencyTradingAddress = (agencyId, companyInfo) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_TRADING_ADDRESS_PENDING,
    });

    const response = await apolloClient.query({
      query: UPDATE_AGENCY_TRADING_ADDRESS,
      variables: {
        agency_id: agencyId,
        agency_trading_address: companyInfo.agencyTradingAddress,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_TRADING_ADDRESS_SUCCESS,
      payload: {
        agency: {
          agencyTradingAddress: response.data.agencyTradingAddress,
        },
      },
    });

    dispatch(push(`/agencies/${agencyId}/agency`));
  }
  catch (error) {
    dispatch({
      type: types.UPDATE_AGENCY_TRADING_ADDRESS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
