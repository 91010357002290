import React, { Component } from "react";
import PropTypes from "prop-types";
import CompaniesHouseSearchFormValidator from "./CompaniesHouseSearchFormValidator";
import { Button, Form } from "../../../common/components";
import styles from "./CompaniesHouseSearchForm.module.scss";

export default class CompaniesHouseSearchForm extends Component {

  static propTypes = {
    busy: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
  }

  state = {
    data: {
      registeredCompanyNumber: "",
      exactLegalName: "",
    },
    errors: {},
  }

  render() {
    return (
      <Form className={styles.container}>
        <Form.Section title="Companies House" variant="h3">
          <Form.Group error={this.state.errors?.registeredCompanyNumber}>
            <Form.Label>Registered Company Number</Form.Label>
            <Form.Input
              size="small"
              name="registeredCompanyNumber"
              value={this.state.data.registeredCompanyNumber}
              maxLength={9}
              onChange={this.handleInputChanged}
              highlightError={this.state.errors?.registeredCompanyNumber}
              disabled={this.props.busy}
            />
          </Form.Group>
          <Form.Group error={this.state.errors?.exactLegalName}>
            <Form.Label>Exact Legal Name</Form.Label>
            <Form.Input
              size="medium"
              name="exactLegalName"
              value={this.state.data.exactLegalName}
              maxLength={255}
              onChange={this.handleInputChanged}
              highlightError={this.state.errors?.exactLegalName}
              disabled={this.props.busy}
            />
          </Form.Group>
        </Form.Section>
        <Form.Group>
          <Button
            variant="primary"
            busy={this.props.busy}
            onClick={this.handleSearchClicked}
          >
            Search Companies House
          </Button>
        </Form.Group>
      </Form>
    );
  }

  handleInputChanged = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSearchClicked = () => {
    const { onSearch } = this.props;

    const validator = new CompaniesHouseSearchFormValidator(this.state.data);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSearch && onSearch(this.state.data);
  }

}
