import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Form } from "../../../common/components";
import styles from "./ResetPasswordForm.module.scss";

class ResetPasswordForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    submitting: PropTypes.bool,
    email: PropTypes.string,
    onSendVerificationCode: PropTypes.func.isRequired,
  }

  state = {
    email: "",
  }

  render() {
    const { className, submitting } = this.props;
    const { email } = this.state;

    return (
      <Form className={classnames(className, styles.container)}>
        <Form.Group className={styles.field}>
          <Form.Label htmlFor="email">
            Email Address
          </Form.Label>
          <Form.Input
            type="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={this.handleInputChange}
            fluid
          />
        </Form.Group>
        <Button
          className={styles.submitButton}
          onClick={this.handleGetVerificationCode}
          disabled={submitting || !email}
          busy={submitting}
        >
          Reset Password
        </Button>
      </Form>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleGetVerificationCode = () => {
    const { onSendVerificationCode } = this.props;
    const { email } = this.state;

    onSendVerificationCode(email);
  }
}

export default ResetPasswordForm;
