import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const GET_COMPANY_HOUSE_COMPANY_PROFILE = gql`
  query($company_number: String!) {
    getCompaniesHouseCompanyProfile(companyNumber: $company_number) {
      company_name
      company_number
      company_status
      company_status_detail
      type
      date_of_creation

      accounts {
        last_accounts {
          made_up_to
        }
      }

      links {
        self
      }

      previous_company_names {
        name
        effective_from
      }

      registered_office_address {
        address_line_1
        address_line_2
        locality
        region
        postal_code
        country
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.companyNumber
 * @returns
 */
export const getCompaniesHouseCompanyProfile = ({ companyNumber }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_COMPANY_HOUSE_COMPANY_PROFILE,
      variables: {
        company_number: companyNumber,
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    dispatch({
      type: types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_SUCCESS,
      payload: {
        data: response.data.getCompaniesHouseCompanyProfile,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
