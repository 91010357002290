import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_AGENCY_CUSTOMER_REQUESTS = gql`
query(
  $legal_entity_id: ID!
  $sort_order: [SortOrder]
) {
  getLegalEntity(id: $legal_entity_id) {
    id

    agency_customer_requests(
      filter_options: { verified: false }
      pager: { page: 1, limit: 1000 }
      sort_order: $sort_order
    ) {
      id
      registered_company_number
      exact_legal_name
      credit_limit_requested
      created_at

      agency {
        id
        known_as

        extended {
          logo_url
        }
      }

      prospect {
        id
        known_as

        extended {
          logo_url
        }
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {object[]} obj.sortOrder
 * @returns
 */
export const getAgencyCustomerRequests = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_CUSTOMER_REQUESTS_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_AGENCY_CUSTOMER_REQUESTS,
      variables: {
        legal_entity_id: obj.legalEntityId,
        sort_order: SortOrderConverter(obj.sortOrder || {}),
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_CUSTOMER_REQUESTS_SUCCESS,
      payload: {
        data: response.data.getLegalEntity.agency_customer_requests,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_CUSTOMER_REQUESTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
