import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import ModalManager from "./ModalManager";

const mapStateToProps = state => ({
  modals: state.modals,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
