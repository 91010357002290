import { Auth } from "aws-amplify";
import { push } from "connected-react-router";
import * as types from "./types";
import { actions } from "../../currentUser/duck";
import { REACT_APP_INACTIVITY_TIMEOUT_DURATION } from "../../../config";

export const login = credentials => async (dispatch) => {
  try {
    dispatch({
      type: types.LOGIN_PENDING,
    });

    const user = await Auth.signIn(credentials);

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      await Auth.completeNewPassword(user, "P455w0rd!", { name: "Admin User" });
    }

    // Set initial timeout expiration for inactivity
    const newExpiration = Date.now() + REACT_APP_INACTIVITY_TIMEOUT_DURATION * 60 * 1000;
    localStorage.setItem("_idleLogoutTime", newExpiration);

    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: {
        user,
      },
    });

    await actions.getCurrentUser()(dispatch);

    dispatch(push("/dashboard"));
  }
  catch (err) {
    let errorMessage;

    switch (err.code) {
      case "NotAuthorizedException":
      case "UserNotFoundException":
        errorMessage = "Incorrect email or password";
        break;

      default:
        errorMessage = "Something went wrong. Please try again later";
    }

    dispatch({
      type: types.LOGIN_FAILURE,
      payload: {
        error: errorMessage,
        actualError: err,
      },
    });
  }
};

export const sendResetPasswordCode = email => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_RESET_PASSWORD_CODE_PENDING,
    });

    await Auth.forgotPassword(email);

    dispatch({
      type: types.GET_RESET_PASSWORD_CODE_SUCCESS,
      payload: {
        email,
      },
    });
  }
  catch (err) {
    let errorMessage = "Something went wrong. Please try again later";

    switch (err.code) {
      case "UserNotFoundException":
        errorMessage = "Email is not registered";
        break;

      default:
        errorMessage = err.message;
        break;
    }

    dispatch({
      type: types.GET_RESET_PASSWORD_CODE_FAILURE,
      payload: {
        error: errorMessage,
        actualError: err,
      },
    });
  }
};

export const verifyResetPasswordCode = (email, newPassword, verificationCode) => async (dispatch) => {
  try {
    dispatch({
      type: types.VERIFY_RESET_PASSWORD_CODE_PENDING,
    });

    await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);

    dispatch({
      type: types.VERIFY_RESET_PASSWORD_CODE_SUCCESS,
    });
    dispatch(push("/"));
  }
  catch (err) {
    let errorMessage = "Something went wrong. Please try again later";

    switch (err.code) {
      case "UserNotFoundException":
        errorMessage = "Email is not registered";
        break;

      default:
        errorMessage = err.message;
        break;
    }

    dispatch({
      type: types.VERIFY_RESET_PASSWORD_CODE_FAILURE,
      payload: {
        error: errorMessage,
        actualError: err,
      },
    });
  }
};
