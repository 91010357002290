import _ from "lodash";

export default class ResetPasswordCodeFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (_.isEmpty(data?.newPassword)) {
      errors.newPassword = "Password cannot be empty";
    }
    else if (data?.newPassword.length < 8) {
      errors.newPassword = "Your password must be at least 8 characters";
    }
    else if (!/[a-z]/.test(data?.newPassword)) {
      errors.newPassword = "Your password must include at least one lowercase character";
    }
    else if (!/[A-Z]/.test(data?.newPassword)) {
      errors.newPassword = "Your password must include at least one uppercase character";
    }
    else if (!/[^A-Za-z0-9]/.test(data?.newPassword)) {
      errors.newPassword = "Your password must include at least one special character";
    }
    else if (data?.confirmNewPassword !== data?.newPassword) {
      errors.confirmNewPassword = "Password does not match";
    }

    if (_.isEmpty(data?.verificationCode)) {
      errors.verificationCode = "Verification Code cannot be empty";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
