import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { VatInformationForm } from "../../components";
import styles from "./EditVatInformationPage.module.scss";

export default class EditVatInformationPage extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    vatInformation: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.object,
      submitting: PropTypes.bool,
    }),
    onFetchVatInformation: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { prospectId, onFetchVatInformation } = this.props;

    await onFetchVatInformation({ prospectId });
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.vatInformation?.loading ? (
          <ActivityIndicator />
        ) : (
          <VatInformationForm
            data={this.props.vatInformation?.data}
            submitting={this.props.vatInformation?.submitting}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onDisabledFieldClick={this.handleDisabledFieldClick}
          />
        )}
      </div>
    );
  }

  handleCancel = async () => {
    const { prospectId, onCancel } = this.props;

    await onCancel(prospectId);
  }

  handleSubmit = async (data) => {
    const { prospectId, onSubmit } = this.props;

    await onSubmit({ prospectId, data });
  }

  handleDisabledFieldClick = async (disabledField) => {
    const { prospectId, onDisabledFieldClick } = this.props;

    await onDisabledFieldClick({ prospectId, disabledField });
  }

}
