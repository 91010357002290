
import React from "react";
import PropTypes from "prop-types";
import { Divider } from "semantic-ui-react";
import Logo from "../../../../assets/images/logo.png";
import { ConfirmationModal } from "../../../common/components";
import styles from "./ChangePasswordSuccessModal.module.scss";

export default function ChangePasswordSuccessModal(props) {
  const handleClose = () => {
    props.hideModal(ChangePasswordSuccessModal.displayName);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      visible
      title=""
      onSubmit={handleClose}
      submitText="Close"
    >
      <div className={styles.header}>
        <img src={Logo} className={styles.logo} alt=""/>
        <div className={styles.title}>
          Password successfully changed!
        </div>
      </div>
      <Divider />
    </ConfirmationModal>
  );
}

ChangePasswordSuccessModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

ChangePasswordSuccessModal.displayName = "ChangePasswordSuccessModal";
