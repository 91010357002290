import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_CONTRACT = gql`
  query($agency_customer_id: ID!, $contract_id: ID!) {
    getAgencyCustomer(id: $agency_customer_id) {
      contract(id: $contract_id) {
        id
        display_name
        trading_as
        assignment
        is_purchase_order_number_required
        agreed_payment_terms_type
        agreed_payment_terms_value

        account_contact {
          id
          first_name
          last_name
          telephone_number
          email_address
          address_line_1
          address_line_2
          town_or_city
          county
          postcode
        }

        invoice_contact {
          id
          first_name
          last_name
          telephone_number
          email_address
          address_line_1
          address_line_2
          town_or_city
          county
          postcode
        }

        agency_customer {
          agency {
            id
            known_as
            short_code
          }
          prospect {
            id
            known_as
          }
        }
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 * @param {string} obj.contractId
 * @returns
 */
export const getContract = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CONTRACT_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_CONTRACT,
      variables: {
        agency_customer_id: obj.agencyCustomerId,
        contract_id: obj.contractId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CONTRACT_SUCCESS,
      payload: {
        data: response.data.getAgencyCustomer.contract,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CONTRACT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
