import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faQuestionCircle, faMinusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "./StatusIcon.scss";

export default class StatusIcon extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    status: PropTypes.oneOf(["active", "inactive", "disabled", "pending", "unknown"]).isRequired,
  }

  renderIcon = () => {
    switch (this.props.status) {
      case "active":
        return (
          <div className="active icon">
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
            <span>Active</span>
          </div>
        );

      case "inactive":
        return (
          <div className="inactive icon">
            <FontAwesomeIcon icon={faTimesCircle} size="2x" />
            <span>In-Active</span>
          </div>
        );

      case "disabled":
        return (
          <div className="inactive icon">
            <FontAwesomeIcon icon={faTimesCircle} size="2x" />
            <span>Disabled</span>
          </div>
        );

      case "pending":
        return (
          <div className="pending icon">
            <FontAwesomeIcon icon={faMinusCircle} size="2x" />
            <span>On-Hold</span>
          </div>
        );

      default:
        return (
          <div className="unknown icon">
            <FontAwesomeIcon icon={faQuestionCircle} size="2x" />
            <span>Unknown</span>
          </div>
        );
    }
  }

  render() {
    const { className, style } = this.props;

    return (
      <div
        className={classnames("StatusIcon", className)}
        style={style}
      >
        {this.renderIcon()}
      </div>
    );
  }

}
