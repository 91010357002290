import gql from "graphql-tag";

export const GET_LEGAL_ENTITIES = gql`
query($pager: Pager) {
  getLegalEntities(pager: $pager) {
    data {
      id
      name
      company_type
      company_number
      company_status
      credit_limit_excluding_vat
      credit_limit_including_vat
      undistributed_credit_limit
    }
    total
  }
}
`;

export const GET_COMPANY_HOUSE_COMPANY_PROFILE = gql`
query($company_number: String!) {
  getCompaniesHouseCompanyProfile(companyNumber: $company_number) {
    company_name
    type
    company_number
    accounts {
      last_accounts {
        made_up_to
      }
    }
    links {
      self
    }
    previous_company_names {
      name
      ceased_on
      effective_from
    }
    company_status
    company_status_detail
    registered_office_address {
      region
      postal_code
      locality
      address_line_2
      country
      address_line_1
    }
  }
}
`;

export const SEARCH_LEGAL_ENTITIES = gql`
query($pager: Pager!, $search_options: LegalEntitySearchOptions!, $sort_order: [SortOrder]) {
  searchLegalEntities(pager: $pager, search_options: $search_options, sort_order: $sort_order) {
    data {
      id
      name
      company_type
      credit_limit_excluding_vat
      credit_limit_including_vat
      undistributed_credit_limit
      __typename
      active_suspension {
        date_of_effect
      }

      ...on RegisteredCompany {
        company_number
        company_status
        inactive_since
        registered_charity_number
      }

      ...on RegisteredCharity {
        company_number
        company_status
        inactive_since
        registered_charity_number
      }

      ...on Charity {
        registered_charity_number
      }
    }
    total
  }
}
`;

export const ADD_LEGAL_ENTITY = gql`
mutation($input: AddLegalEntityInput!) {
  addLegalEntity(input: $input) {
    id
  }
}
`;

export const GET_CREDIT_DETAILS = gql`
query($id: ID!) {
  getLegalEntity(id: $id) {
    credit_limit_excluding_vat
    credit_limit_including_vat
    undistributed_credit_limit
    total_credit_applications
    active_suspension {
      date_of_effect
    }

    ...on RegisteredCompany {
      company_number
      companies_house_info {
        accounts {
          next_due
        }
      }
    }
  }
}
`;

export const GET_CREDIT_APPLICATION = gql`
query($legal_entity_id: ID!, $credit_application_id: ID!) {
  getLegalEntityCreditApplication(
    legal_entity_id: $legal_entity_id
    credit_application_id: $credit_application_id
  ) {
    id
    source
    reference_number
    status
    credit_limit_requested
    approved_credit_excluding_vat
    date_of_request
    date_of_result
    created_at
    completed_at
    completed_by {
      first_name
      last_name
    }
    initial_comment
    closing_comment
    created_by {
        id
        first_name
        last_name
    }
    comments {
      id
      created_by {
        first_name
        last_name
      }
      type
      comment
      created_at
    }
    chases {
      id
      name
      date_of_chase
      method_of_communication {
        label
      }
      comments
      chased_by
    }
    legal_entity {
      credit_limit_excluding_vat
      credit_limit_including_vat

      ...on RegisteredCompany {
        companies_house_info {
          accounts {
            next_due
          }
        }
      }
    }
    experian_credit_check {
      experian_credit_result
    }
  }
}
`;

export const CREATE_CREDIT_APPLICATION_COMMENT = gql`
mutation($input: CreateCreditApplicationCommentInput!) {
  createCreditApplicationComment(input:$input) {
    id
    created_by {
      first_name
      last_name
    }
    comment
    created_at
  }
}
`;

export const GET_EXPERIAN_CREDIT_CHECKS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getExperianCreditChecks(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    data {
      id
      experian_credit_result
      date_of_result
      created_at
      created_by {
        first_name
        last_name
      }
      status
    }
  }
}
`;

export const GET_LATEST_EXPERIAN_CREDIT_CHECK = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getExperianCreditChecks(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    data {
      id
      experian_credit_result
      date_of_result
      created_at
      created_by {
        first_name
        last_name
      }
      status
    }
  }
}
`;

export const GET_CREDIT_ACTIVITY = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getCreditActivity(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    id
    legal_entity {
      id
    }
    action
    date_of_effect
    date_cancelled
    type
    status
    comment
    created_by {
      first_name
      last_name
    }
  }
}
`;

export const GET_CREDIT_APPLICATIONS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder], $include_completed: Boolean) {
  getLegalEntityCreditApplications(
    legal_entity_id: $legal_entity_id
    pager: $pager
    sort_order: $sort_order
    include_completed: $include_completed
  ) {
    data {
      id
      legal_entity {
        id
      }
      created_at
      date_of_request
      date_of_result
      source
      credit_limit_requested
      approved_credit_excluding_vat
      use_discretionary_limit
      requested_credit_history {
        credit_limit_requested
        version_date
      }
      completed_at
      completed_by {
        first_name
        last_name
      }
      created_by {
        first_name
        last_name
      }
      status
      closing_comment
      comments {
        id
        comment
        type
        created_by {
          first_name
          last_name
        }
        created_at
      }
      chases {
        name
        date_of_chase
        comments
      }
    }
  }
}
`;

export const CREATE_SCHEDULED_CREDIT_ACTION = gql`
mutation($input: CreateScheduledCreditActionInput!) {
  createScheduledCreditAction(input: $input) {
    id
    legal_entity {
      id
    }
    type
    source
    new_credit_limit
    scheduled_by
    scheduled_at
    comment
    status
    completed_at
    created_at
    updated_at
    created_by {
      first_name
      last_name
    }
  }
}
`;
export const GET_SCHEDULED_CREDIT_ACTIONS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getScheduledCreditActions(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    data {
      id
      legal_entity {
        id
      }
      source
      new_credit_limit
      scheduled_at
      scheduled_by
      type
      status
      created_at
    }
  }
}
`;

export const GET_SCHEDULED_CREDIT_ACTION = gql`
query($scheduled_credit_action_id: ID!) {
  getScheduledCreditAction(id: $scheduled_credit_action_id) {
    id
    type
    source
    new_credit_limit
    scheduled_at
    scheduled_by
    status
    comment
    created_at
    created_by {
      first_name
      last_name
    }
  }
}
`;

export const CANCEL_SCHEDULED_CREDIT_ACTION = gql`
mutation($scheduled_credit_action_id: ID!) {
  cancelScheduledCreditAction(id: $scheduled_credit_action_id) {
    id
    status
  }
}
`;
