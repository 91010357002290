import React, { Component } from "react";
import PropTypes from "prop-types";
import { TrackingIcon } from "../";
import { Modal, Form } from "../../../common/components";
import styles from "./TrackingModal.module.scss";

export default class TrackingModal extends Component {

  static propTypes = {
    type: PropTypes.oneOf(["tracking","chase","resolve"]),
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = { input: "", dateTime: Date.now() }

  renderModalTitle = () => {
    const { type } = this.props;

    switch (type) {
      case "tracking":
        return "Tracking Request";

      case "chase":
        return "Tracking Chase";

      case "resolve":
        return "Tracking Resolve";

      default:
        break;
    }
  }

  renderModalSubtitle = () => {
    const { type } = this.props;

    switch (type) {
      case "tracking":
        return "Please enter the name of the person you are communicating with and when the communication started.";

      case "chase":
        return "Please enter the name of the person you have chased and when you chased them.";

      case "resolve":
        return "Please enter the name of the person that has resolved the tracking and when it was resolved.";

      default:
        break;
    }
  }

  renderModalChildren = () => (
    <div>
      <Form.Input size="medium" placeholder="Enter Name" className={styles.input} name="input" type="text" onChange={this.onChange} value={this.state.input} />
      <Form.DateTime className={styles.dateTime} value={this.state.dateTime} name="dateTime" onChange={this.onChange} />
    </div>
  )

  renderModalSubmitText = () => {
    const { type } = this.props;

    switch (type) {
      case "tracking":
        return (
          <div className={styles.submit}>
            <TrackingIcon type={type} className={styles.trackingIcon} />
            Request
          </div>
        );

      case "chase":
        return (
          <div className={styles.submit}>
            <TrackingIcon type={type} className={styles.trackingIcon} />
            Chase
          </div>
        );

      case "resolve":
        return (
          <div className={styles.submit}>
            <TrackingIcon type={type} className={styles.trackingIcon} />
            Resolve
          </div>
        );

      default:
        return "Submit";
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = () => {
    this.props.onSubmit && this.props.onSubmit(this.state);
    this.setState({ input: "", dateTime: Date.now() });
  }

  onCancel = () => {
    this.props.onCancel && this.props.onCancel();
    this.setState({ input: "", dateTime: Date.now() });
  }

  render() {
    return (
      <Modal
        primaryButtonDisabled={this.state.input === ""}
        className={styles.modal}
        title={this.renderModalTitle()}
        subtitle={this.renderModalSubtitle()}
        submitText={this.renderModalSubmitText()}
        buttonPosition="left"
        {...this.props}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
      >
        {this.renderModalChildren()}
      </Modal>
    );
  }
}
