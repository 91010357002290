import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { UserForm } from "../../components";
import {
  Breadcrumb,
  Heading,
} from "../../../common/components";
import styles from "./EditUserPage.module.scss";

export default class EditUserPage extends Component {

  static propTypes = {
    userId: PropTypes.string.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        user_type: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
        department: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
        user_groups: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.string.isRequired,
        })),
      }),
    }).isRequired,
    departments: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
    }).isRequired,
    userGroups: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
    }),
    ui: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }).isRequired,
    getUser: PropTypes.func.isRequired,
    getDepartments: PropTypes.func.isRequired,
    getUserGroups: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    gotoUsers: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    await this.props.getDepartments();
    await this.props.getUserGroups();
    await this.props.getUser(this.props.userId);
  }

  render() {
    const isLoading = _.some([
      this.props.user,
      this.props.departments,
      this.props.userGroups,
    ], x => x.loading);

    if (isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <div className={styles.container}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item link="/manage/users" text="User Management" />
          <Breadcrumb.Item link={`/manage/users/edit/${this.props.userId}`} text="Edit User" />
        </Breadcrumb>
        <Heading title="Edit User" />
        <UserForm
          user={{
            firstName: this.props.user.data?.first_name,
            lastName: this.props.user.data?.last_name,
            email: this.props.user.data?.email,
            userTypeId: this.props.user.data?.user_type?.id,
            departmentId: this.props.user.data?.department?.id,
            userGroups: this.props.user.data?.user_groups,
          }}
          departments={this.props.departments.data}
          userGroups={this.props.userGroups.data}
          submitText="Save Changes"
          submitting={this.props.ui.submitting}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }

  handleSubmit = async (data) => {
    await this.props.updateUser({
      userId: this.props.userId,
      user: data,
      redirectUrl: "/manage/users",
    });
  }

  handleCancel = () => {
    this.props.gotoUsers();
  }

}
