import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import config from "./config";
import "react-activity/dist/react-activity.css";
import "semantic-ui-css/semantic.min.css";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import Root from "./Root";
import App from "./App";

Amplify.configure({
  Auth: {
    identityPoolId: config.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: config.REACT_APP_COGNITO_REGION,
    userPoolId: config.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlow: "USER_PASSWORD_AUTH",
  },
});

ReactDOM.render(
  <Root>
    <App />
  </Root>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
