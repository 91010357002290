import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { TabControl } from "../";
import styles from "./TabControlRouter.module.scss";

export class TabControlRouter extends Component {

  static propTypes = {
    children: PropTypes.array.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    onTabChanged: PropTypes.func,
  }

  componentDidMount() {
    const { history, location, children } = this.props;
    const index = children.findIndex(c => location.pathname.includes(c?.props?.to));

    if (index < 0) {
      history.replace(children[0].props.to);
    }
  }

  render() {
    const { children } = this.props;
    const defaultTabIndex = this.getDefaultTabIndex();

    return (
      <TabControl className={styles.tabControl} defaultIndex={defaultTabIndex} onTabChanged={this.handleTabChanged}>
        {children}
      </TabControl>
    );
  }

  getDefaultTabIndex = () => {
    const { location, children } = this.props;
    const index = children.findIndex(c => location.pathname.includes(c?.props?.to));

    return Math.max(0, index);
  }

  handleTabChanged = (tab) => {
    const { history, children, onTabChanged } = this.props;
    const child = children.find(c => c?.props?.title === tab);

    history.push(child.props.to);

    onTabChanged && onTabChanged(tab);
  }

}

export default withRouter(TabControlRouter);
