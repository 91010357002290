import React from "react";
import PropTypes from "prop-types";
import { Close } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import styles from "./MIReportGroupItem.module.scss";

export default function MIReportGroupItem(props) {
  if (props.readOnly) {
    return (
      <span className={styles.text}>{props.value}</span>
    );
  }

  return (
    <Form.Group className={styles.group} error={props.error}>
      <div className={styles.inputWrapper}>
        <Form.Input
          value={props.value}
          onChange={props.onChange}
          highlightError={props.error}
        />
        {!props.disabled && props.canRemove && (
          <IconButton
            icon={(<Close />)}
            iconPosition="left"
            className={styles.removeButton}
            onClick={props.onRemove}
          />
        )}
      </div>
    </Form.Group>
  );
}

MIReportGroupItem.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  canRemove: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
