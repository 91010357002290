/**
 * Gets the user's name from the user object
 * @param  {object} user
 * @returns {string} name
 */
const getUsersName = (user) => {
  return user
    ? `${user.first_name} ${user.last_name}`
    : "";
};

export default getUsersName;
