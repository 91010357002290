import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import TableCell from "./TableCell";
import TableColumn from "./TableColumn";
import TableColumnSortable from "./TableColumnSortable";
import TableRow from "./TableRow";
import "./Table2.scss";

class Table2 extends PureComponent {

  static defaultProps = {
    rowSelectable: () => true,
  }

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    onRowClick: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    rowSelectable: PropTypes.func,
    rowClassName: PropTypes.string,
    customRow: PropTypes.func,
  }

  render() {
    const { className, children, onRowClick, loading, rowSelectable, rowClassName, customRow } = this.props;
    const rows = this.getData().map((row, i) => {
      const cells = this.getChildElements().map((child, j) => (
        <TableCell key={j} className={classnames(child.props.cellClassName, child.props.className)} collapsed={child.props.collapsed}>
          {typeof(child.props.value) == "function" ? child.props.value(row, i) : child.props.value}
        </TableCell>
      ));
      const NewTableRow = customRow ? customRow(TableRow) : TableRow;

      return (
        <NewTableRow className={rowClassName} key={i} data={row} onClick={rowSelectable(row) && onRowClick ? onRowClick.bind(this, row, i) : undefined}>
          {cells}
        </NewTableRow>
      );
    });

    return (
      <div className={classnames("Table2", className, loading && "loading")}>
        <div className="TableHeading">
          <TableRow>
            {children}
          </TableRow>
        </div>
        <div className="TableBody">
          {rows}
        </div>
      </div>
    );
  }

  getData = () => {
    const { data } = this.props;

    return _.reject(data, _.isNull) || [];
  }

  getChildElements = () => {
    const { children } = this.props;

    return children
      ? Array.isArray(children)
        ? children
        : [children]
      : [];
  }

}

Table2.Cell = TableCell;
Table2.Column = TableColumn;
Table2.Row = TableRow;
Table2.SortableColumn = TableColumnSortable;

export default Table2;
