import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import { actions as documentActions } from "../../../documents/duck";
import PayrollTab from "./PayrollTab";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
  mode: ownProps.match.params.mode,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchPayrollInfo: actions.getPayrollInfo,
    onFetchPayrollHistory: actions.getPayrollHistory,
    onDownloadProspectDocument: documentActions.downloadProspectDocument,
  }, dispatch),
  onEditClick: (prospectId, section) => {
    dispatch(push(`/prospects/${prospectId}/edit/payroll/${section}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTab);
