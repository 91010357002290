import React from "react";
import PropTypes from "prop-types";
import {
  HomeRounded,
  FaceOutlined,
  FaceRounded,
  SyncProblemRounded,
  PeopleRounded,
  CreditCardRounded,
  ShareRounded,
  GroupAddRounded,
  ListRounded,
  AllInboxRounded,
  PermPhoneMsgRounded,
  SettingsInputComponentRounded,
  PlaylistAddCheckRounded,
  WorkRounded,
} from "@material-ui/icons";
import { NavLink, Route, Switch } from "react-router-dom";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import Logo from "../assets/images/logo.svg";
import { useInactivityTimeout } from "../utils/hooks";
import { Toaster, UserDropDown } from "../modules/common/components";
import { DataMismatchCount } from "../modules/data-mismatches/containers";
import * as AgenciesModule from "../modules/agencies";
import * as CreditLimitManagementModule from "../modules/credit-limit-management";
import * as CustomersModule from "../modules/customers";
import * as DashboardModule from "../modules/dashboard";
import * as DataMismatchesModule from "../modules/data-mismatches";
import * as GroupManagementModule from "../modules/group-management";
import * as OnboardingModule from "../modules/onboarding";
import * as ProspectsModule from "../modules/prospects";
import * as SystemModule from "../modules/system";
import * as UserManagementModule from "../modules/user-management";
import "./AdminApp.scss";

export default function AdminApp(props) {
  useInactivityTimeout();

  const renderHeader = () => {
    const { user, errors, onLogout, onShowChangePasswordModal } = props;

    return (
      <div className="Header">
        <Toaster errors={errors} />
        <div>
          <div className="Splitter" />
          <UserDropDown
            className="UserDropDown"
            user={user}
            onChangePassword={onShowChangePasswordModal}
            onLogout={onLogout}
          />
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    const { user } = props;

    return (
      <div className="Sidebar">
        <img className="Logo" src={Logo} alt="Optia" />
        <div className="menu">
          <NavLink to="/dashboard" className="item" activeClassName="active">
            <HomeRounded className="icon" />
            <span className="link">Dashboard</span>
          </NavLink>
          <hr className="separator" />
          <NavLink to="/prospects" className="item" activeClassName="active">
            <FaceOutlined className="icon" />
            <span className="link">Prospects</span>
          </NavLink>
          <NavLink to="/agencies" className="item" activeClassName="active">
            <FaceRounded className="icon" />
            <span className="link">Agencies</span>
          </NavLink>
          <NavLink to="/onboarding" className="item" activeClassName="active">
            <PlaylistAddCheckRounded className="icon" />
            <span className="link">Onboarding</span>
          </NavLink>
          <NavLink to="/data-mismatches" className="item" activeClassName="active">
            <SyncProblemRounded className="icon" />
            <span className="link">Data Mis-matches <DataMismatchCount className="data-mismatch" /></span>
          </NavLink>
          <hr className="separator" />
          <NavLink to="/customers" className="item" activeClassName="active">
            <PeopleRounded className="icon" />
            <span className="link">Customers</span>
          </NavLink>
          <NavLink to="/credit-limit-management" className="item" activeClassName="active">
            <CreditCardRounded className="icon" />
            <span className="link">Credit Limit Management</span>
          </NavLink>
          <NavLink to="/disabled" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")} activeClassName="active">
            <PermPhoneMsgRounded className="icon" />
            <span className="link">Credit Control</span>
          </NavLink>
          <hr className="separator" />
          <NavLink to="/workers" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")}  activeClassName="active">
            <WorkRounded className="icon" />
            <span className="link">Workers</span>
          </NavLink>
          <NavLink to="/disabled" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")} activeClassName="active">
            <ShareRounded className="icon" />
            <span className="link">Suppliers</span>
          </NavLink>
          <hr className="separator" />
          <NavLink to="/reports" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")}  activeClassName="active">
            <AllInboxRounded className="icon" />
            <span className="link">Reports</span>
          </NavLink>
          {user.metadata.department?.name === "IT" && (
            <>
              <hr className="separator" />
              <NavLink to="/manage" className="item" activeClassName="active">
                <GroupAddRounded className="icon" />
                <span className="link">User Management</span>
              </NavLink>
              <NavLink to="/lists" data-tip="Coming Soon" onClick={e => e.preventDefault()} className={classnames("item", "disabled")}  activeClassName="active">
                <ListRounded className="icon" />
                <span className="link">List Management</span>
              </NavLink>
              <NavLink to="/system" className="item" activeClassName="active">
                <SettingsInputComponentRounded className="icon" />
                <span className="link">System</span>
              </NavLink>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const { user } = props;

    return (
      <div className="Content">
        <ReactTooltip place="right" />
        <Route exact path="/dashboard" component={DashboardModule.DashboardPage} />
        <Route exact path="/prospects" component={ProspectsModule.ProspectsPage} />
        <Route path="/prospects/:prospectId" component={ProspectsModule.ProspectPage} />
        <Route exact path="/convert-to-agency/:prospectId" component={ProspectsModule.ConvertToAgencyPage} />
        <Route exact path="/agencies" component={AgenciesModule.AgenciesPage} />
        <Switch>
          <Route path="/agencies/:agencyId/edit" component={AgenciesModule.AgencyEditPage} />
          <Route path="/agencies/:agencyId" component={AgenciesModule.AgencyPage} />
        </Switch>
        <Route exact path="/credit-limit-management" component={CreditLimitManagementModule.CreditLimitManagementPage} />
        <Route exact path="/credit-limit-management/legal-entities" component={CreditLimitManagementModule.LegalEntitiesPage} />
        <Route exact path="/credit-limit-management/request-credit-check" component={CreditLimitManagementModule.PerformCreditCheckPage} />
        <Route exact path="/credit-limit-management/search" component={CreditLimitManagementModule.SearchCreditLimitManagementPage} />
        <Route exact path="/credit-limit-management/search/:legalEntityId" component={CreditLimitManagementModule.SearchCreditLimitManagementPage} />
        <Route exact path="/credit-limit-management/non-registered-company/new" component={CreditLimitManagementModule.NewNonRegisteredCompanyPage} />
        <Route exact path="/credit-limit-management/registered-company/new" component={CreditLimitManagementModule.NewRegisteredCompanyPage} />
        <Route exact path="/credit-limit-management/self-billing-organisations" component={CreditLimitManagementModule.SelfBillingOrganisationsPage} />
        <Route exact path="/credit-limit-management/self-billing-organisations/new" component={CreditLimitManagementModule.AddNewSelfBillingOrganisationPage} />
        <Route path="/credit-limit-management/legal-entities/:legalEntityId" component={CreditLimitManagementModule.LegalEntityPage} />
        <Route path="/credit-limit-management/task-list" component={CreditLimitManagementModule.TaskListPage} />
        <Switch>
          <Route exact path="/customers" component={CustomersModule.ViewAgencyCustomersPage} />
          <Route exact path="/customers/new" component={CustomersModule.AddNewCustomerPage} />
          <Route exact path="/customers/:agencyCustomerId" component={CustomersModule.ViewAgencyCustomerPage} />
          <Route exact path="/customers/:agencyCustomerId/verify" component={CustomersModule.VerifyRegisteredCompanyPage} />
          <Route exact path="/customers/:agencyCustomerId/contracts/new" component={CustomersModule.AddNewContractPage} />
          <Route exact path="/customers/:agencyCustomerId/contracts/:contractId" component={CustomersModule.ViewContractPage} />
        </Switch>
        <Route exact path="/data-mismatches" component={DataMismatchesModule.DataMismatchesPage} />
        <Route exact path="/manage" component={UserManagementModule.UserManagementLandingPage} />
        <Route exact path="/manage/users" component={UserManagementModule.UsersPage} />
        <Route exact path="/manage/users/new" component={UserManagementModule.NewUserPage} />
        <Route exact path="/manage/users/edit/:id" component={UserManagementModule.EditUserPage} />
        <Route exact path="/manage/groups" component={GroupManagementModule.GroupManagementLandingPage} />
        <Route exact path="/manage/groups/new" component={GroupManagementModule.NewUserGroupPage} />
        <Route exact path="/manage/groups/edit/:id" component={GroupManagementModule.EditUserGroupPage} />
        <Route exact path="/onboarding" component={OnboardingModule.OnboardingLandingPage} />
        <Route exact path="/onboarding/new/prospects" component={ProspectsModule.NewRegisteredProspectSearchPage} />
        <Route exact path="/onboarding/new/prospects/:companyNumber" component={ProspectsModule.NewRegisteredProspectPage} />
        <Route exact path="/onboarding/new/existing-short-codes" component={OnboardingModule.ExistingShortCodesPage} />
        <Route exact path="/onboarding/agencies" component={OnboardingModule.ViewAsAgencyPage} />
        <Route path="/onboarding/agencies/:agencyId" component={OnboardingModule.AgencyTasksPage} />
        <Route exact path="/onboarding/tasks" component={OnboardingModule.ViewAsTaskPage} />
        <Route exact path="/onboarding/tasks/:onboardingTaskId" component={OnboardingModule.TaskPage} />
        <Route path="/onboarding/tasks/:onboardingTaskId/:agencyId" component={OnboardingModule.TaskPage} />
        <Route exact path="/onboarding/prospects" component={OnboardingModule.ViewAsProspectPage} />
        <Route path="/onboarding/prospects/:prospectId" component={OnboardingModule.ProspectTasksPage} />
        {user.metadata.department?.name === "IT" && (
          <>
            <Route exact path="/system" component={SystemModule.SystemLandingPage} />
            <Route exact path="/system/data-export" component={SystemModule.DataExportPage} />
            <Route exact path="/system/data-import" component={SystemModule.DataImportPage} />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="AdminApp">
      {renderHeader()}
      {renderSidebar()}
      {renderContent()}
    </div>
  );
}

AdminApp.propTypes = {
  user: PropTypes.shape({
    attributes: PropTypes.shape ({
      name: PropTypes.string.isRequired,
    }),
    metadata: PropTypes.shape({
      department: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ),
  onChangePassword: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onShowChangePasswordModal: PropTypes.func.isRequired,
};
