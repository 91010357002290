import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Accordion } from "semantic-ui-react";
import { ExpandMore } from "@material-ui/icons";
import styles from "./BranchListViewItem.module.scss";

export default function BranchListViewItem(props) {
  const [active, setActive] = useState(!!props?.active);

  return (
    <>
      <Accordion.Title
        className={styles.container}
        index={props.index}
        active={active}
        onClick={() => setActive(!active)}
      >
        <div className={classnames(styles.titleContainer, active && styles.active)}>
          <div className={styles.title}>
            {props.branch.name}
          </div>
          <div className={styles.itemControls}>
            {props.rightAccessory}
            <ExpandMore
              className={classnames(
                styles.icon,
                active ? styles.collapse : styles.expand
              )}
            />
          </div>
        </div>
      </Accordion.Title>
      <Accordion.Content active={active} className={classnames(styles.contentContainer, active && styles.active)}>
        {props.branch.address}
      </Accordion.Content>
    </>
  );
}

BranchListViewItem.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.bool,
  branch: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }),
  rightAccessory: PropTypes.element,
};
