import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Modal } from "semantic-ui-react";
import { Button } from "../";
import styles from "./ConfirmationModal.module.scss";

export default function ConfirmationModal(props) {
  const { className, dimmerClassName, ...otherProps } = props;

  return (
    <Modal
      {...otherProps}
      className={classnames(styles.container, className)}
      open={props.visible}
      dimmer={{
        className: classnames(styles.dimmer, dimmerClassName),
      }}
      centered
    >
      <Modal.Header className={styles.title}>
        {props.title}
      </Modal.Header>
      <Modal.Content className={styles.content} scrolling={props.scrolling}>
        {props.children}
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        {props.onCancel && (
          <Button
            variant={props.cancelVariant || "outline-danger"}
            onClick={props.onCancel}
          >
            {props.cancelText}
          </Button>
        )}
        {props.onSubmit && (
          <Button
            variant={props.submitVariant || "primary"}
            busy={props.busy}
            disabled={props.submitDisabled}
            onClick={props.onSubmit}
          >
            {props.submitText}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  submitText: "Yes",
  cancelText: "Cancel",
  scrolling: false,
};

ConfirmationModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  visible: PropTypes.bool.isRequired,
  busy: PropTypes.bool,
  scrolling: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  submitVariant: PropTypes.string,
  cancelVariant: PropTypes.string,
  submitDisabled: PropTypes.bool,
  className: PropTypes.string,
  dimmerClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
