/**
 * Gets the Employer_Ref for the Agency, based on the
 * agency's payroll frequency
 *
 * @param {string} agencyId
 * @param {string} payrollFrequency
 * @return string[]
 */
export const getEmployerRefs = (agencyId, payrollFrequency) => {
  switch (payrollFrequency) {
    case "weekly":
      return [`${agencyId}W`];

    case "monthly":
      return [`${agencyId}M`];

    case "both":
      return [`${agencyId}W`, `${agencyId}M`];

    default:
      return [];
  }
};
