import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_HMRC_INFO = gql`
mutation(
  $agency_id: ID!
  $agency_input: UpdateAgencyInput!
  $hmrc_input: UpdateAgencyHmrcInfoInput!
) {
  updateAgency(
    id: $agency_id,
    agency_input: $agency_input
  ) {
    is_existing_paye_scheme
  }

  updateAgencyHmrcInfo(
    agency_id: $agency_id
    input: $hmrc_input
  ) {
    paye_scheme_reference
    accounts_office_reference
    claiming_employers_ni_allowance
    small_employers_relief
    intermediary_reporting
    apprenticeship_levy
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 * @param {object} obj.data
 * @param {boolean} obj.data.is_existing_paye_scheme
 * @param {string} obj.data.paye_scheme_reference
 * @param {string} obj.data.accounts_office_reference
 * @param {boolean} obj.data.claiming_employers_ni_allowance
 * @param {boolean} obj.data.small_employers_relief
 * @param {boolean} obj.data.intermediary_reporting
 * @param {boolean} obj.data.apprenticeship_levy
 * @param {string} obj.redirectUrl
 *
 */
export const updateHmrcInfo = ({ agencyId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_HMRC_INFO_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_HMRC_INFO,
      variables: {
        agency_id: agencyId,
        agency_input: {
          is_existing_paye_scheme: data?.is_existing_paye_scheme,
        },
        hmrc_input: {
          paye_scheme_reference: data?.paye_scheme_reference,
          accounts_office_reference: data?.accounts_office_reference,
          claiming_employers_ni_allowance: data?.claiming_employers_ni_allowance,
          small_employers_relief: data?.small_employers_relief,
          intermediary_reporting: data?.intermediary_reporting,
          apprenticeship_levy: data?.apprenticeship_levy,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_HMRC_INFO_SUCCESS,
      payload: {
        data: {
          ...response.data.updateAgency,
          ...response.data.updateAgencyHmrcInfo,
        },
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_HMRC_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
