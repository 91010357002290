import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./Pager.scss";

const PAGE_NUMBERS_IN_SEQUENCE = 5;
const PAGE_NUMBERS_WHEN_TRUNCATED = 3;

export default class Pager extends PureComponent {

  static propTypes = {
    pageCount: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    onPageChanged: PropTypes.func.isRequired,
  }

  render() {
    const { pageCount, selectedPage } = this.props;
    const showStartPage = pageCount > PAGE_NUMBERS_IN_SEQUENCE && selectedPage - PAGE_NUMBERS_WHEN_TRUNCATED > 0;
    const showEndPage = pageCount !== PAGE_NUMBERS_IN_SEQUENCE && selectedPage < pageCount - PAGE_NUMBERS_WHEN_TRUNCATED;

    return (
      <div className="Pager">
        <div className="start arrow" onClick={this.handleGoToPage.bind(this, 1)}>
          <span className="pipe">|</span>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="back arrow" onClick={this.handleGoToPage.bind(this, selectedPage - 1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        {showStartPage && (
          <>
            {this.renderPage(1)}
            <div className="page separator">...</div>
          </>
        )}
        {this.renderPages()}
        {showEndPage && (
          <>
            <div className="page separator">...</div>
            {this.renderPage(pageCount)}
          </>
        )}
        <div className="next arrow" onClick={this.handleGoToPage.bind(this, selectedPage + 1)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="end arrow" onClick={this.handleGoToPage.bind(this, pageCount)}>
          <FontAwesomeIcon icon={faChevronRight} />
          <span className="pipe">|</span>
        </div>
      </div>
    );
  }

  renderPages = () => {
    const { pageCount, selectedPage } = this.props;

    if (selectedPage > PAGE_NUMBERS_WHEN_TRUNCATED && selectedPage < pageCount - PAGE_NUMBERS_WHEN_TRUNCATED) {
      return [...Array(PAGE_NUMBERS_WHEN_TRUNCATED).keys()].map(i => i + selectedPage - 1).map(i => this.renderPage(i));
    }

    if (selectedPage <= PAGE_NUMBERS_WHEN_TRUNCATED + 1) {
      return [...Array(PAGE_NUMBERS_IN_SEQUENCE).keys()].map(i => i + 1).map(i => this.renderPage(i));
    }

    return [...Array(PAGE_NUMBERS_IN_SEQUENCE).keys()].map(i => pageCount + i - PAGE_NUMBERS_WHEN_TRUNCATED - 1).map(i => this.renderPage(i));
  }

  renderPage = (pageNumber) => {
    const { selectedPage, pageCount } = this.props;

    return pageNumber <= pageCount && (
      <div
        key={pageNumber}
        className={classnames(selectedPage === pageNumber && "selected", "page", "number")}
        onClick={this.handleGoToPage.bind(this, pageNumber)}
      >
        {pageNumber}
      </div>
    );
  }

  handleGoToPage = (n) => {
    if (n < 1) return;
    if (n > this.props.pageCount) return;
    if (n === this.props.selectedPage) return;

    this.props.onPageChanged(n);
  }
}
