import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_TRADING_ADDRESS = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectInput!
) {
  updateProspect(id: $prospect_id, input: $input) {
    trading_address {
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {string} obj.data.address_line_one
 * @param {string} obj.data.address_line_two
 * @param {string} obj.data.town_or_city
 * @param {string} obj.data.county
 * @param {string} obj.data.postcode
 * @param {string} obj.redirectUrl
 *
 */
export const updateTradingAddress = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_TRADING_ADDRESS_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_TRADING_ADDRESS,
      variables: {
        prospect_id: prospectId,
        input: {
          trading_address_line_one: data.address_line_one,
          trading_address_line_two: data.address_line_two,
          trading_address_town_or_city: data.town_or_city,
          trading_address_county: data.county,
          trading_address_postcode: data.postcode,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_TRADING_ADDRESS_SUCCESS,
      payload: {
        prospect: response.data.updateProspect,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_TRADING_ADDRESS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
