import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_CUSTOMER_REQUEST = gql`
mutation($input: UpdateAgencyCustomerRequestInput!) {
  updateAgencyCustomerRequest(input: $input) {
    id
    registered_company_number
    exact_legal_name
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerRequestId
 * @param {string} obj.registeredCompanyNumber
 * @param {string} obj.exactLegalName
 * @param {number} obj.creditRequested (optional)
 * @param {string} obj.redirectUrl
 * @returns
 */
export const updateAgencyCustomerRequest = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_CUSTOMER_REQUEST_PENDING,
    });

    const input = {
      agency_customer_request_id: obj.agencyCustomerRequestId,
      registered_company_number: obj.registeredCompanyNumber,
      exact_legal_name: obj.exactLegalName,
    };

    // Only update the credit limit requested if we have supplied it
    if (obj.creditRequested) {
      input.credit_limit_requested = obj.creditRequested;
    }

    const response = await apolloClient.mutate({
      mutation: UPDATE_AGENCY_CUSTOMER_REQUEST,
      variables: {
        input,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_CUSTOMER_REQUEST_SUCCESS,
      payload: {
        data: response.data.updateAgencyCustomerRequest,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_CUSTOMER_REQUEST_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
