import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./DeleteDocumentVersionModal.module.scss";

export default function DeleteDocumentVersionModal (props) {

  const handleSubmit = () => {
    props.removeDocumentVersion({
      agencyId: props.agencyId,
      prospectId: props.prospectId,
      document: props.document,
      versionId: props.versionId,
    });

    props.hideModal(DeleteDocumentVersionModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(DeleteDocumentVersionModal.displayName);
  };

  const documentType = props.documentTypes?.find(
    option => option.value === props.document.name
  )?.type || props.document.name;

  return (
    <ConfirmationModal
      title="Are you sure?"
      visible={props.visible}
      className={styles.container}
      submitText="Delete"
      submitVariant="danger"
      onSubmit={handleSubmit}
      onCancel={handleCancel}>
      <div>
        Are you sure that you want to delete this version of the {documentType}?
      </div>
    </ConfirmationModal>
  );
}

DeleteDocumentVersionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  agencyId: PropTypes.string,
  prospectId: PropTypes.string,
  documentTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  document: PropTypes.shape({
    name: PropTypes.string,
  }),
  versionId: PropTypes.string,
  removeDocumentVersion: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

DeleteDocumentVersionModal.displayName = "DeleteDocumentVersionModal";
