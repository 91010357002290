import * as types from "./types";

const initialState = {
  dataMismatchCount: {
    loading: false,
    data: 0,
    error: null,
  },
  dataMismatches: {
    loading: false,
    data: [],
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_DATA_MISMATCH_COUNT_PENDING:
      return {
        ...state,
        dataMismatchCount: {
          ...state.dataMismatchCount,
          loading: true,
          error: null,
        },
      };

    case types.GET_DATA_MISMATCH_COUNT_SUCCESS:
      return {
        ...state,
        dataMismatchCount: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_DATA_MISMATCH_COUNT_FAILURE:
      return {
        ...state,
        dataMismatchCount: {
          loading: false,
          data: 0,
          error: action.payload.error,
        },
      };

    case types.GET_DATA_MISMATCHES_PENDING:
      return {
        ...state,
        dataMismatches: {
          ...state.dataMismatches,
          loading: true,
          error: null,
        },
      };

    case types.GET_DATA_MISMATCHES_SUCCESS:
      return {
        ...state,
        dataMismatchCount: {
          ...state.dataMismatchCount,
          data: action.payload.total,
        },
        dataMismatches: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_DATA_MISMATCHES_FAILURE:
      return {
        ...state,
        dataMismatches: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
