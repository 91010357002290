import { suspendCredit } from "./";
import { SuspendModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const showSuspendModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(SuspendModal.displayName, {
    onSubmit: (data) => {
      dispatch(suspendCredit({
        legalEntityId: obj.legalEntityId,
        ...data,
      }));

      dispatch(modalActions.hideModal(SuspendModal.displayName));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(SuspendModal.displayName));
    },
  }));
};
