import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./Option.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

class Option extends PureComponent {

    static defaultProps = {
      linkText: "View",
      disabled: false,
      hideView: false,
    }

    static propTypes = {
      className: PropTypes.string,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
      hideView: PropTypes.bool,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }

    render() {
      const { className, title, subtitle, linkText, onClick, disabled, hideView }  = this.props;

      return (
        <div
          className={`${styles.option} ${className && className} ${disabled && styles.disabled}`}
          onClick={!disabled && onClick && onClick}
        >
          <div className={`${styles.optionContainer} ${disabled && styles.disabled} ${hideView && styles.compact}`}>
            <div>
              <p className={styles.title}>{title}</p>
              <p className={styles.subtitle}>{subtitle}</p>
            </div>
            { !hideView &&
              <div className={styles.viewContainer}>
                {linkText}
                <FontAwesomeIcon className={styles.viewIcon} icon={faChevronRight} />
              </div>
            }
          </div>
        </div>
      );
    }
}

export default Option;
