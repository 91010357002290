import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const SUSPEND_CREDIT = gql`
mutation($input: AddCreditSuspensionInput!) {
  addCreditSuspension(input: $input) {
    id
    date_of_effect
    type
    status
    comment
    created_by {
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {number} obj.dateOfEffect
 * @param {string} obj.comment (optional)
 * @returns
 */
export const suspendCredit = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: SUSPEND_CREDIT,
      variables: {
        input: {
          legal_entity_id: obj.legalEntityId,
          type: "suspend",
          date_of_effect: obj.dateOfEffect,
          comment: obj.comment,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const activeSuspension = response.data.addCreditSuspension.status === "completed"
      ? response.data.addCreditSuspension
      : null;

    const scheduledSuspension = response.data.addCreditSuspension.status === "pending"
      ? response.data.addCreditSuspension
      : null;

    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_SUCCESS,
      payload: {
        activeSuspension,
        scheduledSuspension,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.TOGGLE_CREDIT_SUSPENSION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
