import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import AddNewCustomerPage from "./AddNewCustomerPage";

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    showUnsavedChangesModal: modalActions.showUnsavedChangesModal,
  }, dispatch),
  onCancelAddNewCustomer: () => {
    dispatch(push("/customers/"));
  },
});

export default connect(null, mapDispatchToProps)(AddNewCustomerPage);
