import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_MI_REPORT_GROUPING = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectMIReportGroupingsInput!
) {
  updateProspectMiReportGroupings(prospect_id: $prospect_id, input: $input) {
    mi_report_grouping_required

    prospect_mi_report_groupings {
      active
      mi_report_grouping {
        id
        label
        value
      }
      values {
        id
        value
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {string} obj.data.mi_report_grouping_required
 * @param {object[]} obj.data.mi_report_groupings
 * @param {boolean} obj.data.mi_report_groupings[].active
 * @param {object} obj.data.mi_report_groupings[].mi_report_grouping
 * @param {number} obj.data.mi_report_groupings[].mi_report_grouping.id
 * @param {string} obj.data.mi_report_groupings[].mi_report_grouping.label
 * @param {string} obj.data.mi_report_groupings[].mi_report_grouping.value
 * @param {object[]} obj.data.mi_report_groupings[].values
 * @param {number} obj.data.mi_report_groupings[].values[].id
 * @param {string} obj.data.mi_report_groupings[].values[].value
 * @param {string} obj.redirectUrl
 *
 */
export const updateMiReportGrouping = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_MI_REPORT_GROUPING_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_MI_REPORT_GROUPING,
      variables: {
        prospect_id: prospectId,
        input: {
          mi_report_grouping_required: data.mi_report_grouping_required,
          prospect_mi_report_groupings: data.mi_report_groupings.map(grouping => ({
            mi_report_grouping_id: grouping.mi_report_grouping.id,
            active: grouping.active,
            values: grouping.values,
          })),
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_MI_REPORT_GROUPING_SUCCESS,
      payload: {
        prospect: {
          mi_report_grouping_required: response.data.updateProspectMiReportGroupings.mi_report_grouping_required,
          mi_report_groupings: response.data.updateProspectMiReportGroupings.prospect_mi_report_groupings,
        },
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_MI_REPORT_GROUPING_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
