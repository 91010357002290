import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { AgencyBranchForm } from "../../../components";
import styles from "./AgencyBranch.module.scss";

export default class AgencyBranch extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    branchId: PropTypes.string.isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }),
    branch: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object.isRequired,
    }).isRequired,
    companyHistory: PropTypes.shape({
      trading_as: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    agencyDisabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    getBranch: PropTypes.func.isRequired,
    updateBranch: PropTypes.func.isRequired,
    getCompanyHistory: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    const { agencyId, branchId, getCompanyHistory, getBranch, getAgencyDisabledFields } = this.props;

    await Promise.all([
      getBranch(agencyId, branchId),
      getCompanyHistory(agencyId),
      getAgencyDisabledFields(
        agencyId,
        ["branch_details"]
      ),
    ]);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderAgencyBranchSection()}
      </div>
    );
  }

  renderAgencyBranchSection = () => {
    const {
      agencyDisabledFields,
      branch: {
        data,
        loading,
      },
      ui: {
        submitting,
      },
    } = this.props;

    if (loading) {
      return <ActivityIndicator />;
    }

    return (
      <AgencyBranchForm
        agencyBranch={data}
        disabledFields={agencyDisabledFields?.data?.map(field => (field.disabled_field))}
        onDisabledFieldClick={this.handleDisabledFieldClicked}
        onSave={this.handleSaveClicked}
        onCancel={this.handleCancelClicked}
        submitting={submitting}
      />
    );
  }

  handleDisabledFieldClicked = (field) => {
    const { agencyDisabledFields, agencyId, history } = this.props;

    const agencyDisabledField = agencyDisabledFields.data.find(item => item.disabled_field === field);

    history.push(`/onboarding/agencies/${agencyId}/${agencyDisabledField.agency_onboarding_task_id}`);
  }

  handleCancelClicked = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/agency`);
  }

  handleSaveClicked = async (data) => {
    const { agencyId, branchId, updateBranch } = this.props;

    const agencyData = {
      ...data,
    };

    await updateBranch(agencyId, branchId, agencyData);
  }
}
