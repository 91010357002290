export default function canSubmit(formData) {
  if (!formData.siteContact.firstName) {
    return false;
  }

  if (!formData.siteContact.lastName) {
    return false;
  }

  if (!formData.siteContact.telephone) {
    return false;
  }

  if (!formData.siteContact.addressLine1) {
    return false;
  }

  if (!formData.siteContact.townOrCity) {
    return false;
  }

  if (!formData.siteContact.postcode) {
    return false;
  }

  if (formData.hasInvoiceContact) {
    if (!formData.useSiteContactForInvoice) {
      if (!formData.invoiceContact.firstName) {
        return false;
      }

      if (!formData.invoiceContact.lastName) {
        return false;
      }

      if (!formData.invoiceContact.telephone) {
        return false;
      }

      if (!formData.invoiceContact.addressLine1) {
        return false;
      }

      if (!formData.invoiceContact.townOrCity) {
        return false;
      }

      if (!formData.invoiceContact.postcode) {
        return false;
      }
    }
  }

  if (!formData.agreedPaymentTermsType) {
    return false;
  }

  if (!formData.agreedPaymentTermsValue) {
    return false;
  }

  return true;
}
