import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { DocumentDownloadButton } from "../";
import styles from "./DocumentDownload.module.scss";

export default function DocumentDownload({
  documents,
  onDownload,
  variant,
}) {

  return (
    documents && !_.isEmpty(documents) &&
    <div className={classnames(styles.container, styles[variant])}>
      {documents.map((doc, index) => (
        <div className={styles.download} key={index}>
          <DocumentDownloadButton
            text={doc.name}
            onClick={() => onDownload(doc.file)}
          />
        </div>
      ))}
    </div>
  );
}

DocumentDownload.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
  })).isRequired,
  onDownload: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["default", "footer"]),
};

DocumentDownload.defaultProps = {
  variant: "default",
};
