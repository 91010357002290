import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import DataExportPage from "./DataExportPage";

const mapStateToProps = state => ({
  ...state.system,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  onExportAgencies: actions.exportAgencies,
  onExportProspects: actions.exportProspects,
  onExportTempest: actions.exportTempest,
  onFetchDataExports: actions.getDataExports,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataExportPage);
