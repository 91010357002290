import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import InputMask from "react-input-mask";
import styles from "./FormInput.module.scss";
import { DisabledFieldLock } from "../../";

export default function FormInput({
  value,
  name,
  className,
  size,
  inline,
  fluid,
  prefix,
  errorMessage,
  tooltip,
  disabled,
  readOnly,
  showError,
  showLock,
  onChange,
  onLockClick,
  highlightError,
  history,
  maskOptions,
  ...otherProps
}) {
  const renderPrefix = () => {
    return prefix && (
      <div className={styles.prefix}>{prefix}</div>
    );
  };

  const renderInput = () => {
    const defaultValue = value ? value : readOnly ? "-" : "";

    const { ...inputProps } = otherProps;

    if (readOnly && !disabled && !maskOptions) {
      return (
        <div
          className={classnames(
            styles.text,
            readOnly && styles.readOnly,
          )}
        >
          {value || defaultValue}
        </div>
      );
    }

    if (readOnly && maskOptions) {
      return (
        <div
          className={classnames(
            styles.text,
            readOnly && styles.readOnly,
          )}
        >
          <InputMask
            className={styles.valueInput}
            value={value || defaultValue}
            disabled={true}
            readOnly={true}
            {...maskOptions}
          />
        </div>
      );
    }

    if (maskOptions) {
      return (
        <InputMask
          className={styles.inputText}
          name={name}
          onChange={handleInputChange}
          value={value || defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete="off"
          {...maskOptions}
        />
      );
    }

    return (
      <input
        {...inputProps}
        className={styles.inputText}
        name={name}
        onChange={handleInputChange}
        value={value || defaultValue}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete="off"
      />
    );
  };

  const renderLock = () => {
    return showLock && (
      <DisabledFieldLock tooltip={tooltip} onClick={onLockClick} />
    );
  };

  const renderHistory = () => {
    return readOnly && (
      <div className={styles.history}>{history}</div>
    );
  };

  const selectInput = (e) => {
    if (e.target.tagName !== "DIV") return;

    const element = e.target.children?.length > 0
      ? e.target.children[0]
      : e.target;

    element.focus();
  };

  const handleInputChange = (e) => {
    if (disabled || readOnly) return;

    onChange && onChange(e);
  };


  return (
    <div>
      <div
        className={classnames(
          styles.container,
          styles[size],
          fluid && styles.fluid,
          inline && styles.inline,
          className,
          disabled && styles.disabled,
          readOnly && styles.readOnly,
          highlightError && styles.highlightError,
        )}
        onClick={selectInput}
      >
        {renderPrefix()}
        {renderInput()}
        {renderLock()}
        {renderHistory()}
      </div>
      {showError && (
        <div className={classnames(styles.error, styles[size])}>
          <span className={styles.errorHeader}>Error: </span>
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}

FormInput.defaultProps = {
  disabled: false,
  size: "medium",
  variant: "default",
};

FormInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fluid: PropTypes.bool,
  inline: PropTypes.bool,
  prefix: PropTypes.string,
  errorMessage: PropTypes.string,
  tooltip: PropTypes.any,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showError: PropTypes.bool,
  showLock: PropTypes.bool,
  highlightError: PropTypes.string,
  history: PropTypes.node,
  maskOptions: PropTypes.shape({
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  onLockClick: PropTypes.func,
};
