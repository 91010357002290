import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as modalActions } from "../../../modals/duck";
import DeleteDocumentVersionModal from "./DeleteDocumentVersionModal";

const mapStateToProps = state => ({
  documentTypes: state.documents.documentTypes,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentVersionModal);

container.displayName = "DeleteDocumentVersionModal";

export default container;
