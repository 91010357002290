import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { bytesToGigabytes } from "../../../../utils";

const UPLOAD_AGENCY_DOCUMENT = gql`
query(
  $input: AgencyDocumentUploadUrlInput!
) {
  getAgencyDocumentUploadUrl(input: $input) {
    bucket
    key
    url
  }
}
`;

const GET_AGENCY_DOCUMENTS = gql`
query(
  $input: AgencyDocumentsInput!
) {

  getAgencyDocuments(input: $input) {
    documents {
      name
      size
      lastModified
      versions {
        name
        size
        lastModified
        versionId
        isLatest
        metadata {
          user {
            first_name
            last_name
          }
          document_type
          date_uploaded
        }
      }
      metadata {
        user {
          first_name
          last_name
        }
        document_type
      }
    }
    folders
    hasNextPage
  }
}
`;

export const uploadAgencyDocument = (document, agency_id, document_type) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPLOAD_AGENCY_DOCUMENT_PENDING,
    });

    const fileSize = bytesToGigabytes(document?.size);

    if (fileSize > 5) {
      throw new Error("File size was too large. Maximum file size is 5GB.");
    }

    const response = await apolloClient.query({
      query: UPLOAD_AGENCY_DOCUMENT,
      variables: {
        input: {
          agency_id,
          document_type,
          filename: document.name,
        },
      },
    });

    const uploadUrl = response.data.getAgencyDocumentUploadUrl;

    await fetch(uploadUrl.url, {
      method: "PUT",
      body: document,
    });

    const documentResponse = await apolloClient.query({
      query: GET_AGENCY_DOCUMENTS,
      variables: {
        input: {
          agency_id,
        },
      },
    });

    dispatch({
      type: types.UPLOAD_AGENCY_DOCUMENT_SUCCESS,
      payload: {
        getAgencyDocuments: documentResponse.data.getAgencyDocuments,
      },
    });

    return document.name;
  }
  catch (err) {
    dispatch({
      type: types.UPLOAD_AGENCY_DOCUMENT_FAILURE,
      payload: {
        error: err,
        fileSizeError: err.message.startsWith("File size was too large"),
      },
    });
  }
};
