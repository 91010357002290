import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const VERIFY_AGENCY_CUSTOMER = gql`
  mutation($id: ID!, $input: VerifyRegisteredAgencyCustomerInput) {
    verifyRegisteredAgencyCustomer(id: $id, input: $input) {
      id
      legal_name
      registered_company_number
      registered_charity_number
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyCustomerId
 * @param {string} obj.mismatchReasonValue
 * @param {string} obj.mismatchReasonDetails
 * @param {string} obj.legal_name
 * @param {string} obj.registered_company_number
 * @param {string} obj.registered_charity_number
 * @returns
 */
export const verifyRegisteredAgencyCustomer = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_PENDING,
    });

    const response = await apolloClient.query({
      query: VERIFY_AGENCY_CUSTOMER,
      variables: {
        id: obj.agencyCustomerId,
        input: {
          legal_name: obj.legal_name,
          registered_company_number: obj.registered_company_number,
          registered_charity_number: obj.registered_charity_number,
          mismatch_reason_value: obj.mismatchReasonValue,
          mismatch_reason_details: obj.mismatchReasonDetails,
        },
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_SUCCESS,
      payload: {
        data: response.data.verifyRegisteredAgencyCustomer,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.VERIFY_AGENCY_CUSTOMER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
