export const DISABLE_WORKERS_PENDING = "WORKERS/DISABLE_WORKERS_PENDING";
export const DISABLE_WORKERS_SUCCESS = "WORKERS/DISABLE_WORKERS_SUCCESS";
export const DISABLE_WORKERS_FAILURE = "WORKERS/DISABLE_WORKERS_FAILURE";
export const ADD_WORKER_PENDING = "WORKERS/ADD_WORKER_PENDING";
export const ADD_WORKER_SUCCESS = "WORKERS/ADD_WORKER_SUCCESS";
export const ADD_WORKER_FAILURE = "WORKERS/ADD_WORKER_FAILURE";
export const GET_WORKERS_PENDING = "WORKERS/GET_WORKERS_PENDING";
export const GET_WORKERS_SUCCESS = "WORKERS/GET_WORKERS_SUCCESS";
export const GET_WORKERS_FAILURE = "WORKERS/GET_WORKERS_FAILURE";
export const GET_WORKER_PENDING = "WORKERS/GET_WORKER_PENDING";
export const GET_WORKER_SUCCESS = "WORKERS/GET_WORKER_SUCCESS";
export const GET_WORKER_FAILURE = "WORKERS/GET_WORKER_FAILURE";
export const SAVE_WORKER_PENDING = "WORKERS/SAVE_WORKER_PENDING";
export const SAVE_WORKER_SUCCESS = "WORKERS/SAVE_WORKER_SUCCESS";
export const SAVE_WORKER_FAILURE = "WORKERS/SAVE_WORKER_FAILURE";
