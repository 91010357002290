import { UseDiscretionaryLimitModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.creditApplicationId
 *
 * @returns
 */
export const showUseDiscretionaryLimitModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(UseDiscretionaryLimitModal.displayName, {
    creditApplicationId: obj.creditApplicationId,
  }));
};
