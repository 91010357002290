import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ReportProblemOutlined } from "@material-ui/icons";
import { parseDate } from "../../../../utils";
import styles from "./CreditSuspendedLabel.module.scss";

export default function CreditSuspendedLabel(props) {
  return (
    <div className={classnames(props.className, styles.container)}>
      <div className={styles.iconWrapper}>
        <ReportProblemOutlined className={styles.icon} />
      </div>
      <span className={styles.text}>
        Suspended on {parseDate(props.suspendedAt)}
      </span>
    </div>
  );
}

CreditSuspendedLabel.propTypes = {
  className: PropTypes.string,
  suspendedAt: PropTypes.number.isRequired,
};
