import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Popup } from "semantic-ui-react";
import { Clear, GetApp } from "@material-ui/icons";
import { Button } from "../../../";
import styles from "./DownloadButton.module.scss";

export default function DownloadButton(props) {
  return (
    <div className={styles.buttonWrapper}>
      <Button
        className={styles.button}
        variant="outline-primary"
      >
        <Popup
          trigger={(
            <span className={styles.value}>{props.value}</span>
          )}
          content={props.value}
          offset={[-20, 10]}
          on="hover"
          disabled={props.value?.length < 24}
          wide
        />
      </Button>
      <div className={styles.actions}>
        {props.downloadable && (
          <GetApp
            className={classnames(styles.actionIcon, styles.downloadIcon)}
            onClick={props.onDownload}
          />
        )}
        {props.readOnly || (
          <Clear
            className={classnames(styles.actionIcon, styles.deleteIcon)}
            onClick={props.onDelete}
          />
        )}
      </div>
    </div>
  );
}

DownloadButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  downloadable: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};
