import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import { DocumentDownload, Form, IconButton, MaskedValue, SeeHistoryTooltip } from "../../../../common/components";
import { DOCUMENT_TYPES } from "../../../../documents";
import { capitalize, formatAnswer, parseDate } from "../../../../../utils";
import styles from "./VATSection.module.scss";

export default function VATSection(props) {
  const documents = [
    {
      name: DOCUMENT_TYPES.VAT_CERTIFICATE,
      file: props.vatDocument,
    },
  ];

  const verifyVatValue = (value) => {
    if (!value || props.isDisabled) {
      return value;
    }

    return props.applyingRegisteredVat
      ? value
      : null;
  };

  return (
    <Form.Card
      className={styles.container}
      title="VAT"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <div className={styles.contentWrapper}>
        <div>
          <Form.Group inline>
            <Form.Label inline>Agency applying / registered for VAT</Form.Label>
            <SeeHistoryTooltip variant="compact" data={props.payrollHistory?.applying_registered_vat} currentValue={formatAnswer(props.applyingRegisteredVat)}>
              <Form.ValueInline>
                {formatAnswer(props.applyingRegisteredVat)}
              </Form.ValueInline>
            </SeeHistoryTooltip>
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>VAT Number</Form.Label>
            <MaskedValue value={props.vatNumber} maskOptions={ { mask: "999 9999 99" } } style={ { fontSize: "16px", color: "#1a1a1a" } } />
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>VAT Period Ending Date</Form.Label>
            <Form.ValueInline>{parseDate(verifyVatValue(props.vatPeriodEndingDate)) || "-"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>VAT Frequency</Form.Label>
            <Form.ValueInline>{capitalize(verifyVatValue(props.vatFrequency)) || "-"}</Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>Next VAT Return Period End</Form.Label>
            <Form.ValueInline>{parseDate(verifyVatValue(props.nextVatReturnPeriodEnd)) || "-"}</Form.ValueInline>
          </Form.Group>
        </div>
        <DocumentDownload
          variant="footer"
          documents={documents.filter(doc => doc.file)}
          onDownload={props.onDownload}
        />
      </div>
    </Form.Card>
  );
}

VATSection.propTypes = {
  applyingRegisteredVat: PropTypes.bool,
  vatNumber: PropTypes.string,
  vatPeriodEndingDate: PropTypes.string,
  vatFrequency: PropTypes.string,
  nextVatReturnPeriodEnd: PropTypes.string,
  vatDocument: PropTypes.string,
  payrollHistory: PropTypes.shape({
    applying_registered_vat: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  isDisabled: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func,
  onDownload: PropTypes.func,
};
