import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Squares as ActivityIndicator } from "react-activity";
import { Label, Table } from "semantic-ui-react";
import { WarningRounded } from "@material-ui/icons";
import moment from "moment";
import { TextLink, SortIndicator } from "../../../common/components";
import { parseDate } from "../../../../utils";
import styles from "./TaskList.module.scss";

const getDaysRemaining = (from, to) => {
  const start = moment(from.setUTCHours(0, 0, 0, 0));
  const due = moment(to.setUTCHours(0, 0, 0, 0));

  return due.diff(start, "days");
};

export default function TaskList(props) {
  const handleColumnClicked = (name) => {
    props.onColumnClick(name, props.sortOrder[name] || "NONE");
  };

  const renderDueDate = (task) => {
    const daysRemaining = getDaysRemaining(new Date(), new Date(task.due_date));

    if (daysRemaining === 0) {
      return (
        <Label className={styles.dueToday} size="large">
          <span className={styles.dueDate}>{parseDate(task.due_date)}</span> - Today
        </Label>
      );
    }

    if (daysRemaining > 0) {
      return (
        <Label className={styles.dueSoon} size="large">
          <span className={styles.dueDate}>{parseDate(task.due_date)}</span> - {daysRemaining} day{daysRemaining > 1 ? "s" : ""} left
        </Label>
      );
    }

    return (
      <Label className={styles.overdue} size="large">
        <span className={styles.dueDate}>{parseDate(task.due_date)}</span> - Overdue
      </Label>
    );
  };

  if (props.data.length === 0 && props.loading) {
    return (
      <ActivityIndicator />
    );
  }

  const renderRows = () => {
    if (props.data.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan="5" className={styles.noResults}>
            There are no tasks available to view.
          </Table.Cell>
        </Table.Row>
      );
    }

    return props.data.map((task, index) => (
      <Table.Row
        key={`task_${task.id}`}
        className={classnames(
          index % 2 === 1 && styles.stripe,
        )}
      >
        <Table.Cell>
          <TextLink
            className={styles.taskName}
            text={task.title}
            onClick={() => props.onTaskClick(task)}
          />
        </Table.Cell>
        <Table.Cell>
          {task.agency?.known_as ?? task.prospect?.known_as}
          {task.prospect && !task.agency && (<strong> (Prospect)</strong>)}
        </Table.Cell>
        <Table.Cell>
          {task.legal_entity.name}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {task.is_urgent && (
            <WarningRounded className={styles.urgentIcon} />
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          {renderDueDate(task)}
        </Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <Table
      className={classnames(
        styles.container,
        props.loading && styles.loading,
      )}
      basic
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            width="5"
            className={styles.sortableHeader}
            onClick={() => handleColumnClicked("taskName")}
          >
            <div>
              Task Name
              <SortIndicator
                className={styles.sortIndicator}
                sortOrder={props.sortOrder.taskName}
              />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell
            width="4"
            className={styles.sortableHeader}
            onClick={() => handleColumnClicked("agency")}
          >
            <div>
              Agency
              <SortIndicator
                className={styles.sortIndicator}
                sortOrder={props.sortOrder.agency}
              />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell
            width="4"
            className={styles.sortableHeader}
            onClick={() => handleColumnClicked("legalEntity")}
          >
            <div>
              Legal Entity
              <SortIndicator
                className={styles.sortIndicator}
                sortOrder={props.sortOrder.legalEntity}
              />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            width="1"
            className={styles.sortableHeader}
            onClick={() => handleColumnClicked("urgent")}
          >
            <div>
              Urgent
              <SortIndicator
                className={styles.sortIndicator}
                sortOrder={props.sortOrder.urgent}
              />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell
            collapsing
            className={styles.sortableHeader}
            onClick={() => handleColumnClicked("dueDate")}
          >
            <div>
              Due Date
              <SortIndicator
                className={styles.sortIndicator}
                sortOrder={props.sortOrder.dueDate}
              />
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {renderRows()}
      </Table.Body>
    </Table>
  );
}

TaskList.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    legal_entity: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    agency: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }),
    prospect: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }),
    is_urgent: PropTypes.bool.isRequired,
    due_date: PropTypes.number.isRequired,
  })),
  sortOrder: PropTypes.shape({
    taskName: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    agency: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    legalEntity: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    urgent: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    dueDate: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
  }).isRequired,
  onColumnClick: PropTypes.func.isRequired,
  onTaskClick: PropTypes.func.isRequired,
};
