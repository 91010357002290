export const GET_PERMISSIONS_PENDING = "PERMISSIONS/GET_PERMISSIONS_PENDING";
export const GET_PERMISSIONS_SUCCESS = "PERMISSIONS/GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "PERMISSIONS/GET_PERMISSIONS_FAILURE";

export const GET_USER_GROUPS_PENDING = "PERMISSIONS/GET_USER_GROUPS_PENDING";
export const GET_USER_GROUPS_SUCCESS = "PERMISSIONS/GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_FAILURE = "PERMISSIONS/GET_USER_GROUPS_FAILURE";

export const ASSIGN_USER_GROUP_USER_BATCH_PENDING = "PERMISSIONS/ASSIGN_USER_GROUP_USER_BATCH_PENDING";
export const ASSIGN_USER_GROUP_USER_BATCH_SUCCESS = "PERMISSIONS/ASSIGN_USER_GROUP_USER_BATCH_SUCCESS";
export const ASSIGN_USER_GROUP_USER_BATCH_FAILURE = "PERMISSIONS/ASSIGN_USER_GROUP_USER_BATCH_FAILURE";

export const UNASSIGN_USER_GROUP_USER_BATCH_PENDING = "PERMISSIONS/UNASSIGN_USER_GROUP_USER_BATCH_PENDING";
export const UNASSIGN_USER_GROUP_USER_BATCH_SUCCESS = "PERMISSIONS/UNASSIGN_USER_GROUP_USER_BATCH_SUCCESS";
export const UNASSIGN_USER_GROUP_USER_BATCH_FAILURE = "PERMISSIONS/UNASSIGN_USER_GROUP_USER_BATCH_FAILURE";
