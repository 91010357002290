import { DistributeCreditRequestModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} creditRequest the credit request to distribute for
 */
export const showDistributeCreditRequestModal = creditRequest => async (dispatch) => {
  dispatch(modalActions.showModal(DistributeCreditRequestModal.displayName, {
    creditRequest,
  }));
};
