import * as types from "./types";

const initialState = {
  dataExports: {
    loading: true,
    data: [],
    error: null,
  },
  dataImports: {
    loading: true,
    data: [],
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.EXPORT_AGENCIES_PENDING:
    case types.EXPORT_PROSPECTS_PENDING:
    case types.EXPORT_TEMPEST_PENDING:
      return {
        ...state,
        dataExports: {
          ...state.dataExports,
          loading: true,
          error: null,
        },
      };

    case types.EXPORT_AGENCIES_SUCCESS:
    case types.EXPORT_PROSPECTS_SUCCESS:
    case types.EXPORT_TEMPEST_SUCCESS:
      return {
        ...state,
        dataExports: {
          loading: false,
          data: [
            ...state.dataExports.data,
            action.payload.data,
          ],
          error: null,
        },
      };

    case types.EXPORT_AGENCIES_FAILURE:
    case types.EXPORT_PROSPECTS_FAILURE:
    case types.EXPORT_TEMPEST_FAILURE:
      return {
        ...state,
        dataExports: {
          ...state.dataExports,
          loading: false,
          error: action.payload.error,
        },
      };

    case types.GET_DATA_EXPORTS_PENDING:
      return {
        ...state,
        dataExports: {
          ...state.dataExports,
          loading: true,
          error: null,
        },
      };

    case types.GET_DATA_EXPORTS_SUCCESS:
      return {
        ...state,
        dataExports: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_DATA_EXPORTS_FAILURE:
      return {
        ...state,
        dataExports: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.GET_DATA_IMPORTS_PENDING:
      return {
        ...state,
        dataImports: {
          ...state.dataImports,
          loading: true,
          error: null,
        },
      };

    case types.GET_DATA_IMPORTS_SUCCESS:
      return {
        ...state,
        dataImports: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_DATA_IMPORTS_FAILURE:
      return {
        ...state,
        dataImports: {
          loading: false,
          data: [],
          error: action.payload.error,
        },
      };

    case types.IMPORT_PROSPECTS_PENDING:
    case types.IMPORT_AGENCIES_PENDING:
    case types.IMPORT_TEMPEST_PENDING:
      return {
        ...state,
        dataImports: {
          ...state.dataImports,
          loading: true,
          error: null,
        },
      };

    case types.IMPORT_AGENCIES_SUCCESS:
    case types.IMPORT_PROSPECTS_SUCCESS:
    case types.IMPORT_TEMPEST_SUCCESS:
      return {
        ...state,
        dataImports: {
          loading: false,
          data: [
            ...state.dataImports.data,
            action.payload.data,
          ],
          error: null,
        },
      };

    case types.IMPORT_AGENCIES_FAILURE:
    case types.IMPORT_PROSPECTS_FAILURE:
    case types.IMPORT_TEMPEST_FAILURE:
      return {
        ...state,
        dataImports: {
          ...state.dataImports,
          loading: false,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}
