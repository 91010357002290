import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { actions } from "../duck";
import { actions as permissionActions } from "../../permissions/duck";
import {
  Breadcrumb,
  ConfirmationModal,
  EditButton,
  Form,
  Heading,
  PermissionList,
  TabControl,
  Table2,
} from "../../common/components";
import "./EditUserGroupPage.scss";

export class EditUserGroupPage extends Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    userGroupId: PropTypes.string.isRequired,
    userGroup: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
      })).isRequired,
      users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      })).isRequired,
    }),
    permissions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })).isRequired,
    getUserGroup: PropTypes.func.isRequired,
    getPermissions: PropTypes.func.isRequired,
    updateUserGroup: PropTypes.func.isRequired,
    deleteUserGroup: PropTypes.func.isRequired,
    gotoUserGroups: PropTypes.func.isRequired,
    gotoEditUserGroup: PropTypes.func.isRequired,
    gotoEditUser: PropTypes.func.isRequired,
  }

  state = {
    selectedPermissions: [],
    showConfirmationModal: false,
  }

  async componentWillMount() {
    await this.props.getUserGroup(this.props.userGroupId);
    await this.props.getPermissions();

    this.setState({
      selectedPermissions: this.props.userGroup.permissions.map(permission => permission.id),
    });
  }

  render() {
    const { loading, saving, userGroupId, userGroup, permissions } = this.props;
    const { selectedPermissions, showConfirmationModal } = this.state;

    return (
      <div className="EditUserGroupPage">
        <Breadcrumb className="header-breadcrumb">
          <Breadcrumb.Item link="/manage" text="Group Management" />
          <Breadcrumb.Item link="/manage/groups" text="Groups" />
          <Breadcrumb.Item link={`/manage/groups/edit/${userGroupId}`} text={userGroup.name} />
        </Breadcrumb>
        <Heading className="heading" title={userGroup.name} />
        <div className="content">
          <p className="sub-header">Edit Permissions for {userGroup.name}</p>
          <p className="sub-title">{"After you save your changes, we'll email the group and let them know if any of their permissions were updated."}</p>
        </div>
        <TabControl>
          <div title="Permissions">
            <Form onSubmit={this.handleSubmit} onCancel={this.handleCancel} submitting={saving}>
              <PermissionList
                availablePermissions={permissions}
                selectedPermissions={selectedPermissions}
                onSelectionChanged={this.handlePermissionSelectionChanged}
              />
            </Form>
            <div className="remove-button-wrapper">
              <div className="remove-button" onClick={this.handleRemoveClicked}>
                Remove Group <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
          <div title="Users in Group">
            <Table2 loading={loading} data={userGroup.users}>
              <Table2.Column name="name" value={row => `${row.first_name} ${row.last_name}`}>
                Name
              </Table2.Column>
              <Table2.Column name="department" value={row => row.department && row.department.name}>
                Department
              </Table2.Column>
              <Table2.Column collapsed name="edit" value={row => (
                <EditButton onClick={() => this.props.gotoEditUser(row.id)} />
              )}>
                Action
              </Table2.Column>
            </Table2>
          </div>
        </TabControl>
        <ConfirmationModal
          visible={showConfirmationModal}
          title="Delete User Group"
          onSubmit={this.handleRemoveGroup}
          onCancel={() => this.setState({ showConfirmationModal: false })}
        >
          Are you sure you want to delete {userGroup.name}?
        </ConfirmationModal>
      </div>
    );
  }

  handlePermissionSelectionChanged = (permission) => {
    const { selectedPermissions } = this.state;
    const existing = selectedPermissions.find(p => p === permission.id);
    const newSelectedPermissions = existing
      ? selectedPermissions.filter(p => p !== permission.id)
      : [...selectedPermissions, permission.id];

    this.setState({
      selectedPermissions: newSelectedPermissions,
    });
  }

  handleSubmit = async () => {
    await this.props.updateUserGroup({
      userGroupId: this.props.userGroupId,
      name: this.props.userGroup.name,
      description: this.props.userGroup.description,
      permissions: this.state.selectedPermissions,
    });
  }

  handleCancel = () => {
    this.props.gotoUserGroups();
  }

  handleRemoveClicked = () => {
    this.setState({
      showConfirmationModal: true,
    });
  }

  handleRemoveGroup = () => {
    const { loading, saving } = this.props;

    if (loading || saving) return;

    this.props.deleteUserGroup({ userGroupId: this.props.userGroupId });
  }

}

const mapStateToProps = (state, ownProps) => ({
  ...state.groupManagement,
  ...state.permissions,
  userGroupId: ownProps.match.params.id,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...permissionActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditUserGroupPage);
