import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DECLINE_CREDIT_REQUEST = gql`
mutation(
  $id: ID!
  $sort_order: [SortOrder]
  $filter_options: CreditRequestFilterOptions
  ) {
  declineCreditRequest(id: $id) {
    id
    agency {
      id
      known_as
    }
    prospect {
      id
      known_as
    }
    credit_requested
    credit_distributed
    date_communicated
    status
    created_at
    credit_request_communications {
      id
      address_type
      contact {
        first_name
        last_name
        email
      }
    }

    legal_entity {
      id

      credit_requests(sort_order: $sort_order, filter_options: $filter_options) {
        filters {
          field
          possible_values {
            value
            display_value
          }
        }
      }
    }
  }
}
`;

export const declineCreditRequest = id => async (dispatch) => {
  try {
    dispatch({
      type: types.DECLINE_CREDIT_REQUEST_PENDING,
    });

    const response = await apolloClient.query({
      query: DECLINE_CREDIT_REQUEST,
      variables: {
        id,
        sort_order: null,
        filter_options: null,
      },
    });

    if (!response.data.declineCreditRequest) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.DECLINE_CREDIT_REQUEST_SUCCESS,
      payload: {
        data: response.data.declineCreditRequest,
        filters: response.data.declineCreditRequest.legal_entity.credit_requests.filters,
      },
    });
  } catch (err) {
    dispatch({
      type: types.DECLINE_CREDIT_REQUEST_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
