import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_USER = gql`
mutation($input: AddAdminUserInput!) {
  addAdminUser(input: $input) {
    id
    cognito_username
    type
    status
    first_name
    last_name
    email
    created_at
    updated_at
    user_groups {
      id
    }
    department {
      id
      name
    }
  }
}
`;

/**
 *
 * @param {object} user
 * @param {string} user.firstName
 * @param {string} user.lastName
 * @param {string} user.email
 * @param {string} user.userTypeId
 * @param {string} user.departmentId
 * @param {object[]} user.userGroups
 * @param {string} user.userGroups[].id
 * @returns
 */
export const addUser = user => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_USER_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: ADD_USER,
      variables: {
        input: {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          user_type_id: user.userTypeId,
          department_id: user.departmentId,
          user_groups: user.userGroups,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.ADD_USER_SUCCESS,
      payload: {
        user: response.data.addAdminUser,
      },
    });
    dispatch(push("/manage/users"));
  }
  catch (err) {
    dispatch({
      type: types.ADD_USER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
