import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import BranchFormValidator from "./BranchFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./BranchForm.module.scss";

export default class BranchForm extends Component {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      branch_name: PropTypes.string,
      address_line_one: PropTypes.string,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string,
      county: PropTypes.string,
      postcode: PropTypes.string,
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        prospect_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        address_line_one: props.data?.address_line_one,
        address_line_two: props.data?.address_line_two,
        town_or_city: props.data?.town_or_city,
        county: props.data?.county,
        postcode: props.data?.postcode,
      },
      errors: {},
    };
  }

  render() {
    const { submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={14}>
          <Form className={styles.form}>
            <Form.Section title={`Branches - ${this.props.data?.branch_name}`} titleClassName={styles.title}>
              <Form.Group inline wide error={errors?.address_line_one}>
                <Form.Label inline>
                  Address Line 1
                </Form.Label>
                <Form.Input
                  name="address_line_one"
                  value={data.address_line_one}
                  size="large"
                  maxLength={255}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("branch_details")}
                  showLock={this.isFieldDisabled("branch_details")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("branch_details")}
                  highlightError={errors?.address_line_one}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.address_line_two}>
                <Form.Label inline note="(Optional)">
                  Address Line 2
                </Form.Label>
                <Form.Input
                  name="address_line_two"
                  value={data.address_line_two}
                  size="large"
                  maxLength={255}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("branch_details")}
                  showLock={this.isFieldDisabled("branch_details")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("branch_details")}
                  highlightError={errors?.address_line_two}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.town_or_city}>
                <Form.Label>
                  Town / City
                </Form.Label>
                <Form.Input
                  name="town_or_city"
                  value={data.town_or_city}
                  size="large"
                  maxLength={255}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("branch_details")}
                  showLock={this.isFieldDisabled("branch_details")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("branch_details")}
                  highlightError={errors?.town_or_city}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.county}>
                <Form.Label inline note="(Optional)">
                  County
                </Form.Label>
                <Form.Input
                  name="county"
                  value={data.county}
                  size="large"
                  maxLength={255}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("branch_details")}
                  showLock={this.isFieldDisabled("branch_details")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("branch_details")}
                  highlightError={errors?.county}
                />
              </Form.Group>
              <Form.Group inline wide error={errors?.postcode}>
                <Form.Label>
                  Postcode
                </Form.Label>
                <Form.Input
                  name="postcode"
                  value={data.postcode}
                  size="small"
                  maxLength={8}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  disabled={this.isFieldDisabled("branch_details")}
                  showLock={this.isFieldDisabled("branch_details")}
                  onChange={this.handleInputChange}
                  onLockClick={() => this.handleDisabledFieldClick("branch_details")}
                  highlightError={errors?.postcode}
                />
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={submitting}
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const validator = new BranchFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    const dataExcludingDisabledFields = _.omitBy(data, (_value, key) => {
      return this.isFieldDisabled(key);
    });

    onSubmit && onSubmit(dataExcludingDisabledFields);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick && onDisabledFieldClick(disabledField);
  }

  isFieldDisabled = (fieldName) => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
