import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PENSIONS_INFO = gql`
query(
  $prospect_id: ID!
) {
  getProspect(id: $prospect_id) {
    previous_pension_provider {
      id
      value
    }

    auto_enrolment_pension_info {
      pension_company {
        id
        value
      }
      pension_scheme
      staging_date
      default_postponement_months
      eligible_job_holder_postponement_months
      employer_pension_reference
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getPensionsInfo = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PENSIONS_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PENSIONS_INFO,
      variables: {
        prospect_id: prospectId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_PENSIONS_INFO_SUCCESS,
      payload: {
        data: {
          ...response.data.getProspect.auto_enrolment_pension_info,
          ...response.data.getProspect,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PENSIONS_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
