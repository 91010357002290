import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_COMPANY_HOUSE_COMPANY_PROFILE = gql`
query($company_number: String!) {
  getCompaniesHouseCompanyProfile(companyNumber: $company_number) {
    company_name
    type
    company_number
    accounts {
      last_accounts {
        made_up_to
      }
    }
    links {
      self
    }
    previous_company_names {
      name
      ceased_on
      effective_from
    }
    company_status
    company_status_detail
    registered_office_address {
      region
      postal_code
      locality
      address_line_2
      country
      address_line_1
    }
  }
}
`;

export const getSelectedCompanyProfile = company_number => async (dispatch) => {
  try {
    dispatch({ type: types.GET_SELECTED_COMPANY_PROFILE_PENDING });
    const response = await apolloClient.query({
      query: GET_COMPANY_HOUSE_COMPANY_PROFILE,
      variables: {
        company_number,
      },
    });
    const selectedCompanyProfile = response.data.getCompaniesHouseCompanyProfile;

    dispatch({
      type: types.GET_SELECTED_COMPANY_PROFILE_SUCCESS,
      payload: {
        selectedCompanyProfile,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_SELECTED_COMPANY_PROFILE_FAILURE,
      payload: {
        error,
      },
    });
  }
};
