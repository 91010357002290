import _ from "lodash";

export default class EditAccountContactDetailsModalValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (_.isEmpty(data?.fullName)) {
      errors = _.set(errors, "fullName", "Site Contact Full Name is required");
    }

    if (_.isEmpty(data?.telephoneNumber)) {
      errors = _.set(errors, "telephoneNumber", "Telephone Number is required");
    }

    if (_.isEmpty(data?.addressLine1)) {
      errors = _.set(errors, "addressLine1", "Address Line 1 is required");
    }

    if (_.isEmpty(data?.townOrCity)) {
      errors = _.set(errors, "townOrCity", "Town / City is required");
    }

    if (_.isEmpty(data?.postcode)) {
      errors = _.set(errors, "postcode", "Postcode is required");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
