import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Link, Route } from "react-router-dom";
import { Dimmer } from "semantic-ui-react";
import {
  ConvertedToAgencyMessage,
  ConvertToAgencyConfirmationModal,
  ProspectHeading,
} from "../../components";
import {
  AgencyTab,
  ContactsTab,
  CustomersTab,
  DocumentsTab,
  PayrollTab,
  PlaceholderTab,
  ReportingTab,
} from "../../tabs";
import {
  Button,
  ButtonGroup,
  TabControlRouter,
} from "../../../common/components";
import styles from "./ViewProspectPage.module.scss";

export default class ViewProspectPage extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    prospect: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        known_as: PropTypes.string.isRequired,
        logo_url: PropTypes.string,
        agency: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    }),
    onFetchProspect: PropTypes.func.isRequired,
    onBackToProspects: PropTypes.func.isRequired,
    onConvertToAgency: PropTypes.func.isRequired,
    onGoToAgency: PropTypes.func.isRequired,
  }

  state = {
    showConvertToAgencyModal: false,
  }

  async componentDidMount() {
    const { prospectId, prospect, onFetchProspect } = this.props;

    // Make sure we don't reload the Agency every time we change the tab
    if (prospect?.data?.id !== prospectId) {
      await onFetchProspect(prospectId);
    }
  }

  render() {
    const { match, prospect, prospectId } = this.props;

    return (
      <>
        <div>
          <ProspectHeading
            className={styles.header}
            loading={prospect.loading}
            prospect={{
              id: prospectId,
              name: prospect?.data?.known_as,
              logoUrl: prospect?.data?.logo_url,
            }}
            showStatus={!this.hasBeenConvertedToAgency()}
          />
          <hr className={styles.separator} />
          <div className={styles.navBar}>
            <TabControlRouter onTabChanged={this.handleTabChanged}>
              <Link title="Agency" to={`${match.url}/agency`} />
              <Link title="Contacts" to={`${match.url}/contacts`} />
              <Link title="Payroll" to={`${match.url}/payroll`} />
              <Link title="Customers" to={`${match.url}/customers`} disabled />
              <Link title="Documents" to={`${match.url}/documents`} />
              <Link title="Reporting" to={`${match.url}/reporting`} />
            </TabControlRouter>
            <ButtonGroup className={styles.actions}>
              {!this.hasBeenConvertedToAgency() && (
                <Button variant="primary" onClick={this.handleConvertToAgencyClick}>Convert to Agency</Button>
              )}
              <Button variant="secondary" onClick={this.props.onBackToProspects}>Back to Prospects</Button>
            </ButtonGroup>
          </div>
          {this.renderRoutes()}
        </div>
        {this.hasBeenConvertedToAgency() && (
          <ConvertedToAgencyMessage
            visible
            onSubmit={this.handleGoToAgency}
          />
        )}
        {!this.hasBeenConvertedToAgency() && (
          <ConvertToAgencyConfirmationModal
            visible={this.state.showConvertToAgencyModal}
            onCancel={this.handleCancelConvertToAgency}
            onSubmit={this.handleConfirmConvertToAgency}
          />
        )}
      </>
    );
  }

  renderRoutes = () => {
    const { match } = this.props;

    if (this.hasBeenConvertedToAgency()) {
      return (
        <Dimmer.Dimmable className={styles.dimmer} blurring dimmed>
          <Switch>
            <Route exact path={`${match.path}/*`} component={PlaceholderTab} />
          </Switch>
        </Dimmer.Dimmable>
      );
    }

    return (
      <Switch>
        <Route exact path={`${match.path}/agency`} component={AgencyTab} />
        <Route exact path={`${match.path}/contacts`} component={ContactsTab} />
        <Route exact path={`${match.path}/customers`} component={CustomersTab} />
        <Route exact path={`${match.path}/documents`} component={DocumentsTab} />
        <Route exact path={`${match.path}/payroll`} component={PayrollTab} />
        <Route exact path={`${match.path}/reporting`} component={ReportingTab} />
      </Switch>
    );
  }

  handleTabChanged = (title) => {
    switch (title) {
      case "Agency":
        break;

      case "People":
        break;

      case "Payroll":
        break;

      case "Customers":
        break;

      case "Documents":
        break;

      case "Reporting":
        break;

      default:
        break;
    }
  }

  handleConvertToAgencyClick = () => {
    this.setState({
      showConvertToAgencyModal: true,
    });
  }

  handleCancelConvertToAgency = async () => {
    this.setState({
      showConvertToAgencyModal: false,
    });
  }

  handleConfirmConvertToAgency = () => {
    const { prospectId, onConvertToAgency } = this.props;

    this.setState({
      showConvertToAgencyModal: false,
    }, () => onConvertToAgency(prospectId));
  }

  hasBeenConvertedToAgency = () => {
    return !!this.props.prospect?.data?.agency?.id;
  }

  handleGoToAgency = async () => {
    const { prospect, onGoToAgency } = this.props;

    if (!prospect?.data?.agency?.id) {
      // eslint-disable-next-line no-console
      console.error("Could not get the Agency ID for the prospect");

      return;
    }

    onGoToAgency && await onGoToAgency(prospect?.data?.agency?.id);
  }

}
