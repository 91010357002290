import React from "react";
import PropTypes from "prop-types";
import styles from "./MIReportGroupItem.module.scss";

export default function MIReportGroupItem(props) {
  return (
    <span className={styles.text}>{props.value}</span>
  );
}

MIReportGroupItem.propTypes = {
  value: PropTypes.string,
};
