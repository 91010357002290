import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./BasicModal.scss";

export default class BasicModal extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    showCloseButton: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }

  render() {
    const { className, children, visible, onClose, showCloseButton, title, subtitle } = this.props;

    return (
      <div
        className={classnames(
          "BasicModal--wrapper",
          `BasicModal--${visible ? "visible" : "hidden"}`
        )}
        onClick={onClose}
      >
        <div className={classnames("BasicModal", className)} onClick={this.handleModalClicked}>
          {(showCloseButton || title) && (
            <div className={classnames("header", title && "header--title")}>
              <div>
                <div className="title">
                  {title || ""}
                </div>
                {subtitle &&
                  (<div className="subtitle">
                    {subtitle}
                  </div>)}
              </div>
              <div className={classnames("close-button", showCloseButton || "hidden")}>
                <FontAwesomeIcon icon={faTimes} size="2x" onClick={onClose} />
              </div>
            </div>
          )}
          <div className="content">
            {children}
          </div>
        </div>
      </div>
    );
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleModalClicked = (e) => {
    e.stopPropagation();
  }

}
