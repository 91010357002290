import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const GET_DATA_IMPORTS = gql`
query {
  getDataImports {
    import_id
    import_type
    status
    created_at
    created_by {
      first_name
      last_name
    }
    error
    url
  }
}
`;

export const getDataImports = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_DATA_IMPORTS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_DATA_IMPORTS,
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_DATA_IMPORTS_SUCCESS,
      payload: {
        data: response.data.getDataImports,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_DATA_IMPORTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
