import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { ConfirmationModal, DocumentVersionDownload } from "../../../common/components";
import styles from "./AgencyDocumentVersionHistoryModal.module.scss";

export default function AgencyDocumentVersionHistoryModal (props) {
  const { agencyId, documentType, getAgencyDocument } = props;

  useEffect(() => {
    getAgencyDocument({
      agencyId,
      documentType,
    });
  }, [agencyId, documentType, getAgencyDocument]);

  const handleCancel = () => {
    props.hideModal(AgencyDocumentVersionHistoryModal.displayName);
  };

  const handleDelete = (version) => {
    props.showDeleteDocumentVersionModal({
      agencyId,
      document: props.document,
      versionId: version.versionId,
    });
  };

  const renderVersions = () => {
    if (props.loading) {
      return <ActivityIndicator />;
    }

    return (
      <DocumentVersionDownload
        agencyId={agencyId}
        document={props.document}
        documentType={documentType}
        onDelete={handleDelete}
        onDownload={props.downloadAgencyDocument}
      />
    );
  };

  return (
    <ConfirmationModal
      title="Version History"
      visible={props.visible}
      className={styles.container}
      cancelText="Close"
      cancelVariant="outline-primary"
      onCancel={handleCancel}>
      {renderVersions()}
    </ConfirmationModal>
  );
}

AgencyDocumentVersionHistoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  agencyId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  document: PropTypes.shape({
    name: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.shape({
      isLatest: PropTypes.bool,
      versionId: PropTypes.string,
      size: PropTypes.number,
      metadata: PropTypes.shape({
        date_uploaded: PropTypes.number,
      }),
    })),
  }),
  removeVersion: PropTypes.func.isRequired,
  downloadAgencyDocument: PropTypes.func.isRequired,
  getAgencyDocument: PropTypes.func.isRequired,
  showDeleteDocumentVersionModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

AgencyDocumentVersionHistoryModal.displayName = "AgencyDocumentVersionHistoryModal";
