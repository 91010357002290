import _ from "lodash";

export default class CommunicateToAgencyModalValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (data.some(x => !x.contactId)) {
      errors = _.set(errors, "recipient", "Not all recipients filled in");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
