import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import {
  EditAgencyDetailsPage,
  EditAutoEnrolmentPensionsPage,
  EditBankInformationPage,
  EditBranchPage,
  EditContractInformationPage,
  EditMiReportGroupingPage,
  EditPayrollGeneralPage,
  EditTradingAddressPage,
  EditVatInformationPage,
  EditWtrHolidayPage,
} from "../";
import { ProspectHeading } from "../../components";
import styles from "./EditProspectPage.module.scss";

export default class EditProspectPage extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    prospect: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        known_as: PropTypes.string.isRequired,
        logo_url: PropTypes.string,
        agency: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    }),
    onFetchProspect: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { prospectId, prospect, onFetchProspect } = this.props;

    if (prospect?.data?.id !== prospectId) {
      await onFetchProspect(prospectId);
    }
  }

  render() {
    const { match, prospect, prospectId } = this.props;

    return (
      <div>
        <ProspectHeading
          className={styles.header}
          loading={prospect.loading}
          prospect={{
            id: prospectId,
            name: prospect?.data?.known_as,
            logoUrl: prospect?.data?.logo_url,
          }}
          showStatus
        />
        <hr className={styles.separator} />
        <Switch>
          <Route exact path={`${match.path}/agency/agency-details`} component={EditAgencyDetailsPage} />
          <Route exact path={`${match.path}/agency/bank-information`} component={EditBankInformationPage} />
          <Route exact path={`${match.path}/agency/branch/:branchId`} component={EditBranchPage} />
          <Route exact path={`${match.path}/agency/contract-information`} component={EditContractInformationPage} />
          <Route exact path={`${match.path}/agency/trading-address`} component={EditTradingAddressPage} />
          <Route exact path={`${match.path}/payroll/general`} component={EditPayrollGeneralPage} />
          <Route exact path={`${match.path}/payroll/wtr-holiday`} component={EditWtrHolidayPage} />
          <Route exact path={`${match.path}/payroll/vat-information`} component={EditVatInformationPage} />
          <Route exact path={`${match.path}/payroll/auto-enrolment-pensions`} component={EditAutoEnrolmentPensionsPage} />
          <Route exact path={`${match.path}/reporting/mi-report-grouping`} component={EditMiReportGroupingPage} />
        </Switch>
      </div>
    );
  }

}
