import _ from "lodash";

const format = (companyType) => {
  if (!companyType) return "";

  switch (companyType) {
    case "RegisteredCompany":
      return "Limited";

    case "RegisteredCharity":
      return "Limited";

    case "Charity":
      return "Charity";

    case "Government":
      return "Non-registered";

    case "Partnership":
      return "Non-registered";

    case "SoleTrader":
      return "Non-registered";

    default:
      return _.startCase(companyType);
  }
};

export default {
  format,
};
