export const ADD_PROSPECT_PENDING = "PROSPECTS/ADD_PROSPECT_PENDING";
export const ADD_PROSPECT_SUCCESS = "PROSPECTS/ADD_PROSPECT_SUCCESS";
export const ADD_PROSPECT_FAILURE = "PROSPECTS/ADD_PROSPECT_FAILURE";
export const ADD_PROSPECT_CONTACT_PENDING = "PROSPECTS/ADD_PROSPECT_CONTACT_PENDING";
export const ADD_PROSPECT_CONTACT_SUCCESS = "PROSPECTS/ADD_PROSPECT_CONTACT_SUCCESS";
export const ADD_PROSPECT_CONTACT_FAILURE = "PROSPECTS/ADD_PROSPECT_CONTACT_FAILURE";
export const ARCHIVE_PROSPECT_CONTACT_PENDING = "PROSPECTS/ARCHIVE_PROSPECT_CONTACT_PENDING";
export const ARCHIVE_PROSPECT_CONTACT_SUCCESS = "PROSPECTS/ARCHIVE_PROSPECT_CONTACT_SUCCESS";
export const ARCHIVE_PROSPECT_CONTACT_FAILURE = "PROSPECTS/ARCHIVE_PROSPECT_CONTACT_FAILURE";
export const CHECK_SHORT_CODE_EXISTS_PENDING = "PROSPECTS/CHECK_SHORT_CODE_EXISTS_PENDING";
export const CHECK_SHORT_CODE_EXISTS_SUCCESS = "PROSPECTS/CHECK_SHORT_CODE_EXISTS_SUCCESS";
export const CHECK_SHORT_CODE_EXISTS_FAILURE = "PROSPECTS/CHECK_SHORT_CODE_EXISTS_FAILURE";
export const CONVERT_TO_AGENCY_PENDING = "PROSPECTS/CONVERT_TO_AGENCY_PENDING";
export const CONVERT_TO_AGENCY_SUCCESS = "PROSPECTS/CONVERT_TO_AGENCY_SUCCESS";
export const CONVERT_TO_AGENCY_FAILURE = "PROSPECTS/CONVERT_TO_AGENCY_FAILURE";
export const DELETE_PROSPECT_CONTACT_PENDING = "PROSPECTS/DELETE_PROSPECT_CONTACT_PENDING";
export const DELETE_PROSPECT_CONTACT_SUCCESS = "PROSPECTS/DELETE_PROSPECT_CONTACT_SUCCESS";
export const DELETE_PROSPECT_CONTACT_FAILURE = "PROSPECTS/DELETE_PROSPECT_CONTACT_FAILURE";
export const GET_AGENCY_DETAILS_PENDING = "PROSPECTS/GET_AGENCY_DETAILS_PENDING";
export const GET_AGENCY_DETAILS_SUCCESS = "PROSPECTS/GET_AGENCY_DETAILS_SUCCESS";
export const GET_AGENCY_DETAILS_FAILURE = "PROSPECTS/GET_AGENCY_DETAILS_FAILURE";
export const GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_PENDING = "PROSPECTS/GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_PENDING";
export const GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_SUCCESS = "PROSPECTS/GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_SUCCESS";
export const GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_FAILURE = "PROSPECTS/GET_COMPANIES_HOUSE_INFO_FOR_NEW_PROSPECT_FAILURE";
export const GET_CUSTOMERS_PENDING = "PROSPECTS/GET_CUSTOMERS_PENDING";
export const GET_CUSTOMERS_SUCCESS = "PROSPECTS/GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "PROSPECTS/GET_CUSTOMERS_FAILURE";
export const GET_PROSPECT_PENDING = "PROSPECTS/GET_PROSPECT_PENDING";
export const GET_PROSPECT_SUCCESS = "PROSPECTS/GET_PROSPECT_SUCCESS";
export const GET_PROSPECT_FAILURE = "PROSPECTS/GET_PROSPECT_FAILURE";
export const GET_PROSPECT_CONTACTS_PENDING = "PROSPECTS/GET_PROSPECT_CONTACTS_PENDING";
export const GET_PROSPECT_CONTACTS_SUCCESS = "PROSPECTS/GET_PROSPECT_CONTACTS_SUCCESS";
export const GET_PROSPECT_CONTACTS_FAILURE = "PROSPECTS/GET_PROSPECT_CONTACTS_FAILURE";
export const GET_PROSPECT_DISABLED_FIELDS_PENDING = "GET_PROSPECT_DISABLED_FIELDS_PENDING";
export const GET_PROSPECT_DISABLED_FIELDS_SUCCESS = "GET_PROSPECT_DISABLED_FIELDS_SUCCESS";
export const GET_PROSPECT_DISABLED_FIELDS_FAILURE = "GET_PROSPECT_DISABLED_FIELDS_FAILURE";
export const GET_PROSPECT_MI_REPORT_GROUPINGS_PENDING = "PROSPECTS/GET_PROSPECT_MI_REPORT_GROUPINGS_PENDING";
export const GET_PROSPECT_MI_REPORT_GROUPINGS_SUCCESS = "PROSPECTS/GET_PROSPECT_MI_REPORT_GROUPINGS_SUCCESS";
export const GET_PROSPECT_MI_REPORT_GROUPINGS_FAILURE = "PROSPECTS/GET_PROSPECT_MI_REPORT_GROUPINGS_FAILURE";
export const GET_PROSPECT_TO_BE_CONVERTED_PENDING = "PROSPECTS/GET_PROSPECT_TO_BE_CONVERTED_PENDING";
export const GET_PROSPECT_TO_BE_CONVERTED_SUCCESS = "PROSPECTS/GET_PROSPECT_TO_BE_CONVERTED_SUCCESS";
export const GET_PROSPECT_TO_BE_CONVERTED_FAILURE = "PROSPECTS/GET_PROSPECT_TO_BE_CONVERTED_FAILURE";
export const GET_PROSPECTS_PENDING = "PROSPECTS/GET_PROSPECTS_PENDING";
export const GET_PROSPECTS_SUCCESS = "PROSPECTS/GET_PROSPECTS_SUCCESS";
export const GET_PROSPECTS_FAILURE = "PROSPECTS/GET_PROSPECTS_FAILURE";
export const GET_BACK_OFFICE_CONTACTS_SUCCESS = "PROSPECTS/GET_BACK_OFFICE_CONTACTS_SUCCESS";
export const GET_BACK_OFFICE_CONTACTS_PENDING = "PROSPECTS/GET_BACK_OFFICE_CONTACTS_PENDING";
export const GET_BACK_OFFICE_CONTACTS_FAILURE = "PROSPECTS/GET_BACK_OFFICE_CONTACTS_FAILURE";
export const GET_BANK_INFORMATION_PENDING = "PROSPECTS/GET_BANK_INFORMATION_PENDING";
export const GET_BANK_INFORMATION_SUCCESS = "PROSPECTS/GET_BANK_INFORMATION_SUCCESS";
export const GET_BANK_INFORMATION_FAILURE = "PROSPECTS/GET_BANK_INFORMATION_FAILURE";
export const GET_BRANCH_PENDING = "PROSPECTS/GET_BRANCH_PENDING";
export const GET_BRANCH_SUCCESS = "PROSPECTS/GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAILURE = "PROSPECTS/GET_BRANCH_FAILURE";
export const GET_BUSINESS_DEVELOPMENT_MANAGERS_PENDING = "PROSPECTS/GET_BUSINESS_DEVELOPMENT_MANAGERS_PENDING";
export const GET_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS = "PROSPECTS/GET_BUSINESS_DEVELOPMENT_MANAGERS_SUCCESS";
export const GET_BUSINESS_DEVELOPMENT_MANAGERS_FAILURE = "PROSPECTS/GET_BUSINESS_DEVELOPMENT_MANAGERS_FAILURE";
export const GET_COMPANY_INFO_PENDING = "PROSPECTS/GET_COMPANY_INFO_PENDING";
export const GET_COMPANY_INFO_SUCCESS = "PROSPECTS/GET_COMPANY_INFO_SUCCESS";
export const GET_COMPANY_INFO_FAILURE = "PROSPECTS/GET_COMPANY_INFO_FAILURE";
export const GET_COMPANY_HISTORY_PENDING = "PROSPECTS/GET_COMPANY_HISTORY_PENDING";
export const GET_COMPANY_HISTORY_SUCCESS = "PROSPECTS/GET_COMPANY_HISTORY_SUCCESS";
export const GET_COMPANY_HISTORY_FAILURE = "PROSPECTS/GET_COMPANY_HISTORY_FAILURE";
export const GET_CONTRACT_INFORMATION_PENDING = "PROSPECTS/GET_CONTRACT_INFORMATION_PENDING";
export const GET_CONTRACT_INFORMATION_SUCCESS = "PROSPECTS/GET_CONTRACT_INFORMATION_SUCCESS";
export const GET_CONTRACT_INFORMATION_FAILURE = "PROSPECTS/GET_CONTRACT_INFORMATION_FAILURE";
export const GET_HOLIDAY_PAY_INFO_PENDING = "PROSPECTS/GET_HOLIDAY_PAY_INFO_PENDING";
export const GET_HOLIDAY_PAY_INFO_SUCCESS = "PROSPECTS/GET_HOLIDAY_PAY_INFO_SUCCESS";
export const GET_HOLIDAY_PAY_INFO_FAILURE = "PROSPECTS/GET_HOLIDAY_PAY_INFO_FAILURE";
export const GET_MI_REPORT_GROUPING_PENDING = "PROSPECTS/GET_MI_REPORT_GROUPING_PENDING";
export const GET_MI_REPORT_GROUPING_SUCCESS = "PROSPECTS/GET_MI_REPORT_GROUPING_SUCCESS";
export const GET_MI_REPORT_GROUPING_FAILURE = "PROSPECTS/GET_MI_REPORT_GROUPING_FAILURE";
export const GET_PAYROLL_HISTORY_PENDING = "AGENCIES/GET_PAYROLL_HISTORY_PENDING";
export const GET_PAYROLL_HISTORY_SUCCESS = "AGENCIES/GET_PAYROLL_HISTORY_SUCCESS";
export const GET_PAYROLL_HISTORY_FAILURE = "AGENCIES/GET_PAYROLL_HISTORY_FAILURE";
export const GET_PAYROLL_INFO_PENDING = "PROSPECTS/GET_PAYROLL_INFO_PENDING";
export const GET_PAYROLL_INFO_SUCCESS = "PROSPECTS/GET_PAYROLL_INFO_SUCCESS";
export const GET_PAYROLL_INFO_FAILURE = "PROSPECTS/GET_PAYROLL_INFO_FAILURE";
export const GET_PAYROLL_GENERAL_PENDING = "PROSPECTS/GET_PAYROLL_GENERAL_PENDING";
export const GET_PAYROLL_GENERAL_SUCCESS = "PROSPECTS/GET_PAYROLL_GENERAL_SUCCESS";
export const GET_PAYROLL_GENERAL_FAILURE = "PROSPECTS/GET_PAYROLL_GENERAL_FAILURE";
export const GET_PENSIONS_INFO_PENDING = "PROSPECTS/GET_PENSIONS_INFO_PENDING";
export const GET_PENSIONS_INFO_SUCCESS = "PROSPECTS/GET_PENSIONS_INFO_SUCCESS";
export const GET_PENSIONS_INFO_FAILURE = "PROSPECTS/GET_PENSIONS_INFO_FAILURE";
export const GET_REPORTING_INFO_PENDING = "PROSPECTS/GET_REPORTING_INFO_PENDING";
export const GET_REPORTING_INFO_SUCCESS = "PROSPECTS/GET_REPORTING_INFO_SUCCESS";
export const GET_REPORTING_INFO_FAILURE = "PROSPECTS/GET_REPORTING_INFO_FAILURE";
export const GET_TRADING_ADDRESS_PENDING = "PROSPECTS/GET_TRADING_ADDRESS_PENDING";
export const GET_TRADING_ADDRESS_SUCCESS = "PROSPECTS/GET_TRADING_ADDRESS_SUCCESS";
export const GET_TRADING_ADDRESS_FAILURE = "PROSPECTS/GET_TRADING_ADDRESS_FAILURE";
export const GET_VAT_INFORMATION_PENDING = "PROSPECTS/GET_VAT_INFORMATION_PENDING";
export const GET_VAT_INFORMATION_SUCCESS = "PROSPECTS/GET_VAT_INFORMATION_SUCCESS";
export const GET_VAT_INFORMATION_FAILURE = "PROSPECTS/GET_VAT_INFORMATION_FAILURE";
export const SEARCH_COMPANIES_HOUSE_PENDING = "PROSPECTS/SEARCH_COMPANIES_HOUSE_PENDING";
export const SEARCH_COMPANIES_HOUSE_SUCCESS = "PROSPECTS/SEARCH_COMPANIES_HOUSE_SUCCESS";
export const SEARCH_COMPANIES_HOUSE_FAILURE = "PROSPECTS/SEARCH_COMPANIES_HOUSE_FAILURE";
export const UPDATE_AGENCY_DETAILS_PENDING = "PROSPECTS/UPDATE_AGENCY_DETAILS_PENDING";
export const UPDATE_AGENCY_DETAILS_SUCCESS = "PROSPECTS/UPDATE_AGENCY_DETAILS_SUCCESS";
export const UPDATE_AGENCY_DETAILS_FAILURE = "PROSPECTS/UPDATE_AGENCY_DETAILS_FAILURE";
export const UPDATE_BANK_INFORMATION_PENDING = "PROSPECTS/UPDATE_BANK_INFORMATION_PENDING";
export const UPDATE_BANK_INFORMATION_SUCCESS = "PROSPECTS/UPDATE_BANK_INFORMATION_SUCCESS";
export const UPDATE_BANK_INFORMATION_FAILURE = "PROSPECTS/UPDATE_BANK_INFORMATION_FAILURE";
export const UPDATE_BRANCH_PENDING = "PROSPECTS/UPDATE_BRANCH_PENDING";
export const UPDATE_BRANCH_SUCCESS = "PROSPECTS/UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILURE = "PROSPECTS/UPDATE_BRANCH_FAILURE";
export const UPDATE_CONTRACT_INFORMATION_PENDING = "PROSPECTS/UPDATE_CONTRACT_INFORMATION_PENDING";
export const UPDATE_CONTRACT_INFORMATION_SUCCESS = "PROSPECTS/UPDATE_CONTRACT_INFORMATION_SUCCESS";
export const UPDATE_CONTRACT_INFORMATION_FAILURE = "PROSPECTS/UPDATE_CONTRACT_INFORMATION_FAILURE";
export const UPDATE_HOLIDAY_PAY_INFO_PENDING = "PROSPECTS/UPDATE_HOLIDAY_PAY_INFO_PENDING";
export const UPDATE_HOLIDAY_PAY_INFO_SUCCESS = "PROSPECTS/UPDATE_HOLIDAY_PAY_INFO_SUCCESS";
export const UPDATE_HOLIDAY_PAY_INFO_FAILURE = "PROSPECTS/UPDATE_HOLIDAY_PAY_INFO_FAILURE";
export const UPDATE_MI_REPORT_GROUPING_PENDING = "PROSPECTS/UPDATE_MI_REPORT_GROUPING_PENDING";
export const UPDATE_MI_REPORT_GROUPING_SUCCESS = "PROSPECTS/UPDATE_MI_REPORT_GROUPING_SUCCESS";
export const UPDATE_MI_REPORT_GROUPING_FAILURE = "PROSPECTS/UPDATE_MI_REPORT_GROUPING_FAILURE";
export const UPDATE_PAYROLL_GENERAL_PENDING = "PROSPECTS/UPDATE_PAYROLL_GENERAL_PENDING";
export const UPDATE_PAYROLL_GENERAL_SUCCESS = "PROSPECTS/UPDATE_PAYROLL_GENERAL_SUCCESS";
export const UPDATE_PAYROLL_GENERAL_FAILURE = "PROSPECTS/UPDATE_PAYROLL_GENERAL_FAILURE";
export const UPDATE_PENSIONS_INFO_PENDING = "PROSPECTS/UPDATE_PENSIONS_INFO_PENDING";
export const UPDATE_PENSIONS_INFO_SUCCESS = "PROSPECTS/UPDATE_PENSIONS_INFO_SUCCESS";
export const UPDATE_PENSIONS_INFO_FAILURE = "PROSPECTS/UPDATE_PENSIONS_INFO_FAILURE";
export const UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_PENDING = "PROSPECTS/UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_PENDING";
export const UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_SUCCESS = "PROSPECTS/UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_SUCCESS";
export const UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_FAILURE = "PROSPECTS/UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_FAILURE";
export const UPDATE_PROSPECT_CONTACT_PENDING = "PROSPECTS/UPDATE_PROSPECT_CONTACT_PENDING";
export const UPDATE_PROSPECT_CONTACT_SUCCESS = "PROSPECTS/UPDATE_PROSPECT_CONTACT_SUCCESS";
export const UPDATE_PROSPECT_CONTACT_FAILURE = "PROSPECTS/UPDATE_PROSPECT_CONTACT_FAILURE";
export const UPDATE_TRADING_ADDRESS_PENDING = "PROSPECTS/UPDATE_TRADING_ADDRESS_PENDING";
export const UPDATE_TRADING_ADDRESS_SUCCESS = "PROSPECTS/UPDATE_TRADING_ADDRESS_SUCCESS";
export const UPDATE_TRADING_ADDRESS_FAILURE = "PROSPECTS/UPDATE_TRADING_ADDRESS_FAILURE";
export const UPDATE_VAT_INFORMATION_PENDING = "PROSPECTS/UPDATE_VAT_INFORMATION_PENDING";
export const UPDATE_VAT_INFORMATION_SUCCESS = "PROSPECTS/UPDATE_VAT_INFORMATION_SUCCESS";
export const UPDATE_VAT_INFORMATION_FAILURE = "PROSPECTS/UPDATE_VAT_INFORMATION_FAILURE";
