import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import { actions as documentActions } from "../../../documents/duck";
import AgencyDocumentVersionHistoryModal from "./AgencyDocumentVersionHistoryModal";

const mapStateToProps = state => ({
  documentTypes: state.documents.documentTypes,
  document: state.documents.agencyDocument?.data,
  loading: state.documents.agencyDocument?.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
  ...documentActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(AgencyDocumentVersionHistoryModal);

container.displayName = AgencyDocumentVersionHistoryModal.displayName;

export default container;
