import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import EditCreditApplicationModal from "./EditCreditApplicationModal";

const mapStateToProps = state => ({
  submitting: state.creditLimitManagement.ui.submitting,
  loading: state.creditLimitManagement.creditApplicationForApproval.loading,
  creditLimitRequested: state.creditLimitManagement.creditApplicationForApproval.data?.credit_limit_requested,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(EditCreditApplicationModal);

container.displayName = "EditCreditApplicationModal";

export default container;
