import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Edit } from "@material-ui/icons";
import { options } from "../../../../constants";
import { Form, IconButton } from "../../../common/components";
import styles from "./ContractPaymentDetails.module.scss";

export default function ContractPaymentDetails(props) {
  return (
    <Form.Card
      title="Payment Details"
      variant="h2"
      className={styles.card}
      rightAccessory={
        <IconButton
          icon={
            <div className={styles.edit}>
              <Edit /> Edit
            </div>
          }
          // onClick={props.onEditClick}
        />
      }
    >
      <Form.Section title="Purchase Order">
        <Form.Group inline>
          <Form.Label>
            Is a P.O. Number required?
          </Form.Label>
          <Form.Label className={styles.value}>
            {props.contract?.is_purchase_order_number_required
              ? "Yes"
              : "No"
            }
          </Form.Label>
        </Form.Group>
      </Form.Section>

      <Form.Section title="Agreed Payment Terms">
        <Form.Group inline>
          <Form.Label>
            Agreed Payment Terms
          </Form.Label>
          <Form.Label className={styles.value}>
            {options.AGREED_PAYMENT_TERMS_OPTIONS?.find(
              option => option.value === props.contract?.agreed_payment_terms_type
            )?.label || "-"}
          </Form.Label>
        </Form.Group>

        {props.contract?.agreed_payment_terms_type === "days_after_invoice_date" && (
          <Form.Group inline>
            <Form.Label>
              Number of days after invoice
            </Form.Label>
            <Form.Label className={styles.value}>
              {props.contract?.agreed_payment_terms_value}
            </Form.Label>
          </Form.Group>
        )}

        {props.contract?.agreed_payment_terms_type === "day_of_month_following_invoice" && (
          <Form.Group inline>
            <Form.Label>
              Day of the month
            </Form.Label>
            <Form.Label className={styles.value}>
              {moment(props.contract?.agreed_payment_terms_value, "DD").format("Do")}
            </Form.Label>
          </Form.Group>
        )}
      </Form.Section>
    </Form.Card>
  );
}

ContractPaymentDetails.propTypes = {
  contract: PropTypes.shape({
    is_purchase_order_number_required: PropTypes.bool,
    agreed_payment_terms_type: PropTypes.string,
    agreed_payment_terms_value: PropTypes.number,
  }),
  onEditClick: PropTypes.func.isRequired,
};
