/**
 * Converts the bytes to a gigabyte (GB)
 *
 * Note: A Gigabyte is equal to 1,000,000,000 bytes
 * @param {number} bytes
 * @returns {number}
 */
const bytesToGigabytes = (bytes) => {
  return bytes / Math.pow(1000, 3);
};

export default bytesToGigabytes;
