export const isAddNewCustomerComplete = (formData) => {
  if (!formData.agency?.id) {
    return false;
  }

  if (!formData.exactLegalName) {
    return false;
  }

  if (!formData.companyType) {
    return false;
  }

  if (formData.isRegisteredCharity && !formData.registeredCharityNumber) {
    return false;
  }

  if ([
    "limited_company",
    "limited_liability_partnership",
    "international",
  ].includes(formData.companyType)) {
    if (!formData.registeredCompanyNumber) {
      return false;
    }
  }

  if (formData.companyType === "sole_trader") {
    if (!formData.soleTraderName) {
      return false;
    }
  }

  if ([
    "limited_company",
    "limited_liability_partnership",
    "partnership",
    "sole_trader",
    "international",
  ].includes(formData.companyType)) {
    if (!formData.creditLimitRequested) {
      return false;
    }
  }

  if ([
    "sole_trader",
    "partnership",
    "government",
  ].includes(formData.companyType)) {
    if (!formData.addressLine1) {
      return false;
    }

    if (!formData.townOrCity) {
      return false;
    }

    if (!formData.postcode) {
      return false;
    }
  }

  if (formData.companyType === "international") {
    if (!formData.internationalTradingName) {
      return false;
    }

    if (!formData.addressLine1) {
      return false;
    }

    if (!formData.townOrCity) {
      return false;
    }

    if (!formData.postcode) {
      return false;
    }

    if (!formData.country) {
      return false;
    }
  }

  return true;
};
