import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Accordion } from "semantic-ui-react";
import { ExpandMore, Remove } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import { List } from "../../../../lists/containers";
import styles from "./PartnerListItem.module.scss";

export default function PartnerListItem(props) {
  const handleChange = (e) => {
    props.onChange && props.onChange(props.index, {
      ...props.partner,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddressChange = (e) => {
    props.onChange && props.onChange(props.index, {
      ...props.partner,
      address: {
        ...props.partner.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <>
      <Accordion.Title
        className={styles.container}
        index={props.index}
        active={props.active}
        onClick={props.onClick.bind(this, props.index)}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            Partner {props.index + 1}
          </div>
          <ExpandMore
            className={classnames(
              styles.icon,
              props.active ? styles.collapse : styles.expand
            )}
          />
        </div>
      </Accordion.Title>
      <Accordion.Content active={props.active}>
        <div>
          <Form.Section>
            <Form.Group error={props.errors?.title}>
              <Form.Label size="small">Title</Form.Label>
              <Form.Value size="small">
                <List
                  className={classnames(
                    styles.titleSelect,
                    props.errors?.title && styles.error,
                  )}
                  identifier="title"
                  placeholder="Select Title..."
                  value={props.partner.title}
                  readOnly={props.readOnly}
                  onChange={handleChange}
                  highlightError={props.errors?.title}
                />
              </Form.Value>
            </Form.Group>
            <Form.Group error={props.errors?.first_name}>
              <Form.Label>First Name</Form.Label>
              <Form.Input
                placeholder="Enter First Name..."
                name="first_name"
                value={props.partner.first_name}
                readOnly={props.readOnly}
                onChange={handleChange}
                highlightError={props.errors?.first_name}
                maxLength={100}
              />
            </Form.Group>
            <Form.Group error={props.errors?.last_name}>
              <Form.Label>Last Name</Form.Label>
              <Form.Input
                placeholder="Enter Last Name..."
                name="last_name"
                value={props.partner.last_name}
                readOnly={props.readOnly}
                onChange={handleChange}
                highlightError={props.errors?.last_name}
                maxLength={100}
              />
            </Form.Group>
            <Form.Group error={props.errors?.phone_number}>
              <Form.Label size="small" note={props.readOnly || "(Optional)"}>Phone Number</Form.Label>
              <Form.Input
                size="small"
                placeholder="Enter Phone Number..."
                name="phone_number"
                value={props.partner.phone_number}
                readOnly={props.readOnly}
                onChange={handleChange}
                highlightError={props.errors?.phone_number}
                maxLength={30}
              />
            </Form.Group>
          </Form.Section>
          <Form.Section title="Partner Address">
            <Form.Group error={props.errors?.address?.address_line_one}>
              <Form.Label>Address Line 1</Form.Label>
              <Form.Input
                placeholder="Address Line 1..."
                name="address_line_one"
                value={props.partner.address?.address_line_one}
                readOnly={props.readOnly}
                onChange={handleAddressChange}
                highlightError={props.errors?.address?.address_line_one}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.address?.address_line_two}>
              <Form.Label note={props.readOnly || "(Optional)"}>Address Line 2</Form.Label>
              <Form.Input
                placeholder="Address Line 2..."
                name="address_line_two"
                value={props.partner.address?.address_line_two}
                readOnly={props.readOnly}
                onChange={handleAddressChange}
                highlightError={props.errors?.address?.address_line_two}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.address?.town_or_city}>
              <Form.Label>Town / City</Form.Label>
              <Form.Input
                placeholder="Town / City..."
                name="town_or_city"
                value={props.partner.address?.town_or_city}
                readOnly={props.readOnly}
                onChange={handleAddressChange}
                highlightError={props.errors?.address?.town_or_city}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.address?.county}>
              <Form.Label>County</Form.Label>
              <Form.Input
                placeholder="County..."
                name="county"
                value={props.partner.address?.county}
                readOnly={props.readOnly}
                onChange={handleAddressChange}
                highlightError={props.errors?.address?.county}
                maxLength={255}
              />
            </Form.Group>
            <Form.Group error={props.errors?.address?.postcode}>
              <Form.Label size="small">Postcode</Form.Label>
              <Form.Input
                size="small"
                placeholder="Postcode..."
                name="postcode"
                value={props.partner.address?.postcode}
                readOnly={props.readOnly}
                onChange={handleAddressChange}
                highlightError={props.errors?.address?.postcode}
                maxLength={8}
              />
            </Form.Group>
          </Form.Section>
          {!props.readOnly && !props.partner.id && props.onRemoveClick && (
            <IconButton
              className={styles.removeButton}
              icon={(<Remove />)}
              iconPosition="left"
              onClick={props.onRemoveClick.bind(this, props.index)}
              text="Remove Partner"
            />
          )}
        </div>
      </Accordion.Content>
    </>
  );
}

PartnerListItem.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  partner: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone_number: PropTypes.string,
    address: PropTypes.shape({
      address_line_one: PropTypes.string.isRequired,
      address_line_two: PropTypes.string,
      town_or_city: PropTypes.string.isRequired,
      county: PropTypes.string.isRequired,
      postcode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    title: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};
