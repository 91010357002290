import React, { useState } from "react";
import PropTypes from "prop-types";
import BackOfficeContactModalValidator from "./BackOfficeContactModalValidator";
import { ConfirmationModal, Form } from "../../../../../common/components";
import styles from "./BackOfficeContactModal.module.scss";

export default function BackOfficeContactModal(props) {
  const [state, setState] = useState({
    payrollAccountManagerId: props.payrollAccountManagerId || null,
    payrollAccountManagerBuddyId: props.payrollAccountManagerBuddyId || null,
    creditControllerId: props.creditControllerId || null,
    creditControllerBuddyId: props.creditControllerBuddyId || null,
    businessDevelopmentManagerId: props.businessDevelopmentManagerId || null,
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    const validator = new BackOfficeContactModalValidator(state);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    props.onSubmit(state);
  };

  return (
    <ConfirmationModal
      className={styles.container}
      title="Edit Back Office Contacts"
      visible={props.visible}
      submitText="Save"
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
    >
      <Form requiredKey>
        <Form.Section>
          <Form.Group inline error={errors?.businessDevelopmentManagerId}>
            <Form.Label inline required>
              Business Development Manager
            </Form.Label>
            <Form.Select
              value={state.businessDevelopmentManagerId}
              options={props.businessDevelopmentManagers.map(user => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
              }))}
              onChange={(_, item) => setState( { ...state, businessDevelopmentManagerId: item.value })}
              highlightError={errors?.businessDevelopmentManagerId}
              fluid
            />
          </Form.Group>
          <Form.Group inline error={errors.payrollAccountManagerId}>
            <Form.Label inline required>
              Payroll Account Manager
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "payroll",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.payrollAccountManagerId}
              onChange={e => setState({ ...state, payrollAccountManagerId: e.target.value })}
              highlightError={errors.payrollAccountManagerId}
              clearable
              fluid
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>
              Payroll Account Manager Buddy
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "payroll",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.payrollAccountManagerBuddyId}
              onChange={e => setState({ ...state, payrollAccountManagerBuddyId: e.target.value })}
              clearable
              fluid
            />
          </Form.Group>
          <Form.Group inline error={errors.creditControllerId}>
            <Form.Label inline required>
              Credit Controller
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "credit control",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.creditControllerId}
              onChange={e => setState({ ...state, creditControllerId: e.target.value })}
              highlightError={errors.creditControllerId}
              clearable
              fluid
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Label inline>
              Credit Controller Buddy
            </Form.Label>
            <Form.SelectQuery
              queryOptions={{
                query: "getUsers",
                inputs: [
                  {
                    type: "UserFilterOptions",
                    variableName: "filter_options",
                    value: {
                      user_type: "back_office",
                      department: "credit control",
                    },
                  },
                  {
                    type: "[SortOrder]",
                    variableName: "sort_order",
                    value: [
                      {
                        field: "first_name",
                        order: "asc",
                      },
                    ],
                  },
                ],
                fields: [
                  "id",
                  "first_name",
                  "last_name",
                ],
                transform: {
                  label: "$.first_name $.last_name",
                  value: "$.id",
                },
              }}
              value={state.creditControllerBuddyId}
              onChange={e => setState({ ...state, creditControllerBuddyId: e.target.value })}
              clearable
              fluid
            />
          </Form.Group>
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

BackOfficeContactModal.propTypes = {
  visible: PropTypes.bool,
  payrollAccountManagerId: PropTypes.string,
  payrollAccountManagerBuddyId: PropTypes.string,
  creditControllerId: PropTypes.string,
  creditControllerBuddyId: PropTypes.string,
  businessDevelopmentManagerId: PropTypes.string,
  businessDevelopmentManagers: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
