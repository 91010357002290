import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import AgencyBankInformationFormValidator from "./AgencyBankInformationFormValidator";
import {
  Button,
  ButtonGroup,
  Form,
  SeeHistoryTooltip,
} from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import { isNumber } from "../../../../../utils";
import styles from "./AgencyBankInformationForm.module.scss";

export default class AgencyBankInformationForm extends Component {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    agencyBankInformation: PropTypes.shape({
      sort_code: PropTypes.string,
      account_number: PropTypes.string,
      bank_name: PropTypes.string,
      branch: PropTypes.string,
      payee_name: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      account_number: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      sort_code: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    disabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {
        sort_code: props.agencyBankInformation?.sort_code,
        account_number: props.agencyBankInformation?.account_number,
        bank_name: props.agencyBankInformation?.bank_name,
        branch: props.agencyBankInformation?.branch,
        payee_name: props.agencyBankInformation?.payee_name,
      },
      errors: {},
    };
  }

  render() {
    const { disabledFields, onDisabledFieldClick, history, submitting } = this.props;
    const { data, errors } = this.state;

    return (
      <div className={styles.container}>
        <Form className={styles.form}>
          <Form.Section title="Bank Information" underlined>
            <Form.Group inline wide error={!disabledFields.includes("sort_code") && errors?.sort_code}>
              <Form.Label inline>
                <SeeHistoryTooltip variant="compact" data={history?.sort_code}>
                  Sort Code
                </SeeHistoryTooltip>
              </Form.Label>
              <Form.Input
                name="sort_code"
                value={data.sort_code}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("sort_code")}
                showLock={disabledFields.includes("sort_code")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("sort_code")}
                fluid
                highlightError={!disabledFields.includes("sort_code") && errors?.sort_code}
                maskOptions={{
                  mask: "99-99-99",
                  maskChar: null,
                }}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.account_number}>
              <Form.Label inline>
                <SeeHistoryTooltip variant="compact" data={history?.account_number}>
                  Account Number
                </SeeHistoryTooltip>
              </Form.Label>
              <Form.Input
                name="account_number"
                value={data.account_number}
                maxLength={8}
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("account_number")}
                showLock={disabledFields.includes("account_number")}
                onChange={this.handleNumberChange}
                onLockClick={() => onDisabledFieldClick("account_number")}
                fluid
                highlightError={errors?.account_number}
              />
            </Form.Group>
            <Form.Group inline wide error={errors?.payee_name}>
              <Form.Label inline>Payee Name</Form.Label>
              <Form.Input
                name="payee_name"
                value={data.payee_name}
                size="large"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("payee_name")}
                showLock={disabledFields.includes("payee_name")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("payee_name")}
                highlightError={errors?.payee_name}
              />
            </Form.Group>
            <Form.Group inline wide>
              <Form.Label inline note="(Optional)">Bank Name</Form.Label>
              <Form.Input
                name="bank_name"
                value={data.bank_name}
                size="large"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("bank_name")}
                showLock={disabledFields.includes("bank_name")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("bank_name")}
              />
            </Form.Group>
            <Form.Group inline wide>
              <Form.Label inline note="(Optional)">Branch</Form.Label>
              <Form.Input
                name="branch"
                value={data.branch}
                size="large"
                tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                disabled={disabledFields.includes("branch")}
                showLock={disabledFields.includes("branch")}
                onChange={this.handleInputChange}
                onLockClick={() => onDisabledFieldClick("branch")}
              />
            </Form.Group>
          </Form.Section>
        </Form>
        <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
          <>
            <Button
              variant="primary"
              className={styles.button}
              onClick={this.handleSaveClicked}
              busy={submitting}
              disabled={submitting || !_.isEmpty(disabledFields)}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              className={styles.button}
              onClick={this.handleCancelClicked}
            >
              Cancel
            </Button>
          </>
        </ButtonGroup>
      </div>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleNumberChange = (e) => {
    if (!isNumber(e.target.value)) {
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCancelClicked = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  }

  handleSaveClicked = () => {
    const { data } = this.state;
    const { onSave } = this.props;

    const validator = new AgencyBankInformationFormValidator(data, this.props);
    const validationResult = (validator && validator.validate()) || { errors: [], success: true };

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) return;

    onSave && onSave({
      ...data,
    });
  }
}
