import { push } from "connected-react-router";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_USER = gql`
mutation($id: ID!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    id
    cognito_username
    type
    status
    first_name
    last_name
    email
    created_at
    updated_at
    user_groups {
      id
    }
    department {
      id
      name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.userId
 * @param {object} obj.user
 * @param {string} obj.user.firstName
 * @param {string} obj.user.lastName
 * @param {string} obj.user.email
 * @param {string} obj.user.userTypeId
 * @param {string} obj.user.departmentId
 * @param {object[]} obj.user.userGroups
 * @param {string} obj.user.userGroups[].id
 * @param {string} obj.redirectUrl (optional)
 * @returns
 */
export const updateUser = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_USER_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_USER,
      variables: {
        id: obj.userId,
        input: {
          first_name: obj.user.firstName,
          last_name: obj.user.lastName,
          email: obj.user.email,
          department_id: obj.user.departmentId,
          user_groups: obj.user.userGroups,
          user_type_id: obj.user.userTypeId,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: {
        user: response.data.updateUser,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }

  }
  catch (err) {
    dispatch({
      type: types.UPDATE_USER_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
