import moment from "moment";

/**
 * Parse a date to natural language, eg. Thursday, 9 September 2021
 *
 * @param  {string || number || Date} date
 * @returns string
 */
const parseDateToNaturalLanguage = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("dddd, D MMMM YYYY");
};

export default parseDateToNaturalLanguage;
