import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { actions } from "../../duck";
import ViewAgencyCustomersPage from "./ViewAgencyCustomersPage";

const mapStateToProps = state => ({
  ...state.customers,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
  }, dispatch),
  onNewCustomerClick: () => {
    dispatch(push("/customers/new"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAgencyCustomersPage);
