import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import styles from "./BackOfficeContactRow.module.scss";

export default function BackOfficeContactRow(props) {
  return (
    <Grid.Row className={styles.row}>
      {props.name ? (
        <span className={styles.name}>{props.name || "-"}</span>
      ) : (
        <span className={styles.noName}>None set</span>
      )}
      <span className={styles.contactType}>{props.label}</span>
    </Grid.Row>
  );
}

BackOfficeContactRow.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
};
