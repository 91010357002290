import gql from "graphql-tag";
import _ from "lodash";
import * as types from "../types";
import { DOCUMENT_TYPES } from "../../../documents";
import { apolloClient } from "../../../../apolloClient";
import { parseDate } from "../../../../utils";

const GET_COMPANY_INFO = gql`
fragment RevisionHistory on Revision {
  operation
  updated_at
  revision_changes {
    path
    old_value
    new_value
  }
}

query(
  $agency_id: ID!
  $certificate_of_incorporation_document_input: AgencyDocumentInput!
  $contract_document_input: AgencyDocumentInput!
  $registered_address_input: GetHistoryInput!
  $contract_start_date_input: GetHistoryInput!
  $correspondence_name_input: GetHistoryInput!
  $legal_name_input: GetHistoryInput!
  $trading_as_input: GetHistoryInput!
  $sort_code_input: GetHistoryInput!
  $account_number_input: GetHistoryInput!
) {
  getAgency(id: $agency_id) {
    id
    short_code
    known_as
    exact_legal_name
    correspondence_name
    company_number
    status

    officers {
      items {
        name
        officer_role
      }
    }

    companies_house_info {
      registered_office_address {
        address_line_1
        locality
        postal_code
      }
      accounts {
        last_accounts {
          made_up_to
        }
      }
      company_status
      company_status_detail
      type
      date_of_creation
    }

    contract {
      start_date
      contract_length
      contract_period
      date_first_traded
      fee
      funding_type
    }

    banking {
      agency_id
      sort_code
      account_number
      bank_name
      branch
      payee_name
    }

    logo_url
    trading_as
    website
    linked_in
    twitter
    introduced_via_broker
    is_ledger_buyout
    trading_address {
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
    }
    agency_status {
      id
      label
      value
    }
    previous_provider {
      label
      value
    }
    previous_provider {
      id
      label
      value
    }

    industry_sectors {
      name
    }

    branches {
      id
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
      branch {
        value
      }
    }
  }

  getCertificateOfIncorporationDocument: getAgencyDocument(input: $certificate_of_incorporation_document_input) {
    name
  }

  getContractDocument: getAgencyDocument(input: $contract_document_input) {
    name
  }

  registered_address: getHistory(input: $registered_address_input) {
    id
    model
    document_id
    document
    operation
    updated_at
  }

  contract_start_date: getHistory(input: $contract_start_date_input) {
    ...RevisionHistory
  }

  correspondence_name: getHistory(input: $correspondence_name_input) {
    ...RevisionHistory
  }

  legal_name: getHistory(input: $legal_name_input) {
    ...RevisionHistory
  }

  trading_as: getHistory(input: $trading_as_input) {
    ...RevisionHistory
  }

  sort_code: getHistory(input: $sort_code_input) {
    ...RevisionHistory
  }

  account_number: getHistory(input: $account_number_input) {
    ...RevisionHistory
  }
}
`;

export const getCompanyInfo = agencyId => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_COMPANY_INFO_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_COMPANY_INFO,
      variables: {
        agency_id: agencyId,
        certificate_of_incorporation_document_input: {
          agency_id: agencyId,
          document_type: DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION,
        },
        contract_document_input: {
          agency_id: agencyId,
          document_type: DOCUMENT_TYPES.CONTRACT,
        },
        registered_address_input: {
          "document_id": `MAIN_${agencyId}`,
          "model": "AgencyAddress",
          "limit": 5,
          "order": "desc",
        },
        contract_start_date_input: {
          "document_id": agencyId,
          "model": "AgencyContract",
          "fields": ["start_date"],
          "limit": 5,
          "order": "desc",
        },
        correspondence_name_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["correspondence_name"],
          "limit": 5,
          "order": "desc",
        },
        legal_name_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["legal_name"],
          "limit": 5,
          "order": "desc",
        },
        trading_as_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["trading_as"],
          "limit": 5,
          "order": "desc",
        },
        sort_code_input: {
          "document_id": agencyId,
          "model": "AgencyBankInformation",
          "fields": ["sort_code"],
          "limit": 5,
          "order": "desc",
        },
        account_number_input: {
          "document_id": agencyId,
          "model": "AgencyBankInformation",
          "fields": ["account_number"],
          "limit": 5,
          "order": "desc",
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const revisionsForAddress = revisions => _.chain(revisions)
      .map((revision) => {
        const address = JSON.parse(revision.document);

        return {
          value: _.compact([
            address.address_line_one,
            address.address_line_two,
            address.town_or_city,
            address.county,
            address.postcode,
          ]).join(", "),
          updated_at: revision.updated_at,
        };
      })
      .filter(historyItem => historyItem.value)
      .groupBy(historyItem => historyItem.updated_at)
      .map(group => _.merge({}, ...group))
      .value();
    const revisionsForField = (revisions, field) => revisions.reduce((result, revision) => [
      ...result,
      ...revision.revision_changes
        .filter(revisionChange => revisionChange.path === field)
        .map(revisionChange => ({
          value: revisionChange.new_value,
          updated_at: revision.updated_at,
        })),
    ], []);
    const revisions = {
      registered_address: revisionsForAddress(response.data.registered_address),
      contract_start_date: revisionsForField(response.data.contract_start_date, "start_date").map(change => ({
        ...change,
        value: change.value && parseDate(change.value),
      })),
      correspondence_name: revisionsForField(response.data.correspondence_name, "correspondence_name"),
      legal_name: revisionsForField(response.data.legal_name, "legal_name"),
      trading_as: revisionsForField(response.data.trading_as, "trading_as"),
      sort_code: revisionsForField(response.data.sort_code, "sort_code"),
      account_number: revisionsForField(response.data.account_number, "account_number"),
    };

    dispatch({
      type: types.GET_COMPANY_INFO_SUCCESS,
      payload: {
        agency: response.data.getAgency,
        certificateOfIncorporationDocument: response.data.getCertificateOfIncorporationDocument,
        contractDocument: response.data.getContractDocument,
        history: revisions,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_COMPANY_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
