import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import DataMismatchCount from "./DataMismatchCount";

const mapStateToProps = state => ({
  count: state.dataMismatches.dataMismatchCount.data,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataMismatchCount);
