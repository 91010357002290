import * as types from "../types";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyOnboardingTaskId
 * @param {number} obj.requiredSubtasksTotal
 * @param {number} obj.requiredSubtasksCompleted
 * @returns
 */
export const agencyOnboardingTaskProgressChanged = obj => (dispatch) => {
  dispatch({
    type: types.AGENCY_ONBOARDING_TASK_PROGRESS_CHANGED,
    payload: {
      agencyOnboardingTaskId: obj.agencyOnboardingTaskId,
      requiredSubtasksTotal: obj.requiredSubtasksTotal,
      requiredSubtasksCompleted: obj.requiredSubtasksCompleted,
    },
  });
};
