import gql from "graphql-tag";

export const GET_PERMISSIONS = gql`
query {
  getPermissions {
    id
    name
    description
  }
}
`;

export const GET_USER_GROUPS = gql`
query {
  getUserGroups {
    id
    name
    description
    permissions {
      id
    }
  }
}
`;

export const ASSIGN_USER_GROUP_USER_BATCH = (userId, userGroups) => {
  const groups = userGroups.map(userGroupId => `group${userGroupId}: assignUserGroupUser(user_group_id: "${userGroupId}", user_id: "${userId}")`);
  return gql`
    mutation {
      ${groups.join("\n")}
    }
  `;
};

export const UNASSIGN_USER_GROUP_USER_BATCH = (userId, userGroups) => {
  const groups = userGroups.map(userGroupId => `group${userGroupId}: unassignUserGroupUser(user_group_id: "${userGroupId}", user_id: "${userId}")`);
  return gql`
    mutation {
      ${groups.join("\n")}
    }
  `;
};
