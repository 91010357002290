import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { AccessTime, Check, ExpandMore, Warning } from "@material-ui/icons";
import { PriorityPill } from "../../";
import { CollapseContainer, Pill, Table2 } from "../../../../common/components";
import { OnboardingStatusConverter } from "../../../../../converters";
import styles from "./OnboardingTaskListItem.module.scss";

export default class OnboardingTaskListItem extends Component {

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    outstanding_agencies: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string.isRequired,
      agency: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      progress: PropTypes.shape({
        required_completed: PropTypes.number.isRequired,
        required_total: PropTypes.number.isRequired,
      }),
    })),
    isExpanded: PropTypes.bool.isRequired,
    onExpandToggle: PropTypes.func.isRequired,
    onViewClick: PropTypes.func.isRequired,
  };

  state = { isExpanded: false };

  render() {
    const { className, id, name, outstanding_agencies, isExpanded } = this.props;

    const sortedAgencies = this.sortAgencies(outstanding_agencies);
    const hasOverdueAgencies = this.hasOverdueAgencies(outstanding_agencies);
    const canExpand = this.canShowExpandedContainer(outstanding_agencies);

    return (
      <div className={classnames(className, styles.container)}>
        <div className={styles.header} onClick={() => this.handleExpandToggle(id)}>
          <div className={styles.leftHeader}>
            <div className={styles.taskName}>{name}</div>
            <div className={styles.tasksCompleted}>{`${sortedAgencies.length} Agencies`}</div>
            {hasOverdueAgencies && (
              <Pill className={styles.overduePill} variant="danger">
                Overdue Agencies
              </Pill>
            )}
          </div>
          <span className={styles.clickableIcon}>
            <ExpandMore fontSize="inherit" className={`${styles.expandIcon} ${isExpanded ? styles.collapse : styles.expand}`} />
          </span>
        </div>
        <CollapseContainer collapsed={!isExpanded}>
          {canExpand && (
            <Table2
              data={sortedAgencies}
              rowClassName={styles.rowClassName}
            >
              <Table2.Column
                collapsed
                cellClassName={styles.tableCell}
                name="task"
                value={row => (
                  <div>
                    <span className={styles.agencyName} onClick={() => this.handleViewClick(row)}>
                      {row.agency.name}
                    </span>
                    <span className={styles.agencyId}>
                      ({row.agency.short_code})
                    </span>
                  </div>
                )}
              >
                Agency
              </Table2.Column>
              <Table2.Column
                collapsed
                cellClassName={styles.tableCell}
                name="task"
                value={row => (
                  <div className={styles.taskStatus}>
                    {this.iconForStatus(row.status)}
                    {OnboardingStatusConverter.to(row.status)}
                  </div>
                )}
              >
                Status
              </Table2.Column>
              <Table2.Column
                collapsed
                className={styles.centerColumn}
                cellClassName={styles.tableCell}
                name="task"
                value={(row) => {
                  return `${row.progress.required_completed} / ${row.progress.required_total}`;
                }}
              >
                Subtasks Completed
              </Table2.Column>
              <Table2.Column
                collapsed
                className={styles.centerColumn}
                cellClassName={styles.tableCell}
                name="priority"
                value={row => <PriorityPill dueDate={row.due_date} />}
              >
                Priority
              </Table2.Column>
            </Table2>
          )}
        </CollapseContainer>
      </div>
    );
  }

  handleExpandToggle = (taskId) => {
    const { onExpandToggle } = this.props;

    onExpandToggle(taskId);
  }

  handleViewClick = (row) => {
    const { onViewClick } = this.props;

    onViewClick({
      agencyOnboardingTaskId: row.id,
      agencyId: row.agency.id,
    });
  }

  iconForStatus = (status) => {
    switch (status) {
      case "not_started":
        return <Warning className={styles.notStartedIcon} />;

      case "in_progress":
        return <AccessTime className={styles.inProgressIcon} />;

      case "done":
        return <Check className={styles.doneIcon} />;

      default:
        return null;
    }
  }

  sortAgencies = (agencies) => {
    return _.sortBy(agencies, agency => agency.due_date);
  }

  hasOverdueAgencies = (agencies) => {
    const now = moment();

    return agencies?.some((agency) => {
      const due = moment(agency.due_date);
      const days = due.diff(now, "days");

      return days < 0;
    });
  }

  canShowExpandedContainer = (agencies) => {
    return _.every(agencies, x => x.agency?.id);
  }
}
