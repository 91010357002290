import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Popup } from "semantic-ui-react";
import styles from "./IconButton.module.scss";

export default function IconButton(props) {
  return (
    <Popup
      trigger={(
        <div
          className={classnames(
            styles.container,
            props.className,
            props.disabled && styles.disabled,
            !props.text && styles.iconOnly
          )}
          disabled={props.disabled}
          onClick={props.onClick}
        >
          {props.iconPosition === "left" && props.icon}
          {props.text && (
            <div>{props.text}</div>
          )}
          {props.iconPosition === "right" && props.icon}
        </div>
      )}
      content={props.tooltip}
      disabled={!props.tooltip}
      hideOnScroll
      wide
    />
  );
}

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left","right"]),
  tooltip: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

IconButton.defaultProps = {
  iconPosition: "left",
};
