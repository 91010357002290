import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";



export const UPDATE_NON_REGISTERED_COMPANY = gql`
mutation($id: ID!, $input: NonRegisteredLegalEntityInput!) {
  updateNonRegisteredLegalEntity(id: $id, input: $input) {
    id
    __typename
    name
    meta {
      key
      value
    }

    ...on NonRegisteredCompany {
      name
      telephone
      email_address
      website
      company_address {
        address_line_one
        address_line_two
        town_or_city
        county
        postcode
      }
    }

    ...on Charity {
      name
      telephone
      email_address
      website
      registered_charity_number
      company_address {
        address_line_one
        address_line_two
        town_or_city
        county
        postcode
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.name
 * @param {string} obj.telephone
 * @param {string} obj.website
 * @param {string} obj.emailAddress
 * @param {string} obj.addressLine1
 * @param {string} obj.addressLine2 (optional)
 * @param {string} obj.townOrCity
 * @param {string} obj.county
 * @param {string} obj.postcode
 * @param {boolean} obj.isRegisteredCharity
 * @param {string} obj.registeredCharityNumber
 * @param {string} obj.redirectUrl (optional)
 * @returns
 */
export const updateNonRegisteredCompany = obj => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_NON_REGISTERED_COMPANY_PENDING });

    const response = await apolloClient.query({
      query: UPDATE_NON_REGISTERED_COMPANY,
      variables: {
        id: obj.legalEntityId,
        input: {
          name: obj.name,
          telephone: obj.telephone,
          website: obj.website,
          email_address: obj.emailAddress,
          company_address: {
            address_line_one: obj.addressLine1,
            address_line_two: obj.addressLine2,
            town_or_city: obj.townOrCity,
            county: obj.county,
            postcode: obj.postcode,
          },
          is_registered_charity: obj.isRegisteredCharity,
          registered_charity_number: obj.registeredCharityNumber,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_NON_REGISTERED_COMPANY_SUCCESS,
      payload: {
        legalEntityDetails: response.data.updateNonRegisteredLegalEntity,
      },
    });

    if (obj.redirectUrl) {
      dispatch(push(obj.redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_NON_REGISTERED_COMPANY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
