import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import FormCheckbox from "../FormCheckbox/FormCheckbox";
import styles from "./FormChecklist.module.scss";

export default function FormChecklist({
  className,
  name,
  values,
  options,
  onChange,
  readOnly,
  disabled,
}) {
  const handleChange = (value) => {
    onChange && onChange({
      target: {
        name,
        value,
      },
    });
  };

  return (
    <div className={classnames(styles.container, className)}>
      {options.map(option => (
        <FormCheckbox
          key={option.value}
          name={option.value}
          label={option.label}
          checked={values.includes(option.value)}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

FormChecklist.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  })).isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormChecklist.defaultProps = {
  values: [],
  options: [],
};
