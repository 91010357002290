import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Squares as ActivityIndicator } from "react-activity";
import { AgencyLogo, StatusLabel } from "../../../common/components";
import styles from "./ProspectHeading.module.scss";

export default function ProspectHeading(props) {
  if (props.loading) {
    return (
      <div className={classnames(styles.container, props.className)}>
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <div className={classnames(styles.container, props.className)}>
      <AgencyLogo name={props.prospect.name} logoUrl={props.prospect.logoUrl} />
      <div className={styles.headerContent}>
        <div className={styles.headerPrimary}>
          <div className={styles.name}>{props.prospect.name}</div>
        </div>
      </div>
      {props.showStatus && (
        <div className={styles.status}>
          <StatusLabel size="large" status="Prospect" variant="prospect" />
        </div>
      )}
    </div>
  );
}

ProspectHeading.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  prospect: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
  }),
  showStatus: PropTypes.bool,
};
