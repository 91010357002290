import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_DOCUMENT = gql`
query($input: AgencyDocumentInput!) {
  getAgencyDocument(input: $input) {
    name
    size
    lastModified
    versions {
      versionId
      isLatest
      size
      metadata {
        user {
          first_name
          last_name
        }
        date_uploaded
      }
    }
  }
}
`;

/**
 * @param  {object} obj
 * @param  {string} obj.agencyId
 * @param  {string} obj.documentType
 */
export const getAgencyDocument = obj => async (dispatch) => {
  try {
    dispatch({ type: types.GET_AGENCY_DOCUMENT_PENDING });

    const response = await apolloClient.query({
      query: GET_AGENCY_DOCUMENT,
      variables: {
        input: {
          agency_id: obj.agencyId,
          document_type: obj.documentType,
        },
      },
    });

    dispatch({
      type: types.GET_AGENCY_DOCUMENT_SUCCESS,
      payload: {
        data: response.data.getAgencyDocument,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_DOCUMENT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
