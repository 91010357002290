import React from "react";
import PropTypes from "prop-types";
import {
  DocumentDownloadCard,
  Form,
} from "../../../common/components";
import styles from "./ContractDocumentDetails.module.scss";

export default function ContractDocumentDetails(props) {
  return (
    <Form.Card
      title="Documents"
      variant="h2"
      className={styles.card}
    >
      {(props.contract?.documents ?? []).length === 0 && (
        <div className={styles.noResults}>
          There are no documents available to view.
        </div>
      )}

      {props.contract?.documents?.map(document => (
        <DocumentDownloadCard
          className={styles.documentDownloadCard}
          fileName={document.fileName}
          fileSize={document.size}
          uploadDate={document.metadata.date_uploaded}
          showDeleteButton={false}
          onDownload={() => {}}
        />
      ))}
    </Form.Card>
  );
}

ContractDocumentDetails.propTypes = {
  contract: PropTypes.shape({
    documents: PropTypes.arrayOf({
      fileName: PropTypes.string,
      size: PropTypes.number,
      metadata: PropTypes.shape({
        date_uploaded: PropTypes.number,
      }),
    }),
  }),
};
