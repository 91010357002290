import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "../../../common/components";
import { REACT_APP_FEATURE_FLAG_PROSPECT_CREDIT } from "../../../../config";
import { isAddNewCustomerComplete } from "./isAddNewCustomerComplete";
import { AgencyProspectSearchBox } from "../../containers";
import { isAlphaNumeric, containsLowercaseCharacters } from "../../../../utils/string";
import { options } from "../../../../constants";
import styles from "./AddNewCustomerForm.module.scss";

const INITIAL_ADD_NEW_CUSTOMER_FORM_DATA = {
  agency: {
    id: "",
    name: "",
  },
  exactLegalName: "",
  companyType: "",
  registeredCompanyNumber: null,
  soleTraderName: null,
  internationalTradingName: null,
  isRegisteredCharity: false,
  registeredCharityNumber: null,
  creditLimitRequested: "",
  addressLine1: null,
  addressLine2: null,
  townOrCity: null,
  county: null,
  postcode: null,
  country: null,
};

export default function AddNewCustomerForm(props) {
  const [formData, setFormData] = useState(INITIAL_ADD_NEW_CUSTOMER_FORM_DATA);

  const handleFormChange =  (event) => {
    let newFormData = {
      ...formData,
      [event.target.name]: event.target.value,
    };

    // If changing customer type, wipe conditionally related fields
    if (newFormData.companyType !== formData.companyType) {
      newFormData.registeredCompanyNumber = null;
      newFormData.creditLimitRequested = null;
      newFormData.soleTraderName = null;
      newFormData.internationalTradingName = null;
      newFormData.addressLine1 = null;
      newFormData.addressLine2 = null;
      newFormData.townOrCity = null;
      newFormData.county = null;
      newFormData.postcode = null;
      newFormData.country = null;
    }

    setFormData(newFormData);
  };

  const handleFormRadioChange = (event) => {
    const newFormData = {
      ...formData,
      [event.target.name]: String(event.target.value) === "true",
    };

    if (event.target.name === "isRegisteredCharity" && String(event.target.value) === "false") {
      newFormData.registeredCharityNumber = null;
    }

    setFormData(newFormData);
  };

  const onAlphaNumericChange = (event) => {
    // if entered value isn't alphanumeric, ignore
    if (!isAlphaNumeric(event.target.value)) {
      return;
    }

    // if entered value contains lowercase chars, make them uppercase
    if (containsLowercaseCharacters(event.target.value)) {
      event.target.value = event.target.value.toUpperCase();
    }

    handleFormChange(event);
  };

  const onRegisteredCompanyNumberChange = (event) => {
    // if entered value has forbidden characters, ignore
    if (!event.target.value.match("^[a-zA-Z0-9-]*$")) {
      return;
    }

    handleFormChange(event);
  };

  const onPostCodeChange = (event) => {
    if (!isAlphaNumeric(event.target.value, true)) {
      return;
    }

    // if entered value contains lowercase chars, make them uppercase
    if (containsLowercaseCharacters(event.target.value)) {
      event.target.value = event.target.value.toUpperCase();
    }

    handleFormChange(event);
  };

  const handleAgencyChange = (agency) => {
    handleFormChange({
      target: {
        name: "agency",
        value: agency,
      },
    });
  };

  const handleSubmit = () => {
    const data = {
      agencyId: formData.agency.id,
      isProspect: formData.agency.prospect,
      ...formData,
    };

    props.onSubmit(data);
  };

  return (
    <>
      <hr className={styles.separator} />
      <div className={styles.formTitle}>Customer Details</div>
      <Form className={styles.form} requiredKey>
        <Form.Section title="Agency" className={styles.formSection}>
          <Form.Group inline>
            <Form.Label className={styles.label} required>
              Agency
            </Form.Label>
            <Form.Value>
              <AgencyProspectSearchBox
                name="agencyId"
                size="large"
                onItemSelected={handleAgencyChange}
                value={formData.agency.name}
                hideProspects={!REACT_APP_FEATURE_FLAG_PROSPECT_CREDIT}
              />
            </Form.Value>
          </Form.Group>
        </Form.Section>

        <hr className={styles.separator} />

        <Form.Section title="Customer" className={styles.formSection}>
          <Form.Group inline>
            <Form.Label className={styles.label} required>
              Customer (Exact Legal Name)
            </Form.Label>
            <Form.Input
              type="text"
              placeholder="Enter Customer..."
              name="exactLegalName"
              size="large"
              value={formData.exactLegalName}
              onChange={handleFormChange}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label className={styles.label} required>
              Customer Type
            </Form.Label>
            <Form.Select
              placeholder="Select Customer Type..."
              name="companyType"
              size="large"
              value={formData.companyType}
              className={styles.companyTypeList}
              options={options.CUSTOMER_DETAILS_CUSTOMER_TYPES}
              onChange={handleFormChange}
            />
          </Form.Group>

          {[
            "limited_company",
            "limited_liability_partnership",
            "international",
          ].includes(formData.companyType) && (
            <Form.Group inline>
              <Form.Label className={styles.label} required>
                Registered Company Number
              </Form.Label>
              <Form.Input
                type="text"
                placeholder="Enter Registered Number..."
                name="registeredCompanyNumber"
                size="large"
                maxLength="255"
                value={formData.registeredCompanyNumber}
                onChange={onRegisteredCompanyNumberChange}
              />
            </Form.Group>
          )}

          {formData.companyType === "sole_trader" && (
            <Form.Group inline>
              <Form.Label className={styles.label} required>
                Name of Sole Trader
              </Form.Label>
              <Form.Input
                type="text"
                placeholder="Enter Name of Sole Trader..."
                name="soleTraderName"
                size="large"
                maxLength="255"
                value={formData.soleTraderName}
                onChange={handleFormChange}
              />
            </Form.Group>
          )}

          <Form.Group inline>
            <Form.Label className={styles.label} required>
              Is it a charity?
            </Form.Label>
            <div>
              <Form.Radio
                value={true}
                name="isRegisteredCharity"
                checked={formData.isRegisteredCharity}
                text="Yes"
                onChange={handleFormRadioChange}
              />
              <Form.Radio
                value={false}
                name="isRegisteredCharity"
                checked={formData.isRegisteredCharity === false}
                text="No"
                onChange={handleFormRadioChange}
              />
            </div>
          </Form.Group>

          {formData.isRegisteredCharity && (
            <Form.Group inline>
              <Form.Label className={styles.label} required>
                Charity Number
              </Form.Label>
              <Form.Input
                type="text"
                placeholder="Enter Charity Number..."
                name="registeredCharityNumber"
                size="large"
                value={formData.registeredCharityNumber}
                onChange={onAlphaNumericChange}
              />
            </Form.Group>
          )}
        </Form.Section>

        <hr className={styles.separator} />

        {["sole_trader", "partnership", "government"].includes(formData.companyType) && (
          <>
            <Form.Section title="Customer Address" className={styles.formSection}>
              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Address Line 1
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Address Line 1..."
                  name="addressLine1"
                  size="large"
                  maxLength="100"
                  value={formData.addressLine1}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label}>
                  Address Line 2
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Address Line 2..."
                  name="addressLine2"
                  size="large"
                  maxLength="100"
                  value={formData.addressLine2}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Town / City
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Town / City..."
                  name="townOrCity"
                  size="large"
                  maxLength="100"
                  value={formData.townOrCity}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label}>
                  County
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter County..."
                  name="county"
                  size="large"
                  maxLength="100"
                  value={formData.county}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Postcode
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Postcode..."
                  name="postcode"
                  size="small"
                  maxLength="8"
                  value={formData.postcode}
                  onChange={onPostCodeChange}
                />
              </Form.Group>
            </Form.Section>

            <hr className={styles.separator} />
          </>
        )}

        {formData.companyType === "international" && (
          <>
            <Form.Section title="International Company Details" className={styles.formSection}>
              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Trading Name
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Trading Name..."
                  name="internationalTradingName"
                  size="large"
                  maxLength="255"
                  value={formData.internationalTradingName}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Address Line 1
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Address Line 1..."
                  name="addressLine1"
                  size="large"
                  maxLength="100"
                  value={formData.addressLine1}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label}>
                  Address Line 2
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Address Line 2..."
                  name="addressLine2"
                  size="large"
                  maxLength="100"
                  value={formData.addressLine2}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Town / City
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Town / City..."
                  name="townOrCity"
                  size="large"
                  maxLength="100"
                  value={formData.townOrCity}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label}>
                  County
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter County..."
                  name="county"
                  size="large"
                  maxLength="100"
                  value={formData.county}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Country
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Country..."
                  name="country"
                  size="large"
                  maxLength="100"
                  value={formData.country}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Postcode
                </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter Postcode..."
                  name="postcode"
                  size="small"
                  maxLength="8"
                  value={formData.postcode}
                  onChange={onPostCodeChange}
                />
              </Form.Group>
            </Form.Section>

            <hr className={styles.separator} />
          </>
        )}

        <Form.Section title="Documents" className={styles.formSection}>
          <div className={styles.info}>
            If you have a company letterheading (or other proof of Customer Legal Name and Registration Number) and signed Terms & Conditions
            then please upload these documents and submit them with this form or provide the information to our credit control department.
          </div>
          <Form.Group>
            <Form.Label className={styles.label} fluid>
              Company Letterhead / Proof of Customer Legal Name & Registration Number
            </Form.Label>
            {/* Add Document Upload input here */}
            <div className={styles.placeholder}>
              Coming soon...
            </div>
          </Form.Group>
        </Form.Section>
        {[
          "limited_company",
          "limited_liability_partnership",
          "partnership",
          "sole_trader",
          "international",
        ].includes(formData.companyType) && (
          <>
            <hr className={styles.separator} />

            <Form.Section title="Estimated Credit Needed" className={styles.formSection}>
              <Form.Group inline>
                <Form.Label className={styles.label} required>
                  Estimated Credit Needed
                </Form.Label>
                <Form.Number
                  name="creditLimitRequested"
                  value={formData.creditLimitRequested}
                  placeholder="e.g. £10,000"
                  prefix="£"
                  size="small"
                  onChange={handleFormChange}
                  formatNumber
                />
              </Form.Group>
            </Form.Section>
          </>
        )}
      </Form>
      <hr className={styles.separator} />
      <div className={styles.buttonsArea}>
        <Button
          variant="outline-danger"
          onClick={() => props.showUnsavedChangesModal({
            onLeaveClick: props.onCancel,
          })}>
          Cancel
        </Button>
        <Button
          variant="outline-primary"
          disabled={!isAddNewCustomerComplete(formData)}
          onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </>
  );
}

AddNewCustomerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showUnsavedChangesModal: PropTypes.func.isRequired,
};
