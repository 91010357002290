import React, { useState } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Button, CompaniesHouseSearchResultsModal, Heading } from "../../../common/components";
import {
  CompaniesHouseDetails,
  CompaniesHouseLookupForm,
} from "../../components";
import styles from "./VerifyRegisteredCompanyPage.module.scss";

const INITIAL_MODAL_PAGINATION = { page: 1, limit: 5 };

export default function VerifyRegisteredCompanyPage(props) {
  const [step, setStep] = useState(0);
  const [companiesHouseUserInput, setCompaniesHouseUserInput] = useState({
    registeredCompanyNumber: props.registeredCompanyNumber || null,
    exactLegalName: props.exactLegalName || null,
  });
  const [showCompaniesHouseSearchResultsModal, setShowCompaniesHouseSearchResultsModal] = useState(false);
  const [modalPagination, setModalPagination] = useState(INITIAL_MODAL_PAGINATION);

  const handleFormChange = e =>
    setCompaniesHouseUserInput({ ...companiesHouseUserInput, [e.target.name]: e.target.value });

  const handleCompaniesHouseUserInputSubmit = () => {
    handleSearchCompaniesHouseClick();
    setShowCompaniesHouseSearchResultsModal(true);
  };

  const handleCompanySelected = (company, existing) => {
    if (existing) {
      return;
    }

    setShowCompaniesHouseSearchResultsModal(false);
    props.getCompaniesHouseCompanyProfile({
      companyNumber: company.companyNumber,
    });

    setStep(1);
  };

  const handleSearchCompaniesHouseClick = (data) => {
    props.searchCompaniesHouse({
      ...companiesHouseUserInput,
      ...modalPagination,
      ...data,
    });
  };

  const handleBackToCompanySelect = () => {
    setModalPagination(INITIAL_MODAL_PAGINATION);
    setStep(0);
  };

  const renderStep = () => {
    switch (step) {
      case 0 :
        return (
          <CompaniesHouseLookupForm
            onFormChange={handleFormChange}
            data={companiesHouseUserInput}
            onSubmit={handleCompaniesHouseUserInputSubmit}
          />
        );

      case 1:
        return props.companiesHouseCompanyProfile?.loading
          ? <ActivityIndicator />
          : (
            <CompaniesHouseDetails
              companyProfile={props.companiesHouseCompanyProfile?.data}
            />
          );

      default:
        return null;
    }
  };

  return (
    <div className={styles.page}>
      <div>
        <Heading title="Verify Registered Company" />
      </div>

      <div className={styles.container}>
        {renderStep()}
      </div>

      <div className={styles.buttons}>
        <div>
          <Button
            variant="outline-danger"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </div>
        {
          step === 1 && (
            <div className={styles.buttonGroup}>
              <Button
                variant="outline-primary"
                className={styles.button}
                onClick={handleBackToCompanySelect}
              >
                Back
              </Button>
              <Button
                variant="primary"
                className={styles.button}
                busy={props.loading}
                onClick={() => props.onSubmit({
                  agencyCustomerId: props.agencyCustomerId,
                  registeredCompanyNumber: props.companiesHouseCompanyProfile.data?.company_number,
                  exactLegalName: props.companiesHouseCompanyProfile.data?.company_name,
                })}
              >
                Continue
              </Button>
            </div>
          )
        }
      </div>

      <CompaniesHouseSearchResultsModal
        visible={showCompaniesHouseSearchResultsModal}
        loading={props.loading}
        registeredCompanyNumber={companiesHouseUserInput.registeredCompanyNumber}
        exactLegalName={companiesHouseUserInput.exactLegalName}
        page={modalPagination.page}
        limit={modalPagination.limit}
        results={props.companiesHouseResults}
        onCancel={() => {
          setShowCompaniesHouseSearchResultsModal(false);
          setModalPagination({ ...modalPagination, page: 1 });
        }}
        onItemClick={handleCompanySelected}
        onPageChanged={(n) => {
          const newState = { ...modalPagination, page: n };

          setModalPagination(newState);
          handleSearchCompaniesHouseClick(newState);
        }}
      />
    </div>
  );
}

VerifyRegisteredCompanyPage.propTypes = {
  loading: PropTypes.bool,
  agencyCustomerId: PropTypes.string.isRequired,
  registeredCompanyNumber: PropTypes.string,
  exactLegalName: PropTypes.string,
  companiesHouseCompanyProfile: PropTypes.object,
  companiesHouseResults: PropTypes.object,
  ui: PropTypes.shape({
    submitting: PropTypes.bool,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getCompaniesHouseCompanyProfile: PropTypes.func.isRequired,
  searchCompaniesHouse: PropTypes.func.isRequired,
};
