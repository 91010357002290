import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./NewRegisteredCompanyConfirmationModal.module.scss";

export default function NewRegisteredCompanyConfirmationModal(props) {
  return (
    <ConfirmationModal
      className={styles.container}
      title="Are you sure?"
      submitText="Complete"
      cancelText="Go Back"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div className={styles.line}>
        Are you sure that the company you have selected is correct? Once selected, you cannot change this.
      </div>
    </ConfirmationModal>
  );
}

NewRegisteredCompanyConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
