import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Add, Close, StarRounded } from "@material-ui/icons";
import { Form, IconButton } from "../../";
import { List } from "../../../../lists/containers";
import styles from "./ContactNumberInput.module.scss";

export default function ContactNumberInput(props) {
  const [firstContactNumber, ...additionalContactNumbers] = props.contactNumbers;

  const renderContactNumberRow = (contactNumber, index) => {
    const error = props.errors?.find(x => x.index === index);

    return contactNumber && (
      <Form.FlexRow
        key={`${contactNumber.phoneNumberType?.value || "unknown"}_${index}`}
        className={styles.compactRow}
      >
        <List
          className={styles.phoneNumberType}
          identifier="phone_number_type"
          name="phoneNumberType"
          value={contactNumber?.phoneNumberType?.value}
          preventReload
          highlightError={error?.phoneNumberType}
          onChange={(_e, phoneNumberType) => props.onChange({
            ...contactNumber,
            phoneNumberType,
          }, index)}
          onReady={(list) => {
            if (!contactNumber.phoneNumberType) {
              props.onChange({
                ...contactNumber,
                phoneNumberType: list.data?.values?.find(x => x.is_default) || null,
              }, index);
            }
          }}
        />
        <Form.Input
          className={styles.phoneNumber}
          name="phoneNumber"
          value={contactNumber.phoneNumber}
          placeholder="Enter Phone Number..."
          onChange={e => props.onChange({
            ...contactNumber,
            phoneNumber: _.trim(_.isFinite(+e.target.value) ? e.target.value : contactNumber.phoneNumber),
          }, index)}
          maxLength={15}
          highlightError={error?.phoneNumber}
        />
        {props.contactNumbers?.length > 1 && (
          <div className={styles.actions}>
            <StarRounded
              className={classnames(
                styles.preferredIcon,
                contactNumber.isPreferredNumber && styles.preferred,
              )}
              onClick={() => props.onPreferred(index)}
            />
            <Close
              className={styles.removeIcon}
              onClick={() => props.onRemove(index)}
            />
          </div>
        )}
      </Form.FlexRow>
    );
  };

  return (
    <div className={styles.container}>
      {renderContactNumberRow(firstContactNumber, 0)}
      {additionalContactNumbers.map((contactNumber, index) => renderContactNumberRow(contactNumber, index + 1))}
      <IconButton
        icon={(<Add />)}
        iconPosition="left"
        className={styles.addButton}
        onClick={props.onAdd}
        text="Add Telephone Number"
      />
    </div>
  );
}

ContactNumberInput.propTypes = {
  contactNumbers: PropTypes.arrayOf(PropTypes.shape({
    phoneNumberType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    phoneNumber: PropTypes.string,
    isPreferredNumber: PropTypes.bool,
    error: PropTypes.string,
  })).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number.isRequired,
  })),
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPreferred: PropTypes.func.isRequired,
};
