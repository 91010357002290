const MAPPINGS = {
  charity: "Charity",
  "charitable-incorporated-organisation": "Limited Charity Company",
  government: "Government",
  llc: "Limited Liability Company",
  llp: "Limited Liability Partnership",
  ltd: "Limited Company",
  partnership: "Partnership",
  plc: "Public Limited Company",
};

/**
 * Converts a human-readable string into a value for the API
 *
 * @param {string} value
 */
const from = (value) => {
  return Object.keys(MAPPINGS).find(x => MAPPINGS[x] === value);
};

/**
 * Converts a value from the API into a human-readable string
 *
 * @param {string} value
 */
const to = (value) => {
  return MAPPINGS[value] || value || "Unknown";
};

export default {
  from,
  to,
};
