import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import { List } from "../../../../lists/containers";
import styles from "./GeneralSection.module.scss";

export default function GeneralSection(props) {
  return (
    <Form.Card
      className={styles.container}
      title="General"
      rightAccessory={(
        <IconButton
          icon={<Edit />}
          tooltip="Edit"
          disabled={!props.onEditClick}
          onClick={props.onEditClick}
        />
      )}
    >
      <Form.Group inline>
        <Form.Label inline>Type of Workers</Form.Label>
        <Form.ValueInline>
          <List
            identifier="worker_types"
            value={props.workerTypes}
            readOnly
            checklistDisplayType="values"
          />
        </Form.ValueInline>
      </Form.Group>
    </Form.Card>
  );
}

GeneralSection.propTypes = {
  workerTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onEditClick: PropTypes.func,
};

GeneralSection.defaultProps = {
  workerTypes: [],
};
