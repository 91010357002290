import React, { useState } from "react";
import PropTypes from "prop-types";
import InputManualLimitModal from "./InputManualLimitModal/InputManualLimitModal";
import InsufficientCreditModal from "./InsufficientCreditModal/InsufficientCreditModal";
import SuccessModal from "./SuccessModal/SuccessModal";
import ExperianCreditCheckForAgencyModalValidator from "./ExperianCreditCheckForAgencyModalValidator";
import styles from "./ExperianCreditCheckForAgencyModal.module.scss";

export default function ExperianCreditCheckForAgencyModal(props) {
  const [formData, setFormData] = useState({
    companyFound: true,
    newCreditLimit: null,
    dateOfResult: Date.now(),
    experianCreditReport: null,
  });
  const [errors, setErrors] = useState({});

  const handleFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleRadioChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: String(e.target.value) === "true",
    });
  };

  const handleClearExperianReport = () => setErrors({ ...errors, experianCreditReport: null });

  const handleFormSubmit = () => {
    const validator = new ExperianCreditCheckForAgencyModalValidator(formData);
    const { success, errors } = validator.validate();

    if (!success) {
      setErrors(errors);
      return;
    }

    props.onVerifyAgencyCustomer(formData);
  };

  if (!props.data?.status) {
    return (
      <InputManualLimitModal
        className={styles.container}
        visible={props.visible}
        loading={props.loading}
        data={formData}
        onChange={handleFormChange}
        onClearExperianReport={handleClearExperianReport}
        onRadioChange={handleRadioChange}
        onSubmit={handleFormSubmit}
        onCancel={props.onCancel}
        errors={errors}
      />
    );
  }

  switch (props.data?.status) {
    case "success":
      return (
        <SuccessModal
          className={styles.container}
          visible={props.visible}
          agencyName={props.data.agencyName}
          legalEntityName={props.data.legalEntityName}
          reservedCredit={props.data.reservedCredit}
          onCancel={props.onCancel}
          onSubmit={props.onCommunicateToAgency}
        />
      );

    case "insufficient_credit":
      return (
        <InsufficientCreditModal
          className={styles.container}
          visible={props.visible}
          agencyName={props.data.agencyName}
          legalEntityName={props.data.legalEntityName}
          approvedAmount={props.data.approvedCredit}
          requestedAmount={props.data.requestedAmount}
          onCancel={props.onCancel}
          onSubmit={props.onContinueToInsurerApplication}
        />
      );

    default:
      return null;
  }
}

ExperianCreditCheckForAgencyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    agencyName: PropTypes.string,
    legalEntityName: PropTypes.string,
    approvedCredit: PropTypes.number,
    reservedCredit: PropTypes.number,
    requestedAmount: PropTypes.number,
    status: PropTypes.oneOf(["success", "insufficient_credit"]),
  }),
  onVerifyAgencyCustomer: PropTypes.func.isRequired,
  onContinueToInsurerApplication: PropTypes.func.isRequired,
  onCommunicateToAgency: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ExperianCreditCheckForAgencyModal.displayName = "ExperianCreditCheckForAgencyModal";
