import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./PayrollTransferConfirmationModal.module.scss";

export default function PayrollTransferConfirmationModal(props) {
  return (
    <ConfirmationModal
      className={styles.container}
      title="Are you sure?"
      submitText="Transfer to Payroll"
      cancelText="Cancel"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div className={styles.line}>
        Are you sure you want to transfer your agency to the payroll system?
      </div>
      <div className={styles.line}>
        This cannot be undone.
      </div>
    </ConfirmationModal>
  );
}

PayrollTransferConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
