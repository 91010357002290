import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_CONTRACT_INFORMATION = gql`
query($id: ID!) {
  getAgency(id:$id) {
    contract {
      start_date
      contract_length
      contract_period
      date_first_traded
      fee
      funding_type
    }

    is_new_agency
    is_ledger_buyout
    introduced_via_broker
  }
}
`;

export const getAgencyContractInformation = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_CONTRACT_INFORMATION_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY_CONTRACT_INFORMATION,
      variables: {
        id,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_CONTRACT_INFORMATION_SUCCESS,
      payload: {
        data: response.data.getAgency,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_CONTRACT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
