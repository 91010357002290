import { unreportInsurerReportingActivity } from "./";
import {
  UnreportModal,
} from "../../components";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.insurerReportingActivityId
 * @param {number} obj.reportedAt
 * @param {number} obj.reportedReason
 * @returns
 */
export const showUnreportModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(UnreportModal.displayName, {
    reportedAt: obj.reportedAt,
    reportedReason: obj.reportedReason,
    onSubmit: (data) => {
      dispatch(unreportInsurerReportingActivity({
        insurerReportingActivityId: obj.insurerReportingActivityId,
        ...data,
      }));

      dispatch(modalActions.hideModal(UnreportModal.displayName));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(UnreportModal.displayName));
    },
  }));
};
