import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_TRADING_ADDRESS = gql`
query($id: ID!) {
  getAgency(id:$id) {
    trading_address {
      address_line_one
      address_line_two
      town_or_city
      county
      postcode
    }
  }
}
`;

export const getAgencyTradingAddress = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_TRADING_ADDRESS_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY_TRADING_ADDRESS,
      variables: {
        id,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_TRADING_ADDRESS_SUCCESS,
      payload: {
        agency: response.data.getAgency.trading_address,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_TRADING_ADDRESS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
