import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Popup } from "semantic-ui-react";
import { parseDate } from "../../../../utils";
import styles from "./SeeHistoryTooltip.module.scss";

export default function SeeHistoryTooltip(props) {
  const renderHistoryItem = (item, index) => {
    return (
      <div key={index} className={styles.historyItem}>
        <div className={styles.date}>
          {parseDate(item.updated_at)}
        </div>
        <div className={styles.value}>
          {item.value || "-"}
        </div>
      </div>
    );
  };

  // If we only have one value, and that value is the same as the current one
  // then we don't need to show the history (because nothing has changed!)
  if (props.data?.length === 1 && props.data[0].value === props.currentValue) {
    return props.children;
  }

  return (
    <div className={classnames(props.className, styles[props.variant], styles.container)}>
      {props.children}
      {props.data?.length > 0 && (
        <Popup
          trigger={(
            <span className={styles.text}>
              See History
            </span>
          )}
          on="click"
          position="right center"
          eventsEnabled
          flowing
        >
          <Popup.Content className={styles.content}>
            {props.data.map(renderHistoryItem)}
            {props.data.length >= 5 && (
              <div className={styles.footer}>
                <span className={styles.seeMore}>
                  See More
                </span>
              </div>
            )}
          </Popup.Content>
        </Popup>
      )}
    </div>
  );
}

SeeHistoryTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf([
    "default",
    "compact",
  ]),
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    updated_at: PropTypes.number.isRequired,
  })),
  currentValue: PropTypes.any,
};

SeeHistoryTooltip.defaultProps = {
  data: [],
  variant: "default",
};
