import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../../duck";
import AgencyTradingAddress from "./AgencyTradingAddress";

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgencyTradingAddress);
