import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./TableRow.scss";

export default class TableRow extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    onClick: PropTypes.func,
  }

  render() {
    const { className, children, onClick, ...otherProps } = this.props;

    return (
      <div className={classnames("TableRow", onClick && "selectable", className)} onClick={onClick} {...otherProps}>
        {children}
      </div>
    );
  }

}
