import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid, Header, Table } from "semantic-ui-react";
import { TextLink } from "../../../../common/components";
import { parseDate } from "../../../../../utils";
import styles from "./DataMismatchRow.module.scss";

export default function DataMismatchRow(props) {
  const sortedMismatches = props.mismatches.sort((a,b) => a.created_at - b.created_at);

  const renderMismatchItem = (mismatch, index) => (
    <Table.Row
      className={classnames(
        index % 2 === 1 && styles.stripe,
      )}
    >
      <Table.Cell>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header size="small">
                {mismatch.display_name}
                <span className={styles.createdAt}>Found {parseDate(mismatch.created_at)}</span>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <div className={styles.label}>Optia Value</div>
              <div>
                {mismatch.source_value?.split("\n").map(str => (
                  <div>{str}</div>
                ))}
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.label}>Tempest Value</div>
              <div>
                {mismatch.target_value?.split("\n").map(str => (
                  <div>{str}</div>
                ))}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Table.Cell>
    </Table.Row>
  );

  return (
    <div className={styles.container}>
      <Header>
        {props.agency.known_as}
        <TextLink
          className={styles.textLink}
          text="Go to Agency"
          to={`/agencies/${props.agency.id}`}
        />
      </Header>
      <Table
        className={styles.table}
        basic
      >
        {sortedMismatches.map(renderMismatchItem)}
      </Table>
    </div>
  );
}

DataMismatchRow.propTypes = {
  agency: PropTypes.shape({
    id: PropTypes.string.isRequired,
    known_as: PropTypes.string.isRequired,
  }).isRequired,
  mismatches: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    source_value: PropTypes.string.isRequired,
    target_value: PropTypes.string.isRequired,
    created_at: PropTypes.number.isRequired,
  })).isRequired,
};
