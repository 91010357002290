import _ from "lodash";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_HISTORY = gql`
query($agencyInput: GetHistoryInput!) {
  agencyHistory: getHistory(input: $agencyInput) {
    id
    model
    document_id
    document
    operation
    revision
    created_at
    user {
      id
      first_name
      last_name
    }
    revision_changes {
      id
      path
      old_value
      new_value
    }
  }
}
`;

export const getAgencyHistory = id => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_HISTORY_PENDING,
    });
    const response = await apolloClient.query({
      query: GET_AGENCY_HISTORY,
      variables: {
        agencyInput: {
          document_id: id,
          model: "Agency",
          fields: [],
        },
      },
    });
    const revisions = _.chain(response.data.agencyHistory)
      .reduce((r, v) => [
        ...r,
        ...v.revision_changes.map(revisionChange => ({
          revision: v.revision,
          operation: v.operation,
          created_at: v.created_at,
          path: revisionChange.path,
          old_value: revisionChange.old_value,
          new_value: revisionChange.new_value,
        })),
      ], [])
      .orderBy(["created_at", "revision", "path"])
      .value();

    dispatch({
      type: types.GET_AGENCY_HISTORY_SUCCESS,
      payload: {
        revisions,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_HISTORY_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
