import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_DETAILS = gql`
query(
  $prospect_id: ID!
  $disabled_field_filter_options: ProspectDisabledFieldsFilterOptions!
) {
  getProspect(id: $prospect_id) {
    id
    known_as
    legal_name
    correspondence_name
    vat_number

    trading_as
    website
    linked_in
    twitter
    previous_provider {
      id
      label
      value
    }

    industry_sectors {
      name
    }

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      prospect_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getAgencyDetails = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_DETAILS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_DETAILS,
      variables: {
        prospect_id: prospectId,
        disabled_field_filter_options: {
          field_names: [
            "known_as",
            "trading_as",
            "industry_sectors",
            "website",
            "linked_in",
            "twitter",
            "vat_registration_number",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_DETAILS_SUCCESS,
      payload: {
        data: response.data.getProspect,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
