import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_AGENCY_PREFERENCES = gql`
query(
  $agency_id: ID!
  $disabled_field_filter_options: AgencyDisabledFieldsFilterOptions!
) {
  getAgency(id: $agency_id) {
    preferences {
      payroll_frequency
      default_payslip_preference
      default_customer_vat_rate
    }

    disabled_fields(filter_options: $disabled_field_filter_options) {
      disabled_field
      agency_onboarding_task {
        id
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.agencyId
 */
export const getAgencyPreferences = ({ agencyId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_AGENCY_PREFERENCES_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_AGENCY_PREFERENCES,
      variables: {
        agency_id: agencyId,
        disabled_field_filter_options: {
          field_names: [
            "agency_preferences",
          ],
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_AGENCY_PREFERENCES_SUCCESS,
      payload: {
        data: {
          ...response.data.getAgency.preferences,
          disabled_fields: response.data.getAgency.disabled_fields,
        },
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_AGENCY_PREFERENCES_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
