import { push } from "connected-react-router";
import { verifyAgencyCustomer } from "./";
import { ExperianCreditCheckForAgencyModal } from "../../components";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.agencyCustomerRequestId
 * @param {string} obj.mismatchReasonId
 * @param {string} obj.mismatchReasonDetails
 *
 * @returns
 */
export const showExperianCreditCheckForAgencyModal = obj => async (dispatch) => {
  const creditManagementUrl = `/credit-limit-management/legal-entities/${obj.legalEntityId}/credit-management`;

  dispatch(modalActions.showModal(ExperianCreditCheckForAgencyModal.displayName, {
    onVerifyAgencyCustomer: (experianData) => {
      dispatch(verifyAgencyCustomer({
        legalEntityId: obj.legalEntityId,
        agencyCustomerRequestId: obj.agencyCustomerRequestId,
        verifyMismatchReasonId: obj.mismatchReasonId,
        verifyMismatchReasonDetails: obj.mismatchReasonDetails,
        companyFound: experianData.companyFound,
        experianCreditResult: experianData.newCreditLimit,
        experianDateOfResult: experianData.dateOfResult,
        experianCreditReport: experianData.experianCreditReport,
      }));

      dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));
    },
    onSubmit: () => {
      dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));

      dispatch(push(creditManagementUrl));
    },
    onCancel: () => {
      dispatch(modalActions.hideModal(ExperianCreditCheckForAgencyModal.displayName));

      dispatch(push(creditManagementUrl));
    },
  }));
};
