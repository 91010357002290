import React from "react";
import PropTypes from "prop-types";
import { Divider, List } from "semantic-ui-react";
import { Close } from "@material-ui/icons";
import { IconButton } from "../../../../common/components";
import styles from "./RecipientList.module.scss";

export default function RecipientList(props) {
  return props.recipients.length > 0 && (
    <List divided relaxed>
      {props.recipients.map((recipient, index) => {
        const recipientType = props.recipientTypes.find(r => r.value === recipient.recipientType);
        const contact = props.contacts.find(c => c.id === recipient.contactId);
        const contactName = contact.preferred_name
          ? `${contact.first_name} ${contact.last_name} (${contact.preferred_name})`
          : `${contact.first_name} ${contact.last_name}`;

        return (
          <List.Item key={contact.id}>
            <List.Content floated="right">
              <IconButton
                className={styles.removeButton}
                icon={(<Close />)}
                iconPosition="left"
                onClick={() => props.onRemoveRecipient(index)}
              />
            </List.Content>
            <List.Content>
              <List.Header>
                {recipientType.label}: <span className={styles.name}>{contactName}</span>
              </List.Header>
              <List.Description className={styles.email}>
                {recipient.email || contact.email}
              </List.Description>
            </List.Content>
          </List.Item>
        );
      })}
      <Divider fitted />
    </List>
  );
}

RecipientList.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    preferred_name: PropTypes.string,
  })).isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    contactId: PropTypes.string.isRequired,
    recipientType: PropTypes.oneOf(["to", "cc", "bcc"]),
    email: PropTypes.string,
  })).isRequired,
  recipientTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
};

RecipientList.defaultProps = {
  contacts: [],
  recipients: [],
};
