import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ViewAsProspectPage from "./ViewAsProspectPage";

const mapStateToProps = state => ({
  ...state.onboarding,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    onFetchOnboardingProspects: actions.getOnboardingProspects,
  }, dispatch),
  onGoToTask: (prospectId, taskId) => {
    dispatch(push(`/onboarding/prospects/${prospectId}/${taskId}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAsProspectPage);
