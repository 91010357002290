import React from "react";
import PropTypes from "prop-types";
import { Form } from "../../../../common/components";

export default function AddNewContractContact(props) {
  return (
    <>
      <Form.Group inline>
        <Form.Label required>
          {`${props.contactType} Contact First Name`}
        </Form.Label>
        <Form.Input
          placeholder={`Enter ${props.contactType} First Name...`}
          name="firstName"
          maxLength={255}
          value={props.contact?.firstName}
          onChange={props.onChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Label required>
          {`${props.contactType} Contact Last Name`}
        </Form.Label>
        <Form.Input
          placeholder={`Enter ${props.contactType} Last Name...`}
          name="lastName"
          maxLength={255}
          value={props.contact?.lastName}
          onChange={props.onChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Label required>
          Telephone Number
        </Form.Label>
        <Form.Number
          placeholder="Enter Telephone Number..."
          name="telephone"
          maxLength={20}
          value={props.contact?.telephone}
          onChange={props.onChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Label>
          Email Address
        </Form.Label>
        <Form.Input
          placeholder="Enter Email Address..."
          name="email"
          maxLength={255}
          value={props.contact?.email}
          onChange={props.onChange}
        />
      </Form.Group>
    </>
  );
}

AddNewContractContact.propTypes = {
  contactType: PropTypes.string,
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};
