import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import FormNumber from "../FormNumber/FormNumber";
import FormSelect from "../FormSelect/FormSelect";
import styles from "./FormTaxPeriod.module.scss";
import { DisabledFieldLock } from "../../";

export default function FormTaxPeriod(props) {
  const now = new Date();

  const handleWeekChange = (e) => {
    const value = _.isEmpty(e.target.value)
      ? null
      : Number(e.target.value);

    if (value > 53) {
      return;
    }

    props.onChange && props.onChange({
      week: value,
      year: props.year,
    });
  };

  const handleWeekBlur = () => {
    if (_.isNumber(props.week) && props.week === 0) {
      props.onChange && props.onChange({
        week: 1,
        year: props.year,
      });
    }
  };

  const handleYearChange = (e) => {
    props.onChange && props.onChange({
      week: props.week,
      year: Number(e.target.value),
    });
  };

  const renderLock = () => {
    return props.showLock && (
      <DisabledFieldLock tooltip={props.tooltip} onClick={props.onLockClick} />
    );
  };

  if (props.readOnly) {
    return props.week && props.year
      ? `${props.week} ${props.year}`
      : "-";
  }

  return (
    <div
      className={classnames(
        styles.container,
        styles[props.size],
        props.readOnly && styles.readOnly,
        props.className
      )}
    >
      <div className={styles.inputs}>
        <FormNumber
          className={styles.week}
          value={props.week}
          placeholder="WW"
          disabled={props.disabled}
          readOnly={props.readOnly}
          highlightError={props.highlightError}
          onChange={handleWeekChange}
          onBlur={handleWeekBlur}
        />
        <FormSelect
          className={styles.year}
          placeholder="Select Year"
          options={[
            now.getFullYear(),
            now.getFullYear() + 1,
          ].map(year => ({
            label: String(year),
            value: year,
          }))}
          value={props.year}
          readOnly={props.readOnly}
          disabled={props.disabled}
          highlightError={props.highlightError}
          onChange={handleYearChange}
        />
        {renderLock()}
      </div>
    </div>
  );
}

FormTaxPeriod.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  week: PropTypes.number,
  year: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fluid: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  highlightError: PropTypes.bool,
  showLock: PropTypes.bool,
  tooltip: PropTypes.any,
  onChange: PropTypes.func,
  onLockClick: PropTypes.func,
};

FormTaxPeriod.defaultProps = {
  size: "medium",
};

