import _ from "lodash";

export default class MIReportGroupingFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    _.each(data?.mi_report_groupings, (grouping, groupIndex) => {
      _.each(grouping.values, ({ value }, itemIndex) => {
        const key = `${groupIndex}_${itemIndex}`;

        if (_.isEmpty(value)) {
          errors[key] = "Value cannot be empty";
        }
      });

      // Process any (non-empty) duplicates
      _.chain(grouping.values)
        .filter(({ value }) => !_.isEmpty(value))
        .map(({ value }, itemIndex) => ({ value, itemIndex }))
        .each(({ value, itemIndex }) => {
          const key = `${groupIndex}_${itemIndex}`;
          const duplicates = _.reduce(grouping.values, (r, v) => {
            return r += this.isDuplicate(v.value, value) ? 1 : 0;
          }, -1);

          if (duplicates > 0) {
            errors[key] = "Values must be unique";
          }
        })
        .value();
    });

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  /**
   * Performs a case-insensitive comparison of the two values
   *
   * @param {string} value
   * @param {string} otherValue
   * @returns bool
   */
  isDuplicate = (value, otherValue) => {
    return value.toLowerCase() === otherValue.toLowerCase();
  }
}
