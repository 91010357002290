import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Table } from "semantic-ui-react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { TextLink } from "modules/common/components";
import styles from "./ProspectRow.module.scss";

export default function ProspectRow({ index, prospect, expanded, onRowClick }) {
  const isStriped = index % 2 === 1;

  return (
    <>
      <Table.Row
        key={`prospect_${prospect.id}`}
        className={classnames(
          styles.prospectRow,
          isStriped && styles.stripe,
          expanded && styles.expandedRow,
        )}
        onClick={() => onRowClick()}
      >
        <Table.Cell>
          <TextLink
            className={styles.prospectName}
            text={prospect.knownAs}
            to={`/prospects/${prospect.id}`}
          />
        </Table.Cell>
        <Table.Cell>
          {prospect.contactName}
        </Table.Cell>
        <Table.Cell>
          {prospect.telephoneNumber}
        </Table.Cell>
        <Table.Cell>
          {prospect.emailAddress}
        </Table.Cell>
        <Table.Cell textAlign="right" verticalAlign="middle">
          {expanded
            ? <ExpandLess className={styles.icon} />
            : <ExpandMore className={styles.icon} />
          }
        </Table.Cell>
      </Table.Row>

      {expanded && (
        <Table.Row
          key={`prospect_${prospect.id}`}
          className={classnames(
            styles.prospectRowExpanded,
            isStriped && styles.stripe,
            expanded && styles.expandedRow,
          )}
          onClick={() => onRowClick()}
        >
          <Table.Cell>
            <div>
              <div className={styles.label}>Exact Legal Name</div>
              <div>{prospect.legalName}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div>
              <div className={styles.label}>Company Registration Number</div>
              <div>{prospect.companyNumber}</div>
            </div>
          </Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      )}
    </>
  );
}

ProspectRow.propTypes = {
  index: PropTypes.number.isRequired,
  prospect: PropTypes.shape({
    id: PropTypes.string.isRequired,
    knownAs: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    telephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    legalName: PropTypes.string,
    companyNumber: PropTypes.string,
  }).isRequired,
  expanded: PropTypes.bool,
  onRowClick: PropTypes.func.isRequired,
};
