import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_WORKER_TYPES = gql`
query {
  getWorkerTypes {
    id
    name
  }
}
`;

export const getWorkerTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_WORKER_TYPES_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_WORKER_TYPES,
    });

    dispatch({
      type: types.GET_WORKER_TYPES_SUCCESS,
      payload: {
        workerTypes: response.data.getWorkerTypes,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_WORKER_TYPES_FAILURE,
      payload: {
        error,
      },
    });
  }
};
