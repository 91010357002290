import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const RESPONSE_FRAGMENT = gql`
fragment coreAddExperianCreditCheckResponseFields on AddExperianCreditCheckResponse {
  status
  legal_entity {
    credit_limit_excluding_vat
    credit_limit_including_vat
    undistributed_credit_limit

    latest_experian_report {
      type
      result_excluding_vat
      date_of_result
      status
    }
  }
}`;

const EXPERIAN_CREDIT_CHECK_FRAGMENT = gql`
fragment coreExperianCreditCheckFields on ExperianCreditCheck {
  id
  experian_credit_result
  date_of_result
  approved_credit
  approved_credit_including_vat
  created_at
}`;

const ADD_EXPERIAN_CREDIT_CHECK_WITHOUT_DOCUMENT = gql`
${RESPONSE_FRAGMENT}
${EXPERIAN_CREDIT_CHECK_FRAGMENT}
mutation($input: AddExperianCreditCheckInput!) {
  addExperianCreditCheck(input: $input) {
    ...coreAddExperianCreditCheckResponseFields

    experian_credit_check {
      ...coreExperianCreditCheckFields
    }
  }
}
`;

const ADD_EXPERIAN_CREDIT_CHECK_WITH_DOCUMENT = gql`
${RESPONSE_FRAGMENT}
${EXPERIAN_CREDIT_CHECK_FRAGMENT}
mutation(
  $input: AddExperianCreditCheckInput!
  $filename: String!
  ) {
  addExperianCreditCheck(input: $input) {
    ...coreAddExperianCreditCheckResponseFields

    experian_credit_check {
      ...coreExperianCreditCheckFields
      upload_url(filename: $filename) {
        url
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {Boolean} obj.companyFound
 * @param {number} obj.experianCreditResult
 * @param {number} obj.experianDateOfResult
 * @param {object} obj.experianCreditReport
 * @param {string} obj.experianCreditReport.name
 * @returns
 */
export const addExperianCreditCheck = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_EXPERIAN_CREDIT_CHECK_PENDING,
    });

    const mutation = obj.experianCreditReport ?
      ADD_EXPERIAN_CREDIT_CHECK_WITH_DOCUMENT :
      ADD_EXPERIAN_CREDIT_CHECK_WITHOUT_DOCUMENT;

    const response = await apolloClient.mutate({
      mutation,
      variables: {
        input: {
          company_found: obj.companyFound,
          legal_entity_id: obj.legalEntityId,
          experian_credit_result: parseFloat(obj.experianCreditResult || 0),
          date_of_result: obj.experianDateOfResult,
        },
        filename: obj.experianCreditReport?.name,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    if (response.data.addExperianCreditCheck.experian_credit_check.upload_url?.url) {
      await fetch(response.data.addExperianCreditCheck.experian_credit_check.upload_url.url, {
        method: "PUT",
        body: obj.experianCreditReport,
      });
    }

    dispatch({
      type: types.ADD_EXPERIAN_CREDIT_CHECK_SUCCESS,
      payload: {
        data: response.data.addExperianCreditCheck,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.ADD_EXPERIAN_CREDIT_CHECK_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
