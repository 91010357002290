import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { MultiFieldFilterDropdown } from "modules/common/components";

const INITIAL_FILTERS = [
  {
    label: "Customer Type",
    values: [],
  },{
    label: "Agency Name",
    values: [],
  },
];

export default function AgencyCustomersFilter(props) {
  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const customerTypePossibleValues = props.customers.filters
    ?.find(filter => filter.field === "legalEntity.company_type")?.possible_values
    ?.map(possible_value => ({
      value: possible_value.value,
      displayValue: possible_value.display_value,
      checked: filters.find(filter => filter.label === "Customer Type").values.includes(possible_value.value),
    })) ?? [];

  const agencyNamePossibleValues = props.customers.filters
    ?.find(filter => filter.field === "agency.id")?.possible_values
    ?.map(possible_value => ({
      value: "agency_" + possible_value.value,
      displayValue: possible_value.display_value,
      checked: filters.find(filter => filter.label === "Agency Name").values.includes("agency_" + possible_value.value),
    })) ?? [];

  const prospectNamePossibleValues = props.customers.filters
    ?.find(filter => filter.field === "prospect.id")?.possible_values
    ?.map(possible_value => ({
      value: "prospect_" + possible_value.value,
      displayValue: possible_value.display_value + " (Prospect)",
      checked: filters.find(filter => filter.label === "Agency Name").values.includes("prospect_" + possible_value.value),
    })) ?? [];

  const filterableFields = [
    {
      label: "Customer Type",
      possibleValues: customerTypePossibleValues,
    }, {
      label: "Agency Name",
      possibleValues: [...agencyNamePossibleValues, ...prospectNamePossibleValues],
    },
  ];

  const formatFiltersForRequest = (filters) => {
    const agencyNameValues = filters[filters.findIndex(filter => filter.label === "Agency Name")].values
      .filter(value => value.startsWith("agency"))
      .map(value => value.slice(7));

    const prospectNameValues = filters[filters.findIndex(filter => filter.label === "Agency Name")].values
      .filter(value => value.startsWith("prospect"))
      .map(value => value.slice(9));

    const customerTypeValues = filters[filters.findIndex(filter => filter.label === "Customer Type")].values;

    return {
      company_type: customerTypeValues.length > 0 ? customerTypeValues : undefined,
      agency_id: agencyNameValues.length > 0 ? agencyNameValues : undefined,
      prospect_id: prospectNameValues.length > 0 ? prospectNameValues : undefined,
    };
  };

  const onChange = (e) => {
    const incomingFilter = {
      label: e.split(":")[0],
      value: e.split(":")[1],
    };

    const newFilters = _.cloneDeep(filters);

    const filterIndex = newFilters.findIndex(filter => filter.label === incomingFilter.label);

    const isSelected = newFilters[filterIndex]
      .values.some(value => value === incomingFilter.value);

    if (isSelected) {
      _.remove(newFilters[filterIndex].values, value => value === incomingFilter.value);
    }
    else {
      newFilters[filterIndex].values.push(incomingFilter.value);
    }

    setFilters(newFilters);

    props.onFiltersChange(formatFiltersForRequest(newFilters));
  };

  const onClearFilters = () => {
    setFilters(INITIAL_FILTERS);

    props.onFiltersChange(formatFiltersForRequest(INITIAL_FILTERS));
  };

  return (
    <MultiFieldFilterDropdown
      fields={filterableFields}
      busy={props.customers.loading}
      onChange={onChange}
      onClearFilters={onClearFilters} />
  );
}

AgencyCustomersFilter.propTypes = {
  customers: PropTypes.shape({
    loading: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      possible_values: PropTypes.arrayOf({
        value: PropTypes.string,
        display_value: PropTypes.string,
      }),
    }),
    ) }).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};
