export default class PayrollGeneralFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const errors = {};

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
