import * as types from "./types";


const initialState = {
  loading: true,
  busy: false,
  workers: [],
  worker: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_WORKERS_PENDING:
      return initialState;

    case types.GET_WORKERS_SUCCESS:
      return { ...state, loading: false, workers: action.payload.workers };

    case types.GET_WORKERS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.GET_WORKER_PENDING:
      return { ...state, loading: true, worker: null };

    case types.GET_WORKER_SUCCESS:
      return { ...state, loading: false, worker: action.payload.worker };

    case types.GET_WORKER_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case types.SAVE_WORKER_PENDING:
      return { ...state, busy: true };

    case types.SAVE_WORKER_SUCCESS:
      return { ...state, busy: false, message: action.payload.message };

    case types.SAVE_WORKER_FAILURE:
      return { ...state, busy: false, error: action.payload.error };

    case types.ADD_WORKER_PENDING:
      return { ...state, busy: true };

    case types.ADD_WORKER_SUCCESS:
      return { ...state, busy: false, workers: state.workers.concat(action.payload.worker) };

    case types.ADD_WORKER_FAILURE:
      return { ...state, busy: false, error: action.payload.error };

    case types.DISABLE_WORKERS_PENDING:
      return { ...state, busy: true };

    case types.DISABLE_WORKERS_SUCCESS:
      return { ...state, busy: false, workers: state.workers.map((value) => {
        if (action.payload.ids.includes(value.id)) {
          value.status = "disabled";
        }
        return value;
      }) };

    default:
      return state;
  }
}
