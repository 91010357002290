import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PROSPECT_DOCUMENT = gql`
query($input: ProspectDocumentInput!) {
  getProspectDocument(input: $input) {
    name
    size
    lastModified
    versions {
      versionId
      isLatest
      size
      metadata {
        user {
          first_name
          last_name
        }
        date_uploaded
      }
    }
  }
}
`;

/**
 * @param  {object} obj
 * @param  {string} obj.agencyId
 * @param  {string} obj.documentType
 */
export const getProspectDocument = obj => async (dispatch) => {
  try {
    dispatch({ type: types.GET_PROSPECT_DOCUMENT_PENDING });

    const response = await apolloClient.query({
      query: GET_PROSPECT_DOCUMENT,
      variables: {
        input: {
          prospect_id: obj.prospectId,
          document_type: obj.documentType,
        },
      },
    });

    dispatch({
      type: types.GET_PROSPECT_DOCUMENT_SUCCESS,
      payload: {
        data: response.data.getProspectDocument,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PROSPECT_DOCUMENT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
