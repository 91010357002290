import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../../duck";
import HmrcOnline from "./HmrcOnline";

const mapStateToProps = (state, ownProps) => ({
  ...state.agencies,
  agencyId: ownProps.match.params.agencyId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchHmrcInfo: actions.getHmrcInfo,
  }, dispatch),
  onCancel: (agencyId) => {
    dispatch(push(`/agencies/${agencyId}/payroll`));
  },
  onSubmit: ({ agencyId, data }) => {
    dispatch(actions.updateHmrcInfo({
      agencyId,
      data,
      redirectUrl: `/agencies/${agencyId}/payroll`,
    }));
  },
  onDisabledFieldClick: ({ agencyId, disabledField }) => {
    dispatch(push(`/onboarding/agencies/${agencyId}/${disabledField.agency_onboarding_task.id}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HmrcOnline);
