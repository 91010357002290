import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./Label.scss";

export default class Label extends PureComponent {

  static defaultProps = {
    selectable: true,
  }

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    htmlFor: PropTypes.string,
    selectable: PropTypes.bool,
    title: PropTypes.string,
  };

  handleSelecting = () => {
    if (!this.props.selectable) {
      return false;
    }
  }

  render() {
    const { className, style, children, htmlFor, title } = this.props;
    const selectable = this.props.selectable ? "off" : "on";

    return (
      <label
        className={classnames("Label", className)}
        style={style}
        htmlFor={htmlFor}
        onDragStart={this.handleSelecting}
        unselectable={selectable}
        title={title}
      >
        {children}
      </label>
    );
  }
}
