import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS = gql`
  query(
    $legal_entity_id: ID!
    $experian_credit_check_id: ID!
  ) {
    getLegalEntity(id: $legal_entity_id) {
      ...on RegisteredCompany {
        experian_credit_check(id: $experian_credit_check_id) {
          documents {
            name
            lastModified
            size
            metadata {
              date_uploaded
              extension
            }
          }
        }
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.experianCreditCheckId
 * @returns
 */
export const getExperianCreditCheckDocuments = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS,
      variables: {
        legal_entity_id: obj.legalEntityId,
        experian_credit_check_id: obj.experianCreditCheckId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    const experianCreditCheckDocuments = response.data.getLegalEntity.experian_credit_check
      ? response.data.getLegalEntity.experian_credit_check.documents
      : [];

    dispatch({
      type: types.GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_SUCCESS,
      payload: {
        data: experianCreditCheckDocuments,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_EXPERIAN_CREDIT_CHECK_DOCUMENTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
