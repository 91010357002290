import { CancelScheduledCreditSuspensionModal } from "../../components";
import { cancelCreditSuspension } from "./";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.creditSuspensionId
 */
export const showCancelScheduledCreditSuspensionModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(CancelScheduledCreditSuspensionModal.displayName, {
    onSubmit: () => {
      dispatch(cancelCreditSuspension({ creditSuspensionId: obj.creditSuspensionId }));

      dispatch(modalActions.hideModal(CancelScheduledCreditSuspensionModal.displayName));
    },
    onCancel: () => dispatch(modalActions.hideModal(CancelScheduledCreditSuspensionModal.displayName)),
  }));
};
