import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const CREATE_CREDIT_REVIEW_DATE = gql`
mutation($input: CreateCreditReviewDateInput!) {
  createCreditReviewDate(input: $input) {
    id
    next_review_date
    comment
    created_by {
      first_name
      last_name
    }
    created_at
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {number} obj.dateOfNextReview
 * @param {string} obj.comment (optional)
 * @returns
 */
export const createCreditReviewDate = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.CREATE_CREDIT_REVIEW_DATE_PENDING,
    });

    const response = await apolloClient.query({
      query: CREATE_CREDIT_REVIEW_DATE,
      variables: {
        input: {
          legal_entity_id: obj.legalEntityId,
          next_review_date: obj.dateOfNextReview,
          comment: obj.comment,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.CREATE_CREDIT_REVIEW_DATE_SUCCESS,
      payload: {
        data: response.data.createCreditReviewDate,
      },
    });

  } catch (err) {
    dispatch({
      type: types.CREATE_CREDIT_REVIEW_DATE_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
