import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./CancelScheduledCreditActionModal.module.scss";

export default function CancelScheduledCreditActionModal(props) {

  const handleSubmit = () => {
    props.cancelScheduledAction({
      scheduledCreditActionId: props.scheduledActionId,
    });
    props.hideModal(CancelScheduledCreditActionModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(CancelScheduledCreditActionModal.displayName);
  };

  return (
    <ConfirmationModal
      title="Are you sure?"
      visible
      className={styles.container}
      submitDisabled={props.submitting}
      submitText="Cancel Scheduled Change"
      cancelText="Go Back"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      submitVariant="danger"
    >
      <div>
      Are you sure you want to cancel this scheduled change? No changes will be made to this account.
      </div>
    </ConfirmationModal>
  );
}

CancelScheduledCreditActionModal.propTypes = {
  submitting: PropTypes.bool,
  scheduledActionId: PropTypes.string.isRequired,
  cancelScheduledAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

CancelScheduledCreditActionModal.displayName = "CancelScheduledCreditActionModal";
