import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./CollapsiblePanel.module.scss";

export default class CollapsiblePanel extends PureComponent {

  static propTypes = {
    title: PropTypes.string,
    collapsed: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      collapsed: !!props.collapsed,
    };
  }

  render() {
    const { title, children } = this.props;
    const { collapsed } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.header} onClick={this.handleHeaderClicked}>
          <div className={styles.title}>{title}</div>
          <FontAwesomeIcon
            className={classnames(styles.icon, collapsed && styles.collapsed)}
            icon={faChevronDown}
          />
        </div>
        <div className={classnames(styles.content, collapsed && styles.show)}>
          {children}
        </div>
      </div>
    );
  }

  handleHeaderClicked = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

}
