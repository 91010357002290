import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Squares as ActivityIndicator } from "react-activity";
import { Grid } from "semantic-ui-react";
import { Edit } from "@material-ui/icons";
import BackOfficeContactModal from "./BackOfficeContactModal/BackOfficeContactModal";
import BackOfficeContactRow from "./BackOfficeContactRow/BackOfficeContactRow";
import { Form, IconButton } from "../../../../common/components";
import styles from "./BackOfficeContacts.module.scss";

const getUsersName = (user) => {
  return user
    ? `${user.first_name} ${user.last_name}`
    : "";
};

export default function BackOfficeContacts(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Form>
        <Form.Section
          title="Back Office Contacts"
          underlined
          rightAccessory={!props.readOnly ? (
            <IconButton
              icon={<Edit />}
              tooltip="Edit"
              onClick={() => setShowModal(true)}
            />
          ) : null}
        >
          <Form.Group
            className={classnames(
              props.submitting && styles.submitting
            )}
          >
            {props.loading ? (
              <ActivityIndicator />
            ) : (
              <Grid className={styles.container}>
                <BackOfficeContactRow
                  label="Business Development Manager"
                  name={getUsersName(props.businessDevelopmentManager)}
                />
                <BackOfficeContactRow
                  label="Payroll Account Manager"
                  name={getUsersName(props.payrollAccountManager)}
                />
                <BackOfficeContactRow
                  label="Payroll Account Manager Buddy"
                  name={getUsersName(props.payrollAccountManagerBuddy)}
                />
                <BackOfficeContactRow
                  label="Credit Controller"
                  name={getUsersName(props.creditController)}
                />
                <BackOfficeContactRow
                  label="Credit Controller Buddy"
                  name={getUsersName(props.creditControllerBuddy)}
                />
              </Grid>
            )}
          </Form.Group>
        </Form.Section>
      </Form>
      {!props.readOnly && showModal && (
        <BackOfficeContactModal
          visible={showModal}
          creditControllerId={props.creditController?.id}
          creditControllerBuddyId={props.creditControllerBuddy?.id}
          payrollAccountManagerId={props.payrollAccountManager?.id}
          payrollAccountManagerBuddyId={props.payrollAccountManagerBuddy?.id}
          onCancel={() => setShowModal(false)}
          onSubmit={(data) => {
            props.onChange(data);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}

BackOfficeContacts.propTypes = {
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  creditController: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  creditControllerBuddy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  payrollAccountManager: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  payrollAccountManagerBuddy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  businessDevelopmentManager: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};
