import React from "react";
import PropTypes from "prop-types";
import { Icon, Label } from "semantic-ui-react";

export default function StatusLabel(props) {
  const getColor = () => {
    switch (props.status) {
      case "pending":
        return "blue";

      case "running":
        return "yellow";

      case "complete":
        return "green";

      case "failed":
        return "red";

      default:
        return "";
    }
  };
  const getIcon = () => {
    switch (props.status) {
      case "pending":
        return <Icon name="clock" inverted />;

      case "running":
        return <Icon loading name="circle notch" inverted />;

      default:
        return null;
    }
  };

  return (
    <Label color={getColor()} icon>
      {getIcon()}
      {props.status}
    </Label>
  );
}

StatusLabel.propTypes = {
  status: PropTypes.string,
};
