import { PartApproveCreditApplicationModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.creditApplicationId
 * @param {string} obj.legalEntityId
 *
 * @returns
 */
export const showPartApproveCreditApplicationModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(PartApproveCreditApplicationModal.displayName, {
    creditApplicationId: String(obj.creditApplicationId),
    legalEntityId: String(obj.legalEntityId),
  }));
};
