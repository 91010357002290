import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../common/components";
import { toMoney } from "../../../../utils";
import styles from "./InsufficientUndistributedCreditModal.module.scss";

export default function InsufficientUndistributedCreditModal(props) {
  return (
    <ConfirmationModal
      className={classnames(styles.container, props.className)}
      title="Insufficient Undistributed Credit"
      submitText="Okay"
      visible={props.visible}
      onSubmit={props.onSubmit}
    >
      <div>
        There is currently {toMoney(props.undistributedCredit)} credit available for {props.legalEntityName}.
      </div>
      <div>
        Unfortunately this is not enough to fulfill the requested amount of {toMoney(props.requestedAmount)}.
      </div>
    </ConfirmationModal>
  );
}


InsufficientUndistributedCreditModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  legalEntityName: PropTypes.string.isRequired,
  undistributedCredit: PropTypes.number.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

InsufficientUndistributedCreditModal.displayName = "InsufficientUndistributedCreditModal";
