import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid } from "semantic-ui-react";
import AgencyPreferencesFormValidator from "./AgencyPreferencesFormValidator";
import {
  Alert,
  Button,
  ButtonGroup,
  DisabledFieldLock,
  Form,
} from "../../../../common/components";
import { options, tooltips } from "../../../../../constants";
import { tryGetOption } from "../../../../../utils";
import styles from "./AgencyPreferencesForm.module.scss";

const EDIT_DISABLED_FIELD_CHECK = "agency_preferences";

export default class AgencyPreferencesForm extends Component {

  static propTypes = {
    data: PropTypes.shape({
      disabled_fields: PropTypes.arrayOf(PropTypes.shape({
        disabled_field: PropTypes.string.isRequired,
        prospect_onboarding_task: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
      })),
    }),
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDisabledFieldClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: {
        payroll_frequency: tryGetOption(props.data?.payroll_frequency, options.PAYROLL_FREQUENCY_OPTIONS)?.value || null,
        default_payslip_preference: tryGetOption(props.data?.default_payslip_preference, options.DEFAULT_PAYSLIP_PREFERENCE_OPTIONS)?.value || null,
        default_customer_vat_rate: tryGetOption(props.data?.default_customer_vat_rate, options.DEFAULT_CUSTOMER_VAT_OPTIONS)?.value || null,
      },
      errors: {},
    };
  }

  render() {
    const isDisabled = this.isEditDisabled();

    return (
      <Grid className={styles.container} padded stackable reversed="mobile">
        <Grid.Column width={12}>
          {isDisabled && (
            <Alert className={styles.onboardingAlert} variant="info" showClose={false}>
              <div className={styles.onboardingAlertContent}>
                <DisabledFieldLock
                  className={styles.lockIcon}
                  tooltip={tooltips.REQUIRES_COMPLETING_ONBOARDING_TASK_TOOLTIP}
                  onClick={() => this.handleDisabledFieldClick(EDIT_DISABLED_FIELD_CHECK)}
                />
                The Onboarding Task for this has not been completed yet.
              </div>
            </Alert>
          )}
          <Form className={styles.form}>
            <Form.Section title="Agency Preferences" titleClassName={styles.title}>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.payroll_frequency}
              >
                <Form.Label inline>
                  Payroll Frequency
                </Form.Label>
                <Form.ValueInline>
                  <Form.Select
                    name="payroll_frequency"
                    size="medium"
                    options={options.PAYROLL_FREQUENCY_OPTIONS}
                    value={this.state.data.payroll_frequency}
                    placeholder="Select Frequency"
                    disabled={isDisabled}
                    highlightError={this.state.errors?.payroll_frequency}
                    onChange={this.handleInputChange}
                  />
                </Form.ValueInline>
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.default_payslip_preference}
              >
                <Form.Label inline>
                  Default Payslip Preference
                </Form.Label>
                <Form.ValueInline>
                  <Form.Select
                    name="default_payslip_preference"
                    size="medium"
                    options={options.DEFAULT_PAYSLIP_PREFERENCE_OPTIONS}
                    value={this.state.data.default_payslip_preference}
                    placeholder="Select Preference"
                    disabled={isDisabled}
                    highlightError={this.state.errors?.default_payslip_preference}
                    onChange={this.handleInputChange}
                  />
                </Form.ValueInline>
              </Form.Group>
              <Form.Group
                className={classnames(this.props.submitting && styles.submitting)}
                inline
                wide
                error={this.state.errors?.default_customer_vat_rate}
              >
                <Form.Label inline>
                  Default Customer VAT Rate
                </Form.Label>
                <Form.ValueInline>
                  <Form.Select
                    name="default_customer_vat_rate"
                    size="medium"
                    options={options.DEFAULT_CUSTOMER_VAT_OPTIONS}
                    value={this.state.data.default_customer_vat_rate}
                    placeholder="Select VAT Rate"
                    disabled={isDisabled}
                    highlightError={this.state.errors?.default_customer_vat_rate}
                    onChange={this.handleInputChange}
                  />
                </Form.ValueInline>
              </Form.Group>
            </Form.Section>
          </Form>
        </Grid.Column>
        <Grid.Column floated="right">
          <ButtonGroup className={classnames(styles.buttons, styles.buttonsFixed)}>
            <Button
              variant="primary"
              busy={this.props.submitting}
              disabled={this.props.submitting || isDisabled}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              disabled={this.props.submitting}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Grid.Column>
      </Grid>
    );
  }

  handleListValueChange = (e, item) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: item,
      },
    });
  }

  handleInputChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const data = { ...this.state.data };

    // We should only hit this if someone removes the disabled attribute from
    // the Submit button manually...
    if (this.isEditDisabled()) {
      return;
    }

    const validator = new AgencyPreferencesFormValidator(data, this.props);
    const validationResult = validator.validate();

    this.setState({
      errors: validationResult.errors,
    });

    if (!validationResult.success) {
      return;
    }

    onSubmit({
      ...data,
    }, this.props);
  }

  handleDisabledFieldClick = (fieldName) => {
    const { onDisabledFieldClick, data } = this.props;
    const disabledField = data?.disabled_fields?.find(field => field.disabled_field === fieldName);

    onDisabledFieldClick && onDisabledFieldClick(disabledField);
  }

  isEditDisabled = () => {
    const { data } = this.props;

    return data?.disabled_fields?.some(field => field.disabled_field === EDIT_DISABLED_FIELD_CHECK);
  }

}

