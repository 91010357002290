import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_EXISTING_AGENCY_SHORT_CODES = gql`
query {
  getExistingAgencyShortCodes {
    id
    short_code
    known_as
  }
}
`;

export const getExistingAgencyShortCodes = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_EXISTING_AGENCY_SHORT_CODES_PENDING });

    const response = await apolloClient.query({
      query: GET_EXISTING_AGENCY_SHORT_CODES,
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_EXISTING_AGENCY_SHORT_CODES_SUCCESS,
      payload: {
        shortCodes: response.data.getExistingAgencyShortCodes,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_EXISTING_AGENCY_SHORT_CODES_FAILURE,
      payload: {
        error,
      },
    });
  }
};
