import { UnsavedChangesModal } from "../../modals";
import { actions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.className
 * @param {function} obj.onLeaveClick
 * @param {function} obj.onGoBackClick
 */
export const showUnsavedChangesModal = obj => async (dispatch) => {
  dispatch(actions.showModal(UnsavedChangesModal.displayName, {
    className: obj.className,
    onLeaveClick: () => {
      dispatch(actions.hideModal(UnsavedChangesModal.displayName));
      obj.onLeaveClick && obj.onLeaveClick();
    },
    onGoBackClick: () => {
      dispatch(actions.hideModal(UnsavedChangesModal.displayName));
      obj.onGoBackClick && obj.onGoBackClick();
    },
  }));
};
