import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

class AuthenticatedRoute extends Component {

    static propTypes = {
      component: PropTypes.node,
      render: PropTypes.func,
      user: PropTypes.shape({
        attributes: PropTypes.object,
      }).isRequired,
    }

    render() {
      const { component, render, ...props } = this.props;
      const RenderedComponent = component || render;
      return (
        <Route
          {...props}
          render={props => (
            this.props.user.attributes ?
              <RenderedComponent {...props} /> :
              <Redirect to='/sign-in' />
          )}
        />
      );
    }
}

export default AuthenticatedRoute;
