import _ from "lodash";

export default class AgencyVatInformationFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data } = this;

    let errors = {};

    if (!data?.applying_registered_vat) {
      return {
        success: true,
        errors,
      };
    }

    if (!this.isFieldDisabled("vat_registration_number")) {
      if (_.isEmpty(data?.vat_number)) {
        errors = _.set(errors, "vat_number", "VAT Registration Number is required");
      }

      if (!_.isEmpty(data?.vat_number) && data?.vat_number.length < 11) {
        errors = _.set(errors, "vat_number", "VAT Registration Number should be a minimum of 9 numbers");
      }

      if (!data?.vat_period_ending_date) {
        errors = _.set(errors, "vat_period_ending_date", "VAT Period Ending Date is required");
      }

      if (_.isEmpty(data?.vat_frequency)) {
        errors = _.set(errors, "vat_frequency", "VAT Frequency is required");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }

  isFieldDisabled = (fieldName) => {
    return this.props?.data?.disabled_fields?.some(field => field.disabled_field === fieldName);
  }
}
