import _ from "lodash";
import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { KeyCaseConverter } from "../../../../converters";

export const ADD_PROSPECT = gql`
mutation($input: AddProspectInput!) {
  addProspect(input: $input) {
    id
  }
}
`;

const defaults = {
  workerTypes: [],
  knownAs: null,
  correspondenceName: null,
  tradingAs: null,
  legalName: null,
  companyNumber: null,
  introducedViaBroker: false,
  isNewAgency: true,
  isLedgerBuyout: null,
  isExistingPayeScheme: null,
  previousProviderId: null,
  otherPreviousProvider: null,
  contacts: [],
  previousPensionProviderId: null,
  otherPreviousPensionProvider: null,
  businessDevelopmentManagerId: null,
};

/**
 *
 * @param {object} data
 * @param {number[]} data.workerTypes
 * @param {string} data.knownAs
 * @param {string} data.correspondenceName
 * @param {string} data.tradingAs
 * @param {string} data.legalName
 * @param {string} data.companyNumber
 * @param {boolean} data.introducedViaBroker
 * @param {boolean} data.isNewAgency
 * @param {boolean} data.isLedgerBuyout
 * @param {boolean} data.isExistingPayeScheme
 * @param {number} data.previousProviderId
 * @param {string} data.otherPreviousProvider
 * @param {object[]} data.contacts
 * @param {boolean} data.contacts[].primaryContact
 * @param {string} data.contacts[].niNumber
 * @param {object[]} data.contacts[].contactNumbers
 * @param {object} data.contacts[].contactNumbers[].phoneNumberType
 * @param {number} data.contacts[].contactNumbers[].phoneNumberType.id
 * @param {string} data.contacts[].contactNumbers[].phoneNumber
 * @param {boolean} data.contacts[].contactNumbers[].isPreferredNumber
 * @param {number} data.previousPensionProviderId
 * @param {string} data.otherPreviousPensionProvider
 * @param {number} data.businessDevelopmentManagerId
 * @returns
 */
export const addProspect = (data = defaults) => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_PROSPECT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: ADD_PROSPECT,
      variables: {
        input: {
          known_as: data.knownAs,
          trading_as: data.tradingAs,
          exact_legal_name: data.legalName,
          correspondence_name: data.correspondenceName,
          company_number: data.companyNumber,
          introduced_via_broker: data.introducedViaBroker,
          is_new_agency: data.isNewAgency,
          is_ledger_buyout: data.isLedgerBuyout,
          is_existing_paye_scheme: data.isExistingPayeScheme,
          previous_provider_id: data.previousProviderId,
          other_previous_provider: data.otherPreviousProvider,
          contacts: data.contacts.map(({ primaryContact, niNumber, ...contact }) => KeyCaseConverter.toSnakeCase(_.omit({
            ...contact,
            is_primary_contact: primaryContact || false,
            ni_number: niNumber,
            contactNumbers: contact.contactNumbers.map(contactNumber => ({
              phoneNumberTypeId: contactNumber.phoneNumberType.id,
              phoneNumber: contactNumber.phoneNumber,
              isPreferredNumber: contactNumber.isPreferredNumber,
            })),
          }, ["meta"]))),
          worker_types: data.workerTypes,
          previous_pension_provider_id: data.previousPensionProviderId,
          other_previous_pension_provider: data.otherPreviousPensionProvider,
          business_development_manager_id: data.businessDevelopmentManagerId,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.ADD_PROSPECT_SUCCESS,
      payload: {
        prospect: response.data.addProspect,
      },
    });

    dispatch(push(`/prospects/${response.data.addProspect.id}`));
  }
  catch (error) {
    dispatch({
      type: types.ADD_PROSPECT_FAILURE,
      payload: {
        error,
      },
    });
  }
};
