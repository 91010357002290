import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_CONTRACT_INFORMATION = gql`
mutation(
  $agency_id: ID!,
  $contract: UpdateAgencyContractInput
  $agency: UpdateAgencyInput
) {
  updateAgencyContract(id: $agency_id, agency_contract_input: $contract) {
    start_date
  }

  updateAgency(id: $agency_id, agency_input: $agency) {
    id
  }
}
`;

export const updateAgencyContractInformation = (agencyId, companyInfo) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_CONTRACT_INFORMATION_PENDING,
    });

    const response = await apolloClient.query({
      query: UPDATE_AGENCY_CONTRACT_INFORMATION,
      variables: {
        agency_id: agencyId,
        contract: companyInfo.contract,
        agency: companyInfo.agency,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_CONTRACT_INFORMATION_SUCCESS,
      payload: {
        agency: {
          contract: response.data.contract,
        },
      },
    });

    dispatch(push(`/agencies/${agencyId}/agency`));
  }
  catch (error) {
    dispatch({
      type: types.UPDATE_AGENCY_CONTRACT_INFORMATION_FAILURE,
      payload: {
        error,
      },
    });
  }
};
