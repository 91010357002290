import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, Icon } from "../../../";
import uploadIcon from "../../../../../../assets/images/upload.svg";
import styles from "./UploadButton.module.scss";

export default function UploadButton(props) {
  const inputRef = useRef();

  const handleFileSelected = (e) => {
    if (e.target.files.length === 0) return;

    let file = e.target.files[0];

    props.onChange && props.onChange({
      target: {
        name: props.name,
        value: {
          name: file.name,
          data: file,
        },
      },
    });
  };

  return (
    <div className={styles.buttonWrapper}>
      <Button
        className={classnames(
          styles.button,
          props.readOnly && styles.readOnly,
        )}
        variant="outline-primary"
        onClick={() => inputRef.current.click()}
        disabled={props.disabled}
      >
        {props.readOnly ? (
          <>
            No Document Available
          </>
        ) : (
          <>
            <Icon className={styles.uploadIcon} icon={uploadIcon} />
            Upload Document
          </>
        )}
      </Button>
      <input
        className={styles.hidden}
        type="file"
        onChange={handleFileSelected}
        ref={inputRef}
      />
    </div>
  );
}

UploadButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
