import React from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Table } from "semantic-ui-react";
import { SortIndicator } from "../../../common/components";
import ProspectRow from "./ProspectRow/ProspectRow";
import styles from "./ProspectList.module.scss";

export default function ProspectList(props) {
  const handleColumnClicked = (name) => {
    props.onColumnClick(name, props.sortOrder[name] || "NONE");
  };

  if (props.loading) {
    return <ActivityIndicator />;
  }

  return (
    <Table
      className={styles.container}
      basic
    >
      <Table.Header>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("knownAs")}
        >
          <div>
            Known As
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.knownAs}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("contactName")}
        >
          <div>
          Contact Name
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.contactName}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("telephoneNumber")}
        >
          <div>
          Telephone Number
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.telephoneNumber}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("emailAddress")}
        >
          <div>
          Email Address
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.emailAddress}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>
          {/* This is for the expand icon */}
        </Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {props.data?.length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan="5" className={styles.noResults}>
              There are no prospects available to view.
            </Table.Cell>
          </Table.Row>
        ) : props.data?.map((prospect, index) => {
          const isExpanded = props.expandedRows.includes(prospect.id);

          return (
            <ProspectRow
              index={index}
              key={`prospect_row_${prospect.id}`}
              prospect={prospect}
              expanded={isExpanded}
              onRowClick={() => {
                if (isExpanded) {
                  props.onRowClick(props.expandedRows.filter(x => x !== prospect.id));
                }
                else {
                  props.onRowClick([...props.expandedRows, prospect.id]);
                }
              }}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
}

ProspectList.propTypes = {
  // Existing
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  expandedRows: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  sortOrder: PropTypes.shape({
    knownAs: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    contactName: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    telephoneNumber: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    emailAddress: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    status: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
  }).isRequired,
  onColumnClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};
