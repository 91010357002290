import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { PayrollView } from "../../components";
import styles from "./PayrollTab.module.scss";

// Conditional upon worker types (from onboarding):
// *only applicable for agencies with PAYE workers
// **only applicable for agencies with limited/umbrella workers

export default class PayrollTab extends Component {

  static propTypes = {
    prospectId: PropTypes.string.isRequired,
    payrollInfo: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object,
      workerTypes: PropTypes.array,
    }).isRequired,
    payrollHistory: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object,
    }).isRequired,
    onFetchPayrollInfo: PropTypes.func.isRequired,
    onFetchPayrollHistory: PropTypes.func.isRequired,
    onDownloadProspectDocument: PropTypes.func.isRequired,
    onEditClick: PropTypes.func,
  }

  async componentDidMount() {
    const { prospectId, onFetchPayrollInfo, onFetchPayrollHistory } = this.props;

    await Promise.all([
      onFetchPayrollInfo({ prospectId }),
      onFetchPayrollHistory({ prospectId }),
    ]);
  }

  render() {
    const { payrollInfo, payrollHistory } = this.props;

    if (payrollInfo.loading || payrollHistory.loading) {
      return <ActivityIndicator />;
    }

    return (
      <PayrollView
        className={styles.container}
        payrollInfo={payrollInfo.data}
        payrollHistory={payrollHistory.data}
        workerTypes={payrollInfo.workerTypes}
        onLoad={this.handleLoad}
        onDownload={this.handleDownload}
        onEditGeneralClick={() => this.handleEditClick("general")}
        onEditWtrHolidayClick={() => this.handleEditClick("wtr-holiday")}
        onEditVatClick={() => this.handleEditClick("vat-information")}
        onEditAutoEnrolmentPensionsClick={() => this.handleEditClick("auto-enrolment-pensions")}
      />
    );
  }

  handleDownload = async (key) => {
    const { prospectId, onDownloadProspectDocument } = this.props;

    onDownloadProspectDocument({ prospectId, documentType: key.name });
  }

  handleEditClick = (section) => {
    const { prospectId, onEditClick } = this.props;

    onEditClick(prospectId, section);
  }
}
