import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import { Close } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import { toMoney } from "../../../../../utils";
import styles from "./DistributeList.module.scss";

const renderAccountLabel = (account) => {
  switch (account.type) {
    case "customerAccount":
      return (
        <div className={styles.value}>
          {account.agency}: {account.name}{" "}({toMoney(account.credit)})
        </div>
      );

    case "legalEntity":
      return (
        <div className={styles.value}>
          Undistributed ({toMoney(account.credit)})
        </div>
      );

    default:
      return null;
  }
};

export default function DistributeList(props) {
  return props.distributions.length > 0 && (
    <List celled relaxed>
      {props.distributions.map((distribution, index) => {
        const account = props.accounts.find(account => account.id === distribution.accountId);

        return (
          <List.Item key={index}>
            <List.Content floated="right">
              <IconButton
                className={styles.removeButton}
                icon={(<Close />)}
                iconPosition="left"
                onClick={() => props.onDistributionRemoved(index)}
              />
            </List.Content>
            <List.Content verticalAlign="middle">
              <List.Description className={styles.description}>
                <Form.Group className={styles.group} inline>
                  <Form.Label className={styles.label} inline>From:</Form.Label>
                  {renderAccountLabel(account)}
                </Form.Group>
                <Form.Group className={styles.group} inline>
                  <Form.Label className={styles.label} inline>Distribute Amount:</Form.Label>
                  <div className={styles.value}>
                    {toMoney(distribution.amount)}
                  </div>
                </Form.Group>
              </List.Description>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
}

DistributeList.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["customerAccount", "legalEntity"]).isRequired,
    name: PropTypes.string.isRequired,
    credit: PropTypes.number.isRequired,
  })).isRequired,
  distributions: PropTypes.arrayOf(PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  })).isRequired,
  onDistributionRemoved: PropTypes.func.isRequired,
};

DistributeList.defaultProps = {
  distributions: [],
  accounts: [],
};
