import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Heading.module.scss";

export default class Heading extends PureComponent {

  static propTypes = {
    className: PropTypes.any,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    caption: PropTypes.string,
    rightAccessory: PropTypes.node,
    hideSeparator: PropTypes.bool,
  }

  render() {
    return (
      <div className={classnames(styles.container, this.props.className)}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{this.props.title}</div>
          {this.props.rightAccessory && (
            <div>
              {this.props.rightAccessory}
            </div>
          )}
        </div>
        {this.props.hideSeparator || (
          <hr className={styles.separator} />
        )}
        {this.props.caption && (
          <div className={styles.caption}>{this.props.caption}</div>
        )}
      </div>
    );
  }

}
