import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { OnboardingTaskList } from "../../components";
import { Heading, SearchInput } from "../../../common/components";
import styles from "./ViewAsTaskPage.module.scss";

export default class ViewAsTaskPage extends Component {

  static propTypes = {
    onboardingTasks: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        completed_agencies: PropTypes.arrayOf(PropTypes.shape({
          status: PropTypes.string.isRequired,
          agency: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        })),
        outstanding_agencies: PropTypes.arrayOf(PropTypes.shape({
          status: PropTypes.string.isRequired,
          agency: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
          progress: PropTypes.shape({
            required_completed: PropTypes.number.isRequired,
            required_total: PropTypes.number.isRequired,
          }),
        })),
      })).isRequired,
      error: PropTypes.string,
    }),
    onFetchOnboardingTask: PropTypes.func.isRequired,
    onFetchOnboardingTasks: PropTypes.func.isRequired,
    onGoToAgencyTask: PropTypes.func.isRequired,
  }

  state = {
    searchText: "",
  };

  async componentDidMount() {
    await this.searchOnboardingAgencies();
  }

  render() {
    const { searchText } = this.state;
    const { onboardingTasks } = this.props;

    return (
      <div className={styles.container}>
        <Heading
          className={styles.header}
          title="Onboarding"
        />
        <div className={classnames(onboardingTasks.loading && styles.loading)} >
          <div className={styles.filterRow}>
            <SearchInput
              name="searchText"
              value={searchText}
              placeholder="Search"
              onChange={this.handleInputChange}
              onSearch={this.searchOnboardingAgencies}
            />
          </div>
          <div className={styles.agencyList}>
            <OnboardingTaskList
              data={this.tasksByUrgency()}
              onTaskExpanded={this.handleTaskExpanded}
              onViewClick={this.handleViewClick}
            />
          </div>
        </div>
      </div>
    );
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleTaskExpanded = async (taskId) => {
    const { onFetchOnboardingTask } = this.props;

    await onFetchOnboardingTask({
      onboardingTaskId: taskId,
    });
  }

  handleViewClick = async (item) => {
    const { onGoToAgencyTask } = this.props;

    await onGoToAgencyTask(item);
  }

  searchOnboardingAgencies = async () => {
    const { searchText } = this.state;
    const { onFetchOnboardingTasks } = this.props;

    await onFetchOnboardingTasks({
      searchText,
    });
  }

  tasksByUrgency = () => {
    return _
      .chain(this.props.onboardingTasks.data)
      .sortBy(task => Math.min(...task.outstanding_agencies
        .filter(agencyTask => agencyTask.due_date)
        .map(agencyTask => agencyTask.due_date)
      ))
      .value();
  };

}
