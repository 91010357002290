import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@material-ui/icons";
import { Form, IconButton } from "../../../common/components";
import { options } from "../../../../constants";
import { toMoney } from "../../../../utils";
import styles from "./AgencyCustomerDetailsCard.module.scss";

export default function AgencyCustomerDetailsCard(props) {
  const customer = {
    ...props.agencyCustomer,
    ...props.agencyCustomer?.customer,
  } ?? {};

  return (
    <Form.Card
      title="Customer"
      variant="h2"
      className={styles.card}
      rightAccessory={
        <IconButton
          icon={
            <div className={styles.edit}>
              <Edit /> Edit
            </div>
          }
          onClick={props.onEditClick}
        />
      }
    >
      <div className={styles.customerContainer}>
        <div className={styles.customerColumn}>
          <Form.Group inline>
            <Form.Label>
              Customer (Exact Legal Name)
            </Form.Label>
            <Form.Label className={styles.value}>
              {customer.legal_name ?? "-"}
            </Form.Label>
          </Form.Group>

          <Form.Group inline>
            <Form.Label>
              Customer Type
            </Form.Label>
            <Form.Label className={styles.value}>
              {options.CUSTOMER_DETAILS_CUSTOMER_TYPES?.find(
                option => option.value === customer.company_type
              )?.label || "-"}
            </Form.Label>
          </Form.Group>

          {["limited_company", "limited_liability_partnership"].includes(
            customer.company_type
          ) && (
            <Form.Group inline>
              <Form.Label>
                Registered Company Number
              </Form.Label>
              <Form.Label className={styles.value}>
                {customer.registered_company_number ?? "-"}
              </Form.Label>
            </Form.Group>
          )}
        </div>

        <div className={styles.customerColumn}>
          <Form.Group inline>
            <Form.Label>
              Is it a charity?
            </Form.Label>
            <Form.Label className={styles.value}>
              {customer.is_registered_charity ? "Yes" : "No"}
            </Form.Label>
          </Form.Group>

          {customer.is_registered_charity && (
            <Form.Group inline>
              <Form.Label>
                Charity Number
              </Form.Label>
              <Form.Label className={styles.value}>
                {customer.registered_charity_number ?? "-"}
              </Form.Label>
            </Form.Group>
          )}

          {[
            "limited_company",
            "limited_liability_partnership",
            "partnership",
            "sole_trader",
          ].includes(customer.company_type) && (
            <Form.Group inline>
              <Form.Label>
                Estimated Credit Needed
              </Form.Label>
              <Form.Label className={styles.value}>
                {toMoney(customer.credit_limit_requested) ?? "-"}
              </Form.Label>
            </Form.Group>
          )}
        </div>
      </div>

      <hr className={styles.separator} />

      {/* Add Document Upload input here */}
      <div className={styles.placeholder}>
        Documents Coming soon...
      </div>
    </Form.Card>
  );
}

AgencyCustomerDetailsCard.propTypes = {
  agencyCustomer: PropTypes.shape({
    legal_name: PropTypes.string,
    company_type: PropTypes.string,
    registered_company_number: PropTypes.string,
    is_registered_charity: PropTypes.string,
    registered_charity_number: PropTypes.string,
    credit_limit_requested: PropTypes.string,
    customer: PropTypes.shape({
      legal_name: PropTypes.string,
      registered_company_number: PropTypes.string,
      registered_charity_number: PropTypes.string,
    }),
  }),
  onEditClick: PropTypes.func.isRequired,
};
