import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const SCHEDULED_EXPERIAN_ACTION = gql`
  fragment coreScheduledExperianActionFields on ScheduledExperianAction {
    id
    legal_entity {
      id
    }
    type
    new_credit_limit
    scheduled_by{
      first_name
      last_name
    }
    scheduled_at
    comment
    status
    completed_at
    created_at
    updated_at
  }
`;

const CREATE_SCHEDULED_EXPERIAN_ACTION_WITHOUT_DOCUMENT = gql`
${SCHEDULED_EXPERIAN_ACTION}
mutation($input: CreateScheduledExperianActionInput!) {
  createScheduledExperianAction(input: $input) {
    ...coreScheduledExperianActionFields
  }
}
`;

const CREATE_SCHEDULED_EXPERIAN_ACTION_WITH_DOCUMENT = gql`
${SCHEDULED_EXPERIAN_ACTION}
mutation(
  $input: CreateScheduledExperianActionInput!
  $filename: String!
  ) {
  createScheduledExperianAction(input: $input) {
    ...coreScheduledExperianActionFields

    upload_url(filename: $filename) {
      url
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {string} obj.type
 * @param {number} obj.newCreditLimit
 * @param {number} obj.scheduledFor
 * @param {object} obj.documentedReason (optional)
 * @param {string} obj.documentedReason.name (optional)
 * @param {string} obj.comment (optional)
 * @returns
 */
export const createScheduledExperianAction = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.CREATE_SCHEDULED_EXPERIAN_ACTION_PENDING,
    });

    const query = obj.documentedReason
      ? CREATE_SCHEDULED_EXPERIAN_ACTION_WITH_DOCUMENT
      : CREATE_SCHEDULED_EXPERIAN_ACTION_WITHOUT_DOCUMENT;

    const response = await apolloClient.query({
      query,
      variables: {
        input: {
          legal_entity_id: obj.legalEntityId,
          type: obj.type,
          new_credit_limit: parseInt(obj.newCreditLimit, 10),
          scheduled_at: obj.scheduledFor,
          comment: obj.comment,
        },
        filename: obj.documentedReason?.name,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    if (response.data.createScheduledExperianAction.upload_url?.url) {
      await fetch(response.data.createScheduledExperianAction.upload_url.url, {
        method: "PUT",
        body: obj.documentedReason,
      });
    }

    dispatch({
      type: types.CREATE_SCHEDULED_EXPERIAN_ACTION_SUCCESS,
      payload: {
        data: response.data.createScheduledExperianAction,
      },
    });

  } catch (err) {
    dispatch({
      type: types.CREATE_SCHEDULED_EXPERIAN_ACTION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
