import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./TableCell.scss";

export default class TableCell extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    collapsed: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  render() {
    const { className, children, collapsed, ...otherProps } = this.props;
    return (
      <div
        className={classnames("TableCell", className, collapsed && "collapsed")}
        {...otherProps}
      >
        {React.Children.map(children, (child, i) => <div className="cell-row" key={i}>{child}</div>)}
      </div>
    );
  }

}
