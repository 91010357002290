import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isDecimal, isNumber } from "../../../../../utils";
import styles from "./FormNumber.module.scss";
import { DisabledFieldLock } from "../../";

export default class FormNumber extends Component {

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    decimal: PropTypes.bool,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    formatNumber: PropTypes.bool,
    highlightError: PropTypes.bool,
    errorMessage: PropTypes.string,
    showError: PropTypes.bool,
    showLock: PropTypes.bool,
    tooltip: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onLockClick: PropTypes.func,
  };

  static defaultProps = {
    size: "medium",
  }

  state = {
    isFocussed: false,
  }


  render() {
    const { disabled, readOnly, className, size, highlightError } = this.props;
    // eslint-disable-next-line no-unused-vars

    return (
      <div
        className={classnames(
          styles.container,
          styles[size],
          className,
          highlightError && styles.highlightError,
          disabled && styles.disabled,
          readOnly && styles.readOnly
        )}
      >
        {this.renderPrefix()}
        {this.renderInput()}
        {this.renderSuffix()}
        {this.renderLock()}
        {this.renderError()}
      </div>
    );
  }

  renderPrefix = () => {
    const { prefix } = this.props;

    return (
      <div className={styles.prefix}>
        {prefix}
      </div>
    );
  }

  renderInput = () => {
    const { isFocussed } = this.state;
    const { name, value, suffix, placeholder, formatNumber, disabled, readOnly, ...otherProps } = this.props;
    const { ...inputProps } = otherProps;

    const defaultValue = value || value === 0
      ? value
      : readOnly
        ? "-"
        : "";

    const numberValue = (readOnly && value
      ? _.join([value, suffix], " ")
      : value || defaultValue);

    return (
      <input
        {...inputProps}
        className={styles.input}
        name={name}
        placeholder={placeholder}
        onChange={this.handleInputChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        value={(formatNumber && !isFocussed)
          ? this.getFormattedValue(numberValue)
          : numberValue
        }
        disabled={disabled}
        readOnly={readOnly}
        autoComplete="off"
      />
    );
  }

  renderSuffix = () => {
    const { readOnly, suffix } = this.props;
    return !readOnly && suffix && (
      <div className={styles.suffix}>
        {suffix}
      </div>
    );
  }

  renderError = () => {
    const { showError, errorMessage, size } = this.props;

    return showError && (
      <div className={classnames(styles.error, styles[size])}>
        <span className={styles.errorHeader}>Error: </span>
        <span className={styles.errorMessage}>{errorMessage}</span>
      </div>
    );
  }

  renderLock = () => {
    const { tooltip, showLock, onLockClick } = this.props;

    return showLock && (
      <DisabledFieldLock tooltip={tooltip} onClick={onLockClick} />
    );
  }

  handleInputChange = (e) => {
    const { disabled, readOnly, decimal, onChange } = this.props;

    if (disabled || readOnly) return;
    if (decimal && !isDecimal(e.target.value)) return;
    if (!decimal && !isNumber(e.target.value)) return;

    onChange && onChange(e);
  }

  handleFocus = () => {
    this.setState({
      isFocussed: true,
    });
  }

  handleBlur = () => {
    const { onBlur } = this.props;
    this.setState({
      isFocussed: false,
    });

    onBlur && onBlur();
  }

  getFormattedValue = (value) => {
    return value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
