import _ from "lodash";
import moment from "moment";

export default class CompleteCreditReviewTaskModalValidator {
  constructor(data, nextReviewDates) {
    this.data = data;
    this.nextReviewDates = nextReviewDates;
  }

  validate() {
    const { data, nextReviewDates } = this;
    let errors = {};

    if (data.setNewReview && data.nextReviewDate < moment().valueOf()) {
      errors = _.set(errors, "nextReviewDate", "You cannot select a date in the past");
    }

    if (data.setNewReview && nextReviewDates.includes(data.nextReviewDate)) {
      errors = _.set(errors, "nextReviewDate", "There is already Date of Next Review for this date");
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
