import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, ButtonGroup } from "../";
import FormCard from "./FormCard/FormCard";
import FormCheckbox from "./FormCheckbox/FormCheckbox";
import FormChecklist from "./FormChecklist/FormChecklist";
import FormDate from "./FormDate/FormDate";
import FormDatePeriod from "./FormDatePeriod/FormDatePeriod";
import FormDateTime from "./FormDateTime/FormDateTime";
import FormDocument from "./FormDocument";
import FormFlexRow from "./FormFlexRow/FormFlexRow";
import FormGroup from "./FormGroup/FormGroup";
import FormInput from "./FormInput/FormInput";
import FormInputList from "./FormInputList/FormInputList";
import FormInputSemantic from "./FormInputSemantic/FormInputSemantic";
import FormLabel from "./FormLabel/FormLabel";
import FormMultiSelect from "./FormMultiSelect/FormMultiSelect";
import FormNote from "./FormNote/FormNote";
import FormNumber from "./FormNumber/FormNumber";
import FormRadio from "./FormRadio/FormRadio";
import FormRadioContainer from "./FormRadioContainer/FormRadioContainer";
import FormRadioList from  "./FormRadioList/FormRadioList";
import FormSection from "./FormSection/FormSection";
import FormSelect from "./FormSelect/FormSelect";
import FormSelectQuery from "./FormSelectQuery/FormSelectQuery";
import FormTaxPeriod from "./FormTaxPeriod/FormTaxPeriod";
import FormValue from "./FormValue/FormValue";
import FormValueInline from "./FormValueInline/FormValueInline";
import FormTextArea from "./FormTextArea/FormTextArea";
import styles from "./Form.module.scss";

function Form({
  className,
  title,
  submitText,
  cancelText,
  canSubmit,
  submitting,
  hideSeparator,
  buttonAlign,
  onSubmit,
  onCancel,
  children,
  requiredKey,
}) {

  const renderButtons = () => {
    return (onSubmit || onCancel) && (
      <>
        {!hideSeparator && <hr className={styles.separator} />}
        <ButtonGroup className={classnames(styles[buttonAlign], styles.buttons)}>
          {onSubmit && <Button disabled={!canSubmit} type="submit" variant="primary" onClick={() => {}} busy={submitting}>
            {submitText || "Submit"}
          </Button>}
          {onCancel && <Button variant="outline-danger" onClick={onCancel}>
            {cancelText || "Cancel"}
          </Button>}
        </ButtonGroup>
      </>
    );
  };

  const handleSubmitClicked = (e) => {
    e.preventDefault();

    onSubmit && onSubmit();
  };

  return (
    <form className={classnames(styles.container, className)} onSubmit={handleSubmitClicked} autoComplete="off">
      {(title || requiredKey) && (
        <div className={styles.heading}>
          <div className={styles.title}>{title}</div>
          {requiredKey && <div className={styles.required}><span>*</span> <em>Required</em></div>}
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
      {renderButtons()}
    </form>
  );
}

Form.defaultProps = {
  canSubmit: true,
  hideSeparator: false,
  requiredKey: false,
};

Form.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  submitting: PropTypes.bool,
  children: PropTypes.node.isRequired,
  canSubmit: PropTypes.bool,
  hideSeparator: PropTypes.bool,
  requiredKey: PropTypes.bool,
  buttonAlign: PropTypes.oneOf([
    "left",
    "right",
    "center",
  ]),
};

Form.Card = FormCard;
Form.Checkbox = FormCheckbox;
Form.Checklist = FormChecklist;
Form.Date = FormDate;
Form.DateTime = FormDateTime;
Form.Document = FormDocument;
Form.DatePeriod = FormDatePeriod;
Form.FormDateTime = FormDateTime;
Form.FlexRow = FormFlexRow;
Form.Group = FormGroup;
Form.Input = FormInput;
Form.InputList = FormInputList;
Form.InputSemantic = FormInputSemantic;
Form.Label = FormLabel;
Form.MultiSelect = FormMultiSelect;
Form.Note = FormNote;
Form.Number = FormNumber;
Form.Section = FormSection;
Form.Select = FormSelect;
Form.SelectQuery = FormSelectQuery;
Form.Radio = FormRadio;
Form.RadioContainer = FormRadioContainer;
Form.RadioList = FormRadioList;
Form.TaxPeriod = FormTaxPeriod;
Form.TextArea = FormTextArea;
Form.Value = FormValue;
Form.ValueInline = FormValueInline;

export default Form;
