import { push } from "connected-react-router";
import * as types from "./types";
import { apolloClient } from "../../../apolloClient";
import * as GQL_TYPES from "./gqltypes";

export const getWorkers = () => (dispatch) => {
  dispatch({
    type: types.GET_WORKERS_PENDING,
  });
  apolloClient.query({ query: GQL_TYPES.GET_WORKERS_LISTVIEW })
    .then(response => dispatch({
      type: types.GET_WORKERS_SUCCESS,
      payload: {
        workers: response.data.getWorkers.map(item => ({
          ...item,
          assignments: 25,
        })),
      },
    }))
    .catch(err => dispatch({
      type: types.GET_WORKERS_FAILURE,
      payload: {
        error: err,
      },
    }));
  // dispatch({
  //   type: types.GET_WORKERS_SUCCESS,
  //   payload: {
  //     workers: [...Array(100).keys()].map(i => ({
  //       id: i + 1,
  //       first_name: "Test",
  //       last_name: ` Worker #${i+1}`,
  //       updated_at: new Date().getTime(),
  //       assignments: Math.ceil(Math.random() * 100),
  //       status: "active",
  //     })),
  //   },
  // });
};

export const selectWorker = id => (dispatch) => {
  dispatch(push(`/workers/${id}/edit`));
};

export const addWorker = data => (dispatch) => {
  dispatch({
    type: types.ADD_WORKER_PENDING,
  });
  apolloClient.mutate({ mutation: GQL_TYPES.ADD_WORKER(data) })
    .then(response => dispatch({
      type: types.ADD_WORKER_SUCCESS,
      payload: {
        worker: { ...response.data.addWorker, assignment: 25 },
      },
    }))
    .catch(err => dispatch({
      type: types.ADD_WORKER_FAILURE,
      payload: {
        error: err,
      },
    }));
};

export const disableWorkers = workers => async (dispatch) => {
  dispatch({ type: types.DISABLE_WORKERS_PENDING });
  try {
    const ids = workers.map(value => value.id);
    const promises = ids.map(id => apolloClient.mutate({ mutation: GQL_TYPES.DISABLE_WORKER(id) }));
    await Promise.all(promises);
    dispatch({ type: types.DISABLE_WORKERS_SUCCESS, payload: { ids } });
  }
  catch (error) {
    dispatch({ type: types.DISABLE_WORKERS_FAILURE, payload: { error } });
  }

};
