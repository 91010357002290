import { push } from "connected-react-router";
import { showCreateCreditApplicationModal } from "./";

export const handleCreditApplicationRequiredTask = creditLimitManagementTask => async (dispatch) => {
  dispatch(push(`/credit-limit-management/legal-entities/${creditLimitManagementTask.data.legal_entity_id}/credit-management`));

  dispatch(showCreateCreditApplicationModal({
    legalEntityId: creditLimitManagementTask.data.legal_entity_id,
  }));
};
