import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, Form } from "../../../common/components";
import styles from "./CreditApplicationCommentModal.module.scss";

export default function CreditApplicationCommentModal (props) {
  const [comment, setComment] = useState("");

  const canComplete = () => {
    return comment !== "";
  };

  const handleSubmit = () => {
    props.createCreditApplicationComment(props.creditApplicationId, comment);

    props.hideModal(CreditApplicationCommentModal.displayName);
  };

  const handleCancel = () => {
    props.hideModal(CreditApplicationCommentModal.displayName);
  };

  const handleFormChange = e => setComment(e.target.value);

  return (
    <ConfirmationModal
      title="Leave Comment"
      visible={props.visible}
      className={styles.container}
      busy={props.submitting}
      submitText="Save Comment"
      onSubmit={handleSubmit}
      submitDisabled={!canComplete()}
      onCancel={handleCancel}
    >
      <Form>
        <Form.Section>
          <Form.TextArea
            name="comments"
            className={styles.textArea}
            value={comment}
            placeholder="Enter comments..."
            rows={6}
            maxLength={255}
            disabled={props.submitting}
            onChange={handleFormChange}
          />
        </Form.Section>
      </Form>
    </ConfirmationModal>
  );
}

CreditApplicationCommentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  creditApplicationId: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  createCreditApplicationComment: PropTypes.func.isRequired,
};

CreditApplicationCommentModal.displayName = "CreditApplicationCommentModal";
