import React, { Component } from "react";
import PropTypes from "prop-types";
import { DataImportList } from "../../components";
import { Heading, LinkDropdown } from "../../../common/components";
import styles from "./DataImportPage.module.scss";

export default class DataImportPage extends Component {

  static propTypes = {
    dataImports: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.string,
    }).isRequired,
    onFetchDataImports: PropTypes.func.isRequired,
    onImportAgencies: PropTypes.func.isRequired,
    onImportProspects: PropTypes.func.isRequired,
    onImportTempest: PropTypes.func.isRequired,
  }

  optiaRef = null;
  tempestRef = null;

  state = {
    selectedImportType: null,
  }

  async componentDidMount() {
    await this.props.onFetchDataImports();
  }

  render() {
    return (
      <div className={styles.container}>
        <Heading
          className="heading"
          title="Data Import"
          rightAccessory={this.renderDropdown()}
        />
        <div className="content">
          <DataImportList
            loading={this.props.dataImports.loading}
            data={this.props.dataImports.data}
          />
        </div>
        <input
          className={styles.hidden}
          name="optia_import"
          type="file"
          accept=".xml"
          ref={_ref => this.optiaRef = _ref}
          onChange={this.handleFileSelected}
        />
        <input
          className={styles.hidden}
          name="tempest_import"
          type="file"
          accept=".csv"
          ref={_ref => this.tempestRef = _ref}
          onChange={this.handleFileSelected}
        />
      </div>
    );
  }

  renderDropdown = () => {
    return (
      <LinkDropdown text="Import" direction="left">
        <LinkDropdown.Item className={styles.link} onClick={this.handleImportProspectsClick}>
          Import Prospects
        </LinkDropdown.Item>
        <LinkDropdown.Item className={styles.link} onClick={this.handleImportAgenciesClick}>
          Import Agencies
        </LinkDropdown.Item>
        <LinkDropdown.Item className={styles.link} onClick={this.handleImportTempestDataClick}>
          Import Tempest Data
        </LinkDropdown.Item>
      </LinkDropdown>
    );
  }

  handleImportProspectsClick = () => {
    this.setState({
      selectedImportType: "prospect",
    }, () => this.optiaRef.click());
  }

  handleImportAgenciesClick = () => {
    this.setState({
      selectedImportType: "agency",
    }, () => this.optiaRef.click());
  }

  handleImportTempestDataClick = () => {
    this.setState({
      selectedImportType: "tempest",
    }, () => this.tempestRef.click());
  }

  handleFileSelected = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    let file = e.target.files[0];

    switch (this.state.selectedImportType) {
      case "agency":
        return this.props.onImportAgencies({ file });

      case "prospect":
        return this.props.onImportProspects({ file });

      case "tempest":
        return this.props.onImportTempest({ file });

      default:
        alert(`Invalid import type: ${this.state.selectedImportType}`);
        break;
    }
  };

}
