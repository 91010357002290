import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_PENSIONS_INFO = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectPensionsInfoInput!
) {
  updateProspectPensionsInfo(prospect_id: $prospect_id, input: $input) {
    id
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {number} obj.data.previous_pensions_provider_id
 * @param {string} obj.redirectUrl
 *
 */
export const updatePensionsInfo = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_PENSIONS_INFO_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_PENSIONS_INFO,
      variables: {
        prospect_id: prospectId,
        input: {
          ...data,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_PENSIONS_INFO_SUCCESS,
      payload: {
        prospect: {
          pensionsInfo: response.data.updateProspectPensionsInfo,
        },
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_PENSIONS_INFO_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
