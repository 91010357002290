import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_CONTRACT_INFORMATION = gql`
mutation(
  $prospect_id: ID!
  $prospect_input: UpdateProspectInput!
  $contract_input: UpdateProspectContractInput!
) {
  updateProspect(id: $prospect_id, input: $prospect_input) {
    introduced_via_broker
    is_ledger_buyout
    is_new_agency
  }

  updateProspectContract(prospect_id: $prospect_id, input: $contract_input) {
    start_date
    contract_length
    contract_period
    date_first_traded
    fee
    funding_type
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {boolean} obj.data.introduced_via_broker
 * @param {boolean} obj.data.is_ledger_buyout
 * @param {number} obj.data.contract_start_date
 * @param {object} obj.data.length_of_contract
 * @param {number} obj.data.length_of_contract.contract_length
 * @param {string} obj.data.length_of_contract.contract_period
 * @param {number} obj.data.date_first_traded
 * @param {number} obj.data.fee
 * @param {string} obj.data.funding_type
 * @param {string} obj.redirectUrl
 *
 */
export const updateContractInformation = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_CONTRACT_INFORMATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_CONTRACT_INFORMATION,
      variables: {
        prospect_id: prospectId,
        prospect_input: {
          introduced_via_broker: data.introduced_via_broker,
          is_ledger_buyout: data.is_ledger_buyout,
        },
        contract_input: {
          start_date: data.contract_start_date,
          contract_length: +data.length_of_contract?.contract_length,
          contract_period: data.length_of_contract?.contract_period,
          date_first_traded: data.date_first_traded,
          fee: data.fee && parseFloat(data.fee),
          funding_type: data.funding_type,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_CONTRACT_INFORMATION_SUCCESS,
      payload: {
        prospect: {
          ...response.data.updateProspect,
          contract: response.data.updateProspectContract,
        },
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_CONTRACT_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
