import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import {
  GetAppRounded,
  CloseRounded,
} from "@material-ui/icons";
import { parseDate, getUsersName } from "../../../../../utils";
import styles from "./DocumentRow.module.scss";

export default function DocumentRow(props) {

  const documentType = props.documentTypes?.find(
    option => option.value === props.document.name
  )?.type || props.document.name;

  const latestVersionId = props.document?.versions.find(
    version => version.isLatest
  ).versionId;

  return (
    <>
      <Table.Row
        key={`document_row_${props.key}`}>
        <Table.Cell className={styles.documentName}>
          {documentType}
          {props.document.versions.length > 1 && (
            <div>
              <span
                className={styles.versionHistoryLabel}
                onClick={() => props.onVersionHistoryClick({
                  prospectId: props.prospectId,
                  documentType: props.document.metadata.document_type,
                })}>
                Version History
              </span>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          {getUsersName(props.document.metadata.user)}
        </Table.Cell>
        <Table.Cell>
          {parseDate(props.document.metadata.date_uploaded)}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <GetAppRounded
            className={styles.downloadIcon}
            onClick={() => props.onDownloadDocument({
              prospectId: props.prospectId,
              documentType,
              versionId: latestVersionId })}/>
          <CloseRounded
            className={styles.deleteIcon}
            onClick={() => props.onDeleteClick({
              prospectId: props.prospectId,
              document: props.document })}/>
        </Table.Cell>
      </Table.Row>
    </>
  );
}

DocumentRow.propTypes = {
  key: PropTypes.string,
  document: PropTypes.shape({
    name: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      user: PropTypes.object,
      date_uploaded: PropTypes.number,
      document_type: PropTypes.string,
    }),
    versions: PropTypes.arrayOf(PropTypes.shape({
      isLatest: PropTypes.bool,
      versionId: PropTypes.string,
    })),
  }),
  prospectId: PropTypes.string.isRequired,
  documentTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  onDownloadDocument: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onVersionHistoryClick: PropTypes.func.isRequired,
};
