import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_PROSPECT_BACK_OFFICE_CONTACTS = gql`
mutation(
  $prospect_id: ID!
  $payroll_account_manager_id: ID!
  $payroll_account_manager_buddy_id: ID
  $credit_controller_id: ID!
  $credit_controller_buddy_id: ID
  $prospect_input: UpdateProspectInput!
) {
  payroll_account_manager: updateProspectPayrollAccountManager(prospect_id: $prospect_id, user_id: $payroll_account_manager_id) {
    id
    first_name
    last_name
  }

  payroll_account_manager_buddy: updateProspectPayrollAccountManagerBuddy(prospect_id: $prospect_id, user_id: $payroll_account_manager_buddy_id) {
    id
    first_name
    last_name
  }

  credit_controller: updateProspectCreditController(prospect_id: $prospect_id, user_id: $credit_controller_id) {
    id
    first_name
    last_name
  }

  credit_controller_buddy: updateProspectCreditControllerBuddy(prospect_id: $prospect_id, user_id: $credit_controller_buddy_id) {
    id
    first_name
    last_name
  }

  updateProspect(id: $prospect_id, input: $prospect_input) {
    business_development_manager {
      id
      first_name
      last_name
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {string} obj.payrollAccountManagerId
 * @param {string} obj.payrollAccountManagerBuddyId
 * @param {string} obj.creditControllerId
 * @param {string} obj.creditControllerBuddyId
 * @param {string} obj.businessDevelopmentManagerId
 */
export const updateProspectBackOfficeContacts = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_PROSPECT_BACK_OFFICE_CONTACTS,
      variables: {
        prospect_id: obj.prospectId,
        payroll_account_manager_id: obj.payrollAccountManagerId,
        payroll_account_manager_buddy_id: obj.payrollAccountManagerBuddyId,
        credit_controller_id: obj.creditControllerId,
        credit_controller_buddy_id: obj.creditControllerBuddyId,
        prospect_input: {
          business_development_manager_id: obj.businessDevelopmentManagerId,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_SUCCESS,
      payload: {
        payroll_account_manager: response.data.payroll_account_manager,
        payroll_account_manager_buddy: response.data.payroll_account_manager_buddy,
        credit_controller: response.data.credit_controller,
        credit_controller_buddy: response.data.credit_controller_buddy,
        business_development_manager: response.data.updateProspect.business_development_manager,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.UPDATE_PROSPECT_BACK_OFFICE_CONTACTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
