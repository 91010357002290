import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Grid, Popup } from "semantic-ui-react";
import {
  CompaniesHouseSearchForm,
  CompaniesHouseSearchResults,
  ValidateProspectModal,
} from "../../components";
import { Button, ButtonGroup, Pager, TextLink } from "../../../common/components";
import styles from "./NewRegisteredProspectSearchPage.module.scss";

const NAME_SEARCH_RESULTS_LIMIT = 3;

export default class NewRegisteredProspectSearchPage extends Component {

  static propTypes = {
    companiesHouseResults: PropTypes.shape({
      loading: PropTypes.bool,
      nameResults: PropTypes.shape({
        total: PropTypes.number.isRequired,
        data: PropTypes.array.isRequired,
        existing_agencies: PropTypes.array.isRequired,
        existing_prospects: PropTypes.array.isRequired,
      }),
      numberResults: PropTypes.shape({
        total: PropTypes.number.isRequired,
        data: PropTypes.array.isRequired,
        existing_agencies: PropTypes.array.isRequired,
        existing_prospects: PropTypes.array.isRequired,
      }),
    }),
    dirty: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    onGoToExisting: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      dirty: props.dirty,
      page: 1,
      companyNumberSearchText: "",
      companyNameSearchText: "",
      selectedCompany: null,
    };
  }

  render() {
    return (
      <>
        <h1 className={styles.title}>Adding New Prospect</h1>
        <hr className={styles.separator} />
        <Grid padded stackable divided stretched columns={2} className={styles.container}>
          <Grid.Column verticalAlign="top">
            <h1>Prospect Details</h1>
            <CompaniesHouseSearchForm
              busy={this.props.companiesHouseResults?.loading}
              onSearch={this.handleSearch}
            />
            <Popup
              trigger={(
                <TextLink
                  className={styles.link}
                  to="#"
                  text="Agency not a registered company yet?"
                />
              )}
            >
              <Popup.Content>
                Coming soon
              </Popup.Content>
            </Popup>
          </Grid.Column>
          <Grid.Column>
            {this.state.dirty && (
              <div
                className={classnames(
                  styles.resultsContainer,
                  this.props.companiesHouseResults.loading && styles.resultsLoading
                )}
              >
                <CompaniesHouseSearchResults
                  searchText={this.state.companyNumberSearchText}
                  {...this.props.companiesHouseResults.numberResults}
                  onItemClick={this.handleItemClicked}
                />
                <CompaniesHouseSearchResults
                  className={styles.nameResults}
                  searchText={this.state.companyNameSearchText}
                  {...this.props.companiesHouseResults.nameResults}
                  onItemClick={this.handleItemClicked}
                />
                <div className={styles.pagination}>
                  {this.props.companiesHouseResults.nameResults?.total > 0 && <Pager
                    pageCount={this.getPageCount()}
                    selectedPage={this.state.page}
                    onPageChanged={this.handlePageChanged}
                  />}
                </div>
              </div>
            )}
            <ButtonGroup className={styles.actions}>
              <Button variant="outline-danger" onClick={this.props.onCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </Grid.Column>
        </Grid>
        {this.renderValidateModal()}
      </>
    );
  }

  renderValidateModal = () => {
    const { showValidateModal, selectedCompany } = this.state;

    return (
      <ValidateProspectModal
        visible={showValidateModal}
        companyStatus={selectedCompany?.companyStatus}
        onSubmit={() => this.setState({ showValidateModal: false }, this.confirmSelectedCompany)}
        onCancel={() => this.setState({ showValidateModal: false, selectedCompany: null })}
      />
    );
  }

  handleSearch = (data) => {
    this.setState({
      dirty: true,
      page: 1,
      companyNumberSearchText: data.registeredCompanyNumber,
      companyNameSearchText: data.exactLegalName,
    }, this.runSearch);
  }

  handlePageChanged = (page) => {
    this.setState({ page }, this.runSearch);
  }

  handleItemClicked = (item, existing) => {
    const { onGoToExisting } = this.props;

    if (existing) {
      onGoToExisting && onGoToExisting(existing);
      return;
    }

    this.setState({
      showValidateModal: true,
      selectedCompany: item,
    });
  }

  runSearch = () => {
    const { onSearch } = this.props;

    onSearch && onSearch({
      companyNumber: this.state.companyNumberSearchText,
      companyName: this.state.companyNameSearchText,
      page: this.state.page,
      limit: NAME_SEARCH_RESULTS_LIMIT,
    });
  }

  getPageCount = () => {
    const { companiesHouseResults } = this.props;

    const pageCount = (companiesHouseResults.nameResults?.total || 1) / NAME_SEARCH_RESULTS_LIMIT;

    if (pageCount < 1) {
      return 1;
    }

    // 1000 results is the max available from CH API.
    if (pageCount > 1000 / 3) {
      return Math.floor(1000 / 3);
    }

    return Math.floor(pageCount);
  }

  confirmSelectedCompany = () => {
    const { onSubmit } = this.props;
    const { selectedCompany } = this.state;

    onSubmit && onSubmit(selectedCompany);
  }

}
