import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { AgencyTradingAddressForm } from "../../../components";
import styles from "./AgencyTradingAddress.module.scss";

export default class AgencyTradingAddress extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }),
    agencyTradingAddress: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object.isRequired,
    }).isRequired,
    companyHistory: PropTypes.shape({
      trading_as: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    agencyDisabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    getAgencyTradingAddress: PropTypes.func.isRequired,
    updateAgencyTradingAddress: PropTypes.func.isRequired,
    getCompanyHistory: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    const { agencyId, getCompanyHistory, getAgencyTradingAddress, getAgencyDisabledFields } = this.props;

    await Promise.all([
      getAgencyTradingAddress(agencyId),
      getCompanyHistory(agencyId),
      getAgencyDisabledFields(
        agencyId,
        [
          "trading_address_line_one",
          "trading_address_line_two",
          "trading_address_town_or_city",
          "trading_address_county",
          "trading_address_postcode",
        ]
      ),
    ]);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderAgencyTradingAddressSection()}
      </div>
    );
  }

  renderAgencyTradingAddressSection = () => {
    const {
      agencyDisabledFields,
      agencyTradingAddress: {
        data,
        loading,
      },
      ui: {
        submitting,
      },
    } = this.props;

    if (loading) {
      return <ActivityIndicator />;
    }

    return (
      <AgencyTradingAddressForm
        agencyTradingAddress={data}
        disabledFields={agencyDisabledFields?.data?.map(field => (field.disabled_field))}
        onDisabledFieldClick={this.handleDisabledFieldClicked}
        onSave={this.handleSaveClicked}
        onCancel={this.handleCancelClicked}
        submitting={submitting}
      />
    );
  }

  handleDisabledFieldClicked = (field) => {
    const { agencyDisabledFields, agencyId, history } = this.props;

    const agencyDisabledField = agencyDisabledFields.data.find(item => item.disabled_field === field);

    history.push(`/onboarding/agencies/${agencyId}/${agencyDisabledField.agency_onboarding_task_id}`);
  }

  handleCancelClicked = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/agency`);
  }

  handleSaveClicked = async (data) => {
    const { agencyId, updateAgencyTradingAddress } = this.props;

    const agencyData = {
      agencyTradingAddress: {
        trading_address: {
          ...data,
        },
      },
    };

    await updateAgencyTradingAddress(agencyId, agencyData);
  }
}
