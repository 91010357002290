import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ConfirmationModal } from "../../../../../common/components";
import { toMoney } from "../../../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import styles from "./SuccessModal.module.scss";

const calculateDirection = (previousLimit, newLimit) => {
  if (newLimit > previousLimit) return "increased";
  if (newLimit < previousLimit) return "decreased";
  return "unchanged";
};

export default function SuccessModal ({
  className,
  visible,
  previousLimit,
  newLimit,
  onSubmit,
}) {
  const direction = calculateDirection(previousLimit, newLimit);

  return (
    <ConfirmationModal
      className={classnames(styles.container, className)}
      submitText="Okay"
      visible={visible}
      onSubmit={onSubmit}
    >
      <div className={styles.content}>
        <FontAwesomeIcon className={styles.icon} icon={faCheckCircle} />
        <div className={styles.message}>
          Experian check has been successfully carried out. The limit has been <strong>{direction}</strong> from {toMoney(previousLimit)} to {toMoney(newLimit)}.
        </div>
      </div>
    </ConfirmationModal>
  );
}


SuccessModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  previousLimit: PropTypes.number.isRequired,
  newLimit: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
