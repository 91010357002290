import React, { Component } from "react";
import PropTypes from "prop-types";
import OnboardingTaskListItem from "./OnboardingTaskListItem/OnboardingTaskListItem";
import styles from "./OnboardingTaskList.module.scss";

export default class OnboardingTaskList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      completed_agencies: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string.isRequired,
        agency: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      })),
      outstanding_agencies: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string.isRequired,
        agency: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        progress: PropTypes.shape({
          required_completed: PropTypes.number.isRequired,
          required_total: PropTypes.number.isRequired,
        }),
      })),
    })),
    onTaskExpanded: PropTypes.func.isRequired,
    onViewClick: PropTypes.func.isRequired,
  }

  state = {
    expandedTask: null,
  }

  render() {
    const { expandedTask } = this.state;
    const { data } = this.props;

    return (
      <div>
        {data.map(onboardingTask => (
          <OnboardingTaskListItem
            className={styles.onboardingTask}
            key={onboardingTask.id}
            isExpanded={onboardingTask.id === expandedTask}
            onExpandToggle={this.handleExpandToggle}
            onViewClick={data => this.props.onViewClick({
              ...data,
              onboardingTaskId: onboardingTask.id,
            })}
            {...onboardingTask}
          />
        ))}
      </div>
    );
  }

  handleExpandToggle = async (taskId) => {
    const { onTaskExpanded } = this.props;
    const { expandedTask } = this.state;

    const newExpandedTask = taskId === expandedTask
      ? null
      : taskId;

    newExpandedTask && await onTaskExpanded(taskId);

    this.setState({
      expandedTask: newExpandedTask,
    });
  }
}
