import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal as Modal } from "../../../common/components";
import styles from "./ConfirmationModal.module.scss";

export default function ConfirmationModal(props) {
  const handleSubmit = () => {
    props.hideModal(ConfirmationModal.displayName);
    props.onSubmit();
  };

  return (
    <Modal
      className={styles.container}
      onCancel={() => props.hideModal(ConfirmationModal.displayName)}
      {...props}
      onSubmit={props.onSubmit ? handleSubmit : null}
    >
    </Modal>
  );
}

ConfirmationModal.displayName = "ConfirmationModal";

ConfirmationModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
