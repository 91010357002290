import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import { actions } from "../../duck";
import { SearchBox } from "../../../common/components";
import styles from "./AgencySearchBox.module.scss";

export class AgencySearchBox extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    agencySearch: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        known_as: PropTypes.string.isRequired,
      })).isRequired,
      pending: PropTypes.bool.isRequired,
    }),
    value: PropTypes.string,
    highlightError: PropTypes.string,
    getAgencySearch: PropTypes.func.isRequired,
    clearAgencySearch: PropTypes.func.isRequired,
    onItemSelected: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.runSearch = _.debounce(this.runSearch, 500);
  }

  render() {
    const { agencySearch, highlightError } = this.props;
    const { value } = this.state;

    return (
      <SearchBox
        className={classnames(
          styles.container,
          highlightError && styles.error,
        )}
        placeholder="Search Agencies..."
        loading={agencySearch.loading}
        value={value}
        results={agencySearch.data.map(item => ({
          ...item,
          title: item.known_as,
        }))}
        pending={agencySearch.pending}
        onSearchChange={this.handleSearchChanged}
        onResultSelect={this.handleResultSelected}
        resultRenderer={this.renderResultItem}
        fluid
      />
    );
  }

  renderResultItem = ({ id, known_as }) => {
    return <span key={id}>{known_as}</span>;
  }

  handleSearchChanged = async (_e, { value }) => {
    this.props.clearAgencySearch();
    this.setState({ value }, await this.runSearch());
  }

  handleResultSelected = (_e, { result }) => {
    const { onItemSelected } = this.props;

    this.setState({ value: result.known_as }, () => {
      onItemSelected({
        name: result.known_as,
        id: result.id,
      });
    });
  }

  runSearch = async () => {
    const { getAgencySearch } = this.props;
    const { value } = this.state;

    if (value) {
      await getAgencySearch({
        searchText: value,
        allowEmptySearch: false,
      });
    }
  }
}

const mapStateToProps = state => ({
  ...state.agencies,
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgencySearchBox);
