import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import TaskPage from "./TaskPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.onboarding,
  onboardingTaskId: ownProps.match.params.onboardingTaskId,
  agencyId: ownProps.match.params.agencyId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchOnboardingTask: actions.getOutstandingAgenciesWithOnboardingTask,
  }, dispatch),
  onViewProfileClick: (id) => {
    dispatch(push(`/agencies/${id}`));
  },
  onGoToAgencyTask: ({ onboardingTaskId, agencyId, agencyOnboardingTaskId }) => {
    dispatch(push(`/onboarding/tasks/${onboardingTaskId}/${agencyId}/${agencyOnboardingTaskId}`));
  },
  onGoToTasks: () => {
    dispatch(push("/onboarding/tasks"));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskPage);
