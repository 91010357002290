import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ViewProspectPage from "./ViewProspectPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    onFetchProspect: actions.getProspect,
  }, dispatch),
  onBackToProspects: () => {
    dispatch(push("/prospects"));
  },
  onConvertToAgency: (prospectId) => {
    dispatch(push(`/convert-to-agency/${prospectId}`));
  },
  onGoToAgency: (agencyId) => {
    dispatch(push(`/agencies/${agencyId}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProspectPage);
