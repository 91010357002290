import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import styles from "./Toaster.module.scss";
import classnames from "classnames";

export default function Toaster(props) {
  const { errors } = props;
  const [latestError] = _.castArray(errors?.slice(-1));

  return (
    <div className={classnames(styles.toaster, latestError && styles.show)}>
      <p className={styles.message}>
        ERROR: <span className={styles.details}>{latestError}</span>
      </p>
    </div>
  );
}

Toaster.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.string,
  ),
};
