import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as agencyActions } from "../../agencies/duck";
import { actions } from "../duck";
import { ItemList, Modal, StatusIcon } from "../../common/components";
import { parseDateTime } from "../../../utils";
import "./WorkersPage.scss";
import WorkerForm from "../components/WorkerForm";

class WorkersPage extends Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    workers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })).isRequired,
    getWorkers: PropTypes.func.isRequired,
    selectWorker: PropTypes.func.isRequired,
    addWorker: PropTypes.func.isRequired,
    updateWorker: PropTypes.func,
    agencies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    getAgencies: PropTypes.func.isRequired,
    disableWorkers: PropTypes.func.isRequired,

  }

  componentDidMount = () => {
    this.props.getWorkers();
    this.props.getAgencies();
  }

  state = { newPageVisible: false };

  render() {
    return (
      <div className="WorkersPage">
        <h1 className="title">Workers</h1>
        <Modal title="New Worker" visible={this.state.newPageVisible} toggleVisibility={this.handleCloseWorkerModal}>
          <WorkerForm agencies={this.props.agencies} onCancel={this.handleCloseWorkerModal} onSubmit={this.handleFormSubmit} />
        </Modal>
        <ItemList
          loading={this.props.loading}
          columns={[
            {
              className: "name",
              name: "Name",
              value: row => `${row.first_name} ${row.last_name}`,
            },
            {
              className: "last-updated",
              name: "Last Updated",
              value: row => parseDateTime(row.updated_at),
            },
            {
              className: "collapsed",
              name: "Active Assignments",
              value: row => row.assignments,
            },
            {
              className: "collapsed",
              name: "Status",
              value: row => <StatusIcon status={row.status} />,
            },
          ]}
          data={this.props.workers}
          onNewClicked={this.handleNewClicked}
          onArchiveClicked={this.handleArchiveClicked}
          onItemClicked={this.handleItemClicked}
          deleteButtonLabel="Disable"
        />
      </div>
    );
  }

  handleFormSubmit = (data) => {
    if (data.id) {
      this.props.updateWorker(data);
    }
    else {
      this.props.addWorker(data);
    }
    this.handleCloseWorkerModal();
  };

  handleCloseWorkerModal = () => {
    this.setState({ newPageVisible: false });
  }

  handleNewClicked = () => {
    this.setState({ newPageVisible: true });
  }

  handleArchiveClicked = (workers) => {
    this.props.disableWorkers(workers);
  }

  handleItemClicked = (worker) => {
    this.props.selectWorker(worker.id);
  }
}

const mapStateToProps = state => ({
  ...state.workers,
  ...state.agencies,
});
const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, ...agencyActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkersPage);
