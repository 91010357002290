import * as types from "../types";

/**
 *
 * @param {string} componentName
 * @param {object} props
 * @returns
 */
export const showModal = (componentName, props) => (dispatch) => {
  dispatch({
    type: types.SHOW_MODAL,
    payload: {
      componentName,
      props,
    },
  });
};
