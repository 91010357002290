import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { bytesToGigabytes } from "../../../../utils";

const UPLOAD_PROSPECT_DOCUMENT = gql`
query($input: ProspectDocumentUploadUrlInput!) {
  getProspectDocumentUploadUrl(input: $input) {
    bucket
    key
    url
  }
}
`;

const GET_PROSPECT_DOCUMENTS = gql`
query($input: ProspectDocumentsInput!) {
  getProspectDocuments(input: $input) {
    documents {
      name
      size
      lastModified
      versions {
        name
        size
        lastModified
        versionId
        isLatest
        metadata {
          user {
            first_name
            last_name
          }
          document_type
          date_uploaded
        }
      }
      metadata {
        user {
          first_name
          last_name
        }
        document_type
      }
    }
    folders
    hasNextPage
  }
}
`;

export const uploadProspectDocument = ({
  prospectId,
  document,
  documentType,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPLOAD_PROSPECT_DOCUMENT_PENDING,
    });

    const fileSize = bytesToGigabytes(document?.size);

    if (fileSize > 5) {
      throw new Error("File size was too large. Maximum file size is 5GB.");
    }

    const response = await apolloClient.query({
      query: UPLOAD_PROSPECT_DOCUMENT,
      variables: {
        input: {
          prospect_id: prospectId,
          document_type: documentType,
          filename: document.name,
        },
      },
    });

    const uploadUrl = response.data.getProspectDocumentUploadUrl;

    await fetch(uploadUrl.url, {
      method: "PUT",
      body: document,
    });

    const documentResponse = await apolloClient.query({
      query: GET_PROSPECT_DOCUMENTS,
      variables: {
        input: {
          prospect_id: prospectId,
        },
      },
    });

    dispatch({
      type: types.UPLOAD_PROSPECT_DOCUMENT_SUCCESS,
      payload: {
        getProspectDocuments: documentResponse.data.getProspectDocuments,
      },
    });

    return document.name;
  }
  catch (err) {
    dispatch({
      type: types.UPLOAD_PROSPECT_DOCUMENT_FAILURE,
      payload: {
        error: err,
        fileSizeError: err.message.startsWith("File size was too large"),
      },
    });
  }
};
