import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import { Form } from "../../../../../common/components";
import { toMoney } from "../../../../../../utils";
import styles from "./RiskCard.module.scss";

export default function RiskCard(props) {
  const getMoneyValue = (value) => {
    if (_.isNil(value)) {
      return "-";
    }

    return toMoney(value);
  };

  return (
    <Form.Card
      className={styles.container}
      title={(
        <>
          Risk
          <span className={styles.caption}>(Incl. VAT)</span>
        </>
      )}
    >
      {props.loading ? (
        <ActivityIndicator />
      ) : (
        <Grid columns={2} relaxed>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.value}>
              Total Debt
            </Grid.Column>
            <Grid.Column className={styles.value} textAlign="right">
              {getMoneyValue(props.totalDebt)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.emphasised}>
              Total Exposure
            </Grid.Column>
            <Grid.Column className={styles.emphasised} textAlign="right">
              {getMoneyValue(props.totalExposure)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Form.Card>
  );
}

RiskCard.propTypes = {
  loading: PropTypes.bool,
  totalDebt: PropTypes.number,
  totalExposure: PropTypes.number,
};
