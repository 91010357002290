import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ReportingTab from "./ReportingTab";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchReportingInfo: actions.getReportingInfo,
  }, dispatch),
  onEditClick: (prospectId, section) => {
    dispatch(push(`/prospects/${prospectId}/edit/reporting/${section}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportingTab);
