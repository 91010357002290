import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ADD_CUSTOMER_CONTRACT = gql`
  mutation($input: CreatePendingContractInput!) {
    createPendingContract(input : $input) {
      id
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.tradingAs
 * @param {string} obj.contractOrAssignment
 * @param {string} obj.siteContact.firstName
 * @param {string} obj.siteContact.lastName
 * @param {number} obj.siteContact.telephone
 * @param {string} obj.siteContact.email
 * @param {string} obj.siteContact.addressLine1
 * @param {string} obj.siteContact.addressLine2
 * @param {string} obj.siteContact.townOrCity
 * @param {string} obj.siteContact.county
 * @param {string} obj.siteContact.postcode
 * @param {boolean} obj.hasInvoiceContact
 * @param {boolean} obj.useSiteContactForInvoice
 * @param {string} obj.invoiceContact.firstName
 * @param {string} obj.invoiceContact.lastName
 * @param {number} obj.invoiceContact.telephone
 * @param {string} obj.invoiceContact.email
 * @param {string} obj.invoiceContact.addressLine1
 * @param {string} obj.invoiceContact.addressLine2
 * @param {string} obj.invoiceContact.townOrCity
 * @param {string} obj.invoiceContact.county
 * @param {string} obj.invoiceContact.postcode
 * @param {boolean} obj.isPurchaseOrderNumberRequired
 * @param {number} obj.agreedPaymentTermsType
 * @param {string} obj.agreedPaymentTermsValue
 * @param {string} obj.payrollAgencyCustomerRequestId
 * @returns
 */
export const addCustomerContract = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.ADD_CUSTOMER_CONTRACT_PENDING,
    });

    const response = await apolloClient.query({
      query: ADD_CUSTOMER_CONTRACT,
      variables: {
        input: {
          trading_as: obj.tradingAs,
          assignment: obj.contractOrAssignment,
          has_invoice_contact: obj.hasInvoiceContact,
          use_account_contact_for_invoice: obj.useSiteContactForInvoice,
          is_purchase_order_number_required: obj.isPurchaseOrderNumberRequired,
          agreed_payment_terms_type: obj.agreedPaymentTermsType,
          agreed_payment_terms_value: obj.agreedPaymentTermsValue,
          account_contact: {
            first_name: obj.siteContact.firstName,
            last_name: obj.siteContact.lastName,
            telephone_number: obj.siteContact.telephone,
            email_address: obj.siteContact.email,
            address_line_1: obj.siteContact.addressLine1,
            address_line_2: obj.siteContact.addressLine2,
            town_or_city: obj.siteContact.townOrCity,
            county: obj.siteContact.county,
            postcode: obj.siteContact.postcode,
          },
          invoice_contact: {
            first_name: obj.invoiceContact.firstName,
            last_name: obj.invoiceContact.lastName,
            telephone_number: obj.invoiceContact.telephone,
            email_address: obj.invoiceContact.email,
            address_line_1: obj.invoiceContact.addressLine1,
            address_line_2: obj.invoiceContact.addressLine2,
            town_or_city: obj.invoiceContact.townOrCity,
            county: obj.invoiceContact.county,
            postcode: obj.invoiceContact.postcode,
          },
          agency_customer_id: obj.agencyCustomerId,
        },
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    dispatch(push(`/customers/${obj.agencyCustomerId}`));

    dispatch({
      type: types.ADD_CUSTOMER_CONTRACT_SUCCESS,
    });
  }
  catch (err) {
    dispatch({
      type: types.ADD_CUSTOMER_CONTRACT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
