import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import { Squares as ActivityIndicator } from "react-activity";
import _ from "lodash";
import styles from "./Subtask.module.scss";
import { SubtaskLabel, SubtaskTracker } from "..";
import { Alert, Checkbox, ContactList, Form } from "../../../common/components";
import { List } from "../../../lists/containers";
import { Check, Remove } from "@material-ui/icons";

const toggleValue = (values, value) => {
  return values.includes(value)
    ? values.filter(v => v !== value)
    : [...values, value];
};

const Subtask = ({
  className,
  labelClassName,
  name,
  required,
  tracking,
  field,
  value,
  error,
  relatedComments,
  dueDate,
  disabled,
  hidden,
  readOnly,
  saving,
  group,
  onChange,
  onListChange,
  onAddTracking,
  onDownload,
  onDelete,
  onTrackingDelete,
  onCommentSelected,
  onInputListAdd,
  onInputListRemove,
  onInputListChange,
}) => {
  const { type, tooltip } = field;
  const renderReadonlyCheckbox = () => value ? <Check /> : <Remove />;
  const trackerIncomplete = tracking.enabled && tracking.entries?.length > 0 && tracking.entries.slice(-1)[0].type !== "resolve";
  const shouldRenderLabel = !["contact_list"].includes(type);
  const renderInputComponent = () => {
    // Need to unrender the component to get the new values
    if (saving) {
      return <ActivityIndicator />;
    }

    switch (type) {
      case "checkbox":
        return (
          !readOnly ?
            <Checkbox
              disabled={trackerIncomplete}
              containerClassName={styles.checkbox}
              className={styles.input}
              checked={value}
              onClick={() => onChange(field.name, !value)}
            /> :
            renderReadonlyCheckbox()
        );

      case "checklist":
        return (
          <Form.Checklist
            disabled={trackerIncomplete}
            className={styles.input}
            values={value}
            options={field.options}
            onChange={e => onChange(field.name, toggleValue(value, e.target.value))}
          />
        );

      case "radiolist":
        return (
          !readOnly ? (
            <Form.RadioList
              className={styles.input}
              disabled={trackerIncomplete}
              name={field.name}
              options={field.options}
              value={value}
              orientation={field.orientation}
              onChange={e => onChange(field.name, e.target.value)}
            />
          ) : (
            <span className={styles.case}>
              {field.options.find(option => String(option.value) === String(value))?.label || value}
            </span>
          )
        );

      case "text":
        return (
          <Form.Input
            disabled={trackerIncomplete}
            readOnly={field.readOnly || readOnly}
            className={styles.input}
            placeholder={field.placeholder}
            size="small"
            value={value}
            maxLength={field.maxLength}
            onChange={e => onChange(field.name, e.target.value)}
            maskOptions={field.maskOptions}
          />
        );

      case "number":
        return (
          <Form.Number
            disabled={trackerIncomplete}
            readOnly={field.readOnly || readOnly}
            className={styles.input}
            size="small"
            placeholder={field.placeholder}
            value={value}
            onChange={e => onChange(field.name, e.target.value)}
          />
        );

      case "date":
        return (
          <Form.Date
            disabled={trackerIncomplete}
            readOnly={field.readOnly || readOnly}
            className={styles.input}
            size="small"
            value={value}
            onChange={e => onChange(field.name, e.target.value)}
          />
        );

      case "date_period":
        return (
          <Form.DatePeriod
            disabled={trackerIncomplete}
            readOnly={field.readOnly || readOnly}
            className={styles.input}
            value={value.value}
            period={value.period}
            onChange={({ value, period }) => onChange(field.name, { value, period })}
          />
        );


      case "document":
        // Ensuring a re-render after we have saved
        return !saving && (
          <Form.Document
            className={styles.input}
            value={value?.name || value}
            onChange={e => onChange(field.name, {
              ...e.target.value,
              dirty: true,
            })}
            onDelete={() => onDelete(field.name)}
            onDownload={() => onDownload(field.document_type)}
            disabled={trackerIncomplete}
            readOnly={readOnly}
          />
        );

      case "select":
        return field.multiple
          ? (
            <Form.MultiSelect
              disabled={trackerIncomplete}
              readOnly={readOnly}
              className={styles.input}
              placeholder={field.placeholder}
              values={value}
              options={field.options}
              onChange={e => onChange(field.name, e.target.values)}
            />
          ) : (
            <Form.Select
              disabled={trackerIncomplete}
              readOnly={readOnly}
              className={styles.input}
              placeholder={field.placeholder}
              value={value}
              options={field.options}
              clearable={field.clearable}
              onChange={e => onChange(field.name, e.target.value)}
            />
          );

      case "select_query":
        return (
          <Form.SelectQuery
            className={styles.input}
            placeholder={field.placeholder}
            value={value}
            queryOptions={field.queryOptions}
            disabled={trackerIncomplete}
            readOnly={readOnly}
            onChange={e => onChange(field.name, e.target.value)}
          />
        );

      case "list":
        if (!readOnly) {
          return (
            <List
              className={styles.input}
              identifier={field.identifier}
              value={value}
              onChange={(id, item) => {
                if (_.has(id, "target")) {
                  onChange(field.name, id.target.value);
                }
                else {
                  onListChange(field.name, item);
                }
              }}
            />
          );
        }

        if (_.isArray(value)) {
          return value.map(v => (
            <>
              <span>{v?.label}</span><br />
            </>
          ));
        }

        return (
          <List
            className={styles.input}
            identifier={field.identifier}
            value={value}
            readOnly
          />
        );

      case "definelist":
        return (
          !readOnly ?
            <Form.InputList
              disabled={trackerIncomplete}
              readOnly={field.readOnly || readOnly}
              className={styles.input}
              placeholder={field.placeholder}
              size="small"
              values={value}
              addButtonLabel={"Add Another " + group}
              maxLength={field.maxLength}
              onAdd={() => onInputListAdd(field.name)}
              onRemove={e => onInputListRemove(field.name, e)}
              onChange={e => onInputListChange(field.name, e.index, e.value)}
            /> :
            _.castArray(value).map(v => (
              <>
                <span>{v}</span><br />
              </>
            ))
        );

      case "contact_list":
        return (
          <ContactList
            readOnly={field.readOnly || readOnly}
            contacts={value || []}
            onChange={e => onChange(field.name, e.target.value)}
          />
        );

      case "tax_period":
        return (
          <Form.TaxPeriod
            disabled={trackerIncomplete}
            readOnly={field.readOnly || readOnly}
            className={styles.input}
            size="small"
            week={value?.week}
            year={value?.year}
            onChange={({ week, year }) => onChange(field.name, { week, year })}
          />
        );

      case "alert":
        return (
          <Alert
            variant={field.variant}
            visible
            showClose={false}
          >
            <p dangerouslySetInnerHTML={{ __html: field.value }} />
          </Alert>
        );

      default:
        return null;
    }
  };

  const handleAddTracking = (data) => {
    onAddTracking && onAddTracking({
      fieldName: field.name,
      type: data.type,
      name: data.name,
      trackedAt: data.dateTime,
    });
  };

  const handleTrackingDelete = (id) => {
    onTrackingDelete && onTrackingDelete(id);
  };

  const handleClickRelatedComments = (comment) => {
    onCommentSelected && onCommentSelected(comment.id);
  };

  if (hidden) return null;

  const parseName = (name) => {
    return name.split("_").pop();
  };

  const parsedLabelClassNames = _.castArray(labelClassName).map((className) => {
    return styles[className];
  });

  return (
    <div
      className={classnames(
        styles.subtask,
        tracking.entries?.length > 0 && styles.trackedSubtask,
        styles[type],
        className,
        parsedLabelClassNames,
        disabled && styles.disabled,
      )}
    >
      {shouldRenderLabel && (
        <div className={styles[`${type}-label`]}>
          <SubtaskLabel
            className={styles.label}
            name={parseName(name)}
            required={!readOnly && required}
            dueDate={!readOnly && !value && dueDate}
            tooltip={tooltip}
          />
          {(relatedComments
            && relatedComments.length > 0)
            && <div className={styles.relatedComments} onClick={() => handleClickRelatedComments(relatedComments[0])}>Attached Comments ({relatedComments.length})</div>
          }
        </div>
      )}
      <div className={styles[`${type}-input`]} data-tip data-for={`${field.name}-tracking-incomplete-tooltip`}>
        {renderInputComponent()}
        {error && (
          <div className={classnames(styles.error, name === "Account Number" && styles.small)}>
            {error}
          </div>
        )}
      </div>
      {trackerIncomplete && <ReactTooltip
        id={`${field.name}-tracking-incomplete-tooltip`}
        effect="solid"
        place="bottom"
      >
        You must resolve the tracker first before completing this subtask.
      </ReactTooltip>}
      {tracking.enabled && !readOnly && (
        <SubtaskTracker
          className={styles.subtaskTracker}
          data={(tracking.entries || []).map(trackingEntry => ({
            id: trackingEntry.id,
            type: trackingEntry.type,
            name: trackingEntry.entry,
            dateTime: trackingEntry.tracked_at,
          }))}
          onSubmit={handleAddTracking}
          onTrackingDelete={handleTrackingDelete}
        />
      )}
    </div>
  );
};

Subtask.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
  field: PropTypes.shape({
    document_type: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    identifier: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.arrayOf(PropTypes.any),
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })),
    multiple: PropTypes.bool,
    period: PropTypes.oneOf(["months", "years"]),
    readOnly: PropTypes.bool,
    clearable: PropTypes.bool,
    maxLength: PropTypes.number,
    tooltip: PropTypes.string,
    maskOptions: PropTypes.any,
    orientation: PropTypes.oneOf(["horizontal", "vertical"]),
    variant: PropTypes.string,
    queryOptions: PropTypes.object,
  }).isRequired,
  labelClassName: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any,
  error: PropTypes.string,
  tracking: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    entries: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(["tracking", "chase", "resolve"]).isRequired,
      entry: PropTypes.string.isRequired,
      tracked_at: PropTypes.number.isRequired,
    })),
  }).isRequired,
  relatedComments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      comment: PropTypes.string.isRequired,
      created_at: PropTypes.string.number,
      created_by: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
      }).isRequired,
      related_subtasks: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string,
          label: PropTypes.string,
        }).isRequired,
      ),
    })
  ).isRequired,
  dueDate: PropTypes.number,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  group: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onListChange: PropTypes.func.isRequired,
  onAddTracking: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onTrackingDelete: PropTypes.func.isRequired,
  onCommentSelected: PropTypes.func.isRequired,
  onInputListAdd: PropTypes.func.isRequired,
  onInputListRemove: PropTypes.func.isRequired,
  onInputListChange: PropTypes.func.isRequired,
};

export default Subtask;
