import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const CHECK_AGENCY_SHORT_CODE = gql`
query($short_code: String!) {
  checkAgencyShortCodeExists(short_code: $short_code)
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.shortCode
 * @returns
 */
export const checkAgencyShortCode = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.CHECK_AGENCY_SHORT_CODE_PENDING,
    });

    const response = await apolloClient.query({
      query: CHECK_AGENCY_SHORT_CODE,
      variables: {
        short_code: obj.shortCode,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.CHECK_AGENCY_SHORT_CODE_SUCCESS,
      payload: {
        exists: response.data.checkAgencyShortCodeExists,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.CHECK_AGENCY_SHORT_CODE_FAILURE,
      payload: {
        error,
      },
    });
  }
};
