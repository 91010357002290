import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Lock } from "@material-ui/icons";
import styles from "./Input.module.scss";

export default class Input extends Component {

  static defaultProps = {
    size: "medium",
    disabled: false,
    numbersOnly: false,
  };

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    labelText: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    value: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    additionalLabel: PropTypes.node,
    readOnly: PropTypes.bool,
    button: PropTypes.node,
    numbersOnly: PropTypes.bool,
    prefix: PropTypes.string,
  };

  state = { tooltipLocation: { top: 0, left: 0 }, showTooltip: false }

  onLockMouseEnter = (e) => {
    const { top, right, height } = e.target.getBoundingClientRect();
    this.setState({ tooltipLocation: { top: top + window.pageYOffset - height, left: right }, showTooltip: true });
  }

  onLockMouseLeave = () => {
    this.setState({ showTooltip: false });
  }

  render() {
    const { labelText, disabled, readOnly, size, value, className, name, additionalLabel, button, prefix, ...otherProps } = this.props;
    const defaultValue = value ? value : readOnly ? "-" : "";
    const isDisabled = !readOnly && disabled;

    const inputComponent = (
      <div className={classnames(styles.input, styles[size], isDisabled && styles.disabled, readOnly && styles.readOnly)}>
        {prefix && <div className={styles.prefix}>{prefix}</div>}
        <input
          {...otherProps}
          className={styles.inputText}
          name={name}
          onChange={this.handleInputChange}
          value={value || defaultValue}
          disabled={isDisabled}
          readOnly={readOnly}
          autoComplete="off"
        />
        {isDisabled && <Lock className={styles.lock} onMouseEnter={this.onLockMouseEnter} onMouseLeave={this.onLockMouseLeave} /> }
      </div>
    );

    return (
      <div className={classnames(styles.inputContainer, className)}>
        <label className={styles.inputLabel}>{labelText} </label> {additionalLabel}
        {button
          ? <div className={styles.flexBox}>
            {inputComponent}
            {button}
          </div>
          : inputComponent}
        {this.state.showTooltip && <div className={styles.tooltip} style={
          { top: `${this.state.tooltipLocation.top}px`, left: `${this.state.tooltipLocation.left}px` }
        }>
          <Lock />
          <span>This is taken from Company House and therefore cannot be changed</span>
        </div>}
      </div>
    );
  }

  handleInputChange = (e) => {
    const { disabled, readOnly, onChange, numbersOnly } = this.props;
    const matchesNumberRegex = e.target.value.match(/^[0-9]*$/);
    if (disabled || readOnly) return;
    if (numbersOnly && !matchesNumberRegex) return;

    onChange && onChange(e);
  }
}
