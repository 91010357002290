import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import ConfirmationModal from "./ConfirmationModal";

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

const container = connect(null, mapDispatchToProps)(ConfirmationModal);

container.displayName = ConfirmationModal.displayName;

export default container;
