import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import { PublishRounded } from "@material-ui/icons";
import { DocumentList } from "../../components";
import styles from "./DocumentsTab.module.scss";

export default function DocumentsTab(props) {
  const { agencyId, getDocuments, getDocumentTypes } = props;

  useEffect(() => {
    getDocuments({ agencyId });
    getDocumentTypes();
  }, [agencyId, getDocuments, getDocumentTypes]);

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Header>Documents</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <div className={styles.uploadContainer}>
            <PublishRounded className={styles.uploadIcon} />
            <span
              className={styles.uploadTitle}
              onClick={() => props.showUploadDocumentsModal({ agencyId: props.agencyId })}
            >
                Upload New Documents
            </span>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <DocumentList
            loading={props.agencyDocuments.loading}
            documents={props.agencyDocuments.documents}
            agencyId= {props.agencyId}
            documentTypes={props.documentTypes}
            onDownloadDocument={props.downloadDocument}
            onDeleteDocumentClick={props.showDeleteDocumentModal}
            onVersionHistoryClick={props.showAgencyDocumentVersionHistoryModal}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

DocumentsTab.propTypes = {
  agencyId: PropTypes.string.isRequired,
  agencyDocuments: PropTypes.shape({
    loading: PropTypes.bool,
    documents: PropTypes.array,
  }).isRequired,
  documentTypes: PropTypes.array.isRequired,
  getDocuments: PropTypes.func.isRequired,
  getDocumentTypes: PropTypes.func.isRequired,
  showAgencyDocumentVersionHistoryModal: PropTypes.func.isRequired,
  showDeleteDocumentModal: PropTypes.func.isRequired,
  showUploadDocumentsModal: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
};
