import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Popup } from "semantic-ui-react";
import { VerifiedUser as Icon } from "@material-ui/icons";
import { parseDateTime } from "../../../../utils";
import styles from "./VerifiedIcon.module.scss";

export default function VerifiedIcon(props) {
  const renderVerificationMessage = () => {
    if (props.verifiedBy) {
      return (
        <div className={styles.text}>
          Verified by{" "}
          <span className={styles.who}>{props.verifiedBy.firstName} {props.verifiedBy.lastName}</span>{" "}
          on{" "}
          <span className={styles.when}>{parseDateTime(props.verifiedAt)}</span>
        </div>
      );
    }

    return (
      <div className={styles.text}>
        Verified on{" "}
        <span className={styles.when}>{parseDateTime(props.verifiedAt)}</span>
      </div>
    );
  };

  const renderMismatchInfo = () => {
    if (!props.mismatchInfo?.reason) {
      return null;
    }

    return (
      <>
        <div className={classnames(styles.text, styles.mismatch)}>
          Mis-matched - {props.mismatchInfo?.reason}
        </div>
        {props.mismatchInfo?.details && (
          <div className={styles.text}>
            {`"${props.mismatchInfo?.details}"`}
          </div>
        )}
      </>
    );
  };

  return (
    <Popup
      trigger={(
        <Icon className={classnames(props.className, styles.icon)}/>
      )}
      position="right center"
      flowing
    >
      <Popup.Content>
        {renderVerificationMessage()}
        {renderMismatchInfo()}
      </Popup.Content>
    </Popup>
  );
}

VerifiedIcon.propTypes = {
  className: PropTypes.string,
  verifiedAt: PropTypes.number.isRequired,
  verifiedBy: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  mismatchInfo: PropTypes.shape({
    reason: PropTypes.string,
    details: PropTypes.string,
  }),
};
