import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./CancelScheduledCreditSuspensionModal.module.scss";

export default function CancelScheduledCreditSuspensionModal({
  onSubmit,
  onCancel,
}) {
  return (
    <ConfirmationModal
      className={styles.modal}
      title="Are you sure?"
      submitText="Cancel Suspension"
      cancelText="Go Back"
      visible={true}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      Are you sure you want to cancel this scheduled suspension?
    </ConfirmationModal>
  );
}

CancelScheduledCreditSuspensionModal.displayName = "CancelScheduledCreditSuspensionModal";

CancelScheduledCreditSuspensionModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
