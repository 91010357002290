import { ConfirmationModal } from "../../modals";
import { actions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 */
export const showConfirmationModal = obj => async (dispatch) => {
  dispatch(actions.showModal(ConfirmationModal.displayName, obj));
};
