import * as types from "../types";

export const setPagination = pagination => (dispatch) => {
  dispatch({
    type: types.SET_PAGINATION,
    payload: {
      pagination,
    },
  });
};
