import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const DELETE_AGENCY_TASK_TRACKING_ENTRY = gql`
mutation($id: ID!) {
  deleteAgencyOnboardingTaskTrackingEntry(id: $id) {
    id
    field_name
  }
}
`;

export const deleteTrackingEntry = ({
  trackingEntryId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.DELETE_AGENCY_TASK_TRACKING_ENTRY_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: DELETE_AGENCY_TASK_TRACKING_ENTRY,
      variables: {
        id: trackingEntryId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.DELETE_AGENCY_TASK_TRACKING_ENTRY_SUCCESS,
      payload: {
        trackingEntry: response.data.deleteAgencyOnboardingTaskTrackingEntry,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.DELETE_AGENCY_TASK_TRACKING_ENTRY_FAILURE,
      payload: {
        error,
      },
    });
  }
};
