import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import CompleteCreditReviewTaskModal from "./CompleteCreditReviewTaskModal";

const mapStateToProps = state => ({
  loading: state.creditLimitManagement.creditReviewDates.loading,
  creditReviewDates: state.creditLimitManagement.creditReviewDates.data,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(CompleteCreditReviewTaskModal);

container.displayName = "CompleteCreditReviewTaskModal";

export default container;
