import React from "react";
import PropTypes from "prop-types";
import { Lock } from "@material-ui/icons";
import {
  DocumentDownload,
  Form,
  IconButton,
  SeeHistoryTooltip,
} from "../../../../common/components";
import { DOCUMENT_TYPES } from "../../../../documents";
import { tooltips } from "../../../../../constants";
import { CompanyTypeConverter } from "../../../../../converters";
import { capitalize, parseDate } from "../../../../../utils";
import styles from "./CompaniesHouseDetailsSection.module.scss";

export default function CompaniesHouseDetailsSection(props) {
  const documents = [
    {
      name: DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION,
      file: props.certificateOfIncorporation,
    },
  ];

  return (
    <Form.Card
      className={styles.container}
      title="Companies House Details"
      rightAccessory={(
        <IconButton
          className={styles.locked}
          icon={<Lock />}
          tooltip={tooltips.CANNOT_CHANGE_COMPANIES_HOUSE_FIELD_TOOLTIP}
        />
      )}
    >
      <div className={styles.cols}>
        <div className={styles.col}>
          <Form.Group inline>
            <Form.Label>Exact Legal Name</Form.Label>
            <Form.ValueInline>
              <SeeHistoryTooltip variant="compact" data={props.companyHistory?.legal_name}>
                {props.legalName}
              </SeeHistoryTooltip>
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Registered Company Number</Form.Label>
            <Form.ValueInline>
              {props.companyNumber}
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Company Type</Form.Label>
            <Form.ValueInline>
              {CompanyTypeConverter.to(props.companyType)}
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Incorporated Date</Form.Label>
            <Form.ValueInline>
              {parseDate(props.dateOfCreation)}
            </Form.ValueInline>
          </Form.Group>
        </div>
        <div className={styles.col}>
          <Form.Group inline>
            <Form.Label>Company Status</Form.Label>
            <Form.ValueInline>
              {capitalize(props.companyStatus) || "-"}
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Status Details</Form.Label>
            <Form.ValueInline>
              {props.companyStatusDetail || "-"}
            </Form.ValueInline>
          </Form.Group>
          <Form.Group inline>
            <Form.Label>Last Accounts Made Up To</Form.Label>
            <Form.ValueInline>
              {parseDate(props.lastAccountsMadeUpTo) || "-"}
            </Form.ValueInline>
          </Form.Group>
          {props.officers && (
            <Form.Group>
              <Form.Label>Officers</Form.Label>
              {props.officers.map(value => (
                <Form.FlexRow key={value.name}>
                  <Form.ValueInline>
                    {value.name} {value.officer_role}
                  </Form.ValueInline>
                </Form.FlexRow>
              ))}
            </Form.Group>
          )}
        </div>
      </div>
      <DocumentDownload
        variant="footer"
        documents={documents.filter(doc => doc.file)}
        onDownload={props.onDownload}
      />
    </Form.Card>
  );
}

CompaniesHouseDetailsSection.propTypes = {
  certificateOfIncorporation: PropTypes.string,
  companyHistory: PropTypes.shape({
    legal_name: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      updated_at: PropTypes.number.isRequired,
    })),
  }),
  companyNumber: PropTypes.string,
  companyType: PropTypes.string,
  dateOfCreation: PropTypes.number,
  companyStatus: PropTypes.string,
  companyStatusDetail: PropTypes.string,
  lastAccountsMadeUpTo: PropTypes.string,
  legalName: PropTypes.string,
  officers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    officer_role: PropTypes.string,
  })),
  onDownload: PropTypes.func.isRequired,
};
