import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { SortOrderConverter } from "../../../../converters";

const GET_CREDIT_REQUESTS = gql`
query(
  $legal_entity_id: ID!
  $sort_order: [SortOrder]
  $filter_options: CreditRequestFilterOptions
) {
  getLegalEntity(id: $legal_entity_id) {
    id

    credit_requests(sort_order: $sort_order, filter_options: $filter_options) {
      data {
        id
        agency {
          id
          known_as
        }
        prospect {
          id
          known_as
        }
        legal_entity {
          id
        }
        credit_requested
        credit_distributed
        date_communicated
        status
        is_agency_customer_verified
        created_at
        credit_request_communications {
          id
          address_type
          contact {
            first_name
            last_name
            email
          }
        }
      }

      filters {
        field
        possible_values {
          value
          display_value
        }
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @param {object} obj.filters
 * @returns
 */
export const getCreditRequests = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_CREDIT_REQUESTS_PENDING,
    });


    const response = await apolloClient.query({
      query: GET_CREDIT_REQUESTS,
      variables: {
        legal_entity_id: obj.legalEntityId,
        filter_options: obj.filters,
        sort_order: SortOrderConverter({
          created_at: "desc",
        }),
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_CREDIT_REQUESTS_SUCCESS,
      payload: {
        data: response.data.getLegalEntity.credit_requests.data,
        filters: response.data.getLegalEntity.credit_requests.filters,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_CREDIT_REQUESTS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
