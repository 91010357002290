import { isSubtaskDependencyValid } from "./is-subtask-dependency-valid";

export const isSubtaskRequired = (subtask, state = {}) => {
  const requiredDependencies = (subtask.depends_on || [])
    .filter(dependency => dependency?.type === "required");

  if (requiredDependencies.length === 0) {
    return subtask.required;
  }

  return requiredDependencies.every((dependency) => {
    if (dependency.$and) {
      return dependency.$and.every(innerDependency => isSubtaskDependencyValid(innerDependency, state));
    }
    if (dependency.$or) {
      return dependency.$or.some(innerDependency => isSubtaskDependencyValid(innerDependency, state));
    }
    return isSubtaskDependencyValid(dependency, state);
  });
};
