import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Popup } from "semantic-ui-react";
import { parseDateTime } from "../../../../utils";
import styles from "./DateOfNextReviewTooltip.module.scss";

export default function DateOfNextReviewTooltip(props) {
  return (
    <Popup
      trigger={(
        <span className={classnames(props.className, styles.text)}>
          {props.text || "See Latest Update"}
        </span>
      )}
      on="click"
      position="bottom left"
      eventsEnabled
      wide
    >
      <Popup.Content className={styles.content}>
        <div>
          Last updated by <strong>{props.updatedBy}</strong> on <strong>{parseDateTime(props.updatedAt)}</strong>
        </div>
        {props.comment && (
          <div>
            {`"${props.comment}"`}
          </div>
        )}
      </Popup.Content>
    </Popup>
  );
}

DateOfNextReviewTooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  updatedBy: PropTypes.string.isRequired,
  updatedAt: PropTypes.number.isRequired,
  comment: PropTypes.string,
};
