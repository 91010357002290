import moment from "moment";

const parseTime = (date, format = "HH:mm") => {
  if (!date) {
    return "";
  }
  return moment(date).format(format);
};

export default parseTime;
