import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { TransfersList } from "../../components";
import styles from "./TransfersTab.module.scss";

export default class TransfersTab extends Component {

  static propTypes = {
    agencyId: PropTypes.string.isRequired,
    transfers: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
      error: PropTypes.string,
    }),
    onFetchTransfers: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { agencyId, onFetchTransfers } = this.props;

    await onFetchTransfers({ agencyId });
  }

  render() {
    const { transfers } = this.props;
    const orderedData = _.sortBy(transfers.data, "created_at");

    return (
      <div className={styles.container}>
        <TransfersList
          loading={transfers.loading}
          data={orderedData}
        />
      </div>
    );
  }
}
