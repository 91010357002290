import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

export default function ImportTypeLabel(props) {
  const getColor = () => {
    switch (props.importType) {
      case "prospect_import":
        return "blue";

      case "agency_import":
        return "orange";

      case "tempest_import":
        return "purple";

      default:
        return "";
    }
  };

  return (
    <Label color={getColor()}>
      {props.importType}
    </Label>
  );
}

ImportTypeLabel.propTypes = {
  importType: PropTypes.string,
};
