import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../../common/components";
import styles from "./ConvertToAgencyConfirmationModal.module.scss";

export default function ConvertToAgencyConfirmationModal(props) {
  return (
    <ConfirmationModal
      className={styles.container}
      title="Convert to Agency"
      submitText="Convert to Agency"
      cancelText="Cancel"
      visible={props.visible}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
    >
      <div className={styles.line}>
        There are questions that need to be reviewed and completed before the prospect can be converted to an agency. These will be available on the next screen.
      </div>
      <div className={styles.line}>
        Once these questions have been answered, the agency cannot be reverted to a prospect. Are you sure you want to do this?
      </div>
    </ConfirmationModal>
  );
}

ConvertToAgencyConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
