import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./CommentsSection.module.scss";
import Comment from "../Comment";
import Button from "../Button";
import { FilterDropdown } from "../";

export default class CommentsSection extends Component {

  static propTypes = {
    title: PropTypes.string,
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
        created_at: PropTypes.number.isRequired,
        created_by: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
        type: PropTypes.string,
      })
    ),
    filterOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        group: PropTypes.string,
      }),
    ),
    filterPlaceholder: PropTypes.string.isRequired.isRequired,
    filterClassName: PropTypes.string.isRequired.isRequired,
    commentRefs: PropTypes.objectOf(
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ),
    commentsEnabled: PropTypes.bool.isRequired,
    onLeaveComment: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    onFilterClear: PropTypes.func.isRequired,
  }

  static defaultProps = {
    filterOptions: [],
  }

  state = {
    selectedValues: [],
  }

  parseName(name) {
    return name.split("_").pop();
  }

  renderOptionGroupHeader(option, index) {
    const { filterOptions } = this.props;

    if (index > 0 && option.group === filterOptions[index-1].group) return null;

    return (
      <>
        <hr className={styles.separator} />
        <div className={styles.groupHeader}>
          {option.group}
        </div>
      </>
    );
  }

  render() {
    const { title, comments, commentRefs, filterOptions, filterPlaceholder, filterClassName, commentsEnabled } = this.props;
    const { selectedValues } = this.state;

    return (
      <div>
        <div className={styles.flexHeader}>
          <h2 className={styles.header}>{title || "Comments"}</h2>
          <div className={styles.flexContainer}>
            {filterOptions.length > 0 && (
              <FilterDropdown
                placeholder={filterPlaceholder}
                className={filterClassName}
                selectedValues={selectedValues}
                onChange={this.handleFilterChange}
                onFilterClear={this.handleFilterClear}
              >
                {filterOptions.map((option, i) => (
                  <>
                    {this.renderOptionGroupHeader(option, i)}

                    <FilterDropdown.Item key={option.key} value={option.value}>
                      {this.parseName(option.label)}
                    </FilterDropdown.Item>
                  </>
                ))}
              </FilterDropdown>)
            }
            {commentsEnabled && (
              <Button
                variant="outline-primary"
                onClick={this.handleCommentClicked}
              >
                Leave Comment
              </Button>
            )}
          </div>
        </div>
        {(comments || []).map(comment =>
          <Comment
            key={comment.id}
            className={styles.comment}
            author={`${comment.created_by.first_name} ${comment.created_by.last_name}`}
            time={comment.created_at}
            text={comment.comment}
            type={comment.type}
            tags={comment.tags}
            commentRef={commentRefs && commentRefs[comment.id]}
          />
        )}
      </div>
    );
  }

  handleCommentClicked = () => {
    const { onLeaveComment } = this.props;

    onLeaveComment && onLeaveComment();
  }

  handleFilterChange = ({ values }) => {
    const { onFilter } = this.props;

    this.setState({
      selectedValues: values,
    });

    onFilter && onFilter(values);
  }

  handleFilterClear = () => {
    const { onFilterClear } = this.props;

    this.setState({
      selectedValues: [],
    });

    onFilterClear && onFilterClear();
  }
}
