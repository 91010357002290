import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import { actions as documentActions } from "../../../documents/duck";
import ProspectDocumentVersionHistoryModal from "./ProspectDocumentVersionHistoryModal";

const mapStateToProps = state => ({
  documentTypes: state.documents.documentTypes,
  document: state.documents.prospectDocument.data,
  loading: state.documents.prospectDocument.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
  ...documentActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(ProspectDocumentVersionHistoryModal);

container.displayName = "ProspectDocumentVersionHistoryModal";

export default container;
