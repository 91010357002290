import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { bytesToGigabytes } from "../../../../utils";
import { getProspectDocuments } from ".";

const UPLOAD_PROSPECT_DOCUMENT = gql`
query($input: ProspectDocumentUploadUrlInput!) {
  getProspectDocumentUploadUrl(input: $input) {
    bucket
    key
    url
  }
}
`;

export const uploadProspectDocuments = ({
  prospectId,
  files,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPLOAD_PROSPECT_DOCUMENTS_PENDING,
    });

    const promises = files.map(async (file) => {
      const fileSize = bytesToGigabytes(file.size);

      if (fileSize > 5) {
        throw new Error("File size was too large. Maximum file size is 5GB.");
      }

      const response = await apolloClient.query({
        query: UPLOAD_PROSPECT_DOCUMENT,
        variables: {
          input: {
            prospect_id: prospectId,
            document_type: file.documentType.type,
            filename: file.name,
          },
        },
      });

      const uploadUrl = response.data.getProspectDocumentUploadUrl;

      await fetch(uploadUrl.url, {
        method: "PUT",
        body: file,
      });
    });

    await Promise.all(promises);

    dispatch(getProspectDocuments({ prospectId }));

    dispatch({
      type: types.UPLOAD_PROSPECT_DOCUMENTS_SUCCESS,
    });

    return document.name;
  }
  catch (err) {
    dispatch({
      type: types.UPLOAD_PROSPECT_DOCUMENTS_FAILURE,
      payload: {
        error: err,
        fileSizeError: err.message.startsWith("File size was too large"),
      },
    });
  }
};
