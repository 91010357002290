import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_ONBOARDING_TASK = gql`
query($id: ID!) {
  getOnboardingTask(id: $id) {
    id
    name
    completed_agencies {
      status
      agency {
        short_code
        name
      }
    }
    outstanding_agencies {
      id
      status
      agency {
        id
        short_code
        name
      }
      progress {
        required_completed
        required_total
      }
      due_date
    }
  }
}
`;

export const getOnboardingTask = ({
  onboardingTaskId: taskId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_ONBOARDING_TASK_PENDING,
      payload: {
        taskId,
      },
    });

    const response = await apolloClient.query({
      query: GET_ONBOARDING_TASK,
      variables: {
        id: taskId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_ONBOARDING_TASK_SUCCESS,
      payload: {
        onboardingTask: response.data.getOnboardingTask,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.GET_ONBOARDING_TASK_FAILURE,
      payload: {
        error,
      },
    });
  }
};
