import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import "./EditButton.scss";

export default class EditButton extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    const { className, onClick } = this.props;

    return (
      <div className={classnames("EditButton", className)} onClick={onClick}>
        <FontAwesomeIcon icon={faPen} />
        <span>Edit</span>
      </div>
    );
  }

}
