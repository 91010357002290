import _ from "lodash";

export default class CompleteSignUpFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    const errors = {};

    if (_.isEmpty(data?.password)) {
      errors.password = "Password cannot be empty";
    }
    else if (data?.password.length < 8) {
      errors.password = "Your password must be at least 8 characters";
    }
    else if (!/[a-z]/.test(data?.password)) {
      errors.password = "Your password must include at least one lowercase character";
    }
    else if (!/[A-Z]/.test(data?.password)) {
      errors.password = "Your password must include at least one uppercase character";
    }
    else if (!/[^A-Za-z0-9]/.test(data?.password)) {
      errors.password = "Your password must include at least one special character";
    }
    else if (data?.confirmPassword !== data?.password) {
      errors.confirmPassword = "Password does not match";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
