import React from "react";
import PropTypes from "prop-types";
import { StatusLabel } from "../";
import ImportTypeLabel from "./ImportTypeLabel/ImportTypeLabel";
import { Table2, TextLink } from "../../../common/components";
import { parseDateTime } from "../../../../utils";
import styles from "./DataImportList.module.scss";

export default function DataImportList(props) {
  return (
    <Table2
      className={styles.container}
      loading={props.loading}
      data={props.data}
    >
      <Table2.Column
        name="import_id"
        value={row => row.import_id}
        collapsed
      >
        Export ID
      </Table2.Column>
      <Table2.Column
        name="import_type"
        value={row => (
          <ImportTypeLabel importType={row.import_type} />
        )}
        collapsed
      >
        Transfer Type
      </Table2.Column>
      <Table2.Column
        name="status"
        value={row => (
          <StatusLabel status={row.status} />
        )}
        collapsed
      >
        Status
      </Table2.Column>
      <Table2.Column
        name="created_at"
        value={row => parseDateTime(row.created_at)}
        collapsed
      >
        Created At
      </Table2.Column>
      <Table2.Column
        name="created_by"
        value={row => `${row.created_by.first_name} ${row.created_by.last_name}`}
      >
        Created By
      </Table2.Column>
      <Table2.Column
        name="error"
        value={row => (
          <span className={styles.error}>{row.error}</span>
        )}
      >
        Error
      </Table2.Column>
      <Table2.Column
        name="url"
        value={row => row.url && (
          <TextLink
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={row.url}
            text="Download"
            hideExternalLinkIcon
          />
        )}
        collapsed
      />
    </Table2>
  );
}

DataImportList.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    import_id: PropTypes.string.isRequired,
    import_type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    created_at: PropTypes.number.isRequired,
    created_by: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
    error: PropTypes.string,
    url: PropTypes.string,
  })),
};

DataImportList.defaultProps = {
  data: [],
};
