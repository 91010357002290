import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import VerifyNonRegisteredAgencyCustomerModal from "./VerifyNonRegisteredAgencyCustomerModal";

const mapStateToProps = state => ({
  submitting: state.customers.ui.submitting,
  loading: state.customers.agencyCustomer.loading,
  agencyCustomer: state.customers.agencyCustomer.data ?? {},
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(VerifyNonRegisteredAgencyCustomerModal);

container.displayName = VerifyNonRegisteredAgencyCustomerModal.displayName;

export default container;
