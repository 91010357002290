import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_BRANCH = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectBranchInput!
) {
  updateProspectBranch(prospect_id: $prospect_id, input: $input) {
    address_line_one
    address_line_two
    town_or_city
    county
    postcode
    branch {
      value
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {number} obj.branchId
 * @param {object} obj.data
 * @param {string} obj.data.address_line_one
 * @param {string} obj.data.address_line_two
 * @param {string} obj.data.town_or_city
 * @param {string} obj.data.county
 * @param {string} obj.data.postcode
 * @param {string} obj.redirectUrl
 *
 */
export const updateBranch = ({ prospectId, branchId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_BRANCH_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_BRANCH,
      variables: {
        prospect_id: prospectId,
        input: {
          id: branchId,
          address_line_one: data.address_line_one,
          address_line_two: data.address_line_two,
          town_or_city: data.town_or_city,
          county: data.county,
          postcode: data.postcode,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_BRANCH_SUCCESS,
      payload: {
        branch: response.data.updateBranch,
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_BRANCH_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
