import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import CompaniesHouseSearchResultItem from "./CompaniesHouseSearchResultItem";
import styles from "./CompaniesHouseSearchResults.module.scss";

export default function CompaniesHouseSearchResults(props) {
  const getExisting = (value) => {
    const existingAgency = props.existing_agencies.find((agency) => {
      return agency.company_number === value.company_number;
    });

    if (existingAgency) {
      return { ...existingAgency, type: "agency" };
    }

    const existingProspect = props.existing_prospects.find((prospect) => {
      return prospect.company_number === value.company_number;
    });

    if (existingProspect) {
      return { ...existingProspect, type: "prospect" };
    }

    return null;
  };

  return (
    <div className={classnames(styles.container, props.className)}>
      <div className={styles.header}>
        Results matching {`'${props.searchText}'`}
        <span className={styles.total}>- {props.total} result{props.total === 1 ? "" : "s"} found</span>
      </div>
      {props.data.map((value) => {
        const existing = getExisting(value);

        return (
          <CompaniesHouseSearchResultItem
            className={styles.item}
            key={value.company_number}
            name={value.name}
            companyNumber={value.company_number}
            companyStatus={value.company_status}
            incorporatedDate={value.incorporated_date}
            addressSnippet={value.address_snippet}
            existing={existing}
            onClick={() => props.onItemClick({
              name: value.name,
              companyNumber: value.company_number,
              companyStatus: value.company_status,
            }, existing)}
          />
        );
      })}
    </div>
  );
}

CompaniesHouseSearchResults.propTypes = {
  className: PropTypes.string,
  searchText: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
    company_status: PropTypes.string,
    incorporated_date: PropTypes.number,
    address_snippet: PropTypes.string,
  })).isRequired,
  existing_agencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
  })),
  existing_prospects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
  })),
  onItemClick: PropTypes.func.isRequired,
};

CompaniesHouseSearchResults.defaultProps = {
  searchText: "",
  total: 0,
  data: [],
  existing_agencies: [],
  existing_prospects: [],
};
