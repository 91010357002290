/**
 * Detect which browser we're in.
 * Returns "chrome" if we're not sure.
 *
 * @returns "chrome", "firefox", or "safari".
 */
const detectBrowser = (_navigator = navigator) => {
  if (_navigator.userAgent?.toLowerCase()?.includes("chrome")) {
    return "chrome";
  }
  if (_navigator.userAgent?.toLowerCase()?.includes("firefox")) {
    return "firefox";
  }
  if (_navigator.userAgent?.toLowerCase()?.includes("safari")) {
    return "safari";
  }
  // If unsure, return "chrome". webkit is the most common
  // and Back Office prioritise Chrome.
  return "chrome";
};

export default detectBrowser;
