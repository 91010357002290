import _ from "lodash";

export default class WtrHolidayFormValidator {
  constructor(data) {
    this.data = data;
  }

  validate() {
    const { data } = this;
    let errors = {};

    if (!data?.default_holiday_scheme) {
      errors = _.set(errors, "default_holiday_scheme", "Default Holiday Scheme is required");
    }

    if (!data?.holiday_accrual_period) {
      errors = _.set(errors, "holiday_accrual_period", "Holiday Accrual Period is required");
    }

    if (data?.holiday_accrual_period?.value === "holiday_year") {
      if (!data?.end_of_holiday_year_pay_period || !data?.end_of_holiday_year_tax_year_end) {
        errors = _.set(errors, "end_of_holiday_year", "Please enter a valid pay period for the holiday year");
      }
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
