import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Checkbox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function Checkbox({
  name,
  style,
  className,
  containerClassName,
  text,
  checked,
  disabled,
  onClick,
}) {

  const handleClick = (e) => {
    e.stopPropagation();

    onClick && !disabled && onClick(name);
  };

  return (
    <div className={classnames(styles.flexContainer, containerClassName)}>
      <div
        className={classnames(styles.checkbox, className, disabled && styles.disabled)}
        style={style}
        onClick={handleClick}
      >
        {checked && <FontAwesomeIcon className={styles.icon} icon={faCheck} />}
      </div>
      {text && (
        <div
          className={classnames(styles.text, disabled && styles.disabled)}
          onClick={handleClick}
        >
          {text}
        </div>
      )}
    </div>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};
