import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_COMPANY_DETAILS = gql`
query($id: ID!) {
  getLegalEntity(id: $id) {
    id
    is_registered_company
    __typename

    ...on RegisteredCompany {
      companies_house_info {
        accounts {
          last_accounts {
            made_up_to
          }
        }
        company_name
        company_number
        company_status
        company_status_detail
        type
      }
      registered_charity_number
    }

    ...on RegisteredCharity {
      companies_house_info {
        accounts {
          last_accounts {
            made_up_to
          }
        }
        company_name
        company_number
        company_status
        company_status_detail
        type
      }
      registered_charity_number
    }

    ...on NonRegisteredCompany {
      name
      telephone
      email_address
      website
      company_address {
        address_line_one
        address_line_two
        town_or_city
        county
        postcode
      }
    }

    ...on Charity {
      name
      telephone
      email_address
      website
      registered_charity_number
      company_address {
        address_line_one
        address_line_two
        town_or_city
        county
        postcode
      }
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getCompanyDetails = ({ legalEntityId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_COMPANY_DETAILS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_COMPANY_DETAILS,
      variables: {
        id: legalEntityId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_COMPANY_DETAILS_SUCCESS,
      payload: {
        data: response.data.getLegalEntity,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_COMPANY_DETAILS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
