import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../duck";
import ResetPasswordPage from "./ResetPasswordPage";

const mapDispatchToProps = dispatch => bindActionCreators({
  onSendCode: actions.sendResetPasswordCode,
  onVerifyCode: actions.verifyResetPasswordCode,
}, dispatch);

const mapStateToProps = state => ({
  resetPasswordState: state.signIn.resetPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
