import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { Form, Alert, Button, Table2 } from "../../../common/components";
import { capitalize } from "../../../../utils";
import { isAlphaNumeric } from "../../../../utils/string";
import styles from "./ExistingShortCodesPage.module.scss";

export default class ExistingShortCodesPage extends Component {

  static propTypes = {
    newAgency: PropTypes.shape({
      loading: PropTypes.bool,
      existingShortCodes: PropTypes.arrayOf(PropTypes.shape({
        short_code: PropTypes.string.isRequired,
        known_as: PropTypes.string.isRequired,
      })),
      shortCodeExists: PropTypes.bool,
      error: PropTypes.object,
    }),
    returnUrl: PropTypes.string.isRequired,
    data: PropTypes.shape({
      shortCode: PropTypes.string,
    }),
    onFetchExistingAgencyShortCodes: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    onUseShortCode: PropTypes.func.isRequired,
  }

  state = {
    firstCharacter: "",
    secondCharacter: "",
  }

  componentDidMount() {
    const { data, onFetchExistingAgencyShortCodes } = this.props;

    const state = data.shortCode
      ? { firstCharacter: data.shortCode[0] || "", secondCharacter: data.shortCode[1] || "" }
      : { firstCharacter: "", secondCharacter: "" };

    this.setState(state, onFetchExistingAgencyShortCodes);
  }

  render() {
    const { firstCharacter, secondCharacter } = this.state;
    const { loading } = this.props.newAgency;

    const shortCode = firstCharacter + secondCharacter;

    if (loading) {
      return <ActivityIndicator />;
    }

    const data = this.getFilteredData();
    const codeAvailable = shortCode.length === 2 && data.length === 0;

    return (
      <div>
        <div>
          <p className={styles.title}>Existing Short Codes</p>
          <hr/>
        </div>
        <Form.Label>Short Code</Form.Label>
        <div className={styles.flexContainer}>
          <div className={styles.flexInputs}>
            <Form.Input
              name="firstCharacter"
              className={styles.singleCharacterInput}
              value={_.toUpper(firstCharacter)}
              onChange={this.handleInputChange}
              maxLength="1"
            />
            <Form.Input
              name="secondCharacter"
              className={styles.singleCharacterInput}
              value={_.toUpper(secondCharacter)}
              onChange={this.handleInputChange}
              maxLength="1"
            />
            {codeAvailable && <Alert
              className={styles.alert}
              text={
                <div className={styles.alertBody}>
                  <span className={styles.alertHeader}>Success</span>
                  <span className={styles.alertMessage}>This short code is available</span>
                </div>
              }
              variant="success"
              showClose={false}
            />}
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} disabled={!codeAvailable} variant="primary" onClick={this.handleUseShortCode}>Use Short Code</Button>
            <Button variant="secondary" onClick={this.handleGoBack}>Go Back To Onboarding</Button>
          </div>
        </div>
        <Table2
          className={styles.shortCodeTable}
          data={data}>
          <Table2.Column
            value={row => row.short_code}
          >
            Short Code
          </Table2.Column>
          <Table2.Column
            value={row => row.known_as}
          >
            Agency
          </Table2.Column>
        </Table2>
      </div>
    );
  }

  handleInputChange = (e) => {
    if (!isAlphaNumeric(e.target.value)) {
      return;
    }

    this.setState({
      [e.target.name]: capitalize(e.target.value),
    });
  }

  handleUseShortCode = async () => {
    const { returnUrl, data, onUseShortCode } = this.props;
    const { firstCharacter, secondCharacter } = this.state;

    const newShortCode = firstCharacter + secondCharacter;

    await onUseShortCode(returnUrl, {
      ...data,
      shortCode: newShortCode || data.shortCode,
    });
  }

  handleGoBack = async () => {
    const { returnUrl, data, onGoBack } = this.props;

    await onGoBack(returnUrl, data);
  }

  getFilteredData = () => {
    const { firstCharacter, secondCharacter } = this.state;
    const { newAgency } = this.props;

    return newAgency.existingShortCodes.filter((row) => {
      if (firstCharacter) {
        if (row.short_code[0] !== firstCharacter) {
          return false;
        }
      }
      if (secondCharacter !== "") {
        if (row.short_code[1] !== secondCharacter) {
          return false;
        }
      }
      return true;
    });
  }
}
