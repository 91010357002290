import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import styles from "./TextBlock.module.scss";

export default function TextBlock({
  className,
  children,
  content,
  variant,
}) {
  if (_.isEmpty(content) && _.isEmpty(children)) {
    return null;
  }

  return (
    <div className={classnames(
      styles.container,
      styles[variant],
      className
    )}>
      {content && (_.castArray(content)).map((item, index) => (
        <div
          key={index}
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))}
      {children}
    </div>
  );
}

TextBlock.defaultProps = {
  variant: "primary",
};

TextBlock.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "info",
    "danger",
    "warning",
  ]),
};
