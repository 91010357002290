export const GET_AGENCY_CUSTOMERS_PENDING = "CUSTOMERS/GET_AGENCY_CUSTOMERS_PENDING";
export const GET_AGENCY_CUSTOMERS_SUCCESS = "CUSTOMERS/GET_AGENCY_CUSTOMERS_SUCCESS";
export const GET_AGENCY_CUSTOMERS_FAILURE = "CUSTOMERS/GET_AGENCY_CUSTOMERS_FAILURE";

export const ADD_AGENCY_CUSTOMER_PENDING = "CUSTOMERS/ADD_AGENCY_CUSTOMER_PENDING";
export const ADD_AGENCY_CUSTOMER_SUCCESS = "CUSTOMERS/ADD_AGENCY_CUSTOMER_SUCCESS";
export const ADD_AGENCY_CUSTOMER_FAILURE = "CUSTOMERS/ADD_AGENCY_CUSTOMER_FAILURE";

export const GET_AGENCY_CUSTOMER_PENDING = "CUSTOMERS/GET_AGENCY_CUSTOMER_PENDING";
export const GET_AGENCY_CUSTOMER_SUCCESS = "CUSTOMERS/GET_AGENCY_CUSTOMER_SUCCESS";
export const GET_AGENCY_CUSTOMER_FAILURE = "CUSTOMERS/GET_AGENCY_CUSTOMER_FAILURE";

export const UPDATE_AGENCY_CUSTOMER_PENDING = "CUSTOMERS/UPDATE_AGENCY_CUSTOMER_PENDING";
export const UPDATE_AGENCY_CUSTOMER_SUCCESS = "CUSTOMERS/UPDATE_AGENCY_CUSTOMER_SUCCESS";
export const UPDATE_AGENCY_CUSTOMER_FAILURE = "CUSTOMERS/UPDATE_AGENCY_CUSTOMER_FAILURE";

export const VERIFY_AGENCY_CUSTOMER_PENDING = "CUSTOMERS/VERIFY_AGENCY_CUSTOMER_PENDING";
export const VERIFY_AGENCY_CUSTOMER_SUCCESS = "CUSTOMERS/VERIFY_AGENCY_CUSTOMER_SUCCESS";
export const VERIFY_AGENCY_CUSTOMER_FAILURE = "CUSTOMERS/VERIFY_AGENCY_CUSTOMER_FAILURE";

export const AGENCY_PROSPECT_SEARCH_PENDING = "CUSTOMERS/AGENCY_PROSPECT_SEARCH_PENDING";
export const AGENCY_PROSPECT_SEARCH_SUCCESS = "CUSTOMERS/AGENCY_PROSPECT_SEARCH_SUCCESS";
export const AGENCY_PROSPECT_SEARCH_FAILURE = "CUSTOMERS/AGENCY_PROSPECT_SEARCH_FAILURE";
export const AGENCY_PROSPECT_SEARCH_CLEAR = "CUSTOMERS/AGENCY_PROSPECT_SEARCH_CLEAR";

export const ADD_CUSTOMER_CONTRACT_PENDING = "CUSTOMERS/ADD_CUSTOMER_CONTRACT_PENDING";
export const ADD_CUSTOMER_CONTRACT_SUCCESS = "CUSTOMERS/ADD_CUSTOMER_CONTRACT_SUCCESS";
export const ADD_CUSTOMER_CONTRACT_FAILURE = "CUSTOMERS/ADD_CUSTOMER_CONTRACT_FAILURE";

export const GET_CONTRACT_PENDING = "CUSTOMERS/GET_CONTRACT_PENDING";
export const GET_CONTRACT_SUCCESS = "CUSTOMERS/GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILURE = "CUSTOMERS/GET_CONTRACT_FAILURE";

export const UPDATE_CUSTOMER_ACCOUNT_PENDING = "CUSTOMERS/UPDATE_CUSTOMER_ACCOUNT_PENDING";
export const UPDATE_CUSTOMER_ACCOUNT_SUCCESS = "CUSTOMERS/UPDATE_CUSTOMER_ACCOUNT_SUCCESS";
export const UPDATE_CUSTOMER_ACCOUNT_FAILURE = "CUSTOMERS/UPDATE_CUSTOMER_ACCOUNT_FAILURE";

export const ADD_CUSTOMER_ACCOUNT_REFERENCE_PENDING = "CUSTOMERS/ADD_CUSTOMER_ACCOUNT_REFERENCE_PENDING";
export const ADD_CUSTOMER_ACCOUNT_REFERENCE_SUCCESS = "CUSTOMERS/ADD_CUSTOMER_ACCOUNT_REFERENCE_SUCCESS";
export const ADD_CUSTOMER_ACCOUNT_REFERENCE_FAILURE = "CUSTOMERS/ADD_CUSTOMER_ACCOUNT_REFERENCE_FAILURE";

export const SEARCH_COMPANIES_HOUSE_PENDING = "CUSTOMERS/SEARCH_COMPANIES_HOUSE_PENDING";
export const SEARCH_COMPANIES_HOUSE_SUCCESS = "CUSTOMERS/SEARCH_COMPANIES_HOUSE_SUCCESS";
export const SEARCH_COMPANIES_HOUSE_FAILURE = "CUSTOMERS/SEARCH_COMPANIES_HOUSE_FAILURE";

export const GET_COMPANIES_HOUSE_COMPANY_PROFILE_PENDING = "CUSTOMERS/GET_COMPANIES_HOUSE_COMPANY_PROFILE_PENDING";
export const GET_COMPANIES_HOUSE_COMPANY_PROFILE_SUCCESS = "CUSTOMERS/GET_COMPANIES_HOUSE_COMPANY_PROFILE_SUCCESS";
export const GET_COMPANIES_HOUSE_COMPANY_PROFILE_FAILURE = "CUSTOMERS/GET_COMPANIES_HOUSE_COMPANY_PROFILE_FAILURE";
