import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { Squares as ActivityIndicator } from "react-activity";
import { SortIndicator, TextLink } from "../../../common/components";
import styles from "./AgencyCustomersList.module.scss";
import { CUSTOMER_DETAILS_CUSTOMER_TYPES } from "constants/options";

export default function AgencyCustomersList(props) {
  const handleColumnClicked = (name) => {
    props.onColumnClick(name, props.sortOrder[name] || "NONE");
  };

  if (props.loading) {
    return <ActivityIndicator />;
  }

  return (
    <Table className={styles.table} basic>
      <Table.Header>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("display_name")}
        >
          <div>
            Customer Name
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.display_name}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("agency_name")}
        >
          <div>
            Agency Name
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.agency_name}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("company_type")}
        >
          <div>
            Customer Type
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.company_type}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell
          className={styles.sortableHeader}
          onClick={() => handleColumnClicked("number_of_contracts")}
        >
          <div>
            No. of Contracts
            <SortIndicator
              className={styles.sortIndicator}
              sortOrder={props.sortOrder.number_of_contracts}
            />
          </div>
        </Table.HeaderCell>
      </Table.Header>

      <Table.Body>
        {(props.customers ?? []).length === 0 ? (
          <Table.Row>
            <Table.Cell colSpan="4" className={styles.noResults}>
              There are no Customers available to view.
            </Table.Cell>
          </Table.Row>
        ) : (
          props.customers.map(customer => (
            <Table.Row key={customer.id}>
              <Table.Cell>
                <TextLink
                  className={styles.customerName}
                  text={customer.display_name}
                  to={`/customers/${customer.id}`}
                />
              </Table.Cell>
              <Table.Cell>
                {customer.agency?.known_as ?? (
                  <>
                    {customer.prospect.known_as} <strong>(Prospect)</strong>
                  </>
                )}
              </Table.Cell>
              <Table.Cell>
                {CUSTOMER_DETAILS_CUSTOMER_TYPES.find(x => x.value === customer.company_type).label || "-"}
              </Table.Cell>
              <Table.Cell>
                {customer.number_of_contracts}
              </Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  );
}

AgencyCustomersList.propTypes = {
  customers: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    company_type: PropTypes.string.isRequired,
    agency: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }).isRequired,
    prospect: PropTypes.shape({
      known_as: PropTypes.string.isRequired,
    }).isRequired,
    number_of_contracts: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  sortOrder: PropTypes.shape({
    display_name: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    agency_name: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    company_type: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
    number_of_contracts: PropTypes.oneOf(["NONE", "ASC", "DESC"]),
  }).isRequired,
  onColumnClick: PropTypes.func.isRequired,
};
