import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import EditProspectPage from "./EditProspectPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.prospects,
  prospectId: ownProps.match.params.prospectId,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  onFetchProspect: actions.getProspect,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditProspectPage);
