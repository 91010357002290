import _ from "lodash";

export const isSubtaskDependencyValid = (dependency, state = {}) => {
  if (_.isNil(dependency.when)) {
    return !!state[dependency.name];
  }

  if (_.has(state, dependency.name)) {
    const arr = _.castArray(state[dependency.name]);

    if (arr.includes(dependency.when)) {
      return true;
    }

    return arr.length > 0 && arr.filter((d) => {
      if (!d) return false;

      return dependency.property
        ? String(dependency.when) === String(d[dependency.property])
        : String(dependency.when) === String(d);
    }).length > 0;
  }

  return false;
};
