import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../duck";
import { actions as permissionActions } from "../../permissions/duck";
import { Breadcrumb, Form, Heading, Input, PermissionList } from "../../common/components";
import "./NewUserGroupPage.scss";

export class NewUserGroupPage extends Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })).isRequired,
    getPermissions: PropTypes.func.isRequired,
    addUserGroup: PropTypes.func.isRequired,
    gotoUserGroups: PropTypes.func.isRequired,
  }

  state = {
    name: "",
    description: "",
    selectedPermissions: [],
  }

  async componentWillMount() {
    await this.props.getPermissions();
  }

  render() {
    const { saving, permissions } = this.props;
    const { name, description, selectedPermissions } = this.state;

    return (
      <div className="NewUserGroupPage">
        <Breadcrumb className="header-breadcrumb">
          <Breadcrumb.Item link="/manage" text="Group Management" />
          <Breadcrumb.Item link="/manage/groups" text="Groups" />
          <Breadcrumb.Item link="/manage/groups/new" text="New Group" />
        </Breadcrumb>
        <Heading className="heading" title="Create New Group" />
        <Form className="content" onSubmit={this.handleSubmit} onCancel={this.handleCancel} submitting={saving}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Value>
              <Input required type="text" name="name" value={name} onChange={this.handleTextChanged} />
            </Form.Value>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Value>
              <Input required type="text" name="description" value={description} onChange={this.handleTextChanged} />
            </Form.Value>
          </Form.Group>
          <Form.Group>
            <PermissionList
              availablePermissions={permissions}
              selectedPermissions={selectedPermissions}
              onSelectionChanged={this.handlePermissionSelectionChanged}
            />
          </Form.Group>
        </Form>
      </div>
    );
  }

  handleTextChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handlePermissionSelectionChanged = (permission) => {
    const { selectedPermissions } = this.state;
    const existing = selectedPermissions.find(p => p === permission.id);
    const newSelectedPermissions = existing
      ? selectedPermissions.filter(p => p !== permission.id)
      : [...selectedPermissions, permission.id];

    this.setState({
      selectedPermissions: newSelectedPermissions,
    });
  }

  handleSubmit = async () => {
    const { name, description, selectedPermissions } = this.state;

    await this.props.addUserGroup({
      name,
      description,
      permissions: selectedPermissions,
    });
  }

  handleCancel = () => {
    this.props.gotoUserGroups();
  }

}

const mapStateToProps = state => ({
  ...state.groupManagement,
  ...state.permissions,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...permissionActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewUserGroupPage);
