import React from "react";
import PlaceholderCard from "./PlaceholderCard/PlaceholderCard";
import styles from "./PlaceholderTab.module.scss";

export default function PlaceholderTab() {
  return (
    <div className={styles.container}>
      <PlaceholderCard title="Agency Details" />
      <PlaceholderCard title="Companies House Information" />
      <div className={styles.row}>
        <PlaceholderCard title="Contract Details" />
        <PlaceholderCard title="Bank Information" />
      </div>
    </div>
  );
}
