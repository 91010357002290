import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, CompaniesHouseSearchResultsModal, Form } from "../../../../common/components";
import styles from "./CompaniesHouseDetailsForm.module.scss";

export default function CompaniesHouseDetailsForm(props) {
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({
    registeredCompanyNumber: props.registeredCompanyNumber || "",
    exactLegalName: props.exactLegalName || "",
    page: props.page || 1,
    limit: props.limit || 5,
  });

  const handleItemClick = (item, existing) => {
    props.onCompanySelected({ company: item, existing }, { ...state });
  };

  return (
    <>
      <div className={classnames(props.className, styles.container)}>
        <Form>
          <Form.Section title="Companies House Details">
            <Form.Group inline>
              <Form.Label>Registered Company Number</Form.Label>
              <Form.Input
                name="registeredCompanyNumber"
                value={state.registeredCompanyNumber}
                size="small"
                onChange={e => setState({ ...state, registeredCompanyNumber: e.target.value })}
              />
            </Form.Group>
            <Form.Group inline>
              <Form.Label>Exact Legal Name</Form.Label>
              <Form.Input
                name="exactLegalName"
                value={state.exactLegalName}
                onChange={e => setState({ ...state, exactLegalName: e.target.value })}
              />
            </Form.Group>
            <Button
              className={styles.searchButton}
              variant="primary"
              disabled={!state.registeredCompanyNumber || !state.exactLegalName}
              onClick={() => {
                setShowModal(true);
                props.onSearchCompaniesHouseClick(state);
              }}
            >
              Search Companies House
            </Button>
          </Form.Section>
        </Form>
        <div className={styles.buttons}>
          <Button
            variant="primary"
            className={styles.button}
            onClick={() => props.onSaveAsDraftClick(state)}
            // disabled={!state.exactLegalName && !state.registeredCompanyNumber}
            disabled
          >
            Save As Draft
          </Button>
          <Button
            variant="outline-danger"
            className={styles.button}
            onClick={() => props.onCancelClick()}
          >
            Cancel
          </Button>
        </div>
      </div>
      <CompaniesHouseSearchResultsModal
        visible={showModal}
        loading={props.loading}
        registeredCompanyNumber={state.registeredCompanyNumber}
        exactLegalName={state.exactLegalName}
        page={state.page}
        limit={state.limit}
        results={props.companiesHouseResults}
        onCancel={() => {
          setShowModal(false);
          setState({ ...state, page: 1 });
        }}
        onItemClick={handleItemClick}
        onPageChanged={(n) => {
          const newState = { ...state, page: n };

          setState(newState);
          props.onSearchCompaniesHouseClick(newState);
        }}
      />
    </>
  );
}

CompaniesHouseDetailsForm.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  registeredCompanyNumber: PropTypes.string,
  exactLegalName: PropTypes.string,
  page: PropTypes.number,
  limit: PropTypes.number,
  companiesHouseResults: PropTypes.array,
  onCancelClick: PropTypes.func.isRequired,
  onCompanySelected: PropTypes.func.isRequired,
  onSaveAsDraftClick: PropTypes.func.isRequired,
  onSearchCompaniesHouseClick: PropTypes.func.isRequired,
};
