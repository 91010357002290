import _ from "lodash";

export const subtaskHasValue = (type, subtaskKey, state) => {
  switch (type) {
    case "definelist":
    case "list":
      return _.castArray(state[subtaskKey]).some(v => v !== "");

    case "contact_list":
      return _.castArray(state[subtaskKey]).length > 0;

    case "checkbox":
      return state[subtaskKey];

    case "date_period":
      return state[subtaskKey]?.period && state[subtaskKey]?.value;

    case "tax_period":
      return state[subtaskKey]?.week && state[subtaskKey]?.year;

    case "date":
      return state[subtaskKey] > 0;

      // The following currently use the default: radiolist, document, number, select,
      // checklist, alert, and text
    default:
      if (_.isNil(state[subtaskKey])) {
        return false;
      }

      if (state[subtaskKey] === "") {
        return false;
      }

      if (Array.isArray(state[subtaskKey]) && state[subtaskKey].length <= 0) {
        return false;
      }

      return true;
  }
};
