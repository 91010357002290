import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Table } from "semantic-ui-react";
import StatusLabel from "./StatusLabel/StatusLabel";
import TransferTypeLabel from "./TransferTypeLabel/TransferTypeLabel";
import { parseDateTime } from "../../../../utils";
import styles from "./TransfersList.module.scss";

export default function TransfersList(props) {
  const [expanded, setExpanded] = useState([]);

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell collapsing>Transfer Type</Table.HeaderCell>
          <Table.HeaderCell collapsing>Status</Table.HeaderCell>
          <Table.HeaderCell collapsing>Created At</Table.HeaderCell>
          <Table.HeaderCell>Error</Table.HeaderCell>
          <Table.HeaderCell collapsing></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.data?.map(row => (
          <>
            <Table.Row>
              <Table.Cell collapsing>
                <TransferTypeLabel transferType={row.transfer_type} />
              </Table.Cell>
              <Table.Cell collapsing>
                <StatusLabel status={row.status} />
              </Table.Cell>
              <Table.Cell collapsing>
                {parseDateTime(row.created_at)}
              </Table.Cell>
              <Table.Cell className={styles.error}>
                {_.truncate(row.error, { length: 255 })}
              </Table.Cell>
              <Table.Cell collapsing>
                {expanded.includes(row.transfer_id) ? (
                  <Button onClick={() => setExpanded(expanded.filter(x => x !== row.transfer_id))}>
                    Hide Additional Info
                  </Button>
                ) : (
                  <Button onClick={() => setExpanded([...expanded, row.transfer_id])}>
                    Show Additional Info
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
            {expanded.includes(row.transfer_id) && (
              <Table.Row>
                <Table.Cell colspan={5}>
                  <Table very basic>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={2}>Transfer ID</Table.Cell>
                        <Table.Cell>{row.transfer_id}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Created By</Table.Cell>
                        <Table.Cell>{`${row.created_by.first_name} ${row.created_by.last_name}`}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Error</Table.Cell>
                        <Table.Cell className={styles.error}>{row.error}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Extended Error</Table.Cell>
                        <Table.Cell className={styles.error}>{row.extended_error}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            )}
          </>
        ))}
      </Table.Body>
    </Table>
  );
}

TransfersList.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    transfer_id: PropTypes.string.isRequired,
    transfer_type: PropTypes.string.isRequired,
    status: PropTypes.oneOf(["pending", "complete", "failed"]).isRequired,
    created_by: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
    created_at: PropTypes.number.isRequired,
    error: PropTypes.string,
  })),
};
