import _ from "lodash";

/**
 * Returns A String In Title Case
 *
 * @param  {string} value
 */
const formatCorrespondenceName = (value) => {
  if (!value) {
    return;
  }

  return _.startCase(_.toLower(value));
};

export default formatCorrespondenceName;
