import React from "react";
import PropTypes from "prop-types";
import PartnerListItem from "./PartnerListItem/PartnerListItem";
import { Accordion } from "semantic-ui-react";
import { Add } from "@material-ui/icons";
import { IconButton } from "../../../common/components";
import styles from "./PartnerList.module.scss";

export default function PartnerList(props) {
  return (
    <div className={styles.container}>
      <Accordion>
        {props.children}
      </Accordion>
      <hr className={styles.separator} />
      {!props.readOnly && props.onAddClick && (
        <IconButton
          className={styles.addButton}
          icon={(<Add />)}
          iconPosition="left"
          onClick={props.onAddClick}
          text="Add Another Partner"
        />
      )}
    </div>
  );
}

PartnerList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  readOnly: PropTypes.bool,
  onAddClick: PropTypes.func,
};

PartnerList.Item = PartnerListItem;
