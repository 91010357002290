import React, { Component } from "react";
import PropTypes from "prop-types";
import { Heading, Option } from "../../../common/components";
import styles from "./SystemLandingPage.module.scss";

export default class SystemLandingPage extends Component {

  static propTypes = {
    onGoToDataExport: PropTypes.func.isRequired,
    onGoToDataImport: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className={styles.container}>
        <Heading className={styles.heading} title="System" />
        <div className={styles.content}>
          <Option
            title="Data Import"
            subtitle="This option allows you to import data into Optia."
            onClick={this.props.onGoToDataImport}
          />
          <Option
            title="Data Export"
            subtitle="This option allows you to export data from Optia (and Tempest)."
            onClick={this.props.onGoToDataExport}
          />
        </div>
      </div>
    );
  }

}
