import * as types from "./types";

const initialState = {
  ui: {
    submitting: false,
  },
  agencyCustomers: {
    loading: false,
    data: [],
    filters: [],
    total: null,
    error: null,
  },
  agencyCustomer: {
    loading: false,
    data: null,
    error: null,
  },
  contract: {
    loading: false,
    data: null,
    error: null,
  },
  agencyProspectSearch: {
    loading: false,
    error: null,
    data: [],
    pending: false,
  },
  companiesHouseResults: {
    loading: false,
    searchByName: {
      total: 0,
      data: [],
    },
    searchByNumber: {
      total: 0,
      data: [],
    },
    error: null,
  },
  companiesHouseCompanyProfile: {
    loading: false,
    data: null,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_AGENCY_CUSTOMERS_PENDING:
      return {
        ...state,
        agencyCustomers: {
          loading: true,
          data: [],
          filters: [],
          total: null,
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMERS_SUCCESS:
      return {
        ...state,
        agencyCustomers: {
          loading: false,
          data: action.payload.data,
          filters: [],
          total: action.payload.total,
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMERS_FAILURE:
      return {
        ...state,
        agencyCustomers: {
          loading: false,
          data: [],
          filters: [],
          total: null,
          error: action.payload.error,
        },
      };

    case types.GET_AGENCY_CUSTOMER_PENDING:
      return {
        ...state,
        agencyCustomer: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMER_SUCCESS:
      return {
        ...state,
        agencyCustomer: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_AGENCY_CUSTOMER_FAILURE:
      return {
        ...state,
        agencyCustomer: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.UPDATE_AGENCY_CUSTOMER_PENDING:
      return {
        ...state,
        agencyCustomer: {
          ...state.agencyCustomer,
          loading: true,
          error: null,
        },
        ui: {
          ...state.ui,
          submitting: true,
        },
      };

    case types.UPDATE_AGENCY_CUSTOMER_SUCCESS:
      return {
        ...state,
        agencyCustomer: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.UPDATE_AGENCY_CUSTOMER_FAILURE:
      return {
        ...state,
        agencyCustomer: {
          ...state.agencyCustomer,
          loading: false,
          error: action.payload.error,
        },
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.VERIFY_AGENCY_CUSTOMER_PENDING:
      return {
        ...state,
        agencyCustomer: {
          ...state.agencyCustomer,
          loading: true,
          error: null,
        },
        ui: {
          ...state.ui,
          submitting: true,
        },
      };

    case types.VERIFY_AGENCY_CUSTOMER_SUCCESS:
      return {
        ...state,
        agencyCustomer: {
          loading: false,
          data: {
            ...state.agencyCustomer.data,
            customer: action.payload.data,
          },
          error: null,
        },
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.VERIFY_AGENCY_CUSTOMER_FAILURE:
      return {
        ...state,
        agencyCustomer: {
          ...state.agencyCustomer,
          loading: false,
          error: action.payload.error,
        },
        ui: {
          ...state.ui,
          submitting: false,
        },
      };

    case types.GET_CONTRACT_PENDING:
      return {
        ...state,
        contract: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };

    case types.GET_CONTRACT_FAILURE:
      return {
        ...state,
        contract: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_PENDING:
      return {
        ...state,
        agencyProspectSearch: {
          ...state.agencyProspectSearch,
          loading: true,
          error: null,
          data: [],
          pending: true,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_SUCCESS:
      return {
        ...state,
        agencyProspectSearch: {
          loading: false,
          data: action.payload.data,
          error: null,
          pending: false,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_FAILURE:
      return {
        ...state,
        agencyProspectSearch: {
          loading: false,
          data: [],
          error: action.payload.error,
          pending: false,
        },
      };

    case types.AGENCY_PROSPECT_SEARCH_CLEAR:
      return {
        ...state,
        agencyProspectSearch: {
          loading: false,
          data: [],
          error: null,
          pending: true,
        },
      };

    case types.UPDATE_CUSTOMER_ACCOUNT_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.UPDATE_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
        contract: {
          ...state.contract,
          data: action.payload.data,
        },
      };

    case types.UPDATE_CUSTOMER_ACCOUNT_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
        contract: {
          ...state.contract,
          error: action.payload.error,
        },
      };

    case types.ADD_CUSTOMER_ACCOUNT_REFERENCE_PENDING:
      return {
        ...state,
        ui: {
          submitting: true,
        },
      };

    case types.ADD_CUSTOMER_ACCOUNT_REFERENCE_SUCCESS:
      return {
        ...state,
        ui: {
          submitting: false,
        },
        contract: {
          ...state.contract,
          data: {
            ...(state.contract.data || {}),
            customer_account_reference: action.payload.data,
          },
        },
      };

    case types.ADD_CUSTOMER_ACCOUNT_REFERENCE_FAILURE:
      return {
        ...state,
        ui: {
          submitting: false,
        },
        contract: {
          ...state.contract,
          error: action.payload.error,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_PENDING:
      return {
        ...state,
        companiesHouseResults: {
          loading: true,
          searchByName: { total: 0, data: [] },
          searchByNumber: { total: 0, data: [] },
          error: null,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_SUCCESS:
      return {
        ...state,
        companiesHouseResults: {
          loading: false,
          searchByName: action.payload.searchByName,
          searchByNumber: action.payload.searchByNumber,
          error: null,
        },
      };

    case types.SEARCH_COMPANIES_HOUSE_FAILURE:
      return {
        ...state,
        companiesHouseResults: {
          loading: false,
          searchByName: { total: 0, data: [] },
          searchByNumber: { total: 0, data: [] },
          error: action.payload.error,
        },
      };

    case types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_PENDING:
      return {
        ...state,
        companiesHouseCompanyProfile: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        companiesHouseCompanyProfile: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
        companiesHouseResults: { searchByName: { total: 0, data: [] }, searchByNumber: { total: 0, data: [] } },
      };

    case types.GET_COMPANIES_HOUSE_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        companiesHouseCompanyProfile: {
          loading: false,
          data: null,
          error: action.payload.error ,
        },
      };

    default:
      return state;
  }
}
