import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const EXPORT_PROSPECTS = gql`
mutation($options: ProspectExportOptions) {
  exportProspects(options: $options) {
    export_id
    export_type
    status
    created_at
    created_by {
      first_name
      last_name
    }
    error
    url
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number[]} obj.prospectIds
 * @returns
 */
export const exportProspects = ({ prospectIds }) => async (dispatch) => {
  try {
    dispatch({
      type: types.EXPORT_PROSPECTS_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: EXPORT_PROSPECTS,
      variables: {
        options: {
          prospect_ids: prospectIds,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.EXPORT_PROSPECTS_SUCCESS,
      payload: {
        data: response.data.exportProspects,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.EXPORT_PROSPECTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
