import _ from "lodash";

export default class ConvertToAgencyFormValidator {
  constructor(data, props) {
    this.data = data;
    this.props = props;
  }

  validate() {
    const { data, props } = this;
    const errors = {};

    if (_.isEmpty(data?.workerTypes)) {
      errors.workerTypes = "You must specify at least one worker type";
    }

    if (_.isEmpty(data?.knownAs)) {
      errors.knownAs = "Known As is required";
    }

    if (_.isEmpty(data?.shortCode)) {
      errors.shortCode = "Short Code is required";
    }
    else if (String(data?.shortCode).length !== 2) {
      errors.shortCode = "Short Code must contain two characters";
    }
    else if (!props?.shortCodeAvailability?.isAvailable) {
      errors.shortCode = "This short code has already been taken";
    }

    if (!data?.agencyStatus?.value) {
      errors.agencyStatus = "Agency Status is required";
    }

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
