import gql from "graphql-tag";

export const GET_USER_GROUPS = gql`
query {
  getUserGroups {
    id
    name
    description
  }
}
`;

export const GET_USER_GROUP = gql`
query($id: ID!) {
  getUserGroup(id: $id) {
    id
    name
    description
    permissions {
      id
      name
    }
    users {
      id
      first_name
      last_name
      email
      department {
        name
      }
    }
  }
}
`;

export const ADD_USER_GROUP = gql`
mutation($input: AddUserGroupInput!) {
  addUserGroup(input: $input) {
    id
    name
    description
  }
}
`;

export const UPDATE_USER_GROUP = gql`
mutation($id: ID!, $input: UpdateUserGroupInput!) {
  updateUserGroup(id: $id, input: $input) {
    id
    name
    description
    permissions {
      id
      name
    }
    users {
      id
      first_name
      last_name
      email
      department {
        name
      }
    }
  }
}
`;

export const DELETE_USER_GROUP = gql`
mutation($id: ID!) {
  deleteUserGroup(id: $id) {
    id
    name
    description
  }
}
`;
