import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { MultiFieldFilterDropdown } from "modules/common/components";

export default function TaskListFilter(props) {
  const initialFilters = [{
    label: "Task Name",
    values: [],
  },{
    label: "Agency",
    values: [],
  },{
    label: "Legal Entity",
    values: [],
  }];

  const [filters, setFilters] = useState(initialFilters);

  const taskNamePossibleValues = props.currentTasks.filters
    ?.find(filter => filter.field === "task_name")?.possible_values.map(possible_value => ({
      value: possible_value.value,
      checked: filters.find(filter => filter.label === "Task Name").values.includes(possible_value.value),
    })) ?? [];

  const agencyPossibleValues = props.currentTasks.filters
    ?.find(filter => filter.field === "agency.id")?.possible_values.map(possible_value => ({
      value: "agency_" + possible_value.value,
      displayValue: possible_value.display_value,
      checked: filters.find(filter => filter.label === "Agency").values.includes("agency_" + possible_value.value),
    })) ?? [];

  const prospectPossibleValues = props.currentTasks.filters
    ?.find(filter => filter.field === "prospect.id")?.possible_values.map(possible_value => ({
      value: "prospect_" + possible_value.value,
      displayValue: possible_value.display_value + " (Prospect)",
      checked: filters.find(filter => filter.label === "Agency").values.includes("prospect_" + possible_value.value),
    })) ?? [];

  const legalEntityPossibleValues = props.currentTasks.filters
    ?.find(filter => filter.field === "legal_entity.id")?.possible_values.map(possible_value => ({
      value: possible_value.value,
      displayValue: possible_value.display_value,
      checked: filters.find(filter => filter.label === "Legal Entity").values.includes(possible_value.value),
    })) ?? [];

  const filterableFields = [{
    label: "Task Name",
    possibleValues: taskNamePossibleValues,
  }, {
    label: "Agency",
    possibleValues: [...agencyPossibleValues, ...prospectPossibleValues],
  }, {
    label: "Legal Entity",
    possibleValues: legalEntityPossibleValues,
  }];

  const formatFiltersForRequest = (filters) => {
    const agencyValues = filters[
      filters.findIndex(filter => filter.label === "Agency")
    ].values
      .filter(value => value.startsWith("agency"))
      .map(value => value.slice(7));

    const prospectValues = filters[
      filters.findIndex(filter => filter.label === "Agency")
    ].values
      .filter(value => value.startsWith("prospect"))
      .map(value => value.slice(9));

    const taskNameValues = filters[filters.findIndex(filter => filter.label === "Task Name")].values;
    const legalEntityValues = filters[filters.findIndex(filter => filter.label === "Legal Entity")].values;

    return {
      title: taskNameValues.length > 0 ? taskNameValues: undefined,
      agency_id: agencyValues.length > 0 ? agencyValues : undefined,
      prospect_id: prospectValues.length > 0 ? prospectValues : undefined,
      legal_entity_id: legalEntityValues.length > 0 ? legalEntityValues : undefined,
    };
  };

  const onChange = (e) => {
    const incomingFilter = {
      label: e.split(":")[0],
      value: e.split(":")[1],
    };

    const newFilters = _.cloneDeep(filters);

    const filterIndex = newFilters.findIndex(filter => filter.label === incomingFilter.label);

    const isSelected = newFilters[filterIndex]
      .values.some(value => value === incomingFilter.value);

    if (isSelected) {
      _.remove(newFilters[filterIndex].values, value => value === incomingFilter.value);
    }
    else {
      newFilters[filterIndex].values.push(incomingFilter.value);
    }

    setFilters(newFilters);

    props.onFiltersChange(formatFiltersForRequest(newFilters));
  };

  const onClearFilters = () => {
    setFilters(initialFilters);

    props.onFiltersChange(formatFiltersForRequest(initialFilters));
  };

  return (
    <MultiFieldFilterDropdown
      fields={filterableFields}
      busy={props.currentTasks.loading}
      onChange={onChange}
      onClearFilters={onClearFilters} />
  );
}

TaskListFilter.propTypes = {
  currentTasks: PropTypes.shape({
    loading: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      possible_values: PropTypes.arrayOf({
        value: PropTypes.string,
        display_value: PropTypes.string,
      }),
    }),
    ) }).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};
