import _ from "lodash";
import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";
import { parseDate } from "../../../../utils";

const GET_COMPANY_HISTORY = gql`
fragment RevisionHistory on Revision {
  operation
  updated_at
  revision_changes {
    path
    old_value
    new_value
  }
}

query(
  $registered_address_input: GetHistoryInput!
  $contract_start_date_input: GetHistoryInput!
  $legal_name_input: GetHistoryInput!
  $trading_as_input: GetHistoryInput!
  $sort_code_input: GetHistoryInput!
  $account_number_input: GetHistoryInput!
) {
  registered_address: getHistory(input: $registered_address_input) {
    id
    model
    document_id
    document
    operation
    updated_at
  }

  contract_start_date: getHistory(input: $contract_start_date_input) {
    ...RevisionHistory
  }

  legal_name: getHistory(input: $legal_name_input) {
    ...RevisionHistory
  }

  trading_as: getHistory(input: $trading_as_input) {
    ...RevisionHistory
  }

  sort_code: getHistory(input: $sort_code_input) {
    ...RevisionHistory
  }

  account_number: getHistory(input: $account_number_input) {
    ...RevisionHistory
  }
}
`;

export const getCompanyHistory = agencyId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_COMPANY_HISTORY_PENDING });

    const { agencies } = await getState();
    const contractId = agencies.agency?.contract?.id || 0;
    const response = await apolloClient.query({
      query: GET_COMPANY_HISTORY,
      variables: {
        registered_address_input: {
          "document_id": `MAIN_${agencyId}`,
          "model": "AgencyAddress",
          "limit": 5,
          "order": "desc",
        },
        contract_start_date_input: {
          "document_id": contractId,
          "model": "AgencyContract",
          "fields": ["start_date"],
          "limit": 5,
          "order": "desc",
        },
        legal_name_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["legal_name"],
          "limit": 5,
          "order": "desc",
        },
        trading_as_input: {
          "document_id": agencyId,
          "model": "Agency",
          "fields": ["trading_as"],
          "limit": 5,
          "order": "desc",
        },
        sort_code_input: {
          "document_id": agencyId,
          "model": "AgencyBankInformation",
          "fields": ["sort_code"],
          "limit": 5,
          "order": "desc",
        },
        account_number_input: {
          "document_id": agencyId,
          "model": "AgencyBankInformation",
          "fields": ["account_number"],
          "limit": 5,
          "order": "desc",
        },
      },
    });

    const revisionsForAddress = revisions => _.chain(revisions)
      .map((revision) => {
        const address = JSON.parse(revision.document);

        return {
          value: _.compact([
            address.address_line_one,
            address.address_line_two,
            address.town_or_city,
            address.county,
            address.postcode,
          ]).join(", "),
          updated_at: revision.updated_at,
        };
      })
      .filter(historyItem => historyItem.value)
      .groupBy(historyItem => historyItem.updated_at)
      .map(group => _.merge({}, ...group))
      .value();
    const revisionsForField = (revisions, field) => revisions.reduce((result, revision) => [
      ...result,
      ...revision.revision_changes
        .filter(revisionChange => revisionChange.path === field)
        .map(revisionChange => ({
          value: revisionChange.new_value,
          updated_at: revision.updated_at,
        })),
    ], []);
    const revisions = {
      registered_address: revisionsForAddress(response.data.registered_address),
      contract_start_date: revisionsForField(response.data.contract_start_date, "start_date").map(change => ({
        ...change,
        value: change.value && parseDate(change.value),
      })),
      legal_name: revisionsForField(response.data.legal_name, "legal_name"),
      trading_as: revisionsForField(response.data.trading_as, "trading_as"),
      sort_code: revisionsForField(response.data.sort_code, "sort_code"),
      account_number: revisionsForField(response.data.account_number, "account_number"),
    };

    dispatch({
      type: types.GET_COMPANY_HISTORY_SUCCESS,
      payload: {
        revisions,
      },
    });
  } catch (error) {
    dispatch({
      type: types.GET_COMPANY_HISTORY_FAILURE,
      payload: {
        error,
      },
    });
  }
};
