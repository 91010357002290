import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_SCHEDULED_EXPERIAN_ACTIONS = gql`
query($legal_entity_id: ID!, $pager: Pager!, $sort_order: [SortOrder]) {
  getScheduledExperianActions(legal_entity_id: $legal_entity_id, pager: $pager, sort_order: $sort_order) {
    data {
      id
      new_credit_limit
      scheduled_at
      scheduled_by {
        first_name
        last_name
      }
      type
      status
      comment
      created_at
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.legalEntityId
 * @returns
 */
export const getScheduledExperianActions = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_SCHEDULED_EXPERIAN_ACTIONS_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_SCHEDULED_EXPERIAN_ACTIONS,
      variables: {
        legal_entity_id: obj.legalEntityId,
        pager: {
          page: 1,
          limit: 1000,
        },
        sort_order: [{
          field: "created_at", order: "desc",
        }],
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_SCHEDULED_EXPERIAN_ACTIONS_SUCCESS,
      payload: {
        data: response.data.getScheduledExperianActions.data,
      },
    });

  } catch (err) {
    dispatch({
      type: types.GET_SCHEDULED_EXPERIAN_ACTIONS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
