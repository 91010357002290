import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as modalActions } from "../../../modals/duck";
import EditAccountContactDetailsModal from "./EditAccountContactDetailsModal";

const mapStateToProps = state => ({
  submitting: state.customers.ui.submitting,
  loading: state.customers.customerAccount.loading,
  accountContactDetails: state.customers.customerAccount.data?.account_contact_details,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...modalActions,
}, dispatch);

const container = connect(mapStateToProps, mapDispatchToProps)(EditAccountContactDetailsModal);

container.displayName = "EditAccountContactDetailsModal";

export default container;
