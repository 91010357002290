const isValidTelephone = (phoneNumber) => {
  if (typeof phoneNumber !== "string") {
    return false;
  }

  const firstChar = phoneNumber.slice(0, 1);
  if (phoneNumber.length > 0 && !/[0-9]/.test(firstChar) && firstChar !== "+") {
    return false;
  }

  const otherChars = phoneNumber.slice(1);
  if (phoneNumber.length > 1 && !/[0-9]$/.test(otherChars)) {
    return false;
  }

  return true;
};

export default isValidTelephone;
