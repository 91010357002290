import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const ARCHIVE_AGENCY_CONTACT = gql`
mutation($contact_id: ID!) {
  archiveAgencyContact(id: $contact_id) {
    id
    first_name
    last_name
    preferred_name
    email_address
    contact_numbers {
      phone_number_type {
        id
        label
        value
      }
      phone_number
      is_preferred_number
    }
    job_role
    is_primary_contact
    ni_number
    archived
    created_at
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.contactId
 */
export const archiveAgencyContact = ({ contactId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.ARCHIVE_AGENCY_CONTACT_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: ARCHIVE_AGENCY_CONTACT,
      variables: {
        contact_id: contactId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.ARCHIVE_AGENCY_CONTACT_SUCCESS,
      payload: {
        agencyContact: response.data.archiveAgencyContact,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.ARCHIVE_AGENCY_CONTACT_FAILURE,
      payload: {
        error: err,
      },
    });
  }
};
