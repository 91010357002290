import React from "react";
import { Search } from "semantic-ui-react";
import styles from "./SearchBox.module.scss";

export default function SearchBox(props) {
  return (
    <Search
      className={styles.container}
      input={{ icon: "search", iconPosition: "left", fluid: true }}
      showNoResults={!props.loading && !props.pending}
      {...props}
    />
  );
}

SearchBox.propTypes = Search.propTypes;
