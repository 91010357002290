import React, { Component } from "react";
import PropTypes from "prop-types";
import { Squares as ActivityIndicator } from "react-activity";
import { formatSortCode } from "utils";
import { AgencyBankInformationForm } from "../../../components";
import styles from "./AgencyBankInformation.module.scss";

export default class AgencyBankInformation extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    ui: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }),
    agencyBankInformation: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.object.isRequired,
    }).isRequired,
    companyHistory: PropTypes.shape({
      account_number: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
      sort_code: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        updated_at: PropTypes.number.isRequired,
      })),
    }),
    agencyDisabledFields: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    getAgencyBankInformation: PropTypes.func.isRequired,
    updateAgencyBankInformation: PropTypes.func.isRequired,
    getCompanyHistory: PropTypes.func.isRequired,
    getAgencyDisabledFields: PropTypes.func.isRequired,
  }

  async componentWillMount() {
    const { agencyId, getCompanyHistory, getAgencyBankInformation, getAgencyDisabledFields } = this.props;

    await Promise.all([
      getAgencyBankInformation(agencyId),
      getCompanyHistory(agencyId),
      getAgencyDisabledFields(
        agencyId,
        [
          "sort_code",
          "account_number",
          "payee_name",
          "bank_name",
          "branch",
        ]
      ),
    ]);
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderAgencyBankInformationSection()}
      </div>
    );
  }

  renderAgencyBankInformationSection = () => {
    const {
      agencyDisabledFields,
      agencyBankInformation: {
        data,
        loading,
      },
      ui: {
        submitting,
      },
    } = this.props;

    if (loading) {
      return <ActivityIndicator />;
    }

    return (
      <AgencyBankInformationForm
        agencyBankInformation={data}
        disabledFields={agencyDisabledFields?.data?.map(field => (field.disabled_field))}
        onDisabledFieldClick={this.handleDisabledFieldClicked}
        onSave={this.handleSaveClicked}
        onCancel={this.handleCancelClicked}
        submitting={submitting}
        history={this.getFormattedHistory()}
      />
    );
  }

  getFormattedHistory = () => {
    const { companyHistory } = this.props;

    return {
      ...companyHistory,
      sort_code: companyHistory.sort_code?.map(revision => ({
        ...revision,
        value: formatSortCode(revision.value),
      })),
    };
  }

  handleDisabledFieldClicked = (field) => {
    const { agencyDisabledFields, agencyId, history } = this.props;

    const agencyDisabledField = agencyDisabledFields.data.find(item => item.disabled_field === field);

    history.push(`/onboarding/agencies/${agencyId}/${agencyDisabledField.agency_onboarding_task_id}`);
  }

  handleCancelClicked = () => {
    const { agencyId, history } = this.props;

    history.push(`/agencies/${agencyId}/agency`);
  }

  handleSaveClicked = async (data) => {
    const { agencyId, updateAgencyBankInformation } = this.props;

    const agencyData = {
      bankInformation: {
        ...data,
        agency_id: agencyId,
      },
    };

    await updateAgencyBankInformation(agencyData);
  }
}
