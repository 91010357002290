import { connect } from "react-redux";
import { push } from "connected-react-router";
import PerformCreditCheckPage from "./PerformCreditCheckPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.creditLimitManagement,
  initialQuestions: ownProps.location?.state?.data,
});
const mapDispatchToProps = dispatch => ({
  onCancel: () => {
    dispatch(push("/credit-limit-management"));
  },
  onGoToAddNewCompany: (data) => {
    if (data.limitedCompany) {
      dispatch(push("/credit-limit-management/registered-company/new", {
        data,
      }));
    }
    else {
      dispatch(push("/credit-limit-management/non-registered-company/new", {
        data,
      }));
    }
  },
  onGoToLegalEntity: (id) => {
    dispatch(push(`/credit-limit-management/legal-entities/${id}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformCreditCheckPage);
