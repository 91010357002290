import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { actions } from "../../duck";
import ViewAsAgencyPage from "./ViewAsAgencyPage";

const mapStateToProps = state => ({
  ...state.agencies,
  ...state.onboarding,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    onFetchOnboardingAgencies: actions.getOnboardingAgencies,
  }, dispatch),
  onGoToAgencyTask: ({ agencyId, agencyOnboardingTaskId }) => {
    dispatch(push(`/onboarding/agencies/${agencyId}/${agencyOnboardingTaskId}`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAsAgencyPage);
