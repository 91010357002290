import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Description, GetApp } from "@material-ui/icons";
import styles from "./DocumentDownloadButton.module.scss";

export default class DocumentDownloadButton extends PureComponent {

  static propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }

  render() {
    const { text } = this.props;

    return (
      <div className={styles.container} onClick={this.handleClick}>
        <div className={styles.content}>
          <div className={styles.title}>
            <Description className={styles.documentIcon} />
            <span className={styles.text}>{text || "Download"}</span>
          </div>
          <GetApp className={styles.downloadIcon} />
        </div>
      </div>
    );
  }

  handleClick = () => {
    const { onClick } = this.props;

    onClick && onClick();
  }

}
