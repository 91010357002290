import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { DateRange } from "@material-ui/icons";
import { detectBrowser, parseDate } from "../../../../../utils";
import styles from "./FormDate.module.scss";
import { DisabledFieldLock } from "../../";

export default function FormDate({
  value,
  name,
  className,
  size,
  inline,
  tooltip,
  disabled,
  readOnly,
  showLock,
  onChange,
  onLockClick,
  highlightError,
  useIcon,
  ...otherProps
}) {

  const renderLock = () => {
    return showLock && (
      <DisabledFieldLock tooltip={tooltip} onClick={onLockClick} />
    );
  };

  const handleInputChange = (e) => {
    if (disabled || readOnly) return;

    onChange && onChange({
      ...e,
      target: {
        name: e.target.name,
        value: moment.utc(e.target.value).valueOf(),
      },
    });
  };

  const parseValue = () => {
    if (!value) return "-";
    if (disabled || readOnly) return parseDate(value);
    return moment.utc(value).format("YYYY-MM-DD");
  };

  const renderInput = () => {
    if (useIcon) {
      return (
        <label>
          <input
            max={disabled || readOnly ? undefined : "9999-12-31"}
            {...otherProps}
            type={disabled || readOnly ? "text" : "date"}
            className={classnames(
              styles.defaultIconDate,
              detectBrowser() === "firefox" ? styles.firefoxIconDate : null,
              detectBrowser() === "chrome" ? styles.chromeIconDate : null,
            )}
            name={name}
            onChange={handleInputChange}
            value={parseValue()}
            disabled={disabled}
            readOnly={readOnly}
          />
          <div className={styles.iconWrapper}>
            <DateRange className={styles.icon} />
          </div>
        </label>
      );
    }

    return (
      <input
        max={disabled || readOnly ? undefined : "9999-12-31"}
        {...otherProps}
        type={disabled || readOnly ? "text" : "date"}
        className={styles.input}
        name={name}
        onChange={handleInputChange}
        value={parseValue()}
        disabled={disabled}
        readOnly={readOnly}
      />
    );
  };


  return (
    <div
      className={classnames(
        !useIcon && styles.container,
        !inline && styles[size],
        inline && styles.inline,
        className,
        disabled && styles.disabled,
        readOnly && styles.readOnly,
        highlightError && styles.highlightError,
      )}
    >
      {renderInput()}
      {renderLock()}
    </div>
  );
}

FormDate.defaultProps = {
  size: "medium",
};

FormDate.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  inline: PropTypes.bool,
  tooltip: PropTypes.any,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showLock: PropTypes.bool,
  highlightError: PropTypes.string,
  onChange: PropTypes.func,
  onLockClick: PropTypes.func,
  useIcon: PropTypes.bool,
};
