import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styles from "./ManageCreditModal.module.scss";
import { Form, Modal } from "../../../common/components";
import { List } from "../../../lists/containers";
import { options } from "../../../../constants";
import { upToNearestThousand } from "../../../../utils";
import ManageCreditModalValidator from "./ManageCreditModalValidator";
import {
  DecreaseForm,
  DistributeConfirmationModal,
  DistributeForm,
  HousekeepForm,
  IncreaseForm,
  RemoveForm,
  SuccessModal,
} from "./subcomponents";

const INITIAL_FORM_STATE = {
  reason: null,
  typeOfCheck: null,
  newCreditLimit: null,
  isExperianMonitoring: true,
  scheduledFor: Date.now(),
  dateOfResult: Date.now(),
  dateOfRequest: Date.now(),
  comment: "",
  companyFound: true,
};

const MANAGEMENT_TYPES = {
  INCREASE: "increase",
  REDUCE: "decrease",
  REMOVE: "removal",
  HOUSEKEEP: "housekeeping",
  DISTRIBUTE: "distribute",
};

export default function ManageCreditModal ({
  currentCreditLimit,
  currentExperianResult,
  currentAmountDistributed,
  undistributedCredit,
  currentVatRate,
  visible,
  busy,
  experianCreditCheck,
  legalEntity,
  agencyCustomers,
  onCloseModal,
  onPerformCreditCheck,
  onCreateCreditApplication,
  onDistributeCredit,
}) {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});
  const [showDistributeConfirmationModal, setShowDistributeConfirmationModal] = useState(false);

  const canComplete = () => {
    if (!formData.reason) return false;

    if (formData.reason === MANAGEMENT_TYPES.INCREASE) {
      if (!formData.typeOfCheck) return false;
      if (formData.typeOfCheck === "insurer" || formData.typeOfCheck === "broker") {
        if (!formData.newCreditLimit || !formData.dateOfRequest) return false;
      }
      if (formData.typeOfCheck === "experian") {
        if (formData.companyFound && _.isNil(formData.newCreditLimit)) return false;
        if (!formData.dateOfResult) return false;
        if (formData.companyFound && !formData.experianCreditReport) return false;
      }
    }
    if (formData.reason === MANAGEMENT_TYPES.REDUCE) {
      if (!formData.newCreditLimit || !formData.scheduledFor || !formData.typeOfCheck) return false;
      if (formData.typeOfCheck === "experian") {
        if (formData.isExperianMonitoring === null) return false;
        if (!formData.isExperianMonitoring && !formData.experianCreditReport) return false;
      }
    }
    if (formData.reason === MANAGEMENT_TYPES.REMOVE) {
      if (!formData.scheduledFor) return false;
      if (!formData.typeOfCheck) return false;
      if (formData.typeOfCheck === "experian") {
        if (formData.isExperianMonitoring === null) return false;
        if (!formData.isExperianMonitoring && !formData.experianCreditReport) return false;
      }
    }
    if (formData.reason === MANAGEMENT_TYPES.HOUSEKEEP) {
      if (!formData.scheduledFor) return false;
    }
    if (formData.reason === MANAGEMENT_TYPES.DISTRIBUTE) {
      if (!formData.fromAccount || !formData.toAccount || !formData.distributeAmount) return false;
    }

    return true;
  };

  const shouldUseScrollModal = () => {
    if (formData.reason === MANAGEMENT_TYPES.REDUCE && formData.typeOfCheck === "experian") {
      return true;
    }
    if (formData.reason === MANAGEMENT_TYPES.REMOVE && formData.typeOfCheck === "experian") {
      return true;
    }
    return false;
  };

  const handleChangeReason = (e) => {
    setFormData({ ...INITIAL_FORM_STATE, [e.target.name]: e.target.value });
    setErrors({});
  };

  const handleChangeTypeOfCheck = (typeOfCheck) => {
    // Don't wipe the form if the reason is not changing
    if (typeOfCheck === formData.typeOfCheck) {
      return;
    }

    setFormData({
      ...INITIAL_FORM_STATE,
      reason: formData.reason,
      typeOfCheck,
    });
    setErrors({});
  };

  const handleOnChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleRadioChange = e => setFormData({
    ...formData,
    [e.target.name]: String(e.target.value) === "true",
  });

  const handleNewCreditLimitBlur = () =>
    setFormData({ ...formData, newCreditLimit: upToNearestThousand(formData.newCreditLimit) });

  const handleCloseClick = () => {
    setFormData(INITIAL_FORM_STATE);
    setErrors({});
    onCloseModal();
  };

  const handleClearExperianReport = () => setErrors({ ...errors, experianCreditReport: null });

  const handleCloseDistributeConfirmationModal = () => setShowDistributeConfirmationModal(false);

  const handleSubmitDistributeConfirmationModal = () => onDistributeCredit(formData);

  const handleSubmitClick = async () => {
    const validator = new ManageCreditModalValidator(formData);
    const { success, errors } = validator.validate({
      currentCreditLimit,
      currentExperianResult: currentExperianResult || 0,
    });

    if (!success) {
      setErrors(errors);
      return;
    }

    if (formData.reason === MANAGEMENT_TYPES.INCREASE) {
      if (formData.typeOfCheck === "experian") {
        setFormData({ ...formData, previousLimit: currentCreditLimit });
        onPerformCreditCheck(formData);
      }
      if (formData.typeOfCheck === "insurer" || formData.typeOfCheck === "broker") {
        onCreateCreditApplication(formData);
      }
    }
    if (formData.reason === MANAGEMENT_TYPES.REDUCE) {
      if (formData.typeOfCheck === "experian") {
        onPerformCreditCheck(formData);
      }
      if (formData.typeOfCheck === "insurer") {
        onCreateCreditApplication({
          ...formData,
          source: "insurer",
        });
      }
    }
    if (formData.reason === MANAGEMENT_TYPES.REMOVE) {
      if (formData.typeOfCheck === "experian") {
        onPerformCreditCheck({
          ...formData,
          newCreditLimit: 0,
        });
      }
      if (formData.typeOfCheck === "insurer") {
        onCreateCreditApplication({
          ...formData,
          source: "insurer",
          newCreditLimit: 0,
        });
      }
    }
    if (formData.reason === MANAGEMENT_TYPES.HOUSEKEEP) {
      onCreateCreditApplication({
        ...formData,
        newCreditLimit: null,
        source: "back_office",
      });
    }
    if (formData.reason === MANAGEMENT_TYPES.DISTRIBUTE) {
      setShowDistributeConfirmationModal(true);
    }

    setErrors({});
  };

  if (!busy && experianCreditCheck?.status === "success") {
    return (
      <SuccessModal
        visible={true}
        previousLimit={formData.previousLimit}
        newLimit={experianCreditCheck?.experian_credit_check?.approved_credit}
        onSubmit={handleCloseClick}
      />
    );
  }

  return (
    <>
      <Modal
        title="Manage Credit"
        subtitle="Complete the form below to make the relevant changes."
        visible={visible}
        busy={busy}
        primaryButtonDisabled={!canComplete()}
        onSubmit={handleSubmitClick}
        onCancel={handleCloseClick}
        submitText="Complete"
        className={styles.modal}
        buttonPosition="middle"
        // Scrolling breaks dropdown overflow. Use with caution.
        scrolling={shouldUseScrollModal()}
      >
        <Form requiredKey>
          <Form.Group inline className={styles.group}>
            <Form.Label inline required>Reason for managing credit</Form.Label>
            <Form.Value>
              <List
                identifier="manage_credit_reasons"
                name="reason"
                fluid
                value={formData.reason}
                onChange={handleChangeReason}
                disabled={busy}
              />
            </Form.Value>
          </Form.Group>

          {formData.reason === MANAGEMENT_TYPES.INCREASE &&
            <IncreaseForm
              currentCreditLimit={currentCreditLimit}
              formData={formData}
              onChange={handleOnChange}
              onChangeTypeOfCheck={handleChangeTypeOfCheck}
              onClearExperianReport={handleClearExperianReport}
              onNewCreditLimitBlur={handleNewCreditLimitBlur}
              onRadioChange={handleRadioChange}
              sourceOptions={options.CREDIT_APPLICATION_SOURCE_OPTIONS}
              busy={busy}
              errors={errors}
            />
          }

          {formData.reason === MANAGEMENT_TYPES.REDUCE &&
            <DecreaseForm
              busy={busy}
              currentCreditLimit={currentCreditLimit}
              currentAmountDistributed={currentAmountDistributed}
              currentVatRate={currentVatRate}
              formData={formData}
              onChange={handleOnChange}
              onChangeTypeOfCheck={handleChangeTypeOfCheck}
              onClearExperianReport={handleClearExperianReport}
              onNewCreditLimitBlur={handleNewCreditLimitBlur}
              onRadioChange={handleRadioChange}
              sourceOptions={options.CREDIT_APPLICATION_SOURCE_OPTIONS}
              errors={errors}
            />
          }

          {formData.reason === MANAGEMENT_TYPES.REMOVE &&
            <RemoveForm
              busy={busy}
              currentAmountDistributed={currentAmountDistributed}
              formData={formData}
              onChange={handleOnChange}
              onChangeTypeOfCheck={handleChangeTypeOfCheck}
              onClearExperianReport={handleClearExperianReport}
              onRadioChange={handleRadioChange}
              sourceOptions={options.CREDIT_APPLICATION_SOURCE_OPTIONS}
              errors={errors}
            />
          }

          {formData.reason === MANAGEMENT_TYPES.HOUSEKEEP &&
            <HousekeepForm
              currentAmountDistributed={currentAmountDistributed}
              formData={formData}
              onChange={handleOnChange}
              errors={errors}
            />
          }

          {formData.reason === MANAGEMENT_TYPES.DISTRIBUTE &&
            <DistributeForm
              formData={formData}
              onChange={handleOnChange}
              errors={errors}
              busy={busy}
              legalEntity={legalEntity}
              legalEntityUndistributed={undistributedCredit}
              agencyCustomers={agencyCustomers}
            />
          }
        </Form>
      </Modal>

      {showDistributeConfirmationModal &&
        <DistributeConfirmationModal
          fromAccount={formData.fromAccount}
          toAccount={formData.toAccount}
          amount={formData.distributeAmount}
          onSubmit={handleSubmitDistributeConfirmationModal}
          onCancel={handleCloseDistributeConfirmationModal}
        />
      }
    </>
  );
}

ManageCreditModal.propTypes = {
  currentCreditLimit: PropTypes.number.isRequired,
  currentExperianResult: PropTypes.number.isRequired,
  currentAmountDistributed: PropTypes.number.isRequired,
  undistributedCredit: PropTypes.number.isRequired,
  currentVatRate: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  busy: PropTypes.bool.isRequired,
  experianCreditCheck: PropTypes.shape({
    experian_credit_check: PropTypes.object,
  }),
  legalEntity: PropTypes.object.isRequired,
  agencyCustomers: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onPerformCreditCheck: PropTypes.func.isRequired,
  onCreateCreditApplication: PropTypes.func.isRequired,
  onDistributeCredit: PropTypes.func.isRequired,
};
