import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../duck";
import { actions as documentActions } from "../../../documents/duck";
import { actions as modalActions } from "../../../modals/duck";
import ProspectTaskPage from "./ProspectTaskPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.onboarding,
  ...state.documents,
  prospectId: ownProps.match.params.prospectId,
  taskId: ownProps.match.params.taskId,
});
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...actions,
    ...documentActions,
    onAddTaskComment: actions.addProspectTaskComment,
    onAddTaskTrackingEntry: actions.addProspectTaskTrackingEntry,
    onDeleteTaskTrackingEntry: actions.deleteProspectTaskTrackingEntry,
    onSaveOnboardingTask: actions.saveProspectOnboardingTask,
    onCompleteOnboardingTask: actions.completeProspectOnboardingTask,
    showUnsavedChangesModal: modalActions.showUnsavedChangesModal,
  }, dispatch),
  onFetchTask: (_prospectId, taskId) => {
    dispatch(actions.getProspectTask({
      prospectOnboardingTaskId: taskId,
    }));
  },
  onFetchOnboardingDocuments: (prospectId) => {
    dispatch(documentActions.getProspectOnboardingDocuments({
      prospectId,
    }));
  },
  onUploadDocument: (document, prospectId, documentType) => {
    return dispatch(documentActions.uploadProspectDocument({
      document,
      prospectId,
      documentType,
    }));
  },
  onUploadOnboardingDocument: (document, prospectId, documentType) => {
    return dispatch(documentActions.uploadProspectOnboardingDocument({
      document,
      prospectId,
      documentType,
    }));
  },
  onDownloadOnboardingDocument: (prospectId, documentType, versionId) => {
    dispatch(documentActions.downloadProspectOnboardingDocument({
      prospectId,
      documentType,
      versionId,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProspectTaskPage);
