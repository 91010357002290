import React  from "react";
import PropTypes from "prop-types";
import { Checkbox, DisabledFieldLock } from "../../";
import styles from "./FormCheckbox.module.scss";

export default function FormCheckbox({
  className,
  label,
  tooltip,
  checked,
  disabled,
  readOnly,
  showLock,
  onChange,
  onLockClick,
  ...otherProps
}) {
  const renderCheckbox = () => {
    return (
      <Checkbox
        {...otherProps}
        className={className}
        text={label}
        checked={checked}
        disabled={disabled || readOnly}
        onClick={onChange}
      />
    );
  };

  const renderLock = () => {
    return showLock && (
      <DisabledFieldLock tooltip={tooltip} onClick={onLockClick} />
    );
  };

  return (
    <div className={styles.container}>
      {renderCheckbox()}
      {renderLock()}
    </div>
  );
}

FormCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showLock: PropTypes.bool,
  onChange: PropTypes.func,
  onLockClick: PropTypes.func,
};
