import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_CUSTOMER_ACCOUNT = gql`
  mutation($customer_account_id: ID!, $input: UpdateCustomerAccountInput!) {
    updateCustomerAccount(id: $customer_account_id, input: $input) {
      id
      display_name
      trading_as
      assignment
      is_purchase_order_number_required
      agreed_payment_terms_type
      agreed_payment_terms_value

      customer_account_reference {
        reference
      }

      account_contact_details{
        id
        full_name
        telephone_number
        email_address
        address_line_1
        address_line_2
        town_or_city
        county
        postcode
      }

      invoice_contact_details {
        id
        full_name
        telephone_number
        email_address
        address_line_1
        address_line_2
        town_or_city
        county
        postcode
      }

      payroll_agency_customer_request {
        agency {
          id
          known_as
          short_code
        }
        prospect {
          id
          known_as
        }
      }
    }
  }
`;

/**
 *
 * @param {object} obj
 * @param {string} obj.customerAccountId
 * @param {object} obj.paymentDetails
 * @param {string} obj.paymentDetails.isPurchaseOrderNumberRequired
 * @param {string} obj.paymentDetails.agreedPaymentTermsType
 * @param {string} obj.paymentDetails.agreedPaymentTermsValue
 * @returns
 */
export const updatePaymentDetails = obj => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_CUSTOMER_ACCOUNT_PENDING,
    });

    const response = await apolloClient.query({
      query: UPDATE_CUSTOMER_ACCOUNT,
      variables: {
        customer_account_id: obj.customerAccountId,
        input: {
          is_purchase_order_number_required: obj.paymentDetails.isPurchaseOrderNumberRequired,
          agreed_payment_terms_type: obj.paymentDetails.agreedPaymentTermsType,
          agreed_payment_terms_value: obj.paymentDetails.agreedPaymentTermsValue,
        },
      },
    });

    if (response.errors) {
      throw Error(response.errors[0].message);
    }

    dispatch({
      type: types.UPDATE_CUSTOMER_ACCOUNT_SUCCESS,
      payload: {
        data: response.data.updateCustomerAccount,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.UPDATE_CUSTOMER_ACCOUNT_FAILURE,
      payload: {
        error: error.message,
      },
    });
  }
};
