import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_BANK_INFORMATION = gql`
mutation(
  $prospect_id: ID!
  $input: UpdateProspectBankInformationInput!
) {
  updateProspectBankInformation(prospect_id: $prospect_id, input: $input) {
    sort_code
    account_number
    payee_name
    bank_name
    branch
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 * @param {object} obj.data
 * @param {string} obj.data.sort_code
 * @param {string} obj.data.account_number
 * @param {string} obj.data.payee_name
 * @param {string} obj.data.bank_name
 * @param {string} obj.data.branch
 * @param {string} obj.redirectUrl
 *
 */
export const updateBankInformation = ({ prospectId, data, redirectUrl }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_BANK_INFORMATION_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: UPDATE_BANK_INFORMATION,
      variables: {
        prospect_id: prospectId,
        input: {
          sort_code: data.sort_code,
          account_number: data.account_number,
          payee_name: data.payee_name,
          bank_name: data.bank_name,
          branch: data.branch,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_BANK_INFORMATION_SUCCESS,
      payload: {
        prospect: {
          banking: response.data.updateProspectBankInformation,
        },
      },
    });

    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_BANK_INFORMATION_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
