export default class DistributeCreditRequestModalValidator {
  constructor(distributions, accounts) {
    this.distributions = distributions;
    this.accounts = accounts;
  }

  validate() {
    const { distributions, accounts } = this;
    const errors = {};

    distributions.forEach((distribution, i) => {
      const account = accounts.find(account => account.id === distribution.accountId);

      if (account && distribution.amount > account.credit) {
        errors[String(i)] = "The amount entered is more than is available in this account";
      }
    });

    return {
      success: Object.keys(errors).length === 0,
      errors,
    };
  }
}
