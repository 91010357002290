import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Lock } from "@material-ui/icons";
import { Form, IconButton } from "../../../../common/components";
import { tooltips } from "../../../../../constants";
import styles from "./AgencyPreferencesSection.module.scss";

export default function AgencyPreferencesSection(props) {
  const renderPayrollFrequency = () => {
    if (!props.payrollFrequency) {
      return "-";
    }

    if (props.payrollFrequency === "both") {
      return "Weekly, Monthly";
    }

    return _.capitalize(props.payrollFrequency);
  };

  const renderDefaultPayslipPreference = () => {
    if (!props.defaultPayslipPreference) {
      return "-";
    }

    if (props.defaultPayslipPreference === "both") {
      return "Email, Post";
    }

    return _.capitalize(props.defaultPayslipPreference);
  };

  return (
    <Form.Card
      className={styles.container}
      title="Agency Preferences"
      rightAccessory={(
        <IconButton
          className={styles.locked}
          icon={<Lock />}
          tooltip={tooltips.NOT_AVAILABLE_TO_PROSPECTS_TOOLTIP}
        />
      )}
    >
      <Form.Group inline>
        <Form.Label inline>Payroll Frequency</Form.Label>
        <Form.ValueInline>
          {renderPayrollFrequency()}
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>Default Payslip Preference</Form.Label>
        <Form.ValueInline>
          {renderDefaultPayslipPreference()}
        </Form.ValueInline>
      </Form.Group>
      <Form.Group inline>
        <Form.Label inline>Default Customer VAT Rate</Form.Label>
        <Form.ValueInline>{props.defaultCustomerVatRate}%</Form.ValueInline>
      </Form.Group>
    </Form.Card>
  );
}

AgencyPreferencesSection.propTypes = {
  payrollFrequency: PropTypes.string,
  defaultCustomerVatRate: PropTypes.number,
  defaultPayslipPreference: PropTypes.string,
};
