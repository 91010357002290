import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Modal } from "semantic-ui-react";
import { Button } from "../../../common/components";
import styles from "./CompleteOnboardingTaskConfirmationModal.module.scss";

export default function CompleteOnboardingTaskConfirmationModal({
  className,
  visible,
  onCancelClick,
  onCompleteClick,
  ...otherProps
}) {
  return (
    <Modal
      {...otherProps}
      className={classnames(styles.container, className)}
      open={visible}
      dimmer={{
        className: styles.dimmer,
      }}
      centered
    >
      <Modal.Header className={styles.title}>
        Are you sure?
      </Modal.Header>
      <Modal.Content className={styles.caption}>
        <div>
          Are you sure you would like to complete this task?
        </div>
        <div className={styles.warningText}>
          Once completed you will not be able to go back and edit this task.
        </div>
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <Button variant="outline-danger" onClick={onCancelClick}>Cancel</Button>
        <Button variant="primary" onClick={onCompleteClick}>Complete</Button>
      </Modal.Actions>
    </Modal>
  );
}

CompleteOnboardingTaskConfirmationModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onCompleteClick: PropTypes.func.isRequired,
};
