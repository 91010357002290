import { DeleteDocumentVersionModal } from "../../../documents/containers";
import { actions as modalActions } from "../../../modals/duck";
import { actions as documentActions } from "../../../documents/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @param {object} obj.document
 * @param {string} obj.versionId
 *
 * @returns
 */
export const showDeleteDocumentVersionModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(DeleteDocumentVersionModal.displayName, {
    agencyId: obj.agencyId,
    document: obj.document,
    versionId: obj.versionId,
    removeDocumentVersion: data => dispatch(documentActions.removeAgencyDocument(data)),
  }));
};
