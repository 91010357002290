import gql from "graphql-tag";
import { push } from "connected-react-router";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const UPDATE_AGENCY_MI_REPORT_GROUPINGS = gql`
mutation($agency_id: ID!, $input: UpdateAgencyMIReportGroupingsInput!) {
  updateAgencyMiReportGroupings(id: $agency_id, input: $input) {
    mi_report_grouping_required
    agency_mi_report_groupings {
      active
      mi_report_grouping {
        id
        label
      }
      values {
        id
        value
      }
    }
  }
}
`;

export const updateAgencyMIReportGroupings = ({ agencyId, data }) => async (dispatch) => {
  try {
    dispatch({
      type: types.UPDATE_AGENCY_MI_REPORT_GROUPINGS_PENDING,
    });

    const agencyMiReportGroupingsInput = data.mi_report_groupings.map(grouping => ({
      mi_report_grouping_id: grouping.mi_report_grouping.id,
      active: grouping.active,
      values: grouping.values,
    }));

    const response = await apolloClient.mutate({
      mutation: UPDATE_AGENCY_MI_REPORT_GROUPINGS,
      variables: {
        agency_id: agencyId,
        input: {
          mi_report_grouping_required: data.mi_report_grouping_required,
          agency_mi_report_groupings: agencyMiReportGroupingsInput,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.UPDATE_AGENCY_MI_REPORT_GROUPINGS_SUCCESS,
      payload: {
        mi_report_grouping_required: response.data.updateAgencyMiReportGroupings.mi_report_grouping_required,
        mi_report_groupings: response.data.updateAgencyMiReportGroupings.agency_mi_report_groupings,
      },
    });
    dispatch(push(`/agencies/${agencyId}/reporting`));
  }
  catch (err) {
    dispatch({
      type: types.UPDATE_AGENCY_MI_REPORT_GROUPINGS_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
