import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

const GET_PAYROLL_GENERAL = gql`
query($prospect_id: ID!) {
  getProspect(id: $prospect_id) {
    worker_types {
      id
    }
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {number} obj.prospectId
 */
export const getPayrollGeneral = ({ prospectId }) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_PAYROLL_GENERAL_PENDING,
    });

    const response = await apolloClient.query({
      query: GET_PAYROLL_GENERAL,
      variables: {
        prospect_id: prospectId,
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.GET_PAYROLL_GENERAL_SUCCESS,
      payload: {
        workerTypes: response.data.getProspect.worker_types,
      },
    });
  }
  catch (err) {
    dispatch({
      type: types.GET_PAYROLL_GENERAL_FAILURE,
      payload: {
        error: err,
      },
    });
  }
}
;
