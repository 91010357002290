import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { Alert, DisabledFieldLock } from "../../../common/components";
import AgencyContacts from "./AgencyContacts/AgencyContacts";
import BackOfficeContacts from "./BackOfficeContacts/BackOfficeContacts";
import { tooltips } from "../../../../constants";
import styles from "./ContactsTab.module.scss";

const DISABLED_FIELD_TASK_NAME = "agency_contacts";

export default class ContactsTab extends Component {

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    agencyId: PropTypes.string.isRequired,
    agencyContacts: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.array,
      submitting: PropTypes.bool,
    }),
    agencyDisabledFields: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          agency_onboarding_task_id: PropTypes.string.isRequired,
          disabled_field: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      error: PropTypes.string,
    }).isRequired,
    backOfficeContacts: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        credit_controller: PropTypes.shape({
          id: PropTypes.string.isRequired,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
        credit_controller_buddy: PropTypes.shape({
          id: PropTypes.string.isRequired,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
        payroll_account_manager: PropTypes.shape({
          id: PropTypes.string.isRequired,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
        payroll_account_manager_buddy: PropTypes.shape({
          id: PropTypes.string.isRequired,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }),
      }),
      submitting: PropTypes.bool,
    }),
    onFetchAgencyDisabledFields: PropTypes.func.isRequired,
    onFetchBackOfficeContacts: PropTypes.func.isRequired,
    onFetchAgencyContacts: PropTypes.func.isRequired,
    onAddAgencyContact: PropTypes.func.isRequired,
    onUpdateAgencyContact: PropTypes.func.isRequired,
    onRemoveAgencyContact: PropTypes.func.isRequired,
    onArchiveAgencyContact: PropTypes.func.isRequired,
    onSaveBackOfficeContacts: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
  }

  static defaultProps = {
    agencyContacts: {
      loading: false,
      data: [],
    },
    backOfficeContacts: {
      loading: false,
      data: [],
    },
  }

  state = {
    showArchived: false,
  }

  async componentDidMount() {
    const { agencyId, match, onFetchAgencyDisabledFields, onFetchAgencyContacts, onFetchBackOfficeContacts, onLoad } = this.props;

    await Promise.all([
      onFetchAgencyDisabledFields(agencyId),
      onFetchAgencyContacts({ agencyId }),
      onFetchBackOfficeContacts({ agencyId }),
      onLoad(match.path),
    ]);
  }

  render() {
    const canEditContacts = this.canEditContacts();

    return (
      <>
        {!this.props.agencyDisabledFields?.loading && !canEditContacts && (
          <Alert className={styles.alert}
            variant={"warning"}
            showClose={false}>
            <div className={styles.onboardingAlertContent}>
              <DisabledFieldLock
                className={styles.lockIcon}
                tooltip={tooltips.CLICK_TO_GO_TO_TASK}
                onClick={() => this.handleOnboardingWarningClick()}
              />
                  The Contacts onboarding task must be complete before you can edit this page.
            </div>
          </Alert>
        )}
        <Grid className={styles.container} padded stackable stretched columns="equal" reversed="mobile">
          <Grid.Column>
            <AgencyContacts
              {...this.props.agencyContacts}
              showArchived={this.state.showArchived}
              onShowArchived={this.handleShowArchivedChanged}
              onContactAdd={this.handleAgencyContactAdded}
              onContactChange={this.handleAgencyContactChanged}
              onContactRemove={this.handleAgencyContactRemoved}
              onContactArchive={this.handleAgencyContactArchived}
              readOnly={!canEditContacts}
            />
          </Grid.Column>
          <Grid.Column>
            <BackOfficeContacts
              creditController={this.props.backOfficeContacts.data?.credit_controller}
              creditControllerBuddy={this.props.backOfficeContacts.data?.credit_controller_buddy}
              payrollAccountManager={this.props.backOfficeContacts.data?.payroll_account_manager}
              payrollAccountManagerBuddy={this.props.backOfficeContacts.data?.payroll_account_manager_buddy}
              businessDevelopmentManager={this.props.backOfficeContacts.data?.business_development_manager}
              onChange={this.handleBackOfficeContactsChanged}
              readOnly={!canEditContacts}
            />
          </Grid.Column>
        </Grid>
      </>
    );
  }

  handleOnboardingWarningClick = () => {
    const { history, agencyId, agencyDisabledFields } = this.props;

    const taskId = agencyDisabledFields?.data?.find(field =>
      field.disabled_field === DISABLED_FIELD_TASK_NAME).agency_onboarding_task_id;

    history.push(`/onboarding/agencies/${agencyId}/${taskId}`);
  }

  handleAgencyContactAdded = (contact) => {
    const { agencyId, onAddAgencyContact } = this.props;

    onAddAgencyContact({
      agencyId,
      contact,
    });
  }

  handleAgencyContactChanged = (contact) => {
    const { agencyId, onUpdateAgencyContact } = this.props;

    onUpdateAgencyContact({
      agencyId,
      contact,
    });
  }

  handleAgencyContactRemoved = (contact) => {
    const { onRemoveAgencyContact } = this.props;

    onRemoveAgencyContact({
      contactId: contact.id,
    });
  }

  handleAgencyContactArchived = (contact) => {
    const { onArchiveAgencyContact } = this.props;

    onArchiveAgencyContact({
      contactId: contact.id,
    });
  }

  handleBackOfficeContactsChanged = (backOfficeContacts) => {
    const { agencyId, onSaveBackOfficeContacts } = this.props;

    onSaveBackOfficeContacts({
      agencyId,
      ...backOfficeContacts,
    });
  }

  handleShowArchivedChanged = () => {
    const { showArchived } = this.state;

    this.setState({
      showArchived: !showArchived,
    });
  }

  fetchBackOfficeContacts = async () => {
    const { agencyId, onFetchBackOfficeContacts } = this.props;

    onFetchBackOfficeContacts({ agencyId });
  }

  canEditContacts = () => {
    return !this.props.agencyDisabledFields?.data?.some(field =>
      field.disabled_field === DISABLED_FIELD_TASK_NAME);
  }
}
