import gql from "graphql-tag";
import * as types from "../types";
import { apolloClient } from "../../../../apolloClient";

export const EXPORT_TEMPEST = gql`
mutation($options: TempestExportOptions) {
  exportTempest(options: $options) {
    export_id
    export_type
    status
    created_at
    created_by {
      first_name
      last_name
    }
    error
    url
  }
}
`;

/**
 *
 * @param {object} obj
 * @param {string[]} obj.agencyShortCodes
 * @returns
 */
export const exportTempest = ({ agencyShortCodes }) => async (dispatch) => {
  try {
    dispatch({
      type: types.EXPORT_TEMPEST_PENDING,
    });

    const response = await apolloClient.mutate({
      mutation: EXPORT_TEMPEST,
      variables: {
        options: {
          agency_short_codes: agencyShortCodes,
        },
      },
    });

    if (response.errors) {
      throw response.errors[0].message;
    }

    dispatch({
      type: types.EXPORT_TEMPEST_SUCCESS,
      payload: {
        data: response.data.exportTempest,
      },
    });
  }
  catch (error) {
    dispatch({
      type: types.EXPORT_TEMPEST_FAILURE,
      payload: {
        error,
      },
    });
  }
};
