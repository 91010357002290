import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ExpandMore } from "@material-ui/icons";
import { Squares as ActivityIndicator } from "react-activity";
import NewProspectFormValidator from "./NewProspectFormValidator";
import {
  Button,
  CollapseContainer,
  ContactList,
  DocumentDownloadButton,
  Form,
  TextBlock,
} from "../../../common/components";
import { List } from "../../../lists/containers";
import { DOCUMENT_TYPES } from "../../../documents";
import { capitalize, formatCorrespondenceName, parseDate } from "../../../../utils";
import { tooltips } from "../../../../constants";
import styles from "./NewProspectForm.module.scss";
import { Divider } from "semantic-ui-react";

export default function NewProspectForm(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [previousExactLegalName, setPreviousExactLegalName] = useState(null);
  const [state, setState] = useState({
    workerTypes: [],
    isNewAgency: true,
    isLedgerBuyout: null,
    isExistingPayeScheme: null,
    previousProvider: null,
    otherPreviousProvider: null,
    introducedViaBroker: false,
    knownAs: "",
    correspondenceName: "",
    tradingAs: "",
    contacts: [],
    previousPensionProvider: null,
    otherPreviousPensionProvider: null,
    businessDevelopmentManager: null,
  });
  const [errors, setErrors] = useState({});

  if (previousExactLegalName !== props.companyProfile?.company_name) {
    setPreviousExactLegalName(props.companyProfile?.company_name);
    setState({
      ...state,
      correspondenceName: formatCorrespondenceName(props.companyProfile?.company_name),
    });
  }

  const handleRadioButtonChange = (e) => {
    setState({
      ...state,
      [e.target.name]: String(e.target.value) === ""
        ? null
        : String(e.target.value) === "true",
    });
  };

  const handleIsNewAgencyChange = (e) => {
    const newState = {
      ...state,
      isNewAgency: e.target.value === "true",
    };

    // Reset dependent fields
    if (e.target.value === "true") {
      newState.isLedgerBuyout = null;
      newState.previousProvider = null;
      newState.otherPreviousProvider = null;
      newState.previousPensionProvider = null;
      newState.otherPreviousPensionProvider = null;
    }

    setState(newState);
  };

  const handlePreviousPensionProviderChange = (e, item) => {
    const newState = {
      ...state,
      previousPensionProvider: item,
    };

    // Reset dependent fields
    if (item?.value !== "other") {
      newState.otherPreviousPensionProvider = null;
    }

    setState(newState);
  };

  const handlePreviousProviderChange = (e, item) => {
    const newState = {
      ...state,
      previousProvider: item,
    };

    // Reset dependent fields
    if (item?.value !== "other") {
      newState.otherPreviousProvider = null;
    }

    setState(newState);
  };

  const canComplete = () => {
    if (!state.knownAs) {
      return false;
    }
    if (!state.correspondenceName) {
      return false;
    }
    if (!state.contacts || state.contacts.length === 0) {
      return false;
    }
    if (!state.businessDevelopmentManager) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const validator = new NewProspectFormValidator(state);
    const validationResult = validator.validate();

    setErrors(validationResult.errors);

    if (!validationResult.success) {
      return;
    }

    const data = {
      ...state,
      previousProviderId: +state.previousProvider?.id,
      previousPensionProviderId: +state.previousPensionProvider?.id,
      businessDevelopmentManagerId: +state.businessDevelopmentManager?.value,
      contacts: state.contacts.map(contact => _.omit(contact, ["id"])),
    };

    props.onSubmit(data);
  };

  return (
    <div className={classnames(props.className, styles.container)}>
      <div className={styles.companyDetailsContainer}>
        <div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
          <h1 className={styles.title}>
            Companies House Details
          </h1>
          {props.loading ? (
            <ActivityIndicator />
          ) : (
            <span className={styles.icon}>
              <ExpandMore
                className={classnames(
                  styles.expandIcon,
                  isExpanded ? styles.collapse : styles.expand
                )}
                fontSize="inherit"
              />
            </span>
          )}
        </div>
        <CollapseContainer collapsed={!isExpanded}>
          <Form className={styles.form}>
            <div className={styles.cols}>
              <div className={styles.col}>
                <Form.Group inline>
                  <Form.Label>Exact Legal Name</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.company_name}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Registered Company Number</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.company_number}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Company Type</Form.Label>
                  <Form.Value>
                    {capitalize(props.companyProfile?.type) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Incorporated Date</Form.Label>
                  <Form.Value>
                    {parseDate(props.companyProfile?.date_of_creation) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Last Account Period End On</Form.Label>
                  <Form.Value>
                    {parseDate(props.companyProfile?.accounts?.last_accounts?.made_up_to) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group className={styles.alignStart} inline>
                  <Form.Label className={styles.alignStartLabel}>Previous Names</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.previous_company_names?.map((previousName, index) => (
                      <Form.FlexRow key={`previous_name_${index}`}>
                        <Form.ValueInline>
                          {previousName.name}
                        </Form.ValueInline>
                      </Form.FlexRow>
                    )) || "-"}
                  </Form.Value>
                </Form.Group>
              </div>
              <div className={styles.col}>
                <Form.Group inline>
                  <Form.Label>Company Status</Form.Label>
                  <Form.Value>
                    {capitalize(props.companyProfile?.company_status) || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group inline>
                  <Form.Label>Company Status Detail</Form.Label>
                  <Form.Value>
                    {props.companyProfile?.company_status_detail || "-"}
                  </Form.Value>
                </Form.Group>
                <Form.Group className={styles.alignStart} inline>
                  <Form.Label className={styles.alignStartLabel}>Registered Address</Form.Label>
                  <Form.Value>
                    {_.chain([
                      props.companyProfile?.registered_office_address?.address_line_1,
                      props.companyProfile?.registered_office_address?.address_line_2,
                      props.companyProfile?.registered_office_address?.locality,
                      props.companyProfile?.registered_office_address?.region,
                      props.companyProfile?.registered_office_address?.postal_code,
                      props.companyProfile?.registered_office_address?.country,
                    ]).compact().map((addressLine, index) => (
                      <Form.FlexRow key={`registered_address_${index}`}>
                        <Form.ValueInline>
                          {addressLine}
                        </Form.ValueInline>
                      </Form.FlexRow>
                    )).value()}
                  </Form.Value>
                </Form.Group>
              </div>
            </div>
            <Divider />
            <div>
              <DocumentDownloadButton
                text={DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION}
                onClick={() => {
                  window.open(props.certificateOfIncorporationUrl, "_blank");
                }}
              />
            </div>
          </Form>
        </CollapseContainer>
      </div>
      <Form className={classnames(styles.additionalQuestionsForm, props.submitting && styles.submitting)} requiredKey>
        <Form.Section title="Additional Questions">
          <Form.Group className={styles.alignStart} inline error={errors?.workerTypes}>
            <Form.Label className={styles.alignStartLabel} inline>Type of workers?</Form.Label>
            <Form.Value>
              <List
                identifier="worker_types"
                value={state.workerTypes}
                highlightError={errors?.workerTypes}
                onChange={workerTypeId => setState({
                  ...state,
                  workerTypes: state.workerTypes.includes(workerTypeId)
                    ? state.workerTypes.filter(workerType => workerType !== workerTypeId)
                    : [...state.workerTypes, workerTypeId],
                })}
              />
            </Form.Value>
          </Form.Group>
          <Form.Group className={styles.alignStart} inline error={errors?.isNewAgency}>
            <Form.Label className={styles.alignStartLabel} inline>Is the agency new or existing?</Form.Label>
            <Form.RadioList
              orientation="vertical"
              value={state.isNewAgency}
              options={[
                {
                  label: "New",
                  value: true,
                },
                {
                  label: "Existing",
                  value: false,
                },
              ]}
              onChange={handleIsNewAgencyChange}
            />
          </Form.Group>
          <Form.Group inline error={errors?.previousProvider}>
            <Form.Label inline>Previous Provider</Form.Label>
            <List
              className={styles.previousProviders}
              identifier="previous_provider"
              name="previousProvider"
              value={state.previousProvider?.value}
              size="large"
              disabled={state.isNewAgency}
              highlightError={errors?.previousProvider}
              onChange={handlePreviousProviderChange}
            />
          </Form.Group>
          {state.previousProvider?.value === "other" && (
            <Form.Group inline error={errors?.otherPreviousProvider}>
              <Form.Label inline required>Other Previous Provider</Form.Label>
              <Form.Input
                type="text"
                value={state.otherPreviousProvider}
                placeholder="Enter Other Previous Provider..."
                size="large"
                maxLength={255}
                highlightError={errors?.otherPreviousProvider}
                onChange={e => setState({ ...state, otherPreviousProvider: e.target.value })}
              />
            </Form.Group>
          )}
          <Form.Group className={styles.alignStart} inline error={errors?.isLedgerBuyout}>
            <Form.Label className={styles.alignStartLabel} inline>Ledger Buyout?</Form.Label>
            <Form.Value>
              <Form.Radio
                value={true}
                name="isLedgerBuyout"
                checked={state.isLedgerBuyout}
                text="Yes"
                disabled={state.isNewAgency}
                onChange={handleRadioButtonChange}
              />
              <Form.Radio
                value={false}
                name="isLedgerBuyout"
                checked={state.isLedgerBuyout === false}
                text="No"
                disabled={state.isNewAgency}
                onChange={handleRadioButtonChange}
              />
              <Form.Radio
                value={null}
                name="isLedgerBuyout"
                checked={_.isNull(state.isLedgerBuyout)}
                text="Unknown"
                disabled={state.isNewAgency}
                onChange={handleRadioButtonChange}
              />
            </Form.Value>
          </Form.Group>
          <Form.Group className={styles.alignStart} inline error={errors?.isExistingPayeScheme}>
            <Form.Label className={styles.alignStartLabel} inline>Existing PAYE Scheme?</Form.Label>
            <Form.Value>
              <Form.Radio
                value={true}
                name="isExistingPayeScheme"
                checked={state.isExistingPayeScheme}
                text="Yes"
                onChange={handleRadioButtonChange}
              />
              <Form.Radio
                value={false}
                name="isExistingPayeScheme"
                checked={state.isExistingPayeScheme === false}
                text="No"
                onChange={handleRadioButtonChange}
              />
              <Form.Radio
                value={null}
                name="isExistingPayeScheme"
                checked={_.isNull(state.isExistingPayeScheme)}
                text="Unknown"
                onChange={handleRadioButtonChange}
              />
            </Form.Value>
          </Form.Group>
          <Form.Group inline error={errors?.previousPensionProvider}>
            <Form.Label inline>Previous Pension Provider</Form.Label>
            <List
              identifier="previous_pension_company"
              name="previousPensionProvider"
              value={state.previousPensionProvider?.value}
              size="large"
              disabled={state.isNewAgency}
              highlightError={errors?.previousPensionProvider}
              placeholder="Select a previous pension provider"
              onChange={handlePreviousPensionProviderChange}
            />
          </Form.Group>
          {state.previousPensionProvider?.value === "other" && (
            <Form.Group inline error={errors?.otherPreviousPensionProvider}>
              <Form.Label inline required>Other Previous Pension Provider</Form.Label>
              <Form.Input
                type="text"
                value={state.otherPreviousPensionProvider}
                placeholder="Enter Other Previous Pension Provider..."
                size="large"
                maxLength={255}
                highlightError={errors?.otherPreviousPensionProvider}
                onChange={e => setState({ ...state, otherPreviousPensionProvider: e.target.value })}
              />
            </Form.Group>
          )}
          <Form.Group inline error={errors?.knownAs}>
            <Form.Label inline required>Agency Known As Name</Form.Label>
            <Form.Input
              type="text"
              value={state.knownAs}
              placeholder="Enter Known As Name..."
              size="large"
              maxLength={30}
              highlightError={errors?.knownAs}
              onChange={e => setState({ ...state, knownAs: e.target.value })}
            />
          </Form.Group>

          <Form.Group inline>
            <Form.Label
              inline
              required
              tooltip={tooltips.WHAT_IS_CORRESPONDENCE_NAME}
            >
                Exact Legal Name <br/>(Payroll Version)
            </Form.Label>
            <Form.Input
              type="text"
              value={state.correspondenceName}
              placeholder="Enter Exact Legal Name..."
              size="large"
              maxLength={160}
              onChange={e => setState({ ...state, correspondenceName: e.target.value })}
            />
          </Form.Group>

          <Form.Group inline error={errors?.tradingAs}>
            <Form.Label inline>Trading As</Form.Label>
            <Form.Input
              type="text"
              value={state.tradingAs}
              placeholder="Enter Trading As Name..."
              size="large"
              highlightError={errors?.tradingAs}
              onChange={e => setState({ ...state, tradingAs: e.target.value })}
            />
          </Form.Group>
          <Form.Group className={styles.alignStart} inline error={errors?.introducedViaBroker}>
            <Form.Label className={styles.alignStartLabel} inline>Introduced via broker?</Form.Label>
            <Form.RadioList
              orientation="vertical"
              value={state.introducedViaBroker}
              options={[
                {
                  label: "Yes",
                  value: true,
                },
                {
                  label: "No",
                  value: false,
                },
              ]}
              onChange={e => setState({ ...state, introducedViaBroker: e.target.value === "true" })}
            />
          </Form.Group>
        </Form.Section>
        <Form.Section title="Agency Contact Details">
          <TextBlock content="At least one contact is required" variant="info" />
          <ContactList
            contacts={state.contacts}
            onChange={e => setState({ ...state, contacts: e.target.value })}
            error={errors?.contacts}
          />
        </Form.Section>
        <Form.Section title="Back Office Contacts">
          <Form.Group inline error={errors?.businessDevelopmentManager}>
            <Form.Label inline required>Business Development Manager</Form.Label>
            <Form.Select
              name="businessDevelopmentManager"
              value={state.businessDevelopmentManager?.value}
              size="large"
              options={props.businessDevelopmentManagers.map(user => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
              }))}
              disabled={props.submitting}
              highlightError={errors?.businessDevelopmentManager}
              onChange={(_e, item) => setState({ ...state, businessDevelopmentManager: item })}
            />
          </Form.Group>
        </Form.Section>
      </Form>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          className={styles.button}
          disabled={props.submitting || !canComplete()}
          onClick={handleSubmit}
        >
          Complete
        </Button>
        <Button
          variant="outline-danger"
          className={styles.button}
          disabled={props.submitting}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

NewProspectForm.propTypes = {
  className: PropTypes.string,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
  companyProfile: PropTypes.shape({
    company_name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    company_number: PropTypes.string.isRequired,
    accounts: PropTypes.shape({
      last_accounts: PropTypes.shape({
        made_up_to: PropTypes.string.isRequired,
      }),
    }),
    company_status: PropTypes.string.isRequired,
    company_status_detail: PropTypes.string,
    previous_company_names: PropTypes.shape({
      ceased_on: PropTypes.string.isRequired,
      effective_from: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    registered_office_address: PropTypes.shape({
      address_line_1: PropTypes.string.isRequired,
      address_line_2: PropTypes.string.isRequired,
      locality: PropTypes.string.isRequired,
      postal_code: PropTypes.string.isRequired,
    }),
  }).isRequired,
  certificateOfIncorporationUrl: PropTypes.string.isRequired,
  businessDevelopmentManagers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  })).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
