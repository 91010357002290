import { AgencyDocumentVersionHistoryModal } from "../../containers";
import { actions as modalActions } from "../../../modals/duck";

/**
 *
 * @param {object} obj
 * @param {string} obj.agencyId
 * @param {object} obj.documentType
 *
 * @returns
 */
export const showAgencyDocumentVersionHistoryModal = obj => async (dispatch) => {
  dispatch(modalActions.showModal(AgencyDocumentVersionHistoryModal.displayName, {
    agencyId: obj.agencyId,
    documentType: obj.documentType,
  }));
};
